import React, { Fragment, Dispatch, useState, useEffect } from "react";
import ReturnRequestDialog from './ReturnRequestDialog'
import "./OrderHistory.css";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ReturnRequestSnackbar from "../../common/components/returnRequestSnackbar";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Collapse from '@material-ui/core/Collapse';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import DatePicker from '../../common/components/dateRangePicker';
import Checkbox from "@material-ui/core/Checkbox";
import { getOrdersList, getOrderId, getInvoice, resetInvoice, setPageNumber, getOrderStatus } from "../../store/actions/orderHistory.actions";
import moment from "moment";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import { isEmpty, isString } from 'lodash';
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import { get } from "lodash";
import NotificationIcon from "../../common/components/NotificationIcon";
import { getDeliveryList } from "../../store/actions/delivery.actions";
import { getNewBrand } from "../../common/components/util";
import { useCallback } from "react";
import { ALL_STATUS, viewDeliveryOrderStatuses, viewReturnRequestStatuses, viewViewInvoiceStatuses } from "../../constants/orderHistoryConstants";
import { getSellingPrice } from "../../helpers/commonmethod";

const useStyles = makeStyles((theme) => ({
  collapseBg: {
    background: 'rgba(66, 94, 108, 0.07)'
  },
  rowHighlight: {
    background: 'rgba(67, 95, 107, 0.15)',
    color: '#666666',
    fontWeight: 700
  },
  orangeRowHighlight: {
    background: 'rgba(240, 223, 0, 0.12)',
    color: '#666666',
    fontWeight: 700
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 140,

  },
  bodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  },
  collapseIcon: {
    color: '#000000',
    fontWeight: 500,
  },
  tableHeadSmall: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 40,
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 140,
  },
  subTableHead: {
    color: '#444444',
    fontWeight: 800,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHeadSmall: {
    color: '#444444',
    fontWeight: 800,
    width: 40,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  actionButton: {
    color: '#1A9FE0',
  },
  subTableBodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 140,
  },
  subTableBodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  }
}));

const OrderHistory: React.FC = () => {
  const history = useHistory();
  const [isReturned, setIsReturned] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const [openReturnRequest, setOpenReturnRequest] = React.useState(false);
  const [openReturnRequestSnackbar, setOpenReturnRequestSnackbar] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const { orderList, invoice, prevPage, orderStatus }: any = useSelector((state: any) => state.orderHistory);
  const [selectedOrder, setselectedOrder] = React.useState<any>(null);
  const [searchText, setsearchText] = useState<any>('');
  const [isMyOrders, setisMyOrders] = useState<boolean>(false);
  const [startDate, setstartDate] = useState<any>(null);
  const [endDate, setendDate] = useState<any>(null);
  const [status, setstatus] = useState<any>('');
  const [open, setOpen] = React.useState(-1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);
  const { deliveryList }: any = useSelector((state: any) => state.delivery);
  const b2cUserId = get(profileDetails, "data.data.basicInfo.b2cUserId", "");
  const showViewInvoice = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.DOwNLOAD_INVOICE_EXCEL) && !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.LIST_BILLING_DOCS);
  const showViewDelivery = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_DELIVERY);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setselectedOrder(null)
  };

  const loadOrderList = useCallback((pageNumber?: any) => {
    setPage((prevPage && prevPage !== null) ? prevPage : pageNumber ? pageNumber : 0);
    let newPage = (prevPage && prevPage !== null) ? prevPage : pageNumber ? pageNumber : 0;
    let searchData: any = {};
    if (searchText && searchText.trim()) {
      searchData["enquiryNumber"] = searchText.trim();
      searchData["referenceNumber"] = searchText.trim();
      searchData["orderNumber"] = searchText.trim();
    }
    searchData["userId"] = isMyOrders ? b2cUserId : "";
    if (startDate && endDate) {
      searchData["fromDate"] = startDate;
      searchData["toDate"] = endDate;
    }
    if (status && status !== ALL_STATUS) {
      searchData["status"] = status;
    }
    dispatch(getOrdersList(searchData, newPage));
    dispatch(setPageNumber(null));
  }, [b2cUserId, dispatch, searchText, isMyOrders, startDate, endDate, status, prevPage]);

  const getInvoiceDetails = (orderDetails: any) => {
    const data = {
      orderNumber: orderDetails.orderNumber,
      enquiryNumber: orderDetails.enquiryNumber,
      webApplicationLoginId: sessionStorage.getItem('webApplicationLoginId'),
      make: orderDetails.make
    }
    if (data.orderNumber && data.enquiryNumber && data.webApplicationLoginId && data.make) {
      dispatch(getInvoice(data));
    }
  }

  React.useEffect(() => {
    if (searchText === '' && startDate === null && endDate === null) {
      loadOrderList()
    }
    dispatch(getOrderStatus());
  }, [searchText, startDate, endDate, isMyOrders]); //eslint-disable-line

  const resetInvoiceValues = useCallback(() => {
    dispatch(resetInvoice());
  }, [dispatch]);

  useEffect(() => {
    if (!openSnackbar) resetInvoiceValues();
  }, [openSnackbar, resetInvoiceValues]);

  useEffect(() => {
    if (invoice && invoice.data && invoice.data.data && invoice.data.data.invoiceUrl) {
      downloadUrl(invoice.data.data.invoiceUrl);
      handleopenCloseSnackbar(true);
    }
    if (invoice && invoice.err && invoice.err.statusText && invoice.err.statusText.data && invoice.err.statusText.data.error) {
      handleopenCloseSnackbar(true);
    }
  }, [invoice]);

  const downloadUrl = (url: any) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setPageNumber(null));
    loadOrderList(newPage)
    setPage(newPage);
  };

  const handleopenCloseReturnRequest = (open?: any) => {
    setOpenReturnRequest(open ? true : false);
  };

  const handleopenCloseReturnRequestSnackbar = (open?: any) => {
    setOpenReturnRequestSnackbar(open ? true : false);
  };

  const { data: orders } = orderList || {};

  return (
    <Fragment>
      {((orderList && orderList.loading) || (deliveryList && deliveryList.loading)) && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Order History
        </h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      {/* <div className="row mt-4"> */}
      <ReturnRequestSnackbar
        open={openReturnRequestSnackbar}
        handleopenClose={() => {
          handleopenCloseReturnRequestSnackbar(false)
        }}
      />
      {invoice && invoice.data && invoice.data.data && invoice.data.data.invoiceUrl && (
        <Snackbar
          open={openSnackbar}
          message='Invoice Downloaded Successfully'
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} />
      )}
      {invoice && invoice.err && invoice.err.statusText && invoice.err.statusText.data && invoice.err.statusText.data.error && (
        <Snackbar
          open={openSnackbar}
          type="error"
          message={invoice.err.statusText.data.error ? invoice.err.statusText.data.message.message : 'Invoice Request Failed'}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} />
      )}
      {deliveryList && deliveryList.err && deliveryList.err.statusText && deliveryList.err.statusText.data && deliveryList.err.statusText.data.message && deliveryList.err.statusText.data.message.status && deliveryList.err.statusText.data.message.status === "E" && (
        <Snackbar
          open={openSnackbar}
          type="error"
          message={deliveryList.err.statusText.data.message.message ? deliveryList.err.statusText.data.message.message : 'There was a problem fetching the delivery list'}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} />
      )}
      <ReturnRequestDialog
        selectedOrder={selectedOrder}
        open={openReturnRequest}
        markReturned={setIsReturned}
        handleopenClose={(data: any) => {
          if (data) {
            //return api call here
            handleopenCloseReturnRequestSnackbar(true)
          }
          handleopenCloseReturnRequest(false)
        }}
      />
      {/* </div> */}
      <div className="row mt-4">
        <div className="col-12">
          <div className="cardCommon p-3">
            <div className="d-flex align-items-center">
              <h5 className="page-sub-title mb-2">Search Orders</h5>
            </div>
            <TextField
              value={searchText}
              onChange={(e) => {
                setsearchText(e.target.value)
              }}
              size="small" className="col-4 col-sm-6 col-md-4 col-lg-4 common-input-font-size commonRoundedInputs searchField" label="Search by Order Enquiry No/Order No/Reference No" variant="outlined" />
            <div className="col-4 col-sm-12 col-md-4 col-lg-4 d-inline-flex date-picker-50">
              <DatePicker
                startDate={startDate}
                endDate={endDate}
                onChange={(start: any, end: any) => {
                  setstartDate(start);
                  setendDate(end);
                }}
              />
            </div>
            <div className="col-2 col-sm-6 col-md-2 col-lg-2  d-inline-flex pl-0  ">
              <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select

                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={status}
                  onChange={(e) => {
                    setstatus(e.target.value)
                  }}
                  label="Status"
                >
                  <MenuItem key={ALL_STATUS}
                    value={ALL_STATUS}>All</MenuItem>
                  {orderStatus && orderStatus.data && orderStatus.data.data && orderStatus.data.data.length > 0 && orderStatus.data.data.map((status: any, i: any) => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button
              className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton"
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                loadOrderList()
              }}
            >Search</Button>
            <div className="ml-2 mt-2">
              <Checkbox checked={isMyOrders} onChange={() => { setisMyOrders(!isMyOrders); }} />
              <span className="myOrders">My Orders</span>
            </div>
          </div></div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex align-items-center">
            <h5 className="page-sub-title">List of Orders</h5>
            <div className="legendItem ml-auto">
              {/* <span className="orangedot"></span> */}
              {/* <span className="legendItemLabel mr-4">Return Created</span> */}
              <span className="dot"></span>
              <span className="legendItemLabel">Back Order</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12">
          <div className="cardCommon mh-300p">
            <TableContainer>
              <Table aria-label="table" >
                <TableHead className="w-100">
                  <TableRow>
                    <TableCell className={classes.tableHeadSmall}></TableCell>
                    <TableCell className={classes.tableHead}>Order Enquiry No.</TableCell>
                    <TableCell className={classes.tableHead}>Reference No.</TableCell>
                    <TableCell className={classes.tableHead}>Brand</TableCell>
                    <TableCell className={classes.tableHead}>
                      <h6 className="totalHead">Total Amount</h6>
                      <div className="text-muted font-10 font-weight-600">(incl. of Vat)</div>
                    </TableCell>
                    <TableCell className={classes.tableHead}>Created By</TableCell>
                    <TableCell className={classes.tableHead}>Created On</TableCell>
                    <TableCell className={classes.tableHead}>Status</TableCell>
                    <TableCell className={classes.tableHead}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEmpty(orders) && orders.map((order: any, index: any) => {
                    // const { order } = props;
                    const { subEnquiries } = order || {};
                    const showViewEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_ENQUIRY_DETAILS);
                    return (
                      <React.Fragment>
                        <TableRow key={order.masterId} hover role="checkbox" tabIndex={-1} onClick={() => setOpen(open === index + 1 ? -1 : index + 1)} className="hover-pointer" >
                          <TableCell className={classes.bodyContentSmall}>
                            <IconButton aria-label="expand row" size="small" className={classes.collapseIcon}>
                              {open > 0 && open === index + 1 ? <RemoveIcon fontSize="inherit" /> : <Add fontSize="inherit" />}
                            </IconButton>
                          </TableCell>
                          <TableCell className={classes.bodyContent}>{order.masterId}</TableCell>
                          <TableCell className={classes.bodyContent}>{order.masterReferenceNumber}</TableCell>
                          <TableCell align="left" className={classes.bodyContent}>
                            {order.brands && Array.isArray(order.brands) && order.brands.toString()}
                          </TableCell>
                          <TableCell className={classes.bodyContent}>{parseFloat(getSellingPrice(!!order.estimatedTotal && order.estimatedTotal, isSellingPriceData, markupPercentage)).toFixed(2)}</TableCell>
                          <TableCell className={classes.bodyContent}>{order.createdBy}</TableCell>
                          <TableCell className={classes.bodyContent}>{moment(order.createdOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                          <TableCell className={classes.bodyContent}>{order.status}</TableCell>
                          <TableCell>
                            {showViewEnquiry &&
                              <Tooltip title="View Order Enquiry" placement="top">
                                <IconButton className={classes.actionButton} size="small" onClick={() => {
                                  sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                                    mode: 'view',
                                    masterId: order.masterId
                                  }));
                                  dispatch(setPageNumber(page));
                                  history.push('/viewenquiry')
                                }}>
                                  <AssignmentIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.collapseBg}>
                          <TableCell className="p-0 w-100" colSpan={12}>
                            <Collapse in={open > 0 && open === index + 1 ? true : false}>
                              <Box>
                                <Table aria-label="table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className={classes.subTableHeadSmall}>
                                        {selectedOrder && selectedOrder.status && typeof selectedOrder.status === "string" && selectedOrder.status.toLowerCase() === 'order completed' && (
                                          <span className="orangedot"></span>
                                        )}
                                      </TableCell>
                                      <TableCell className={classes.subTableHead}>
                                        <span className="mx-2 ml-3"></span>
                                        Order No.</TableCell>
                                      <TableCell className={classes.subTableHead}>Reference No.</TableCell>
                                      <TableCell className={classes.subTableHead}>Brand</TableCell>
                                      <TableCell className={classes.subTableHead}>
                                        Total Amount
                                        <div className="text-muted font-10 font-weight-600">(incl. of Vat)</div>
                                      </TableCell>
                                      <TableCell className={classes.subTableHead}>Created By</TableCell>
                                      <TableCell className={classes.subTableHead}>Created On</TableCell>
                                      <TableCell className={classes.subTableHead}>Status</TableCell>
                                      <TableCell className={classes.subTableHead}>Payment Status</TableCell>
                                      <TableCell className={classes.subTableHead}>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {!isEmpty(subEnquiries) && subEnquiries.map((subEnquiry: any) => {
                                      const canShowStatus = isString(subEnquiry.status);
                                      const showViewEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_ORDERS);
                                      return <>
                                        <TableRow key={subEnquiry.orderNumber} className={isReturned ? classes.orangeRowHighlight : classes.subTableBodyContent}>
                                          <TableCell className={classes.subTableBodyContentSmall}>
                                            {subEnquiry.isAirOrder && <span className="ml-2 dot"></span>}
                                            {subEnquiry && subEnquiry.status && typeof subEnquiry.status === 'string' && subEnquiry.status.toLowerCase() === "return created" && <span className="ml-2 orangedot"></span>}
                                          </TableCell>
                                          <TableCell className={classes.subTableBodyContent}>
                                            {subEnquiry.orderNumber ? subEnquiry.orderNumber : subEnquiry.backOrderNumber}
                                          </TableCell>
                                          <TableCell className={classes.subTableBodyContent}>{subEnquiry.enquiryReferenceNumber}</TableCell>
                                          <TableCell className={classes.subTableBodyContent}>{getNewBrand(subEnquiry.make.toString())}</TableCell>
                                          <TableCell className={classes.subTableBodyContent}>{subEnquiry.orderNumber || subEnquiry.backOrderNumber ? 
                                          (parseFloat(getSellingPrice(subEnquiry.estimatedTotal, isSellingPriceData, markupPercentage)).toFixed(2)) : (parseFloat(getSellingPrice(0,isSellingPriceData, markupPercentage)).toFixed(2))}</TableCell>
                                          <TableCell className={classes.subTableBodyContent}>{subEnquiry.userName}</TableCell>
                                          <TableCell className={classes.subTableBodyContent}>{moment(order.orderCreatedon).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                          <TableCell className={classes.subTableBodyContent}>{canShowStatus && subEnquiry.status}</TableCell>
                                          <TableCell className={classes.subTableBodyContent}>{subEnquiry.paymentStatus ? subEnquiry.paymentStatus : "-"}</TableCell>
                                          <TableCell className={classes.subTableBodyContent}>
                                            {((subEnquiry.orderNumber && subEnquiry.orderNumber !== "-") || (subEnquiry.isAirOrder && subEnquiry.backOrderNumber)) && <IconButton onClick={(event: any) => { handleClick(event); setselectedOrder(subEnquiry) }} className={classes.actionButton} size="small">
                                              <MoreVertIcon fontSize="inherit" className={classes.actionButton} />
                                            </IconButton>}
                                          </TableCell>
                                        </TableRow>
                                        {selectedOrder && ((subEnquiry.orderNumber && selectedOrder.orderNumber === subEnquiry.orderNumber) || (subEnquiry.isAirOrder && selectedOrder.backOrderNumber === subEnquiry.backOrderNumber)) &&
                                          <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                          >
                                            {showViewEnquiry && <MenuItem onClick={() => {
                                              dispatch(getOrderId(selectedOrder.backOrderNumber && !selectedOrder.orderNumber ? selectedOrder.backOrderNumber : selectedOrder.orderNumber));
                                              sessionStorage.setItem('isBackOrder', selectedOrder.backOrderNumber && !selectedOrder.orderNumber ? 'true' : '');
                                              sessionStorage.setItem('orderStoredBrand', selectedOrder.make.toString())
                                              sessionStorage.setItem('orderBackNumber', selectedOrder.backOrderNumber)
                                              if (selectedOrder.backOrderNumber || selectedOrder.orderNumber) {
                                                history.push(`/orderdetails`);
                                                dispatch(setPageNumber(page));
                                              } handleClose()
                                            }}>View Order</MenuItem>}
                                            {selectedOrder && selectedOrder.status && typeof selectedOrder.status == "string" &&
                                              <>
                                                {showViewDelivery && viewDeliveryOrderStatuses.includes(selectedOrder.status.toLowerCase()) &&
                                                  <MenuItem onClick={() => {
                                                    dispatch(getDeliveryList(selectedOrder.orderNumber, selectedOrder.enquiryNumber, selectedOrder.make, selectedOrder.backOrderNumber));
                                                    sessionStorage.setItem('selectedOrderMake', selectedOrder.make);
                                                    sessionStorage.setItem('selectedOrderEnquiryNumber', selectedOrder.enquiryNumber);
                                                    sessionStorage.setItem('selectedOrderCreatedOn', moment(order.createdOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'));
                                                    if (deliveryList && deliveryList.err) {
                                                      handleopenCloseSnackbar(true);
                                                    }
                                                    else {
                                                      history.push(`/deliverylist`);
                                                    }
                                                    handleClose()
                                                  }}>View Delivery</MenuItem>
                                                }
                                                {viewReturnRequestStatuses.includes(selectedOrder.status.toLowerCase()) &&
                                                  <MenuItem onClick={() => {
                                                    handleopenCloseReturnRequest(true);
                                                    handleClose();
                                                    dispatch(getOrderId(subEnquiry.backOrderNumber && !subEnquiry.orderNumber ? subEnquiry.backOrderNumber : subEnquiry.orderNumber));
                                                  }}>Return Order</MenuItem>
                                                }
                                                {showViewInvoice && viewViewInvoiceStatuses.includes(selectedOrder.status.toLowerCase()) &&
                                                  <MenuItem onClick={() => { getInvoiceDetails(selectedOrder); handleClose() }}>View Invoice
                                                  </MenuItem>
                                                }
                                              </>
                                            }
                                          </Menu>}
                                      </>
                                    })}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell >
                        </TableRow >
                      </React.Fragment >
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="col-12 d-flex justify-content-end">
              <TablePagination
                className="pagination"
                rowsPerPageOptions={[10]}
                component="div"
                count={orderList && orderList.data && orderList.totalCount ? orderList.totalCount : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderHistory;