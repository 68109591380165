import { useMsal } from "@azure/msal-react";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { OnboardingErrorPage } from "./common/AccountRestricted/OnboardingErrorPage";
import { signOut, useUserDispatch } from "./context/UserContext";
import { logout } from "./store/actions/account.actions";
import { logoutUser } from "./store/actions/logon.actions";

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.
export const ExportLogout = forwardRef((props, ref) => {

  const dispatch = useDispatch();
  const userDispatch = useUserDispatch();
  const history = useHistory();
  const { instance } = useMsal();
  const [alertCount, setAlertCount] = useState(0);
  const [deactiveMsg, setDeactiveMsg] = useState('');

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument

  useImperativeHandle(ref, () => ({
    async callLogout(status?: any) {
      if (!status) {
        if (alertCount === 0) {
          // alert('Your login session has expired, please login again.');
          setAlertCount(alertCount + 1)
        }
        await dispatch(logoutUser());
        await dispatch(logout());
        signOut(userDispatch);
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      } else {
        setDeactiveMsg(status);
        if (!window.location.href.includes('/login'))
          window.location.href = '/login';
      }
    }
  }));

  return <></>;
});