import React, { Fragment, Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';

import CancelDialog from '../../common/components/cancelDialog';
import Snackbar from './../../common/components/Snackbar';
import DatePicker from '../../common/components/dateRangePicker';
import { FULLY_CANCELLEED } from '../../constants/orderEnquiryConstants';
import { get } from "lodash";
import { getEnquiriesList, getOrderEnquiryStatus } from '../../store/actions/orderEnquiry.action';
import { cancelEnquiry, getUniqueEnquirySessionId } from "../../store/actions/createUpdateEnquiry.actions";
import FullscreenLoader from './../../common/components/FullscreenLoader';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import "./orderEnquiry.css";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import NotificationIcon from "../../common/components/NotificationIcon";
import moment from "moment";
import { getNewBrand, getUniqueTimestamp } from "../../common/components/util";
import { ALL_STATUS } from "../../constants/orderHistoryConstants";
import { getSellingPrice } from "../../helpers/commonmethod";

const useStyles = makeStyles((theme) => ({
  collapseBg: {
    background: 'rgba(66, 94, 108, 0.07)'
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    minWidth: 84
  },
  collapseIcon: {
    color: '#000000',
    fontWeight: 500,
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    minWidth: 70
  },
  subTableHead: {
    color: '#444444',
    fontWeight: 800,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    minWidth: 100,
    padding: 10
  },
  actionButton: {
    color: '#1A9FE0',
  },
  subTableBodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10
  },
  createOrderEnqButton: {
    backgroundColor: '#1A9FE0',
    width: 190,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    color: '#FFFFFF',
    borderRadius: 1000,
    height: 35,
    textAlign: 'center',
  },
}));

const OrderEnquiry: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const { enquiryList, enquiryStatus } = useSelector((state: any) => state.orderEnquiry);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedMasterId, setSelectedMasterId] = useState<any>(null);
  const dataOrderRedux: any = useSelector((state: any) => state.orderEnquiry);
  const [searchText, setsearchText] = useState<any>('');
  const [startDate, setstartDate] = useState<any>(null);
  const [endDate, setendDate] = useState<any>(null);
  const [status, setstatus] = useState<any>('');
  const [open, setOpen] = useState(-1);
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);

  // const loadEnquiryList = useCallback((pageNumber?: any) => {
  //   setPage(pageNumber ? pageNumber : 0);
  //   let searchData: any = {}
  //   if (searchText && searchText.trim()) {
  //     searchData["enquiryNumber"] = searchText.trim();
  //     searchData["referenceNumber"] = searchText.trim();
  //   }
  //   if (startDate && endDate) {
  //     searchData["fromDate"] = startDate;
  //     searchData["toDate"] = endDate;
  //   }
  //   if (status) {
  //     searchData["status"] = status;
  //   }
  //   dispatch(getEnquiriesList(searchData, pageNumber));
  // }, [startDate, endDate, searchText, status, dispatch])

  // React.useEffect(() => {
  //   if (searchText === '' && startDate === null && endDate === null) {
  //     loadEnquiryList();
  //     setstartDate(null);
  //     setendDate(null);
  //     setstatus('');
  //   }
  // }, [searchText, startDate, endDate, loadEnquiryList]);

  React.useEffect(() => {
    if (searchText === '' && startDate === null && endDate === null) {
      dispatch(getEnquiriesList({}));
      setstartDate(null);
      setendDate(null);
      setstatus('');
      dispatch(getOrderEnquiryStatus())
    }
  }, [searchText, startDate, endDate]); // eslint-disable-line

  const loadEnquiryList = (pageNumber?: any) => {
    setPage(pageNumber ? pageNumber : 0);
    let searchData: any = {}
    if (searchText && searchText.trim()) {
      searchData["enquiryNumber"] = searchText.trim();
      searchData["referenceNumber"] = searchText.trim();
    }
    if (startDate && endDate) {
      searchData["fromDate"] = startDate;
      searchData["toDate"] = endDate;
    }
    if (status && status !== ALL_STATUS) {
      searchData["status"] = status;
    }
    dispatch(getEnquiriesList(searchData, pageNumber));
  }

  const showCancel = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CANCEL_ENQUIRY) && !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);
  const showViewEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_ENQUIRY_DETAILS);

  const SubEnquiries = (props: { subEnq: any, enquiry: any }) => {
    const { subEnq } = props;

    let estimatedTotal;

    if (!!subEnq.estimatedTotal) {
      if (typeof subEnq.estimatedTotal === "string" && subEnq.estimatedTotal.toLowerCase() !== "nan") {
        estimatedTotal = subEnq.estimatedTotal;
      }
      else {
        estimatedTotal = 0;
      }

      if (typeof subEnq.estimatedTotal === "number") {
        estimatedTotal = subEnq.estimatedTotal;
      }
    }

    return (
      <TableRow>
        <TableCell className={classes.subTableBodyContent}>
          <span className="mx-4 pl-4"></span>
          {getNewBrand(subEnq.make)}
        </TableCell>
        <TableCell className={classes.subTableBodyContent}>{subEnq.enquiryReferenceNumber}</TableCell>
        <TableCell className={classes.subTableBodyContent}>{subEnq.status}</TableCell>
        <TableCell className={classes.subTableBodyContent}>{parseFloat(getSellingPrice(estimatedTotal,isSellingPriceData, markupPercentage)).toFixed(2)}</TableCell>
      </TableRow>
    )
  }

  function EnquiryList(props: { enquiry: any, setSelectedMasterId: any, index: any }) {
    const { enquiry, setSelectedMasterId, index } = props;
    const showCancelButton = enquiry.status && enquiry.status.toLowerCase() !== FULLY_CANCELLEED && enquiry.status.toLowerCase() !== "cancel" && showCancel
    return (
      <React.Fragment>
        <TableRow hover role="checkbox" tabIndex={-1} onClick={() => setOpen(open === index + 1 ? -1 : index + 1)} className="hover-pointer">
          <TableCell className="bodyContent">
            <IconButton aria-label="expand row" size="small" className={classes.collapseIcon}>
              {open > 0 && open === index + 1 ? <RemoveIcon fontSize="inherit" /> : <Add fontSize="inherit" />}
            </IconButton>
          </TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.masterId}</TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.masterReferenceNumber}</TableCell>
          <TableCell align="left" className={classes.bodyContent}>{enquiry.make && Array.isArray(enquiry.make) && enquiry.make.toString()}</TableCell>
          <TableCell className={classes.bodyContent}>{!!enquiry.estimatedTotal && 
          (typeof enquiry.estimatedTotal === 'string' &&  enquiry.estimatedTotal.toLowerCase() !== "nan") ? 
          parseFloat(getSellingPrice(enquiry.estimatedTotal,isSellingPriceData, markupPercentage)).toFixed(2) : (parseFloat(getSellingPrice(0,isSellingPriceData, markupPercentage)).toFixed(2))}</TableCell>
          <TableCell className={classes.bodyContent}>{moment(enquiry.createdOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.createdBy}</TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.status}</TableCell>
          <TableCell className={classes.bodyContent}>
            {showViewEnquiry &&
              <Tooltip title="View Order Enquiry" placement="top">
                <IconButton className={classes.actionButton} size="small" onClick={() => {
                  if ((enquiry.status && enquiry.status.toLowerCase() === 'failed') || (enquiry.status && enquiry.status.toLowerCase() === "enquiry cancelled") || enquiry.status.toLowerCase() === "cancel" || !showCreateChangeEnquiry)
                    history.push('/viewenquiry')
                  else
                    history.push('/updateenquiry')
                  sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                    mode: enquiry.status && enquiry.status.toLowerCase() !== 'failed' && enquiry.status.toLowerCase() !== "enquiry cancelled" && enquiry.status.toLowerCase() !== "cancel" && showCreateChangeEnquiry ? 'update' : 'view',
                    masterId: enquiry.masterId
                  }))
                }}>
                  <OpenInNewOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            }
            {showCancelButton &&
              <Tooltip title="Cancel Order Enquiry" placement="top">
                <IconButton className={classes.actionButton} size="small"
                  onClick={() => {
                    setSelectedMasterId(enquiry.masterId)
                    handleopenCloseCancelDialog(true)
                  }}>
                  <CancelIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            }
          </TableCell>
        </TableRow>
        <TableRow className={classes.collapseBg} hover role="checkbox" tabIndex={-1}>
          <TableCell className="p-0 w-100" colSpan={12}>
            <Collapse in={open > 0 && open === index + 1 ? true : false}>
              <Box>
                <Table aria-label="table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${classes.subTableHead} pr-0`}>
                        <span className="mx-4 pl-4"></span>
                        Brand</TableCell>
                      <TableCell className={`${classes.subTableHead}`}>Reference No.</TableCell>
                      <TableCell className={classes.subTableHead}>Status</TableCell>
                      <TableCell className={classes.subTableHead}>Estimated Total (incl. of Vat)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {enquiry.subEnquiries && enquiry.subEnquiries.map((subEnq: any) => {
                      return (
                        <SubEnquiries key={subEnq.enquiryNumber} subEnq={subEnq} enquiry={enquiry} />
                      );
                    }
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseCancelDialog = (open?: any) => {
    setOpenCancelDialog(open ? true : false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    loadEnquiryList(newPage)
    setPage(newPage);
  };

  const showCreateChangeEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);

  return (
    <Fragment>
      {dataOrderRedux && dataOrderRedux.enquiryList && dataOrderRedux.enquiryList.loading && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Order Enquiry
        </h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="cardCommon p-3">
            <div className="d-flex align-items-center">
              <h5 className="page-sub-title mb-2">Search Enquiry</h5>
            </div>
            <TextField
              value={searchText}
              onChange={(e) => {
                setsearchText(e.target.value)
                // if (!e.target.value) {
                //   loadEnquiryList()
                // }
              }}
              size="small"
              className="col-4 col-sm-6 col-md-4 col-lg-4 common-input-font-size commonRoundedInputs searchField" label="Search by Order Enquiry No/Reference No" variant="outlined" />
            <div className="col-4 col-sm-12 col-md-4 col-lg-4 d-inline-flex date-picker-50">
              <DatePicker
                startDate={startDate}
                endDate={endDate}
                onChange={(start: any, end: any) => {
                  setstartDate(start);
                  setendDate(end);
                }}
              />
            </div>
            <div className="col-2 col-sm-6 col-md-2 col-lg-2  d-inline-flex pl-0  ">
              <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                <Select

                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={status}
                  onChange={(e) => {
                    setstatus(e.target.value)
                  }}
                  label="Status"
                >
                  <MenuItem key={ALL_STATUS}
                    value={ALL_STATUS}>All</MenuItem>
                  {enquiryStatus && enquiryStatus.data && enquiryStatus.data.data && enquiryStatus.data.data.length > 0 && enquiryStatus.data.data.map((status: any, i: any) => (
                    <MenuItem key={status}
                      value={status}>{status}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button
              className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton"
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                loadEnquiryList()
              }}
            >Search
            </Button>
          </div>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        text="Order Enquiry has been cancelled successfully"
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }}
      />

      <CancelDialog
        open={openCancelDialog}
        text="Are you sure to cancel this Order Enquiry ?"
        handleopenClose={async (data: any) => {
          if (data) {
            await dispatch(cancelEnquiry(selectedMasterId));
            dispatch(getEnquiriesList({}));
            setSelectedMasterId(null)
            handleopenCloseSnackbar(true)
          }
          handleopenCloseCancelDialog(false)
        }}
      />

      <div className="row mt-4">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex align-items-center mb-2">
            <h5 className="page-sub-title mt-2">List of Order Enquiries</h5>
            {showCreateChangeEnquiry &&
              <Button
                variant="contained"
                color="primary"
                className="ml-auto rounded-pill"
                startIcon={<AddIcon />}
                onClick={() => {
                  dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                  history.push('/createenquiry');
                }}>
                Create Order Enquiry
              </Button>
            }
          </div>

          <div className="cardCommon mh-300p">
            <TableContainer className={`classes.container`}>
              <Table aria-label="table">
                <TableHead className="w-100">
                  <TableRow>
                    <TableCell className={classes.tableHead}></TableCell>
                    <TableCell className={classes.tableHead}>Order Enquiry No.</TableCell>
                    <TableCell className={classes.tableHead}>Reference No.</TableCell>
                    <TableCell className={classes.tableHead}>Brand</TableCell>
                    <TableCell>
                      <h6 className="tableHead">Estimated Total</h6>
                      <h6 className="vat">(incl.of VAT)</h6>
                    </TableCell>
                    <TableCell className={classes.tableHead}>Created On</TableCell>
                    <TableCell className={classes.tableHead}>Created By</TableCell>
                    <TableCell className={classes.tableHead}>Status</TableCell>
                    <TableCell className={classes.tableHead}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enquiryList && enquiryList.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data && enquiryList.data.data.length > 0 && (
                    <>
                      {enquiryList.data.data.map((enquiry: any, index: any) => {
                        return (
                          <EnquiryList key={index} index={index} enquiry={enquiry} setSelectedMasterId={setSelectedMasterId} />
                        );
                      })}
                    </>
                  )}

                </TableBody>
              </Table>
              {((enquiryList && enquiryList.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data && enquiryList.data.data.length === 0) || (enquiryList && enquiryList.data && enquiryList && !enquiryList.data.data)) && (
                <div className="mt-5 justify-content-center mx-auto text-center">No Enquiries Found.</div>
              )}
            </TableContainer>
            <div className="col-12 d-flex justify-content-end">
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={enquiryList && enquiryList && enquiryList.totalCount ? enquiryList.totalCount : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderEnquiry;
