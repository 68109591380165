
import { ORDER_HISTORY } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { orderHistoryService } from "../../services/orderHistory.service";
import { searchProducts } from "../../store/actions/products.actions";

export const orderHistoryActions = {
  getOrdersList,
  getOrderHistoryDetails,
  createReturnRequest,
  getOrderId
};

export function getOrdersList(filterParams: any, page?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_HISTORY.GET_ORDER_HISTORY_LIST_FETCHING));
    try {
      // const response = orderList.data;
      const response = await orderHistoryService.getOrdersList(filterParams, page);
      dispatch(apiSuccess(ORDER_HISTORY.GET_ORDER_HISTORY_LIST_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(ORDER_HISTORY.GET_ORDER_HISTORY_LIST_ERROR, error));
      return error;
    }
  };
}

export function getPartList(partnumber: any, materialGroups?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_HISTORY.GET_PARTS_HISTORY_LIST_FETCHING));
    try {
      let response = null;
      let apiData: any = await dispatch(searchProducts([partnumber], null, null, null, [partnumber], null));
      if (apiData && apiData.data && Array.isArray(apiData.data) && apiData.data.length > 0) {
        let productFetched = apiData.data[0];
        let fetchedMaterialGroup = productFetched.attributes.materialGroup.value;
        let group = fetchedMaterialGroup.substring(0, 5).toUpperCase()
        let brand = materialGroups[group];
        if (brand) {
          response = await orderHistoryService.getPartList(partnumber, brand);
        }
      }
      dispatch(apiSuccess(ORDER_HISTORY.GET_PARTS_HISTORY_LIST_SUCCESS, response && response.data));
      return response || [];
    } catch (error) {
      dispatch(apiError(ORDER_HISTORY.GET_PARTS_HISTORY_LIST_ERROR, error));
      return error;
    }
  };
}

export function getOrderHistoryDetails(id: any, backOrder?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_HISTORY.GET_ORDER_HISTORY_DETAILS_FETCHING));
    try {
      const response = await orderHistoryService.getOrdersDetails(id, backOrder);
      dispatch(apiSuccess(ORDER_HISTORY.GET_ORDER_HISTORY_DETAILS_SUCCESS, response));
      return response || {};
    } catch (error) {
      dispatch(apiError(ORDER_HISTORY.GET_ORDER_HISTORY_DETAILS_ERROR, error));
      return error;
    }
  };
}

export function createReturnRequest(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_HISTORY.CREATE_RETURN_REQUEST_FETCHING));
    try {
      const response = await orderHistoryService.returnRequest(data);
      dispatch(apiSuccess(ORDER_HISTORY.CREATE_RETURN_REQUEST_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(ORDER_HISTORY.CREATE_RETURN_REQUEST_ERROR, error));
      return error;
    }
  };
}

export function getOrderId(id: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_HISTORY.GET_ORDER_ID, id));
  }
}

export function resetInvoice() {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_HISTORY.RESET_INVOICE));
  }
}

export function getInvoice(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_HISTORY.GET_INVOICE_FETCHING));
    const response = await orderHistoryService.getInvoiceDetails(data);
    if (response.error) {
      dispatch(apiError(ORDER_HISTORY.GET_INVOICE_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ORDER_HISTORY.GET_INVOICE_SUCCESS, response));
      return response || [];
    }
  }
}

export function getOrderStatus() {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_HISTORY.GET_ORDER_STATUS_FETCHING));
    const response = await orderHistoryService.getOrderStatus();
    if (response.error) {
      dispatch(apiError(ORDER_HISTORY.GET_ORDER_STATUS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ORDER_HISTORY.GET_ORDER_STATUS_SUCCESS, response));
      return response || [];
    }
  }
}

export function setPageNumber(page: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_HISTORY.SET_PAGE_NUMBER, page));
  }
}
