import { IActionBase, INotificationState } from "../models/root.interface";
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "../actions/notifications.action";
import { NOTIFICATIONS } from "../actions/actionsTypes";

const initialState: INotificationState = {
  notifications: [],
  notificationsList: null,
  markAsRead: null,
  addNotification: null,
};

function notificationReducer(state: INotificationState = initialState, action: IActionBase): INotificationState {
  switch (action.type) {
    case NOTIFICATIONS.GET_ALL_NOTIFICATIONS_FETCHING:
      return {
        ...state,
        notificationsList: { loading: true, data: null, err: null },
      };
    case NOTIFICATIONS.GET_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsList: { loading: false, data: action.payload.data.data, err: null }
      };
    case NOTIFICATIONS.GET_ALL_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notificationsList: { loading: false, data: null, err: action.payload.error }
      };
    case NOTIFICATIONS.MARK_NOTIFICATIONS_READ:
      return {
        ...state,
        markAsRead: { loading: true, data: null, err: null },
      };
    case NOTIFICATIONS.MARK_NOTIFICATIONS_READ_SUCCESS:
      return {
        ...state,
        markAsRead: { loading: false, data: action.payload.data, err: null }
      };
    case NOTIFICATIONS.MARK_NOTIFICATIONS_READ_ERROR:
      return {
        ...state,
        markAsRead: { loading: false, data: null, err: action.payload.error }
      };
    case NOTIFICATIONS.ADD_NOTIFICATION:
      return {
        ...state,
        addNotification: { loading: true, data: null, err: null },
      };
    case NOTIFICATIONS.ADD_NOTIFICATION_ERROR:
      return {
        ...state,
        addNotification: { loading: false, data: null, err: action.payload.error }
      };
    case NOTIFICATIONS.ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addNotification: { loading: false, data: action.payload.data, err: null }
      };
    case ADD_NOTIFICATION: {
      let maxId: number = Math.max.apply(Math, state.notifications.map(o => o.id));
      if (maxId === -Infinity) { maxId = 0; }
      let newItem = {
        id: maxId + 1,
        date: new Date(),
        title: action.title,
        text: action.text,
        error: action.error
      };
      return { ...state, notifications: [...state.notifications, newItem] };
    }
    case REMOVE_NOTIFICATION: {
      return {
        ...state, notifications: state.notifications
          .filter(Notification => Notification.id !== action.id)
      };
    }
    default:
      return state;
  }
}


export default notificationReducer;