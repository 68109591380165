import React, { Fragment, Dispatch, useEffect, useState } from "react";
import "./leftMenu.css";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import HistoryIcon from "@material-ui/icons/History";
import logoicon from "../../assets/mainLogoBlue.jpg";
import tempKamLogo from "./afg.jpeg"
import smallLogoicon from "../../assets/smallLogoBlue.png";
import dealericon from "../../assets/dealerImage.png";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SettingsIcon from "@material-ui/icons/Settings";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import EmojiEventsOutlinedIcon from "@material-ui/icons/EmojiEventsOutlined";
import PersonIcon from "@material-ui/icons/Person";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { logout } from "../../store/actions/account.actions";
import { openSideMenu } from "../../store/actions/global.actions";
import { useDispatch, useSelector } from "react-redux";
import { signOut, useUserDispatch } from "../../context/UserContext.js";
import { useHistory } from "react-router-dom";
import LocalMallIcon from '@material-ui/icons/LocalMall';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import RoomIcon from '@material-ui/icons/Room';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useMsal } from "@azure/msal-react";
import { userAccessControl } from "./UserAccessControl";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import LogoutDialog from "./logoutDialog";
import { getProfile, isSellingPrice } from "../../store/actions/profile.action";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";
import { userTypes } from "../../constants/userTypes";
import { useCallback } from "react";
import { logoutUser } from "../../store/actions/logon.actions";
import { getUniqueEnquirySessionId } from "../../store/actions/createUpdateEnquiry.actions";
import { getUniqueTimestamp } from "../../common/components/util";
import _ from "lodash";
import { getTermsAndConditions } from "../../store/actions/termsAndConditions.action";
import { profileservice } from "../../services/profile.service";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import { Button } from "@material-ui/core";
import { withStyles, Theme, createStyles, makeStyles, createTheme } from '@material-ui/core/styles';
import { getAllCreditRequestStatus } from "../../store/actions/dealer.action";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { EmojiEventsOutlined, PeopleAlt, ViewStreamOutlined } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  toggle: {
    marginLeft: 5,
    width: "115px",
  },
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 150,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 11,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Purchase Price"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"Selling Price"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(122px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

const LeftMenu: React.FC = () => {
  const [showText, setShowText] = React.useState<any>(false);

  const classes = useStyles()
  const [currentUrl, setCurrentUrl] = React.useState<any>(window.location.href);
  const [menuItems, setMenuItems] = React.useState<any>([]);
  const [displayPrice, setDisplayPrice] = React.useState<any>([]);
  const [showAchievements, isShowAchievements] = React.useState<any>(false);
  const [adminMenuItems, setAdminMenuItems] = React.useState<any>([]);
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const userDispatch = useUserDispatch();
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  const { isAccessAchievement }: any = useSelector((state: any) => state.achievement);
  const isAdmin: any = useSelector((state: any) => state.global && state.global.isAdmin);
  const [openDeactivatedDialog, setOpenDeactivatedDialog] = React.useState<any>('');
  const [openTAndCDialog, setOpenTAndCDialog] = React.useState(false);
  const [openLogoutDialog, setOpenLogoutDialog] = React.useState(false);
  const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const [userConfirmation, setUserConfirmation] = React.useState<any>(false);
  const { instance } = useMsal();

  useEffect(() => {
    if (!isAdmin) {
      userAccessControl(profileDetails);
    }
  }, [isAdmin, profileDetails])

  const restrictedSideMenuItems = sessionStorage.getItem('restrictFeat');
  const showCreateChangeEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);

  let sideMenuItems = [
    {
      featureCode: "ACHIEVEMENTS",
      icon: <EmojiEventsOutlined fontSize="inherit" />,
      name: "Achievements",
      route: "achievements",
    },
    {
      featureCode: "DASHBOARD",
      icon: <DashboardOutlinedIcon fontSize="inherit" />,
      name: "Dashboard",
      route: "dashboard",
    },
    {
      featureCode: "ENQ_LIST",
      icon: <AssignmentOutlinedIcon fontSize="inherit" />,
      name: "Order Enquiry",
      route: "orderenquiry",
    },
    {
      featureCode: "ORD_LIST",
      icon: <LocalMallOutlinedIcon fontSize="inherit" />,
      name: "Order History",
      route: "orderhistory",
    },
    {
      featureCode: "LIST_FAVORIT",
      icon: <FavoriteBorderIcon fontSize="inherit" />,
      name: "Favorites",
      route: "favorites",
    },
    {
      featureCode: "FIND_SUPERSESSION",
      icon: <HistoryIcon fontSize="inherit" />,
      name: "Supersession",
      route: "partshistory",
    },
    {
      featureCode: "SPECIAL_OFFERS",
      icon: <LocalOfferIcon fontSize="inherit" />,
      name: "Special Offers",
      route: "specialofferdetail",
    },
    {
      featureCode: "ORG_SETTINGS",
      icon: <SettingsIcon fontSize="inherit" />,
      name: "Org. Setting",
      route: "orgsetting",
    },
    {
      featureCode: "MY_PROFILE",
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "profile",
    },
    {
      featureCode: "LOGOUT",
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  let onBoardMenuItems = [
    {
      featureCode: "ORG_SETTINGS",
      icon: <SettingsIcon fontSize="inherit" />,
      name: "Complete Registration",
      route: "onboarding",
    },
    {
      featureCode: "SHIP_TO_PARTY",
      icon: <RoomIcon fontSize="inherit" />,
      name: "Ship to Party",
      route: "shiptoparty",
    },
    {
      featureCode: "MY_PROFILE",
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "profile",
    },
    {
      featureCode: "LOGOUT",
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  // USER ADDED BY KAM FOR CONFIRMATION OF INFO
  let onBoardMenuItemsForUserConfirmation = [
    {
      featureCode: "ORG_SETTINGS",
      icon: <SettingsIcon fontSize="inherit" />,
      name: "Complete Registration",
      route: "onboarding",
    },
    {
      featureCode: "MY_PROFILE",
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "profile",
    },
    {
      featureCode: "LOGOUT",
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  let creditRequestMenuItems = [

    {
      featureCode: "ORD_LIST",
      icon: <LocalMallIcon fontSize="inherit" />,
      name: "Credit Request",
      route: "creditteam",
    },
    {
      featureCode: "MY_PROFILE",
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "adminprofile",
    },
    {
      featureCode: "LOGOUT",
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  // SUPER ADMIN LEFT - MENU ITEMS 
  let superAdminMenuItems = [
    {
      featureCode: "PLAN_FEATURES",
      icon: <PersonOutlineIcon fontSize="inherit" />,
      name: "Plan Features",
      route: "planfeatures",
    },
    {
      featureCode: "SET_TARGET",
      icon: <img src="./settarget.svg" />,
      selectedIcon: <img src="./settargetinfo.svg" />,
      name: "Set Target",
      route: "settarget",
    },
    {
      featureCode: "DEALERS",
      icon: <PeopleAlt fontSize="inherit" />,
      name: "Dealers",
      route: "superadmindealers",
    },
    {
      featureCode: "MY_PROFILE",
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "adminprofile",
    },
    {
      featureCode: "LOGOUT",
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  if (restrictedSideMenuItems) {
    sideMenuItems = sideMenuItems.filter((item: any) => {
      return !restrictedSideMenuItems?.includes(item.featureCode)
    })
  }

  const adminSideMenuItems = [
    {
      icon: <DashboardOutlinedIcon fontSize="inherit" />,
      name: "Dashboard",
      route: "dashboard",
    },
    {
      icon: <PersonOutlineIcon fontSize="inherit" />,
      name: "Dealers",
      route: "dealers",
    },
    {
      icon: <CreditCardIcon fontSize="inherit" />,
      name: "Credit Request",
      route: "creditlist",
    },
    {
      icon: <LocalOfferIcon fontSize="inherit" />,
      name: "Special Offers",
      route: "adminspecialoffers",
    },
    {
      icon: <PersonIcon fontSize="inherit" />,
      name: "My Profile",
      route: "adminprofile",
    },
    {
      icon: <ExitToAppIcon fontSize="inherit" />,
      name: "Logout",
    },
  ];

  const callLogout = async () => {
    await dispatch(logoutUser());
    await dispatch(logout());
    signOut(userDispatch);
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  }

  const loadProfileDetails = useCallback(() => {
    const b2cUid = sessionStorage.getItem('userId');
    const userType = sessionStorage.getItem('userType');
    if (b2cUid !== "undefined" && b2cUid !== null && userType !== 'undefined' && userType !== null) {
      dispatch(getProfile(b2cUid, userType));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTermsAndConditions());
  }, []);

  useEffect(() => {
    history.listen(() => {
      setCurrentUrl(window.location.href);
    })
  }, [history]);

  useEffect(() => {
    const userType = sessionStorage.getItem('userType');
    if (currentUrl) {
      if ((((currentUrl.includes('onboarding')) || (currentUrl.includes('shiptoparty')) || (currentUrl.includes('profile') && menuItems && menuItems.length === 4)) && !isKamOnboarded)) {
        setMenuItems(onBoardMenuItems);
      } else if ((currentUrl.includes('onboarding')) || (currentUrl.includes('profile') && menuItems && menuItems.length === 3) && isKamOnboarded) {
        setMenuItems(onBoardMenuItemsForUserConfirmation);
      } else if ((currentUrl.includes('creditteam')) || (currentUrl.includes('creditteamdetails')) || (currentUrl.includes('profile') && adminMenuItems && adminMenuItems.length === 3)) {
        setAdminMenuItems(creditRequestMenuItems);
      } else if ((currentUrl.includes('planfeatures')) || (currentUrl.includes('settarget')) || (currentUrl.includes('superadmindealers')) || (currentUrl.includes('profile') && adminMenuItems && adminMenuItems.length === 5)) {
        setAdminMenuItems(superAdminMenuItems);
      } else {
        setMenuItems(sideMenuItems);
      }
    }
  }, [currentUrl]);

  useEffect(() => {
    if (!profileDetails)
      loadProfileDetails();
  }, [profileDetails, loadProfileDetails]);

  let dealerCountry;
  let dealerLogo;
  let customerType: any;
  let companyName;
  let userName;
  let userType: any;
  let region;
  let isKamOnboarded: any;
  let percentageForSellingPrice: any;
  let markupPercentage: any;
  let featureAccessibility: any;

  // dealerCountry = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.addresses && profileDetails.data.data.customerInfo.addresses.length > 0 && profileDetails.data.data.customerInfo.addresses.split(',')[0]) || "";
  dealerLogo = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.logo) || "";
  customerType = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.customerType) || "";
  companyName = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.companyName) || "";
  userName = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.firstName + " " + profileDetails.data.data.basicInfo.lastName) || "";
  region = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.region) || '';
  userType = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.userType) || "";
  isKamOnboarded = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.isAddedByKam) || false;
  percentageForSellingPrice = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.percentageForSellingPriceApply) || false;
  markupPercentage = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.markupPercentage) || false;
  featureAccessibility = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.accessParameter && profileDetails.data.data.accessParameter.featureAccessibility);

  useEffect(() => {
    if (isAccessAchievement && isAccessAchievement.data && isAccessAchievement.data.accessToViewAchievementModule && isAccessAchievement.data.isTierFound && isAccessAchievement.data.customerType === 'dealer') {
      isShowAchievements(true)
    } else {
      isShowAchievements(false)
    }
  }, [isAccessAchievement]);

  useEffect(() => {
    if (featureAccessibility && featureAccessibility.length > 0) {
      isDisplaySellingPrice(featureAccessibility);
    }
  }, [featureAccessibility]);

  useEffect(() => {
    if (isAdmin) {
      if (userType === "CREDIT_CONTROL_MANAGER") {
        setAdminMenuItems(creditRequestMenuItems);
      } else if (userType === "AFG_SUPER_ADMIN") {
        setAdminMenuItems(superAdminMenuItems);
      } else {
        setAdminMenuItems(adminSideMenuItems);
      }
    }
  }, [userType, isAdmin]);

  const handleopenClose = (open?: any) => {
    setOpenLogoutDialog(open ? true : false);
  };

  const handleOpenCloseTAndC = (open?: any) => {
    setOpenTAndCDialog(open ? true : false);
  };

  const isDisplaySellingPrice = (accessParam: any) => {
    if (accessParam && accessParam.length > 0) {
      let data = accessParam.filter((param: any) => param.featureCode === 'DISPLAY_SELLING_PRICE');
      if (data && data.length > 0 && data[0].value === true) {
        dispatch(isSellingPrice(false))
      }
      setDisplayPrice(data);
    }
  }

  const ENQ_LIST = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.ENQ_LIST);

  return (
    <Fragment>
      {(menuItems && menuItems.length === 0) && (adminMenuItems && adminMenuItems.length === 0) && <FullscreenLoader />}
      {
        <div className={leftMenuVisibility ? "leftMenuGlobal" : "collasped-menu"}>
          <Drawer
            variant={leftMenuVisibility ? "persistent" : "temporary"}
            anchor={"left"}
            open={leftMenuVisibility}
          >
            <div className="px-2 my-3 align-items-center">
              <IconButton
                onClick={() => {
                  dispatch(openSideMenu(false));
                }}
              >
                <MenuIcon />
              </IconButton>
              <img src={logoicon} onClick={() => {
                let userRole: any = _.get(profileDetails, "data.data.basicInfo.userType", '')
                if (userRole && userRole.toLowerCase() == "guestadmin") {
                  history.push('/onboarding')
                } else if (userRole === "CREDIT_CONTROL_MANAGER") {
                  history.push('/creditteam')
                } else if (userRole === "AFG_SUPER_ADMIN") {
                  history.push('/planfeatures')
                } else {
                  history.push('/dashboard')
                }
              }} alt="Logo" className="h-40 ml-2 " style={{ cursor: 'pointer' }} />
            </div>
            <div className="media bg-primary p-4">
              <img className="mr-3 rounded-circle hw-64" src={(userType === userTypes.kam || userType === userTypes.fse || userType === "AFG_SUPER_ADMIN") ? tempKamLogo : dealerLogo ? dealerLogo : dealericon} alt="Logo Client" />
              <div className="media-body">
                {userType === userTypes.kam || userType === userTypes.fse || userType === "AFG_SUPER_ADMIN" ?
                  <>
                    <p className="clientNameSidebar d-flex align-items-center ml-1">
                      &nbsp;{userName}
                    </p>
                    <p className="clientNameSidebar d-flex align-items-center ml-1">
                      {Array.isArray(region) ? (
                        <>
                          &nbsp;{region && Array.isArray(region) && region.length === 0 ? '' :
                            region && Array.isArray(region) && region.length > 0 && region.map((value: any) => value).join(", ")
                          }
                        </>
                      ) : (
                        <>
                          {region && typeof region === 'string' ? region : ''}
                        </>)}
                    </p>
                    <p className="companyLocationSidebar ml-2 mb-1">{userType === "AFG_SUPER_ADMIN" ? 'Super Admin' : userType}</p> </> : <><p className="companyNameSidebar ml-1 pl-1 mb-1">{companyName}</p>
                    <p className="companyLocationSidebar ml-2 mb-1">{userType === 'CREDIT_CONTROL_MANAGER' ? 'Credit Control Manager' : userType === "AFG_SUPER_ADMIN" ? 'Super Admin' : userType} {dealerCountry}</p> <p className="clientNameSidebar d-flex align-items-center ml-1">
                      <PersonOutlineIcon fontSize="inherit" />
                      &nbsp;{userName}
                    </p>
                    {/* <p className="companyLocationSidebar ml-2 mb-1">{userType === 'SUPER_ADMIN' ? 'Super Admin' : userType} {dealerCountry}</p> <p className="clientNameSidebar d-flex align-items-center ml-1">
                    <PersonOutlineIcon fontSize="inherit" />
                    &nbsp;{userName}
                  </p> */}
                  </>}
              </div>
            </div>
            <Dialog
              open={openDeactivatedDialog ? true : false}
              onClose={() => {
                setOpenDeactivatedDialog('')
                callLogout()
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}>

              <DialogContent>
                <div className={classes.icon}>
                  <WarningIcon className="delete-warning" fontSize="small" />
                </div>
                <div>
                  <DialogContentText id="alert-dialog-description">
                    <div className="mt-2">
                      <h5 className="submit-dialog-content">Your account has been deactivated</h5>
                      <p >Reason: {openDeactivatedDialog}</p>
                    </div>
                  </DialogContentText>
                </div>
              </DialogContent>
              <DialogActions className="mt-3 mb-3 justify-content-end">
                <Button variant="contained"
                  color="secondary" className={`createFavourite ${classes.createButton}`}
                  onClick={() => {
                    setOpenDeactivatedDialog('')
                    callLogout()
                  }}>Logout
                </Button>
              </DialogActions>
            </Dialog>
            <div className="w-100 p-3 left-nav">
              {!isAdmin ? menuItems.map((item: any, index: any) => {
                return (
                  <>
                    {item.route === 'achievements' ? (
                      <>
                        {showAchievements && (
                          <ListItem
                            onClick={async () => {
                              // alert(item.route)
                              if (item.name.toLowerCase() === "logout") {
                                handleopenClose(true);
                              } else {
                                if (item.route) {
                                  if (userType.toLowerCase().includes('admin') || userType.toLowerCase().includes('user')) {
                                    let data = await profileservice.profileStatus(sapCustomerId)
                                    if (data.data && !data.data.isActive) {
                                      setOpenDeactivatedDialog(data.data.deactivationReason)
                                    }
                                  }
                                  history.push(item.route);
                                }
                              }
                              setCurrentUrl(window.location.href);
                            }}
                            button
                            key={item.name}
                            className={currentUrl && currentUrl.includes(item.route) ? "active-menu" : ""}
                          >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.name} />
                            {item.route === 'orderenquiry' && showCreateChangeEnquiry &&
                              <ListItemIcon>
                                <IconButton className="p-1 ml-auto toast-header" edge="end" aria-label="add" onClick={(e) => { e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp())); history.push('/createenquiry'); }}>
                                  <AddCircleIcon fontSize="medium" />
                                </IconButton>
                              </ListItemIcon>
                            }
                          </ListItem>
                        )}
                      </>
                    ) : (
                      < ListItem
                        onClick={async () => {
                          // alert(item.route)
                          if (item.name.toLowerCase() === "logout") {
                            handleopenClose(true);
                          } else {
                            if (item.route) {
                              if (userType.toLowerCase().includes('admin') || userType.toLowerCase().includes('user')) {
                                let data = await profileservice.profileStatus(sapCustomerId)
                                if (data.data && !data.data.isActive) {
                                  setOpenDeactivatedDialog(data.data.deactivationReason)
                                }
                              }
                              history.push(item.route);
                            }
                          }
                          setCurrentUrl(window.location.href);
                        }}
                        button
                        key={item.name}
                        className={currentUrl && currentUrl.includes(item.route) ? "active-menu" : ""}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                        {item.route === 'orderenquiry' && showCreateChangeEnquiry &&
                          <ListItemIcon>
                            <IconButton className="p-1 ml-auto toast-header" edge="end" aria-label="add" onClick={(e) => { e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp())); history.push('/createenquiry'); }}>
                              <AddCircleIcon fontSize="medium" />
                            </IconButton>
                          </ListItemIcon>
                        }
                      </ListItem>
                    )}
                  </>
                );
              }) : adminMenuItems.map((item: any, index: any) => {
                return (
                  <ListItem
                    onClick={async () => {
                      if (item.name.toLowerCase() === "logout") {
                        handleopenClose(true);
                      } else {
                        if (item.route) {
                          if (item.route === "creditlist") {
                            dispatch(getAllCreditRequestStatus('kam'));
                          }
                          history.push(item.route);
                        }
                      }
                      setCurrentUrl(window.location.href);
                    }}
                    button
                    key={item.name}
                    className={currentUrl && currentUrl.includes(item.route) ? "active-menu" : ""}
                  >
                    <ListItemIcon>{currentUrl && currentUrl.includes(item.route) ? item.selectedIcon ? item.selectedIcon : item.icon : item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} />
                    {item.route === 'dealers' &&
                      <ListItemIcon hidden>
                        <IconButton className="p-0 m-1 toast-header" edge="end" aria-label="add">
                          <AddCircleIcon fontSize="medium" />
                        </IconButton>
                      </ListItemIcon>
                    }
                  </ListItem>
                );
              })}
              {!isAdmin && (
                <div className="font-12 mt-2">
                  {markupPercentage && parseFloat(markupPercentage) > 0 && displayPrice && displayPrice.length > 0 && displayPrice[0].value === false && percentageForSellingPrice && (
                    <Typography component="div" className="ml-5 mb-4">
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>
                          <AntSwitch
                            checked={isSellingPriceData}
                            onChange={(e) => {
                              dispatch(isSellingPrice(e.target.checked));
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Typography>
                  )}
                </div>
              )}
              <div className="font-12 mt-2 copyright">
                <span onClick={(event) => event.preventDefault()} style={{ cursor: 'pointer' }} className="text-muted mr-2">Support</span> |  <span onClick={() => handleOpenCloseTAndC(true)} style={{ cursor: 'pointer' }} className="text-muted ml-2">T &amp; C</span>
                <div className="text-muted mt-1">© 2021 Al-Futtaim. All rights reserved</div>
              </div>
            </div>
            <TermsAndConditionsDialog
              open={openTAndCDialog}
              handleopenClose={() => {
                handleOpenCloseTAndC(false)
              }}
            />
          </Drawer>
          {!leftMenuVisibility && (
            <>
              <div className="px-2 mt-3 align-items-center text-center">
                <IconButton
                  onClick={() => {
                    dispatch(openSideMenu(true));
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </div>
              <div className="pt-3 pb-4 text-center">
                <img src={smallLogoicon} alt="Logo" className="h-28" />
              </div>
              <div className="media bg-primary py-3 px-1">
                <img className="rounded-circle hw-45 ml-2" src={(userType === userTypes.kam || userType === userTypes.fse) ? tempKamLogo : dealerLogo ? dealerLogo : dealericon} alt="Logo Client" />
              </div>
              <div className="w-100 p-2 left-nav">
                {!isAdmin ? menuItems.map((item: any, index: any) => {
                  return (
                    <>
                      {item.route === 'achievements' ? (
                        <>
                          {showAchievements && (
                            <Tooltip title={item.name} placement="right">
                              <ListItem
                                onClick={async () => {
                                  if (item.name.toLowerCase() === "logout") {
                                    handleopenClose(true);
                                  } else {
                                    if (item.route) {
                                      history.push(item.route);
                                    }
                                  }
                                  setCurrentUrl(window.location.href);
                                }}
                                button
                                key={item.name}
                                className={currentUrl && currentUrl.includes(item.route) ? "active-menu mb-2" : "mb-2"}
                              >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                              </ListItem>
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        <Tooltip title={item.name} placement="right">
                          <ListItem
                            onClick={async () => {
                              if (item.name.toLowerCase() === "logout") {
                                handleopenClose(true);
                              } else {
                                if (item.route) {
                                  history.push(item.route);
                                }
                              }
                              setCurrentUrl(window.location.href);
                            }}
                            button
                            key={item.name}
                            className={currentUrl && currentUrl.includes(item.route) ? "active-menu mb-2" : "mb-2"}
                          >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                          </ListItem>
                        </Tooltip>
                      )}
                    </>
                  );
                }) : adminMenuItems.map((item: any, index: any) => {
                  return (
                    <Tooltip title={item.name} placement="right">
                      <ListItem
                        onClick={async () => {
                          if (item.name.toLowerCase() === "logout") {
                            handleopenClose(true);
                          } else {
                            if (item.route) {
                              history.push(item.route);
                            }
                          }
                          setCurrentUrl(window.location.href);
                        }}
                        button
                        key={item.name}
                        className={currentUrl && currentUrl.includes(item.route) ? "active-menu mb-2" : "mb-2"}
                      >
                        <ListItemIcon>{currentUrl && currentUrl.includes(item.route) ? item.selectedIcon ? item.selectedIcon : item.icon : item.icon}</ListItemIcon>
                        {item.route === 'dealers' &&
                          <ListItemIcon hidden>
                            <IconButton className="p-0 m-1 toast-header" edge="end" aria-label="add">
                              <AddCircleIcon fontSize="medium" />
                            </IconButton>
                          </ListItemIcon>
                        }
                      </ListItem>
                    </Tooltip>
                  );
                })}
              </div>
            </>
          )}
        </div>
      }
      <LogoutDialog
        open={openLogoutDialog}
        text="Are you sure to logout?"
        handleopenClose={() => {
          handleopenClose(false)
        }} />
    </Fragment >
  );
};

export default LeftMenu;