import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const tasksService = {
    getTasks,
    closeTasks
};

async function getTasks(page?:any) {
    const apiPath = API_ENDPOINTS.TASKS + `/${sessionStorage.getItem('webApplicationLoginId')}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {
        },
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 4 : 0}|no-of-records=4`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function closeTasks(id:any) {
    const apiPath = API_ENDPOINTS.TASKS;
    const options = {
        path: apiPath,
        method: "PUT",
        bodyObj: {
            "id": id,
            'webApplicationLoginId':sessionStorage.getItem('webApplicationLoginId')
        },
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}
