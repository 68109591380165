import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Close';
import DealerDetails from './DealerDetails';
import UploadedDocuments from './UploadedDocuments';
import DealerAdminDetails from './DealerAdminDetails';

export interface DealerDetailsDialogProps {
    open: boolean;
    selectedTabValue: number;
    onClose: (value: boolean) => void;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`dealerdetail-tabpanel-${index}`}
            aria-labelledby={`dealerdetail-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

function allTabProps(index: any) {
    return {
        id: `dealerdetail-tab-${index}`,
        'aria-controls': `dealerdetail-tabpanel-${index}`,
    };
}

const DealerDetailsDialog: React.FC<DealerDetailsDialogProps> = (props: DealerDetailsDialogProps) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };
    const { dealerDetails }: any = useSelector((state: any) => state.dealer);
    let companyDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails) ? dealerDetails.data.data.customerDetails.companyDetails : null;

    useEffect(() => { 
        setTabValue(props.selectedTabValue);
    }, [props.selectedTabValue])

    return (
        <Dialog
            open={props.open ? true : false}
            onClose={() => { props.onClose(false) }}
            fullWidth={true}
            maxWidth={'md'}
            disableEscapeKeyDown>
            <DialogTitle className="pb-0" id="form-dialog-title">
                <div className="page-title">
                    {companyDetails && companyDetails.companyTitle ? companyDetails.companyTitle : ""} &nbsp;
                    {companyDetails && companyDetails.companyName ? "M/s " + companyDetails.companyName : ""}
                    <IconButton aria-label="close" className="float-right" onClick={() => {
                        setTabValue(0);
                        props.onClose(false);
                    }}>
                        <CloseIcon />
                    </IconButton>
                </div>

            </DialogTitle>
            <DialogContent className="p-0">
                <div className="p-0">
                    <Paper square>
                        <Tabs className="pl-2" value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="Dealer Tab">
                            <Tab label="Dealer Details" {...allTabProps(0)} />
                            <Tab label="Uploaded Documents" {...allTabProps(1)} />
                            <Tab label="Admin Details" {...allTabProps(2)} />
                        </Tabs>
                    </Paper>
                    <TabPanel value={tabValue} index={0}>
                        <div className="pl-4 pt-3">
                            <DealerDetails />
                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <div className="pl-4 pt-3">
                            <UploadedDocuments />
                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <div className="pl-4 pt-3">
                            <DealerAdminDetails />
                        </div>
                    </TabPanel>
                </div>
            </DialogContent>
            <DialogActions className="my-2 mr-4">
                <Button variant="contained" className="rounded-button-dealer-cancel pl-4 pr-4" onClick={() => { setTabValue(0); props.onClose(false); }} >Close</Button>
            </DialogActions>
        </Dialog >
    )
}

export default DealerDetailsDialog
