import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0.125rem 175',
    display: 'flex',
    alignItems: 'center',
    width: 300,
    borderRadius: "1.25rem",
    height: "2.25rem",
    minWidth: 200,
    margin: 0,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: '0.75rem',
    padding: '1rem',
    color: '#999999',
    fontWeight: 800,
    fontFamily: 'Nunito'
  },
  iconButton: {
    color: '#444444',
    fontWeight: 700
  },
}));

export default function SearchBar(props: any) {
  const classes = useStyles();

  return (
    <Paper component="div" className={classes.root}>
      <InputBase
        value={props.value}
        onChange={props.onChange}
        className={classes.input}
        placeholder={props.label}
        inputProps={{ 'aria-label': 'Search' }}
      />
      <IconButton onClick={()=> props.callAPI()}>
        <SearchIcon className={classes.iconButton} fontSize="small"/>
      </IconButton>
    </Paper>
  );
}
