import { Route, Redirect, RouteProps } from "react-router";
import React from "react";
import { useSelector } from "react-redux";
import { IStateType } from "../../store/models/root.interface";
import { IAccount } from "../../store/models/account.interface";
import LoginAzure from "../../components/Login/LoginAzure";


export function AccountRoute({ children, ...rest }: RouteProps): JSX.Element {

    const account: IAccount = useSelector((state: IStateType) => state.account);

    return (
        <Route
            {...rest}
            render={() =>
                account.userData ? (
                    <Redirect
                        to={{
                            pathname: "/"
                        }}
                    />
                ) : <LoginAzure />
            }
        />
    );
}