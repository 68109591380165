import * as React from 'react';
import ErrorPage from "./ErrorPage";

interface IErrorBoundaryState {
    isError: boolean;
    error: React.ErrorInfo;
}

export default class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {

    constructor(props: any) {
        super(props)
        this.state = {
            isError: false, error: {
                componentStack: ''
            }
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.setState({ isError: true, error: errorInfo });
    }

    render(): any {
        if (this.state.isError) {
            return <ErrorPage/>;
        }

        return this.props.children;
    }
}