import { CREDITREQUEST } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  creditLimitDetails: null,
  creditRequestDetails: null,
  creditRequestStatus: null,
  isApproveCR: null,
  isRejectCR: null,
};

function creditRequestReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case CREDITREQUEST.GET_ALL_CREDIT_REQUEST_FETCHING:
      return {
        ...state,
        creditLimitDetails: { loading: true, data: null, err: null }
      };
    case CREDITREQUEST.GET_ALL_CREDIT_REQUEST_SUCCESS:
      return {
        ...state,
        creditLimitDetails: { loading: false, data: action.payload, err: null }
      };
    case CREDITREQUEST.GET_ALL_CREDIT_REQUEST_ERROR:
      return {
        ...state,
        creditLimitDetails: { loading: false, data: null, err: action.payload }
      };
    case CREDITREQUEST.GET_CREDIT_REQUEST_STATUS_FETCHING:
      return {
        ...state,
        creditLimitDetails: { loading: true, data: null, err: null }
      };
    case CREDITREQUEST.GET_CREDIT_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        creditLimitDetails: { loading: false, data: action.payload, err: null }
      };
    case CREDITREQUEST.GET_CREDIT_REQUEST_STATUS_ERROR:
      return {
        ...state,
        creditLimitDetails: { loading: false, data: null, err: action.payload }
      };
    case CREDITREQUEST.GET_CREDIT_REQUEST_DETAILS_FETCHING:
      return {
        ...state,
        creditRequestDetails: { loading: true, data: null, err: null }
      };
    case CREDITREQUEST.GET_CREDIT_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        creditRequestDetails: { loading: false, data: action.payload.data, err: null }
      };
    case CREDITREQUEST.GET_CREDIT_REQUEST_DETAILS_ERROR:
      return {
        ...state,
        creditRequestDetails: { loading: false, data: null, err: action.payload }
      };

    case CREDITREQUEST.REJECT_CREDIT_REQUEST_DETAILS_FETCHING:
      return {
        ...state,
        isRejectCR: { loading: true, data: null, err: null }
      };
    case CREDITREQUEST.REJECT_CREDIT_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        isRejectCR: { loading: false, data: action.payload.data, err: null }
      };
    case CREDITREQUEST.REJECT_CREDIT_REQUEST_DETAILS_ERROR:
      return {
        ...state,
        isRejectCR: { loading: false, data: null, err: action.payload }
      };

    case CREDITREQUEST.APPROVE_CREDIT_REQUEST_DETAILS_FETCHING:
      return {
        ...state,
        isApproveCR: { loading: true, data: null, err: null }
      };
    case CREDITREQUEST.APPROVE_CREDIT_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        isApproveCR: { loading: false, data: action.payload.data, err: null }
      };
    case CREDITREQUEST.APPROVE_CREDIT_REQUEST_DETAILS_ERROR:
      return {
        ...state,
        isApproveCR: { loading: false, data: null, err: action.payload }
      };

    default:
      return state;
  }
};
export default creditRequestReducer;