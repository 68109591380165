import { ANNOUNCEMENTS } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { cmsAPIService } from "../../services/cms.service";

export const announcementsActions = {
    getAnnouncements,
    getAnnouncementDetail,
  };
  
  export function getAnnouncements() {
    return async (dispatch: any) => {
      dispatch(apiStart(ANNOUNCEMENTS.GET_ANNOUNCEMENTS_FETCHING));
      try {
        // const response = AnnouncementList.Offers.OffersList;
        const response = await cmsAPIService.getAnnouncementsList();
        dispatch(apiSuccess(ANNOUNCEMENTS.GET_ANNOUNCEMENTS_SUCCESS, response));
        return response || [];
      } catch (error) {
        dispatch(apiError(ANNOUNCEMENTS.GET_ANNOUNCEMENTS_ERROR, error));
        return error;
      }
    };
  }

  export function getAnnouncementDetail(id: any, name:any) {
    return async (dispatch: any) => {
      dispatch(apiStart(ANNOUNCEMENTS.GET_ANNOUNCEMENT_DETAIL_FETCHING));
      try {
        const response = await cmsAPIService.getAnnouncementDetails(name);
        dispatch(apiSuccess(ANNOUNCEMENTS.GET_ANNOUNCEMENT_DETAIL_SUCCESS, response.data.articleList.filter((item:any) => item.id === id)[0]));
        return response || {};
      } catch (error) {
        dispatch(apiError(ANNOUNCEMENTS.GET_ANNOUNCEMENT_DETAIL_ERROR, error));
        return error;
      }
    };
  }
