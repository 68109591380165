import React from 'react'
import image2 from './../../assets/landingpage/images/car.png'
import image3 from './../../assets/landingpage/images/parts.png'
import OwlCarousel from "react-owl-carousel";

const HomeContent = (props:any) => {

  return (
    <div id="myCarousel" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
       {props.pageData && props.pageData.banner && props.pageData.banner.list && props.pageData.banner.list && <OwlCarousel
          items={1}
          // items={flag == true ? 6 : 2}
          autoplay={true}
          autoplayTimeout={8000}
          className="owl-theme"
          loop
          dotsEach={true}
          margin={8}
          mergeFit
        >
          {props.pageData && props.pageData.banner && props.pageData.banner.list && props.pageData.banner.list.map((item:any)=>{
            let imageURL= item && item.background_image && item.background_image.renditionList && item.background_image.renditionList[0] && item.background_image.renditionList[0].resourceUri  ? item.background_image.renditionList[0].resourceUri : ''
            return  <div className="carousel-item active">
            <div className="container xl h-100 line-bg">
              <div className="row align-items-center h-100">
                <div className="col-lg-6 col-xl-5 mb-5 mt-5 mt-lg-0">
                  <h1 className="poppins-700 text-black">{item.heading}</h1>
                  <p className="font-16 mt-3 mb-4">{item.Sub_Heading}</p>
                 {item.CTA_Link_1 && <a className="btn btn-primary tp-btn mr-3 mt-3" href="javascript:void(0);" onClick={()=>{
                    window.open(item.CTA_Link_1)
                  }}>{item.CTA_Label_1}</a>}
                  {item.CTA_Link_2 &&<a className="btn btn-primary tp-btn mt-3" href="javascript:void(0);" onClick={()=>{
                    window.open(item.CTA_Link_2)
                  }}>{item.CTA_Label_2}</a>}
                </div>
                <div className="col-lg-6 col-xl-7 h-100"> <img src={imageURL} className="position-lg-absolute img-fluid" alt="car" /> </div>
              </div>
            </div>
          </div>
          }) }
        </OwlCarousel>}
        <div className="slider-action pb-5 pb-lg-4 pt-4 pt-lg-0">
          <div className="container xl d-flex align-items-center">
            <button className="btn btn-outline-primary tp-btn p-1 ln-0 mr-2" type="button" data-target="#myCarousel" data-slide="prev"> <span className="material-icons-outlined ">chevron_left</span> </button>
            <button className="btn btn-primary tp-btn p-1 ln-0" type="button" data-target="#myCarousel" data-slide="next"> <span className="material-icons-outlined">navigate_next</span> </button>
            <ol className="carousel-indicators position-relative ml-auto m-0 d-inline-flex">
              {/* <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
              <li data-target="#myCarousel" data-slide-to="1" className=""></li>
              <li data-target="#myCarousel" data-slide-to="2" className=""></li> */}
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeContent