import React, { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";

const LoginAzure: React.FC = () => {
    const { instance } = useMsal();

    useEffect(() => {
        // This will be run on component mount
        const callbackId = instance.addEventCallback((event: any) => {
            // This will be run every time an event is emitted after registering this callback
            if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                // dispatch(login(event.payload));
                // history.push('/');
            }
        });

        return () => {
            // This will be run on component unmount
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        }

    }, []); // eslint-disable-line

    /**
     * Using the event API, you can register an event callback that will do something when an event is emitted. 
     * When registering an event callback in a react component you will need to make sure you do 2 things.
     * 1) The callback is registered only once
     * 2) The callback is unregistered before the component unmounts.
     * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
     */
    // useEffect(() => {
    //     const callbackId = instance.addEventCallback((event: any) => {
    //         if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
    //             dispatch(login(event.payload));
    //         }
    //         if (event.eventType === EventType.LOGIN_FAILURE) {
    //             if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
    //                 if (event.interactionType === InteractionType.Redirect) {
    //                     //instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
    //                 } else if (event.interactionType === InteractionType.Popup) {
    //                     // instance.loginPopup(b2cPolicies.authorities.forgotPassword)
    //                     //     .catch(e => {
    //                     //         return;
    //                     //     });
    //                 }
    //             }
    //         }

    //         if (event.eventType === EventType.LOGIN_SUCCESS) {
    //             if (event?.payload) {
    //                 /**
    //                  * We need to reject id tokens that were not issued with the default sign-in policy.
    //                  * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
    //                  * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
    //                  */
    //                 if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
    //                     //window.alert("Password has been reset successfully. \nPlease sign-in with your new password");
    //                     return instance.logout();
    //                 }
    //             }
    //         }
    //     });

    //     return () => {
    //         if (callbackId) {
    //             instance.removeEventCallback(callbackId);
    //         }
    //     };
    // });

    return (
        <div>
            Hello
        </div>
    )
}

export default LoginAzure
