import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { b2cPolicies, msalConfig } from "../../authConfig";
import { onBoardingService } from './../../services/onBoarding.service'
import { API_ENDPOINTS } from '../../constants/apiConfig';
import { OnboardingErrorPage } from '../../common/AccountRestricted/OnboardingErrorPage';

const SignupAzureB2C: React.FC = () => {
    const [linkExpired, setlinkExpired] = React.useState(false)
    const location = useLocation();
    const redirect_uri = encodeURIComponent(`${API_ENDPOINTS.ON_BOARDING_URL}/onboard`);//encodeURIComponent('https://localhost:3005/onboard')
    const [registeredUser, setregisteredUser] = React.useState('')

    const callLoginAPI = async () => {
        let data = queryString.parse(location.search)
        if (data && data.b2bUserId) {
            let isValidLink = await onBoardingService.verifyLink(data.b2bUserId)
            if (isValidLink && isValidLink.status == 400) {
                if (typeof isValidLink.error == 'string') {
                    setregisteredUser(isValidLink.error)
                } else {
                    setregisteredUser(isValidLink.error && isValidLink.error.message)
                }
            } else {
                if (isValidLink && isValidLink.data && !isValidLink.data.isLinkExpired) {
                    let signupURL = `https://${b2cPolicies.authorityDomain}/${b2cPolicies.tenantUrl}/oauth2/v2.0/authorize?p=${b2cPolicies.names.signUp}&client_id=${msalConfig.auth.clientId}&nonce=defaultNonce&redirect_uri=${redirect_uri}&scope=openid&response_type=id_token&prompt=login&state=${data.b2bUserId}`
                    window.location.href = signupURL;
                } else {
                    setlinkExpired(true)
                }
            }
        } else {
            //User does not exist message, contact Key Account Manager.
            return <OnboardingErrorPage status="noUser" />
        }
    }

    useEffect(() => {
        callLoginAPI()
    }, []); // eslint-disable-line

    return (<div>
        {linkExpired || registeredUser && <OnboardingErrorPage status={registeredUser ? "registeredUser": "expiredLink"} message={registeredUser} />}
    </div>)
}

export default SignupAzureB2C
