
import React, { Fragment} from "react";

import "./fullscreen.css";
import Spinner from "../../components/spinner";

const FullscreenLoader: React.FC = () => {

  return (
    <Fragment>
        <div className="overlayLoader">
            <div>
                <Spinner/>
            </div>
        </div>
    </Fragment>
  );
};

export default FullscreenLoader;
