import React, { Fragment, Dispatch, useState, useEffect } from "react";
import "./Dashboard.css";
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import CancelIcon from '@material-ui/icons/Cancel';
import TableHead from '@material-ui/core/TableHead';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import { Link, useHistory } from "react-router-dom";
import SpecialOffers from "../SpecialOffer/SpecialOffers";
import Announcements from "../Announcements/Announcements";
// import { userLogon } from "../../store/actions/logon.actions";
import { getEnquiriesList, setOrderEnquiryDetails } from "../../store/actions/orderEnquiry.action";
import ImportDialog from './../CreateEnquiry/ImportExcelDialog'
import FullscreenLoader from './../../common/components/FullscreenLoader';
import Snackbar from '../../common/components/CreatedEnquirySnackbar'
import { getFavoriteDetails, getFavoritesList } from "../../store/actions/favorites.actions";
import { Dialog } from "@material-ui/core";
import CreateFavorite from "../favorites/createFavorite";
import ViewFavorite from "../favorites/viewFavorite";
import { getIP, getNewBrand, getUniqueTimestamp } from "../../common/components/util";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import CancelDialog from '../../common/components/cancelDialog';
import { cancelEnquiry, getUniqueEnquirySessionId } from "../../store/actions/createUpdateEnquiry.actions";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import NotificationIcon from "../../common/components/NotificationIcon";
import Loading from '../../common/components/loading';
import { get } from "lodash";
import { getTermsAndConditions } from "../../store/actions/termsAndConditions.action";
import { FULLY_CANCELLEED } from '../../constants/orderEnquiryConstants';
import { getSellingPrice } from "../../helpers/commonmethod";

const useStyles = makeStyles((theme) => ({

  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  collapseBg: {
    background: 'rgba(66, 94, 108, 0.07)'
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: 12,
    fontFamily: 'Nunito',
    padding: 10,
    minWidth: 70
  },
  collapseIcon: {
    color: '#000000',
    fontWeight: 500,
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    minWidth: 70,
    padding: 10
  },
  subTableHead: {
    color: '#444444',
    fontWeight: 800,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    minWidth: 111,
    padding: 10
  },
  actionButton: {
    color: '#1A9FE0',
  },
  subTableBodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    minWidth: 130,
    padding: 10
  },
}));

const Dashboard: React.FC = () => {
  const history = useHistory()
  const [partNumber, setpartNumber] = React.useState('');
  const [quantity, setquantity] = React.useState<any>('');
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const { enquiryList } = useSelector((state: any) => state.orderEnquiry);
  const [selectedMasterId, setSelectedMasterId] = useState<any>(null);
  const { favoritesList, updateFavorite }: any = useSelector((state: any) => state.favorites);
  const [openEditFavorite, setOpenEditFavorite] = React.useState(false);
  const [openViewFavorite, setOpenViewFavorite] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const logonData: any = useSelector((state: any) => state.logon.logonData);
  const [searchText, setsearchText] = React.useState<any>('');
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);
  const { addNotification }: any = useSelector((state: any) => state.notifications);

  const [open, setOpen] = useState(-1);
  let favoritesPage = 2;
  let rowsPerPage = 5;
  let page = 0;
  useEffect(() => {
    dispatch(getEnquiriesList({}));
    getIP();
    dispatch(getTermsAndConditions());
  }, [dispatch, profileDetails]);

  useEffect(() => {
    if (searchText === '') dispatch(getFavoritesList(null, null));
  }, [searchText, dispatch]);

  const searchFavorites = () => {
    if (searchText && searchText !== '') {
      dispatch(getFavoritesList(null, searchText));
    }
  }

  useEffect(() => {
    if (updateFavorite && updateFavorite.data) {
      handleClose();
      setSuccessMessage('Favorite updated successfully.');
      handleopenCloseSnackbar(true);
      handleopenCloseViewFavorite(false);
      dispatch(getFavoritesList(null, null));
    }
  }, [updateFavorite, dispatch]);

  const SubEnquiries = (props: { subEnq: any, enquiry: any }) => {
    const { subEnq } = props;
    return (
      <TableRow>
        <TableCell className={classes.subTableBodyContent}>
          <span className="mx-4 pl-4"></span>
          {getNewBrand(subEnq.make)}
        </TableCell>
        <TableCell className={classes.subTableBodyContent}>{subEnq.enquiryReferenceNumber}</TableCell>
        <TableCell className={classes.subTableBodyContent}>{subEnq.status}</TableCell>
        <TableCell className={classes.subTableBodyContent}>{subEnq.estimatedTotal && typeof subEnq.estimatedTotal === 'string' &&
          subEnq.estimatedTotal.toLowerCase() !== "nan" ? parseFloat(getSellingPrice(subEnq.estimatedTotal, isSellingPriceData, markupPercentage)).toFixed(2) : (parseFloat(getSellingPrice(0, isSellingPriceData, markupPercentage)).toFixed(2))}</TableCell>
      </TableRow>
    )
  }

  function EnquiryList(props: { enquiry: any, setSelectedMasterId: any, index: any }) {
    const { enquiry, setSelectedMasterId, index } = props;

    const showCancel = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CANCEL_ENQUIRY);
    const showViewEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_ENQUIRY_DETAILS);
    const showCancelButton = enquiry.status && enquiry.status.toLowerCase() !== FULLY_CANCELLEED && enquiry.status.toLowerCase() !== "cancel" && showCancel && showCreateChangeEnquiry

    return (
      <Fragment>
        <Snackbar
          open={openSnackbar}
          message={successMessage}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} />
        <TableRow hover role="checkbox" tabIndex={-1} onClick={() => setOpen(open === index + 1 ? -1 : index + 1)} className="hover-pointer">
          <TableCell className="bodyContent">
            <IconButton aria-label="expand row" size="small" className={classes.collapseIcon}>
              {open > 0 && open === index + 1 ? <RemoveIcon fontSize="inherit" /> : <Add fontSize="inherit" />}
            </IconButton>
          </TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.masterId}</TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.masterReferenceNumber}</TableCell>
          <TableCell align="left" className={classes.bodyContent}>{enquiry.make && Array.isArray(enquiry.make) && enquiry.make.toString()}</TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.estimatedTotal && (typeof enquiry.estimatedTotal === 'string' &&
            enquiry.estimatedTotal.toLowerCase() !== "nan") ?
            parseFloat(getSellingPrice(enquiry.estimatedTotal, isSellingPriceData, markupPercentage)).toFixed(2) : (parseFloat(getSellingPrice(0, isSellingPriceData, markupPercentage)).toFixed(2))}</TableCell>
          <TableCell className={classes.bodyContent}>{moment(enquiry.createdOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.createdBy}</TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.status}</TableCell>
          <TableCell className={classes.bodyContent}>
            {showViewEnquiry &&
              <Tooltip title="View Order Enquiry" placement="top">
                <IconButton className={classes.actionButton} size="small" onClick={() => {
                  sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                    mode: enquiry.status && enquiry.status.toLowerCase() !== 'failed' && enquiry.status.toLowerCase() !== "enquiry cancelled" && enquiry.status.toLowerCase() !== "cancel" && showCreateChangeEnquiry ? 'update' : 'view',
                    masterId: enquiry.masterId
                  }))
                  if ((((enquiry.status && enquiry.status.toLowerCase() === 'failed') || (enquiry.status.toLowerCase() === "enquiry cancelled")) && enquiry.status.toLowerCase() === "cancel") || !showCreateChangeEnquiry)
                    history.push('/viewenquiry')
                  else
                    history.push('/updateenquiry')
                }}>
                  <OpenInNewOutlinedIcon fontSize="inherit"
                  />
                </IconButton>
              </Tooltip>
            }
            {showCancelButton &&
              <Tooltip title="Cancel Order Enquiry" placement="top">
                <IconButton className={classes.actionButton} size="small" onClick={() => {
                  setSelectedMasterId(enquiry.masterId)
                  handleopenCloseCancelDialog(true)
                }}>
                  <CancelIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            }
          </TableCell>
        </TableRow>
        <TableRow className={classes.collapseBg} hover role="checkbox" tabIndex={-1}>
          <TableCell className="p-0 w-100" colSpan={12}>
            <Collapse in={open > 0 && open === index + 1 ? true : false}>
              <Box>
                <Table aria-label="table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${classes.subTableHead} pr-0`}>
                        <span className="mx-4 pl-4"></span>
                        Brand</TableCell>
                      <TableCell className={`${classes.subTableHead}`}>Reference No.</TableCell>
                      <TableCell className={classes.subTableHead}>Status</TableCell>
                      <TableCell className={classes.subTableHead}>Estimated Total (incl. of Vat)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {enquiry.subEnquiries && enquiry.subEnquiries.map((subEnq: any) => {
                      return (
                        <SubEnquiries key={subEnq.enquiryNumber} subEnq={subEnq} enquiry={enquiry} />
                      );
                    }
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClickOpen = () => {
    setOpenEditFavorite(true);
  };

  const handleClose = () => {
    setOpenEditFavorite(false);
  };

  const handleopenCloseCancelDialog = (open?: any) => {
    setOpenCancelDialog(open ? true : false);
  };

  const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");

  useEffect(() => {
    sessionStorage.setItem('webApplicationLoginId', webApplicationLoginId);;
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.salesOrganization) {
      let brands = profileDetails.data.data.basicInfo.salesOrganization.map((salesOrg: any) => {
        return salesOrg.salesOrganization;
      })
      sessionStorage.setItem('brands', brands);
    }
    if (!logonData && webApplicationLoginId !== "" && webApplicationLoginId !== null) {
      // dispatch(userLogon('Dealer'));
    }
  }, [profileDetails, logonData, webApplicationLoginId, dispatch]);

  const handleopenCloseViewFavorite = (open?: any) => {
    setOpenViewFavorite(open ? true : false);
  };

  const showCreateChangeEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);
  const showImportExcel = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.UPLOAD_ENQUIRY_EXCEL);
  const showFavorites = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.LIST_FAVORITES);
  const ENQ_LIST = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.ENQ_LIST);

  return (
    <Fragment>
      {(enquiryList && enquiryList.loading && !logonData) || (addNotification && addNotification.loading) && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Dashboard
        </h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          {showCreateChangeEnquiry &&
            <div className="cardCommon p-3">

              <div className="d-flex align-items-center">
                <h5 className="page-sub-title mb-2">Create Order Enquiry</h5>
                {showImportExcel &&
                  <div className="ml-auto">
                    <ImportDialog commonInsertionLogic={(data: any) => {
                      if (data && Array.isArray(data) && data.length > 0) {
                        data = data.map((item: any) => {
                          item.productId = item.productId ? item.productId : item.partnumber
                          return item
                        });
                        sessionStorage.setItem('partsData', JSON.stringify(data));
                        dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                        history.push('/createenquiry');
                        dispatch(setOrderEnquiryDetails('create'));
                      }
                    }} />
                  </div>
                }
              </div>

              <TextField value={partNumber} onChange={(e) => { setpartNumber(e.target.value) }}
                size="small" className="col-sm-12 col-md-6 col-ml-7 col-xl-8 pr-3 commonRoundedInputs common-input-font-size" label="Search By Part Number" variant="outlined" />

              <TextField value={quantity} onChange={(e) => {
                let value: any = e.target.value && parseInt(e.target.value) && /^[+]?\d+([.]\d+)?$/.test(e.target.value) &&
                  parseInt(e.target.value) >= 1
                  ? parseInt(e.target.value)
                  : ""
                setquantity(value)
              }}
                inputProps={{ maxLength: 5 }}
                size="small" className="col-sm-6 col-md-3 col-ml-2 col-xl-2 pr-3 commonRoundedInputs common-input-font-size" label="Quantity" variant="outlined" />
              <Button
                className="col-sm-6 col-md-3 col-ml-3 col-xl-2 font-12 addOrderEnqButton"

                onClick={() => {
                  if (partNumber) {
                    sessionStorage.setItem('partsData', JSON.stringify([{
                      productId: partNumber ? partNumber.trim() : '',
                      quantity: quantity && parseInt(quantity) ? parseInt(quantity) : 1
                    }]))
                  }
                  dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                  history.push('/createenquiry'); dispatch(setOrderEnquiryDetails('create'));
                }}
                variant="contained"
                color="primary">Add to Order Enquiry
              </Button>

            </div>
          }
        </div>
      </div>

      {ENQ_LIST && <div className="row mt-4">
        <div className="col-12">
          <h5 className="page-sub-title w-100">Recent Order Enquiries</h5>
          <div className="cardCommon mh-300p w-100">
            <Table aria-label="table">
              <TableHead className="w-100">
                <TableRow>
                  <TableCell className={classes.tableHead}></TableCell>
                  <TableCell className={classes.tableHead}>Order Enquiry No.</TableCell>
                  <TableCell className={classes.tableHead}>Reference No.</TableCell>
                  <TableCell className={classes.tableHead}>Brand</TableCell>
                  <TableCell>
                    <h6 className="tableHead">Estimated Total</h6>
                    <h6 className="vat">(incl.of VAT)</h6>
                  </TableCell>
                  <TableCell className={classes.tableHead}>Created On</TableCell>
                  <TableCell className={classes.tableHead}>Created By</TableCell>
                  <TableCell className={classes.tableHead}>Status</TableCell>
                  <TableCell className={classes.tableHead}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enquiryList && enquiryList.data && enquiryList.data.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data.length > 0 && (
                  <>
                    {enquiryList.data.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((enquiry: any, index: any) => {
                      return (
                        <EnquiryList key={index} index={index} enquiry={enquiry} setSelectedMasterId={setSelectedMasterId} />
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
            {((enquiryList && enquiryList.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data && enquiryList.data.data.length === 0) || (enquiryList && enquiryList.data && enquiryList && !enquiryList.data.data)) && (
              <div className="mt-5 justify-content-center mx-auto text-center d-flex">No Enquiries Found.</div>
            )}
            {enquiryList && enquiryList.loading && (
              <div className="mt-5 justify-content-center mx-auto text-center d-flex"><Loading /></div>
            )}
            {enquiryList && enquiryList.data && enquiryList.data.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data.length > 0 &&
              <Link className="bottom-link p-3 d-block" to="/orderenquiry">View all</Link>
            }
          </div>
        </div>
      </div>}
      <div className="row mt-4">
        <div className="col-12 col-lg-4 col-md-12 col-sm-12">
          <SpecialOffers />
        </div>
        <div className="col-12 col-lg-4 col-md-12 col-sm-12">
          <Announcements />
        </div>
        {showFavorites &&
          <div className="col-12 col-lg-4 col-md-12 col-sm-12">
            <h5 className="page-sub-title mb-2">Favorites</h5>
            <div className="cardCommon height-400">
              <div className="col-12 mt-3">
                <div className="justifyContentHorizontalSpacebetween align-items-center">
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 ml-1 mb-3 fav">Part</div>
                  <div className="mr-5 mb-3 fav">Action</div>
                </div>
                <Divider light />
                <div className="searchFav">
                  <span>
                    <IconButton
                      edge="start"
                      className="ml-2"
                      onClick={() => searchFavorites()}
                    >
                      <SearchIcon fontSize="inherit" />
                    </IconButton>
                    <TextField value={searchText} onChange={(e) => { setsearchText(e.target.value) }}
                      size="small" className="mt-2" placeholder="Search your favorites" variant="outlined" />
                  </span>
                </div>
                <Divider light />
                {favoritesList && favoritesList.data && favoritesList.data.favouriteList && Array.isArray(favoritesList.data.favouriteList) && favoritesList.data.favouriteList.length > 0 && (
                  <>
                    {favoritesList.data.favouriteList.slice(page * favoritesPage, page * favoritesPage + favoritesPage).map((favorite: any, index: any) => (
                      <Fragment key={index}>
                        <div className="justifyContentHorizontalSpacebetween align-items-center">
                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-3 ml-1 footFav">{favorite.name}</div>
                          <div className="mr-5 mt-3">
                            <Tooltip title="Edit" placement="top">
                              <IconButton className={classes.actionButton} size="small" onClick={() => { handleClickOpen(); dispatch(getFavoriteDetails(favorite)); }}>
                                <EditOutlinedIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="View" placement="top">
                              <IconButton className={classes.actionButton} size="small" onClick={() => { handleopenCloseViewFavorite(true); dispatch(getFavoriteDetails(favorite)); }}>
                                <OpenInNewOutlinedIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="ml-3 mt-2 footOffers">By{' '}{favorite.userId}</div>
                        <div className="ml-3 mt-2 mb-2 footDate">{moment(favorite.updatedAt).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</div>
                        <Divider light />
                      </Fragment>
                    ))}
                  </>
                )}
                {favoritesList && favoritesList.data && favoritesList.data.favouriteList && Array.isArray(favoritesList.data.favouriteList) && favoritesList.data.favouriteList.length === 0 && (
                  <span className="ml-5 mt-3">No favorites found</span>
                )}
              </div>
              {favoritesList && favoritesList.data && favoritesList.data.favouriteList && Array.isArray(favoritesList.data.favouriteList) && favoritesList.data.favouriteList.length > 0 &&
                <Link className="bottom-link p-3 ml-3 d-block" to="/favorites">View all</Link>
              }
            </div>
          </div>
        }
      </div>
      <Dialog
        open={openEditFavorite}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        className="createFavoriteDialog"
        maxWidth="md"
      >
        <CreateFavorite setOpenDialog={handleClose} />
      </Dialog>
      <ViewFavorite
        open={openViewFavorite}
        handleopenClose={(data: any) => {
          handleopenCloseViewFavorite(false)
        }}
      />
      <CancelDialog
        open={openCancelDialog}
        text="Are you sure to cancel this Order Enquiry ?"
        handleopenClose={async (data: any) => {
          if (data) {
            await dispatch(cancelEnquiry(selectedMasterId));
            dispatch(getEnquiriesList({}));
            setSelectedMasterId(null)
            handleopenCloseSnackbar(true)
            setSuccessMessage('Enquiry cancelled successfully.');
          }
          handleopenCloseCancelDialog(false)
        }}
      />
    </Fragment>
  );
};

export default Dashboard;
