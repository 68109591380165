import React, {useEffect, Dispatch} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import './Announcements.css'

import Divider from '@material-ui/core/Divider';
import { getAnnouncements, getAnnouncementDetail } from '../../store/actions/announcements.actions';

const Announcements: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const {announcements}: any = useSelector((state: any) => state.announcements);
    const history = useHistory();

    useEffect(() => {
        dispatch(getAnnouncements());
      }, [dispatch]);    

    return (
        <>
            <h5 className="page-sub-title mb-2">Announcement</h5>

            <div className="cardCommon height-400">
                {announcements && announcements.data && announcements.data.data && announcements.data.data.Offers && announcements.data.data.Offers.OffersList.map((item:any) =>
                    <div className="col-12 mt-3" key={item.id}>
                        <Link to="/announcements" onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(getAnnouncementDetail(item.id, item.name));
                                    history.push('/announcements');
                                    }} className="ml-3 mr-2 mt-2 section-title">{item.title}</Link>

                        <div className="ml-3 mr-2 mt-1 section-content text-ellipsis">{item.description}</div>

                        <div className="ml-3 mr-2 mt-2 section-date"> {item.date}</div>
                        <Divider className="mt-3 mb-2" />
                    </div>
                )}

            </div>
        </>
    )
}

export default Announcements
