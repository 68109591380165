import React, { Dispatch, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Select, MenuItem, IconButton, DialogTitle } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Link, useHistory } from "react-router-dom";
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Snackbar from '../../common/components/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import { getDetailsBySapId, getGenrateOTP, getVerifyOTP, setCustomerType } from "../../store/actions/onboard.actions";
import { get } from "lodash";
import { useMsal } from "@azure/msal-react";
import { logoutUser } from "../../store/actions/logon.actions";
import { logout } from "../../store/actions/account.actions";
import { signOut, useUserDispatch } from "../../context/UserContext";
import { OnboardingEmailVarificationValidation } from "../../constants/messages";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 10,
  },
  goToLogin: {
    backgroundColor: '#EAEAEA',
    width: 125,
    fontWeight: 800,
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  close: {
    color: '#000000',
    fontWeight: 900
  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

export function NewCustomerDialog(props: any) {
  const dispatch: Dispatch<any> = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [customerNumber, setCustomerNumber] = React.useState<any>();
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState<any>();
  const [customerNumberError, setCustomerNumberError] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [customerVerifyOTP, setCustomerVerifyOTP] = React.useState<any>();
  const [customerVerifyOTPError, setCustomerVerifyOTPError] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<any>('new');
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { verifyOtp, generateOTP, customerType }: any = useSelector((state: any) => state.onboard);
  const { openCustomerDialog, verifySuccess } = props;
  const [verify, setVerify] = React.useState<any>(false);
  const [error, setError] = React.useState<any>();
  const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const userDispatch = useUserDispatch();

  const handleChange = (event: any) => {
    setSelected(event.target.value);
    dispatch(setCustomerType(event.target.value));
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCustomerChange = (e: any) => {
    setCustomerNumber(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        setCustomerNumberError(true);
      } else if (validateCustomerNumber(e.target.value) === false) {
        setCustomerNumberError(true);
      }
      else {
        setCustomerNumberError(false);
      }
    }
  }

  function validateCustomerNumber(number: any) {
    const re = /^[0-9]+$/;
    return re.test(number);
  }

  function validateVarificationCode(number: any) {
    const re = /^\d{6}$/;
    return re.test(number);
  }

  const handleCustomerVerifyOTPChange = (e: any) => {
    setCustomerVerifyOTP(e.target.value && e.target.value.length ? e.target.value : "");
    if (e.target.value === "" || e.target.value.trim() === "") {
      if (!validateVarificationCode(customerVerifyOTP)) {
        setCustomerVerifyOTPError(true);
      }
      else {
        setCustomerVerifyOTPError(false);
      }
    }
  }

  // useEffect(() => {
  //   if (verifyOtp && verifyOtp.err) {
  //     setErrorMessage(verifyOtp.err.error);
  //   }
  // }, [verifyOtp])

  useEffect(() => {
    if (generateOTP && generateOTP.data) {
      setError(null);
      setVerify(true);
    } else if (generateOTP && generateOTP.err) {
      setError(generateOTP.err.error);
    }
  }, [generateOTP])

  const handleSubmit = () => {
    if (selected === 'new') {
      dispatch(setCustomerType('new'));
      setOpen(false);
      verifySuccess();
      sessionStorage.setItem('sapCustomerId', '');
      sessionStorage.setItem('customerType', 'new');
    } else {
      if (customerNumber) {
        let appendedString: any = customerNumber + '';
        if (appendedString.length < 10) {
          let newString = '';
          let customerLength = 10 - appendedString.length;
          for (let i = 0; i < customerLength; i++) {
            newString += '0'
          }
          appendedString = newString + customerNumber;
        }
        setCustomerNumber(appendedString);
        dispatch(setCustomerType('existing'));
        sessionStorage.setItem('customerType', 'existing');
        dispatch(getGenrateOTP(appendedString, b2bUserId))
        setCustomerNumberError(false);
      } else {
        setCustomerNumberError(true);
      }
    }
  }

  const resendVerificationLink = () => {
    dispatch(getGenrateOTP(customerNumber, b2bUserId));
    setCustomerVerifyOTP("");
  }

  async function handleVerifyOTP() {
    if (customerVerifyOTP) {
      let verifyData: any = await dispatch(getVerifyOTP(customerNumber, customerVerifyOTP, b2bUserId));
      if (verifyData && verifyData.message && verifyData.message.status === 'S') {
        setSuccessMessage('OTP verified successfully');
        dispatch(getDetailsBySapId(customerNumber));
        sessionStorage.setItem('sapCustomerId', customerNumber);
        verifySuccess();
        setErrorMessage(null);
        handleopenCloseSnackbar(true);
        setOpen(false);
        setCustomerNumber(null);
        setCustomerVerifyOTP(null);
      }
      else {
        setErrorMessage(verifyData.error);
      }
      setCustomerVerifyOTPError(false);
    } else {
      setCustomerVerifyOTPError(true);
    }
  }

  useEffect(() => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.isExistingUser === false && !customerType) {
      setOpen(true);
    }
  }, [profileDetails, customerType])

  useEffect(() => {
    if (openCustomerDialog) {
      setVerify(false);
      handleopenCloseSnackbar(false);
      setOpen(true);
    }
  }, [openCustomerDialog])

  const classes = useStyles();
  const { instance } = useMsal();

  const callLogout = async () => {
    await dispatch(logoutUser());
    await dispatch(logout());
    signOut(userDispatch);
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  }

  return (
    <Dialog
      open={open ? true : false}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false)
        }
      }}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown
      maxWidth="xs"
      className="typeDialogParent">

      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} text={successMessage} />
      <DialogTitle>
        {!verify && (
          <div className="justifyContentHorizontalSpacebetween align-items-center">
            <h4 className="page-title">Please Select Customer Type.</h4>
            {isExistingUser && (
              <IconButton onClick={() => { setOpen(false); props.setOpenCustomerDialog(false); }}>
                <CloseIcon fontSize="inherit" className={classes.close} />
              </IconButton>
            )}
          </div>
        )}
        {verify && (
          <div className="justifyContentHorizontalSpacebetween align-items-center">
            <h4 className="page-title">Verify Your Email</h4>
            {isExistingUser && (
              <IconButton onClick={() => { setOpen(false); props.setOpenCustomerDialog(false); setCustomerVerifyOTP(""); }}>
                <CloseIcon fontSize="inherit" className={classes.close} />
              </IconButton>
            )}
          </div>
        )}
      </DialogTitle>
      <DialogContent>
        {!verify && (
          <div>
            <FormControl component="fieldset" name="customer-type" className="mt-4 ml-4">
              <RadioGroup row onChange={handleChange} value={selected}>
                <FormControlLabel
                  value="new"
                  control={<Radio color="primary" />}
                  label="New"
                />
                <FormControlLabel
                  value="existing"
                  control={<Radio color="primary" />}
                  label="Existing"
                  className="ml-5"
                />
              </RadioGroup>
            </FormControl>
            {selected === 'existing' && (
              <TextField id="customer Number"
                value={customerNumber}
                error={customerNumberError}
                onChange={(e: any) => handleCustomerChange(e)}
                size="small" className="col-sm-12 my-2 commonRoundedInputs hiddenNo"
                type="number"
                label="Al Futtaim Customer Number" required variant="outlined" margin="dense" />
            )}
            {error && (
              <p className="d-flex text-red text-center justify-content-center mt-3">{error}</p>
            )}
          </div>
        )}
      </DialogContent>
      <DialogContent>
        {verify && (
          <div className="col-12">
            <div className="d-flex flex-row">
              <IconButton className="p-0 m-1 toast-header" edge="end" aria-label="add" onClick={() => { setVerify(false); setCustomerVerifyOTP(""); }}>
                <ChevronLeftIcon fontSize="medium" />
                <span className="info-sub-header-document m-2 mt-2">Back</span>
              </IconButton>
              <div className="d-flex flex-row">
                <h6 className="customerDialog pt-1 pb-2 mt-2"> | </h6>
              </div>
              <div className="d-flex flex ml-2">
                <h6 className="document-content pt-1 pb-2 mt-2">UAE-AFG-0001 </h6>
              </div>
            </div>
            <h6 className="document-sub-content mb-1 mt-2">Please enter 6 digit code which we have sent to  your registered email {generateOTP && generateOTP.data && generateOTP.data.email}</h6>
            <div className="">
              <TextField id="customer Number"
                value={customerVerifyOTP}
                error={customerVerifyOTPError}
                helperText={customerVerifyOTPError &&
                  <span style={{ color: 'red' }}>{OnboardingEmailVarificationValidation.EMAIL_VALIDATION_TEXT}</span>
                }
                onChange={(e: any) => handleCustomerVerifyOTPChange(e)}
                type="text"
                inputProps={{ maxLength: 6, minLength: 0 }}
                size="small" className="col-sm-12 my-2 commonRoundedInputs hiddenNo"
                label="Enter Code" required variant="outlined" margin="dense" />
            </div>
            <div className="d-flex align-items-center">
              <h5 className="document-sub-content">Haven't received ?</h5>
              <div className="row-space-start ml-auto">
                <span className="bottom-link p-3 d-block info-sub-header-document cursor-pointer"
                  onClick={() => { resendVerificationLink(); setCustomerVerifyOTP(""); }}>Resend Verification Code</span>
              </div>
            </div>
            <h6 className="document-sub-content pt-1 pb-2">Incase of any issues, please contact your assigned KAM</h6>
            {errorMessage && (
              <p className="d-flex text-red text-center justify-content-center mt-3">{errorMessage}</p>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions className="mb-4 justify-content-end">
        {!verify && (
          <Button variant="contained" color="primary" className="rounded-pill font-size-11 mr-2" onClick={() => { handleSubmit() }}>
            Submit
          </Button>
        )}
        {verify && (
          <Button variant="contained" color="primary" className="rounded-pill font-size-11 mr-3" onClick={() => { handleVerifyOTP() }}>
            Verify
          </Button>
        )}
        {!isExistingUser && (
          <Button variant="contained" className={classes.goToLogin}
            onClick={() => { callLogout(); }}
          >Go to Login</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
