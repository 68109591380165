import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";
import { Prompt, useHistory } from 'react-router-dom';
import Badge from "@material-ui/core/Badge";
import { ThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PurChaseHistory from "./PurchaseHistory";
import "./Achivement.css";
import React, { useEffect, useState, Dispatch, useRef } from 'react';
import { getNewBrand } from "../../common/components/util";
import { getCreditLimit } from '../../store/actions/creditLimit.actions';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createTheme } from '@material-ui/core/styles';
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import FilterTiltShiftRoundedIcon from '@material-ui/icons/FilterTiltShiftRounded';
import { EmojiEventsOutlined, Visibility } from "@material-ui/icons";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import DoneIcon from '@material-ui/icons/Done';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import { getPurChaseHistoryByYear } from "../../store/actions/achievement.actions";
import _ from "lodash";
import Alert from '@material-ui/lab/Alert';
import NotificationIcon from "../../common/components/NotificationIcon";
import { getInternationalNumbers } from "../../helpers/commonmethod";

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0',
    },
    secondary: {
      main: '#FF0000',
    }
  },
});

const useStyles = makeStyles((theme) => ({
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
  },
  table: {
    minWidth: '100%',
  },
  close: {
    color: '#000000',
    fontWeight: 900,
    fontSize: 18
  }
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      height: 120,
      fontSize: 18
    },
    body: {
      fontSize: 14,
      fontWeight: 600,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const iconsPlan: any = {
  "silver": <HomeOutlinedIcon />,
  "gold": <StorageOutlinedIcon />,
  "platinum": <EmojiEventsOutlinedIcon />,
  "elite": <SportsSoccerIcon />,
}

const showStars: any = {
  "1 stars": <><StarBorderOutlinedIcon /></>,
  "2 stars": <><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /></>,
  "3 stars": <><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /></>,
  "4 stars": <><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /></>,
  "5 stars": <><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /></>,
}

const quaterEndDates: any = {
  quater1: "31 March",
  quater2: "30 June",
  quater3: "30 September",
  quater4: "31 December"
}

const quatersData: any = {
  "jan": "quater1",
  "feb": "quater1",
  "mar": "quater1",
  "apr": "quater2",
  "may": "quater2",
  "jun": "quater2",
  "jul": "quater3",
  "aug": "quater3",
  "sep": "quater3",
  "oct": "quater4",
  "nov": "quater4",
  "dec": "quater4",
}

const d = new Date();
let month: any = d.getMonth();
const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
let activequater = quatersData[months[month]];

const Achivements: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const scrollToDiv: any = useRef()
  const { creditLimitDetails }: any = useSelector((state: any) => state.creditLimitDetails);
  const { dealerDetails }: any = useSelector((state: any) => state.dealer);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const classes = useStyles()
  const [amountSpentData, setamountSpentData] = useState<any>(0);
  const [quarterlySpendData, setQuarterlySpendData] = useState<any>(0);
  const [currentUrl, setCurrentURL] = useState<any>();
  const [datafetched, setdatafetched] = useState<any>(false);
  const [achievement, setAchievementNotFound] = useState(false)
  const [quarter, setQuarter] = useState<string>("");
  const { purchaseHistoryByYear }: any = useSelector((state: any) => state.achievement);
  const purchaseData: any = useSelector((state: any) => state.achievement);
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const sapCustomerIdNew: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
  const b2bUserId = _.get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const planfeaturedData = purchaseData?.purchaseHistoryByYear?.data?.featuresList;
  const customerTierInfo = purchaseData?.purchaseHistoryByYear?.data?.customerTierInfo;
  const purchaseDataAll = purchaseData?.purchaseHistoryByYeary?.data?.purchaseData;
  const history = useHistory();

  //Initial current month quarter Calculations
  useEffect(() => {

    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const currentMonth = new Date();
    const name = currentMonth.getMonth()
    const currentYear = new Date().getUTCFullYear();

    let valuesMonth: any = []
    const len = purchaseDataAll &&
      purchaseDataAll.purchaseDataAll && purchaseDataAll.purchaseHistory &&
      purchaseDataAll.purchaseHistory.data && purchaseDataAll.purchaseHistory.data.targetsData &&
      purchaseDataAll.purchaseHistory.data.targetsData && purchaseDataAll.purchaseHistory.data.targetsData.brandTargets.length

    if (name <= 3) {
      setQuarter("Q1")
    } else if (name <= 5) {
      setQuarter("Q2")
    } else if (name <= 8) {
      setQuarter("Q3")
    } else if (name <= 6) {
      setQuarter("Q4")
    }

    for (let i = 0; i < len; i++) {
      valuesMonth[i] = month[new Date().getMonth()]
    }
  }, [])

  useEffect(() => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let quarters = ""
    let currentMonth: Array<string> = []
    if (quarter === "Q1") {
      currentMonth = months.slice(0, 3)
      quarters = "quarter1"
    } else if (quarter === "Q2") {
      currentMonth = months.slice(3, 6)
      quarters = "quarter2"
    } else if (quarter === "Q3") {
      currentMonth = months.slice(6, 9)
      quarters = "quarter3"
    } else if (quarter === "Q4") {
      currentMonth = months.slice(9, 12)
      quarters = "quarter4"
    }

    let quaterlyTotal = 0

    const res2 = purchaseHistoryByYear && purchaseHistoryByYear.data && purchaseHistoryByYear.data.purchaseData.map((ele: any) => {
      if (currentMonth.includes(ele.month)) {
        quaterlyTotal += parseInt(ele.purchaseAmount)
      }
    })
    setQuarterlySpendData(quaterlyTotal);
  }, [purchaseHistoryByYear, quarter])


  useEffect(() => {
    let url = window.location.href.split("/")
    let currentUrl = url[url.length - 1]
    setCurrentURL(currentUrl);
    async function call() {
      if (currentUrl === "achievementssuperadmin") {
        let res: any = await (dispatch(getPurChaseHistoryByYear(b2bUserId, webApplicationLoginId, sapCustomerIdNew, "2022", "superadmin")))
        dispatch(getCreditLimit(sapCustomerIdNew, webApplicationLoginId));
        if (res.status) {
          setAchievementNotFound(true)
        }
        //dispatch(getPurChaseHistoryByYearKam("",webApplicationLoginId,sapCustomerIdNew,"2022"))
      } if (currentUrl === "achievementskam") {
        let res1: any = await dispatch(getPurChaseHistoryByYear(b2bUserId, webApplicationLoginId, sapCustomerIdNew, "2022", "kam"))
        dispatch(getCreditLimit(sapCustomerIdNew, webApplicationLoginId));
        if (res1.status) {
          setAchievementNotFound(true)
        }
      } if (currentUrl === "achievements") {
        let res = await (dispatch(getPurChaseHistoryByYear(b2bUserId, webApplicationLoginId, sapCustomerId, "2022", "dealer")))
      }
    }
    call()
      .catch((err) => {
      })
  }, [])

  useEffect(() => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
      dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
    }
  }, [profileDetails]) // eslint-disable-line

  const getCreditValue = (creditLimit: any) => {
    let value = (parseInt(creditLimit.split('.'))) * 100;
    if (value > 100 || value < 0) value = -1;
    return value;
  }

  const getAvaliableBalance = (availableBalance: any) => {
    let value = (parseInt(availableBalance.split('.'))) * 100;
    if (value > 100 || value < 0) value = -1;
    return value;
  }

  const comsumerTierData: any = {
    silver: {
      neededAmount: planfeaturedData?.featurePlans[0].features["Monthly Spend"]?.value,
      nextTier: 'gold'
    },
    gold: {
      neededAmount: planfeaturedData?.featurePlans[1].features["Monthly Spend"]?.value,
      nextTier: 'platinum'
    },
    elite: {
      neededAmount: planfeaturedData?.featurePlans[3].features["Monthly Spend"]?.value,
    },
    platinum: {
      neededAmount: planfeaturedData?.featurePlans[2].features["Monthly Spend"]?.value,
      nextTier: 'elite'
    }
  }


  if (!datafetched && purchaseDataAll) {
    let total = 0
    purchaseDataAll.map((item: any, index: any) => {
      if (activequater == 'quater1' && (item.month == 'Jan' || item.month == 'Feb' || item.month == 'Mar')) {
        total += item.purchaseAmount
      } else if (activequater == 'quater2' && (item.month == 'Apr' || item.month == 'May' || item.month == 'Jun')) {
        total += item.purchaseAmount
      }
      else if (activequater == 'quater3' && (item.month == 'Jul' || item.month == 'Aug' || item.month == 'Sep')) {
        total += item.purchaseAmount
      }
      else if (activequater == 'quater4' && (item.month == 'Oct' || item.month == 'Nov' || item.month == 'Dec')) {
        total += item.purchaseAmount
      }
    })
    setdatafetched(true)
    setamountSpentData(total)
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'auto'
    });
  };

  const showText = (text: any) => {
    if (text && showStars[text]) {
      return showStars[text]
    } else {
      return text
    }
  }


  function createData(
    name: string,
    calories: string,
    fat: string,
    carbs: string,
    protein: string
  ) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [createData("Balance / Credit Limit", "AED 1,00,000/ 5,00,000", "AED 1,00,000/ 5,00,000", "AED 1,00,000/ 5,00,000", "AED 1,00,000/ 5,00,000",)];

  let allColumns: any = planfeaturedData?.featurePlans && planfeaturedData?.featurePlans[0] ? Object.keys(planfeaturedData?.featurePlans[0].features) : []

  allColumns = allColumns.sort();

  const showCreditStatus = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_CREDIT_STATUS);

  function convertToInternationalCurrencySystem(labelValue: any) {
    return Math.abs(Number(labelValue)) >= 1.0e+9
      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
      : Math.abs(Number(labelValue)) >= 1.0e+6
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
        : Math.abs(Number(labelValue)) >= 1.0e+3
          ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"
          : Math.abs(Number(labelValue));
  }

  return (
    <>
      {achievement === false
        ? (<>
          <div>
            {creditLimitDetails && creditLimitDetails.loading && <FullscreenLoader />}
            <div className="d-flex align-items-center mb-2">
              <h4 className="page-title mb-3">
                Achievements
              </h4>
              <div className="ml-auto">
                <AnnouncementIcon />
                <NotificationIcon />
              </div>
            </div>
          </div>
          {currentUrl === "achievementssuperadmin" && (
            <>
              <div>
                <div className="d-flex bd-highlight">
                  <div className="flex-grow-1 bd-highlight"><h5 className="page-sub-title heading-text mt-2">Brands & Credit limit</h5></div>
                  <div className="bd-highlight">
                    <Button variant="contained" className={`resetToDefaultActionButton border-left rounded-pill mr-3`}
                      onClick={() => { history.push('/superadmindealers'); }}
                    >Back To Dealer List</Button>
                  </div>
                </div>
              </div>
              <div className="row mt-4 d-flex d-lg-row d-md-column d-sm-column">
                <div className="px-2 col-lg-6 col-sm-12 mb-2">
                  <div className="col-12 card p-0 card-height-achievement">
                    <ThemeProvider theme={theme}>
                      <TableContainer component={Paper} className="border-top-0 border-bottom-0">
                        <Table aria-label="" className="table table-bordered text-center">
                          <TableHead className="sticky-top bg-achivement-cutomer-tier">
                            <TableRow className="">
                              <TableCell className="header-color">Brand Name</TableCell>
                              <TableCell component="th" scope="row" className="header-color">
                                Balance/ Credit Limit
                              </TableCell >
                            </TableRow>
                          </TableHead>
                          <TableBody className="">
                            {creditLimitDetails && creditLimitDetails.data && creditLimitDetails.data.data && creditLimitDetails.data.data.data && creditLimitDetails.data.data.data.length > 0 && creditLimitDetails.data.data.data.map((limit: any, index: any) => (
                              <TableRow className="">
                                <TableCell className="brand-names">{getNewBrand(limit.brand)}</TableCell>
                                <TableCell align="left" className="brand-names">
                                  {parseInt(limit.creditDetails.availableBalance) ? getInternationalNumbers(parseInt(limit.creditDetails.availableBalance)) : "0"}
                                  {" / "} {parseInt(limit.creditDetails.creditLimit) ? getInternationalNumbers(parseInt(limit.creditDetails.creditLimit)) : "0"}
                                </TableCell>
                                {/* {creditLimitDetails && creditLimitDetails.data && creditLimitDetails.data.data && creditLimitDetails.data.data.data && creditLimitDetails.data.data.data.length > 0 && creditLimitDetails.data.data.data.map((limit: any, index: any) => (
                                        ))} */}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {webApplicationLoginId === null && webApplicationLoginId === "" &&
                        (
                          <span className="limitExceed text-center mt-4 mx-auto ml-5">Unable to fetch Credit Limit</span>
                        )}
                    </ThemeProvider>
                  </div>
                </div>
                {customerTierInfo?.currentTier && comsumerTierData && comsumerTierData[customerTierInfo?.currentTier.toLowerCase()] &&
                  <div className="card card-height-achievement p-0 col-lg-6 col-sm-12 col-md-12 mt-0">
                    <div className="d-flex bd-highlight mb-3 bg-achivement-cutomer-tier current-brand-tier">
                      <div className="col-md-3 col-lg-3 col-sm-3 bg-achivement current-brand-tier">
                        <div className="mt-4 pr-2 pl-4 mb-3">
                          <div className="row bd-highlight font-achivement customer-tier-plan" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "14px" }}>Current Plan </div>
                        </div>
                      </div>
                      <div className="bd-highlight mt-3 pl-4 mb-2 font-achivememt customer-tier-heading">
                        {customerTierInfo?.currentTier === "Silver" &&
                          <>
                            <HomeIcon className="mb-1 px-1 font-achivement" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "30px" }} />
                            {customerTierInfo?.currentTier}
                          </>}
                        {customerTierInfo?.currentTier === "Gold" &&
                          <>
                            <MenuIcon className="mb-1 px-1 font-achivement" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "30px" }} />
                            {customerTierInfo?.currentTier}
                          </>}{customerTierInfo?.currentTier === "Platinum" &&
                            <>
                              <EmojiEventsOutlined className="mb-1 px-1 font-achivement" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "30px" }} />
                              {customerTierInfo?.currentTier}
                            </>}{customerTierInfo?.currentTier === "Elite" &&
                              <>
                                <FilterTiltShiftRoundedIcon className="mb-1 px-1 font-achivement" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "30px" }} />
                                {customerTierInfo?.currentTier}
                              </>}
                      </div>
                      <div className="ml-auto bd-highlight mt-3 mb-2 pr-2"
                        style={{ fontSize: "12px", color: "white", cursor: "pointer" }} >
                        <Button
                          className="font-achivement customer-tier-plan"
                          style={{ fontFamily: "Nunito !importatnt", fontSize: "12px", color: "white", cursor: "pointer" }}
                          startIcon={<Visibility />}
                          onClick={() => { scrollToDiv.current.scrollIntoView() }}
                        >View Plans</Button>
                      </div>
                    </div>
                    <div className="cardCommon d-flex flex-row flex-lg-row flex-md-column flex-sm-column flex-column">
                      <div className="col-md-12 mt-2 mb-2">
                        <div className="d-flex align-items-center">
                          {/* <h6 className="dealer-table-content mb-0"></h6> */}
                          {quarterlySpendData < (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3) ?
                            <div className="row d-flex flex-row pr-3 pl-3 mt-2 mb-2 font-achivememt brand-names" style={{ fontSize: "10px", }}>
                              To remain {customerTierInfo?.currentTier === "Elite" ? "an" : "a"} {customerTierInfo?.currentTier} Customer spend more : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div className="row px-3" style={{ color: "#1A9FE0" }}>
                                {quarterlySpendData < (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3)
                                  ? getInternationalNumbers((comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3) - quarterlySpendData)
                                  : 0}</div>
                            </div> :
                            <div className="d-flex flex-row  pr-1 pl-2 mt-2 mb-2 font-achivememt brand-names"
                              style={{ fontSize: "10px", }}>You have achieved your {customerTierInfo?.currentTier} target</div>
                          }
                          <div className="row-space-start ml-auto sm">
                            <div className="row d-flex flex-row pr-1 pl-3 mt-2 mb-2  ml-2 font-achivment brand-names bd-highlight">Target Date: <div className="pl-1" style={{ color: "#1A9FE0" }}>{activequater && quaterEndDates[activequater]}</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cardCommon d-flex flex-row flex-lg-row flex-md-column flex-sm-column flex-column">
                      <div className="col-md-12 mt-2 mb-2">
                        <div className="d-flex align-items-center">
                          {comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier ?
                            quarterlySpendData < (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3)
                              ?
                              <div className="row brand-names d-flex flex-row pr-2 pl-3 mt-2 mb-2">To be eligible to become {comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier === "elite" ? "an" : "a"} <p className="row text-capitalize px-3">{comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier}</p> Customer spend more
                                :
                                <div className="row px-3" style={{ color: "#1A9FE0" }}>
                                  {quarterlySpendData < (comsumerTierData[comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier].neededAmount * 3)
                                    ?
                                    getInternationalNumbers((comsumerTierData[comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier].neededAmount * 3) - quarterlySpendData) : 0} </div></div> :
                              <div className="brand-names d-flex flex-row  pr-1 pl-2 mt-2 mb-2">
                                <div className="d-flex flex-row">
                                  To be eligible to become {" "}
                                  <p className="row text-capitalize px-3">{comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier}</p> spend more :</div>
                                <p>  <div className="row px-3" style={{ color: "#1A9FE0" }}>
                                  {quarterlySpendData < (comsumerTierData[comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier].neededAmount * 3)
                                    ?
                                    getInternationalNumbers((comsumerTierData[comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier].neededAmount * 3) - quarterlySpendData) : 0} </div></p>
                              </div>
                            : <div className="brand-names d-flex flex-row  pr-1 pl-2 mt-2 mb-2">{quarterlySpendData >= (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3) ? "You are already an Elite member" : ""}  </div>}
                          <div className="row-space-start ml-auto sm">
                            <div className="row d-flex flex-row pr-1 pl-3 mt-2 mb-2 font-achivment brand-names bd-highlight">{quarterlySpendData >= (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3) ? <>Target Date: <div className="pl-1" style={{ color: "#1A9FE0" }}>{activequater && quaterEndDates[activequater]}</div></> : ""} </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div >
            </>
          )}
          {currentUrl === "achievementskam" && (
            <>
              <div>
                <div className="d-flex bd-highlight">
                  <div className="flex-grow-1 bd-highlight"><h5 className="page-sub-title heading-text mt-2">Brands & Credit limit</h5></div>
                  <div className="bd-highlight">
                    <Button variant="contained" className={`resetToDefaultActionButton border-left rounded-pill mr-3`}
                      onClick={() => { history.push('/dealers'); }}
                    >Back To Dealer List</Button>
                  </div>
                </div>
              </div>
              {/* <div><h5 className="page-sub-title heading-text mt-3 mb-2">Brands & Credit limit</h5></div> */}
              <div className="row mt-4 d-flex d-lg-row d-md-column d-sm-column">
                <div className="px-2 col-lg-6 col-sm-12 mb-2">
                  <div className="col-12 card p-0 card-height-achievement">
                    <ThemeProvider theme={theme}>
                      <TableContainer component={Paper} className="border-top-0 border-bottom-0">
                        <Table aria-label="" className="table table-bordered text-center">
                          <TableHead className="sticky-top bg-achivement-cutomer-tier">
                            <TableRow className="">
                              <TableCell className="header-color">Brand Name</TableCell>
                              <TableCell component="th" scope="row" className="header-color">
                                Balance/ Credit Limit
                              </TableCell >
                            </TableRow>
                          </TableHead>
                          <TableBody className="">
                            {creditLimitDetails && creditLimitDetails.data && creditLimitDetails.data.data && creditLimitDetails.data.data.data && creditLimitDetails.data.data.data.length > 0 && creditLimitDetails.data.data.data.map((limit: any, index: any) => (
                              <TableRow className="">
                                <TableCell className="brand-names">{getNewBrand(limit.brand)}</TableCell>
                                <TableCell align="left" className="brand-names">
                                  {parseInt(limit.creditDetails.availableBalance) ? getInternationalNumbers(parseInt(limit.creditDetails.availableBalance)) : "0"}
                                  {" / "} {parseInt(limit.creditDetails.creditLimit) ? getInternationalNumbers(parseInt(limit.creditDetails.creditLimit)) : "0"}
                                </TableCell>
                                {/* {creditLimitDetails && creditLimitDetails.data && creditLimitDetails.data.data && creditLimitDetails.data.data.data && creditLimitDetails.data.data.data.length > 0 && creditLimitDetails.data.data.data.map((limit: any, index: any) => (
                                        ))} */}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {webApplicationLoginId === null && webApplicationLoginId === "" &&
                        (
                          <span className="limitExceed text-center mt-4 mx-auto ml-5">Unable to fetch Credit Limit</span>
                        )}
                    </ThemeProvider>
                  </div>
                </div>
                {customerTierInfo?.currentTier && comsumerTierData && comsumerTierData[customerTierInfo?.currentTier.toLowerCase()] &&
                  <div className="card card-height-achievement p-0 col-lg-6 col-sm-12 col-md-12 mt-0">
                    <div className="d-flex bd-highlight mb-3 bg-achivement-cutomer-tier current-brand-tier">
                      <div className="col-md-3 col-lg-3 col-sm-3 bg-achivement current-brand-tier">
                        <div className="mt-4 pr-2 pl-4 mb-3">
                          <div className="row bd-highlight font-achivement customer-tier-plan" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "14px" }}>Current Plan </div>
                        </div>
                      </div>
                      <div className="bd-highlight mt-3 pl-4 mb-2 font-achivememt customer-tier-heading">
                        {customerTierInfo?.currentTier === "Silver" &&
                          <>
                            <HomeIcon className="mb-1 px-1 font-achivement" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "30px" }} />
                            {customerTierInfo?.currentTier}
                          </>}
                        {customerTierInfo?.currentTier === "Gold" &&
                          <>
                            <MenuIcon className="mb-1 px-1 font-achivement" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "30px" }} />
                            {customerTierInfo?.currentTier}
                          </>}{customerTierInfo?.currentTier === "Platinum" &&
                            <>
                              <EmojiEventsOutlined className="mb-1 px-1 font-achivement" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "30px" }} />
                              {customerTierInfo?.currentTier}
                            </>}{customerTierInfo?.currentTier === "Elite" &&
                              <>
                                <FilterTiltShiftRoundedIcon className="mb-1 px-1 font-achivement" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "30px" }} />
                                {customerTierInfo?.currentTier}
                              </>}
                      </div>
                      <div className="ml-auto bd-highlight mt-3 mb-2 pr-2"
                        style={{ fontSize: "12px", color: "white", cursor: "pointer" }} >
                        <Button
                          className="font-achivement customer-tier-plan"
                          style={{ fontFamily: "Nunito !importatnt", fontSize: "12px", color: "white", cursor: "pointer" }}
                          startIcon={<Visibility />}
                          onClick={() => { scrollToDiv.current.scrollIntoView() }}
                        >View Plans</Button>
                      </div>
                    </div>
                    <div className="cardCommon d-flex flex-row flex-lg-row flex-md-column flex-sm-column flex-column">
                      <div className="col-md-12 mt-2 mb-2">
                        <div className="d-flex align-items-center">
                          {/* <h6 className="dealer-table-content mb-0"></h6> */}
                          {quarterlySpendData < (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3) ?
                            <div className="row d-flex flex-row pr-3 pl-3 mt-2 mb-2 font-achivememt brand-names" style={{ fontSize: "10px", }}>
                              To remain {customerTierInfo?.currentTier === "Elite" ? "an" : "a"} {customerTierInfo?.currentTier} Customer spend more : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div className="row px-3" style={{ color: "#1A9FE0" }}>
                                {quarterlySpendData < (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3)
                                  ? getInternationalNumbers((comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3) - quarterlySpendData)
                                  : 0}</div>
                            </div> :
                            <div className="d-flex flex-row  pr-1 pl-2 mt-2 mb-2 font-achivememt brand-names"
                              style={{ fontSize: "10px", }}>You have achieved your {customerTierInfo?.currentTier} target</div>
                          }
                          <div className="row-space-start ml-auto sm">
                            <div className="row d-flex flex-row pr-1 pl-3 mt-2 mb-2  ml-2 font-achivment brand-names bd-highlight">Target Date: <div className="pl-1" style={{ color: "#1A9FE0" }}>{activequater && quaterEndDates[activequater]}</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cardCommon d-flex flex-row flex-lg-row flex-md-column flex-sm-column flex-column">
                      <div className="col-md-12 mt-2 mb-2">
                        <div className="d-flex align-items-center">
                          {comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier ?
                            quarterlySpendData < (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3)
                              ?
                              <div className="row brand-names d-flex flex-row pr-2 pl-3 mt-2 mb-2">To be eligible to become {comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier === "elite" ? "an" : "a"} <p className="row text-capitalize px-3">{comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier}</p> Customer spend more
                                :
                                <div className="row px-3" style={{ color: "#1A9FE0" }}>
                                  {quarterlySpendData < (comsumerTierData[comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier].neededAmount * 3)
                                    ?
                                    getInternationalNumbers((comsumerTierData[comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier].neededAmount * 3) - quarterlySpendData) : 0} </div></div> :
                              <div className="brand-names d-flex flex-row  pr-1 pl-2 mt-2 mb-2">
                                <div className="d-flex flex-row">
                                  To be eligible to become {" "}
                                  <p className="row text-capitalize px-3">{comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier}</p> spend more :</div>
                                <p>  <div className="row px-3" style={{ color: "#1A9FE0" }}>
                                  {quarterlySpendData < (comsumerTierData[comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier].neededAmount * 3)
                                    ?
                                    getInternationalNumbers((comsumerTierData[comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier].neededAmount * 3) - quarterlySpendData) : 0} </div></p>
                              </div>
                            : <div className="brand-names d-flex flex-row  pr-1 pl-2 mt-2 mb-2">{quarterlySpendData >= (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3) ? "You are already an Elite member" : ""} </div>}
                          <div className="row-space-start ml-auto sm">
                            <div className="row d-flex flex-row pr-1 pl-3 mt-2 mb-2 font-achivment brand-names bd-highlight">{quarterlySpendData >= (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3) ? <>Target Date: <div className="pl-1" style={{ color: "#1A9FE0" }}>{activequater && quaterEndDates[activequater]}</div></> : ""} </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div >
            </>
          )}
          {currentUrl === "achievements" && (
            <>
              {showCreditStatus && <div><h5 className="page-sub-title heading-text mt-3 mb-2">Brands & Credit limit</h5></div>}
              <div className="row mt-4 d-flex d-lg-row d-md-column d-sm-column">
                {showCreditStatus && <div className="px-2 col-lg-6 col-sm-12 mb-2">
                  <div className="col-12 card p-0 card-height-achievement">
                    <ThemeProvider theme={theme}>
                      <TableContainer component={Paper} className="border-top-0 border-bottom-0">
                        <Table aria-label="" className="table table-bordered text-center">
                          <TableHead className="sticky-top bg-achivement-cutomer-tier">
                            <TableRow className="">
                              <TableCell className="header-color">Brand Name</TableCell>
                              <TableCell component="th" scope="row" className="header-color">
                                Balance/ Credit Limit
                              </TableCell >
                            </TableRow>
                          </TableHead>
                          <TableBody className="">
                            {creditLimitDetails && creditLimitDetails.data && creditLimitDetails.data.data && creditLimitDetails.data.data.data && creditLimitDetails.data.data.data.length > 0 && creditLimitDetails.data.data.data.map((limit: any, index: any) => (
                              <TableRow className="">
                                <TableCell className="brand-names">{getNewBrand(limit.brand)}</TableCell>
                                <TableCell align="left" className="brand-names">
                                  {parseInt(limit.availableBalance) ? getInternationalNumbers(parseInt(limit.availableBalance)) : "0"}
                                  {" / "} {parseInt(limit.creditLimit) ? getInternationalNumbers(parseInt(limit.creditLimit)) : "0"}
                                </TableCell>
                                {/* {creditLimitDetails && creditLimitDetails.data && creditLimitDetails.data.data && creditLimitDetails.data.data.data && creditLimitDetails.data.data.data.length > 0 && creditLimitDetails.data.data.data.map((limit: any, index: any) => (
                                        ))} */}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {webApplicationLoginId === null && webApplicationLoginId === "" &&
                        (
                          <span className="limitExceed text-center mt-4 mx-auto ml-5">Unable to fetch Credit Limit</span>
                        )}
                    </ThemeProvider>
                  </div>
                </div>}
                {customerTierInfo?.currentTier && comsumerTierData && comsumerTierData[customerTierInfo?.currentTier.toLowerCase()] &&
                  <div className="card card-height-achievement p-0 col-lg-6 col-sm-12 col-md-12 mt-0">
                    <div className="d-flex bd-highlight mb-3 bg-achivement-cutomer-tier current-brand-tier">
                      <div className="col-md-3 col-lg-3 col-sm-3 bg-achivement current-brand-tier">
                        <div className="mt-4 pr-2 pl-4 mb-3">
                          <div className="row bd-highlight font-achivement customer-tier-plan" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "14px" }}>Current Plan </div>
                        </div>
                      </div>
                      <div className="bd-highlight mt-3 pl-4 mb-2 font-achivememt customer-tier-heading">
                        {customerTierInfo?.currentTier === "Silver" &&
                          <>
                            <HomeIcon className="mb-1 px-1 font-achivement" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "30px" }} />
                            {customerTierInfo?.currentTier}
                          </>}
                        {customerTierInfo?.currentTier === "Gold" &&
                          <>
                            <MenuIcon className="mb-1 px-1 font-achivement" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "30px" }} />
                            {customerTierInfo?.currentTier}
                          </>}{customerTierInfo?.currentTier === "Platinum" &&
                            <>
                              <EmojiEventsOutlined className="mb-1 px-1 font-achivement" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "30px" }} />
                              {customerTierInfo?.currentTier}
                            </>}{customerTierInfo?.currentTier === "Elite" &&
                              <>
                                <FilterTiltShiftRoundedIcon className="mb-1 px-1 font-achivement" style={{ fontFamily: "Nunito !importatnt", color: "white", fontSize: "30px" }} />
                                {customerTierInfo?.currentTier}
                              </>}
                      </div>
                      <div className="ml-auto bd-highlight mt-3 mb-2 pr-2"
                        style={{ fontSize: "12px", color: "white", cursor: "pointer" }} >
                        <Button
                          className="font-achivement customer-tier-plan"
                          style={{ fontFamily: "Nunito !importatnt", fontSize: "12px", color: "white", cursor: "pointer" }}
                          startIcon={<Visibility />}
                          onClick={() => { scrollToDiv.current.scrollIntoView() }}
                        >View Plans</Button>
                      </div>
                    </div>
                    <div className="cardCommon d-flex flex-row flex-lg-row flex-md-column flex-sm-column flex-column">
                      <div className="col-md-12 mt-2 mb-2">
                        <div className="d-flex align-items-center">
                          {/* <h6 className="dealer-table-content mb-0"></h6> */}
                          {quarterlySpendData < (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3) ?
                            <div className="row d-flex flex-row pr-3 pl-3 mt-2 mb-2 font-achivememt brand-names" style={{ fontSize: "10px", }}>
                              To remain {customerTierInfo?.currentTier === "Elite" ? "an" : "a"} {customerTierInfo?.currentTier} Customer spend more : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <div className="row px-3" style={{ color: "#1A9FE0" }}>
                                {quarterlySpendData < (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3)
                                  ? getInternationalNumbers((comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3) - quarterlySpendData)
                                  : 0}</div>
                            </div> :
                            <div className="d-flex flex-row  pr-1 pl-2 mt-2 mb-2 font-achivememt brand-names"
                              style={{ fontSize: "10px", }}>You have achieved your {customerTierInfo?.currentTier} target</div>
                          }
                          <div className="row-space-start ml-auto sm">
                            <div className="row d-flex flex-row pr-1 pl-3 mt-2 mb-2  ml-2 font-achivment brand-names bd-highlight">Target Date: <div className="pl-1" style={{ color: "#1A9FE0" }}>{activequater && quaterEndDates[activequater]}</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cardCommon d-flex flex-row flex-lg-row flex-md-column flex-sm-column flex-column">
                      <div className="col-md-12 mt-2 mb-2">
                        <div className="d-flex align-items-center">
                          {comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier ?
                            quarterlySpendData < (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3)
                              ?
                              <div className="row brand-names d-flex flex-row pr-2 pl-3 mt-2 mb-2">To be eligible to become {comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier === "elite" ? "an" : "a"} <p className="row text-capitalize px-3">{comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier}</p> Customer spend more
                                :
                                <div className="row px-3" style={{ color: "#1A9FE0" }}>
                                  {quarterlySpendData < (comsumerTierData[comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier].neededAmount * 3)
                                    ?
                                    getInternationalNumbers((comsumerTierData[comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier].neededAmount * 3) - quarterlySpendData) : 0} </div></div> :
                              <div className="brand-names d-flex flex-row  pr-1 pl-2 mt-2 mb-2">
                                <div className="d-flex flex-row">
                                  To be eligible to become {" "}
                                  <p className="row text-capitalize px-3">{comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier}</p> spend more :</div>
                                <p>  <div className="row px-3" style={{ color: "#1A9FE0" }}>
                                  {quarterlySpendData < (comsumerTierData[comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier].neededAmount * 3)
                                    ?
                                    getInternationalNumbers((comsumerTierData[comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].nextTier].neededAmount * 3) - quarterlySpendData) : 0} </div></p>
                              </div>
                            : <div className="brand-names d-flex flex-row  pr-1 pl-2 mt-2 mb-2">{quarterlySpendData >= (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3) ? "You are already an Elite member" : ""} </div>}
                          <div className="row-space-start ml-auto sm">
                            <div className="row d-flex flex-row pr-1 pl-3 mt-2 mb-2 font-achivment brand-names bd-highlight">{quarterlySpendData >= (comsumerTierData[customerTierInfo?.currentTier.toLowerCase()].neededAmount * 3) ? <>Target Date: <div className="pl-1" style={{ color: "#1A9FE0" }}>{activequater && quaterEndDates[activequater]}</div> </> : ""}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div >
            </>
          )}
          <PurChaseHistory />
          <div><h5 className="page-sub-title heading-future-target mt-2 mb-0">*Future targets are subject to revision</h5></div>
          <div ref={scrollToDiv} className="d-flex align-items-center page-sub-title mt-3 mb-0"><h6 className="heading-text-features">Features</h6></div>
          <div className="row mt-2 d-flex d-lg-row d-md-column d-sm-column px-2">
            <div className="cardCommon w-100">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <h4 className="mt-5 mb-0">Features</h4>
                      </StyledTableCell>
                      {planfeaturedData?.featurePlans?.map((item: any, index: any) => {
                        return <StyledTableCell align="left"> <h4 className="color-info mb-3 font-20">{item.label}</h4>
                          <Button className="color-info btn-link font-18 ml-3 cursor-default" startIcon={iconsPlan[item.label.toLowerCase()]}
                          ></Button>
                        </StyledTableCell>
                      })}
                    </TableRow>
                  </TableHead>
                  {<TableBody>
                    {allColumns?.map((key: any) => {
                      return <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {key}
                        </StyledTableCell>
                        {planfeaturedData?.featurePlans?.map((item: any, index: any) => {
                          if (key == "Monthly Spend") {
                            return <StyledTableCell align="left">{item?.features[key]?.value ? convertToInternationalCurrencySystem(item?.features[key]?.value) : ""} {index + 1 < planfeaturedData?.featurePlans.length ? " - " + convertToInternationalCurrencySystem(planfeaturedData?.featurePlans[index + 1]?.features[key]?.value) : "+"}  </StyledTableCell>
                          } else {
                            return <StyledTableCell align="left">{item?.features[key]?.value ? key != "Monthly Spend" ? (((`${item?.features[key]?.value}`)?.toLowerCase() == 'yes' || (`${item?.features[key]?.value}`)?.toLowerCase() == 'no') ? ((`${item?.features[key]?.value}`)?.toLowerCase() == 'yes' ? <DoneIcon /> : "") : showText(item?.features[key]?.value)) : convertToInternationalCurrencySystem(item?.features[key]?.value) : ""}</StyledTableCell>
                          }

                        })}
                      </StyledTableRow>
                    })}
                  </TableBody>}
                </Table >
                <div className="pl-3 s-4 pb-4 col-12 pt-2 font-planfeatures">
                  <p>Comment:</p>
                  {planfeaturedData?.comment}
                </div>
                {/* {isEdit ?
                            (<div className="pl-3">
                                <div className="color-info cursor-pointer mt-3 mb-2" onClick={() => { seteditKeyName(true) }}>+ Add New Feature</div>
                                <br />
                                <div className="pb-4 pr-4 col-lg-none">
                                    <TextField
                                        label="Comment"
                                        size="small" className="col-sm-12 w-100 commonRoundedInputs"
                                        value={comment}
                                        variant="outlined" margin="dense"
                                        inputProps={{ maxLength: 1000 }}
                                        onChange={(e: any) => {
                                            setcomment(e.target.value)
                                        }}
                                    >
                                    </TextField>
                                </div>
                            </div>)
                            : (<div className="pl-3 mt-4 pb-4 col-12 font-planfeatures">
                                <p>Comment:</p>
                                {comment}
                            </div>
                            )} */}
              </TableContainer >
            </div>

          </div>
        </>) :
        <>
          {currentUrl === "achievementssuperadmin" && (
            <>
              <div>
                {creditLimitDetails && creditLimitDetails.loading && <FullscreenLoader />}
                <div className="d-flex align-items-center">
                  <h4 className="page-title mb-3">
                    Achievements
                  </h4>
                  <div className="ml-auto">
                    <AnnouncementIcon />
                    <NotificationIcon />
                  </div>
                </div>
                <div className="d-flex bd-highlight">
                  <div className="p-2 flex-grow-1 bd-highlight mt-3"></div>
                  <div className="p-2 bd-highlight">
                    <Button variant="contained" className={`resetToDefaultActionButton border-left rounded-pill mr-1`}
                      onClick={() => { history.push('/superadmindealers'); }}
                    >Back To Dealer List</Button>
                  </div>
                </div>
              </div>
              <Alert severity="info">Customer tier not found.</Alert>
            </>
          )}
          {currentUrl === "achievementskam" && (
            <>
              <div>
                {creditLimitDetails && creditLimitDetails.loading && <FullscreenLoader />}
                <div className="d-flex align-items-center">
                  <h4 className="page-title mb-3">
                    Achievements
                  </h4>
                  <div className="ml-auto">
                    <AnnouncementIcon />
                    <NotificationIcon />
                  </div>
                </div>
                <div className="d-flex bd-highlight">
                  <div className="p-2 flex-grow-1 bd-highlight mt-3"></div>
                  <div className="p-2 bd-highlight">
                    <Button variant="contained" className={`resetToDefaultActionButton border-left rounded-pill mr-1`}
                      onClick={() => { history.push('/dealers'); }}
                    >Back To Dealer List</Button>
                  </div>
                </div>
              </div>
              <Alert severity="info">Customer tier not found.</Alert>
            </>
          )}
          {currentUrl === "achievements" && (
            <>
              <Alert severity="info">Customer tier not found.</Alert>
            </>
          )}
        </>
      }
    </>
  )
}

export default Achivements