import React from 'react';
import { useHistory } from 'react-router-dom';
import specialdiscount from "../../assets/specialOffer.png";
import './SpecialOffers.css'

const SpecialOffers: React.FC = () => {

    
    const history = useHistory();

    return (
        <>
            <h5 className="page-sub-title">Special Offers</h5>

            <div className="cardCommon height-400">

                <div className="col-12 mt-3">
                    <img className="img-fluid-costom" src={specialdiscount} alt=""
                        onClick={(e) => { e.preventDefault(); history.push('/specialofferdetail'); }} />
                </div>

            </div>
        </>
    )
}

export default SpecialOffers
