import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from "react-redux";
import clsx from 'clsx';
import XLSX from 'xlsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import DeleteDialog from '../../common/components/deleteDialog';
import { deleteFavorites, getFavoriteDetails } from '../../store/actions/favorites.actions';
import { ButtonGroup, Dialog } from '@material-ui/core';
import CreateFavorite from './createFavorite';
import { getNewBrand } from '../../common/components/util';

const headCells = [
  { id: 'srNo', numeric: true, disablePadding: true, label: 'Sr. No.' },
  { id: 'partNo', numeric: true, disablePadding: false, label: 'Part No.' },
  { id: 'desc', numeric: true, disablePadding: false, label: 'Desc.' },
  { id: 'brand', numeric: true, disablePadding: false, label: 'Brand' },
  { id: 'qty', numeric: true, disablePadding: false, label: 'Quantity' },
];

function EnhancedTableHead(props: any) {
  const { classes, onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
            className="m-3"
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableHeader}
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    flex: '1 1 100%',
    fontSize: 25,
    fontWeight: 'bold',
    marginLeft: '0.7rem   '
  },
  tableHeader: {
    color: "#133F8A",
    fontWeight: 900,
    fontFamily: 'Nunito',
    fontSize: 14
  },
  offer: {
    paddingRight: 30,
    borderRight: '2px solid #666666',
    fontWeight: 700,
    color: '#666666',
    fontSize: 14
  }
}));

export default function ViewFavoriteInfo(props: any) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<any>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const { favoritesDetails }: any = useSelector((state: any) => state.favorites);
  const dispatch: Dispatch<any> = useDispatch();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = favoritesDetails && favoritesDetails.data && favoritesDetails.data.products && favoritesDetails.data.products.map((n: any) => n.productId);
      setSelected(newSelecteds);
      return undefined;
    }
    setSelected([]);
  };

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
  };

  React.useEffect(() => {
    if (selected) {
      let products: any = [];
      favoritesDetails && favoritesDetails.data && favoritesDetails.data.products && favoritesDetails.data.products.map((product: any) => {
        selected.map((id: any) => {
          if (product.productId === id) products.push({ productId: product.productId, quantity: product.quantity });
          return undefined;
        })
        return undefined;
      })
      props.selectedProducts(products);
    }
  }, [selected, favoritesDetails]) // eslint-disable-line

  const handleClick = (event: any, name: any) => {
    const selectedIndex: any = selected.indexOf(name);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  React.useEffect(() => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === 'Admin') {
      setIsAdmin(true);
    } else if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === 'User') {
      setIsAdmin(false);
    }
  }, [profileDetails])

  const exportData = (products: any) => {
    if (products && Array.isArray(products) && products.length > 0) {
      let filename = 'favoriteParts.xlsx';
      let data: any = []
      products.map((item: any) => {
        data.push({
          'Part No': item.productId,
          'Description': item.productIdDescription,
          Brand: getNewBrand(item.make),
          Quantity: parseInt(item.quantity)
        })
        return undefined;
      })
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
    }
  }

  return (
    <div className={classes.root}>
      <Toolbar className={clsx(classes.root)}>
        <h4 className="page-title">Favorites</h4>
      </Toolbar>
      <Toolbar className="mr-1">
        <div className={`mr-4 ${classes.offer}`}>
          {favoritesDetails && favoritesDetails.data && favoritesDetails.data.name}
        </div>
        <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
          <Button
            className="text-info"
            onClick={() => exportData(favoritesDetails && favoritesDetails.data && favoritesDetails.data.products)}
            startIcon={<GetAppIcon />}
          >
            Download
          </Button>
          {isAdmin && (
            <Button
              className="text-info"
              onClick={() => { handleClickOpen(); dispatch(getFavoriteDetails(favoritesDetails.data)); }}
              startIcon={<EditOutlinedIcon />}
            >
              Edit
            </Button>
          )}
          {!isAdmin && favoritesDetails && favoritesDetails.data && favoritesDetails.data.userId === sessionStorage.getItem('webApplicationLoginId') ?
            <Button
              className="text-info"
              onClick={() => { handleClickOpen(); dispatch(getFavoriteDetails(favoritesDetails.data)); }}
              startIcon={<EditOutlinedIcon />}
            >
              Edit
            </Button> : ''}
          {isAdmin && (
            <Button
              className="text-info"
              onClick={() => { handleopenClose(true); dispatch(getFavoriteDetails(favoritesDetails.data)); }}
              startIcon={<DeleteOutlineOutlinedIcon />}
            >
              Delete
            </Button>
          )}
          {!isAdmin && favoritesDetails && favoritesDetails.data && favoritesDetails.data.userId === sessionStorage.getItem('webApplicationLoginId') ?
            <Button
              className="text-info"
              onClick={() => { handleopenClose(true); dispatch(getFavoriteDetails(favoritesDetails.data)); }}
              startIcon={<DeleteOutlineOutlinedIcon />}
            >
              Delete
            </Button> : ''}
        </ButtonGroup>
      </Toolbar>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          className="w-100"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={favoritesDetails && favoritesDetails.data && favoritesDetails.data.products && favoritesDetails.data.products.length}
          />
          <TableBody>
            {favoritesDetails && favoritesDetails.data && favoritesDetails.data.products && favoritesDetails.data.products.length && (
              <>
                {favoritesDetails.data.products.map((row: any, index: any) => {
                  const labelId: any = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.productId}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selected && selected.includes(row.productId) ? true : false}
                          inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
                          onClick={(event: any) => handleClick(event, row.productId)}
                          className="m-3"
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" className="p-0 text-light-gray">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" className="text-light-gray">{row.productId}</TableCell>
                      <TableCell align="left" className="text-light-gray">{row.productIdDescription}</TableCell>
                      <TableCell align="left" className="text-light-gray">{getNewBrand(row.make)}</TableCell>
                      <TableCell align="left" className="text-light-gray">{row.quantity}</TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
        <DeleteDialog
          open={openDeleteDialog}
          text="Are you sure to delete this favorite ?"
          handleopenClose={(data: any) => {
            if (data === 'delete') dispatch(deleteFavorites([favoritesDetails.data.id]))
            handleopenClose(false)
          }} />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          className="createFavoriteDialog"
          maxWidth="md"
        >
          <CreateFavorite setOpenDialog={handleClose} />
        </Dialog>
      </TableContainer>
    </div >
  );
}
