import { PRODUCTS_SEARCH } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  productsData: null
};

function productsReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case PRODUCTS_SEARCH.GET_PRODUCTS_SEARCH_FETCHING:
      return {
        ...state,
        productsData: { loading: true, data: null, err: null }
      };
    case PRODUCTS_SEARCH.GET_PRODUCTS_SEARCH_SUCCESS:
      return {
        ...state,
        productsData: { loading: false, data: action.payload.data, err: null }
      };
    case PRODUCTS_SEARCH.GET_PRODUCTS_SEARCH_ERROR:
      return {
        ...state,
        productsData: { loading: false, data: null, err: action.payload }
      };
    case PRODUCTS_SEARCH.REMOVE_PRODUCTS:
      return {
        ...state,
        productsData: null,
      };
    default:
      return state;
  }
};

export default productsReducer;
