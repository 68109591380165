import React, { Dispatch } from 'react';
import { useSelector } from 'react-redux';
import "./ReturnRequestDialog.css";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import {FormControl} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import { createReturnRequest } from '../../store/actions/orderHistory.actions';
import { useDispatch } from "react-redux";
import { useEffect } from 'react';
import { get } from 'lodash';
import Snackbar from './../../common/components/Snackbar';

const useStyles = makeStyles((theme) => ({

    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    cancelButton: {
        backgroundColor: '#EAEAEA',
        width: 140,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: 1000,
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    close: {
        color: '#000000',
        fontWeight: 900
    }
}));

export default function ReturnRequestDialog(props: any) {
    const classes = useStyles();
    const [salesOrderNumber, setSalesOrderNumber] = React.useState("");
    const [referenceNumber, setReferenceNumber] = React.useState("");
    const [deliveryNumber, setDeliveryNumber] = React.useState(props.selectedOrder && props.selectedOrder.deliveryNumber ? props.selectedOrder.deliveryNumber :"");
    const [invoiceNumber, setInvoiceNumber] = React.useState(props.selectedOrder && props.selectedOrder.invoiceNumber ? props.selectedOrder.invoiceNumber : "" );
    const [invoiceDate, setInvoiceDate] = React.useState(props.selectedOrder && props.selectedOrder.invoiceDate ? props.selectedOrder.invoiceDate : new Date());
    const [partNumbers, setPartNumbers] = React.useState(props.selectedOrder && props.selectedOrder.partNumbers ? props.selectedOrder.partNumbers : "");
    const [totalQuantity, setTotalQuantity] = React.useState(props.selectedOrder && props.selectedOrder.totalQuantity ? props.selectedOrder.totalQuantity : "");
    const [reason, setReason] = React.useState("");
    const [otherReason, setOtherReason] = React.useState("");
    const [showOtherReason, setShowotherReason] = React.useState(false);
    const [remark, setRemark] = React.useState(props.selectedOrder && props.selectedOrder.remark ? props.selectedOrder.remark : "");
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const firstName = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.firstName ? profileDetails.data.data.basicInfo.firstName : "";
    const lastName = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.lastName ? profileDetails.data.data.basicInfo.lastName : "";
    const { orderId }: any = useSelector((state: any) => state.orderHistory);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [apiResponseText, setApiResponseText] = React.useState(false);
    const dispatch: Dispatch<any> = useDispatch();
    let address = get(
        profileDetails,
        "data.data.customerInfo.addresses",
        ""
      );
    // const handleDateChange = (date: any) => {
    //     setInvoiceDate(date);
    // };

    if(typeof address != 'string'){
        if(typeof address == 'object'){
          let data = address.find((item:any) => item.firstAddress)
          if(data){
            address = `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}`
          }else{
            address = ``
          }
        }else{
          address = ""
        }
      }

    useEffect(()=>{
        const populateReturnRequest=()=>{
            props.selectedOrder && props.selectedOrder.orderNumber ?setSalesOrderNumber( props.selectedOrder.orderNumber):setSalesOrderNumber("") ;
            props.selectedOrder && props.selectedOrder.enquiryReferenceNumber ? setReferenceNumber(props.selectedOrder.enquiryReferenceNumber) : setReferenceNumber("");
        }
        populateReturnRequest();
    },[props.selectedOrder])

    const reasonHandle = (event: any) => {
        event.preventDefault();
        setReason(event.target.value);
    };
    const reasons= [
        "Damaged",
        "Wrong part#interpretation",
        "Mistakenly Excess quantity ordered/Order duplication",
        "Due to pricing error",
        "Excess",
        "Other"

    ]
    const handleSubmit = async (e:any) => {
        e.preventDefault();
        let hasError = false;
        if ((deliveryNumber.trim() === '') || (invoiceNumber.trim() === '') || (partNumbers.trim() === '')||(totalQuantity.trim() === '') ||(reason.trim() === '')||(remark.trim() === '') ) {
            hasError = true;
        }
       if(!hasError){
        let data = {
            orderId : orderId.orderId,
            orderReturnRequest: {
                salesOrderNumber: salesOrderNumber,
                referenceNumber: referenceNumber,
                deliveryNumber: deliveryNumber,
                invoiceNumber: invoiceNumber,
                invoiceDate: invoiceDate,
                partNumbers: partNumbers,
                totalQuantity: totalQuantity,
                reason: otherReason!== "" ? otherReason: reason,
                remark: remark,
            },
            emailDetails: {
                fromAddress: 'tradepoint@alfuttaim.com' ,
                toAddress: profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.email ? profileDetails.data.data.basicInfo.email : "",
                senderName: firstName.concat(lastName),
                subject: 'Order Return Request '+ props.selectedOrder.enquiryNumber ,
                templateName: 'B2C_OTP_VERIFY_EMAIL',
                templateData: {
                   date:"",
                   sendTo:"kamal",
                   url:"https://autob2bqa.corp-al-futtaim.com",
                   pickUpDate:"",
                   pickUpAddress:address ? address : "-"
                }
            },
        }

        let returndata= await dispatch(createReturnRequest(data));
        const apiResponse = get(returndata, 'message', {});
        if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
            setOpenSnackbar(true);
            setApiResponseText(apiResponse.message);
            setTimeout(() => {
                setOpenSnackbar(false);
            }, 2000);
        }
        setTimeout(()=>{
            props.handleopenClose(false)
        },2000)
       }
    };
    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };
    return <Dialog
        open={props.open ? true : false}
        onClose={() => { props.handleopenClose(false) }}
        fullWidth={true}
        maxWidth={'sm'}
    >

        <DialogTitle>
            <div className="justifyContentHorizontalSpacebetween align-items-center">
                <h4 className="page-title">Return Request</h4>
                <IconButton onClick={() => { props.handleopenClose(false) }}>
                        <CloseIcon fontSize="inherit" className={classes.close}/> 
                </IconButton>
            </div>
        </DialogTitle>

        <DialogContent>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-3">
                    <TextField 
                        required 
                        size="small" 
                        className="col-sm-12 my-2 commonRoundedInputs" 
                        label="Sales Order No." 
                        value={salesOrderNumber} 
                        disabled 
                        variant="outlined" />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 pl-3 mb-3">
                    <TextField 
                        required 
                        size="small" 
                        className="col-sm-12 my-2 commonRoundedInputs" 
                        label="Reference Number" 
                        value={referenceNumber} 
                        disabled 
                        variant="outlined" />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-3">
                    <TextField 
                        required 
                        size="small" 
                        className="col-sm-12 my-2 commonRoundedInputs" 
                        label="Delivery No." 
                        value={deliveryNumber} 
                        error={(deliveryNumber === '' || deliveryNumber.trim() === '') ? true : false} 
                        helperText={(deliveryNumber === '' || deliveryNumber.trim() === '') ? "Please Enter Delivery No." : ""} 
                        onChange={(e) => setDeliveryNumber(e.target.value)}  
                        variant="outlined" />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 pl-3 mb-3">
                    <TextField 
                        required 
                        size="small" 
                        className="col-sm-12 my-2 commonRoundedInputs" 
                        label="Invoice Number" 
                        value={invoiceNumber} 
                        error={(invoiceNumber === '' || invoiceNumber.trim() === '') ? true : false} 
                        helperText={(invoiceNumber === '' || invoiceNumber.trim() === '') ? "Please Enter InvoiceNumber" : ""} 
                        onChange={(e) => setInvoiceNumber(e.target.value)} 
                        variant="outlined" />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-3">
                    <KeyboardDatePicker
                        inputVariant="outlined"
                        size="small"
                        className="col-sm-12 my-2 commonRoundedInputs"
                        margin="normal"
                        label="Invoice Date"
                        format="dd/MM/yyyy"
                        value={invoiceDate}
                        onChange={(e)=>setInvoiceDate(e)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 pl-3 mb-3">
                    <TextField 
                        required 
                        size="small" 
                        className="col-sm-12 my-2 commonRoundedInputs" 
                        label="Total Parts No. Returned" 
                        type="number"
                        value={partNumbers}
                        error={(partNumbers === '' || partNumbers.trim() === '') ? true : false}  
                        helperText={(partNumbers === '' || partNumbers.trim() === '') ? "Please Enter PartNumber" : ""} 
                        onChange={(e) => setPartNumbers(e.target.value)} 
                        variant="outlined" />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-3">
                    <TextField 
                        required 
                        size="small" 
                        className="col-sm-12 my-2 commonRoundedInputs" 
                        label="Total Qty Returned" 
                        type="number"
                        value={totalQuantity}
                        error={(totalQuantity === '' || totalQuantity.trim() === '') ? true : false}
                        helperText={(totalQuantity === '' || totalQuantity.trim() === '') ? "Please enter TotalQuantity" : ""}  
                        onChange={(e) => setTotalQuantity(e.target.value)} 
                        variant="outlined" />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 pl-3 mb-3">
                    <FormControl variant="outlined" className="col-sm-12 my-2 commonRoundedInputs" size="small">
                        <InputLabel>Select Reason</InputLabel>
                        <Select required 
                            native
                            value={reason}
                            onChange={(e:any)=>{reasonHandle(e); if(e.target.value==="Other") setShowotherReason(true); else {setShowotherReason(false); setOtherReason("")} } }
                            label="Select Reason"
                            >
                            {reasons.map((reason)=>{
                                return <option key={reason} value={reason}>{reason}</option>
                            })}
                        </Select>
                    </FormControl>
                   {showOtherReason && (
                        <TextField 
                        required 
                        error={(reason === '' || reason.trim() === '') ? true : false}
                        helperText={(reason === '' || reason.trim() === '') ? "Please Enter Reason" : ""}
                        size="small" 
                        className="col-sm-12 my-2 commonRoundedInputs" 
                        label="Other" 
                        onChange={(e) => setOtherReason(e.target.value)} variant="outlined" />
                   )}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 px-3">
                    <TextField 
                        required error={(remark === '' || remark.trim() === '') ? true : false}
                        helperText={(remark === '' || remark.trim() === '') ? "Please Enter Remark" : ""}
                        className="col-sm-12 my-2 commonRoundedInputs"
                        label="Enter Remarks"
                        multiline
                        rows={4}
                        onChange={(e) => setRemark(e.target.value)}
                        variant="outlined"
                    />
                </div>
            </div>
        </DialogContent>

        <DialogActions className="mt-2 mb-4 justify-content-end">
            <Button className="add-user-tabs-button font-size-11 mr-2" onClick={handleSubmit}>Submit</Button>
            <Button variant="contained" className={classes.cancelButton} onClick={() => {props.handleopenClose(false)}}>Cancel</Button>
        </DialogActions>
        <Snackbar
                    open={openSnackbar}
                    handleopenClose={() => {
                        handleopenCloseSnackbar(false)
                    }} text={apiResponseText} />
    </Dialog >
}
