import React from "react"

import About from "./About"
import Footer from "./Footer"
import HomeContent from "./HomeContent"
import OurBrands from "./OurBrands"
import Register from "./Register"

const Content = (props:any) => {
    
    return (     
        <div className="site-content">
            {/* HomeContent */}
            <HomeContent pageData={props.pageData} />.

            {/* About (loop) */}
            <About pageData={props.pageData} />

            {/* Register with us */}
            <Register pageData={props.pageData} />

            {/* Our Brands */}
            <OurBrands pageData={props.pageData} />

            {/* Footer */}
            <Footer pageData={props.pageData} />
        </div>
    )
}

export default Content