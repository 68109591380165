import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import tempKamLogo from "./afg.jpeg"
import { Markup } from 'interweave';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  yes: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#1A9FE0',
    textTransform: 'capitalize'
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  bodyText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  }
}));

export default function TermsAndConditionsDialog(props: any) {

  const classes = useStyles();
  const { termsAndConditions }: any = useSelector((state: any) => state.termsAndConditions);
  const termsAndConditionsData = get(termsAndConditions, 'data', {});

  return (
    <div className="float-left">
      {termsAndConditionsData &&
        <Dialog
          open={props.open ? true : false}
          onClose={() => { props.handleopenClose(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogContent>
            <Grid container>
              <Grid item xs={2}>
                <img src={tempKamLogo} alt="logo" width="70px" height="70px" />
              </Grid>
              <Grid item xs={10}>
              <div>
                <DialogContentText id="alert-dialog-description">
                  <div className={`mt-3 ${classes.bodyText}`}>
                    {/* {props.text} */}
                    <Markup content={termsAndConditionsData.Article_Body} />
                  </div>
                </DialogContentText>
              </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button className={`mr-4 ${classes.cancel}`} onClick={() => { props.handleopenClose(false) }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div >
  );
}
