export const LOG_IN: string = "LOG_IN";
export const LOG_OUT: string = "LOG_OUT";

export function login(userData: any): ILogInActionType {
    return { type: LOG_IN, userData: userData };
}

export function logout(): ILogOutActionType {
    return { type: LOG_OUT };
}

interface ILogInActionType { type: string, userData: any };
interface ILogOutActionType { type: string };
