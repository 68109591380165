
import { DELIVERY } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { deliveryService } from "../../services/delivery.service";

export const deliveryActions = {
  getDeliveryList,
  getDeliveryDetails
};

export function getDeliveryList(orderNumber: any, enquiryNumber: any, make: any, backOrderNumber: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DELIVERY.GET_DELIVERY_LIST_FETCHING));
      const response = await deliveryService.getDeliveryList(orderNumber,enquiryNumber,make,backOrderNumber);
      if (response.error) {
        dispatch(apiError(DELIVERY.GET_DELIVERY_LIST_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(DELIVERY.GET_DELIVERY_LIST_SUCCESS, response));
        return response || [];
      }
  };
}

export function getDeliveryDetails(enquiryNumber: any, deliveryNumber: any, make: any, page?:any) {
    return async (dispatch: any) => {
        dispatch(apiStart(DELIVERY.GET_DELIVERY_DETAILS_FETCHING));
          const response = await deliveryService.getDeliveryDetails(enquiryNumber,deliveryNumber,make, page);
          if (response.error) {
            dispatch(apiError(DELIVERY.GET_DELIVERY_DETAILS_ERROR, response));
            return response || [];
          } else {
            dispatch(apiSuccess(DELIVERY.GET_DELIVERY_DETAILS_SUCCESS, response));
            return response || [];
          }
      };
}


