import React, {
  Fragment,
  Dispatch,
  useEffect,
} from "react";
import "./AdminDashboard.css";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import SpecialOffers from "../SpecialOffer/SpecialOffers";
import Announcements from "../AdminAnnouncement/Announcements";
import TablePagination from "@material-ui/core/TablePagination";
import Avatar from "@material-ui/core/Avatar";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import { closeTask, getTasks } from "../../store/actions/tasks.actions";
import { get } from "lodash";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import Snackbar from './../../common/components/Snackbar';
import DealerDetailsDialog from "../Dealers/DealerDetailsDialog";
import ReportComponent from "./ReportComponent";
import { getTermsAndConditions } from "../../store/actions/termsAndConditions.action";
import { useHistory } from 'react-router-dom';
import { getDealerDetails } from "../../store/actions/dealer.action";
import { getIP } from "../../common/components/util";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    textTransform: "none",
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  collapseBg: {
    background: "rgba(66, 94, 108, 0.07)",
  },
  bodyContent: {
    color: "#666666",
    fontWeight: 600,
    fontSize: 12,
    fontFamily: "Nunito",
    padding: 0,
    align: "center",
    maxWidth: "165px",
  },

  collapseIcon: {
    color: "#000000",
    fontWeight: 500,
  },
  tableHead: {
    color: "#133F8A",
    background: "#FFFFFF",
    fontWeight: 800,
    fontSize: 13,
    fontFamily: "Nunito",
    paddingTop: 15,
    lineHeight: 0,
    position: "sticky",
  },
  tableTitle: {
    fontWeight: 800,
    background: "FFFFFF",
    fontSize: 15,
    fontFamily: "Nunito",
    paddingLeft: 15,
    paddingTop: 15,
    paddingBottom: 15,
    position: "sticky",
    top: 0,
    color: '#000000'
  },
  tableContainer: {
    overflow: "auto",
    height: "388px",
    borderTop: "1px solid #0000001A",
  },
  blueText: {
    fontSize: 11,
    fontWeight: 700,
    paddingRight: 0,
    color: "#1A9FE0",
    fontFamily: "Nunito"
  },

  subTableCell: {
    color: "#666666",
    fontSize: 10,
    fontFamily: "Nunito",
    fontWeight: 600
    // minWidth: 100,
  },
  // redText: {
  //   color: "#EC4242",
  //   fontSize: 11,
  //   fontWeight: 700,
  //   fontFamily: "Nunito",
  // },
  actionButton: {
    color: "#1A9FE0",
  },
  subTableBodyContent: {
    color: "#666666",
    fontWeight: 600,
    fontSize: "0.75rem",
    fontFamily: "Nunito",
  },
}));

interface Column {
  id: "name" | "openTask" | "action";
  label: string;
  align: "left";
}
const columns: Column[] = [
  {
    id: "name",
    label: "Name",
    align: "left",
  },
  {
    id: "openTask",
    label: "Open Task",
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    align: "left",
  },
];

const AdminDashboard: React.FC = () => {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const [page, setPage] = React.useState(0);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [closeTaskSuccess, setCloseTaskSuccess] = React.useState("");
  const [openDealerDetailDialog, setOpenDealerDetailDialog] = React.useState(false);
  const tasks: any = useSelector((state: any) => state.tasks);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");
  const tasksList = get(tasks, 'tasksList.data.openTaskList', []);
  
  useEffect(() => {
    dispatch(getTermsAndConditions());
    getIP();
    sessionStorage.setItem('webApplicationLoginId', webApplicationLoginId);
    // dispatch(userLogon('Admin'));
    if (tasks === null || tasks.tasksList === null) {
      dispatch(getTasks(page));
    }
  }, []); // eslint-disable-line

  function Row(props: { row: any }) {
    const { row } = props;
    const taskDoneLoading = get(tasks, 'closeTask.data.loading', false);

    const handleopenCloseSnackbar = (open?: any) => {
      setOpenSnackbar(open ? true : false);
    };

    const handleCloseTask = async () => {
      const closeTask: any = await dispatch(getTasks(page));
      setCloseTaskSuccess(closeTask && closeTask.message && closeTask.message.status)
      if ((closeTask && closeTask.message && closeTask.message.status === "S") || (closeTask && closeTask.message && closeTask.message.status === "E")) {
        setOpenSnackbar(true);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 4000)
      }
    }

    return (
      <React.Fragment>
        <TableRow hover role="checkbox" tabIndex={-1}>
          <TableCell className={`mw-210p ${classes.bodyContent}`}>
            <div className="mx-3 d-flex">
              <div style={{ display: "inline", position: 'relative' }}>
                <Avatar src={row.logo} style={{ position: 'absolute', margin: 0, top: '50%', transform: 'translateY(-50%)' }} />
              </div>
              <div className="ml-2 py-1 pr-1" style={{ display: "inline" }}>
                <h6 className={classes.blueText} style={{ marginLeft: '45px', cursor: 'pointer' }}
                  onClick={async () => {
                    await dispatch(getDealerDetails(row.sapCustomerId, 'dealerDetails'))
                  }}>
                  {row.companyTitle} {row.companyName}
                </h6>
                {/* Add trade number when available */}
                <h6 className={`pt-1 ${classes.subTableCell}`} style={{ marginLeft: '45px' }}>-</h6>
              </div>
            </div>
          </TableCell>
          <TableCell className="font-12 font-weight-bold text-red mw-210p">{row.title}</TableCell>
          <TableCell align="left">
            <Button fullWidth className="font-size-11 rounded-pill" disabled={taskDoneLoading} variant="contained" color="primary"
              onClick={() => {
                dispatch(closeTask(row.id));
                handleCloseTask();
              }}>
              Done</Button>
          </TableCell>
        </TableRow>
        {openSnackbar &&
          <Snackbar
            open={openSnackbar}
            handleopenClose={() => {
              handleopenCloseSnackbar(false)
            }}
            text={closeTaskSuccess === "S" ? "Task closed successfully!" : "There was an error closing this task."} />
        }
      </React.Fragment>
    );
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    dispatch(getTasks(newPage));
  };

  const tasksListLoading = tasks && tasks.tasksList && tasks.tasksList.loading;

  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Dashboard
        </h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      {openDealerDetailDialog &&
        <DealerDetailsDialog selectedTabValue={0} open={openDealerDetailDialog} onClose={(value: boolean) => setOpenDealerDetailDialog(value)} />
      }
      <div className="row mt-3">
        <div className="col-lg-6 col-ml-7 col-xl-8 mb-4">
          <div className="cardCommon mh-300p">
            <h3 className={classes.tableTitle}>Dealer's Open Task</h3>
            {tasksListLoading || sessionStorage.getItem('webApplicationLoginId') === "" ? <FullscreenLoader /> :
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          className={classes.tableHead}
                          key={column.id}
                          align={column.align}
                          style={{ paddingRight: 0 }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasksList.map((row: any) => {
                      return <Row key={row.id} row={row} />;
                    })}
                  </TableBody>
                </Table>
                <div className="col-12 d-flex justify-content-end">
                  <TablePagination
                    rowsPerPageOptions={[4]}
                    component="div"
                    count={tasks && tasks.tasksList && tasks.tasksList.data && tasks.tasksList.data.totalCount ? tasks.tasksList.data.totalCount : 0}
                    rowsPerPage={4}
                    page={page}
                    onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
                  />
                </div>
              </TableContainer>
            }
          </div>
        </div>
        <div className="col-lg-6 col-ml-5 col-xl-4 height-450">
          <ReportComponent />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 col-ml-7 col-xl-8 mb-2">
          <Announcements />
        </div>
        <div className="col-lg-6 col-ml-5 col-xl-4 mb-2">
          <SpecialOffers />
        </div>
      </div>
    </Fragment>
  );
};

export default AdminDashboard;
