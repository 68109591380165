import { HOME } from "./actionsTypes";
import { apiError, apiSuccess } from "./apiActions"

import image4 from './../../assets/landingpage/images/cjdr.png'
import image5 from './../../assets/landingpage/images/honda.png'
import image6 from './../../assets/landingpage/images/toyota.png'
import image7 from './../../assets/landingpage/images/volvo.png'
import image8 from './../../assets/landingpage/images/lexus.png'
import image9 from './../../assets/landingpage/images/jeep.png'

const features = [
    { id: Math.random().toString(), icons: 'speed', title : 'Fastest Delivery', body: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiu' },
    { id: Math.random().toString(), icons: 'verified', title : 'Genuine Parts', body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod' },
    { id: Math.random().toString(), icons: 'settings_suggest', title : 'Best Service', body: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui' },
    { id: Math.random().toString(), icons: 'local_police', title : 'Trusted  Brands', body: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiu' }
]

const brands = [
    { id: Math.random().toString(),  src : image4  },
    { id: Math.random().toString(),  src : image5  },
    { id: Math.random().toString(),  src : image6  },
    { id: Math.random().toString(),  src : image7  },
    { id: Math.random().toString(),  src : image8  },
    { id: Math.random().toString(),  src : image9  }
]

export function startGetFeatures () {
    return async (dispatch: any) => {
        try {
            dispatch(apiSuccess(HOME.GET_FEATURES, features))
            return features || []
        } catch (error) {
            dispatch(apiError(HOME.GET_HOME_ERROR, error));
            return error
        }
    }
}

export function startGetBrands () {
    return async (dispatch: any) => {
        try {
            dispatch(apiSuccess(HOME.GET_BRANDS, brands))
            return brands || []
        } catch (error) {
            dispatch(apiError(HOME.GET_HOME_ERROR, error));
            return error
        }
    }
}