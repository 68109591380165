import { API_ENDPOINTS, ORDER_ENQUIRY, ONBOARD, KAMREVIEW, DEALER } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const editDealerService = {
    saveDealerDetails,
    getDealerDetails,
    getDetailsBySapId,
    getRegion,
    getDealingIn,
    getNationality,
    getTypeOfTrade,
    getPartnerDetails,
    savePartnerDetails,
    editPartnerDetails,
    deletePartnerDetails,
    getAddNewAuthorisedSignatories,
    saveAuthorisedSignatories,
    updateAuthorisedSignatories,
    deleteAuthorisedSignatories,
    saveDocument,
    updateDocument,
    deleteDocument,
    deletePartnerDocument,
    deleteSignatoryDocument,
    getGenrateOTP,
    getVerifyOTP,
    getAllAddress,
    getAddressDetails,
    updateAddress,
    deleteAddress,
    getTreadeLicenseRegion,
    getDocumentById,
    addNewAddress,
    changeCustomerType,
    getTradeRegion,
    deleteAddressDocument,
    getAllPartners,
    getAllSignatories,
    requestCorrection,
    saveRequestCorrection,
    saveZone,
    getTransportationZone,
    historyListRequestCorrection,
    getParameters,
    getParameterValues,
    saveParameterValues,
    updateParameterValues,
    getSapDetails,
    saveSapDetails,
    updateSapDetails,
    getKamDetails,
    saveKamReviewDetails,
    deleteKamReviewsDocument,
    getAccessTabData,
    getAllAcessParams,
    getCustomerTypes,
    saveUpdateAcessTab,
    approveDealer,
    checkSapAvailability,
    rejectDealer
};



async function getKamDetails(b2bUserId: string, type: any) {
    const apiPath = API_ENDPOINTS.KAM_REVIEW_ENDPOINT + ONBOARD.API_PATH + `/customer?b2bUserId=${b2bUserId}&details=${type}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    }
    return await makeRequest(options)

}
async function saveKamReviewDetails(data: any) {
    const apiPath = API_ENDPOINTS.KAM_REVIEW_ENDPOINT + KAMREVIEW.SAVE_KAM_REVIEW_DETAILS;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    }
    return await makeRequest(options)

}

async function saveDealerDetails(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH;
    const options = {
        path: `${apiPath}/customer`,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getDealerDetails(sapCustomerId: any, type: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_DEALER_DETAILS;
    const options = {
        path: `${apiPath}/${sapCustomerId}?loginId=${sessionStorage.getItem('webApplicationLoginId')}&details=${type}`,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getRegion() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ORDER_ENQUIRY.FORMFIELD + '/region';
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getTradeRegion(sapId: any) {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/tradeLicenseRegion?id=${sapId}`;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}


async function getDealingIn() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ORDER_ENQUIRY.FORMFIELD + '/dealingIn';
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getTypeOfTrade() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ORDER_ENQUIRY.FORMFIELD + '/typeOfTrade';
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getDetailsBySapId(sapId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/sapCustomerDetails?sapCustomerId=${sapId}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getPartnerDetails(id: any, sapId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/partner?id=${sapId}&partnerId=${id}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function savePartnerDetails(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/partner`;
    const options = {
        path: apiPath,
        bodyObj: data,
        method: "POST",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}
async function editPartnerDetails(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/partner`;
    const options = {
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}
async function deletePartnerDetails(id: any, sapId: any) {
    const data = { id: sapId, partnerId: id }
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/partner/delete`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

//ADD NEW AUTHORISED SIGNATORIES FUNCTION CODE

async function getAddNewAuthorisedSignatories(id: any, sapId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/authorizedSignatories?id=${sapId}&signatorieId=${id}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function saveAuthorisedSignatories(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/authorizedSignatories`;
    const options = {
        path: apiPath,
        bodyObj: data,
        method: "POST",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}
async function updateAuthorisedSignatories(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/authorizedSignatories`;
    const options = {
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}
async function deleteAuthorisedSignatories(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/authorizedSignatories/delete`;
    const options = {
        path: apiPath,
        bodyObj: data,
        method: "POST",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// DOCUMENT TAB FUNCTION CODE
async function saveDocument(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + '/customer';
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}
async function updateDocument(formData: any, headers: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.DOCUMENTS;
    const options = {
        path: apiPath,
        bodyObj: formData,
        method: "POST",
        customHeaders: headers,
        formdata: true
    };
    return await makeRequest(options)
}
async function deleteDocument(sapCustomerId: any, id: any, type: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/document/delete?sapCustomerId=${sapCustomerId}&docId=${id}`;
    const options = {
        path: apiPath,
        bodyObj: type,
        method: "POST",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function deletePartnerDocument(partnerId: any, documentId: any, data: any, sapId: any) {
    let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/partner/document/delete?docId=${documentId}&id=${sapId}`
    if (partnerId) {
        apiPath += `&partnerId=${partnerId}`
    }
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function deleteSignatoryDocument(signatoryId: any, documentId: any, data: any, sapId: any) {
    let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/authorizedSignatories/document/delete?docId=${documentId}&id=${sapId}`;
    if (signatoryId) {
        apiPath += `&signatoriId=${signatoryId}`
    }
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FUNCTION CODE FOR GENRATE OTP
async function getGenrateOTP(sapId: any, b2bUserId: any) {
    const data = { sapCustomerId: sapId, b2bUserId: b2bUserId }
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/generatOTP`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FUNCTION CODE FOR VERIFY OTP
async function getVerifyOTP(sapVerifyId: any, sapVerifyIdOTP: any, b2bUserId: any) {
    const data = { sapCustomerId: sapVerifyId, otp: sapVerifyIdOTP, b2bUserId: b2bUserId }
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/verifyOtp`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}


async function getAllAddress(b2bUserId: any) {

    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/${b2bUserId}/address`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function addNewAddress(sapCustomerId: any, data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/${sapCustomerId}/address`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getAddressDetails(sapCustomerId: any, addressId: any) {

    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/${sapCustomerId}/address/${addressId}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function updateAddress(sapCUstomerId: any, data: any, addressId: any) {

    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/${sapCUstomerId}/address/${addressId}`;
    const options = {
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function deleteAddress(sapCustomerId: any, addressId: any, userType: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/${sapCustomerId}/address/${addressId}/delete`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: userType,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}


async function deleteAddressDocument(addressId: any, documentId: any, type: any, sapId: any) {
    let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/address/delete?docId=${documentId}&id=${sapId}`;
    if (addressId) {
        apiPath += `&addressId=${addressId}`
    }
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: type,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function deleteKamReviewsDocument(b2bUserId: any, documentId: any, type: any) {
    let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/document/delete?b2bUserId=${b2bUserId}`;
    if (documentId) {
        apiPath += `&docId=${documentId}`
    }
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: type,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getTreadeLicenseRegion() {

    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/b2b0000000682/region`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getDocumentById() {

    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/b2b0000000682/region`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function changeCustomerType(data: any) {

    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/changeDealerType`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}
// FOR GET ALL PARTNERS DETAILS
async function getAllPartners(sapCustomerId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/allPartner?sapCustomerId=${sapCustomerId}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR GET ALL SIGANTORIES DETAILS
async function getAllSignatories(sapCustomerId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/customer/allSignatories?sapCustomerId=${sapCustomerId}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR GET REQUEST CORRECTION DETAILS
async function requestCorrection(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/dataCorrection`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR SAVE REQUEST CORRECTION DETAILS
async function saveRequestCorrection(b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR HISTORY LIST CORRECTION DETAILS
async function historyListRequestCorrection(b2bUserId?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/dataCorrection?b2bUserId=${b2bUserId}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR GET TRANSPORTATION ZONE
async function getTransportationZone(b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ORDER_ENQUIRY.FORMFIELD + '/transportationZone';;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR SAVE ZONE
async function saveZone(sapCustomerId: any, data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/${sapCustomerId}/transportationZone`;
    const options = {
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR GET PARAMETERS DETAILS
async function getParameters(b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR GET PARAMETER VALUES
async function getParameterValues(b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR SAVE PARAMETER VALUES
async function saveParameterValues(b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR UPDATE PARAMETER VALUES
async function updateParameterValues(b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR GET SAP DETAILS
async function getSapDetails(b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR SAVE SAP DETAILS
async function saveSapDetails(b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR UPDATE SAP DETAILS
async function updateSapDetails(b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + ``;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// get Kam details


// FOR UPDATE SAP DETAILS
async function getAccessTabData(b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/accessData/${b2bUserId}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR UPDATE SAP DETAILS
async function getAllAcessParams() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/masterListOfAccess`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}


// FOR UPDATE SAP DETAILS
async function saveUpdateAcessTab(sapCustomerId: any, b2bUserId: any, data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/${sapCustomerId}/accessParameter/${b2bUserId}`;
    const options = {
        path: apiPath,
        bodyObj: data,
        method: "PUT",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR UPDATE SAP DETAILS
async function checkSapAvailability(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + `/checkUserAvalibility`;
    const options = {
        path: apiPath,
        bodyObj: data,
        method: "POST",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR UPDATE SAP DETAILS
async function getCustomerTypes() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/customerType`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR UPDATE SAP DETAILS
async function approveDealer() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/customerType`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR UPDATE SAP DETAILS
async function rejectDealer(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/onboarding/rejectCustomer`;
    const options = {
        path: apiPath,
        bodyObj: data,
        method: "POST",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// FOR GET NATIONALITY DETAILS FOR OWNER/PARTNER & ADD AUTHORIZED SIGANTORIES DETAILS
async function getNationality() {
	const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/formField/countries` ;
	const options = {
		path: apiPath,
		method: "GET",
		customHeaders: {},
		formdata: true
	};
	return await makeRequest(options)
}