import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { getNewBrand } from '../../common/components/util';
import { getSellingPrice } from '../../helpers/commonmethod';

const useStyles = makeStyles(() => ({
  header: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.625rem',
    fontFamily: 'Nunito',
    minWidth: 80
  },
  brand: {
    color: '#444444',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  body: {
    color: '#666666',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  price: {
    color: '#E88B00',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  credit: {
    backgroundColor: '#E88B00',
    borderRadius: 20,
    color: '#FFFFFF',
    fontSize: 9,
    padding: '2px 10px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
  },
  backOrder: {
    backgroundColor: '#1A9FE0',
    borderRadius: 20,
    color: '#FFFFFF',
    fontSize: 9,
    padding: '2px 10px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
  },
  orderCreated: {
    minWidth: "120px !important",
    border: "1px solid #4ec766",
    background: '#4ec766',
    borderRadius: 100,
    color: 'white !important',
    fontSize: "9px !important",
    padding: '2px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    "&:hover, &:focus": {
      background: '#4ec766',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    }
  },
  orderFailed: {
    minWidth: "120px !important",
    border: "1px solid red",
    background: 'red',
    borderRadius: 100,
    color: 'white !important',
    fontSize: "9px !important",
    padding: '2px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    "&:hover, &:focus": {
      background: 'red',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    }
  },
  rowCustomSummary: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}));
const columns = [
  {
    id: 'brand',
    label: 'Brand',
    align: 'left',
  },
  {
    id: 'orderno',
    label: 'Order No.',
    align: 'left',
  },
  {
    id: 'referenceno',
    label: 'Reference No.',
    align: 'left'
  },
  {
    id: 'lines',
    label: 'Lines',
    align: 'left'
  },
  {
    id: 'price',
    label: 'Price',
    align: 'left'
  },
];
export default function OrderSummaryDialog(props: any) {
  const classes = useStyles();
  const history = useHistory();

  const { isSellingPriceData, profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);

  let address = get(
    profileDetails,
    "data.data.customerInfo.addresses",
    ""
  );

  if (typeof address != 'string') {
    if (typeof address == 'object') {
      let data = address.find((item: any) => item.firstAddress)
      if (data) {
        address = `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}`
      } else {
        address = ``
      }
    } else {
      address = ""
    }
  }

  return <Dialog
    disableBackdropClick={true}
    open={props.open ? true : false}
    onClose={() => { props.handleopenClose(false) }}
    fullWidth={true}
    maxWidth={'md'}
  >

    <DialogTitle className="p-0">
      <div className="row justifyContentHorizontalSpacebetween align-items-center ml-0">
        <h4 className="page-title">Order Summary</h4>
      </div>
    </DialogTitle>

    <DialogContent className="p-0">

      <div className="d-flex justifyContentHorizontalSpacebetween align-items-center col-12 my-2">
        <div className="mr-1"
        > <CheckCircleIcon className="order-placed-tick" fontSize="large" />
          <span className="order-placed ml-3">Order Placed Successfully.</span>
        </div>
        <h6 className="ml-auto font-10 font-weight-600">Date -  {props.orderData && Array.isArray(props.orderData) && props.orderData.length > 0 && props.orderData[0] && props.orderData[0].createdOn ? moment(new Date(props.orderData[0].createdOn)).utc().format('DD MMM YYYY') : '-'}</h6>
      </div>
      <div className="col-12 col-lg-12 col-md-12 col-sm-12 p-0">
        <div className="col-12 border-top w-100 p-0">
          <div className={`enquiry-number ${classes.rowCustomSummary} justifyContentHorizontalSpacebetween align-items-center`}>
            <div>Enquiry Number : {props.orderData && Array.isArray(props.orderData) && props.orderData.length > 0 && props.orderData[0] && props.orderData[0].B2bEnquiryId ? props.orderData[0].B2bEnquiryId : '-'}</div>
            <div>Reference Number : {props.orderData && Array.isArray(props.orderData) && props.orderData.length > 0 && props.orderData[0] && props.orderData[0].masterReferenceNumber ? props.orderData[0].masterReferenceNumber : '-'}</div>
          </div>
        </div>
        {/* <div className="col-12 border-bottom"></div> */}
        <TableContainer className="mb-5">
          <Table>
            <TableHead className="w-100">
              <TableRow>
                {columns.map((column: any) => (
                  <TableCell
                    key={column.id}
                    className={classes.header}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell className={classes.header}>Order Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.orderData && Array.isArray(props.orderData) && props.orderData.map((item: any) => {
                let priceFinal: any = item && item.price && typeof item.price == 'string' ? parseFloat(item.price) : item.price;
                priceFinal = priceFinal.toFixed(2)
                if (item) {
                  return <TableRow>
                    <TableCell className={!item.orderNumber ? 'text-red' : `${classes.brand}`}>{getNewBrand(item.make)}
                      {!item.orderNumber && item.status && !(item.status.toLowerCase().includes('failed')) && (
                        <small className="text-red position-absolute font-weight-normal d-block mt-1">{item.sapOrderMessage} </small>
                      )}
                      {item.sapOrderMessage && item.sapOrderMessage.includes("not allow external procurement") && (
                        <small className="text-red position-absolute font-weight-normal d-block mt-1">Some parts are not allowed for external procurement</small>
                      )}
                    </TableCell>
                    <TableCell className={classes.body}>{item.orderNumber}</TableCell>
                    <TableCell className={classes.body}>{item.enquiryReferenceNumber}</TableCell>
                    <TableCell className={classes.body}>{item.lines}</TableCell>
                    <TableCell className={!item.orderNumber ? 'text-red' : `${classes.body}`}>
                      AED {parseFloat(getSellingPrice(priceFinal, isSellingPriceData, markupPercentage)).toFixed(2)}</TableCell>
                    <TableCell className={classes.body}>
                      {/* <Button
                        className={item.orderNumber ? classes.orderCreated : classes.orderFailed}
                        size="small"
                        variant="contained"
                      > */}
                        {item.status && typeof item.status == 'string' && item.status}
                      {/* </Button> */}
                    </TableCell>
                  </TableRow>
                }
                return null
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </DialogContent>

    <div className="p-0">
      <div className="row no-gutters table-bottom p-0">
        <div className="col-lg-3 col-md-3 col-sm-6 p-3 border-right-bottom">
          <p className="bottom-field-name-table">
            Total Price
          </p>
          <p className="bottom-field-value-table ">
            AED {props.orderTotal && parseFloat(props.orderTotal) ? parseFloat(getSellingPrice(parseFloat(props.orderTotal), isSellingPriceData, markupPercentage)).toFixed(2) : '-'}
            <span className="vat ml-1">(Incl. of VAT)</span>
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 p-3 border-right-bottom">
          <p className="bottom-field-name-table">
            Office Address
          </p>
          <p className="bottom-field-value-table">
            {address}
          </p>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 p-2 border-bottom d-flex justify-content-center">
          <Button variant="contained" color="primary" className="mt-2 actionButtomButtomsBlue" onClick={() => {
            history.push('/orderhistory')
          }} >
            Order History
          </Button>
        </div>
      </div>
    </div>

  </Dialog >
}
