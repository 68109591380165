import React, { Fragment, useEffect, useState, Dispatch, useCallback } from 'react';
import { get } from "lodash";
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Loading from '../../common/components/loading';
import ErrorFormat from '../../common/components/errorFormat';
import Snackbar from './../../common/components/Snackbar';
import { getAllUsers, setUserDetails, getUploadedDocuments, getUserDetails, uploadDocument, deleteDocument } from "../../store/actions/dealer.action";
import dealericon from "../../assets/dealerImage.png";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import ProgressBar from "./ProgressBar";
import { userTypes } from "../../constants/userTypes";
import { getNewBrand } from "../../common/components/util";
import { getProfile } from "../../store/actions/profile.action";
import AddDealerDialog from '../Dealers/AddDealerDialog';
// import { getDealingIn, getTypeOfTrade } from '../../store/actions/onboard.actions';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Visibility from "@material-ui/icons/Visibility";
// import CircleIcon from '@material-ui/icons/Circle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import moment from 'moment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import AddNewOwnerPartnerDialog from '../ViewEditDealerDetailsKam/addNewOwnerPartnerDialog';
import {
  editPartnerDetails, getDealerDetails, getPartnerDetails, savePartnerDetails, deletePartnerDetails,
  setCustomerType, resetValues, setStatus, getAllPartners, getNationality, getRegion, getDealingIn,
  getTypeOfTrade
} from '../../store/actions/editDealer.actions';

const useStyles = makeStyles((theme) => ({
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
  },
}));

const DealerDetails = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { creditLimitDetails }: any = useSelector((state: any) => state.creditLimitDetails);
  const { dealingInData, typeofTradeData }: any = useSelector((state: any) => state.onboard);
  const { dealerDetails, updateDealer, usersList, uploadedDocuments, uploadDocumentData, removeDocumentData }: any = useSelector((state: any) => state.dealer);
  const customerDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data) ? dealerDetails.data.data : null;
  const { userType } = props;
  const [openEditInfo, setOpenEditInfo] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isDialogForEdit, setIsDialogForEdit] = React.useState(false);
  const [isView, setIsView] = React.useState(false);
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
  const customerId = sessionStorage.getItem('customerId')
  const companyBrands: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data) ? dealerDetails.data.data.organizationIds : null;
  const creditLimitData = get(creditLimitDetails, 'data.data.data', []);
  const brandIds = companyBrands && companyBrands.map((id: any) => id.organizationId);
  const sapCustomerId = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId ? profileDetails.data.data.basicInfo.sapCustomerId : '';
  const filteredBrands = creditLimitData && Array.isArray(creditLimitData) && creditLimitData.filter((x: any, index: any) => {
    if (brandIds && brandIds.length > 0) {
      return brandIds.includes(x.brandId);
    }
    return undefined;
  });
  const [openDealerSnackbar, setOpenDealerSnackbar] = React.useState(false);
  const [completeCreditRequest, setCompleteCreditRequest] = React.useState<any>(false);
  const history: any = useHistory();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [editData, setEditData] = React.useState();

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const updateNewPartnerData = (data: any) => {
    dispatch(editPartnerDetails(data));
  }

  const setOwnerData = (data: any) => {
    dispatch(savePartnerDetails(data));
  }

  const handleEditPartnerDetails = (partner: any, isView: any) => {
    dispatch(getPartnerDetails(partner.id, sapCustomerId));
    handleClickOpen();
    setIsEdit(true);
    setIsView(isView);
    setEditData(partner);
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#1A9FE0'
      },
      secondary: {
        main: '#FF0000',
      }
    },
  });

  //TABLE HEADER COLOUMN CODE
  const headCells = [
    { id: 'firstName', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
    { id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile' },
    { id: 'nationality', numeric: true, disablePadding: false, label: 'Nationality' },
    { id: 'designation', numeric: true, disablePadding: false, label: 'Designation' },
    { id: 'emiratesIdNo', numeric: true, disablePadding: false, label: 'Emirates ID No' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Action' },
  ];

  //TABLE HEADER CODE
  function EnhancedTableHead(props: any) {
    const classes = useStyles();
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              className={classes.bodyContent}
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const getTypeOfTradeData = (typeOfTrade: any) => {
    if (typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0) {
      var typeOfTradeName = typeofTradeData.data[0].tradeType.filter((obj: any) => obj.id == typeOfTrade)
      if (typeOfTradeName && typeOfTradeName.length > 0) return typeOfTradeName[0].title;
    }
    return "";
  }

  const getDealingInData = (dealingIn: any) => {
    if (dealingInData && dealingInData.data && dealingInData.data.length > 0) {
      var dealingInName = dealingInData.data[0].regions.filter((obj: any) => obj.id == dealingIn)
      if (dealingInName && dealingInName.length > 0) return dealingInName[0].title;
    }
    return "";
  }

  const uploadFile = async (file: any) => {
    const { name } = file,
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || name,
      doctExtensions = ['jpg', 'jpeg', 'png'],
      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;

    if (!canUploadFile) {
      return;
    }

    const sapCustomerId: any = get(dealerDetails, 'data.data.sapCustomerId', false),
      organization = get(dealerDetails, 'data.data.organizationIds[0]', {});

    const formData: any = new FormData();

    let keyAttribute = `fieldname=${name}&docExtension=${doctExtension}`;

    if (!sapCustomerId) { return; }


    const customHeaders = {
      "Accept": "application/json",

      "Lob-Id": "AUTO",
      "Channel-Id": "B2B",
      "Org-Id": organization.organizationId || "",
      "Accept-Language": "EN",
    };

    formData.append("entityName", "CUSTOMER");
    formData.append("keyAttribute", keyAttribute);
    formData.append("fileUpload", file);
    formData.append("document", 'false');
    formData.append("Attributes", `key=Brand&value=${file}`);
    formData.append("sapCustomerId", sapCustomerId);
    formData.append("uploadedByRole", 'dealerUser');
    formData.append("uploadedBywebApplicationLoginId", sessionStorage.getItem('webApplicationLoginId'));

    await dispatch(uploadDocument(formData, customHeaders));
    loadProfileDetails();
  }

  const handleopenCloseEditInfo = (open?: any) => {
    setOpenEditInfo(open ? true : false);
  }

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const OnClickCompleteCreditRequest = () => {
    setCompleteCreditRequest(true);
  }

  const showManageUser = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.MANAGER_USER);
  const showViewUser = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_USERS);

  const getDetailedExposure = (creditExposure: any) => {
    const credLimit = creditExposure.split('.')[0];
    if (credLimit.includes("000")) {
      const number = credLimit.substring(0, credLimit.lastIndexOf("000"));
      return number;
    } else if (credLimit > 1000) {
      return (credLimit / 1000 | 0);
    } else return credLimit
  }

  const getCreditValue = (creditLimit: any, creditExposure: any) => {
    let value = (parseInt(creditExposure.split('.')) / parseInt(creditLimit.split('.'))) * 100;
    if (value > 100 || value < 0) value = -1;
    return value;
  }

  const showCreditStatus = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_CREDIT_STATUS);

  const loadProfileDetails = () => {
    const b2cUid = sessionStorage.getItem('userId');
    const userType = sessionStorage.getItem('userType');
    if (b2cUid !== "undefined" && b2cUid !== null && userType !== 'undefined' && userType !== null) {
      dispatch(getProfile(b2cUid, userType));
    }
  };

  const handleopenCloseDealerSnackbar = (open?: any) => {
    setOpenDealerSnackbar(open ? true : false);
  }

  return (
    <Fragment>
      {!completeCreditRequest && (
        <div className="row mt-2">
          <Snackbar
            open={openDealerSnackbar}
            text="Details Updated Successfully"
            handleopenClose={() => {
              handleopenCloseSnackbar(false)
            }}
          />
          <AddDealerDialog
            open={openEditInfo}
            isEdit={isDialogForEdit}
            hideAdmin
            handleopenClose={(data: any) => {
              if (data) {
                handleopenCloseDealerSnackbar(true)
              }
              dispatch(getDealerDetails(sapCustomerId, 'dealerDetails'));
              handleopenCloseEditInfo(false)
            }}
          />
          <div className="col-12 col-lg-8 col-md-12 col-sm-12 mt-3">
            <h5 className="page-sub-title">Info</h5>
            <div className="cardCommon row px-3 mr-2 mt-2">
              {dealerDetails && dealerDetails.loading && (
                <div className="mx-auto my-5">
                  <Loading />
                </div>
              )}
              {dealerDetails && dealerDetails.error && (
                <div className="mx-auto my-5">
                  <ErrorFormat error={dealerDetails.error} />
                </div>
              )}
              {dealerDetails && !dealerDetails.loading && (
                <div className="w-100">
                  <div className="row pl-2">
                    <div className="col-4 pt-5">
                      <div className="info-sub-title">Brand Logo :</div>
                    </div>
                    <div className="col-2 pt-4">
                      {customerDetails && customerDetails.logo
                        ? <img src={customerDetails && customerDetails.logo} alt="Logo Client" className="brandLogo" />
                        : <img src={dealericon} alt="Logo Client" className="brandLogo" />}
                    </div>
                    {userType !== userTypes.user && (
                      <div className="cameraIcon col-2 mt-4">
                        <Button
                          variant="contained" component="label"
                          className="text-info"
                          startIcon={<CameraAltIcon className="font-size-18" fontSize="large" />}
                        >
                          Change
                          <input type="file" hidden onChange={(e) =>
                            (e.target && e.target.files) ?
                              uploadFile(e.target.files[0]) : ''
                          } />
                        </Button>
                        <div className="logoSize mr-2 px-3">Recommended size 180px by 180px</div>
                        <div className="logoSize mr-2 px-3">Please upload jpg, jpeg and png files</div>
                      </div>
                    )}
                    {userType !== userTypes.user && (
                      <div className="closeIcon col-2 mt-4 px-4">
                        <Button
                          className="text-info"
                          startIcon={<CloseIcon className="font-size-18" fontSize="large" />}
                          onClick={async () => {
                            await dispatch(deleteDocument(customerDetails.logoId, customerDetails.sapCustomerId, false));
                            loadProfileDetails();
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    )}
                    <div className="col-12 row justify-content-end">
                      <div className="pt-3">
                        {/* {userType !== userTypes.user &&
                          <IconButton className="edit" disabled onClick={() => { handleopenCloseEditInfo(true); setIsDialogForEdit(true) }}>
                            <EditIcon className="edit" fontSize='small' />
                            <span className=" editIcon ml-1">Edit</span>
                          </IconButton>
                        } */}
                      </div>
                    </div>
                    {customerDetails && customerDetails.companyDetails && (
                      <>
                        <div className="col-4 mt-4">
                          <div className="info-sub-title">Company Name</div>
                          <div className="info-sub-title-value mt-2">{customerDetails.companyDetails.title}{' '}{customerDetails.companyDetails.companyName}</div>
                        </div>
                        <div className="col-4 mt-4">
                          <div className="info-sub-title">VAT Registration No.</div>
                          <div className="info-sub-title-value mt-2">{customerDetails.companyDetails.vatRegistrationNumber ? customerDetails.companyDetails.vatRegistrationNumber : '-'}</div>
                        </div>
                        {customerDetails.companyDetails.telephones && customerDetails.companyDetails.telephones.length > 0 && (
                          <div className="col-4 mt-4">
                            <div className="info-sub-title">Telephone Office</div>
                            <div className="info-sub-title-value mt-2">{customerDetails.companyDetails.telephones[0].telephone}</div>
                          </div>
                        )}
                        <div className="col-4 mt-4">
                          <div className="info-sub-title">Established Date</div>
                          <div className="info-sub-title-value mt-2">{moment(customerDetails.companyDetails.establishedDate).format('DD MMM YYYY')}</div>
                        </div>
                        {customerDetails.companyDetails.emails && customerDetails.companyDetails.emails.length > 0 && (
                          <div className="col-4 mt-4">
                            <div className="info-sub-title">Dealer Email</div>
                            <div className="info-sub-title-value mt-2">{customerDetails.companyDetails.emails[0].emailId}</div>
                          </div>
                        )}
                        {userType !== userTypes.user &&
                          <div className="col-4 mt-4">
                            <div className="info-sub-title">Annual Turnover</div>
                            <div className="info-sub-title-value mt-2">{customerDetails.companyDetails && customerDetails.companyDetails.companyAnnualTurnover ? customerDetails.companyDetails.companyAnnualTurnover : '-'}</div>
                          </div>
                        }
                        <div className="col-4 mt-4">
                          <div className="info-sub-title">Dealing In</div>
                          <div className="info-sub-title-value mt-2">{getDealingInData(customerDetails.companyDetails.dealingIn) ? getDealingInData(customerDetails.companyDetails.dealingIn) : '-'}</div>
                        </div>
                        <div className="col-4 mt-4">
                          <div className="info-sub-title">Type of Trade</div>
                          <div className="info-sub-title-value mt-2">{getTypeOfTradeData(customerDetails.companyDetails.typeOfTrade) ? getTypeOfTradeData(customerDetails.companyDetails.typeOfTrade) : '-'}</div>
                        </div>
                        <div className="col-4 mt-4">
                          <div className="info-sub-title">Head Office Region</div>
                          <div className="info-sub-title-value mt-2">{customerDetails.companyDetails.region}</div>
                        </div>
                        {customerDetails.addresses && customerDetails.addresses.length > 0 && (
                          <>
                            <div className="col-4 mt-4">
                              <div className="info-sub-title">PO Box</div>
                              <div className="info-sub-title-value mt-2">{customerDetails.addresses[0].poBox ? customerDetails.addresses[0].poBox : '-'}</div>
                            </div>
                            <div className="col-4 mt-4">
                              <div className="info-sub-title">Street Name</div>
                              <div className="info-sub-title-value mt-2">{customerDetails.addresses[0].streetName ? customerDetails.addresses[0].streetName : '-'}</div>
                            </div>
                            <div className="col-4 mt-4">
                              <div className="info-sub-title">City</div>
                              <div className="info-sub-title-value mt-2">{customerDetails.addresses[0].city ? customerDetails.addresses[0].city : '-'}</div>
                            </div>
                            <div className="col-4 mt-4">
                              <div className="info-sub-title">Country</div>
                              <div className="info-sub-title-value mt-2">{customerDetails.addresses[0].country ? customerDetails.addresses[0].country : '-'}</div>
                            </div>
                            <div className="col-4 mt-4">
                              <div className="info-sub-title">Postal Code</div>
                              <div className="info-sub-title-value mt-2">{customerDetails.addresses[0].postalCode ? customerDetails.addresses[0].postalCode : '-'}</div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {customerDetails && customerDetails.partnerDetails && customerDetails.partnerDetails.length > 0 && (
                      <div className="row col-12 mt-2 mb-2 pl-3 border-top">
                        <div className="col-12 mt-4">
                          <div className="info-sub-header">Owner/Partner Details</div>
                        </div>
                        <div className="col-lg-12 mt-3">
                          <TableContainer>
                            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                              <EnhancedTableHead />
                              <TableBody>
                                <>
                                  {customerDetails.partnerDetails.map((newPartnerData: any, index: any) => (
                                    <TableRow hover tabIndex={-1} key={index}>
                                      <TableCell component="th"
                                        scope="row" padding="none" className="document-tablecell-data">
                                        {newPartnerData.firstName}{'  '}{newPartnerData.lastName}
                                      </TableCell>
                                      <TableCell align="left" className="document-tablecell-data">
                                        {newPartnerData.email}
                                      </TableCell>
                                      <TableCell align="left" className="document-tablecell-data">
                                        {newPartnerData.mobile}
                                      </TableCell>
                                      <TableCell align="left" className="document-tablecell-data">
                                        {newPartnerData.nationality}
                                      </TableCell>
                                      <TableCell align="left" className="document-tablecell-data">
                                        {newPartnerData.designation}
                                      </TableCell>
                                      <TableCell align="left" className="document-tablecell-data">
                                        {newPartnerData.emiratesIdNumber}
                                      </TableCell>
                                      <TableCell align="left" className="document-tablecell-data">
                                        <div className="">
                                          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                            <Button
                                              className="text-info"
                                              startIcon={<Visibility />}
                                              onClick={() => { handleEditPartnerDetails(newPartnerData, true); }}
                                            >
                                              View
                                            </Button>
                                          </ButtonGroup>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                  }
                                </>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    )}
                    {customerDetails && customerDetails.organizationIds && customerDetails.organizationIds.length > 0 && (
                      <React.Fragment>
                        <div className="col-12 mt-4">
                          <div className="info-sub-header">Brands &amp; Others</div>
                        </div>
                        <div className="col-8 mt-3">
                          <div className="info-sub-title">Brands</div>
                          <div className="mt-2">
                            {customerDetails.organizationIds.map((org: any, index: any) => (
                              <Button key={index} disabled onClick={() => {
                              }}
                                className="brandsButton mr-2"
                                variant="contained">
                                {getNewBrand(org.make)}
                              </Button>
                            ))}
                          </div>
                        </div>
                        <div className="col-4 mt-4">
                          <div className="info-sub-title">Initial Stock Order</div>
                          <div className="info-sub-title-value mt-2">{customerDetails.initialStockOrder ? customerDetails.initialStockOrder : '-'}</div>
                        </div>
                        <div className="col-4 mt-4">
                          <div className="info-sub-title">Total Investment</div>
                          <div className="info-sub-title-value mt-2">{customerDetails.totalInvesment ? customerDetails.totalInvesment : '-'}</div>
                        </div>
                        <div className="col-4 mt-4">
                          <div className="info-sub-title">Other Business Est.</div>
                          <div className="info-sub-title-value mt-2">{customerDetails.otherBusinessEstablishments ? customerDetails.otherBusinessEstablishments : '-'}</div>
                        </div>
                        <div className="col-4 mt-4">
                          <div className="info-sub-title">Other Brands/Products Sold</div>
                          <div className="info-sub-title-value mt-2">{customerDetails.otherBrandsOrProductsSold ? customerDetails.otherBrandsOrProductsSold : '-'}</div>
                        </div>
                      </React.Fragment>
                    )}
                    {customerDetails && customerDetails.treadReference && customerDetails.treadReference.length > 0 && (
                      <div className="row col-12 mt-2 mb-2 pl-3 border-top">
                        <div className="col-12 mt-4">
                          <div className="info-sub-header">Trade Reference</div>
                        </div>
                        <div className="col-lg-12 mt-3">
                          <TableContainer>
                            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell>Company Name</TableCell>
                                  <TableCell>Address</TableCell>
                                  <TableCell>Telephone</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {customerDetails.treadReference.map((trade: any, index: any) => (
                                  <TableRow hover tabIndex={-1} key={index}>
                                    <TableCell component="th"
                                      scope="row" padding="none" className="document-tablecell-data px-3">
                                      {trade.name}
                                    </TableCell>
                                    <TableCell component="th"
                                      scope="row" padding="none" className="document-tablecell-data px-3">
                                      {trade.companyName}
                                    </TableCell>
                                    <TableCell align="left" className="document-tablecell-data">
                                      {trade.addresses}
                                    </TableCell>
                                    <TableCell align="left" className="document-tablecell-data">
                                      {trade.telephones && trade.telephones.length > 0 && trade.telephones[0].telephone}
                                    </TableCell>
                                  </TableRow>
                                ))
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <Dialog className="pb-0"
              open={open}
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  handleClose()
                }
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth="md"
              disableEscapeKeyDown>
              <AddNewOwnerPartnerDialog
                setOpenDialog={handleClose} setOwnerData={setOwnerData}
                updateNewPartnerData={updateNewPartnerData}
                isView={isView}
              />
            </Dialog>
          </div>
          {showCreditStatus &&
            <div className="col-12 col-lg-4 col-md-12 col-sm-12">
              <div className="d-flex justifyContentHorizontalSpacebetween align-items-center">
                <h5 className="page-sub-title mt-3" >Credit Limit (in AED - 000s)</h5>
                {/* <div className={`${"Credit Limit (in AED - 000s)" ? "creditCircleblue mr-4 mt-2" : ""}`} >
              </div> */}
              </div>
              <div className="cardCommon p-3">
                <div className="w-100">
                  <ThemeProvider theme={theme}>
                    {creditLimitDetails && creditLimitDetails.loading && (
                      <div className="mx-auto my-5 text-center d-flex justify-content-center">
                        <Loading />
                      </div>
                    )}
                    {creditLimitDetails && creditLimitDetails.data && creditLimitDetails.data.data && creditLimitDetails.data.data.error && (
                      <div className="mx-auto my-5">
                        <ErrorFormat error={creditLimitDetails.data.data.error} />
                      </div>
                    )}
                    {filteredBrands && filteredBrands.length > 0 && filteredBrands.map((limit: any, index: any) => (
                      <div className="col-12 px-2" key={index}>
                        <div className="creditLimitTitle mt-2 mb-0">{getNewBrand(limit.brand)}</div>
                        {limit.message.messageType === "S" && (
                          <>
                            <div className="d-flex justifyContentHorizontalSpacebetween align-items-center">
                              <ProgressBar
                                key={index}
                                bgColor={getCreditValue(limit.creditLimit, limit.creditExposure) === -1 ? "#FF0000" : "#1A9FE0"}
                                completed={getCreditValue(limit.creditLimit, limit.creditExposure) === -1 ? 100 : getCreditValue(limit.creditLimit, limit.creditExposure)}
                                creditUsed={limit.creditExposure}
                              />
                              <div className={`${getCreditValue(limit.creditLimit, limit.creditExposure) === -1 ? "creditCircleRed" : "creditCircle"}`} >{getDetailedExposure(limit.creditLimit)}</div>
                              {/* {limit.availableBalance < 0 && (
                                  <span className="limitExceed">Your credit has gone below limit. Please pay utilised {Math.abs(limit.availableBalance)}</span>
                                )} */}
                            </div>
                            <div className="creditLimit">Available to spend : {limit.currencyKey ? limit.currencyKey : 'AED'} {' '} {limit.availableBalance < 0 ? 0 : limit.availableBalance}</div>
                            {!limit.id ? <div className="creditLimit-dealerDetails border-bottom mt-0 px-0">
                              {showManageUser && showViewUser ?
                                <IconButton className="px-0" aria-label="delete"
                                  onClick={(e: any) => {
                                    sessionStorage.setItem('creditBrand', JSON.stringify(limit));
                                    history.push('/creditrequestorg');
                                  }}>
                                  <AttachMoneyIcon className="dollar-icon-color" fontSize="small" />
                                  <h6 className="creditLimit-dealerDetails"> Complete Your Credit Process</h6>
                                </IconButton>
                                : null}
                            </div> :
                              <div className="creditLimit-dealerDetails border-bottom mt-0 px-0">
                                {showManageUser && userType !== userTypes.user &&
                                  <IconButton className="px-0" aria-label="delete"
                                    onClick={(e: any) => {
                                      sessionStorage.setItem('creditBrand', JSON.stringify(limit));
                                      history.push('/creditrequestorg');
                                    }}>
                                    <AttachMoneyIcon className="dollar-icon-color" fontSize="small" />
                                    <h6 className="creditLimit-dealerDetails"> View Credit Details</h6>
                                  </IconButton>
                                }
                              </div>
                            }
                          </>
                        )}
                        <div className="border-bottom">
                          <div className="d-flex justify-content-between px-0 mt-1 mb-2">
                            {limit.isCreditBrand === false && limit.paymentType === "D000" && limit.message.messageType === "S" ?
                              <div className="d-flex flex-row mb-2 mt-1">
                                Cash Brand
                              </div>
                              : " "}
                            {/* <div className="row-space-start ml-auto mt-0 mb-0">
                              <Button className="text-info btn-link mt-0 mb-0" startIcon={<Visibility />}
                                disabled onClick={() => { }}>View Request</Button>
                            </div> */}
                          </div>
                          {limit.message.messageType !== "S" ?
                            <span className="limitExceed text-center mt-0 ml-0 mx-auto">Error fetching credit limit.</span>
                          : " " }
                        </div>
                      </div>
                    ))}
                    {webApplicationLoginId === null && webApplicationLoginId === "" &&
                      (
                        <span className="limitExceed text-center mt-4 mx-auto ml-5">Unable to fetch Credit Limit</span>
                      )}
                  </ThemeProvider>
                </div>
              </div>
              <div className="col-12 mt-3 text-center mr-0 pr-0">
                <Button
                  className="font-size-11 new-brand-credit-request-button col-12 m-0"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled
                  onClick={() => { }}>New Brand Credit Request</Button>
              </div>
            </div>
          }
        </div>
      )}
    </Fragment>
  )
}

export default DealerDetails
