import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
    }),
);

export default function ErrorFormat(props: any) {
    const classes = useStyles();
    const { error } = props;
    return (
        <div className={classes.root}>
            <Alert severity="error">{error && error.error ? error.error : error && error.message ? error.message : error}</Alert>
        </div>
    );
}
