import { tasksService } from "../../services/tasks.service";
import { TASKS } from "./actionsTypes";
import { apiError, apiStart, apiSuccess } from "./apiActions";

export const announcementsActions = {
    getTasks,
    closeTask,
  };
  
  export function getTasks(page:number) {
    return async (dispatch: any) => {
      dispatch(apiStart(TASKS.GET_TASKS_FETCHING));
      try {
        // const response = AnnouncementList.Offers.OffersList;
        const response = await tasksService.getTasks(page);
        dispatch(apiSuccess(TASKS.GET_TASKS_SUCCESS, response));
        return response || [];
      } catch (error) {
        dispatch(apiError(TASKS.GET_TASKS_ERROR, error));
        return error;
      }
    };
  }

  export function closeTask(id:string) {
    return async (dispatch: any) => {
      dispatch(apiStart(TASKS.CLOSE_TASKS_FETCHING));
      try {
        const response = await tasksService.closeTasks(id);
        dispatch(apiSuccess(TASKS.CLOSE_TASKS_SUCCESS, response));
        return response || {};
      } catch (error) {
        dispatch(apiError(TASKS.CLOSE_TASKS_ERROR, error));
        return error;
      }
    };
  }