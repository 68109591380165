import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { TextField, MenuItem } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DeleteDialog from '../../../common/components/deleteDialog';
import { withStyles, Theme, createStyles, makeStyles, createTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
//import RequestCorrectionDialog from './RequestCorrectionDialog';
import RequestCorrectionDialog from '../../ViewEditDealerDetailsKam/RequestCorrectionDialog';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Dialog from '@material-ui/core/Dialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import ActionButtons from './ActionButtons';
//import './Access.css';
import { useEffect } from 'react';
import { onboardingService } from '../../../services/onboard.service';
import { get, sample } from 'lodash';
import ReturnRequestSnackbar from '../../../common/components/CreatedEnquirySnackbar';
import FullscreenLoader from '../../../common/components/FullscreenLoader';
import { changeCustomerType, getDealerDetails, getShipToPartyAddress } from '../../../store/actions/onboard.actions';
import { getNewBrand } from '../../../common/components/util';
import { OnboardingAuthorisedSignatoriesValidation, ValidationErrorMessage } from '../../../constants/messages';
import SubmitDialog from '../../newUserOnboard/submitYourRequestDialog';
import SubmitDialogKAM from './submitYourRequestDialogKam';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    flex: '1 1 100%',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const AddNewKamAccess = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const [selectedTab, setselectedTab] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const { generateB2bId }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(generateB2bId, "data.createdByB2bUserId", "");
  const [paramsdata, setparamsdata] = React.useState<any>([])
  const [isApprove, setIsApprove] = React.useState<any>(false);
  const [shipToPartyAddressData, setShipToPartyAddressData] = React.useState<any>();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [notfetchedInitialData, setnotfetchedInitialData] = React.useState(true);
  const [formSubmitted, setformSubmitted] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [sapAvailabilityChecked, setsapAvailabilityChecked] = React.useState<any>(false);
  const [accessTabData, setAccessTabData] = React.useState<any>();
  // const [dataUpdated, setDataUpdated] = React.useState(false);
  const [firstName, setFirstName] = React.useState<string>("");
  const [firstNameError, setfirstNameError] = React.useState<boolean>(false);
  const [firstNameErrorMessage, setfirstNameErrorMessage] = React.useState<string>("");
  const [openSubmitDialog, setOpenSubmitDialog] = React.useState(false);
  const [lastName, setLastName] = React.useState<string>("");
  const [lastNameError, setLastNameError] = React.useState<boolean>(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>("");
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [dealerDetails, setDealerDetails] = React.useState<any>();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const { customerDetails }: any = useSelector((state: any) => state.onboard);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const sapCustomerId = dealerDetails && dealerDetails.data && isExistingUser
    ? dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId : customerDetails && customerDetails.data && customerDetails.data.customerInformation.customerCode;
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const [formData, setformData] = React.useState<any>({
    "featureAccessibility": [
    ],
    "userInfo": {
      "firstName": "",
      "lastName": "",
      "emailId": "",
    }
  });

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const classes = useStyles();

  useEffect(() => {
    loadInitialData();
    setDataUpdated(false)
  }, [])

  const loadInitialData = async () => {
    let paramsData: any = await onboardingService.getAllAcessParams();
    let data = await onboardingService.getAccessTabData(b2bUserId);

    if (data && data.data) {
      setAccessTabData(data.data.userInfo);
      if (data.data.userInfo && data.data.userInfo.firstName) {
        setFirstName(data.data.userInfo.firstName);

      }
      if (data.data.userInfo && data.data.userInfo.lastName) {
        setLastName(data.data.userInfo.lastName)
      }
      if (data.data.userInfo && data.data.userInfo.email) {
        setEmail(data.data.userInfo.email)
      }
      if (data.data.featureAccessibility && Array.isArray(data.data.featureAccessibility)) {
        let allowedIds: any = [];
        data.data.featureAccessibility.map((feature: any) => {
          if (feature.value) {
            allowedIds.push(feature.featureCode)
          }
        })
        if (paramsData && paramsData.data && paramsData.data[0] && paramsData.data[0].featureAccessibility) {
          let tempData = paramsData.data[0].featureAccessibility
          tempData.map((param: any) => {
            if (allowedIds.includes(param.featureCode)) {
              param.value = true
            } else {
              param.value = false
            }
            return param
          })
          setparamsdata(tempData)
        } else {
          setparamsdata(paramsData && paramsData.data && paramsData.data[0] && paramsData.data[0].featureAccessibility)
        }
      } else {
        setparamsdata(paramsData && paramsData.data && paramsData.data[0] && paramsData.data[0].featureAccessibility)
      }
    }
    setnotfetchedInitialData(false)
  }

  const approveCallback = async () => {
    let dataValid = true;
    let dealerDetails: any = await dispatch(getDealerDetails(b2bUserId, 'dealerDetails,documents,kamReview'));
    let shipToPartyAddressData: any = await dispatch(getShipToPartyAddress(b2bUserId));
    if (dealerDetails && dealerDetails.data && shipToPartyAddressData && shipToPartyAddressData.data) {
      if (dealerDetails.data.partnerDetails && dealerDetails.data.partnerDetails.length > 0) {
        setIsApprove(true);
      } else {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Please add at leaset one Owner/Partner");
        setIsApprove(false);
        dataValid = false;
      }

      if (dealerDetails.data.authorizedSignatories && dealerDetails.data.authorizedSignatories.length > 0) {
        setIsApprove(true);
      } else {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Please add at leaset one Signatory");
        setIsApprove(false);
        dataValid = false;
      }
      if (dealerDetails.data.treadLicense && dealerDetails.data.treadLicense.length > 0) {
        let allTl: any = [];
        dealerDetails.data.treadLicense.map((tl: any) => {
          if (tl.regionCode && tl.tradeLicenseNo && tl.expiryDate && tl.documentId) {
            allTl.push(true);
          } else {
            allTl.push(false);
          }
        });
        if (allTl && allTl && allTl.every((p: any) => p === true)) {
          setIsApprove(true);
        } else {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage("All fields are mandatory in Trande licence.");
          setIsApprove(false);
          dataValid = false;
        }
      }
      if (dealerDetails.data.companySeal && dealerDetails.data.companySeal.documentId) {
        setIsApprove(true);
      } else {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Please add companySeal document.");
        setIsApprove(false);
        dataValid = false;
      }
      if (dealerDetails.data.memorandumOfAssociation && dealerDetails.data.memorandumOfAssociation.documentId && dealerDetails.data.memorandumOfAssociation.expiryDate) {
        setIsApprove(true);
      } else {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Please add Memorandum Of Association document.");
        setIsApprove(false);
        dataValid = false;
      }
      if (dealerDetails.data.vatCertificateCopy && dealerDetails.data.vatCertificateCopy.documentId && dealerDetails.data.vatCertificateCopy.expiryDate) {
        setIsApprove(true);
      } else {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Please add Vat Certificate Copy document.");
        setIsApprove(false);
        dataValid = false;
      }
      if (dealerDetails.data.kamReview) {
        let kamData = dealerDetails.data.kamReview;
        if (kamData.size && kamData.stock && kamData.salesSince && kamData.growthPlan && kamData.numberOfStaff && kamData.paymentHistory && kamData.salesToCutomerCurrencyType && kamData.pictureOfHo && kamData.pictureOfHo.length > 0) {
          setIsApprove(true);
        } else {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage("Please fill all mandatory fields and documents in kam review.");
          setIsApprove(false);
          dataValid = false;
        }
      }

      if (shipToPartyAddressData && shipToPartyAddressData.data && shipToPartyAddressData.data.addresses && shipToPartyAddressData.data.addresses.length > 0) {
        let allAdd: any = [];
        shipToPartyAddressData.data.addresses.map((add: any) => {
          if (add.shiftToPartyCustomerNumber || add.transportationZone) {
            allAdd.push(true);
          } else {
            allAdd.push(false);
          }
        });
        if (allAdd && allAdd && allAdd.every((p: any) => p === true)) {
          setIsApprove(true);
        } else {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage("TransportationZone or CustomerNumber is mandatory in ship to party.");
          setIsApprove(false);
          dataValid = false;
        }
      }
      let mainvalid = await validateAction();
      if (dataValid && mainvalid) {
        setIsApprove(true);
      } else {
        setIsApprove(false);
        dataValid = false;
      }
    }
    return dataValid;
  }

  const handleopenCloseSubmit = (open?: any) => {
    setformSubmitted(true);
    if (validationForm()) {
      setOpenSubmitDialog(open ? true : false);
    }
  };

  // ON CHANGE SELECT DEALER TYPE 
  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }

  const handleBackClick = () => {
    // props.setTabValue(3)
    if (dataUpdated) {
      setDeleteMessage('Do you wish to proceed without saving the data?');
      handleopenClose(true);
    } else {
      props.setTabValue(3)
    }
  }

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const deleteCustomerData = async () => {
    let data: any = await dispatch(changeCustomerType({ b2bUserId: b2bUserId }));
    if (data && data.message && data.message.status === 'S') {
      history.push('/dealers');
    } else {
      setSuccessMessage(data.error);
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
    }
  }

  function validateFormFields(action: string, value: any) {
    switch (action) {
      case "lastName": {
        const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return value.length > 250 || !re.test(value) ? false : true;
      }
      case "firstName": {
        const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return !re.test(value) ? false : true;
      }
      case "email": {
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        return re.test(value.trim()) ? false : true;
      }
      default:
        return true;
    }
  }

  function validationForm() {
    let isValid = true;
    if (firstName === "") {
      isValid = false;
      setfirstNameError(true);
    }
    else {
      if (!validateFormFields('firstName', firstName)) {
        setfirstNameError(true);
        setfirstNameErrorMessage(ValidationErrorMessage.LENGTH)
        isValid = false;
      }
      else {
        setfirstNameError(false);
        setfirstNameErrorMessage("")
        isValid = true;
      }
    }
    if (lastName === "") {
      isValid = false;
      setLastNameError(true);
    }
    else {
      if (!validateFormFields('lastName', lastName)) {
        setLastNameError(true);
        setLastNameErrorMessage(ValidationErrorMessage.LENGTH)
        isValid = false;

      }
      else {
        setLastNameError(false);
        setLastNameErrorMessage("")

        isValid = true;
      }
    }
    if (email === "") {
      isValid = false;
      setEmailError(true);
    }
    else {
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.trim()))) {
        isValid = false;
        setEmailError(true);
        setEmailErrorMessage(OnboardingAuthorisedSignatoriesValidation.EMAIL_TEXT)
      }
      else {
        setEmailError(false);
        setEmailErrorMessage("")
        isValid = true;
      }
    }
    return isValid;
  }

  const saveAccessTab = async (sendInvitation: any) => {
    setformSubmitted(true);
    if (accessTabData && accessTabData.sapCustomerId === '') {
      let featureData = paramsdata.filter((item: any) => item.value)
      let data = {
        "sapCustomerId": "",
        "sessionDetails": {
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
        },
        "b2bUserId": b2bUserId,
        "sendInvitation": sendInvitation,
        "featureAccessibility": featureData,
        "userInfo": {
          "firstName": firstName,
          "lastName": lastName,
          "emailId": email
        }
      }
      if (validationForm()) {
        const responseData = await onboardingService.sendInvitation(data);
        setnotfetchedInitialData(false)
        if (responseData && responseData.message && responseData.message.status == "S") {
          setDataUpdated(false);
          props.setDataUpdated(false);
          loadInitialData();
          setOpenErrorSnackbar(false);
          setOpenSnackbar(true);
          setTimeout(() => {
            if (sendInvitation) {
              history.push('/dealers');
            }
          }, 4000)
          setSuccessMessage(responseData.message.message);
        } else {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage(responseData.error ? responseData.error : "Something went Wrong");
        }
      }
    }
  }

  function onChangeFirstName(data: any) {
    setDataUpdated(true);
    setFirstName(data.target.value);
    setfirstNameError(false)
  }

  function onChangeLastName(data: any) {
    setDataUpdated(true);
    setLastName(data.target.value);
    setLastNameError(false)
  }

  function onChangeEmail(data: any) {
    setDataUpdated(true);
    setformSubmitted(false);
    setEmail(data.target.value);
    if (data.target.value) {
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.target.value.trim()))) {
        setEmailError(true);
        setEmailErrorMessage("Invalid email");
      }
      else {
        setEmailError(false);
        setEmailErrorMessage("");
      }
    }
    else {
      setEmailError(false);
      setEmailErrorMessage("");
    }
  }

  const validateAction = async () => {
    setformSubmitted(true);
    if (sapAvailabilityChecked) {
      let featureData = paramsdata.filter((item: any) => item.value)
      let data = {
        "sapCustomerId": formData.userInfo.sapCustomerId,
        "sessionDetails": {
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
        },
        "b2bUserId": b2bUserId,
        "isApprove": false,
        "customerType": "",
        "featureAccessibility": featureData,
        "addParameter": formData.addParameter
      }

      let hasValidationIssue = false;
      const requiredBandField = ['shipToParty', 'payerId', 'billToParty', 'webApplicationLoginId']
      requiredBandField.map((item: any) => {
        if (!formData['addParameter'] || !formData['addParameter'][item]) {
          hasValidationIssue = true
        }
      })
      let counter = 0;
      let brandName = ""
      let tempdata: any = JSON.parse(JSON.stringify(data.addParameter['brands']));
      if (tempdata) {
        tempdata = tempdata.filter((item: any) => {
          if (item.assignKam && item.salesEmployee && item.salesGroup && item.salesOffice && item.division && item.distributionChannel && item.salesOrganization && item.creditControlArea) {
            counter += 1
            return true
          } else {
            let fields = ["creditControlArea", "distributionChannel", "division", "salesOffice", "salesGroup", "salesEmployee"]
            fields.map((subItem) => {
              if (item[subItem]) {
                hasValidationIssue = true
                brandName = item.make
              }
            })
            return false
          }
        })
      }
      if (counter == 0) {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Please fill the details for atleast one brand below.");
        return
      } else if (hasValidationIssue) {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage(`Please complete the details for the following brand(s): ${brandName}`);
        return
      }
      if (hasValidationIssue) {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Please fill all mandatory fields");
        return false
      }
      data.addParameter['brands'] = tempdata;
      return data
    } else {
      setOpenErrorSnackbar(true);
      setOpenSnackbar(true);
      setSuccessMessage("Check User Id Availability before Saving");
      return false
    }
  }

  return (
    <Fragment>
      {notfetchedInitialData && <FullscreenLoader />}
      <div className="card col-12 m-0 p-0">
        <div className="row mb-0 px-2">
          <div className="col-12 mt-1">
            <div className="d-flex align-items-center">
              <h5 className="page-sub-title px-2 mt-1">Customer Access</h5>
              <div className="row-space-start ml-auto">
                <Button
                  className="mr-2 text-info"
                  startIcon={<RotateLeftIcon className="font-size-18 mb-1" />}
                  onClick={async () => {
                    const paramsData = await onboardingService.getAllAcessParams();
                    if (paramsData && paramsData.data && paramsData.data[0] && paramsData.data[0].featureAccessibility) {
                      setparamsdata(paramsData.data[0].featureAccessibility)
                      props.setDataUpdated(true);
                      setDataUpdated(true);
                    }
                  }}
                ><span className="onshiftoparty-font mt-1 mb-2 pr-0">Reset to Default
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card col-12 m-0 p-0">
        <div className="row mb-1 mt-1">
          {paramsdata && paramsdata.map((itemParam: any, index: any) => {
            return <div className="col-sm-12 col-md-4 col-lg-4 px-3 pr-2 mb-2 mt-2 row-space-between border-right">
              <div className="lock-indicator font-size-12 px-2">
                {itemParam.label}
              </div>
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>
                    <AntSwitch disabled={itemParam.featureCode == "SEARCH_EPC" || (itemParam.featureCode === 'MANAGE_EXPORT_TO_EXL') || itemParam.featureCode == "LIST_BILL_DOC" || itemParam.featureCode == "ACCOUNT_STATEMENT" || itemParam.featureCode == "CURT_PREVI_MONTH_SALES" || itemParam.featureCode == "DOC_EXP"} checked={itemParam.value}
                      onChange={(e) => {
                        props.setDataUpdated(true);
                        setDataUpdated(true);
                        let paramDataTemp = JSON.parse(JSON.stringify(paramsdata));
                        paramDataTemp[index].value = !itemParam.value
                        setparamsdata(paramDataTemp)
                      }} />
                  </Grid>
                </Grid>
              </Typography>
            </div>
          })}
        </div>
      </div>
      <div className="row-space-start col-12 p-3">
        <>
          <div className="col-12 m-0 p-0">
            <div className="row mt-0 mb-1">
              <h5 className="page-sub-title px-2 mt-1">Add Admin</h5>
            </div>
            <div className="row mb-1 mt-0 px-3">
              <div className="col-sm-12 col-md-4 col-sm-4 px-2 pr-2 mb-1 mt-2 row-space-between ">
                <TextField id=""
                  required
                  type="text"
                  value={firstName}
                  error={firstNameError}
                  // helperText={setSizeError && setSize && <span style={{ color: 'red' }}>{setSizeValidationMessage}</span>}
                  onChange={(e: any) => onChangeFirstName(e)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="First Name" variant="outlined">
                </TextField>
              </div>
              <div className="col-sm-12 col-md-4 col-sm-4 px-2 pr-2 mb-1 mt-2 row-space-between ">
                <TextField id=""
                  required
                  type="text"
                  value={lastName}
                  error={lastNameError}
                  onChange={(e: any) => onChangeLastName(e)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Last Name" variant="outlined">
                </TextField>
              </div>
              <div className="col-sm-12 col-md-4 col-sm-4 px-2 pr-2 mb-1 mt-2 row-space-between ">
                <TextField id=""
                  required
                  type="text"
                  value={email}
                  error={emailError}
                  onChange={(e: any) => onChangeEmail(e)}
                  helperText={emailError && email.length > 0 && formSubmitted && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Email" variant="outlined">
                </TextField>
              </div>
            </div>
          </div>
        </>
      </div>
      <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
        <div className="col-12 display-flex p-0">
          <div className="col-8 p-0">
            <div className="row m-0">
              {/* <div className="col-5 p-0">
                <p className="mt-2 ml-4 shipAdd">Dealer Type</p>
                {dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' ? (
                  <p className="ml-4 mt-2 text-capitalize">{sessionStorage.getItem('customerType')} User</p>
                ) : (
                  <p className="cursor-pointer ml-4 mt-2" onClick={() => { changeDealerType() }}>
                    <span className="document-content text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                      Dealer</span><KeyboardArrowDownIcon />{'  '}<span className="info-sub-header-document">Change Type</span>
                  </p>
                )}
              </div> */}
              <div className="col-4 mt-3 p-0">
                <Button variant="contained" className="border-left rounded-pill ml-5 w-150p mb-2"
                  onClick={() => saveAccessTab(false)}
                >Save Form</Button>
              </div>
            </div>
          </div>
          <div className="col-4 onboard-footer float-right">
            <div className="p-3 mr-3">
              <Button variant="contained" className="rounded-pill pl-0 pr-0 w-150p"
                onClick={() => handleBackClick()}
              >Back</Button>
            </div>
            <div className="p-3 mr-3">
              <Button variant="contained" color="primary"
                onClick={() => {
                  setDeleteMessage("Are you sure you want to switch from the current Dealer type. By doing so, all your saved data will be lost.");
                  handleopenCloseSubmit(true);
                }}
                className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2" >{props.showSave ? 'Submit' : 'Submit'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SubmitDialogKAM
        open={openSubmitDialog}
        handleopenClose={(data: any) => {
          if (data === 'Submit') {
            saveAccessTab(true);
          }
          if (!data) handleopenCloseSubmit(false);
        }} />
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm">
        <RequestCorrectionDialog setOpenDialog={handleClose}></RequestCorrectionDialog>
      </Dialog>
      <DeleteDialog
        open={confirmDialog}
        text={deleteMessage}
        handleopenClose={(data: any) => {
          if (data === 'delete') {
            deleteCustomerData();
          }
          else if (data === 'back') {
            handleopenClose(false)
            props.setTabValue(3);
          }
          if (!data) {
            handleopenClose(false)
            handleopenCloseConfirmPopup(false);
          }
        }} />

      {/* <ActionButtons
        isSaveDisabled={!props.dataUpdated}
        iscorrectionDisabled={false}
        isapproveDisabled={!(accessTabData && accessTabData.sapCustomerId)}
        isrejectDisabled={true}
        showSave={true}
        showBackButton={true}

        saveCallback={() => {
          saveAccessTab(true)
        }}
        correctionCallback={() => {
          handleClickOpen();
        }}
        saveFormCallBack={() => {
          saveAccessTab(false)
        }}
        approveCallback={approveCallback}
        rejectCallback={() => { }}
        validateAction={validateAction}
      /> */}
      <ReturnRequestSnackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          setOpenSnackbar(false)
        }} message={successMessage} />
    </Fragment>
  );
}
export default AddNewKamAccess;

