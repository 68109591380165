import React, { Fragment, useEffect, Dispatch } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DeleteDialog from '../../common/components/deleteDialog';
import Visibility from "@material-ui/icons/Visibility";
import { AddNewAddressDailog } from './AddNewAddressDailog';
import { getShipToPartyAddress, AddShipToPartyAddress, deleteShipToPartyAddress, getSelectedShipToPartyAddress, UpdatedShipToPartyAddress, getTradeRegion, resetValues, getTransportationZone, saveZone, setCustomerType } from '../../store/actions/onboard.actions';
import TextField from '@material-ui/core/TextField';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import { MenuItem, Button, makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import FullscreenLoader from './../../common/components/FullscreenLoader';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import Tooltip from '@material-ui/core/Tooltip';

import Alert from '@material-ui/lab/Alert';
import ActionButtons from './ActionButtons';
import './shiptoparty.css';
import { Help } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingLeft: 220
  },
}));

export const ShipToParty = (props: any) => {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState<any>('');
  const [shippingAddress, setShippingAddress] = React.useState<any>(null);
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const b2bUserId: any = selectedNewDealer && selectedNewDealer.createdByB2bUserId;
  const [selected, setSelected] = React.useState<string>("");
  const [sapId, setSapId] = React.useState<string>();
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [apiResponseText, setApiResponseText] = React.useState('');
  const [tempSetCustomer, TempSetCustomer] = React.useState<any>(["New", "Existing"]);
  const [loading, setLoading] = React.useState<any>(true);
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [formSubmitted, setformSubmitted] = React.useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const { shipToPartyAddressData, getSelectedAddress, regionData, updateAddressData, deleteAddressData, saveNewAdress, zoneData, dealerDetails }: any = useSelector((state: any) => state.onboard);

  const [editedAddress, setEditAddress] = React.useState({});
  const [addressList, setAddressList] = React.useState<any>([]);


  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getNewAddressData = async (data: any) => {
    if (isEdited) {
      let result = await dispatch(UpdatedShipToPartyAddress(b2bUserId, data, selected))
      const apiResponse = get(result, 'message', {});

      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
    }
    else {
      let result = await dispatch(AddShipToPartyAddress(b2bUserId, data));
      const apiResponse = get(result, 'message', {});

      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);


        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }

      setSelected("");
    }


  }

  const getRegionName = (regionCode: any) => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      var regionName = regionData.data[0].regions.filter((obj: any) => obj.Code == regionCode)
      return regionName && regionName.length > 0 ? regionName[0].Region : '-';
    }
    return "";
  }

  const onClickDeleteAddress = async (shippingAddress: any) => {
    handleopenClose(true);
    setIsEdited(false);
    setSelected("");
    setShippingAddress(shippingAddress);

  }

  const deleteShippingAddress = async (action: any) => {
    handleopenClose(false);
    var userType = {
      "userType": "kam"
    }
    let result = await dispatch(deleteShipToPartyAddress(b2bUserId, shippingAddress.id, userType));
    const apiResponse = get(result, 'message', {});
    if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
      setOpenSnackbar(true);
      setApiResponseText(apiResponse.message);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
    }
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEdited(false);
    setEditAddress([]);
  };

  const onClickEditAddress = async (addressId: any) => {
    setIsEdited(true);
    await dispatch(getSelectedShipToPartyAddress(b2bUserId, addressId));
    setSelected(addressId);
    setOpen(true);
  }

  function getZoneCode(zoneName: any) {
    let zoneCode: any = '';
    if (zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone
      && zoneData.data[0].transportationZone.length > 0)
      zoneData.data[0].transportationZone.map((zone: any) => {
        if (zone.title === zoneName) {
          zoneCode = zone.code
        }
      });
    return zoneCode;
  }


  const onChangeZone = (e: any, data: any, index: any) => {
    props.setDataUpdated(true);
    let address = [...addressList]
    if (e.target.value && e.target.value) {
      if (address[index].type == "New") {
        address[index].transportationZone = e.target.value;
        address[index].transportationZoneCode = getZoneCode(e.target.value);
      }

      else {
        address[index].shiftToPartyCustomerNumber = e.target.value;
      }

      setAddressList(address);
    }
    else if (!e.target.value) {
      address[index].shiftToPartyCustomerNumber = "";
      setAddressList(address);
    }
  }

  const onChangeNew = (e: any, data: any, index: any) => {
    // props.setDataUpdated(true);
    if (e.target.value && e.target.value) {
      let address = [...addressList]
      address[index].type = e.target.value;
      if (e.target.value == "New") {
        address[index].isExisting = false;
        address[index].transportationZone = "";
        address[index].transportationZoneCode = getZoneCode(e.target.value);
      }
      else {
        address[index].isExisting = true;
        address[index].shiftToPartyCustomerNumber = "";
      }
      // address[index].transportationZoneCode = getZoneCode(e.target.value);
      setAddressList(address);
    }
  }

  const saveZoneAddress = async () => {
    if (status !== 'Incomplete from KAM') {
      setIsValid(true);
      let isValidTemp = true
      let address = [...addressList];
      let data: any = {
        addresses: []
      };
      address.map((add: any) => {
        if (add.transportationZone && add.transportationZoneCode && !add.isExisting) {
          data.addresses.push({
            id: add.id,
            transportationZone: add.transportationZone,
            transportationZoneCode: add.transportationZoneCode,
            isExisting: add.isExisting,
          })
        }
        else if (add.isExisting && add.shiftToPartyCustomerNumber) {
          data.addresses.push({
            id: add.id,
            isExisting: add.isExisting,
            shiftToPartyCustomerNumber: add.shiftToPartyCustomerNumber
          })
        } else {
          isValidTemp = false;
          setIsValid(false);
        }
      });
      if (isValidTemp && address.length === data.addresses.length) {
        let savedZone: any = await dispatch(saveZone(b2bUserId, data));
        if (savedZone && savedZone.message && savedZone.message.status === 'S') {
          props.setDataUpdated(false);
          setApiResponseText(savedZone.message.message);
          handleopenCloseSnackbar(true);
          return true;
        } else {
          setApiResponseText(savedZone.error);
          handleopenCloseErrorSnackbar(true);
          return false;
        }
      } else {
        setApiResponseText('Please fill all mandatory fields');
        handleopenCloseErrorSnackbar(true);
      }
    }
  }

  // useEffect(() => {

  //   if ((saveNewAdress && saveNewAdress.data && saveNewAdress.data.data) || (updateAddressData && updateAddressData.data && updateAddressData.data.data) || (deleteAddressData && deleteAddressData.data && deleteAddressData.data.data)) {
  //     dispatch(getShipToPartyAddress(b2bUserId));
  //   }
  // }, [saveNewAdress, updateAddressData, deleteAddressData])

  useEffect(() => {
    // if (saveNewAdress) {
    //   setLoading(false);
    // }
    if (saveNewAdress && saveNewAdress.data && saveNewAdress.data.message) {
      handleopenClose(false);
      setSuccessMessage(saveNewAdress.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [saveNewAdress]);

  useEffect(() => {
    // if (updateAddressData) {
    //   setLoading(false);
    // }
    if (updateAddressData && updateAddressData.data && updateAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(updateAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [updateAddressData]);

  useEffect(() => {
    // if (deleteAddressData) {
    //   setLoading(false);
    // }
    if (deleteAddressData && deleteAddressData.data && deleteAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deleteAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [deleteAddressData]);

  useEffect(() => {
    loadshipDetails();
  }, []);

  const loadshipDetails = async () => {
    setLoading(true);
    props.setDataUpdated(false)
    let response: any = await dispatch(getShipToPartyAddress(b2bUserId));
    let response1: any = await dispatch(getTransportationZone(b2bUserId));
    if (response || response1) {
      setLoading(false);
    }
    setSuccessMessage(null)
    handleopenCloseSnackbar(false);
    dispatch(resetValues());

  }

  useEffect(() => {
    if (shipToPartyAddressData && shipToPartyAddressData.data) {
      // setLoading(false);
      // shipToPartyAddressData.data.addresses["type"] = "";
      setAddressList(setaddressData(shipToPartyAddressData.data.addresses));
      setSapId(shipToPartyAddressData.data.sapCustomerId);
    }
    else if (shipToPartyAddressData && shipToPartyAddressData.err) {
      // setLoading(false);
    }
    if (getSelectedAddress && getSelectedAddress.data) {
      // setLoading(false);
      setEditAddress(getSelectedAddress ? getSelectedAddress.data : {})
    }
    else {
      // setLoading(false);
      setSelected("");
    }

  }, [shipToPartyAddressData, getSelectedAddress])

  function isDisabled() {
    if (status === 'Incomplete from KAM' || status === 'Dealer confirmation pending') {
      return true;
    } else {
      return false;
    }
  }

  const handleBackClick = () => {
    props.setTabValue(1)

    if (props.dataUpdated) {
      // setDeleteMessage('Do you wish to proceed without saving the data?');
      // handleopenClose(true);
    } else {
      props.setTabValue(1)
    }
  }

  const handleNextClick = async () => {
    if (saveZoneAddress()) {
      props.setTabValue(3);
    }
  }

  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }

  const handleopenCloseSubmit = (open?: any) => {
    setformSubmitted(true);
    // if (validateExistingTab()) {
    //   setOpenSubmitDialog(open ? true : false);
    // }
  };



  function setaddressData(data: any) {
    data.forEach((element: any) => {
      element["type"] = "";
      if (dealerDetails && dealerDetails.data && dealerDetails.data.isNewCustomer) {
        element.type = "New";
        setStatus(dealerDetails.data.status);
        element.isExisting = false;
      } else {
        element.type = "Existing";
        setStatus(dealerDetails && dealerDetails.data && dealerDetails.data.status);
        element.isExisting = true;
      }
    });
    return data;
  }

  return (
    <Fragment>
      {/* {loading && <FullscreenLoader />} */}
      {loading && <FullscreenLoader />}
      {shipToPartyAddressData && shipToPartyAddressData.loading && <FullscreenLoader />}
      {getSelectedAddress && getSelectedAddress.loading && <FullscreenLoader />}
      {updateAddressData && updateAddressData.loading && <FullscreenLoader />}
      {deleteAddressData && deleteAddressData.loading && <FullscreenLoader />}
      {saveNewAdress && saveNewAdress.loading && <FullscreenLoader />}
      {/* <div className="col-12">
        <div className="d-flex align-items-center mb-2">
          <div className="row-space-start ml-auto">
            <Button
              className="text-info disabledCustomclass"
              startIcon={<AddIcon className="font-size-18" />}
              disabled
              // disabled={shipToPartyAddressData && shipToPartyAddressData.err && shipToPartyAddressData.err.error}
              onClick={() => {
                handleClickOpen();
              }}
            ><span className="onshiftoparty-font">Add New Address
              </span>
            </Button> 
          </div>
        </div>
      </div> */}
      <div className="row">
        {(shipToPartyAddressData && shipToPartyAddressData.err) && (
          <div className="col-12">
            <Alert severity="info">Please submit Complete Registration details prior to Ship to Party!</Alert>
          </div>
        )}
        {addressList && addressList.length > 0 && addressList.map((shipppingAddress: any, index: any) => (
          <>
            <div className="col-12 m-0 pl-3">
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="card-body border-bottom pt-1" key={index}>
                    <div className="w-50 d-inline-block">
                      <div className="shipAddTitle">{shipppingAddress.dafault ? shipppingAddress.companyName : shipppingAddress.location} &nbsp;
                        {shipppingAddress.dafault && (
                          <Button
                            className="defaultAddressButton cursor-default"
                            size="small"
                            variant="contained"
                            color="secondary"
                          >Head Office</Button>
                        )}
                      </div>
                      <div className="shipAdd mt-2">{shipppingAddress.streetName ? shipppingAddress.streetName + " - " : ""}  {shipppingAddress.city ? shipppingAddress.city + " - " : ""} {shipppingAddress.country}</div>
                      <div className="shipAdd">Telephone Office: {typeof shipppingAddress.telephoneNumber == 'string' ? shipppingAddress.telephoneNumber : shipppingAddress && shipppingAddress.telephoneNumber && shipppingAddress.telephoneNumber.length > 0 && shipppingAddress.telephoneNumber[0].telephone}</div>
                      {/* <div className="shipAdd">PO Box: {shipppingAddress.poBox}</div> */}
                      <div className="shipAdd">Emirates/Region: {shipppingAddress.region} {getRegionName(shipppingAddress.region)}</div>
                      {/* <div> */}
                      {isDisabled() ? '' : (
                        <div className="px-0 col-md-3 col-lg-5" style={{ display: "flex" }}>
                          <div className="px-0 col-md-3 col-lg-4 typeOfCustomer">
                            <TextField select id="New"
                              required
                              disabled
                              // error={!shipppingAddress.transportationZone && !isValid}
                              value={shipppingAddress.type}
                              onChange={(e: any) => { onChangeNew(e, shipppingAddress, index); props.setDataUpdated(true); }}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs"
                              variant="outlined" margin="dense" >
                              {tempSetCustomer.length > 0 && tempSetCustomer.map((zone: any, index: any) => (
                                <MenuItem value={zone}> {zone} </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          <Tooltip title={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 ?
                            'Customer needs to fill all the address details before assigning Transportation zone/Ship to party customer number ' : ''} placement="top">
                            {!shipppingAddress.isExisting ?
                              <TextField select id="transportationZone"
                                required
                                disabled={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                                error={!shipppingAddress.transportationZone && !isValid}
                                value={shipppingAddress.transportationZone}
                                onChange={(e: any) => onChangeZone(e, shipppingAddress, index)}
                                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                label="Transportation Zone" variant="outlined" margin="dense" >
                                {zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone && zoneData.data[0].transportationZone.length > 0 && zoneData.data[0].transportationZone.map((zone: any, index: any) => (
                                  <MenuItem key={zone.code} value={zone.title}> {zone.title} </MenuItem>
                                ))}
                              </TextField>
                              : <TextField type="text" id="shipToParty"
                                required
                                error={!shipppingAddress.shiftToPartyCustomerNumber && !isValid}
                                value={shipppingAddress.shiftToPartyCustomerNumber}
                                disabled={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                                onChange={(e: any) => onChangeZone(e, shipppingAddress, index)}
                                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                label="Ship To Party Customer number" variant="outlined" margin="dense" >
                              </TextField>
                            }
                          </Tooltip>
                          {shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && (
                            <Tooltip title={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 ?
                              'Customer needs to fill all the address details before assigning Transportation zone/Ship to party customer number ' : ''} placement="top">
                              <Button
                                className="cursor-normal mb-1 text-info"
                                startIcon={<Help />}
                              >
                              </Button>
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </div>
                    {shipppingAddress.dafault && !isDisabled() && (
                      <div className="markDefaultIcon w-50 d-inline-block text-right">
                        <Button
                          className="text-info"
                          startIcon={<Visibility />}
                          onClick={() => {
                            onClickEditAddress(shipppingAddress.id)
                          }}><span className="onshiftoparty-font">View</span>
                        </Button>
                      </div>
                    )}
                    {isDisabled() && (
                      <div className="markDefaultIcon w-50 d-inline-block text-right">
                        <Button
                          className={status == 'Dealer confirmation pending' ? 'text-info disabledCustomclass' : 'text-info'}
                          disabled={status == 'Dealer confirmation pending'}
                          startIcon={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0 ? <EditOutlinedIcon /> : <AddIcon />}
                          onClick={() => {
                            onClickEditAddress(shipppingAddress.id)
                          }}><span className="onshiftoparty-font">
                            {shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0 ? 'Edit' : 'Add Authorized Person to Accept the Deliveries'}
                          </span>
                        </Button>
                      </div>
                    )}
                    {/* {!shipppingAddress.dafault && (
                      <div className="markDefaultIcon w-25 d-inline-block text-right">
                        <Button
                          className="text-info disabledCustomclass"
                          startIcon={<EditOutlinedIcon />}
                          disabled
                          onClick={() => {
                            onClickEditAddress(shipppingAddress.id)
                          }}><span className="onshiftoparty-font">View/Edit </span>
                        </Button>
                        <Button
                          onClick={() => onClickDeleteAddress(shipppingAddress)}
                          className="text-info disabledCustomclass"
                          disabled
                          startIcon={<DeleteOutlineOutlinedIcon />}
                        > <span className="onshiftoparty-font">Delete</span>
                        </Button>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            {dealerDetails && dealerDetails.data && dealerDetails.data.status === "Incomplete from KAM" && (
              <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
                <div className="col-12 display-flex p-0">
                  <div className="col-8 p-0">
                    <div className={`row m-0`}>
                      {/* <div className="col-5 p-0">
                        <p className="mt-2 ml-4 shipAdd">Dealer Type</p>
                        {dealerDetails.data.status === 'Submitted' ? (
                          <p className="ml-4 mt-2 text-capitalize">{sessionStorage.getItem('customerType')} User</p>
                        ) : (
                          <p className="cursor-pointer ml-4 mt-2" onClick={() => { changeDealerType() }}>
                            <span className="document-content text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                              Dealer</span><KeyboardArrowDownIcon />{'  '}<span className="info-sub-header-document">Change Type</span>
                          </p>
                        )}
                      </div> */}
                      {status !== 'Incomplete from KAM' && (
                        <div className="col-4 mt-3 p-0">
                          <Button variant="contained" className="border-left rounded-pill ml-5 w-150p mb-2"
                            onClick={() => saveZoneAddress()}
                          >Save Form</Button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-4 onboard-footer float-right">
                    <div className="p-3 mr-3">
                      <Button variant="contained" className="rounded-pill pl-0 pr-0 w-150p"
                        onClick={() =>
                          handleBackClick()
                        }
                      >Back</Button>
                    </div>
                    <div className="p-3 mr-3">
                      <Button variant="contained" color="primary"
                        onClick={() => {
                          // setDeleteMessage("Are you sure you want to switch from the current Dealer type. By doing so, all your saved data will be lost.");
                          // handleopenCloseSubmit(true);
                          handleNextClick();

                        }}
                        className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2" >{props.showSave ? 'Next' : 'Next'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <AddNewAddressDailog setOpenDialog={handleClose} getNewAddressData={getNewAddressData} editedAddress={editedAddress} isEdited={isEdited} regionData={regionData} sapId={sapId} status={status} />
      </Dialog>
      <DeleteDialog
        open={openDeleteDialog}
        text="Are you sure you want to delete this shipping address ?"
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteShippingAddress(data);
          if (!data) handleopenClose(false);
        }} />
      {apiResponseText && <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={apiResponseText} />}
      {apiResponseText && <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={apiResponseText} />}
      {
        dealerDetails && dealerDetails.data && dealerDetails.data.status !== "Incomplete from KAM" && (
          <ActionButtons
            isSaveDisabled={!props.dataUpdated}
            iscorrectionDisabled={dealerDetails && dealerDetails.data && dealerDetails.data.status === "Incomplete from KAM" ? true : false}
            isapproveDisabled={true}
            isrejectDisabled={true}
            saveCallback={() => {
              saveZoneAddress();
            }}
            correctionCallback={() => { }}
            approveCallback={() => { }}
            rejectCallback={() => { }}
            setOpenErrorSnackbar={setOpenErrorSnackbar}
            openErrorSnackbar={openErrorSnackbar}
            handleopenCloseSnackbar={handleopenCloseSnackbar}
            setSuccessMessage={setApiResponseText}
            setDataUpdated={props.setDataUpdated}
          />)}
    </Fragment>
  )
}
export default ShipToParty;
