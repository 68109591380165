import React, { Fragment, Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { createTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import XLSX from 'xlsx';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { kamAdditionalCreditRequestValidation } from '../../constants/messages';
import { updateDocument } from '../../store/actions/onboard.actions';
import DeleteDialog from '../../common/components/deleteDialog';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import MenuItem from '@material-ui/core/MenuItem';
import { saveAdditionalCreditRequest, deleteBankStatementOfCreditRequest } from "../../store/actions/dealer.action";
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { getNewBrand } from '../../common/components/util';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  title: {
    flex: '1 1 100%',
  },
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  addUpdateDailogTitle: {
    position: "absolute",
    top: 10,
    left: 10
  },
  correctionTextCSS: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  }
}));
const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

export default function AdditionalCreditRequestKAM(props: any) {
  const dispatch: Dispatch<any> = useDispatch();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [salesOrganizationId, setSalesOrganizationId] = React.useState<any>("");
  const [bankDetails, setBankDetails] = React.useState<any>("");
  const [creditControlArea, setCreditControlArea] = React.useState<any>("");
  const [creditPeriodInDays, setCreditPeriodInDays] = React.useState<any>("");
  const [justificationMsg, setJustificationForCreditText] = React.useState<any>("");
  const [justificationForCreditTextError, setJustificationForCreditTextError] = React.useState<boolean>(false);
  const [invalidJustificationForCreditText, setInvalidJustificationForCreditText] = React.useState<boolean>(false);
  const [justificationForCreditTextErrorMessage, setJustificationForCreditTextErrorMessage] = React.useState<any>("");
  const [existingCreditAmountCurrencyType, setExistingCreditAmountCurrencyType] = React.useState<any>("AED");
  const [additionalCreditAmountCurrencyType, setAdditionalCreditAmountCurrencyType] = React.useState<any>("AED");
  const [additionCreditTypeError, setAdditionCreditTypeError] = React.useState<boolean>(false);
  const [invalidAdditionCreditType, setInvalidAdditionCreditType] = React.useState<boolean>(false);
  const [additionCreditTypeValidationMessage, setAdditionCreditTypeValidationMessage] = React.useState<string>("");
  const [existingCreditAmount, setExistingCreditAmount] = React.useState<string>("");
  const [existingCreditError, setExistingCreditError] = React.useState<boolean>(false);
  const [invalidExistingCredit, setInvalidExistingCredit] = React.useState<boolean>(false);
  const [additionalCreditAmount, setAdditionalCreditAmount] = React.useState<any>();
  const [additionalCreditError, setAdditionalCreditError] = React.useState<boolean>(false);
  const [invalidAdditionalCredit, setInvalidAdditionalCredit] = React.useState<boolean>(false);
  const [selectBrand, setSelectBrand] = React.useState<any>();
  const [selectedCR, setSelectedCR] = React.useState<any>();
  const [selectBrandError, setSelectBrandError] = React.useState<boolean>(false);
  const [invalidSelectBrandError, setInvalidSelectBrandError] = React.useState<boolean>(false);
  const [documentUploadCopy, setDocumentUploadCopy] = React.useState<any>({ documentId: '' });
  const [creditRequestBankGuaranteeFile, setCreditRequestBankGuaranteeFile] = React.useState<any>(null);
  const [documentType, setDocumentType] = React.useState<any>(null);
  const [deletedType, setDeletedType] = React.useState();
  const currencies = ["AED"];
  const { setOpenDialog, selectedBrand } = props;
  const { getDealerCreditRequestAPI, saveAdditionalCreditRequestAPI }: any = useSelector((state: any) => state.dealer);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const sapCustomerId = getDealerCreditRequestAPI && getDealerCreditRequestAPI.data && getDealerCreditRequestAPI.data[0].sapCustomerId
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId') || '';
  const b2bUserId = get(profileDetails, 'data.data.basicInfo.b2bUserId', "");
  const history = useHistory();

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  useEffect(() => {
    if (selectedCR) {
      setExistingCreditAmount(selectedCR.existingCreditAmount);
    }
  }, [selectedCR])

  const handleInputChange = (e: any, setterName: any, errorSetterName?: any) => {
    setterName(e.target.value);
    if (errorSetterName && e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        errorSetterName(true);
      else
        errorSetterName(false);
    }
  }

  // ON CLICK OF DELETE BANK GUARANTEE DOCUMENT
  const deleteDocument = async (type?: any) => {
    let data: any;
    setDeletedType(documentType);
    if (documentType == 'creditRequestBankGuarantee') {
      data = creditRequestBankGuaranteeFile;
    }
    const obj = {
      "type": 'bankGuarantee'
    }
    let deleteDocumentData: any = await dispatch(deleteBankStatementOfCreditRequest(documentUploadCopy && documentUploadCopy.documentId, obj, ''));
    if (deleteDocumentData && deleteDocumentData.message) {
      if (documentType == 'creditRequestBankGuarantee') {
        setCreditRequestBankGuaranteeFile(null);
      }
      handleopenClose(false);
    }
  }

  // API INTEGRATION FOR ADDITIONAL CREDIT REQUEST ON CLICK OF SAVE
  async function saveAdditionalCreditData() {
    const data = {
      "salesOrganization": selectedCR && selectedCR.salesOrganization,
      "make": selectBrand,
      "sapCustomerId": selectedCR && selectedCR.sapCustomerId,
      "webApplicationLoginId": webApplicationLoginId,
      "additionalCreditAmount": additionalCreditAmount,
      "additionalCreditAmountCurrencyType": 'AED',
      "existingCreditAmount": selectedCR && selectedCR.existingCreditAmount,
      "existingCreditAmountCurrencyType": 'AED',
      "justificationMsg": justificationMsg,
      "creditControlArea": creditControlArea,
      "creditPeriodInDays": creditPeriodInDays,
      "documents": [
        {
          "documentId": documentUploadCopy.documentId,
          "Type": 'bankGuarantee'
        }
      ]
    }
    let savedData: any = await dispatch(saveAdditionalCreditRequest(selectedCR && selectedCR.sapCustomerId, data));
    if (savedData && savedData.message && savedData.message.status === 'S') {
      history.push('/creditlist');
      handleopenCloseSnackbar(true);
      setSuccessMessage("Additional Credit Request Created Successfully");
    } else {
      setOpenErrorSnackbar(true);
      handleopenCloseErrorSnackbar(true);
      showErrorMessage(savedData.error);
    }
  }

  // ONCLICK OF CANCEL THE REMOVE ALL DATA FROM THE FILED
  const resetData = () => {
    setExistingCreditError(false);
    setInvalidExistingCredit(false);
    setAdditionalCreditError(false);
    setSelectBrand(null);
    setSelectBrandError(false);
    setInvalidAdditionalCredit(false);
    setInvalidSelectBrandError(false);
    setJustificationForCreditText(null);
    setJustificationForCreditTextError(false);
    setJustificationForCreditTextErrorMessage(null);
    setInvalidJustificationForCreditText(false);
    setDocumentUploadCopy({ documentId: '' });
  }

  // ONCHANGE OF BRAND SELECTOR 
  const onChangeOfBrandSelector = (e: any) => {
    setSelectBrand(e.target.value);
    if (getDealerCreditRequestAPI && getDealerCreditRequestAPI.data && getDealerCreditRequestAPI.data.length > 0) {
      getDealerCreditRequestAPI.data.map((crdata: any) => {
        if (e.target.value === crdata.make) {
          setSelectedCR(crdata);
        }
      })
    }
    if (e.target.value === "" || e.target.value.trim() === "") {
      setSelectBrandError(true);
    }
    else {
      setSelectBrandError(false);
    }
  }

  // VALIDATIONS FOR OTHER TEXTFILEDS 
  const validationAdditionalCreditRequest = () => {
    let isValid: boolean = true;
    if (!selectBrand || selectBrand == "" || selectBrand == undefined) {
      setSelectBrandError(true);
      isValid = false;
    }
    else {
      setSelectBrandError(false);
    }
    if (!additionalCreditAmount || additionalCreditAmount === "" || additionalCreditAmount.trim() === "") {
      setAdditionalCreditError(true);
      isValid = false;
    }
    if (!additionalCreditAmount || additionalCreditAmount === "" || additionalCreditAmount.trim() === "") {
      if (additionalCreditAmount && additionalCreditAmount.split('').length > 250) {
        setInvalidAdditionalCredit(true);
        isValid = false;
      }
      else {
        setInvalidAdditionalCredit(false);
      }
    }
    if (!justificationMsg || justificationMsg === "" || justificationMsg.trim() === "") {
      setJustificationForCreditTextError(true);
      isValid = false;
    }
    if (!justificationMsg || justificationMsg === "" || justificationMsg.trim() !== "") {
      if (justificationMsg && justificationMsg.split('').length > 250) {
        setInvalidAdditionalCredit(true);
        isValid = false;
      }
      else {
        setInvalidJustificationForCreditText(false);
        setJustificationForCreditTextErrorMessage(false);
      }
    }
    if (isValid) {
      saveAdditionalCreditData();
    }
  }

  // ONCLICK BROWSE FILE
  const onClickBrowseFile = async (type: any, item: any, setCallback: any) => {
    showErrorMessage(null);
    handleopenCloseErrorSnackbar(false);
    const name = item && item.name ? item.name : '',
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
      doctExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
    if (!canUploadFile) {
      showErrorMessage('Invalid File type format');
      handleopenCloseErrorSnackbar(true);
      return;
    }
    if (item && item.size < 2000000) {
      const formData: any = new FormData();
      let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;
      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };
      let docType = '';
      if (type === 'creditRequestBankGuaranteeFile') { docType = 'Bank Guarantee Of Credit Request' }
      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", selectedCR && selectedCR.sapCustomerId);
      formData.append("docType", docType);
      formData.append("label", type);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'kam');
      formData.append("uploadedBywebApplicationLoginId", webApplicationLoginId);
      formData.append("selectedFileName", item.name);
      formData.append("brand", selectedCR && selectedCR.salesOrganization);
      let uploadedDocumentData: any = await dispatch(updateDocument(formData, customHeaders));
      if (uploadedDocumentData && uploadedDocumentData.message && uploadedDocumentData.message.status === 'S') {
        const item = uploadedDocumentData && uploadedDocumentData.data ? uploadedDocumentData.data : {};
        const { id, label } = item;
        if (label === 'creditRequestBankGuaranteeFile') {
          setDocumentUploadCopy({ documentId: id });
          setCreditRequestBankGuaranteeFile(item);
        }
      } else {
        showErrorMessage('Document upload failed');
        handleopenCloseErrorSnackbar(true);
      }
    } else if (item && item.size > 2000000) {
      showErrorMessage('Please upload less than 2MB file');
      handleopenCloseErrorSnackbar(true);
    }
  }

  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.root}>
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} text={successMessage} />
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
        <Toolbar
          className={clsx(classes.root)}>
          <div className="col-12 mb-2 mt-2">
            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
              <h4 className="shift-to-party-page-title">
                <div className={classes.addUpdateDailogTitle}> Additional Credit Request </div>
                <IconButton aria-label="close" className="float-right" onClick={() => { setOpenDialog(); }}>
                  <CloseIcon />
                </IconButton>
              </h4>
            </Typography>
          </div>
        </Toolbar>
        <Toolbar className="col-sm-12 mb-2 mt-2">
          <div className="col-md-6 col-lg-6 px-2 mb-1 pl-0 mt-0">
            <TextField
              value={selectBrand} select id="selectBrand"
              error={selectBrandError}
              // helperText={invalidSelectBrandError &&
              //     <span style={{ color: 'red' }}>Please select brand</span>
              // }
              onChange={(e: any) => onChangeOfBrandSelector(e)}
              size="small" className="col-lg-12 col-md-12 col-sm-12 pr-0 commonRoundedInputs common-input-font-size" label="Select Brand *" variant="outlined" >
              {getDealerCreditRequestAPI && getDealerCreditRequestAPI.data && getDealerCreditRequestAPI.data.map((item: any, index: any) => {
              if(index == props.selectedBrand){
                return <MenuItem key={item} value={item.make} >{getNewBrand(item.make)}</MenuItem>
              }else{
                return null
              }})}
            </TextField>
          </div>
          <div className="col-sm-6 col-md-6 col-sm-6 pr-2 mb-2 mt-0 row-space-between salestoCustomerKam">
            <TextField
              value={existingCreditAmountCurrencyType}
              disabled id="existingCreditAmountCurrencyType"
              // defaultValue={existingCreditAmountCurrencyType}
              // error={existingCreditTypeError}
              // helperText={existingCreditTypeError && existingCreditAmountCurrencyType && <span style={{ color: 'red' }}>{existingCreditTypeValidationMessage}</span>}
              className="col-sm-2 commonRoundedInputs-dropdown" margin="dense" variant="outlined" style={{ width: '74px' }}>
              <MenuItem value="AED">AED</MenuItem>
            </TextField>
            <TextField id="existingCreditAmount" type="number"
              // error={existingCreditError || invalidExistingCredit}
              // helperText={invalidExistingCredit &&
              //   <span style={{ color: 'red' }}> {kamAdditionalCreditRequestValidation.EXISTING_CREDIT}</span>
              // }
              disabled value={existingCreditAmount}
              onChange={(e: any) => handleInputChange(e, setExistingCreditAmount)}
              inputProps={{ maxLength: 250 }}
              label="Existing Credit"
              size="small" className="col-sm-10 commonRoundedInputs-textfield"
              style={{ maxHeight: '36px' }} variant="outlined" margin="dense" required />
          </div>
        </Toolbar>
        <Toolbar className="mb-2">
          <div className="col-sm-12 col-md-6 col-sm-6 pr-2 mb-3 mt-0 row-space-between salestoCustomerKam">
            <TextField
              value={additionalCreditAmountCurrencyType}
              disabled id="additionalCreditAmountCurrencyType"
              // defaultValue={additionalCreditAmountCurrencyType}
              // error={additionCreditTypeError}
              // helperText={additionCreditTypeError && additionalCreditAmountCurrencyType && <span style={{ color: 'red' }}>{additionCreditTypeValidationMessage}</span>}
              className="col-sm-2 commonRoundedInputs-dropdown" margin="dense" variant="outlined" style={{ width: '74px' }}>
              <MenuItem value="AED">AED</MenuItem>
            </TextField>
            <TextField
              error={additionalCreditError || invalidAdditionalCredit}
              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              inputProps={{ maxLength: 250 }}
              helperText={invalidAdditionalCredit &&
                <span style={{ color: 'red' }}> {kamAdditionalCreditRequestValidation.ADDITIONAL_CREDIT}</span>
              }
              onChange={(e: any) => handleInputChange(e, setAdditionalCreditAmount, setAdditionalCreditError)}
              value={additionalCreditAmount} id="additionalCreditAmount" type="number"
              size="small" className="col-sm-10 commonRoundedInputs-textfield" style={{ maxHeight: '36px' }} label="Additional Credit" variant="outlined" margin="dense" required />
          </div>
          <div className="col-md-4 col-lg-4 pr-3 mb-2">
            <h6 className="document-content px-1">Document Upload</h6>
            <span className="dealer-table-content mr-2 px-1 mb-1"> Bank guarantee or security </span>
            <span className="document-sub-content-additional mt-2 px-1">Supported: (.jpg, .jpeg, .png, .pdf, .doc, .docx, upto 2 MB.)</span>
          </div>
          <div className="col-md-2 col-lg-2 pr-2 mb-2 pl-0 mt-0">
            {creditRequestBankGuaranteeFile !== null ?
              <span className="file-name">
                <span className="cursor-pointer" onClick={() => {
                  window.open(creditRequestBankGuaranteeFile.url)
                }}>{creditRequestBankGuaranteeFile.name ? creditRequestBankGuaranteeFile.name : creditRequestBankGuaranteeFile.selectedFileName}</span>
                <IconButton className="p-1 mt-0" aria-label="delete" onClick={(e) => {
                  // setCreditRequestBankGuaranteeFile(null); deleteDocument('documentUpload', creditRequestBankGuaranteeFile);
                  setDocumentType('creditRequestBankGuarantee');
                  handleopenClose(true);
                }}>
                  <CancelIcon className="file-close-color" fontSize="small" />
                </IconButton>
              </span>
              : <Button variant="contained"
                component={selectBrand ? "label" : "button"}
                color="secondary"
                disabled={!selectBrand}
                className="rounded-button-dealer pl-4 pr-4 mt-0 mb-3">
                Browse File
                <input type="file" hidden onChange={(e) =>
                  (e.target && e.target.files) ?
                    onClickBrowseFile('creditRequestBankGuaranteeFile', e.target.files[0], setCreditRequestBankGuaranteeFile) : setCreditRequestBankGuaranteeFile(null)
                } />
              </Button>
            }
          </div>
        </Toolbar>
        <Toolbar className="mb-2">
          <div className="col-12">
            <TextareaAutosize
              value={justificationMsg} id="justificationMsg"
              aria-label="minimum height" minRows={3} placeholder="Justification for Credit*" className="col-lg-12 col-md-12 col-sm-12 pr-3 textArea-additionalCreditRequest commonRoundedInputs common-input-font-size p-2 pl-0"
              onChange={(e: any) => handleInputChange(e, setJustificationForCreditText, setJustificationForCreditTextError)}
            />
          </div>
        </Toolbar>
        <div className="row col-12 px-5">
          {justificationForCreditTextError &&
            <span className="col-12" style={{ color: 'red' }}>Please Enter Justification for Credit.</span>
          }
          {invalidJustificationForCreditText &&
            <span className="col-12" style={{ color: 'red' }}>Only 250 characters are allowed.</span>
          }
        </div>
        <DialogActions className="mt-3 mb-3 justify-content-end">
          <Button variant="contained" color="secondary" className={`createFavourite ${classes.createButton}`}
            onClick={() => {
              validationAdditionalCreditRequest();
            }} >Request
          </Button>
          <Button variant="contained" className={classes.closeButton}
            onClick={() => { setOpenDialog(); resetData(); }} >Cancel</Button>
          <DeleteDialog
            open={openDeleteDialog}
            text="Are you sure you want to delete this document ?"
            handleopenClose={(data: any) => {
              if (data === 'delete') deleteDocument();
              if (!data) handleopenClose(false);
            }} />
        </DialogActions>
      </div>
    </Fragment>
  );
}