
import { FAVORITES } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { favoritesService } from "../../services/favorites.service";

export const favoritesActions = {
  getFavoritesList,
  getFavoriteDetails,
  createFavorites
};

export function getFavoritesList(createdByME: any, searchText: any, page?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(FAVORITES.GET_FAVORITES_LIST_FETCHING));
    const response = await favoritesService.getFavoritesList(createdByME, searchText, page);
    if (response.error) {
      dispatch(apiError(FAVORITES.GET_FAVORITES_LIST_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(FAVORITES.GET_FAVORITES_LIST_SUCCESS, response));
      return response || [];
    }
  }
}

export function getFavoriteDetails(favorite: any) {
  return async (dispatch: any) => {
    dispatch(apiSuccess(FAVORITES.GET_FAVORITES_DETAILS_SUCCESS, favorite));
    return favorite || {};
  };
}

export function createFavorites(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(FAVORITES.CREATE_FAVORITES_FETCHING));
    const response = await favoritesService.createFavorites(data);
    if (response.error) {
      dispatch(apiError(FAVORITES.CREATE_FAVORITES_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(FAVORITES.CREATE_FAVORITES_SUCCESS, response));
      return response || [];
    }
  }
}

export function updateFavorites(id: any, data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(FAVORITES.UPDATE_FAVORITES_FETCHING));
    const response = await favoritesService.editFavorites(id, data);
    if (response.error) {
      dispatch(apiError(FAVORITES.UPDATE_FAVORITES_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(FAVORITES.UPDATE_FAVORITES_SUCCESS, response));
      return response || [];
    }
  };
}

export function deleteFavorites(id: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(FAVORITES.DELETE_FAVORITES_FETCHING));
    const response = await favoritesService.deleteFavorites(id);
    if (response.error) {
      dispatch(apiError(FAVORITES.DELETE_FAVORITES_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(FAVORITES.DELETE_FAVORITES_SUCCESS, response));
      return response || [];
    }

  };
}
