import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles(() => ({
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  yes: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#1A9FE0',
    textTransform: 'capitalize'
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  deleteText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  }
}));

export default function UpdateDialog(props: any) {
  const classes = useStyles();
  return (
    <div className="float-left">
      <Dialog
        open={props.open ? true : false}
        onClose={() => { props.handleopenCloseUpdate(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogContent>
          <div className={classes.icon}>
            <WarningIcon className="delete-warning" fontSize="small" />
          </div>
          <div>
            <DialogContentText id="alert-dialog-description">
              <div className={`mt-3 ${classes.deleteText}`}>
                {props.text}
              </div>
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={`mx-4 ${classes.yes}`} onClick={() => {
            if (props.text === "Do you wish to proceed without saving the data?") {
              props.handleopenCloseUpdate('back')
            }
            else {
              if (props && props.type && props.type === "trade") {
                props.handleopenCloseUpdate('tradeRef')
              }
              else {
                props.handleopenCloseUpdate('update')
              }
            }
          }} autoFocus>{!props.updateApproval ? 'Proceed' : 'Yes'}</Button>
          <Button className={`mr-4 ${classes.cancel}`} onClick={() => { props.handleopenCloseUpdate(false) }} >
            {!props.updateApproval ? 'Cancel' : 'No'}
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}