import React, { Fragment, useEffect, Dispatch } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';
import {
  getShipToPartyAddress, AddShipToPartyAddress, deleteShipToPartyAddress,
  getSelectedShipToPartyAddress, UpdatedShipToPartyAddress, getTradeRegion, resetValues,
  getTransportationZone, saveZone, changeCustomerType
} from '../../../store/actions/onboard.actions';
import TextField from '@material-ui/core/TextField';
import { MenuItem, Button } from '@material-ui/core';
import { get } from 'lodash';
import FullscreenLoader from './../../../common/components/FullscreenLoader';
import Snackbar from '../../../common/components/CreatedEnquirySnackbar';
import Alert from '@material-ui/lab/Alert';
import ActionButtons from './ActionButtons';
import './ShipToParty.css';
import { AddNewAddressDailog } from '../../newUserOnboard/AddNewAddressDailog';
import DeleteDialog from '../../../common/components/deleteDialog';
import { Prompt, useHistory } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export const ShipToParty = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const [shippingAddress, setShippingAddress] = React.useState<any>(null);
  const { selectedNewDealer, generateB2bId }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(generateB2bId, "data.createdByB2bUserId", "");
  // const sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
  const [selected, setSelected] = React.useState<string>("");
  const [sapId, setSapId] = React.useState<string>();
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [apiResponseText, setApiResponseText] = React.useState('');
  const [tempSetCustomer, TempSetCustomer] = React.useState<any>(["New", "Existing"]);
  const [loading, setLoading] = React.useState<any>(true);
  const { shipToPartyAddressData, getSelectedAddress, regionData, updateAddressData, deleteAddressData, saveNewAdress, zoneData, dealerDetails }: any = useSelector((state: any) => state.onboard);
  const [editedAddress, setEditAddress] = React.useState({});
  const [addressList, setAddressList] = React.useState<any>([]);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const { getIndustryTypeAPI }: any = useSelector((state: any) => state.onboard);
  const { customerDetails }: any = useSelector((state: any) => state.onboard);
  const sapCustomerId = dealerDetails && dealerDetails.data && isExistingUser
    ? dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId : customerDetails && customerDetails.data && customerDetails.data.customerInformation.customerCode;
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ON CHANGE SELECT DEALER TYPE 
  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  // ON CHANGE OF DEALER TYPE 
  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }

  // ON CLICK OF NEXT BUTTON - SAVE DATA AND VALIDATIONS CHECK
  const handleNextClick = async () => {
    // saveDocumentDetails(true);
    props.setTabValue(3);
  }

  // ON CLCIK OF BACK BUTTON FUNCTIONALITY
  const handleBackClick = () => {
    props.setTabValue(1)
  }

  const deleteCustomerData = async () => {
    let data: any = await dispatch(changeCustomerType({ b2bUserId: b2bUserId }));
    if (data && data.message && data.message.status === 'S') {
      history.push('/dealers');
    } else {
      setSuccessMessage(data.error);
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
    }
  }

  // TO DISPATCH - NEW ADDRESS API & UPDATE API 
  const getNewAddressData = async (data: any) => {
    if (isEdited) {
      let result = await dispatch(UpdatedShipToPartyAddress(b2bUserId, data, selected))
      const apiResponse = get(result, 'message', {});
      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
    }
    else {
      let result = await dispatch(AddShipToPartyAddress(b2bUserId, data));
      const apiResponse = get(result, 'message', {});
      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
      setSelected("");
    }
  }

  // REGION API INTEGRATION
  const getRegionName = (regionCode: any) => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      var regionName = regionData.data[0].regions.filter((obj: any) => obj.Code == regionCode)
      return regionName && regionName.length > 0 ? regionName[0].Region : '-';
    }
    return "";
  }

  // ON CLICK OF DELETE ADDRESS
  const onClickDeleteAddress = async (shippingAddress: any) => {
    handleopenClose(true);
    setIsEdited(false);
    setSelected("");
    setShippingAddress(shippingAddress);
  }

  // ON CLICK OF DELETE - API INTEGRATION
  const deleteShippingAddress = async (action: any) => {
    handleopenClose(false);
    var userType = {
      "userType": "kam"
    }
    let result = await dispatch(deleteShipToPartyAddress(b2bUserId, shippingAddress.id, userType));
    const apiResponse = get(result, 'message', {});
    if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
      setOpenSnackbar(true);
      setApiResponseText(apiResponse.message);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
    }
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEdited(false);
    setEditAddress([]);
  };

  // TO DISPATCH API - ON CLICK OF EDIT
  const onClickEditAddress = async (addressId: any) => {
    setIsEdited(true);
    await dispatch(getSelectedShipToPartyAddress(b2bUserId, addressId));
    setSelected(addressId);
    setOpen(true);
  }

  // ZONE TRANSPORTATION  CODE API INTEGRATION
  function getZoneCode(zoneName: any) {
    let zoneCode: any = '';
    if (zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone
      && zoneData.data[0].transportationZone.length > 0)
      zoneData.data[0].transportationZone.map((zone: any) => {
        if (zone.title === zoneName) {
          zoneCode = zone.code
        }
      });
    return zoneCode;
  }

  // ON CHANGE OF TRANSPORTATION ZONE FUNCTIONALITY
  const onChangeZone = (e: any, data: any, index: any) => {
    props.setDataUpdated(true);
    let address = [...addressList]
    if (e.target.value && e.target.value) {
      if (address[index].type == "New") {
        address[index].transportationZone = e.target.value;
        address[index].transportationZoneCode = getZoneCode(e.target.value);
      }
      else {
        address[index].shiftToPartyCustomerNumber = e.target.value;
      }
      setAddressList(address);
    }
    else if (!e.target.value) {
      address[index].shiftToPartyCustomerNumber = "";
      setAddressList(address);
    }
  }

  // ON CHANGE OF NEW TRANSPORTATION ZONE
  const onChangeNew = (e: any, data: any, index: any) => {
    // props.setDataUpdated(true);
    if (e.target.value && e.target.value) {
      let address = [...addressList]
      address[index].type = e.target.value;
      if (e.target.value == "New") {
        address[index].isExisting = false;
        address[index].transportationZone = "";
        address[index].transportationZoneCode = getZoneCode(e.target.value);
      }
      else {
        address[index].isExisting = true;
        address[index].shiftToPartyCustomerNumber = "";
      }
      // address[index].transportationZoneCode = getZoneCode(e.target.value);
      setAddressList(address);
    }
  }

  // TO SAVE ADDRESS ON CLICK OF SAVE & NEXT
  const saveZoneAddress = async () => {
    setIsValid(true);
    let isValidTemp = true
    let address = [...addressList];
    let data: any = {
      addresses: []
    };
    address.map((add: any) => {
      if (add.transportationZone && add.transportationZoneCode && !add.isExisting) {
        data.addresses.push({
          id: add.id,
          transportationZone: add.transportationZone,
          transportationZoneCode: add.transportationZoneCode,
          isExisting: add.isExisting,
        })
      }
      else if (add.isExisting && add.shiftToPartyCustomerNumber) {
        data.addresses.push({
          id: add.id,
          isExisting: add.isExisting,
          shiftToPartyCustomerNumber: add.shiftToPartyCustomerNumber
        })
      } else {
        isValidTemp = false;
        setIsValid(false);
      }
    });
    if (isValidTemp && address.length === data.addresses.length) {
      let savedZone: any = await dispatch(saveZone(b2bUserId, data));
      if (savedZone && savedZone.message && savedZone.message.status === 'S') {
        props.setDataUpdated(false);
        setApiResponseText(savedZone.message.message);
        handleopenCloseSnackbar(true);
      } else {
        setApiResponseText(savedZone.error);
        handleopenCloseErrorSnackbar(true);
      }
    } else {
      setApiResponseText('Please fill all mandatory fields');
      handleopenCloseErrorSnackbar(true);
    }
  }

  // ON CLICK OF SAVE NEW ADDRESS
  useEffect(() => {
    if (saveNewAdress && saveNewAdress.data && saveNewAdress.data.message) {
      handleopenClose(false);
      setSuccessMessage(saveNewAdress.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [saveNewAdress]);

  // ON CLICK OF UPDATE ADDRESS
  useEffect(() => {
    if (updateAddressData && updateAddressData.data && updateAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(updateAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [updateAddressData]);

  // ON CLICK OF DELETE ADDRESS
  useEffect(() => {
    if (deleteAddressData && deleteAddressData.data && deleteAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deleteAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [deleteAddressData]);

  useEffect(() => {
    loadshipDetails();
  }, []);

  // TO GET THE - SHIP TO PARTY ADDRESS & TRANSPORTATION ZONE
  const loadshipDetails = async () => {
    setLoading(true);
    let response: any = await dispatch(getShipToPartyAddress(b2bUserId));
    let response1: any = await dispatch(getTransportationZone(b2bUserId));
    if (response || response1) {
      setLoading(false);
    }
    setSuccessMessage(null)
    handleopenCloseSnackbar(false);
    dispatch(resetValues());
  }

  useEffect(() => {
    if (shipToPartyAddressData && shipToPartyAddressData.data) {
      setAddressList(setaddressData(shipToPartyAddressData.data.addresses));
      setSapId(shipToPartyAddressData.data.sapCustomerId);
    }
    else if (shipToPartyAddressData && shipToPartyAddressData.err) {
    }
    if (getSelectedAddress && getSelectedAddress.data) {
      setEditAddress(getSelectedAddress ? getSelectedAddress.data : {})
    }
    else {
      setSelected("");
    }
  }, [shipToPartyAddressData, getSelectedAddress])

  // TO SET NEW & EXISITING ADDRESS DATA
  function setaddressData(data: any) {
    data.forEach((element: any) => {
      element["type"] = "";
      if (dealerDetails && dealerDetails.data && dealerDetails.data.isNewCustomer) {
        element.type = "New";
        element.isExisting = false;
      } else {
        element.type = "Existing";
        element.isExisting = true;
      }
    });
    return data;
  }

  return (
    <Fragment>
      {loading && <FullscreenLoader />}
      {shipToPartyAddressData && shipToPartyAddressData.loading && <FullscreenLoader />}
      {getSelectedAddress && getSelectedAddress.loading && <FullscreenLoader />}
      {updateAddressData && updateAddressData.loading && <FullscreenLoader />}
      {deleteAddressData && deleteAddressData.loading && <FullscreenLoader />}
      {saveNewAdress && saveNewAdress.loading && <FullscreenLoader />}
      <div className="row">
        {addressList && addressList.length > 0 && addressList.map((shipppingAddress: any, index: any) => (
          <>
            <div className="col-12 m-0 pl-3">
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="card-body border-bottom pt-1" >
                    <div className="w-50 d-inline-block">
                      <div className="shipAddTitle">
                        {shipppingAddress.dafault ? shipppingAddress.companyName : shipppingAddress.location} &nbsp;
                        {shipppingAddress.dafault && (
                          <Button
                            className="defaultAddressButton cursor-default"
                            size="small"
                            variant="contained"
                            color="secondary"
                          >Head Office</Button>
                        )}
                      </div>
                      <div className="shipAdd mt-2">
                        {shipppingAddress.streetName ? shipppingAddress.streetName + " - " : ""}
                        {shipppingAddress.city ? shipppingAddress.city + " - " : ""}
                        {shipppingAddress.country}
                      </div>
                      <div className="shipAdd">Telephone Office: 531423444
                        {shipppingAddress.telephoneNumber}
                      </div>
                      <div className="shipAdd">PO Box: {shipppingAddress.poBox}</div>
                      <div className="shipAdd">Emirates/Region:
                        {shipppingAddress.region} {getRegionName(shipppingAddress.region)}
                      </div>
                    </div>

                    {shipppingAddress.dafault && (
                      <div className="markDefaultIcon w-50 d-inline-block text-right">
                        <Button
                          className="text-info"
                          startIcon={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0 ? <EditOutlinedIcon /> : <AddIcon />}
                          onClick={() => {
                            onClickEditAddress(shipppingAddress.id)
                          }}><span className="onshiftoparty-font">
                            {shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0 ? 'Edit' : 'Add Authorized Person to Accept the Deliveries'}
                          </span>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
        <div className="col-12 display-flex p-0">
          <div className="col-8 p-0">
            <div className="row m-0">
              {/* <div className="col-5 p-0">
                <p className="mt-2 ml-4 shipAdd">Dealer Type</p>
                {dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' ? (
                  <p className="ml-4 mt-2 text-capitalize">{sessionStorage.getItem('customerType')} User</p>
                ) : (
                  <p className="cursor-pointer ml-4 mt-2" onClick={() => { changeDealerType() }}>
                    <span className="document-content text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                      Dealer</span><KeyboardArrowDownIcon />{'  '}<span className="info-sub-header-document">Change Type</span>
                  </p>
                )}
              </div> */}
              {/* <div className="col-4 mt-3 p-0">
                <Button variant="contained" className="border-left rounded-pill ml-5 w-150p mb-2"
                // onClick={() => saveDocumentDetails(false)}
                >Save Form</Button>
              </div> */}
            </div>
          </div>
          <div className="col-4 onboard-footer float-right">
            <div className="p-3 mr-3">
              <Button variant="contained" className="rounded-pill pl-0 pr-0 w-150p"
                onClick={() => handleBackClick()}
              >Back</Button>
            </div>
            <div className="p-3 mr-3">
              <Button variant="contained" color="primary"
                onClick={() => { handleNextClick(); }}
                className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2" >{props.showSave ? 'Next' : 'Next'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <AddNewAddressDailog setOpenDialog={handleClose} getNewAddressData={getNewAddressData} editedAddress={editedAddress} isEdited={isEdited} regionData={regionData} sapId={sapId} addDealer={true} />
      </Dialog>
      <DeleteDialog
        open={openDeleteDialog}
        text="Are you sure you want to delete this shipping address ?"
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteShippingAddress(data);
          if (!data) handleopenClose(false);
        }} />
      {apiResponseText && <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={apiResponseText} />}
      {apiResponseText && <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={apiResponseText} />}
      <DeleteDialog
        open={confirmDialog}
        text="Are you sure you want to switch from the current Dealer type. By doing so, all your saved data will be lost."
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteCustomerData();
          if (!data) {
            handleopenCloseConfirmPopup(false);
          }
        }} />
      {/* <ActionButtons
        isSaveDisabled={!props.dataUpdated}
        iscorrectionDisabled={true}
        showBackButton={true}
        saveCallback={() => {
          saveZoneAddress();
        }}
        correctionCallback={() => { }}
      /> */}
    </Fragment>
  )
}
export default ShipToParty;
