import { Route, Redirect, RouteProps } from "react-router";
import React from "react";
import { useSelector } from "react-redux";
import { IStateType } from "../../store/models/root.interface";
import { IAccount } from "../../store/models/account.interface";
import AdminLoginAzure from "../../components/Login/AdminLoginAzure";


export function AdminAccountRoute({ children, ...rest }: RouteProps): JSX.Element {

    const account: IAccount = useSelector((state: IStateType) => state.account);
    return (
        <Route
            {...rest}
            render={() =>
                account.userData ? (
                    <Redirect
                        to={{
                            pathname: "/"
                        }}
                    />
                ) : <AdminLoginAzure />
            }
        />
    );
}