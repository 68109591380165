import React, { Fragment, useEffect, useState, Dispatch } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, MenuItem, IconButton } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Visibility from "@material-ui/icons/Visibility";
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DeleteDialog from '../../common/components/deleteDialog';
import DeleteDialogOnboarding from '../../common/components/deleteDialogOnboarding';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddNewAuthorisedSignatoriesDailog from './addNewAuthorisedSignatories';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { getAddNewAuthorisedSignatories, saveAuthorisedSignatories, updateAuthorisedSignatories, deleteAuthorisedSignatories, updateDocument, deleteDocument, getDealerDetails, getAllSignatories, resetValues, changeCustomerType, saveDocument, getRegion } from '../../store/actions/editDealer.actions';
import DeleteIcon from "@material-ui/icons/Delete";
import FullscreenLoader from '../../common/components/FullscreenLoader';
import SubmitDialog from '../newUserOnboard/submitYourRequestDialog';
import { useHistory } from 'react-router-dom';
import DeleteDocumentDialog from '../../common/components/deleteDocumentDialog';
import ActionButtons from './ActionButtons';
import { updateDealer } from '../../store/actions/dealer.action';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 10,
  },
  button: {
    borderRadius: "1.25rem",
    minWidth: 170,
    margin: "0 0 0 .5%",
    backgroundColor: "#1A9FE0",
    color: "white",
    height: "2.25rem",
    fontSize: "0.7rem",
  },
  header: {
    color: '#666666',
    fontWeight: 900,
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    textAlign: 'left',
    minWidth: 90,
    maxWidth: 120
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
  },
  bodyContentActionHeader: {
    color: "#133F8A",
    fontWeight: 900,
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    textAlign: 'left',
    maxWidth: 60
  },
  oppacityLess: {
    opacity: 0.5,

  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

//TABLE HEADER CODE
const headCells = [
  { id: 'firstName', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
  { id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile' },
  { id: 'designation', numeric: true, disablePadding: false, label: 'Designation' },
  { id: 'emiratesIdNo', numeric: true, disablePadding: false, label: 'Emirates ID No' },
  { id: 'authorisedFor', numeric: true, disablePadding: false, label: 'Authorized for' },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Action' },
];
export const Documents = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const classes = useStyles();
  const [isAdmin, setIsAdmin] = React.useState(true);
  const [isView, setIsView] = React.useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = React.useState(false);
  const [tradeLicense, setTradeLicense] = React.useState<any>(null);
  const [isValidtradeLicenseExpiryDate, setIsValidtradeLicenseExpiryDate] = React.useState<any>(true);
  const [memoRandumExpiryDate, setMemoRandumExpiryDate] = React.useState<any>(null);
  const [isValidMemoRandumExpiryDate, setIsValidMemoRandumExpiryDate] = React.useState<any>(true);
  const [memoRandumRegFile, setMemoRandumRegFile] = React.useState<any>(null);
  const [vatRegExpiryDate, setVatRegExpiryDate] = React.useState<any>(null);
  const [isValidVatRegExpiryDate, setIsValidVatRegExpiryDate] = React.useState<any>(true);
  const [vatRegFile, setVatRegFile] = React.useState<any>(null);
  const [membershipExpiryDate, setMembershipExpiryDate] = React.useState<any>(null);
  const [companySealFile, setCompanySealFile] = React.useState<any>(null);
  const [passportFile, setPassportFile] = React.useState<any>(null);
  const [passportExpiryDate, setPassportExpiryDate] = React.useState<any>(null);
  const [isValidPassportExpiryDate, setIsValidPassportExpiryDate] = React.useState<any>(true);
  const [visaExpiryDate, setVisaExpiryDate] = React.useState<any>(null);
  const [isValidVisaExpiryDate, setIsValidVisaExpiryDate] = React.useState<any>(true);
  const [visaFile, setVisaFile] = React.useState<any>(null);
  const [tradeLicenseNoError, setTradeLicenseNoError] = React.useState<boolean>(false);
  const [invalidTradeLicenseNo, setInvalidTradeLicenseNo] = React.useState<boolean>(false);
  const [selectRegionError, setSelectRegionError] = React.useState<boolean>(false);
  const [invalidSelectRegion, setInvalidSelectRegion] = React.useState<string>("");

  //ADD NEW AUTHORISED SIGANTORIES CODE
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [selectedAddNewData, setSelectedAddNewData] = React.useState<any>("");
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openDeleteDialogTrade, setOpenDeleteDialogTrade] = React.useState(false);
  const [openDeleteDialogDocument, setOpenDeleteDialogDocument] = React.useState(false);
  const [dealerstatus, setStatus] = React.useState<any>("");

  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [typeofDailog, setTypeOfDailog] = React.useState<string>("");
  const [editData, setEditData] = React.useState();
  const [selected, setSelected] = React.useState<any>([]);
  const [inCompleteFileds, setInCompleteFileds] = React.useState<any>(false);
  const [addNewAuthorisedSignatories, setAddNewAuthorisedSignatories]: any = React.useState([])
  const [currentEmiratesIndex, setCurrentEmiratesIndex] = React.useState<any>();
  const [tradeReference, setTradeReference] = React.useState<any>([
    {
      tradeLicenseNo: "",
      selectRegion: "",
      tradeLicenseExpiryDate: null,
      tradeLicense: null,
    }
  ]);
  const history = useHistory();
  const [documentType, setDocumentType] = React.useState<any>(null);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [selectedType, setSelectedType] = React.useState<any>();
  const [dealerDetails, setDealerDetails] = React.useState<any>();
  const [dataFetched, setDataFetched] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<any>(false);
  const [selectedTradeReference, setSelectedTradeReference] = React.useState<any>([]);
  const [memorandamCopy, setMemorandamCopy] = React.useState({ documentId: '', expiryDate: '' });
  const [vatCertificateCopy, setVatCertificateCopy] = React.useState({ documentId: '', expiryDate: '' });
  const [companySealCopy, setCompanySealCopy] = React.useState({ documentId: '' });
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const { regionData, saveSignatoriesData, editSignatoriesData,
    deleteSignatoriesData, getAllSignatoriesData }: any = useSelector((state: any) => state.onboard);
  const { selectedNewDealer, isEditDealer }: any = useSelector((state: any) => state.dealer);
  const b2bUserId: any = selectedNewDealer && selectedNewDealer.createdByB2bUserId;
  const sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;

  //FUNCTION CODE FOR ADD NEW AUTHORIZED SIGNATORIES DIALOG
  const handleClose = () => {
    setOpen(false);
  };
  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleopenCloseSubmit = (open?: any) => {
    if (isValidDocumentTab()) {
      setOpenSubmitDialog(open ? true : false);
    }
  };

  const handleopenCloseTrade = (open?: any) => {
    setOpenDeleteDialogTrade(open ? true : false);
    setSuccessMessage(null);
  };

  const handleopenCloseDocument = (open?: any) => {
    setOpenDeleteDialogDocument(open ? true : false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const populateDocumentDetails = (data: any) => {
    if (data && data.data && data.data.treadLicense && data.data.treadLicense.length > 0 && !dataFetched) {
      let tread: any = [];
      data.data.treadLicense.map((tl: any) => {
        tread.push({
          selectRegion: tl.regionCode,
          tradeLicenseNo: tl.tradeLicenseNo,
          tradeLicenseExpiryDate: tl.expiryDate,
          tradeLicense: tl.documentId && tl.documentId.url ? tl.documentId : '',
          id: tl.id
        })
      })
      if (data.data.treadLicense.length === 0) {
        tread.push({
          tradeLicenseNo: "",
          selectRegion: "",
          tradeLicenseExpiryDate: null,
          tradeLicense: null,
        })
      }
      setTradeReference(tread);
      setSelectedTradeReference(tread);
      handleopenClose(false);
    }
    if (data && data.data && data.data.status) {
      setStatus(data.data.status)
    }
    if (data && data.data && data.data.memorandumOfAssociation && data.data.memorandumOfAssociation.documentId && !dataFetched) {
      const { expiryDate, id } = data.data.memorandumOfAssociation.documentId;
      setMemoRandumExpiryDate(expiryDate);
      setMemorandamCopy({ documentId: id, expiryDate: expiryDate })
      setMemoRandumRegFile(data.data.memorandumOfAssociation.documentId)
    }
    if (data && data.data && data.data.vatCertificateCopy && data.data.vatCertificateCopy.documentId && !dataFetched) {
      const { expiryDate, id } = data.data.vatCertificateCopy.documentId;
      setVatCertificateCopy({ documentId: id, expiryDate: expiryDate })
      setVatRegExpiryDate(expiryDate)
      setVatRegFile(data.data.vatCertificateCopy.documentId);
    }
    if (data && data.data && data.data.companySeal && data && data.data.companySeal.documentId && !dataFetched) {
      const { id } = data && data.data.companySeal.documentId;
      setCompanySealCopy({ documentId: id });
      setCompanySealFile(data && data.data.companySeal.documentId)
    }
    if (!dataFetched) setDataFetched(true);
    handleopenCloseSnackbar(false);
    handleopenCloseDocument(false);
  }

  useEffect(() => {
    loadSignatories();
  }, []);

  const loadSignatories = async () => {
    if (selectedNewDealer) {
      dispatch(getRegion());
      setLoading(true);
      let data: any = await dispatch(getDealerDetails(sapCustomerId, 'documents'));
      if (data && data.data && !dataFetched) {
        setDealerDetails(data);
        populateDocumentDetails(data);
      }
      dispatch(getAllSignatories(sapCustomerId));
      setLoading(false);
    }
  }

  useEffect(() => {
    if (saveSignatoriesData && saveSignatoriesData.data && saveSignatoriesData.data.message) {
      setSuccessMessage(saveSignatoriesData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getAllSignatories(sapCustomerId));
      props.setDataUpdated(true);
    }
  }, [saveSignatoriesData, editSignatoriesData])

  useEffect(() => {
    if (editSignatoriesData && editSignatoriesData.data && editSignatoriesData.data.message) {
      setSuccessMessage(editSignatoriesData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getAllSignatories(sapCustomerId));
      props.setDataUpdated(true);
    }
  }, [editSignatoriesData]);

  useEffect(() => {
    if (deleteSignatoriesData && deleteSignatoriesData.data && deleteSignatoriesData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deleteSignatoriesData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getAllSignatories(sapCustomerId));
      props.setDataUpdated(true);
    }
  }, [deleteSignatoriesData]);

  //BROWSE FILE CODE 
  const onClickBrowseFile = async (type: any, item: any, setCallback: any, index?: any) => {
    props.setDataUpdated(true);
    showErrorMessage(null);
    setSuccessMessage(null);
    handleopenCloseSnackbar(false);
    handleopenCloseErrorSnackbar(false);
    setCurrentEmiratesIndex(index);
    const name = item && item.name ? item.name : '',
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
      doctExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
    if (!canUploadFile) {
      showErrorMessage('Invalid File type format');
      handleopenCloseErrorSnackbar(true);
      return;
    }

    if (item && item.size < 2000000) {
      const canNotUploadWithoutDate = type === 'memorandumOfAssociation' || type === 'vatCertificateFile' || type === 'tradeLicenes';
      const formData: any = new FormData();
      let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;
      let expiryDate: any = '';

      if (setCallback) {
        if (type == "tradeLicenes") {
          let tradeReferenceRow = [...tradeReference];
          tradeReferenceRow[index].tradeLicense = item;
          setCallback(tradeReferenceRow);
          // return;
        }
      }
      if (type !== "tradeLicenes") setCallback(item);
      if (canNotUploadWithoutDate) {
        if (type === 'memorandumOfAssociation') expiryDate = moment(memoRandumExpiryDate).utc().format();
        else if (type === 'vatCertificateFile') expiryDate = moment(vatRegExpiryDate).utc().format();
        else if (type === 'tradeLicenes') {
          let trade = [...tradeReference];
          expiryDate = moment(trade[index].tradeLicenseExpiryDate).utc().format()
          setCallback(trade);
        };
      }
      keyAttribute = `${keyAttribute}&expiryDate=${expiryDate}`;
      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };
      let docType = '';
      if (type === 'memorandumOfAssociation') { docType = 'Memorandum File' }
      else if (type === 'vatCertificateFile') { docType = 'Vat Certificate File' }
      else if (type === 'tradeLicenes') { docType = 'Trade License File' }
      else if (type === 'companySealFile') { docType = 'Company Seal File' }

      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", sapCustomerId ? sapCustomerId : '');
      formData.append("docType", docType);
      formData.append("label", type);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'dealerUser');
      formData.append("uploadedBywebApplicationLoginId", '');
      formData.append("selectedFileName", item.name);
      let uploadedData: any = await dispatch(updateDocument(formData, customHeaders));
      if (uploadedData && uploadedData.data && uploadedData.message && uploadedData.message.status === 'S') {
        setSuccessMessage(uploadedData.message.message);
        handleopenCloseSnackbar(true);
        const { label, expiryDate, url, id, sapCustomerId } = uploadedData.data;
        if (label === 'memorandumOfAssociation') {
          setMemoRandumExpiryDate(expiryDate);
          setMemorandamCopy({ documentId: id, expiryDate: expiryDate });
          setMemoRandumRegFile(uploadedData.data);
        }
        else if (label === 'vatCertificateFile') {
          setVatCertificateCopy({ documentId: id, expiryDate: expiryDate });
          setVatRegExpiryDate(expiryDate);
          setVatRegFile(uploadedData.data);
        }
        else if (label === 'tradeLicenes') {
          let tradeReferenceRow: any = [...tradeReference];
          tradeReferenceRow[index].tradeLicense = uploadedData.data;
          tradeReferenceRow[index].tradeLicenseExpiryDate = expiryDate;
          setTradeReference(tradeReferenceRow);
          setSelectedTradeReference(tradeReferenceRow);
        } else if (label === 'companySealFile') {
          setCompanySealCopy({ documentId: id });
          setCompanySealFile(uploadedData.data)
        }
      } else {
        showErrorMessage('Document upload failed');
        handleopenCloseErrorSnackbar(true);
        if (type === 'memorandumOfAssociation') {
          setMemoRandumExpiryDate(null);
          setMemorandamCopy({ documentId: '', expiryDate: '' });
          setMemoRandumRegFile(null);
        }
        else if (type === 'vatCertificateFile') {
          setVatCertificateCopy({ documentId: '', expiryDate: '' });
          setVatRegExpiryDate(null);
          setVatRegFile(null);
        }
        else if (type === 'tradeLicenes') {
          let tradeReferenceRow: any = [...tradeReference];
          tradeReferenceRow[index].tradeLicense = null;
          tradeReferenceRow[index].tradeLicenseExpiryDate = null;
          setTradeReference(tradeReferenceRow);
          setSelectedTradeReference(tradeReferenceRow);
        } else if (type === 'companySealFile') {
          setCompanySealCopy({ documentId: '' });
          setCompanySealFile(null)
        }
      }
    } else {
      showErrorMessage('Please upload less than 2MB file');
      handleopenCloseErrorSnackbar(true);
    }
  }

  //CODE FOR DATE PICKER
  function datePickerValid(e: any, state: any, index?: any) {
    if (state === 'trade') {
      let trade = [...tradeReference]
      if (e === "Invalid Date Format") {
        trade[index].dateError = "Invalide Date Format"
        trade[index].invalidDate = true
      } else {
        trade[index].dateError = '';
        trade[index].invalidDate = false
      }
    } else {
      if (e === "Invalid Date Format") {
        state(false);
        // setSubmitDisabled(true);
      } else if (e === "Date should not be before minimal date") {
        state(false);
      } else if (e === "Date should not be after maximal date") {
        state(false);
      } else {
        state(true);
      }
    }
  }

  function isRegionExists(name: any, index: any) {
    let array: any = [];
    let trade = [...tradeReference];
    trade.map((t: any, i: any) => {
      array.push(t.selectRegion)
    })
    if (array.includes(name)) return true;

  }

  // VALIDATION ERROR MESSAGE CODE FOR ADD MORE TEXTFILEDS
  const handleInputChange = (e: any, type: any, index: number, errorSetterName?: any, errorErrorMessage?: any) => {
    props.setDataUpdated(true);
    let trade = [...tradeReference]
    switch (type) {
      case "selectRegion":
        trade[index].selectRegion = e.target.value
        break;
      case "tradeLicenseNo":
        trade[index].tradeLicenseNo = e.target.value;
        // validateTradeLicense(e.target.value, index);
        break;
      case "tradeLicenseExpiryDate":
        let date = new Date(e)
        if (date > new Date()) {
          trade[index].tradeLicenseExpiryDate = e;
          trade[index].dateError = '';
          trade[index].invalidDate = false;
        } else {
          trade[index].dateError = "Invalid Date Format"
          trade[index].invalidDate = true
        }
        break;
      case "tradeLicense":
        break;
      default: ;
    }
    setTradeReference(trade);
    setSelectedTradeReference(trade);

    // HANDLE ONCHANGE CODE
    if (errorSetterName && e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
      }

      else {
        errorSetterName(false);
      }
    }
  }

  const isValidDocuments = () => {
    let isValid = true;
    if (vatCertificateCopy.documentId === '') {
      isValid = false;
    }
    if (companySealCopy.documentId === '') {
      isValid = false;
    }
    let trade = [...selectedTradeReference];
    trade.map((tradeItemMap: any) => {
      tradeItemMap.error = null;
      if (!tradeItemMap.tradeLicenseNo || tradeItemMap.isError || !tradeItemMap.selectRegion || !tradeItemMap.tradeLicenseExpiryDate || !tradeItemMap.tradeLicense) {
        isValid = false;
      }
    });

    return isValid;
  }
  // API INTEGARTION CODE
  function isValidDocumentTab() {
    showErrorMessage(null);
    handleopenCloseErrorSnackbar(false);
    if (isValidDocuments()) {
      if (getAllSignatoriesData && getAllSignatoriesData.data && getAllSignatoriesData.data.length === 0) {
        // setOpenErrorSnackbar(true);
        setInCompleteFileds(true);
        handleopenCloseErrorSnackbar(true);
        showErrorMessage('Please add atleast one Authorized Signatories');
      } else {
        setInCompleteFileds(false);
        return true;
      }
    } else {
      setInCompleteFileds(true);
      showErrorMessage("Please fill all mandatory fields and upload all mandatory documents");
      handleopenCloseErrorSnackbar(true);

    }
  }

  function randomString() {
    let chars = '0123456789abcdefghijklmnopqrstuvwxyz-';
    let length = 35;
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  async function saveDocumentDetails(isSubmitted?: any) {
    if (isValidDocumentTab()) {
      dispatch(resetValues());
      // setSuccessMessage(null);
      handleopenCloseSnackbar(false);
      let selectedTrade: any[] = [];
      let trade = [...selectedTradeReference];
      trade.map((t: any) => {
        selectedTrade.push({
          "regionCode": t.selectRegion,
          "tradeLicenseNo": t.tradeLicenseNo,
          "expiryDate": moment(t.tradeLicenseExpiryDate).utc().format(),
          "documentId": t.tradeLicense && t.tradeLicense.id ? t.tradeLicense.id : '',
          id: t.id ? t.id : randomString()
        })
      })
      const customerInfo = dealerDetails && dealerDetails.data;
      let data = {
        "b2bUserId": b2bUserId,
        "sapCustomerId": sapCustomerId,
        "treadLicense": selectedTrade,
        "companySeal": companySealCopy,
        "memorandumOfAssociation": memorandamCopy,
        "vatCertificateCopy": vatCertificateCopy,
        "isSubmited": false,
      }
      setLoading(true);
      const savedData: any = await dispatch(updateDealer(sapCustomerId, data));
      if (savedData && savedData.message && savedData.message.status === 'S') {
        setLoading(false);
        props.setDataUpdated(false);
        props.setselectedTab(2);
        // props.setSuccessMessage(savedData.message.message)
        props.setSuccessMessage("Customer details updated successfully")
        props.handleopenCloseSnackbar(true);
      } else {
        setLoading(false);
        setOpenErrorSnackbar(true);
        // handleopenCloseSnackbar(true);
        showErrorMessage(savedData.error)
        // setSuccessMessage(savedData.message.message);
      }
    }
  }

  // ADD NEW ROW ONCLICK OF ADD MORE
  const addTradeRow = () => {
    let trade = [...tradeReference];
    props.setDataUpdated(true);
    let allow = true;
    trade.map((tradeItemMap: any) => {
      tradeItemMap.error = null;
      if (!tradeItemMap.tradeLicenseNo || !tradeItemMap.selectRegion || !tradeItemMap.tradeLicenseExpiryDate || !tradeItemMap.tradeLicense) {
        allow = false;
      }
    });
    if (allow) {
      const data = {
        tradeLicenseNo: "",
        selectRegion: "",
        tradeLicenseExpiryDate: null,
        tradeLicense: ""
      }
      trade.push(data);
      setTradeReference(trade);
      setSelectedTradeReference(trade);
      setInCompleteFileds(false);
    } else {
      setInCompleteFileds(true);
    }
    return allow;
  }

  // ALLOW TO ADD NEW ROW ONCLICK OF ADD MORE
  const allowNewRow = () => {
    let allow = true;
    return allow;
  }

  // DELETE ROW ONCLICK OF ADD MORE DELETE ICON
  const deleteTradeRow = () => {
    let trade = [...tradeReference];
    props.setDataUpdated(true);
    trade = trade.filter((element, index1) => {
      return index1 != currentEmiratesIndex;
    })
    setTradeReference(trade);
    setSelectedTradeReference(trade);
    handleopenCloseTrade(false);
  }

  const getAuthorisedFor = (data: any) => {
    if (data.authorizedForCheque && data.authorizedForPurchaseOffice) {
      return 'Cheques , Purchase Orders';
    } else if (data.authorizedForCheque) {
      return 'Cheques'
    } else if (data.authorizedForPurchaseOffice) {
      return 'Purchase Orders'
    } else {
      return '-'
    }
  }

  // ADD NEW USER AUTHORISED SIGANTORIES CODE
  const getNewAuthorisedSignatories = (data: any) => {
    addNewAuthorisedSignatories.push(data);
    dispatch(saveAuthorisedSignatories(data));
    setAddNewAuthorisedSignatories(addNewAuthorisedSignatories);
  }

  // UPDATE USER AUTHORISED SIGANTORIES CODE
  const updateNewAuthorisedSignatories = (data: any) => {
    dispatch(updateAuthorisedSignatories(data));
  }

  // DELETE USER AUTHORISED SIGANTORIES CODE
  const deleteSignatory = (addNewData: any) => {
    handleopenClose(true);
    setDeleteMessage('Are you sure you want to delete this Authorized Signatories ?');
    setSelectedAddNewData(addNewData);
  }

  const handleSubmit = () => {
    handleopenClose(true);
    setDeleteMessage('Are you sure you want to delete this Trade License Details ?');
  }

  const deleteAuthorised = () => {
    const data = {
      "id": selectedAddNewData && selectedAddNewData.sapCustomerId ? selectedAddNewData.sapCustomerId : '',
      "signatorieId": selectedAddNewData.id
    }

    dispatch(deleteAuthorisedSignatories(data))
  }

  const getRegionName = (regionCode: any) => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      var regionName = regionData.data[0].regions.filter((obj: any) => obj.Code == regionCode)
      if (regionName && regionName.length > 0) return regionName[0].Region;
    }
    return "";
  }

  const deleteDealerDocument = async () => {
    let data: any;
    props.setDataUpdated(true);
    setSelectedType(documentType);
    if (documentType == 'companySeal') {
      data = companySealFile;
    } else if (documentType == 'memorandumOfAssociation') {
      data = memoRandumRegFile;
    } else if (documentType == 'vatCertificateCopy') {
      data = vatRegFile;
    } else if (documentType == 'treadLicense') {
      let trade = [...tradeReference]
      data = trade[currentEmiratesIndex].tradeLicense;
    }
    const obj = {
      "type": documentType
    }
    let response: any = await dispatch(deleteDocument(sapCustomerId, data.id, obj));
    if (response && response.message && response.message.status === 'S') {
      if (documentType === 'companySeal') {
        setCompanySealCopy({ documentId: '' });
        setCompanySealFile(null);
      }
      if (documentType === 'memorandumOfAssociation') {
        setMemoRandumRegFile(null); setMemoRandumExpiryDate(null);
        setMemorandamCopy({ documentId: '', expiryDate: '' });
      }
      if (documentType === 'vatCertificateCopy') {
        setVatRegFile(null); setVatRegExpiryDate(null);
        setVatCertificateCopy({ documentId: '', expiryDate: '' });
      }
      if (documentType === 'treadLicense') {
        let trade = [...tradeReference]
        trade[currentEmiratesIndex].tradeLicense = null;
        trade[currentEmiratesIndex].tradeLicenseExpiryDate = null;
      }
      handleopenCloseDocument(false);
    } else {
      showErrorMessage(response.error);
      setOpenErrorSnackbar(true);
    }
  }

  //TABLE CODE
  function EnhancedTableHead(props: any) {
    const classes = useStyles();
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <>
              {/* {!isEditDealer && headCell.label === 'Action' ? '' : ( */}
              <TableCell
                className={classes.bodyContent}
                key={headCell.id}
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                {headCell.label}
              </TableCell>
              {/* )} */}
            </>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  return (
    <Fragment>
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      {loading && (<FullscreenLoader />)}
      {isEditDealer && (
        <div className="row m-0 p-0 border-bottom mt-3">
          <div className="col-12 mt-3 mb-3">
            <h6 className="info-sub-header-document">Supported File Extension : .jpg, .jpeg, .png, .pdf, .doc, .docx, upto 2 MB can be uploaded.</h6>
          </div>
        </div>
      )}
      {isEditDealer ? (
        <div className="row  m-0 p-0 border-bottom">
          <div className="col-md-2 col-lg-2 mb-2 mt-3">
            <h6 className="document-content">Trade License{' '}*</h6>
            {isEditDealer && (
              <>
                <IconButton className="p-0 m-1 toast-header"
                  edge="end" aria-label="add"
                  //  disabled
                  disabled={dealerstatus == "Active" ? false : true}
                >
                  <AddCircleIcon fontSize="medium" onClick={() => {
                    addTradeRow();
                  }} />
                </IconButton>
                <span className="info-sub-header-document mt-2">Add More</span>
              </>
            )}
          </div>
          <div className="col-md-10 col-lg-10 mb-2 pl-0 mt-3 trade onboarding-field">
            {tradeReference && tradeReference.length > 0 && tradeReference.map((tradeItem: any, index: any) => {
              return <div className="col-md-12 col-lg-12 mb-2 pl-0 mt-0">
                <TextField select id="selectRegion"
                  value={tradeItem.selectRegion}
                  disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                  error={!tradeItem.selectRegion && inCompleteFileds}
                  // disabled
                  helperText={invalidSelectRegion}
                  onChange={(e: any) => handleInputChange(e, "selectRegion", index, setSelectRegionError, setInvalidSelectRegion)}
                  size="small" className="col-sm-3 my-2 pr-2 commonRoundedInputs mt-2"
                  label="Select Region" required variant="outlined" margin="dense">
                  {regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((item: any) => (
                    <MenuItem key={item.Code} value={item.Code}>{item.Region} </MenuItem>))}
                </TextField>
                <TextField id="tradeLicenseNo"
                  type="text"
                  // disabled
                  value={tradeItem.tradeLicenseNo}
                  disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                  error={!tradeItem.tradeLicenseNo && inCompleteFileds}
                  // onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  helperText={tradeItem.errormessage && <span style={{ color: 'red' }}>{tradeItem.errormessage}</span>}
                  inputProps={{ maxLenght: 30 }}
                  onChange={(e: any) => handleInputChange(e, "tradeLicenseNo", index)}
                  size="small" className="col-sm-3 my-2 pr-2 commonRoundedInputs mt-2"
                  label="Trade License No" required variant="outlined" margin="dense" />
                <KeyboardDatePicker
                  views={["year", "month", "date"]}
                  openTo="year"
                  disablePast
                  disabled={dealerstatus == "Active" ? false : true}
                  // disabled
                  onChange={(date: Date | null) => { handleInputChange(date, "tradeLicenseExpiryDate", index); }}
                  onError={(e, v) => datePickerValid(e, 'trade', index)}
                  // disabled={tradeLicenseExpiryDate && tradeLicense ? true : false}
                  autoOk size="small"
                  disableToolbar
                  error={!tradeItem.tradeLicenseExpiryDate && inCompleteFileds || tradeItem.invalidDate}
                  variant="inline" inputVariant="outlined"
                  format="dd/MM/yyyy" margin="normal" label="Expiry Date *"
                  value={tradeItem.tradeLicenseExpiryDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={'col-sm-2 my-2 pr-3 rounded-input-dealer cal-icon'}
                  helperText={tradeItem.invalidDate && <span style={{ color: 'red' }}>{tradeItem.dateError}</span>}
                />
                {tradeItem.tradeLicense ?
                  <div className="file-name ml-2">
                    <span className="cursor-pointer" onClick={() => {
                      window.open(tradeItem.tradeLicense.url)
                    }}>{tradeItem.tradeLicense.name ? tradeItem.tradeLicense.name : tradeItem.tradeLicense.selectedFileName}</span>
                    <IconButton className="p-1 mt-2 "
                      // disabled
                      disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                      aria-label="delete"
                      onClick={(e: any) => {
                        setDocumentType('treadLicense');
                        setCurrentEmiratesIndex(index);
                        handleopenCloseDocument(true);
                      }}>
                      <CancelIcon className="file-close-color" fontSize="small" />
                    </IconButton>
                  </div>
                  : <Button
                    variant="contained"
                    // component="label" 
                    color="secondary"
                    className={`col-md-3 col-lg-3 rounded-button-dealer mt-2 mr-4 px-4 ml-2 ${!tradeItem.tradeLicenseExpiryDate ? classes.oppacityLess : ""}`}
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                  >
                    Upload Trade license
                    <input type="file" hidden
                      onChange={(e) =>
                        (e.target && e.target.files) ?
                          onClickBrowseFile('tradeLicenes', e.target.files[0], setTradeReference, index) : setTradeLicense(null)
                      }
                    />
                  </Button>
                }
                {tradeReference && tradeReference.length === 1 ? '' : (
                  <IconButton
                    // disabled 
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                    className="col-md-0 mt-2 float-right " size="small"
                    onClick={() => {
                      setCurrentEmiratesIndex(index);
                      handleopenCloseTrade(true)
                    }}>
                    <DeleteIcon
                      fontSize="inherit" />
                    <span className="font-size-13 load-from-fav ml-1 mt-0">
                    </span>
                  </IconButton>
                )}
                {tradeItem.error ? (<div className="text-red">{tradeItem.error}</div>) : ''}
              </div>
            })}
          </div>
        </div>
      ) : (
        <div className="col-12 m-0 p-0">
          <div className="row mb-0 mt-0 px-2">
            <div className="col-12 mt-1 mb-1">
              <div className="d-flex align-items-center mt-2 mb-2">
                <h5 className="document-content pt-3 pb-2 px-1">Trade Licenses</h5>
              </div>
              {dealerDetails && dealerDetails.data && dealerDetails.data.treadLicense && dealerDetails.data.treadLicense.length > 0 && (
                <div className="row m-0 col-12 mt-3 border-bottom">
                  <div className="col-3 col-md-2 col-sm-2 mb-1 mt-3">
                    <div className="info-sub-title-value font-size-12 pr-4">Region</div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-2">
                    <div className="info-sub-title-value font-size-12 mt-3 mb-1">Trade License Number</div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-2">
                    <div className="info-sub-title-value font-size-12 mt-3 mb-1">Expiry Date</div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-2">
                    <div className="info-sub-title-value font-size-12 mt-3 mb-1">Supported Document</div>
                  </div>
                  <div className="col-2 col-md-3 col-sm-2">
                  </div>
                </div>
              )}
              <div className="col-12 m-0 p-0">
                {dealerDetails && dealerDetails.data && dealerDetails.data.treadLicense && dealerDetails.data.treadLicense.length > 0 && dealerDetails.data.treadLicense.map((tradeItem: any, index: any) => (
                  <div className="row m-0 col-12 mb-0 border-bottom">
                    <div className="col-3 col-md-2 col-sm-2 mt-2 mb-0">
                      <div className="subTitle mt-2">{getRegionName(tradeItem.regionCode)}</div>
                    </div>
                    <div className="col-3 col-md-3 col-sm-2 mt-2 mb-0">
                      <div className="subTitle mt-2 mb-3">{tradeItem.tradeLicenseNo}</div>
                    </div>
                    <div className="col-3 col-md-3 col-sm-2 mt-2 mb-0">
                      <div className="subTitle mt-2 mb-3">{moment(tradeItem.expiryDate).format('DD MMM YYYY')}</div>
                    </div>
                    {tradeItem.documentId && tradeItem.documentId.url && (
                      <div className="col-3 col-md-3 col-sm-2 mt-2 mb-0">
                        <div className="subTitl mt-2 mb-0">
                          <span className="info-sub-header-document pr-2 mt-0 cursor-pointer" onClick={() => {
                            window.open(tradeItem.documentId.url)
                          }}>{tradeItem.documentId.selectedFileName ? tradeItem.documentId.selectedFileName : "-"}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="col-2 col-md-3 col-sm-2">
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row m-0 p-0 border-bottom">
        <div className="col-md-4 col-lg-4 pr-2 mb-2">
          <h6 className="document-content pt-3 pb-2">Company Seal{' '}*</h6>
          <small className="document-content">--</small>
        </div>
        <div className="col-md-2 col-lg-2 pr-5 mb-2 pl-0 mt-2">
          {companySealFile !== null ?
            <span className="file-name">
              <span className="cursor-pointer" onClick={() => {
                window.open(companySealFile.url)
              }}>{companySealFile.name ? companySealFile.name : companySealFile.selectedFileName}</span>
              {isEditDealer && (
                <IconButton className="p-1 " aria-label="delete"
                  // disabled
                  disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                  onClick={(e) => {
                    setDocumentType('companySeal');
                    handleopenCloseDocument(true);
                  }}>
                  <CancelIcon className="file-close-color" fontSize="small" />
                </IconButton>
              )}
            </span>
            :
            <>
              {isEditDealer && (
                <Button variant="contained"
                  color="secondary"
                  component="label"
                  disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                  className="col-md-12 rounded-button-dealer mt-2 mr-4 px-4">
                  Browse File
                  <input type="file" hidden
                    onChange={(e) =>
                      (e.target && e.target.files) ?
                        onClickBrowseFile('companySealFile', e.target.files[0], setCompanySealFile) : setCompanySealFile(null)
                    } />
                </Button>
              )}
            </>
          }
        </div>
      </div>
      <div className="row  m-0 p-0 border-bottom">
        <div className="col-md-4 col-lg-4 pr-3 mb-2 mt-3">
          <h6 className="document-content">Memorandum Of Association (MOA)</h6>
          <small className="document-sub-content pb-3">Power Of Attorney (POA)</small><br></br>
          <small className="pb-3">If you are not the Owner, it's mandatory to upload.</small>
        </div>
        <div className={isEditDealer ? 'col-md-3 col-lg-2 pr-5 mb-2 pl-0 mt-2' : 'col-md-6 col-lg-6 pr-5 mb-2 pl-0 mt-2'}>
          {memoRandumRegFile !== null ?
            <>
              <div className="file-name">
                <span className="cursor-pointer" onClick={() => {
                  window.open(memoRandumRegFile.url)
                }}>{memoRandumRegFile.name ? memoRandumRegFile.name : memoRandumRegFile.selectedFileName}</span>

                {isEditDealer && (
                  <IconButton className="p-1  " aria-label="delete"
                    // disabled
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}

                    onClick={(e) => {
                      setDocumentType('memorandumOfAssociation');
                      handleopenCloseDocument(true);
                    }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                )}
              </div>
              {!isEditDealer && (
                <div className="info-sub-title-value font-size-12 px-0">Expiry Date : {moment(memoRandumRegFile.expiryDate).format('DD MMM YYYY')} </div>
              )}
            </>
            :
            <>
              {isEditDealer && (
                <Button
                  variant="contained"
                  component={memoRandumExpiryDate && isValidMemoRandumExpiryDate ? "label" : "button"}
                  color="secondary"
                  className="col-md-12 rounded-button-dealer mt-2 mr-4 px-4"
                  disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                >Browse File
                  <input type="file" hidden
                    onChange={(e) =>
                      (e.target && e.target.files) ?
                        onClickBrowseFile('memorandumOfAssociation', e.target.files[0], setMemoRandumRegFile) : setMemoRandumRegFile(null)
                    } />
                </Button>
              )}
            </>
          }
        </div>
        {isEditDealer && (
          <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-2">
            <KeyboardDatePicker
              views={["year", "month", "date"]}
              openTo="year"
              disablePast
              onError={(e, v) => datePickerValid(e, setIsValidMemoRandumExpiryDate)}
              disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
              // disabled
              autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
              format="dd/MM/yyyy" margin="normal" label="Expiry Date" value={memoRandumExpiryDate}
              onChange={(date: Date | null) => { setMemoRandumExpiryDate(date); !date && setMemoRandumRegFile(null) }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className={'col-sm-4 my-2 pr-3 rounded-input-dealer cal-icon'}
            />
          </div>
        )}
      </div>
      <div className="row m-0 p-0 border-bottom">
        <div className="col-md-4 col-lg-4 pr-3 mb-2 mt-3">
          <h6 className="document-content">VAT Certificate Copy{' '}*</h6>
          <small className="document-sub-content pb-3">One Time With Issue Date</small>
        </div>
        <div className={isEditDealer ? 'col-md-3 col-lg-2 pr-5 mb-2 pl-0 mt-2' : 'col-md-6 col-lg-6 pr-5 mb-2 pl-0 mt-2'}>
          {vatRegFile !== null ?
            <>
              <div className="file-name">
                <span className="cursor-pointer" onClick={() => {
                  window.open(vatRegFile.url)
                }}>{vatRegFile.name ? vatRegFile.name : vatRegFile.selectedFileName}</span>
                {isEditDealer && (
                  <IconButton className="p-1  " aria-label="delete"
                    //  disabled
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                    onClick={(e) => {
                      setDocumentType('vatCertificateCopy');
                      handleopenCloseDocument(true);
                    }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                )}
              </div>
              {!isEditDealer && (
                <div className="info-sub-title-value font-size-12 px-0">Expiry Date : {moment(vatRegFile.expiryDate).format('DD MMM YYYY')} </div>
              )}
            </>
            :
            <>
              {isEditDealer && (
                <Button
                  variant="contained"
                  component={vatRegExpiryDate && isValidVatRegExpiryDate ? "label" : "button"}
                  color="secondary" className="col-md-12 rounded-button-dealer mt-2 mr-4 px-4"
                  disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                >Browse File
                  <input type="file" hidden
                    onChange={(e) =>
                      (e.target && e.target.files) ?
                        onClickBrowseFile('vatCertificateFile', e.target.files[0], setVatRegFile) : setVatRegFile(null)
                    } />
                </Button>
              )}
            </>
          }
        </div>
        {isEditDealer && (
          <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-2">
            <KeyboardDatePicker views={["year", "month", "date"]} openTo="year"
              disableFuture
              onError={(e, v) => datePickerValid(e, setIsValidVatRegExpiryDate)}
              disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
              // disabled
              autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
              format="dd/MM/yyyy" margin="normal" label="Issue Date" value={vatRegExpiryDate}
              onChange={(date: Date | null) => { setVatRegExpiryDate(date); !date && setVatRegFile(null) }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className={'col-sm-4 my-2 pr-3 rounded-input-dealer cal-icon'}
            />
          </div>
        )}
      </div>
      <div className="row m-0 p-0 mt-2 mb-2">
        <div className="col-12">
          <h6 className="dealer-table-content float-left mt-3">Authorized Signatories</h6>
          {isEditDealer && (
            <Button
              className="col-sm-3 col-md-3 col-3 float-right font-12 rounded-button-dealer mt-2"
              size="small"
              // disabled
              disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
              onClick={() => { handleClickOpen(); setIsEdit(false); 
                setIsView(false);
                setTypeOfDailog('add')}}
              variant="contained" color="secondary">
              Add Authorized Signatories
            </Button>
          )}
        </div>
      </div>
      {getAllSignatoriesData && getAllSignatoriesData.data && getAllSignatoriesData.data.length > 0 && (
        <div className="row m-0 p-0 mt-2 mb-3">
          <div className="col-12">
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                />
                <TableBody>
                  <>
                    {getAllSignatoriesData && getAllSignatoriesData.data && getAllSignatoriesData.data.map((newAuthorisedSignatories: any, index: any) => (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell component="th"
                          scope="row" padding="none" className="document-tablecell-data">
                          {newAuthorisedSignatories.firstName}{'  '}{newAuthorisedSignatories.lastName}
                        </TableCell>
                        <TableCell align="left" className="document-tablecell-data">
                          {newAuthorisedSignatories.email}
                        </TableCell>
                        <TableCell align="left" className="document-tablecell-data">
                          {newAuthorisedSignatories.mobile}
                        </TableCell>
                        <TableCell align="left" className="document-tablecell-data">
                          {newAuthorisedSignatories.designation}
                        </TableCell>
                        <TableCell align="left" className="document-tablecell-data">
                          {newAuthorisedSignatories.emiratesIdNumber}
                        </TableCell>
                        <TableCell align="left" className="document-tablecell-data">
                          {getAuthorisedFor(newAuthorisedSignatories)}
                        </TableCell>
                        <TableCell align="left">
                          <div className="row">
                            <ButtonGroup variant="text" color="primary" aria-label="text primary button group text-capitalize">
                              <Button
                                className=""
                                startIcon={<Visibility />}
                                onClick={() => {
                                  dispatch(getAddNewAuthorisedSignatories(newAuthorisedSignatories.id, sapCustomerId))
                                  handleClickOpen();
                                  setIsView(true);
                                  setIsEdit(true);
                                  setEditData(newAuthorisedSignatories);
                                  setTypeOfDailog('view')
                                }}>
                                View
                              </Button>
                            </ButtonGroup>
                            {isEditDealer && (
                              <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                <Button
                                  className=""
                                  startIcon={<EditOutlinedIcon />}
                                  // disabled
                                  disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                                  onClick={() => {
                                    dispatch(getAddNewAuthorisedSignatories(newAuthorisedSignatories.id, sapCustomerId))
                                    handleClickOpen();
                                    setIsEdit(true);
                                    setIsView(false);
                                    setEditData(newAuthorisedSignatories);
                                    setTypeOfDailog('edit')
                                  }}>
                                  Edit
                                </Button>
                                <Button
                                  disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                                  onClick={() => deleteSignatory(newAuthorisedSignatories)}
                                  className=""
                                  // disabled
                                  startIcon={<DeleteOutlineOutlinedIcon />}
                                >
                                  Delete
                                </Button>
                              </ButtonGroup>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )
      }
      <Dialog className="pb-0"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <AddNewAuthorisedSignatoriesDailog setOpenDialog={handleClose} getNewAuthorisedSignatories={getNewAuthorisedSignatories}
          isEdit={isEdit}
          editData={editData}
          updateNewAuthorisedSignatories={updateNewAuthorisedSignatories}
          isView={isView} typeofDailog={typeofDailog}
        />
      </Dialog>
      <DeleteDialog
        open={openDeleteDialog}
        text={deleteMessage}
        handleopenClose={(data: any) => {
          if (data === 'delete')
            deleteAuthorised();
          if (!data) handleopenClose(false);
        }} />

      <DeleteDialogOnboarding
        open={openDeleteDialogTrade}
        text="Are you sure you want to delete this Trade License Details ?"
        handleopenCloseTrade={(data: any) => {
          if (data === 'delete')
            deleteTradeRow();
          if (!data) handleopenCloseTrade(false);
        }} />
      <DeleteDocumentDialog
        open={openDeleteDialogDocument}
        text="Are you sure you want to delete this document ?"
        handleopenCloseDocument={(data: any) => {
          if (data === 'delete')
            deleteDealerDocument();
          if (!data) handleopenCloseDocument(false);
        }} />
      <SubmitDialog
        open={openSubmitDialog}
        handleopenClose={(data: any) => {
          if (data === 'Submit') {
            saveDocumentDetails(true);
          }
          if (!data) handleopenCloseSubmit(false);
        }} />
      {
        isEditDealer && (
          <ActionButtons
            openRequestSnackbar={true}
            deactiveShow={true}
            dealerDetails={dealerDetails}
            dealerStatus={dealerstatus}
            saveCallback={() => {
              saveDocumentDetails(false);
            }}
          />
        )
      }
    </Fragment >
  )
}

export default Documents;