import React, { Fragment, useEffect, useState, Dispatch } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { Prompt, useHistory } from 'react-router-dom';
import { DealerDetails } from './dealerDetails';
import Documents from './documents';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import DeleteDialog from "../../common/components/deleteDialog";
import { getAllBrands } from '../../store/actions/dealer.action';
import { getDealerDetails, getDealingIn, getRegion, getTypeOfTrade } from '../../store/actions/onboard.actions';
import { get } from 'lodash';
import NotificationIcon from '../../common/components/NotificationIcon';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { getTermsAndConditions } from '../../store/actions/termsAndConditions.action';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import ShipToParty from './shipToParty';

const CustomerOnboarding: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [tabValue, setTabValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [newValue, setNewValue] = React.useState<any>();
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [dealerStatus, setdealerStatus] = useState<string>('')
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const isKamOnboarded = get(profileDetails, "data.data.basicInfo.isAddedByKam", false);
  const history = useHistory();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>();
  const [confirmDialog, setConfirmDialog] = React.useState(false);

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };

  useEffect(() => {
    dispatch(getAllBrands());
    dispatch(getRegion());
    dispatch(getDealingIn());
    dispatch(getTypeOfTrade());
  }, []);

  function tabProps(index: any) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1A9FE0'
      },
      secondary: {
        main: '#FF0000',
      }
    },
  });

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    loadDealerDetails()
    dispatch(getTermsAndConditions());
  }, []);

  const loadDealerDetails = async (message?: any) => {
    // setLoading(true);
    let data: any = await dispatch(getDealerDetails(b2bUserId, tabValue === 0 ? 'dealerDetails' : 'documents'));
    setdealerStatus(data && data.data && data.data.status)
    if (data && data.data && data.data.status) setLoading(false);
    if ((data && data.data && data.data.status === 'Submitted') || (data && data.data && data.data.status === 'Confirmed from Dealer')) {
      if (tabValue === 1 && message) {
        // setSuccessMessage('Request submitted Successfully without Credit Request');
        // handleopenCloseSnackbar(true);
      }
      // history.push('/onboarding');
    }
  }

  return (
    <Fragment>
      <DeleteDialog
        open={confirmDialog && dataUpdated}
        text="Do you wish to proceed without saving the data?"
        handleopenClose={(data: any) => {
          if (data === 'delete' || data === "back") {
            setTabValue(newValue); handleopenClose(false); setDataUpdated(false);
          }
          if (!data) { handleopenClose(false); }
        }} />
      <Prompt when={dataUpdated} message={"Do you wish to proceed without saving the data?"}>
      </Prompt>
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      {loading ? <FullscreenLoader /> : (
        <Fragment>
          <>
            <div className="d-flex align-items-center pt-0 user-onboard">
              <h2 className="page-title">
                Complete Registration
              </h2>
              <div className="ml-auto">
                <NotificationIcon />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center mb-2">
                  <h6 className="dealer-table-content mb-0">Please complete your details</h6>
                  <div className="row-space-start ml-auto">
                    <h6 className="dealer-table-content mb-0 mr-2">Status</h6>
                    <Button
                      className="defaultAddressButton cursor-default"
                      size="small"
                      variant="contained"
                      color="secondary"
                    >
                      {dealerStatus ? dealerStatus : 'In-Progress'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
          <Grid container className="marginScrollableArea">
            <Grid item xs={12} sm={12}>
              <ThemeProvider theme={theme}>
                <Paper square className="bg-transparent">
                  <div className="border-bottom">
                    <Tabs
                      className="tabSelect"
                      textColor="primary"
                      indicatorColor="primary"
                      value={tabValue}
                      onChange={(e: any, data: any) => {
                        if (dealerStatus.toLowerCase() === 'submitted' || dealerStatus.toLowerCase() === 'dealer confirmation pending' || dealerStatus.toLowerCase() === 'confirmed from dealer') {
                          if (!dataUpdated) {
                            handleTabChange(e, data)
                          } else {
                            setNewValue(data);
                            handleopenClose(true);
                          }
                        }
                      }}
                      aria-label="disabled tabs example">
                      <Tab label="Dealer Details" {...tabProps(0)} />
                      <Tab label="Documents" {...tabProps(1)} />
                      {isKamOnboarded && (
                        <Tab label="Ship To Party" {...tabProps(2)} />
                      )}
                    </Tabs>

                  </div>
                  <div className="d-block w-100">
                    {tabValue == 0 && <DealerDetails setTabValue={setTabValue} loadDealerDetails={loadDealerDetails} setDataUpdated={setDataUpdated} dataUpdated={dataUpdated} setdealerStatus={setdealerStatus}/>}
                    {tabValue == 1 && <Documents setTabValue={setTabValue} loadDealerDetails={loadDealerDetails} setDataUpdated={setDataUpdated} dataUpdated={dataUpdated} setdealerStatus={setdealerStatus}/>}
                    {tabValue == 2 && isKamOnboarded && <ShipToParty setTabValue={setTabValue} loadDealerDetails={loadDealerDetails} setDataUpdated={setDataUpdated} setdealerStatus={setdealerStatus}/>}
                  </div>
                </Paper>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  )
}

export default CustomerOnboarding;
