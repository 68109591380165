import { CREDITREQUEST } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { creditRequestService } from "../../services/creditRequest.service";

export const creditRequestActions = {
    getAllCreditRequest,
    updateCreditLimit,
    createCreditLimits,
    deleteCreditLimits,
    getCreditRequestStatus,
    getCreditRequestDetails,
    rejectCreditRequest,
    approveCreditRequest
};

export function getAllCreditRequest(filterBy: any,page:any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITREQUEST.GET_ALL_CREDIT_REQUEST_FETCHING));
        try {
            const response = await creditRequestService.getCreditRequest(filterBy,page);
            dispatch(apiSuccess(CREDITREQUEST.GET_ALL_CREDIT_REQUEST_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITREQUEST.GET_ALL_CREDIT_REQUEST_ERROR, error));
            return error;
        }
    };
}

export function createCreditLimits(customerId: any, body: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITREQUEST.GET_ALL_CREDIT_REQUEST_FETCHING));
        try {
            const response = await creditRequestService.createCreditLimits(customerId, body);
            dispatch(apiSuccess(CREDITREQUEST.GET_ALL_CREDIT_REQUEST_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITREQUEST.GET_ALL_CREDIT_REQUEST_ERROR, error));
            return error;
        }
    };
}

export function updateCreditLimit(customerId: any, loginId: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITREQUEST.GET_ALL_CREDIT_REQUEST_FETCHING));
        try {
            const response = await creditRequestService.updateCreditLimits(customerId, loginId);
            dispatch(apiSuccess(CREDITREQUEST.GET_ALL_CREDIT_REQUEST_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITREQUEST.GET_ALL_CREDIT_REQUEST_ERROR, error));
            return error;
        }
    };
}

export function deleteCreditLimits(docid: any, type: any, requestId: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITREQUEST.GET_ALL_CREDIT_REQUEST_FETCHING));
        try {
            const response = await creditRequestService.deleteCreditLimits(docid, type, requestId);
            dispatch(apiSuccess(CREDITREQUEST.GET_ALL_CREDIT_REQUEST_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITREQUEST.GET_ALL_CREDIT_REQUEST_ERROR, error));
            return error;
        }
    };
}

export function getCreditRequestDetails(id: any, sapCustomerId: any, type: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITREQUEST.GET_CREDIT_REQUEST_DETAILS_FETCHING));
        try {
            const response = await creditRequestService.getCreditRequestDetails(id, sapCustomerId, type);
            dispatch(apiSuccess(CREDITREQUEST.GET_CREDIT_REQUEST_DETAILS_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITREQUEST.GET_CREDIT_REQUEST_DETAILS_ERROR, error));
            return error;
        }
    };
}

export function getCreditRequestDetailsKam(id: any, sapCustomerId: any, type: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITREQUEST.GET_CREDIT_REQUEST_DETAILS_FETCHING));
        try {
            const response = await creditRequestService.getCreditRequestDetails(id, sapCustomerId, type);
            dispatch(apiSuccess(CREDITREQUEST.GET_CREDIT_REQUEST_DETAILS_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITREQUEST.GET_CREDIT_REQUEST_DETAILS_ERROR, error));
            return error;
        }
    };
}



export function getCreditRequestStatus(docid: any, type: any, requestId: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITREQUEST.GET_CREDIT_REQUEST_STATUS_FETCHING));
        try {
            const response = await creditRequestService.getCreditRequestStatus();
            dispatch(apiSuccess(CREDITREQUEST.GET_CREDIT_REQUEST_STATUS_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITREQUEST.GET_CREDIT_REQUEST_STATUS_ERROR, error));
            return error;
        }
    };
}

export function rejectCreditRequest(data:any, id: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITREQUEST.REJECT_CREDIT_REQUEST_DETAILS_FETCHING));
        try {
            const response = await creditRequestService.rejectCreditRequest(data, id);
            dispatch(apiSuccess(CREDITREQUEST.REJECT_CREDIT_REQUEST_DETAILS_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITREQUEST.REJECT_CREDIT_REQUEST_DETAILS_ERROR, error));
            return error;
        }
    };
}

export function approveCreditRequest(data: any, id: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITREQUEST.APPROVE_CREDIT_REQUEST_DETAILS_FETCHING));
        try {
            const response = await creditRequestService.approveCreditRequest(data, id);
            dispatch(apiSuccess(CREDITREQUEST.APPROVE_CREDIT_REQUEST_DETAILS_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITREQUEST.APPROVE_CREDIT_REQUEST_DETAILS_ERROR, error));
            return error;
        }
    };
}

export function getallCreditRequestKam(filterBy: any, searchBy: any, Id: any,page:any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITREQUEST.GET_CREDIT_REQUEST_STATUS_FETCHING));
        try {
            const response = await creditRequestService.getallCreditRequestKam(filterBy, searchBy, Id,page);
            dispatch(apiSuccess(CREDITREQUEST.GET_CREDIT_REQUEST_STATUS_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITREQUEST.GET_CREDIT_REQUEST_STATUS_ERROR, error));
            return error;
        }
    };
}
