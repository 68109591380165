import React, { Fragment, Dispatch, useEffect } from "react";
import { Prompt, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Snackbar from './../../common/components/Snackbar';
import Paper from "@material-ui/core/Paper";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { Tab, Tabs } from "@material-ui/core";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Documents from "./Documents";
import ShipToParty from "./ShipToParty";
import KamReview from "./KamReview";
import AccessKam from './Access';
import DealerDetails from "./DealerDetailsKam";
import { getAllBrands } from "../../store/actions/dealer.action";
import { getDealingIn, getRegion, getTypeOfTrade } from "../../store/actions/onboard.actions";
import DeleteDialog from "../../common/components/deleteDialog";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MoveOutDialog from "./MoveOutDialog";
import { getDealerDetails, getShipToPartyAddress } from '../../store/actions/onboard.actions';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});





const Dealers: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { allDealers }: any = useSelector((state: any) => state.dealer);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [selectedTab, setselectedTab] = React.useState(0);
  const [newValue, setNewValue] = React.useState<any>();
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const history = useHistory();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [open, setOpen] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [moveOutDialog, setmoveOutDialog] = React.useState(false);
  const classes = useStyles();
  const { dealerDetails }: any = useSelector((state: any) => state.onboard);
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");

  useEffect(() => {
    dispatch(getAllBrands());
    dispatch(getRegion());
    dispatch(getDealingIn());
    dispatch(getTypeOfTrade());
    dispatch(getDealerDetails(b2bUserId, 'dealerDetails,documents,kamReview'));
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  function a11yProps(index: any,dataUpdated:any) {
    // if(dataUpdated){
    //   setmoveOutDialog(true)
    // }
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleopenCloseMoveOut = (open?: any) => {
    setmoveOutDialog(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };


  return (
    <Fragment>
      {false && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          View Dealer Details</h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-2 mb-2">
          <div className="d-flex align-items-center mb-2">
            <h6 className="dealer-table-content mb-0">Please verify dealer details</h6>
            <div className="dealer-table-content mb-0 px-2"><h6 className="page-sub-title mb-0">
              {dealerDetails && dealerDetails.data && dealerDetails.data.companyName ? dealerDetails.data.companyName :"-"}
              </h6></div>
            <div className="row-space-start ml-auto sm">
              <Button variant="contained" color="primary" onClick={(e) => {
                e.preventDefault();
                if (dataUpdated) {
                  handleopenCloseMoveOut(true)
                } else {
                  history.push('/dealers');
                }
              }} className="blueActionButton border-left rounded-pill">Back to dealer list</Button>
            </div>
          </div>
        </div>
      </div>
      <Grid container className="marginScrollableArea">
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} text="" />
        <Grid item xs={12} sm={12}>
          <ThemeProvider theme={theme}>
            <Paper square>
              <div className="border-bottom">
                <Tabs
                  // className="tabSelect"
                  className={dealerDetails && dealerDetails.data && dealerDetails.data.status === "Incomplete from KAM" ? "remove-cursor-icon" : "tabSelect" }
                  value={selectedTab}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={(event, newValue) => {
                    if (dealerDetails && dealerDetails.data && dealerDetails.data.status !== "Incomplete from KAM") {
                    if (!dataUpdated) {
                      setselectedTab(newValue);
                    } else {
                      setNewValue(newValue);
                      handleopenClose(true);
                    }
                  }
                  }}>
                  <Tab label="Dealer Details" value={0} {...a11yProps('DealerList',dataUpdated)} />
                  <Tab label="Documents" value={1} {...a11yProps('Documents.',dataUpdated)} />
                  <Tab label="Ship to Party" value={2} {...a11yProps('ShipToParty',dataUpdated)} />
                  <Tab label="KAM Review" value={3} {...a11yProps('KamReview',dataUpdated)} />
                  <Tab label="Access" value={4} {...a11yProps('Access',dataUpdated)} />
                </Tabs>
              </div>
              <div className="d-block w-100">
                {selectedTab == 0 && <DealerDetails dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
                {selectedTab == 1 && <Documents dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
                {selectedTab == 2 && <ShipToParty dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
                {selectedTab == 3 && <KamReview dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
                {selectedTab == 4 && <AccessKam dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
              </div>
            </Paper>
          </ThemeProvider>
        </Grid>
      </Grid>
      <DeleteDialog
        open={confirmDialog && dataUpdated}
        text="Do you wish to proceed without saving the data?"
        handleopenClose={(data: any) => {

          if (data === 'delete' || data==="back") {
             setselectedTab(newValue); handleopenClose(false); setDataUpdated(false); }
          if (!data) { handleopenClose(false); }
        }} />
      <MoveOutDialog
        open={moveOutDialog && dataUpdated}
        text="Do you wish to proceed without saving the data?"
        handleopenClose={(data: any) => {

          if (data) {
            history.push('/dealers');
            handleopenCloseMoveOut(false);
          }
          else if(data === 'back'){

          }
          if (!data) { handleopenCloseMoveOut(false); }
        }} />
      <Prompt when={dataUpdated} message={"Do you wish to proceed without saving the data?"}>
      </Prompt>
    </Fragment>
  );
};

export default Dealers;
