import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import { RESTRICTED_ACCESS } from '../../constants/restrictedAccessConstants';
import { getNewBrand } from '../../common/components/util';

const headCells = [
  { id: 'srNo', numeric: true, disablePadding: true, label: 'Sr. No.' },
  { id: 'partNo', numeric: true, disablePadding: false, label: 'Part No.' },
  { id: 'desc', numeric: true, disablePadding: false, label: 'Desc.' },
  { id: 'brand', numeric: true, disablePadding: false, label: 'Brand' },
  { id: 'qty', numeric: true, disablePadding: false, label: 'Quantity' },
];

function EnhancedTableHead(props: any) {
  const { classes, onSelectAllClick } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={props.checkedAll ? true : false}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
            className="m-1"
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableHeader}
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props: any) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Toolbar className={clsx(classes.root)}>
        <h4 className="page-title  mt-2">Load From Favorites</h4>
      </Toolbar>
      <Toolbar className="mr-1">
        <IconButton size="small" onClick={() => { props.setselectedFavourite(false) }}>
          <ChevronLeftIcon fontSize="inherit" className="backLinkLabel mr-2" />
          <span className="backLinkLabel mr-3">Back</span>
        </IconButton>
        <div className="offer">
          <span>|</span>
          <span className="ml-3">{props.favoritesDetailsState && props.favoritesDetailsState.data && props.favoritesDetailsState.data.name}</span>
        </div>
      </Toolbar>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    flex: '1 1 100%',
    fontSize: 25,
    fontWeight: 'bold',
    marginLeft: '0.7rem   '
  },
  tableHeader: {
    color: "#133F8A",
    fontWeight: 900,
    fontSize: 12,
    fontFamily: "Nunito",
    // padding:10,
  },
  offer: {
    paddingRight: 30,
    borderRight: '3px solid grey',
    fontWeight: 'bold',
    color: '#999999'
  }
}));

export default function ViewFavoriteInfo(props: any) {
  const classes = useStyles();
  const { favoritesDetails }: any = useSelector((state: any) => state.favorites);
  const [favoritesDetailsState, setfavoritesDetailsState] = React.useState<any>(null);
  const [checkedAll, setcheckedAll] = React.useState<any>(false);
  const [error, setError] = React.useState<any>('');

  const handleSelectAllClick = () => {
    let tempData: any = JSON.parse(JSON.stringify(favoritesDetailsState))
    if (tempData && tempData.data && tempData.data.products && Array.isArray(tempData.data.products)) {
      tempData.data.products = tempData.data.products.map((item: any) => {
        item['isSelected'] = !checkedAll
        return item
      })
      setfavoritesDetailsState({ ...tempData })
      setError('');
    }
    setcheckedAll(!checkedAll)
  };

  useEffect(() => {
    if (favoritesDetails && favoritesDetails.data && favoritesDetails.data.products && Array.isArray(favoritesDetails.data.products)) {
      setfavoritesDetailsState(favoritesDetails)
    }
  }, [favoritesDetails])

  const showCreateChangeEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);

  return (<>
    <div className={classes.root}>
      <EnhancedTableToolbar favoritesDetailsState={favoritesDetailsState} setselectedFavourite={props.setselectedFavourite} />
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size='medium'
          aria-label="enhanced table"
          className="w-100"
        >
          <EnhancedTableHead
            checkedAll={checkedAll}
            classes={classes}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {favoritesDetailsState && favoritesDetailsState.data && favoritesDetailsState.data.products && Array.isArray(favoritesDetailsState.data.products) && favoritesDetails.data.products.map((row: any, index: any) => {
              return <TableRow hover
                role="checkbox"
                tabIndex={-1}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={favoritesDetailsState.data.products[index]['isSelected'] ? true : false}
                    onChange={() => {
                      let tempData: any = JSON.parse(JSON.stringify(favoritesDetailsState))
                      tempData.data.products[index]['isSelected'] = !tempData.data.products[index]['isSelected']
                      setError('');
                      setfavoritesDetailsState({ ...tempData })
                      setcheckedAll(false)
                    }}
                    className="m-1"
                  />
                </TableCell>
                <TableCell component="th" id={''} scope="row" className="pl-2 text-light-gray"> {index + 1}</TableCell>
                <TableCell align="left" className="text-light-gray">{row.productId}</TableCell>
                <TableCell align="left" className="text-light-gray">{row.productIdDescription}</TableCell>
                <TableCell align="left" className="text-light-gray">{getNewBrand(row.make)}</TableCell>
                <TableCell align="center" className="text-light-gray">{row.quantity}</TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
        {error && (
          <div className="text-center text-danger mx-auto mt-2">{error}</div>
        )}
      </TableContainer>
    </div>
    <DialogActions className="mt-2 mb-3 justify-content-end">
      {showCreateChangeEnquiry &&
      <Button
        autoFocus
        variant="contained"
        color="secondary"
        className={`font-size-11 mr-1 actionButtomButtomsBlue`}
        onClick={() => {

          let data: any = [];
          let tempData: any = JSON.parse(JSON.stringify(favoritesDetailsState))
          if (tempData && tempData.data && tempData.data.products && Array.isArray(tempData.data.products)) {
            tempData.data.products = tempData.data.products.map((item: any) => {
              if (item['isSelected']) {
                data.push({
                  productId: item.productId,
                  quantity: item.quantity
                })
              }
              return undefined;
            })
            if (data && data.length > 0) {
              props.setselectedFavourite(false);
              props.setOpen(false);
              props.commonInsertionLogic(data)
              setError('');
            } else {
              setError('Please select atleast one part');
            }
          }
        }}
      >
        Add to Order Enquiry
      </Button>
      }
      <Button onClick={() => { props.setselectedFavourite(false); props.setOpen(false) }} className="cancel-button font-size-11 mr-3">
        Close
      </Button>
    </DialogActions>
  </>);
}
