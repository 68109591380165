import React, { Fragment, Dispatch, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { Tab, Tabs, Box } from "@material-ui/core";
import { withStyles, Theme, createStyles, createTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { getAllDefaultTargetData } from "../../store/actions/superAdmin.actions";
import { superAdminServices } from "../../services/superAdmin.service";
import { Prompt } from "react-router-dom"
import { getYears } from "../../helpers/commonmethod";
import DeleteDialog from "../../common/components/deleteDialog";

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SetTarget: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const leftMenuVisibility: any = useSelector((state: any) => state.global && state.global.sideMenuOpened);
  const [value, setValue] = React.useState(0);
  const { getDefaultTargetDetails, updateDefaultTarget }: any = useSelector((state: any) => state.superAdmin);
  const currencies = ["AED"];
  const [dataUpdated, setDataUpdated] = React.useState<any>(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [range, setRange] = React.useState<any>();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId') || '';
  const [selectYear, setSelectYear] = React.useState<any>(new Date().getFullYear());
  const [selectedTab, setselectedTab] = React.useState(0);
  const [selectedTabData, setSelectedTabData] = React.useState<any>(null);
  const [tabData, setTabData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [disableMonths, setDisableMonth] = React.useState<any>({});
  const [dataUpdatedPrompt, setDataUpdatedPrompt] = React.useState<boolean>(false);
  const years = getYears();
  const [newValue, setNewValue] = React.useState<any>();
  const [confirmDialog, setConfirmDialog] = React.useState(false);

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // SUCCESS - SNACKBAR FUNCTIONALITY
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  // ERROR - SNACKBAR FUNCTIONALITY
  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  // TO FETCH & DISPLAY THE SELECTED TAB DATA
  useEffect(() => {
    if (getDefaultTargetDetails && getDefaultTargetDetails.data && getDefaultTargetDetails.data.data && getDefaultTargetDetails.data.data.plan.length > 0) {
      setSelectedTabData(JSON.parse(JSON.stringify({ ...getDefaultTargetDetails.data.data.plan[0] })))
    }
  }, [getDefaultTargetDetails])

  // DISPATCH - GET API FOR SET TARGET
  useEffect(() => {
    dispatch(getAllDefaultTargetData(webApplicationLoginId, selectYear));
  }, [selectYear]);

  useEffect(() => {
    if (getDefaultTargetDetails?.data?.data?.categoryRange && getDefaultTargetDetails.data.data.categoryRange.length > 0 && selectedTabData) {
      let rangeValue = getDefaultTargetDetails.data.data.categoryRange.filter((range: any) => range.code === selectedTabData.code)
      setRange(rangeValue && rangeValue.length > 0 && rangeValue[0])
    }
  }, [selectedTabData]);

  function convertToInternationalCurrencySystem(labelValue: any) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"// Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6

        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"// Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3

          ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"

          : Math.abs(Number(labelValue));

  }

  // DISABLED PREVIOUS MONTHS FUNCTIONATILTY
  useEffect(() => {
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const currentMonth = new Date();
    const name = currentMonth.getMonth()
    const currentYear = new Date().getUTCFullYear();
    const filterMonth = month.filter((ele: any, i: any) => {
      return i < name
    })
    const disableMonth = { jan: true, feb: true, mar: true, apr: true, may: false, jun: false, jul: false, aug: false, oct: false, sep: false, nov: false, dec: false }
    if (filterMonth.includes(month[0])) {
      disableMonth.jan = true
    }
    if (filterMonth.includes(month[1])) {
      disableMonth.feb = true
    } if (filterMonth.includes(month[2])) {
      disableMonth.mar = true
    } if (filterMonth.includes(month[3])) {
      disableMonth.apr = true
    } if (filterMonth.includes(month[4])) {
      disableMonth.may = true
    } if (filterMonth.includes(month[5])) {
      disableMonth.jun = true
    } if (filterMonth.includes(month[6])) {
      disableMonth.jul = true
    } if (filterMonth.includes(month[7])) {
      disableMonth.aug = true
    } if (filterMonth.includes(month[8])) {
      disableMonth.sep = true
    } if (filterMonth.includes(month[9])) {
      disableMonth.oct = true
    } if (filterMonth.includes(month[10])) {
      disableMonth.nov = true
    } if (filterMonth.includes(month[11])) {
      disableMonth.dec = true
    }
    if (selectYear === currentYear) {
      setDisableMonth(disableMonth)
    }
    else if (selectYear != currentYear) {
      setDisableMonth({ jan: false, feb: false, mar: false, apr: false, may: false, jun: false, jul: false, aug: false, oct: false, sep: false, nov: false, dec: false })
    }
  }, [selectYear])

  // FOR PUT API CALL - TO UPADTE SET TARGET DATA
  const setTargetApiCall = async () => {
    let value = getDefaultTargetDetails.data.data.plan;
    value[selectedTab] = selectedTabData;
    let data = {
      "updatedBy": webApplicationLoginId,
      "plan": value,
    }
    setLoading(true);
    const responseData = await superAdminServices.updateDefaultTarget(data, webApplicationLoginId, selectYear);
    if (responseData && responseData.message && responseData.message.status == "S") {
      setLoading(false);
      setDataUpdated(false);
      setOpenErrorSnackbar(false);
      setOpenSnackbar(true);
      setSuccessMessage(responseData.message.message);
    } else {
      setLoading(false);
      // setOpenErrorSnackbar(true);
      handleopenCloseErrorSnackbar(true);
      showErrorMessage(responseData.error ? responseData.error : "Something went Wrong");
    }
  }

  // FOR QUARTER 1 - TOTAL 
  const handleInputChange = () => {
    let jan = parseInt(selectedTabData?.monthlyTaget?.Jan?.amount || 0);
    let feb = parseInt(selectedTabData?.monthlyTaget?.Feb?.amount || 0);
    let mar = parseInt(selectedTabData?.monthlyTaget?.Mar?.amount || 0);
    let Q1 = jan + feb + mar
    let initialValue = selectedTabData
    initialValue.quarterlyTaget.quarter1.amount = Q1
    setSelectedTabData({ ...initialValue });
    handleInputChangeYaerlyTarget();
  }

  // FOR QUARTER 2 TARGET- TOTAL 
  const handleInputChangeQ2 = () => {
    let apr = parseInt(selectedTabData?.monthlyTaget?.Apr?.amount || 0);
    let may = parseInt(selectedTabData?.monthlyTaget?.May?.amount || 0);
    let june = parseInt(selectedTabData?.monthlyTaget?.Jun?.amount || 0);
    let Q2 = apr + may + june
    let initialValue = selectedTabData
    initialValue.quarterlyTaget.quarter2.amount = Q2
    setSelectedTabData({ ...initialValue });
    handleInputChangeYaerlyTarget();
  }

  // FOR QUARTER 3 TARGET- TOTAL 
  const handleInputChangeQ3 = () => {
    let jul = parseInt(selectedTabData?.monthlyTaget?.Jul?.amount || 0);
    let aug = parseInt(selectedTabData?.monthlyTaget?.Aug?.amount || 0);
    let sept = parseInt(selectedTabData?.monthlyTaget?.Sept?.amount || 0);
    let Q3 = jul + aug + sept
    let initialValue = selectedTabData
    initialValue.quarterlyTaget.quarter3.amount = Q3
    setSelectedTabData({ ...initialValue });
    handleInputChangeYaerlyTarget();
  }

  // FOR QUARTER 4 TARGET - TOTAL 
  const handleInputChangeQ4 = () => {
    let oct = parseInt(selectedTabData?.monthlyTaget?.Oct?.amount || 0);
    let nov = parseInt(selectedTabData?.monthlyTaget?.Nov?.amount || 0);
    let dec = parseInt(selectedTabData?.monthlyTaget?.Dec?.amount || 0);
    let Q4 = oct + nov + dec
    let initialValue = selectedTabData
    initialValue.quarterlyTaget.quarter4.amount = Q4
    setSelectedTabData({ ...initialValue });
    handleInputChangeYaerlyTarget();
  }

  // FOR YEARLY TARGET - TOTAL 
  const handleInputChangeYaerlyTarget = () => {
    let Q1 = parseInt(selectedTabData?.quarterlyTaget?.quarter1?.amount || 0);
    let Q2 = parseInt(selectedTabData?.quarterlyTaget?.quarter2?.amount || 0);
    let Q3 = parseInt(selectedTabData?.quarterlyTaget?.quarter3?.amount || 0);
    let Q4 = parseInt(selectedTabData?.quarterlyTaget?.quarter4?.amount || 0);
    let YearlyTargetTotal = Q1 + Q2 + Q3 + Q4;
    let initialValue = selectedTabData
    initialValue.yearlyTaget.amount = YearlyTargetTotal
    setSelectedTabData({ ...initialValue });
  }

  return (
    <Fragment>
      {loading && <FullscreenLoader />}
      {getDefaultTargetDetails && getDefaultTargetDetails.loading && <FullscreenLoader />}
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      <div className="d-flex align-items-center">
        <h2 className="page-title"> Set Target </h2>
        <div className="ml-auto"> </div>
      </div>
      <div className="d-flex align-items-center"> Set default target for all categories.
        <div className="ml-auto">
          <TextField select
            id="selectYear"
            label="Select Year"
            value={selectYear}
            variant="outlined"
            className="rounded-input-dealer mw-200p mr-2 mb-2"
            size="small"
            onChange={(e: any) => {
              setselectedTab(0);
              setSelectYear(e.target.value);
              dispatch(getAllDefaultTargetData(webApplicationLoginId, e.target.value));
            }}
          >
            {years.map((data: any, index: any) => (
              <MenuItem key={index} value={data}> {data} </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <div className="row">
        <div className="mt-3 mb-3 pb-5 w-100">
          <ThemeProvider theme={theme}>
            <Paper square>
              <Tabs
                className="tabSelect border-bottom border-top-0"
                value={selectedTab}
                textColor="primary"
                indicatorColor="primary"
                onChange={(event, newValue) => {
                  if (!dataUpdated) {
                    setselectedTab(newValue);
                    if (getDefaultTargetDetails && getDefaultTargetDetails.data &&
                      getDefaultTargetDetails.data.data &&
                      getDefaultTargetDetails.data.data.plan.length > 0)
                      setSelectedTabData(JSON.parse(JSON.stringify({ ...getDefaultTargetDetails.data.data.plan[newValue] })))
                  } else {
                    setNewValue(newValue);
                    handleopenClose(true);
                  }
                }}
                aria-label="basic tabs example">
                {getDefaultTargetDetails && getDefaultTargetDetails.data &&
                  getDefaultTargetDetails.data.data && getDefaultTargetDetails.data.data.plan.length > 0 &&
                  getDefaultTargetDetails.data.data.plan.map((planName: any, item: any, index: any) => {
                    return <Tab label={planName.planName} {...a11yProps(index)} />
                  })}
              </Tabs>
              {selectedTabData && (
                <TabPanel value={selectedTab} index={selectedTab}>
                  <div className="row p-2 m-0">
                    <span className="row px-4 pt-4 text-info">Monthly spend range - AED
                      <div className="row px-3">{range && range.value.split(" - ")[0] ? convertToInternationalCurrencySystem(parseInt(range && range.value.split(" - ")[0])) : ""}
                        {range && range.value.split(" - ")[0] && range && range.value.split(" - ")[1] ? "-" : "+"} {range && range.value.split(" - ")[1] ? convertToInternationalCurrencySystem(parseInt(range && range.value.split(" - ")[1])) : ""}</div>
                    </span>
                  </div>
                  <div className="row p-2 m-0">
                    <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3 border-bottom border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12 px-0"><h6 className="setTarget-monthly-target mb-2">Monthly Target</h6></div>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={selectedTabData?.monthlyTaget?.Jan?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" 
                          variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="january"
                          type={selectedTabData?.monthlyTaget?.Jan?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.monthlyTaget?.Jan?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.monthlyTaget.Jan.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            handleInputChange();
                            setDataUpdated(true);
                            setDataUpdatedPrompt(true);
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          label="January" variant="outlined" margin="dense"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          disabled={disableMonths.jan} />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={selectedTabData?.monthlyTaget?.Feb?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField
                          id="february"
                          type={selectedTabData?.monthlyTaget?.Feb?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.monthlyTaget?.Feb?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.monthlyTaget.Feb.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            handleInputChange();
                            setDataUpdated(true);
                            setDataUpdatedPrompt(true);
                          }}
                          label="February" variant="outlined" margin="dense"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          disabled={disableMonths.feb}
                        />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-3">
                        <TextField id="currenciesValues"
                          value={selectedTabData?.monthlyTaget?.Mar?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="march"
                          type={selectedTabData?.monthlyTaget?.Mar?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.monthlyTaget?.Mar?.amount}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.monthlyTaget.Mar.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdated(true);
                            handleInputChange();
                            setDataUpdatedPrompt(true);
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="March" variant="outlined" margin="dense" disabled={disableMonths.mar} />
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12"><h6 className="setTarget-quarter-target mb-2">Quarter 1 Target</h6></div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 pr-0">
                        <TextField
                          id="quarterCurrencies"
                          value={selectedTabData?.quarterlyTaget?.quarter1?.currencyType}
                          defaultValue={currencies[0]}
                          disabled
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="quarterOne"
                          type={selectedTabData?.quarterlyTaget?.quarter1?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.quarterlyTaget?.quarter1?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          disabled={disableMonths.mar}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.quarterlyTaget.quarter1.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdated(true);
                            setDataUpdatedPrompt(true);
                            handleInputChangeYaerlyTarget();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="Quarter 1" variant="outlined" margin="dense" />
                      </div>
                    </div>
                    <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3 border-bottom border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12 px-0"><h6 className="setTarget-monthly-target mb-2">Monthly Target</h6></div>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={selectedTabData?.monthlyTaget?.Apr?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="april"
                          type={selectedTabData?.monthlyTaget?.Apr?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.monthlyTaget?.Apr?.amount}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.monthlyTaget.Apr.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ2();
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="April" variant="outlined" margin="dense" disabled={disableMonths.apr} />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={selectedTabData?.monthlyTaget?.May?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="may"
                          type={selectedTabData?.monthlyTaget?.May?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.monthlyTaget?.May?.amount}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.monthlyTaget.May.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ2();
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="May" variant="outlined" margin="dense" disabled={disableMonths.may} />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-3">
                        <TextField id="currenciesValues"
                          value={selectedTabData?.monthlyTaget?.Jun?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense" >
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="june"
                          type={selectedTabData?.monthlyTaget?.Jun?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.monthlyTaget?.Jun?.amount}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.monthlyTaget.Jun.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ2();
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="June" variant="outlined" margin="dense" disabled={disableMonths.jun} />
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12"><h6 className="setTarget-quarter-target mb-2">Quarter 2 Target</h6></div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-2 pr-0">
                        <TextField
                          id="quarterCurrencies"
                          value={selectedTabData?.quarterlyTaget?.quarter2?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="quarterTwo"
                          type={selectedTabData?.quarterlyTaget?.quarter2?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.quarterlyTaget?.quarter2?.amount}
                          disabled={disableMonths.jun}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.quarterlyTaget.quarter2.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdatedPrompt(true)
                            setDataUpdated(true)
                            handleInputChangeYaerlyTarget();
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="Quarter 2" variant="outlined" margin="dense" />
                      </div>
                    </div>
                    <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3 border-bottom border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12 px-0"><h6 className="setTarget-monthly-target mb-2">Monthly Target</h6></div>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={selectedTabData?.monthlyTaget?.Jul?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="july"
                          type={selectedTabData?.monthlyTaget?.Jul?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.monthlyTaget?.Jul?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.monthlyTaget.Jul.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ3();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="July" variant="outlined" margin="dense" disabled={disableMonths.jul} />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={selectedTabData?.monthlyTaget?.Aug?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="august"
                          type={selectedTabData?.monthlyTaget?.Aug?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.monthlyTaget?.Aug?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.monthlyTaget.Aug.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ3();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="August" variant="outlined" margin="dense" disabled={disableMonths.aug} />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-3">
                        <TextField id="currenciesValues"
                          value={selectedTabData?.monthlyTaget?.Sept?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="september"
                          type={selectedTabData?.monthlyTaget?.Sept?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.monthlyTaget?.Sept?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.monthlyTaget.Sept.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ3();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="September" variant="outlined" margin="dense" disabled={disableMonths.sep} />
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12"><h6 className="setTarget-quarter-target mb-2">Quarter 3 Target</h6></div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-2 pr-0">
                        <TextField
                          id="quarterCurrencies"
                          value={selectedTabData?.quarterlyTaget?.quarter3?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="quarterThird"
                          type={selectedTabData?.quarterlyTaget?.quarter3?.amount > 0 ? "text" : "number"}
                          disabled={disableMonths.sep}
                          value={selectedTabData?.quarterlyTaget?.quarter3?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.quarterlyTaget.quarter3.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdatedPrompt(true)
                            setDataUpdated(true)
                            handleInputChangeYaerlyTarget();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="Quarter 3" variant="outlined" margin="dense" />
                      </div>
                    </div>
                    <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3 border-bottom border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12 px-0"><h6 className="setTarget-monthly-target mb-2">Monthly Target</h6></div>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={selectedTabData?.monthlyTaget?.Oct?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="october"
                          type={selectedTabData?.monthlyTaget?.Oct?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.monthlyTaget?.Oct?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.monthlyTaget.Oct.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdated(true);
                            setDataUpdatedPrompt(true);
                            handleInputChangeQ4();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="October" variant="outlined" margin="dense" disabled={disableMonths.oct} />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                        <TextField id="currenciesValues"
                          value={selectedTabData?.monthlyTaget?.Nov?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="november"
                          type={selectedTabData?.monthlyTaget?.Nov?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.monthlyTaget?.Nov?.amount}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.monthlyTaget.Nov.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ4();
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="November" variant="outlined" margin="dense" disabled={disableMonths.nov} />
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-3">
                        <TextField id="currenciesValues"
                          value={selectedTabData?.monthlyTaget?.Dec?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="december"
                          type={selectedTabData?.monthlyTaget?.Dec?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.monthlyTaget?.Dec?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.monthlyTaget.Dec.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdated(true)
                            setDataUpdatedPrompt(true)
                            handleInputChangeQ4();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="December" variant="outlined" margin="dense" disabled={disableMonths.dec} />
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12"><h6 className="setTarget-quarter-target mb-2">Quarter 4 Target</h6></div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-2 pr-0">
                        <TextField
                          id="quarterCurrencies"
                          value={selectedTabData?.quarterlyTaget?.quarter4?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="quarterFour"
                          type={selectedTabData?.quarterlyTaget?.quarter4?.amount > 0 ? "text" : "number"}
                          disabled={disableMonths.dec}
                          value={selectedTabData?.quarterlyTaget?.quarter4?.amount}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.quarterlyTaget.quarter4.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdatedPrompt(true)
                            setDataUpdated(true)
                            handleInputChangeYaerlyTarget();
                          }}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="Quarter 4" variant="outlined" margin="dense" />
                      </div>
                    </div>
                    <div className="row col-sm-12 col-md-12 col-lg-8 mb-3 px-3">
                      <div className="col-12 d-flex justify-content-between"></div>
                      <div className="col-sm-12 col-md-4 col-lg-4 mb-2 pr-0"></div>
                      <div className="col-sm-8 col-md-8 col-lg-8 mb-2 pr-3"> </div>
                    </div>
                    <div className="col-12 col-lg-4 col-md-12 col-sm-12 mb-3 px-3 border border-bottom border-right-0 border-top-0">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="col-12"><h6 className="setTarget-yearly-target mb-2">Yearly Target</h6></div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 mb-2 pr-0">
                        <TextField
                          id="quarterCurrencies"
                          value={selectedTabData?.yearlyTaget?.currencyType}
                          disabled
                          defaultValue={currencies[0]}
                          size="small" className="col-sm-3 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                          {currencies.map(item => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>))}
                        </TextField>
                        <TextField id="yearlyTarget"
                          type={selectedTabData?.yearlyTaget?.amount > 0 ? "text" : "number"}
                          value={selectedTabData?.yearlyTaget?.amount}
                          onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = selectedTabData
                            initialValue.yearlyTaget.amount = updateValue
                            setSelectedTabData({ ...initialValue });
                            setDataUpdatedPrompt(true)
                            setDataUpdated(true)
                          }}
                          onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                          size="small" className="col-sm-9 commonRoundedInputs-textfield"
                          InputLabelProps={{
                            style: { color: '#000000' },
                          }}
                          label="Yearly" variant="outlined" margin="dense" />
                      </div>
                    </div>
                  </div>
                </TabPanel>
              )}
              <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
                <div className="col-12 d-flex mt-2 mb-2 p-0">
                  <div className="col-6 px-4">
                  </div>
                  <div className="col-6 onboard-footer">
                    <div className="">
                      <Button
                        variant="contained" color="primary"
                        className={`blueActionButtonSave border-left rounded-pill mr-5 mt-2 mb-2`}
                        onClick={() => {
                          setTargetApiCall(); setDataUpdated(false); setDataUpdatedPrompt(false);
                        }}
                      >{dataUpdated ? 'Update' : 'Save'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </ThemeProvider>
        </div>
      </div>
      <Prompt when={dataUpdatedPrompt} message={"Do you wish to proceed without saving the data?"}></Prompt>
      <DeleteDialog
        open={confirmDialog && dataUpdated}
        text="Do you wish to proceed without saving the data?"
        handleopenClose={(data: any) => {
          if (data === 'delete' || data === 'back') {
            setselectedTab(newValue);
            setSelectedTabData(JSON.parse(JSON.stringify({ ...getDefaultTargetDetails.data.data.plan[newValue] })))
            handleopenClose(false);
            setDataUpdated(false);
          }
          if (!data) { handleopenClose(false); }
        }} />
    </Fragment >
  );
};

export default SetTarget;
