import React, { forwardRef, Fragment, Dispatch, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import { Button, FormControl, IconButton, InputAdornment, MenuItem, Tooltip } from '@material-ui/core';
import { ValidationErrorMessage } from '../../constants/messages';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import { getNewBrand } from '../../common/components/util';
import NotificationIcon from '../../common/components/NotificationIcon';
import './creditRequest.css'
import CancelIcon from '@material-ui/icons/Cancel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { updateDocument } from '../../store/actions/onboard.actions';
import { useEffect } from 'react';
import { createCreditLimits, getCreditRequest, updateCreditLimit, deleteCreditLimits } from '../../store/actions/creditLimit.actions';
import _ from 'lodash';
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import Alert from '@material-ui/lab/Alert';

const CreditApplication: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [dataSubmitted, setdataSubmitted] = React.useState<any>(false);
  const [updateMode, setupdateMode] = React.useState<any>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [disableWolePage, setdisableWolePage] = React.useState<any>(false)
  const [emptyWholePage, setemptyWholePage] = React.useState<any>(false)
  const [creditLimitData, setcreditLimitData] = React.useState<any>({
    "b2bUserId": "",
    "sapCustomerId": "",
    "brandCredit": [

    ],
    "bankDetails": {
      "bankName": "",
      "accountNumber": "",
      "bankIBAN": "",
      "telephone": "",
      "contactName": "",
      "bankAddress": ""
    },
    "documents": [
      {
        "type": "bankStatement",
        "documentId": ""
      },
      {
        "type": "aecbConsentForm",
        "documentId": ""
      },
      {
        "type": "auditedFinancialStatement",
        "documentId": ""
      },
      {
        "type": "managementAccount",
        "documentId": ""
      },
      {
        "type": "vatReturnStatement",
        "documentId": ""
      },
      {
        "type": "contract",
        "documentId": ""
      },
      {
        "type": "tenancyContractCopy",
        "documentId": ""
      },
      {
        "type": "companyProfileDocument",
        "documentId": ""
      },
      {
        "type": "authorizationLatter",
        "documentId": ""
      },
      {
        "type": "rtaFleetListReport",
        "documentId": ""
      },

      {
        "type": "additionalDocuments",
        "documentId": ""
      }
    ]
  });

  const fieldLabelsAndConditions: any = {
    "bankStatement": {
      label: 'Bank Statement*',
      subLabel: 'Latest 6 months',
      required: true
    },
    "aecbConsentForm": {
      label: 'AECB Consent Form*',
      subLabel: '',
      required: true
    },
    "auditedFinancialStatement": {
      label: 'Audited Financial Statements*',
      subLabel: 'Latest 3 years',
      required: true
    },
    "managementAccount": {
      label: 'Management Account*',
      subLabel: 'Current year (Financial statement/P&L/Balance sheet)',
      required: true
    },
    "vatReturnStatement": {
      label: 'VAT Return Statement*',
      subLabel: 'Last 2 years',
      required: true
    },
    "contract": {
      label: 'Contract*',
      subLabel: 'Latest 6 months',
      required: false
    },
    "tenancyContractCopy": {
      label: 'Tenancy Contract Copy',
      subLabel: '--',
      required: true
    },
    "companyProfileDocument": {
      label: 'Company Profile Document',
      subLabel: '--',
      required: false
    },
    "authorizationLatter": {
      label: 'Authorization Letter',
      subLabel: '--',
      required: false
    },
    "rtaFleetListReport": {
      label: 'RTA Report / Fleet List Report',
      subLabel: '--',
      required: false
    },
    "additionalDocuments": {
      label: 'Additional Documents',
      subLabel: '--',
      required: false
    },
  }


  const currencies = ["AED"];
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const b2bUserId = _.get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");

  const handleInputChangeBrands = (e: any, setterName: any, index: any) => {
    if (setterName) {
      let creditLimitDataTemp = JSON.parse(JSON.stringify(creditLimitData));
      creditLimitDataTemp['brandCredit'][index][setterName] = e && e.target && e.target.value ? parseInt(e.target.value) ? e.target.value : 1 : '';
      setcreditLimitData(creditLimitDataTemp);
    }
  }

  const handleInputChangeBankDetails = (e: any, setterName: any) => {
    if (setterName) {
      let creditLimitDataTemp = JSON.parse(JSON.stringify(creditLimitData));
      creditLimitDataTemp['bankDetails'][setterName] = e && e.target && e.target.value ? e.target.value : '';
      setcreditLimitData(creditLimitDataTemp);
    }
  }

  const handleInputChangeDocuments = async (e: any, setterName: any, index?: any) => {
    let item = e && e.target && e.target.files && e.target.files[0];
    if (item) {
      const name = item && item.name ? item.name : '',
        doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
        doctExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
        canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
      let keyAttribute = `fieldname=${setterName}&docExtension=${doctExtension}`;
      if (item && item.size > 2000000) {
        setOpenSnackbar(true);
        setSuccessMessage("Please upload less than 2MB file.");
        setOpenErrorSnackbar(true);
        return;
      }
      if (!canUploadFile) {
        setOpenSnackbar(true);
        setSuccessMessage("Invalid File type format.");
        setOpenErrorSnackbar(true);
        return;
      }
      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };
      const formData: any = new FormData();
      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", sapCustomerId || creditLimitData.sapCustomerId);
      formData.append("docType", 'Bank Documents');
      formData.append("label", setterName);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'dealerUser');
      formData.append("uploadedBywebApplicationLoginId", '');
      let dataDocument: any = await dispatch(updateDocument(formData, customHeaders));
      if (dataDocument && dataDocument.data) {
        let creditLimitDataTemp = JSON.parse(JSON.stringify(creditLimitData));
        creditLimitDataTemp['documents'][index]['documentId'] = dataDocument.data;
        setcreditLimitData(creditLimitDataTemp);
      }
    } else {
      let creditLimitDataTemp = JSON.parse(JSON.stringify(creditLimitData));
      dispatch(deleteCreditLimits(creditLimitDataTemp['documents'][index]['documentId']['id'], creditLimitDataTemp['documents'][index]['type'], creditLimitData['id'] ? creditLimitData['id'] : ''));
      creditLimitDataTemp['documents'][index]['documentId'] = '';
      setcreditLimitData(creditLimitDataTemp);
    }
  }

  useEffect(() => {
    loadCreditLimits()
  }, [])

  const loadCreditLimits = async () => {
    let requestData: any = await dispatch(getCreditRequest(b2bUserId, ''))
    if (requestData && requestData.data) {
      if (requestData.data.status.toLowerCase() == 'not requested' && requestData.data['organizationIds'] && Array.isArray(requestData.data['organizationIds'])) {
        setupdateMode(false)
        let creditLimitDataTemp = JSON.parse(JSON.stringify(creditLimitData));
        requestData.data['organizationIds'].map((item: any) => {
          item['currencyType'] = "AED";
        })
        creditLimitDataTemp['brandCredit'] = requestData.data['organizationIds'];
        creditLimitDataTemp['sapCustomerId'] = requestData.data['sapCustomerId'];
        setcreditLimitData(creditLimitDataTemp);
      }
      else {
        if (requestData.data.status.toLowerCase() == 'requested') {
          setdisableWolePage(true)
        }
        setupdateMode(true)
        setcreditLimitData(requestData.data)
      }
    } else {
      setemptyWholePage(true)
    }
  }

  const submitForm = async () => {
    let creditLimitDataTemp = JSON.parse(JSON.stringify(creditLimitData));
    setdataSubmitted(true)
    creditLimitDataTemp['documents'].map((item: any) => {
      item.documentId = item.documentId && item.documentId.id ? item.documentId.id : ''
      return item
    })
    creditLimitDataTemp['b2bUserId'] = b2bUserId;
    creditLimitDataTemp['sapCustomerId'] = sapCustomerId || creditLimitDataTemp['sapCustomerId'];
    let hasValidData = true;
    creditLimitDataTemp['brandCredit'] && creditLimitDataTemp['brandCredit'].map((item: any) => {
      if (!item.creditPeriod || !item.currencyType || !item.creditRequired) {
        hasValidData = false
      }
    })
    const requiredBandField = ['bankAddress', 'bankIBAN', 'accountNumber', 'bankName', 'telephone', 'contactName']
    requiredBandField.map((item: any) => {
      if (!creditLimitDataTemp['bankDetails'] || !creditLimitDataTemp['bankDetails'][item]) {
        hasValidData = false
      }
    })
    const requiredDocument = ['bankStatement', "aecbConsentForm", 'auditedFinancialStatement', "contract", "managementAccount", "vatReturnStatement"]
    creditLimitData.documents.map((item: any) => {
      if (requiredDocument.includes(item['type']) && !item['documentId']) {
        hasValidData = false;
      }
    })
    if (hasValidData) {
      if (!updateMode) {
        let data: any = await dispatch(createCreditLimits(b2bUserId, creditLimitDataTemp))
        let creditLimitDataStore = JSON.parse(JSON.stringify(creditLimitData));
        let idStored = data.data.id;
        creditLimitDataStore['id'] = idStored;
        setcreditLimitData(creditLimitDataStore);
        setupdateMode(true);
        setOpenSnackbar(true);
        setSuccessMessage("Credit Request succesfully created");
        setOpenErrorSnackbar(false);
      } else {
        let data = await dispatch(updateCreditLimit(b2bUserId, creditLimitDataTemp))
        setSuccessMessage("Credit Request succesfully updated")
        setOpenSnackbar(true)
        setOpenErrorSnackbar(false)
      }
    } else {
      setOpenSnackbar(true)
      setSuccessMessage("Please fill all mandatory fields and upload all mandatory documents")
      setOpenErrorSnackbar(true)
    }
  }

  return (
    <Fragment>
      <div className={`d-flex align-items-center pt-0 user-onboard ${disableWolePage ? 'diabledSectionCredit' : ''}`}>
        <h2 className="page-title">
          Credit Request
        </h2>
        <div className="ml-auto">
          <NotificationIcon />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="d-flex align-items-center mb-2">
            <h5 className="page-sub-title">Credit request as per brands you have opted.</h5>
            <div className="row-space-start ml-auto">
            </div>
          </div>
        </div>
      </div>
      {!emptyWholePage ? <>
        <div className={`card mb-5 ${disableWolePage ? 'diabledSectionCredit' : ''}`}>
          {creditLimitData.brandCredit && Array.isArray(creditLimitData.brandCredit) && creditLimitData.brandCredit.map((item: any, index: any) => {
            return <div className="row m-0 p-3 border-bottom">
              <div className="col-md-1 col-lg-1">
                <div className="brandbadgecreditRequest">
                  {item.make}
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0">
                <TextField
                  // defaultValue={brands[0]}
                  value={item.currencyType}
                  error={!item.currencyType && dataSubmitted}
                  onChange={(e: any) => handleInputChangeBrands(e, "currencyType", index)}
                  select
                  disabled={true}
                  id="hondaCreditLimitSelector"
                  className="commonRoundedInputs-dropdown" margin="dense" variant="outlined">
                  {currencies.map(item => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>))}
                </TextField>
                <TextField id="hondaCreditLimit" type="number"
                  value={item.creditRequired}
                  error={!item.creditRequired && dataSubmitted}
                  // helperText={invalidHondaCreditLimit}
                  onChange={(e: any) => handleInputChangeBrands(e, "creditRequired", index)}
                  InputProps={{ inputProps: { min: 1 } }}
                  size="small" className="col-sm-9 commonRoundedInputs-textfield"
                  label="Credit Required" variant="outlined" margin="dense" />
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField id="hondaCreditPeriodDays" type="number"
                  value={item.creditPeriod}
                  error={!item.creditPeriod && dataSubmitted}
                  // helperText={invalidHondaCreditPeriodDays}
                  InputProps={{ inputProps: { min: 1 } }}
                  onChange={(e: any) => handleInputChangeBrands(e, "creditPeriod", index)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Credit Period (Days)" required variant="outlined" margin="dense" />
              </div>
            </div>
          })}
          <div className="row m-0 p-3 border-bottom">
            <div className="col-md-12 col-lg-12 mb-2">
              Bank Details
            </div>
            <div className="col-md-6 col-lg-6">
              <TextField id="hondaCreditPeriodDays"
                value={creditLimitData.bankDetails.bankName || ''}
                error={!creditLimitData.bankDetails.bankName && dataSubmitted}
                // helperText={invalidHondabankNameDays}
                onChange={(e: any) => handleInputChangeBankDetails(e, "bankName")}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                inputProps={{ maxLength: 100 }}
                label="Bank Name" required variant="outlined" margin="dense" />
            </div>
            <div className="col-md-6 col-lg-6">
              <TextField id="hondaCreditPeriodDays"
                value={creditLimitData.bankDetails.accountNumber || ''}
                error={!creditLimitData.bankDetails.accountNumber && dataSubmitted}
                // helperText={invalidHondaaccountNumberDays}
                onChange={(e: any) => handleInputChangeBankDetails(e, "accountNumber")}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                inputProps={{ maxLength: 23 }}
                label="Account No." required variant="outlined" margin="dense" />
            </div>
            <div className="col-md-6 col-lg-6">
              <TextField id="hondaCreditPeriodDays"
                value={creditLimitData.bankDetails.bankIBAN || ''}
                error={!creditLimitData.bankDetails.bankIBAN && dataSubmitted}
                // helperText={invalidHondabankIBANDays}
                onChange={(e: any) => handleInputChangeBankDetails(e, "bankIBAN")}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                inputProps={{ maxLength: 100 }}
                label="IBAN No" required variant="outlined" margin="dense" />
            </div>
            <div className="col-md-6 col-lg-6">
              <TextField id="hondaCreditPeriodDays"
                value={creditLimitData.bankDetails.telephone || ''}
                type="number"
                error={!creditLimitData.bankDetails.telephone && dataSubmitted}
                // helperText={invalidHondatelephoneDays}
                onChange={(e: any) => handleInputChangeBankDetails(e, "telephone")}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                inputProps={{ maxLength: 20 }}
                required label="Telephone" variant="outlined" margin="dense" />
            </div>
            <div className="col-md-6 col-lg-6">
              <TextField id="hondaCreditPeriodDays"
                value={creditLimitData.bankDetails.contactName || ''}
                error={!creditLimitData.bankDetails.contactName && dataSubmitted}
                // helperText={invalidHondacontactNameDays}
                onChange={(e: any) => handleInputChangeBankDetails(e, "contactName")}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                inputProps={{ maxLength: 100 }}
                required label="Contact Name" variant="outlined" margin="dense" />
            </div>
            <div className="col-md-6 col-lg-6">
              <TextField id="hondaCreditPeriodDays"
                value={creditLimitData.bankDetails.bankAddress || ''}
                error={!creditLimitData.bankDetails.bankAddress && dataSubmitted}
                // helperText={invalidHondabankAddressDays}
                onChange={(e: any) => handleInputChangeBankDetails(e, "bankAddress")}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                inputProps={{ maxLength: 250 }}
                label="Address" required variant="outlined" margin="dense" />
            </div>
          </div>
          <div className="row m-0 p-3 border-bottom">
            Required Documents
          </div>
          <div className="row m-0 border-bottom">
            {creditLimitData.documents && Array.isArray(creditLimitData.documents) && creditLimitData.documents.map((item: any, index: any) => {
              return <>
                <div className="col-lg-6 col-md-6 border-bottom">
                  <div className="row m-0">
                    <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0">
                      <h6 className="document-content pt-3 pb-2">{
                        fieldLabelsAndConditions[item.type] ? fieldLabelsAndConditions[item.type].label : item.type}</h6>
                      <small className="document-content">
                        {fieldLabelsAndConditions[item.type] ? fieldLabelsAndConditions[item.type].subLabel : '--'}
                      </small>
                    </div>
                    <div className="col-md-4 col-lg-4 pr-5 mb-2 pl-0 mt-2">
                      {item.documentId ?
                        <span className="file-name" onClick={() => {
                          window.open(item.documentId.url)
                        }}>
                          {item.documentId.fileName}
                          {!disableWolePage && <IconButton className="p-1" aria-label="delete" onClick={(e) => {
                            handleInputChangeDocuments(null, item.type, index)
                          }}>
                            <CancelIcon className="file-close-color" fontSize="small" />
                          </IconButton>}
                        </span>
                        : <Button variant="contained"
                          component="label" color="secondary"
                          className="rounded-button-dealer pl-4 pr-4 mt-2">
                          Browse File
                          <input type="file" hidden onChange={(e) => {
                            handleInputChangeDocuments(e, item.type, index)
                          }
                          } />
                        </Button>
                      }
                    </div>
                  </div>
                </div>
                {index == 5 && <div className="py-3 col-lg-12 col-md-12 border-bottom">
                  Additional Documents
                </div>
                }
              </>
            })}
          </div>
        </div>
        <div className={`row card card-body p-2 fixed-bottom ${disableWolePage ? 'diabledSectionCredit' : ''}`}>
          <div className="col-12 display-flex">
            <div className="col-2"></div>
            <div className="col-6">
              <div className="row">
                <div className="col-2">
                  <p className="mt-4 ml-5 shipAdd">Status</p>
                </div>
                <div className="col-  4 mt-3">
                  <Button variant="contained" className="border-left rounded-pill ml-4 w-150p mb-2">{!updateMode ? 'Not ' : ''}Requested</Button>
                </div>
              </div>
            </div>
            <div className="col-4 onboard-footer">
              <div className="p-3 mr-5">
                {!disableWolePage && <Button variant="contained" color="primary" className="rounded-pill ml-3 w-150p"
                  onClick={() => { submitForm() }}
                >
                  Request
                </Button>}
              </div>
            </div>
          </div>
        </div>
        <ReturnRequestSnackbar
          open={openSnackbar}
          type={openErrorSnackbar ? 'error' : ''}
          handleopenClose={() => {
            setOpenSnackbar(false)
          }} message={successMessage} />
      </> :
        <div className="col-12">
          <Alert severity="info">Please submit Complete Registration details prior to Credit Request!</Alert>
        </div>
      }
    </Fragment >
  )
}

export default CreditApplication;
