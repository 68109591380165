import { API_ENDPOINTS, DELIVERY } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const deliveryService = {
    getDeliveryList,
    getDeliveryDetails,
};

async function getDeliveryList(orderNumber: any, enquiryNumber: any, make: any, backOrderNumber: any, page?:any) {
    const apiPath = API_ENDPOINTS.DELIVERY + DELIVERY.LIST;
    const options = {
        path: `${apiPath}`,
        method: "POST",
        bodyObj: {
            "deliveryList": {
                "salesDocument": orderNumber ? orderNumber : "",
                "forwardOrderNumber": backOrderNumber ? backOrderNumber : "",
                "shippingReceivingPoint": "",
                "description": "",
                "delivery": "",
                "pickingStatus": "",
                "shippingPlanningStatus": "",
                "goodsMovementStatus": "",
                "actualGoodsMovementDate": "",
                "billingStatus": "",
                "billingDateForBillingIndexPrintout": ""
            },
            "sessionDetails": {
                "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
                "clientIPAddress": sessionStorage.getItem('clientIp'),
            },
            "enquiryNumber": enquiryNumber,
            "make": make
        },
        customHeaders: {
            // "Paging-Info": `start-index=${page ? page : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getDeliveryDetails(enquiryNumber: any, deliveryNumber: any, make: any, page?:any) {
    const apiPath = API_ENDPOINTS.DELIVERY + DELIVERY.DETAILS;
    const options = {
        path: `${apiPath}`,
        method: "POST",
        bodyObj: {
            "sessionDetails": {
                "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
                "clientIPAddress": sessionStorage.getItem('clientIp'),
            },
            "enquiryNumber": enquiryNumber,
            "delivery":deliveryNumber,
            "make": make
        },
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 10 : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}
