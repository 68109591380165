import React, { Fragment, Dispatch, useState, useEffect } from "react";
import { get, isEmpty, find } from "lodash";
import "./OrgSetting.css";
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import GetAppIcon from '@material-ui/icons/GetApp';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import AddUserDialog from './AddUserDialog'
import Snackbar from './../../common/components/Snackbar';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { getAllUsers, getDealerDetails, setUserDetails, getUploadedDocuments, getUserDetails, uploadDocument, deleteDocument } from "../../store/actions/dealer.action";
import AddDealerDialog from '../Dealers/AddDealerDialog';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import { getCreditLimit } from "../../store/actions/creditLimit.actions";
import { Grid } from "@material-ui/core";
import SearchBar from "../favorites/searchBar";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import NotificationIcon from "../../common/components/NotificationIcon";
import { userTypes } from "../../constants/userTypes";
import { getNewBrand } from "../../common/components/util";
import { getProfile } from "../../store/actions/profile.action";
import XLSX from 'xlsx';
import { getShipToPartyAddress, resetValues, getTransportationZone, getRegion, getDealingIn, getTypeOfTrade, getNationality } from '../../store/actions/onboard.actions';
import FullscreenLoader from './../../common/components/FullscreenLoader';
import DealerDetails from "./DealerDetails";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import moment from 'moment';
import Visibility from "@material-ui/icons/Visibility";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { getAddNewAuthorisedSignatories, saveAuthorisedSignatories, updateAuthorisedSignatories, deleteAuthorisedSignatories, updateDocument, getAllSignatories, changeCustomerType, saveDocument } from '../../store/actions/editDealer.actions';
import Dialog from '@material-ui/core/Dialog';
import AddNewAuthorisedSignatoriesDailog from "../ViewEditDealerDetailsKam/addNewAuthorisedSignatories";
import SellingPrice from "./SellingPrice";
import { Prompt } from "react-router-dom";
import MoveOutDialog from "../ViewEditDealerDetailsKam/MoveOutDialog";
import DeleteDialog from "../../common/components/deleteDialog";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';

import InputLabel from "@material-ui/core/InputLabel";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
  header: {
    color: '#133F8A',
    fontFamily: 'Nunito',
    fontSize: 12,
    fontWeight: 900,
    minWidth: 87,
    padding: 14
  },
  body: {
    color: '#666666',
    fontFamily: 'Nunito',
    fontSize: 13,
    fontWeight: 600,
    padding: 15
  },
  actions: {
    color: '#1A9FE0',
    fontSize: 13,
    fontFamily: 'Nunito',
    fontWeight: 700
  },
  error: {
    color: '#1A9FE0',
    fontSize: 13
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const OrgSetting: React.FC = (props: any) => {
  const [selectedUserId, setSelectedUserId] = React.useState("");
  const [sapCustomerId, setSapCustomerId] = React.useState<any>();
  const [b2bUserId, setb2bId] = React.useState<any>();
  const classes = useStyles();
  const [selectedTab, setselectedTab] = React.useState('Dealership');
  const [tabValue, setTabValue] = React.useState<any>(null);
  const dispatch: Dispatch<any> = useDispatch();
  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [openEditInfo, setOpenEditInfo] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [customerDetails, setCustomerDetails]: any = React.useState();
  const [isDialogForEdit, setIsDialogForEdit] = React.useState(false);
  const [isUserDialogForEdit, setIsUserDialogForEdit] = React.useState(false);
  const [isUserDialogForView, setIsUserDialogForView] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [openDealerSnackbar, setOpenDealerSnackbar] = React.useState(false);
  const [isValid, setIsvalid] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [customerDocuments, setCustomerDocuments] = React.useState([]);
  const [selected, setSelected] = React.useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const { shipToPartyAddressData, getSelectedAddress, regionData }: any = useSelector((state: any) => state.onboard);
  const [editedAddress, setEditAddress] = React.useState({});
  const [addressList, setAddressList] = React.useState<any>([]);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [shippingAddress, setShippingAddress] = React.useState<any>(null);
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const [sapId, setSapId] = React.useState<string>();
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [apiResponseText, setApiResponseText] = React.useState(false);
  const [selectedZone, setSelectedZone] = React.useState<any>([]);
  const [isValidAddress, setIsValidAddress] = React.useState<boolean>(true);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { creditLimitDetails }: any = useSelector((state: any) => state.creditLimitDetails);
  const { dealerDetails, updateDealer, usersList, uploadedDocuments, uploadDocumentData, removeDocumentData }: any = useSelector((state: any) => state.dealer);
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
  const customerId = sessionStorage.getItem('customerId')
  const companyBrands: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data) ? dealerDetails.data.data.organizationIds : null;
  const creditLimitData = get(creditLimitDetails, 'data.data.data', []);
  const brandIds = companyBrands && companyBrands.map((id: any) => id.organizationId);
  const [isView, setIsView] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [addNewAuthorisedSignatories, setAddNewAuthorisedSignatories]: any = React.useState([])
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [moveOutDialog, setmoveOutDialog] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleopenCloseMoveOut = (open?: any) => {
    setmoveOutDialog(open ? true : false);
  };

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };

  // ADD NEW USER AUTHORISED SIGANTORIES CODE
  const getNewAuthorisedSignatories = (data: any) => {
    addNewAuthorisedSignatories.push(data);
    dispatch(saveAuthorisedSignatories(data));
    setAddNewAuthorisedSignatories(addNewAuthorisedSignatories);
  }

  // UPDATE USER AUTHORISED SIGANTORIES CODE
  const updateNewAuthorisedSignatories = (data: any) => {
    dispatch(updateAuthorisedSignatories(data));
  }

  // const filteredBrands = creditLimitData && creditLimitData.filter((x: any, index: any) => {
  //   if (brandIds && brandIds.length > 0) {
  //     return brandIds.includes(x.brandId);
  //   }
  //   return undefined;
  // });

  const getRegionName = (regionCode: any) => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      var regionName = regionData.data[0].regions.filter((obj: any) => obj.Code == regionCode)
      return regionName && regionName.length > 0 ? regionName[0].Region : '-';
    }
    return "";
  }

  useEffect(() => {
    // dispatch(getShipToPartyAddress(b2bUserId));
    dispatch(getTransportationZone(b2bUserId));
    setSuccessMessage(null)
    handleopenCloseSnackbar(false);
    dispatch(resetValues());
    dispatch(getRegion());
    dispatch(getDealingIn());
    dispatch(getTypeOfTrade());
    dispatch(getNationality());
  }, []);

  useEffect(() => {
    if (shipToPartyAddressData && shipToPartyAddressData.data) {
      setAddressList(shipToPartyAddressData.data.addresses);
      setSapId(shipToPartyAddressData.data.sapCustomerId);
    }
    else if (shipToPartyAddressData && shipToPartyAddressData.err) {
    }
    if (getSelectedAddress && getSelectedAddress.data) {
      setEditAddress(getSelectedAddress ? getSelectedAddress.data : {})
    }
    else {
      setSelected("");
    }
  }, [shipToPartyAddressData, getSelectedAddress])

  const getAuthorisedFor = (data: any) => {
    if (data.authorizedForCheque && data.authorizedForPurchaseOffice) {
      return 'Cheques , Purchase Orders';
    } else if (data.authorizedForCheque) {
      return 'Cheques'
    } else if (data.authorizedForPurchaseOffice) {
      return 'Purchase Orders'
    } else {
      return '-'
    }
  }

  useEffect(() => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
      dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'dealerDetails'));
      setSapCustomerId(profileDetails.data.data.basicInfo.sapCustomerId);
      setb2bId(profileDetails.data.data.basicInfo.b2bUserId);
      dispatch(getCreditLimit(customerId, webApplicationLoginId));
    }
  }, [profileDetails]) // eslint-disable-line

  useEffect(() => {
    if (updateDealer && updateDealer.data && updateDealer.data.data && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo) {
      setIsvalid(false);
      dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'dealerDetails'));
    }
  }, [updateDealer]) // eslint-disable-line

  const getUsers = () => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
      setPage(0);
      dispatch(getAllUsers(profileDetails.data.data.basicInfo.sapCustomerId));
    }
  }

  useEffect(() => {
    if (uploadDocumentData && uploadDocumentData.data && uploadDocumentData.data.data && uploadDocumentData.data.data.data) {
      if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
        setIsvalid(false);
        dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'dealerDetails'));
      }
    }
  }, [uploadDocumentData]); // eslint-disable-line

  useEffect(() => {
    if (removeDocumentData && removeDocumentData.data && removeDocumentData.data.data && removeDocumentData.data.data && removeDocumentData.data.data.message && removeDocumentData.data.data.message.status === "S") {
      if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
        setIsvalid(false);
        dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'dealerDetails'));
      }
    }
  }, [removeDocumentData]); // eslint-disable-line

  const columns = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
    },
    {
      id: 'userid',
      label: 'User Name',
      align: 'left',
    },
    {
      id: 'b2cUserId',
      label: 'User Id',
      align: 'left',
    },
    {
      id: 'usertype',
      label: 'User Type',
      align: 'left'
    },
    {
      id: 'email',
      label: 'Email',
      align: 'left'
    },
    {
      id: 'mobile',
      label: 'Mobile',
      align: 'left'
    },
    {
      id: 'brands',
      label: 'Brands',
      align: 'left'
    },
    {
      id: 'status',
      label: 'Status',
      align: 'left'
    },
  ];

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setselectedTab(newValue);
  };

  const handleChangePage = (newPage: number) => {
    if (searchValue === "") {
      dispatch(getAllUsers(dealerDetails.data.data.sapCustomerId, newPage));
    }
    else {
      dispatch(getAllUsers(dealerDetails.data.data.sapCustomerId, newPage, searchValue));
    }
    setPage(newPage);
  };

  const handleopenCloseAddUser = (open?: any) => {
    setOpenAddUser(open ? true : false);
  };

  const handleopenCloseEditInfo = (open?: any) => {
    setOpenEditInfo(open ? true : false);
  }

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseDealerSnackbar = (open?: any) => {
    setOpenDealerSnackbar(open ? true : false);
  }

  const loadProfileDetails = () => {
    const b2cUid = sessionStorage.getItem('userId');
    const userType = sessionStorage.getItem('userType');
    if (b2cUid !== "undefined" && b2cUid !== null && userType !== 'undefined' && userType !== null) {
      dispatch(getProfile(b2cUid, userType));
    }
  };

  const uploadFile = async (file: any) => {
    const { name } = file,
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || name,
      doctExtensions = ['jpg', 'jpeg', 'png'],
      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;

    if (!canUploadFile) {
      return;
    }

    const sapCustomerId: any = get(dealerDetails, 'data.data.sapCustomerId', false),
      organization = get(dealerDetails, 'data.data.organizationIds[0]', {});

    const formData: any = new FormData();

    let keyAttribute = `fieldname=${name}&docExtension=${doctExtension}`;

    if (!sapCustomerId) { return; }


    const customHeaders = {
      "Accept": "application/json",

      "Lob-Id": "AUTO",
      "Channel-Id": "B2B",
      "Org-Id": organization.organizationId || "",
      "Accept-Language": "EN",
    };

    formData.append("entityName", "CUSTOMER");
    formData.append("keyAttribute", keyAttribute);
    formData.append("fileUpload", file);
    formData.append("document", 'false');
    formData.append("Attributes", `key=Brand&value=${file}`);
    formData.append("sapCustomerId", sapCustomerId);
    formData.append("uploadedByRole", 'dealerUser');
    formData.append("uploadedBywebApplicationLoginId", sessionStorage.getItem('webApplicationLoginId'));

    await dispatch(uploadDocument(formData, customHeaders));
    loadProfileDetails();
  }
  const getDetailedExposure = (creditExposure: any) => {
    const credLimit = creditExposure.split('.')[0];
    if (credLimit.includes("000")) {
      const number = credLimit.substring(0, credLimit.lastIndexOf("000"));
      return number;
    } else if (credLimit > 1000) {
      return (credLimit / 1000 | 0);
    } else return credLimit
  }

  const getCreditValue = (creditLimit: any, creditExposure: any) => {
    let value = (parseInt(creditExposure.split('.')) / parseInt(creditLimit.split('.'))) * 100;
    if (value > 100 || value < 0) value = -1;
    return value;
  }

  const showCreditStatus = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_CREDIT_STATUS);
  const showManageUser = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.MANAGER_USER);
  const showViewUser = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_USERS);
  const userType = get(profileDetails, 'data.data.basicInfo.userType', "");

  const searchDealerList = (e: any, isSearch: any) => {
    if (selectedTab) {
      if (searchValue.trim() === "") {
        dispatch(getAllUsers(dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.sapCustomerId, 0, searchValue));
      } else {
        dispatch(getAllUsers(dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.sapCustomerId, 0, searchValue));
      }
    }
  }

  //TABLE HEADER CODE
  const headCells = [
    { id: 'firstName', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
    { id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile' },
    { id: 'designation', numeric: true, disablePadding: false, label: 'Designation' },
    { id: 'emiratesIdNo', numeric: true, disablePadding: false, label: 'Emirates ID No' },
    { id: 'authorisedFor', numeric: true, disablePadding: false, label: 'Authorized for' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Action' },
  ];

  function EnhancedTableHead(props: any) {
    const classes = useStyles();
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              className={classes.bodyContent}
              key={headCell.id}
              // align={headCell.numeric ? headCell.id === 'actions' || headCell.id === 'brands' ? 'center' : 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Organization Setting
        </h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <div className="row mt-2">
        <Snackbar
          open={openDealerSnackbar}
          text="Details Updated Successfully"
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }}
        />
        <AddDealerDialog
          open={openEditInfo}
          isEdit={isDialogForEdit}
          hideAdmin
          handleopenClose={(data: any) => {
            if (data) {
              handleopenCloseDealerSnackbar(true)
            }
            dispatch(getDealerDetails(sapCustomerId, 'dealerDetails'));
            handleopenCloseEditInfo(false)
          }}
        />
        <Snackbar
          open={openSnackbar}
          text="New User Created Successfully"
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }}
        />
        {openAddUser &&
          <AddUserDialog
            open={openAddUser}
            isEdit={isUserDialogForEdit}
            setIsEdit={setIsUserDialogForEdit}
            isView={isUserDialogForView}
            setIsView={setIsUserDialogForView}
            setInProgress={setInProgress}
            setSelectedUserId={setSelectedUserId}
            inProgress={inProgress}
            sapCustomerId={sapCustomerId}
            isAdmin={false}
            selectedUserId={selectedUserId}
            handleopenClose={(data: any) => {
              if (data) {
                handleopenCloseSnackbar(true)
              }
              handleopenCloseAddUser(false)
            }}
          />
        }
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <ThemeProvider theme={theme}>
            <Paper square className="bg-transparent">
              <Tabs
                className="tabSelect"
                value={selectedTab}
                textColor="primary"
                indicatorColor="primary"
                onChange={(e: any, newValue: any) => {
                  if (newValue === 'DocumentsTab') {
                    dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'documents'));
                  } else if (newValue === 'Address') {
                    dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'shipToParty'));
                  } else if (newValue === 'Dealership') {
                    dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'dealerDetails'));
                  } else if (newValue === 'Users') {
                    getUsers();
                  }
                  if (!dataUpdated) {
                    handleTabChange(e, newValue);
                  } else {
                    handleopenClose(true);
                    setTabValue(newValue);
                    return
                  }
                }}
                aria-label="disabled tabs example">
                <Tab label="Dealer Details" value="Dealership" {...a11yProps('Dealership')} />
                <Tab label="Documents" value="DocumentsTab" {...a11yProps('DocumentsTab')} />
                <Tab label="Ship to Party" value="Address" {...a11yProps('Address')} />
                {showViewUser && <Tab label="Users" value="Users" {...a11yProps('Users')} />}
                {userType === 'Admin' && (
                  <Tab label="Selling Price" value="sellingPrice" {...a11yProps('sellingPrice')} />
                )}
              </Tabs>
            </Paper>
          </ThemeProvider>
        </div>
        <Divider light />
      </div>
      <TabPanel value={selectedTab} index="Dealership">
        <DealerDetails dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} userType={userType} />
      </TabPanel>
      <TabPanel value={selectedTab} index="DocumentsTab">
        {dealerDetails && dealerDetails.loading && (
          <div className="mx-auto my-5">
            <FullscreenLoader />
          </div>
        )}
        {/* {shipToPartyAddressData && shipToPartyAddressData.loading && <FullscreenLoader />} */}
        <div className="row mt-3">
          <div className="col-12 col-lg-12 col-md-12 col-sm-12">
            <div className="d-flex align-items-center">
              <h5 className="page-sub-title mt-3 mb-2">List of Documents</h5>
            </div>
          </div>
        </div>
        <div className="card col-12 m-0 p-0">
          <div className="row mb-0 mt-0 px-2">
            <div className="col-12 mt-1 mb-1">
              <div className="d-flex align-items-center mt-3 mb-3">
                <h5 className="info-sub-header-document px-4">Trade Licenses</h5>
                <div className="row-space-start ml-auto">
                  {/* <Button className="text-info" disabled startIcon={<EditOutlinedIcon />} onClick={() => { }}>
                    <span className="onshiftoparty-font mt-1 mb-0 pr-0">Edit</span>
                  </Button> */}
                </div>
              </div>
              <div className="row m-0 col-12 mt-2 mb-3">
                <div className="col-3 col-md-2 col-sm-2">
                  <div className="info-sub-title-value font-size-12">Region</div>
                </div>
                <div className="col-3 col-md-3 col-sm-2">
                  <div className="info-sub-title-value font-size-12">Trade License Number</div>
                </div>
                <div className="col-3 col-md-3 col-sm-2">
                  <div className="info-sub-title-value font-size-12">Expiry Date</div>
                </div>
                <div className="col-3 col-md-3 col-sm-2">
                  <div className="info-sub-title-value font-size-12">Supported Document</div>
                </div>
                <div className="col-2 col-md-3 col-sm-2">
                </div>
              </div>
              {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.treadLicense && dealerDetails.data.data.treadLicense.length > 0 && dealerDetails.data.data.treadLicense.map((treadLicense: any, index: any) => (
                <div className="col-12 m-0 p-0">
                  <div className="row m-0 col-12 mt-2 mb-2 border-bottom">
                    <div className="col-3 col-md-2 col-sm-2 mt-3">
                      <div className="subTitle mt-2">{getRegionName(treadLicense.regionCode)}</div>
                    </div>
                    <div className="col-3 col-md-3 col-sm-2 mt-3">
                      <div className="subTitle mt-2 mb-3">{treadLicense.tradeLicenseNo}</div>
                    </div>
                    <div className="col-3 col-md-3 col-sm-2 mt-3">
                      <div className="subTitle mt-2 mb-3">{moment(treadLicense.expiryDate).format('DD MMM YYYY')}</div>
                    </div>
                    {dealerDetails && dealerDetails.data && dealerDetails.data.data.treadLicense && treadLicense.documentId && treadLicense.documentId.url && (
                      <div className="col-3 col-md-3 col-sm-2 mt-3">
                        <div className="subTitle mt-1 mb-3">
                          <span className="info-sub-header-document pr-2 mt-0" onClick={() => {
                            window.open(treadLicense.documentId.url)
                          }}>{treadLicense.documentId.selectedFileName ? treadLicense.documentId.selectedFileName : "-"}
                          </span>
                          <IconButton className="p-1" aria-label="download" onClick={() => { window.open(treadLicense.documentId.url) }}>
                            <GetAppIcon className="file-close-color" fontSize="small" />
                          </IconButton>
                        </div>
                      </div>
                    )}
                    <div className="col-2 col-md-3 col-sm-2">
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="card col-12 m-0 p-0">
          <div className="row mb-1 mt-1 px-2">
            <div className="col-12 mt-1 mb-1">
              <div className="d-flex align-items-center mt-0 mb-0">
                <h5 className="info-sub-header-document mt-3 mb-0 px-4">Authorized Signatories</h5>
              </div>
            </div>
          </div>
          <div className="row m-0 p-0 mt-2 mb-3 px-2">
            <div className="col-12 px-4">
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                  />
                  <TableBody>
                    <>
                      {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.authorizedSignatories && dealerDetails.data.data.authorizedSignatories.length > 0 && dealerDetails.data.data.authorizedSignatories.map((authorizedSignatories: any, index: any) => (
                        <TableRow hover tabIndex={-1}>
                          <TableCell component="th"
                            scope="row" padding="none" className="document-tablecell-data">
                            {authorizedSignatories.firstName}{'  '}{authorizedSignatories.lastName}
                          </TableCell>
                          <TableCell align="left" className="document-tablecell-data">
                            {authorizedSignatories.email}
                          </TableCell>
                          <TableCell align="left" className="document-tablecell-data">
                            {authorizedSignatories.mobile}
                          </TableCell>
                          <TableCell align="left" className="document-tablecell-data">
                            {authorizedSignatories.designation}
                          </TableCell>
                          <TableCell align="left" className="document-tablecell-data">
                            {authorizedSignatories.emiratesIdNumber}
                          </TableCell>
                          <TableCell align="left" className="document-tablecell-data">
                            {getAuthorisedFor(authorizedSignatories)}
                          </TableCell>
                          <TableCell align="left">
                            <div className="row">
                              <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                <Button
                                  className="text-info"
                                  startIcon={<Visibility />}
                                  onClick={() => {
                                    dispatch(getAddNewAuthorisedSignatories(authorizedSignatories.id, sapCustomerId))
                                    handleClickOpen();
                                    setIsView(true);
                                    setIsEdit(true);
                                    setEditData(authorizedSignatories);
                                  }}>
                                  View
                                </Button>
                              </ButtonGroup>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <Dialog className="pb-0"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
          >
            <AddNewAuthorisedSignatoriesDailog setOpenDialog={handleClose} getNewAuthorisedSignatories={getNewAuthorisedSignatories}
              isEdit={isEdit}
              editData={editData}
              updateNewAuthorisedSignatories={updateNewAuthorisedSignatories}
              isView={isView}
            />
          </Dialog>
        </div>
        <div className="card col-12 m-0 p-0">
          <div className="row mb-0 mt-0 px-2">
            <div className="col-12 mt-2 mb-2">
              <h5 className="info-sub-header-document mt-3 mb-1 px-4">Other Docs</h5>
            </div>
            <div className="col-12 mt-1 mb-1">
              <div className="row col-md-12 mb-2 mt-2 px-4">
                {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.companySeal && dealerDetails.data.data.companySeal.documentId && dealerDetails.data.data.companySeal.documentId.url && (
                  <div className="col-md-4 col-lg-4 pr-3 mb-2">
                    <h6 className="document-content px-2 mb-2">Company Seal</h6>
                    <small className="document-sub-content px-2"> ----- </small><br></br>
                    <div className="mb-2 mt-4">
                      <IconButton size="small" className="" onClick={() => { window.open(dealerDetails.data.data.companySeal.documentId.url) }}>
                        <AssignmentReturnedIcon className="download-icon mr-1" />
                      </IconButton>
                      <span className="download-docs mr-2 px-2" onClick={() => {
                        window.open(dealerDetails.data.data.companySeal.documentId.url)
                      }}> {dealerDetails.data.data.companySeal.documentId ? dealerDetails.data.data.companySeal.documentId.selectedFileName : "-"}
                      </span>
                    </div>
                    <div className="info-sub-title-value font-size-12 px-0"> </div>
                  </div>
                )}
                {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.memorandumOfAssociation && dealerDetails.data.data.memorandumOfAssociation.documentId && dealerDetails.data.data.memorandumOfAssociation.documentId.url && (
                  <div className="col-md-4 col-lg-4 pr-3 mb-2">
                    <h6 className="document-content px-0">Memorandum Of Association (MOA)</h6>
                    <small className="document-sub-content">Power Of Attorney (POA)</small><br></br>
                    <small className="mb-3">If you are not the Owner, it's mandatory to upload.</small>
                    <div className="mb-2 mt-2">
                      <IconButton size="small" className="" onClick={() => { window.open(dealerDetails.data.data.memorandumOfAssociation.documentId.url) }}>
                        <AssignmentReturnedIcon className="download-icon mr-1" />
                      </IconButton>
                      <span className="download-docs mr-2 px-2" onClick={() => {
                        window.open(dealerDetails.data.data.memorandumOfAssociation.documentId.url)
                      }}>{dealerDetails.data.data.memorandumOfAssociation.documentId ? dealerDetails.data.data.memorandumOfAssociation.documentId.selectedFileName : "-"}
                      </span>
                    </div>
                    <div className="info-sub-title-value font-size-12 px-0">Expiry Date : {moment(dealerDetails.data.data.memorandumOfAssociation.expiryDate).format('DD MMM YYYY')} </div>
                  </div>
                )}
                {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.vatCertificateCopy && dealerDetails.data.data.vatCertificateCopy.documentId && dealerDetails.data.data.vatCertificateCopy.documentId.url && (
                  <div className="col-md-4 col-lg-4 pr-3 mb-2">
                    <h6 className="document-content px-0">VAT Certificate Copy</h6>
                    <small className="document-sub-content mb-2">One Time With Issue Date</small>
                    <div className="mb-2 mt-4">
                      <IconButton size="small" className="" onClick={() => { window.open(dealerDetails.data.data.vatCertificateCopy.documentId.url) }}>
                        <AssignmentReturnedIcon className="download-icon mr-1" />
                      </IconButton>
                      <span className="download-docs mr-2 px-2" onClick={() => {
                        window.open(dealerDetails.data.data.vatCertificateCopy.documentId.url)
                      }}>{dealerDetails.data.data.vatCertificateCopy.documentId ? dealerDetails.data.data.vatCertificateCopy.documentId.selectedFileName : "-"}
                      </span>
                    </div>
                    <div className="info-sub-title-value font-size-12 px-0">Expiry Date : {moment(dealerDetails.data.data.vatCertificateCopy.expiryDate).format('DD MMM YYYY')}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={selectedTab} index="Address">
        <Fragment>
          {dealerDetails && dealerDetails.loading && (
            <div className="mx-auto my-5">
              <FullscreenLoader />
            </div>
          )}
          <div className="row mt-3">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <div className="row-space-start ml-auto text-info">
                  (Contact KAM to add new address)
                  {/* <Button
                    className="text-info"
                    startIcon={<AddIcon className="font-size-18" />}
                    disabled
                    onClick={() => {
                      // handleClickOpen();
                    }}
                  ><span className="onshiftoparty-font">Add New Address
                    </span>
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card row m-0 mt-3">
            {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.addresses && dealerDetails.data.data.addresses.length && dealerDetails.data.data.addresses.map((addresses: any, index: any) => (
              <>
                <div className="col-12 m-0 pl-3 p-2 border-bottom">
                  <div className="row">
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="card-body pt-1" key={index}>
                        <div className="w-75 d-inline-block mt-2">
                          <div className="shipAddTitle">{addresses.dafault ? addresses.companyName : addresses.location} &nbsp;
                            {addresses.dafault && (
                              <Button
                                className="defaultAddressButton cursor-default"
                                size="small"
                                variant="contained"
                                color="secondary"
                              >Head Office</Button>
                            )}
                            {/* {!addresses.dafault && (
                              <Button
                                className="underKAMReviewAddressButton cursor-default"
                                size="small"
                                variant="contained"
                                color="secondary"
                              >Under KAM Review</Button>
                            )} */}
                          </div>
                          <div className="shipAdd mt-2">{addresses.streetName ? addresses.streetName + " - " : ""}  {addresses.city ? addresses.city + " - " : ""} {addresses.country}</div>
                          <div className="shipAdd">Telephone Office: {addresses.telephoneNumber}</div>
                          <div className="shipAdd">PO Box: {addresses.poBox}</div>
                          <div className="shipAdd">Emirates/Region: {addresses.region} {getRegionName(addresses.region)}</div>
                          <div className="px-0 col-md-3 col-lg-3">
                          </div>
                        </div>
                        {!addresses.dafault && (
                          <div className="markDefaultIcon w-25 d-inline-block text-right">
                            {/* <Button
                              className="text-info"
                              startIcon={<EditOutlinedIcon />}
                              disabled
                              onClick={() => {
                              }}><span className="onshiftoparty-font">View/Edit</span>
                            </Button> */}
                            {/* <Button
                              // onClick={() => onClickDeleteAddress(shipppingAddress)}
                              className="text-info"
                              startIcon={<DeleteOutlineOutlinedIcon />}
                              disabled
                            > <span className="onshiftoparty-font">Delete</span>
                            </Button> */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </Fragment>
      </TabPanel>
      <TabPanel value={selectedTab} index="Users">
        <Fragment>
          {usersList && usersList.loading && (
            <div className="mx-auto my-5">
              <FullscreenLoader />
            </div>
          )}
          <div className="row">
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 mt-2">
              <div className="d-flex bd-highlight">
                <div className="flex-grow-1 bd-highlight">
                  <h5 className="page-sub-title mt-3">Users List</h5>
                </div>
                <div className="mt-2 mb-1 bd-highlight">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="dealer-search" className="pl-2" margin="dense">Search</InputLabel>
                    <OutlinedInput
                      id="dealer-search"
                      value={searchValue}
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          searchDealerList(searchValue, true);
                          setPage(0);
                        }
                      }}
                      onChange={(e: any) => {
                        setSearchValue(e.target.value);
                        if (e.target.value === '') {
                          getUsers();
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Search"
                            edge="end"
                            onClick={(e: any) => {
                              searchDealerList(e, true);
                              setPage(0);
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={50} label="Search by User name" margin="dense" className="rounded-input-dealer mw-200p mr-2" />
                  </FormControl>
                </div>
                <div className="mt-2 mb-1 bd-highlight">
                  {showManageUser && userType !== userTypes.user &&
                    <Button
                      variant="contained"
                      color="primary"
                      className="rounded-pill ml-5"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setSelectedUserId("");
                        setIsUserDialogForView(false);
                        setIsUserDialogForEdit(false);
                        handleopenCloseAddUser(true);
                      }}
                    >
                      Add User
                    </Button>
                  }
                </div>
              </div>
              {/* <Grid container>
                <Grid item xs={5}>
                  <h5 className="page-sub-title mt-2">Users List</h5>
                </Grid>
                <Grid item xs={7} >
                  <div className="row float-right">
                    <Grid item xs={7}>
                      <SearchBar callAPI={() => {
                        dispatch(getAllUsers(dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.sapCustomerId, 0, searchValue));
                        setPage(0);
                      }} value={searchValue} onChange={(e: any) => {
                        setSearchValue(e.target.value);
                        if (e.target.value === '') {
                          getUsers();
                        }
                      }} label="Search by User name" className="mr-2" />
                    </Grid>
                    {showManageUser && userType !== userTypes.user &&
                      <Grid item xs={5}>
                        <Button
                          variant="contained"
                          color="primary"
                          className="rounded-pill ml-5"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            setSelectedUserId("");
                            setIsUserDialogForView(false);
                            setIsUserDialogForEdit(false);
                            handleopenCloseAddUser(true);
                          }}
                        >
                          Add User
                        </Button>
                      </Grid>
                    }
                  </div>
                </Grid>
              </Grid>
               */}
              <div className="cardCommon mh-300p mt-2">
                <TableContainer className={`classes.container`}>
                  <Table aria-label="table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column: any) => (
                          <TableCell
                            key={column.id}
                            className={classes.header}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell className={classes.header}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersList && usersList.data && usersList.data.data && usersList.data.data.userList && usersList.data.data.userList.length > 0 && usersList.data.data.userList.map((user: any, index: any) => (
                        <TableRow key={index}>
                          <TableCell className={classes.body}>{user.firstName}{' '}{user.lastName}</TableCell>
                          <TableCell className={classes.body}>{user.webApplicationLoginId}</TableCell>
                          <TableCell className={classes.body}>{user.b2cUserId}</TableCell>
                          <TableCell className={classes.body}>{user.userType}</TableCell>
                          <TableCell className={classes.body}>{user.email}</TableCell>
                          <TableCell className={classes.body}>{user.firstTelephoneNumber}</TableCell>
                          <TableCell className={classes.body}>
                            {user && user.salesOrganization && user.salesOrganization.length > 0 && user.salesOrganization.map((org: any, index: any) => (
                              <React.Fragment key={index}>{getNewBrand(org.make)}{user.salesOrganization.length === index + 1 ? '' : ', '}</React.Fragment>
                            ))}
                          </TableCell>
                          <TableCell className={classes.body}>{user.status}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setSelectedUserId(user.b2bUserId);
                                setIsUserDialogForEdit(true);
                                setIsUserDialogForView(true);
                                if (user.status.toLowerCase() === "in progress") {
                                  setInProgress(true);
                                }
                                else {
                                  setInProgress(false);
                                }
                                dispatch(getUserDetails(user.b2bUserId));
                                dispatch(setUserDetails(user));
                                handleopenCloseAddUser(true);
                              }}
                              className={`mr-2 ${classes.actions}`} size="small">
                              <Visibility fontSize="inherit" />
                              <span className="ml-1">View</span>
                            </IconButton>
                            {showManageUser && userType !== userTypes.user &&
                              <IconButton
                                onClick={() => {
                                  setSelectedUserId(user.b2bUserId);
                                  setIsUserDialogForEdit(true);
                                  setIsUserDialogForView(false)
                                  if (user.status.toLowerCase() === "in progress") {
                                    setInProgress(true);
                                  }
                                  else {
                                    setInProgress(false);
                                  }
                                  dispatch(getUserDetails(user.b2bUserId));
                                  dispatch(setUserDetails(user));
                                  handleopenCloseAddUser(true);
                                }}
                                className={classes.actions} size="small">
                                <EditIcon fontSize="inherit" />
                                <span className="ml-1">Edit</span>
                              </IconButton>}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="col-12 d-flex justify-content-end">
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={usersList && usersList.data && usersList.data.data && usersList.data.data.totaCount ? usersList.data.data.totaCount : 0}
                    rowsPerPage={10}
                    page={page}
                    onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </TabPanel>
      <TabPanel value={selectedTab} index="sellingPrice">
        {dealerDetails && dealerDetails.loading && (
          <div className="mx-auto my-5">
            <FullscreenLoader />
          </div>
        )}
        <SellingPrice dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} />
      </TabPanel>
      <DeleteDialog
        open={confirmDialog && dataUpdated}
        text="Do you wish to proceed without saving the data?"
        handleopenClose={(data: any, newValue: any) => {
          if (data === 'delete' || data === "back") {
            setDataUpdated(false); setselectedTab(tabValue); handleopenClose(false);
          }
          if (!data) { handleopenClose(false); }
        }} />
      <Prompt when={dataUpdated} message={"Do you wish to proceed without saving the data?"}>
      </Prompt>
    </Fragment >
  );
};

export default OrgSetting;

