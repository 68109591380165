import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useSelector } from "react-redux";
import defaultLogo from '../../assets/default-user-logo.png';
import Loading from '../../common/components/loading';
import { getNewBrand } from '../../common/components/util';

const DealerDetails: React.FC = () => {
  const { dealerDetails }: any = useSelector((state: any) => state.dealer);
  let companyDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails) ? dealerDetails.data.data.customerDetails.companyDetails : null;
  let customerDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails) ? dealerDetails.data.data.customerDetails : null;
  let ownerDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails) ? dealerDetails.data.data.customerDetails.customerInformation : null;
  let companyBankDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails) ? dealerDetails.data.data.customerDetails.bankDetails : null;
  let companyBrands: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails) ? dealerDetails.data.data.customerDetails.organizationIds : null;
  let customerInfo: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails) ? dealerDetails.data.data.customerDetails : null;

  return (
    <>
      {dealerDetails && dealerDetails.loading && (
        <div className="d-flex justify-content-center mx-auto mt-5">
          <Loading />
        </div>
      )}
      {dealerDetails && !dealerDetails.loading && (
        <Grid container>
          <Grid item xs={12} className="file-name pb-3">Company Details</Grid>
          <Grid item xs={4} className="dealer-table-content pt-2">Brand Logo :</Grid>
          <Grid item xs={8}>
            <img
              src={customerDetails.logo ? customerDetails.logo : defaultLogo}
              alt="Logo Client"
              className="dealer-grid-logo"
            />
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-3">Company Name</div>
            <div className="dealer-table-subcontent pt-1">
              {companyDetails && companyDetails.title ? companyDetails.title : ""} &nbsp;
              {companyDetails && companyDetails.companyName ? companyDetails.companyName : ""}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-3">Telephone Number</div>
            <div className="dealer-table-subcontent pt-1">
              {companyDetails && companyDetails.telephones[0].telephone ? companyDetails.telephones[0].telephone : ""}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-3">Address</div>
            <div className="dealer-table-subcontent pt-1">
              {companyDetails && companyDetails.addresses ? companyDetails.addresses : ""}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-3">Email</div>
            <div className="dealer-table-subcontent pt-1">
              {companyDetails && companyDetails.emails[0].emailId ? companyDetails.emails[0].emailId : ""}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-3">Trade License</div>
            <div className="dealer-table-subcontent pt-1">
              {companyDetails && companyDetails.tradeLicenseNo ? companyDetails.tradeLicenseNo : ""}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-3">Tax Registration No</div>
            <div className="dealer-table-subcontent pt-1">
              {companyDetails && companyDetails.taxRegistrationNo ? companyDetails.taxRegistrationNo : "-"}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-3">Annual Turnover</div>
            <div className="dealer-table-subcontent pt-1">
              {companyDetails && companyDetails.companyCurrencyType ? companyDetails.companyCurrencyType : ""} &nbsp;
              {companyDetails && companyDetails.companyAnnualTurnover ? companyDetails.companyAnnualTurnover : ""}
            </div>
          </Grid>
          <Grid item xs={12} className="file-name pt-4 pb-2">Owner/Partner Details</Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-1">Name</div>
            <div className="dealer-table-subcontent pt-1">
              {ownerDetails && ownerDetails.title ? ownerDetails.title : ""} &nbsp;
              {ownerDetails && ownerDetails.customerFirstName ? ownerDetails.customerFirstName : ""} &nbsp;
              {ownerDetails && ownerDetails.customerLastName ? ownerDetails.customerLastName : ""}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-1">Mobile</div>
            <div className="dealer-table-subcontent pt-1">
              {ownerDetails && ownerDetails.phoneNumber ? ownerDetails.phoneNumber : ""}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-1">Email</div>
            <div className="dealer-table-subcontent pt-1">
              {ownerDetails && ownerDetails.emails[0].emailId ? ownerDetails.emails[0].emailId : ""}
            </div>
          </Grid>
          <Grid item xs={12} className="file-name pt-4 pb-2">Bank Details</Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-1">Bank Name</div>
            <div className="dealer-table-subcontent pt-1">
              {companyBankDetails && companyBankDetails.bankName ? companyBankDetails.bankName : ""}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-1">IBAN</div>
            <div className="dealer-table-subcontent pt-1">
              {companyBankDetails && companyBankDetails.bankIBAN ? companyBankDetails.bankIBAN : ""}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-1">Address</div>
            <div className="dealer-table-subcontent pt-1">
              {companyBankDetails && companyBankDetails.bankAddress ? companyBankDetails.bankAddress : ""}
            </div>
          </Grid>
          <Grid item xs={12} className="file-name pt-4 pb-2">Brand &amp; Others</Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-1">Brands:</div>
            <Grid container>

              <Grid item xs={6} className="pr-2 mt-2">
                {companyBrands && companyBrands.length > 0 && companyBrands.map((brand: any, index: any) =>
                  <Chip size="small" label={getNewBrand(brand.make)} className="dealer-table-subcontent w-100 brand-chip mt-2" />
                )}
              </Grid>
              {/* <Grid item xs={6} className="pr-4 mt-2">
                {creditLimitDetails && creditLimitDetails.loading && (
                  <Loading/>
                )}
                {filteredBrands.length>0 && filteredBrands.map((limit: any, index: any) =>
                  <Chip size="small" label={customerInfo ? customerInfo.creditCurrencyType + " " + (limit.creditExposure | 0) + " of " + (limit.creditLimit | 0) : ""} className="dealer-table-subcontent w-100 brand-limit-chip mt-2" />
                )}
              </Grid> */}

            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container>
              <Grid item xs={6}>
                <div className="dealer-table-content pt-1">SAP Cust. ID</div>
                <div className="dealer-table-subcontent pt-1">{customerInfo && customerInfo.sapCustomerId}</div>
              </Grid>
              <Grid item xs={6}>
                <div className="dealer-table-content pt-1">Language</div>
                <div className="dealer-table-subcontent pt-1">{customerInfo && customerInfo.language}</div>
              </Grid>
              <Grid item xs={12}>
                <div className="dealer-table-content pt-3">Currency</div>
                <div className="dealer-table-subcontent pt-1">{customerInfo && customerInfo.currency}</div></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="file-name pt-4 pb-2">Assigned KAM & FSE</Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-1">BRAND</div>
            {customerDetails && customerDetails.assignedKamAndFse && customerDetails.assignedKamAndFse.length > 0 &&
              customerDetails.assignedKamAndFse.map((user: any) => (
                <div className="dealer-table-subcontent pt-1">{getNewBrand(user.make)}</div>
              )
              )}
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-1">KAM</div>
            {customerDetails && customerDetails.assignedKamAndFse && customerDetails.assignedKamAndFse.length > 0 &&
              customerDetails.assignedKamAndFse.map((user: any) => (
                <div className="dealer-table-subcontent pt-1">{user.kamUserName}</div>
              )
              )}
          </Grid>
          <Grid item xs={4}>
            <div className="dealer-table-content pt-1">FSE</div>
            {customerDetails && customerDetails.assignedKamAndFse && customerDetails.assignedKamAndFse.length > 0 &&
              customerDetails.assignedKamAndFse.map((user: any) => (
                <div className="dealer-table-subcontent pt-1">{user.fseUserName}</div>
              )
              )}
          </Grid>
        </Grid >
      )
      }
    </>
  )
}

export default DealerDetails
