import React from 'react'

const ProgressBar = (props:any) => {
    const {bgColor, completed,creditUsed} = props;
    const containerStyles = {
        height: 20,
        width: '80%',
        backgroundColor:"#D2D2D2",
        borderRadius: 50,
    }
    const fillerStyles = {
        height: '100%',
        width:`${completed}%`,
        backgroundColor: bgColor,
        borderRadius: 'inherit',
        alignItem: 'right'
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 900
    }
    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${creditUsed}`}</span>
            </div>
        </div>
    )
}

export default ProgressBar
