import React, { Fragment, useState, Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './AdminProfile.css'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AnnouncementIcon from '../../common/components/AnnouncementIcon';
import NotificationIcon from '../../common/components/NotificationIcon';
import { getProfile, updateProfile } from "../../store/actions/profile.action";
import { get } from 'lodash';
import Snackbar from './../../common/components/Snackbar';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { Messages } from '../../constants/messages';


const AdminProfile: React.FC = () => {

    const dispatch: Dispatch<any> = useDispatch();
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const [firstName, setFirstName] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");
    const [mobileNumber, setMobileNumber] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [userName, setUserName] = React.useState<string>("");
    const [userType, setUserType] = React.useState<string>("");
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [apiResponseText, setApiResponseText] = React.useState(false);

    useEffect(() => {
        const userType = sessionStorage.getItem('userType');
        if (userType === "creditTeam") {
            dispatch(getProfile(profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.email, "creditTeam"));
            setUserType("creditTeam");
        } else if (userType === "afg_super_admin") {
            dispatch(getProfile(profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.email, "kam"));
            setUserType("afg_super_admin");
        } else {
            dispatch(getProfile(profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.email, "kam"));
            setUserType("kam");
        }
        populateProfileData();
    }, []); // eslint-disable-line

    function populateProfileData() {
        const profile = get(profileDetails, 'data.data', {});
        profile && profile.basicInfo && profile.basicInfo.firstName ? setFirstName(profile.basicInfo.firstName) : setEmail("");
        profile && profile.basicInfo && profile.basicInfo.lastName ? setLastName(profile.basicInfo.lastName) : setEmail("");
        profile && profile.basicInfo && profile.basicInfo.phoneNumber ? setMobileNumber(profile.basicInfo.phoneNumber) : setMobileNumber("");
        profile && profile.basicInfo && profile.basicInfo.email ? setEmail(profile.basicInfo.email) : setEmail("");
        profile && profile.basicInfo && profile.basicInfo.webApplicationLoginId ? setUserName(profile.basicInfo.webApplicationLoginId) : setUserName("");
    }

    const [mobileError, setMobileError] = useState(false);

    const OnMobileChange = (e: any) => {
        setMobileNumber(e.target.value);
        if (/^[5]\d{8}$/.test(e.target.value))
            setMobileError(false);
        else
            setMobileError(true);
    };

    const UpdateProfile = async (e: any) => {
        e.preventDefault();
        if (mobileError || !(/^[5]\d{8}$/.test(mobileNumber))) {
            setMobileError(true);
        } else {
            let data = {
                "id": userName.trim(),
                "firstName": firstName.trim(),
                "lastName": lastName.trim(),
                "mobileNumber": mobileNumber.trim(),
                "email": email.trim(),
                "userType": userType
            }
            let result = await dispatch(updateProfile(data));
            const apiResponse = get(result, 'message', {});
            if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
                setOpenSnackbar(true);
                setApiResponseText(apiResponse.message);
                const kamEmail = sessionStorage.getItem('email');
                dispatch(getProfile(kamEmail, userType))
                setTimeout(() => {
                    setOpenSnackbar(false);
                }, 2000);
            }
        }
    };

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    return (
        <Fragment>
            {profileDetails && profileDetails.loading && <FullscreenLoader />}
            <div className="d-flex align-items-center">
                <h2 className="page-title">
                    My Profile</h2>
                {userType !== 'afg_super_admin' && (
                    <div className="ml-auto">
                        <AnnouncementIcon />
                        <NotificationIcon />
                    </div>
                )}
            </div>
            <div className="row mt-4">
                <div className="col-12 mb-2">
                    <h5 className="page-sub-title mb-2">Your Information</h5>
                    <div className="mt-1 cardCommon p-3">
                        <div className="col-sm-6 col-xs-12">
                            <div className="row">
                                <div className="col-10 m-1 mt-3">
                                    <TextField value={firstName} className="full-width rounded-input" size="small" id="firstName" label="First Name" variant="outlined" margin="dense" disabled />
                                </div>
                                <div className="col-10 m-1 mt-1">
                                    <TextField value={lastName} className="full-width rounded-input" size="small" id="lastName" label="Last Name" variant="outlined" margin="dense" disabled />
                                </div>
                                <div className="col-10 m-1">
                                    <TextField error={mobileError} placeholder={Messages.MOBILE_PLACEHOLDER_TEXT} helperText={mobileError && "It should start with 5 and contains 9 digits"} onChange={OnMobileChange} value={mobileNumber} type="tel" className="full-width rounded-input" id="mobile" label="Mobile" size="small" variant="outlined" margin="dense" />
                                </div>
                                <div className="col-10 m-1">
                                    <TextField value={email} type="email" className="full-width rounded-input" id="email" label="Email Id" variant="outlined" margin="dense" size="small" disabled />
                                </div>
                                <div className="col-10 m-1">
                                    <TextField value={userName} className="full-width rounded-input" id="username" label="Username" variant="outlined" margin="dense" size="small" disabled />
                                </div>
                                <div className="col-10 m-1">
                                    <TextField value={userType} className="full-width rounded-input" id="usertype" label="User Type" variant="outlined" margin="dense" size="small" disabled />
                                </div>
                                <div className="col-10 mt-2 mb-4">
                                    <Button onClick={UpdateProfile} variant="contained" className="rounded-button pull-right font-size-11"> Update </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Snackbar
                    open={openSnackbar}
                    handleopenClose={() => {
                        handleopenCloseSnackbar(false)
                    }} text={apiResponseText} />
            </div>
        </Fragment >
    )
}

export default AdminProfile
