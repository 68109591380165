import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles(() => ({
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  }
}));

export default function SubmitDialogKAM(props: any) {
  const classes = useStyles();

  return (
    <div className="float-left">
      <Dialog
        open={props.open ? true : false}
        onClose={() => { props.handleopenClose(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}>

        <DialogContent>
          <div className={classes.icon}>
            <WarningIcon className="delete-warning" fontSize="small" />
          </div>
          <div>
            <DialogContentText id="alert-dialog-description">
              <div className="mt-2">
                <h5 className="submit-dialog-content">Are you sure you want to submit?</h5>
              </div>
              <div className="pr-2 mb-2 pl-0">
                <h3 className="document-content pt-3 pb-2">Please note: Once submitted, the data cannot be edited.</h3>
              </div>
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions className="mt-3 mb-3 justify-content-end">
          <Button variant="contained"
            color="secondary" className={`createFavourite ${classes.createButton}`}
            onClick={() => {
              props.handleopenClose('Submit')
            }}>Submit
          </Button>
          <Button variant="contained" className={classes.closeButton}
            onClick={() => {
              props.handleopenClose(false)
            }}>Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}
