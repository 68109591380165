import { PROFILE } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
    profileDetails: null,
    updateProfile: null,
    isSellingPriceData: true,
};


function profileReducer(state: any = initialState, action: IActionBase): IGlobal {
    switch (action.type) {
        case PROFILE.GET_PROFILE_FETCHING:
            return {
                ...state,
                profileDetails: { loading: true, data: null, err: null }
            };
        case PROFILE.GET_PROFILE_SUCCESS:
            return {
                ...state,
                profileDetails: { loading: false, data: action.payload.data, err: null }
            };
        case PROFILE.GET_PROFILE_ERROR:
            return {
                ...state,
                profileDetails: { loading: false, data: null, err: action.payload }
            };
        case PROFILE.PUT_PROFILE_FETCHING:
            return {
                ...state,
                updateProfile: { loading: true, data: null, err: null }
            };
        case PROFILE.PUT_PROFILE_SUCCESS:
            return {
                ...state,
                updateProfile: { loading: false, data: action.payload.data, err: null }
            };
        case PROFILE.PUT_PROFILE_ERROR:
            return {
                ...state,
                updateProfile: { loading: false, data: null, err: action.payload }
            };
        case PROFILE.IS_SELLING_PRICE_TOGGLE_BUTTON:
            return {
                ...state,
                isSellingPriceData: action.payload.data,
            }
        default:
            return state;
    }
};
export default profileReducer;