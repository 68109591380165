import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";


export const creditLimitService = {
    getCreditLimits,
    getCreditRequest,
    updateCreditLimits,
    createCreditLimits,
    deleteCreditLimits,
    createDefaultCreditAdditionalData,
    getDefaultCreditAdditionalData,
    updateDefaultCreditAdditionalData
};

async function getCreditLimits(sapCustomerId: any, loginId: any) {

    let apiPath = API_ENDPOINTS.CREDIT_LIMIT_NEW + `?sapCustomerId=${sapCustomerId}&loginId=${loginId}`;
    let url = window.location.href.split("/")
    let currentUrl = url[url.length - 1]

    if (currentUrl === "achievementssuperadmin") {
        apiPath = API_ENDPOINTS.CREDIT_LIMIT_NEW_ACHIEVEMENT + `/achievements/creditLimit?sapCustomerId=${sapCustomerId}&webApplicationLoginId=${loginId}`
    } if (currentUrl === "achievementskam") {
        apiPath = API_ENDPOINTS.CREDIT_LIMIT_NEW_ACHIEVEMENT + `/achievements/creditLimit?sapCustomerId=${sapCustomerId}&webApplicationLoginId=${loginId}`
    }

    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
    };
    return await makeRequest(options)
}

async function getCreditRequest(sapCustomerId: any, loginId: any) {
    const apiPath = API_ENDPOINTS.CREDIT_REQUEST;
    const options = {
        path: `${apiPath}/creditRequest?b2bUserId=${sapCustomerId}`,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
    };
    return await makeRequest(options)
}

async function createCreditLimits(sapCustomerId: any, bodyData: any) {
    const apiPath = API_ENDPOINTS.CREDIT_REQUEST;
    const options = {
        path: `${apiPath}/creditRequest/${sapCustomerId}`,
        method: "POST",
        bodyObj: bodyData,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}


async function updateCreditLimits(sapCustomerId: any, bodyData: any) {
    const apiPath = API_ENDPOINTS.CREDIT_REQUEST;
    const options = {
        path: `${apiPath}/creditRequest`,
        method: "PUT",
        bodyObj: bodyData,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function deleteCreditLimits(docid: any, type: any, requestId: any) {
    const apiPath = API_ENDPOINTS.CREDIT_REQUEST;
    const options = {
        path: `${apiPath}/creditRequest/delete?docId=${docid}&requestId=${requestId}`,
        method: "POST",
        bodyObj: {
            "type": type
        },
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function createDefaultCreditAdditionalData(bodyData: any, sapCustomerId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT;
    const options = {
        path: `${apiPath}/dealer/${sapCustomerId}/creditRequest/create/default`,
        method: "POST",
        bodyObj: bodyData,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateDefaultCreditAdditionalData(bodyData: any, sapCustomerId: any, requestId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT;
    const options = {
        path: `${apiPath}/dealer/${sapCustomerId}/creditRequest/${requestId}/saveUpdate`,
        method: "POST",
        bodyObj: bodyData,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getDefaultCreditAdditionalData(requestId: any, sapCustomerId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT;
    const options = {
        path: `${apiPath}/dealer/creditRequest/requestId/${requestId}?sapCustomerId=${sapCustomerId}&source=dealeruser`,
        method: "GET",
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}