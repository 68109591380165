import { CREATE_UPDATE, CREATE_UPDATE_QUEUE_CHECK } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState = {
  enquiryInfo: null,
  enquiryDetailsQueueCheck: null,
  enquirySessionId: null,
};

function createEnquiryReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING:
      return {
        ...state,
        enquiryInfo: { loading: true, data: null, err: null }
      };
    case CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS:
      return {
        ...state,
        enquiryInfo: { loading: false, data: action.payload.data.data, err: null }
      };
    case CREATE_UPDATE.POST_CREATE_UPDATE_ERROR:
      return {
        ...state,
        enquiryInfo: { loading: false, data: null, err: action.payload }
      };
    case CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_FETCHING:
      return {
        ...state,
        enquiryDetailsQueueCheck: { loading: true, data: null, err: null }
      };
    case CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_SUCCESS:
      return {
        ...state,
        enquiryDetailsQueueCheck: { loading: false, data: action.payload.data.data, err: null }
      };
    case CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_ERROR:
      return {
        ...state,
        enquiryDetailsQueueCheck: { loading: false, data: null, err: action.payload }
      };
    case CREATE_UPDATE.GET_UNIQUE_ENQUIRY_ID:
      return {
        ...state,
        enquirySessionId: action.payload.data
      }
    default:
      return state;
  }
};

export default createEnquiryReducer;
