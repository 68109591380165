import moment from "moment";
import { API_ENDPOINTS, LOGON } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const logonService = {
    userLogon,
    getToken,
    validateRefreshToken,
    getKamToken,
    logoutUser
};

async function userLogon(portal: any) {
    const apiPath = API_ENDPOINTS.LOGON_ENDPOINT + LOGON.API_PATH;
    let kamBrands = sessionStorage.getItem('kamOrgs');
    const adminBodyObj = {
        "brands": kamBrands ? JSON.parse(kamBrands) : [],
        "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'), //Hard coded value needs to be removed
        "clientIPAddress": sessionStorage.getItem('clientIp'),
        "webRequestDateTime": moment().format('YYYY-MM-DDTHH:MM:SS') + '+0400',
    }
    const dealerBodyObj = {
        "brands": sessionStorage.getItem('brands')?.split(","),
        "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
        "clientIPAddress": sessionStorage.getItem('clientIp'),
        "webRequestDateTime": moment().format('YYYY-MM-DDTHH:MM:SS') + '+0400',
    }
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: portal === 'Admin' ? adminBodyObj : dealerBodyObj,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getToken(b2cUserId: any, email: any, portal: any) {
    const apiPath = API_ENDPOINTS.GET_TOKEN_ENDPOINT
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: {
            userId: b2cUserId,
            email: email,
            source: portal === "admin" ? portal : "dealer"
        },
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getKamToken(oid: any, secret: any) {
    const apiPath = API_ENDPOINTS.GET_KAM_TOKEN_ENDPOINT + `/${oid}?identifier=${Date.now()}${Math.random() * 1000}`
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {
        },
        customHeaders: {
            secret: secret
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function validateRefreshToken(refreshToken: any, b2cUserId: any, email: any) {
    const apiPath = API_ENDPOINTS.VALIDATE_TOKEN_ENDPOINT
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: {
            refreshToken: refreshToken,
            userId: b2cUserId,
            email: email
        },
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}


async function logoutUser() {
    const apiPath = API_ENDPOINTS.LOGON_ENDPOINT + LOGON.LOGOUT;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: {
            "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
            "clientIPAddress": ""
        },
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}


