
import React, { useEffect, useCallback } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ListAltIcon from '@material-ui/icons/ListAlt';
import IconButton from '@material-ui/core/IconButton';
import { readFile } from './../../helpers/commonmethod'
import GetAppIcon from '@material-ui/icons/GetApp';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone'

function ImportDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [uploadedFileName, setuploadedFileName] = React.useState('');
  const [uploadedFileNameError, setuploadedFileNameError] = React.useState(false);
  const [error, setError] = React.useState<any>('');

  function checkLength(quantity: any) {
    return quantity.toString().length <= 5;
  }

  const fileUploadChange = useCallback((data: any) => {
    if (data && data.length && data.length > 0 && data[0]) {
      if (data[0] && data[0].name.toLowerCase() && (data[0].name.toLowerCase().includes('.xlsx') || data[0].name.toLowerCase().includes('.xls'))) {
        const readFileCallBack = (JSONData: any) => {
          if (JSONData && Array.isArray(JSONData) && JSONData.length > 0) {
            let array: any = [];
            JSONData.map((arr: any) => array.push(arr.quantity));
            let v = array.every(checkLength)
            if (v) {
              props.commonInsertionLogic(JSONData);
              setuploadedFileNameError(false);
              setError('');
              setuploadedFileName(data[0] && data[0].name)
            } else {
              setError('Quantity should be maximum 5 digits');
              setuploadedFileNameError(false);
              setuploadedFileName(data[0] && data[0].name)
            }
          } else {
            setuploadedFileNameError(true);
            setError('');
            setuploadedFileName(data[0] && data[0].name)
          }
        }
        readFile(data[0], readFileCallBack);
      } else {
        setError('Please upload excel file');
        setuploadedFileNameError(false);
        setuploadedFileName(data[0] && data[0].name)
      }
    }
    let uploader: any = document.getElementById('storesfileupload');
    if (uploader) uploader.value = "";
  }, []) // eslint-disable-line

  const onDrop = useCallback((acceptedFiles: any) => {
    fileUploadChange(acceptedFiles)
  }, [fileUploadChange])

  useEffect(() => {
    if (uploadedFileName && !uploadedFileNameError && error === '') {
      setOpen(false);
    }
  }, [uploadedFileName, uploadedFileNameError, error])

  useEffect(() => {
    if (!open) {
      setuploadedFileName('');
      setuploadedFileNameError(false);
      setError('');
    }
  }, [open])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <React.Fragment>
      <span className="cursor-pointer mr-2" onClick={(e) => {
        setuploadedFileName('');
        setuploadedFileNameError(false);
        e.preventDefault();
        setOpen(true);
        if (props.isOpened) props.isOpened()
      }}>
        <IconButton className="searchbarOptions" size="small">
          <ListAltIcon fontSize="inherit" />
          <span className="font-size-13 load-from-fav ml-1">Import Excel</span>
        </IconButton>
      </span>

      <Dialog fullWidth={true} maxWidth="sm" open={open ? true : false} onClose={() => { setOpen(false) }} aria-labelledby="form-dialog-title">
        <DialogContent>
          <div className="d-flex justifyContentHorizontalSpacebetween ">
            <h4 className="page-title">Import Excel</h4>
            <a href="./sampleexcel.xlsx" download="sampleexcel.xlsx">
              <span className="cursor-pointer searchbarOptions">
                <IconButton className="mr-1" size="small">
                  <GetAppIcon fontSize="inherit" />
                  <span className="font-size-13 load-from-fav ml-1">Download Sample Excel</span>
                </IconButton>
              </span>
            </a>
          </div>
          <div className="d-flex mt-3 py-4 dropzoneImport" {...getRootProps()}>
            <div className="d-flex col-lg-6 col-md-6 col-sm-12 justify-content-center align-items-center dashed-right-border">

              <div className="dragHereIcon justify-content-center">
                <CloudUploadIcon fontSize="large" />
              </div>
              <div className="dragHereText ml-2">Drag your excel sheet here</div>
              <input className="d-none" type="file" hidden id="productsfileupload"{...getInputProps()} />

            </div>
            <div className="d-flex col-lg-6 col-md-6 col-sm-12 justify-content-center align-items-center">
              <Button
                className="font-size-11 addToEnquiryButton"
                size="small"
                // onClick={() => {
                //   fileUploadAction()
                // }}
                variant="contained" color="primary">Browse Excel</Button>
            </div>
          </div>
          {uploadedFileName && !uploadedFileNameError && error === '' && <div className="d-flex font-weight-bold justify-content-center mt-2 text-success">{uploadedFileName} imported succesfully!</div>}
          {uploadedFileNameError && <div className="d-flex font-weight-bold justify-content-center mt-2 text-danger">{uploadedFileName} - Invalid data format</div>}
          {error && <div className="d-flex font-weight-bold justify-content-center mt-2 text-danger">{uploadedFileName} - {error}</div>}
        </DialogContent>
        <DialogActions className="mt-2 mb-4 justify-content-end">
          {/* <Button autoFocus className="import-button-disabled font-size-11 mr-1">Import</Button> */}
          <Button variant="contained" className="cancel-button font-size-11 mr-3" onClick={() => { setOpen(false) }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ImportDialog;
