import { SPECIAL_OFFERS } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  specialOffers: null,
  specialOfferDetail: null
};

function specialOffersReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case SPECIAL_OFFERS.GET_SPECIAL_OFFERS_FETCHING:
      return {
        ...state,
        specialOffers: { loading: true, data: null, err: null }
      };
    case SPECIAL_OFFERS.GET_SPECIAL_OFFERS_SUCCESS:
      return {
        ...state,
        specialOffers: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_OFFERS.GET_SPECIAL_OFFERS_ERROR:
      return {
        ...state,
        specialOffers: { loading: false, data: null, err: action.payload }
      };
      case SPECIAL_OFFERS.GET_SPECIAL_OFFER_DETAIL_FETCHING:
      return {
        ...state,
        specialOfferDetail: { loading: true, data: null, err: null }
      };
    case SPECIAL_OFFERS.GET_SPECIAL_OFFER_DETAIL_SUCCESS:
      return {
        ...state,
        specialOfferDetail: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_OFFERS.GET_SPECIAL_OFFER_DETAIL_ERROR:
      return {
        ...state,
        specialOfferDetail: { loading: false, data: null, err: action.payload }
      };
    default:
      return state;
  }
};

export default specialOffersReducer;
