import * as React from "react";
import "react-dates/initialize";
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

function DatePicker(props: any): JSX.Element {
  const [focusedInput, setFocusedInput] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const onChange = (start: any, end: any) => {
    setStartDate(start);
    setEndDate(end);
    props.onChange(start, end)
  }

  const onFocusChange = (focus: any) => {
    setFocusedInput(focus)
  }

  return (
    <DateRangePicker
      displayFormat="DD/MM/yyyy"
      startDate={startDate}
      isOutsideRange={() => false}
      startDateId="startDate"
      startDatePlaceholderText="From Date"
      endDatePlaceholderText="To Date"
      endDate={endDate}
      endDateId="endDate"
      showClearDates
      onDatesChange={({ startDate, endDate }) => onChange(startDate, endDate)}
      focusedInput={focusedInput}
      onFocusChange={focusedInput => onFocusChange(focusedInput)}
    />
  );
}

export default DatePicker;
