import React, { Fragment, useEffect, useState, Dispatch, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { get } from 'lodash';
import './Profile.css'
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { getAssignedKAMs } from "../../store/actions/assignedKAM.action";
import AnnouncementIcon from '../../common/components/AnnouncementIcon';
import { getProfile, updateProfile } from "../../store/actions/profile.action";
import Snackbar from './../../common/components/Snackbar';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { b2cPolicies, msalConfig } from "../../authConfig";
import { API_ENDPOINTS } from '../../constants/apiConfig';
import NotificationIcon from '../../common/components/NotificationIcon';
import { Messages } from '../../constants/messages';
import { getNewBrand } from '../../common/components/util';

const Profile: React.FC = () => {

    const dispatch: Dispatch<any> = useDispatch();
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const [name, setName] = React.useState<string>("");
    const [lastname, setLastName] = React.useState<string>("");
    const [mobileNumber, setMobileNumber] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [username, setUsername] = React.useState<string>("");
    const [userType, setUserType] = React.useState<string>("");
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [apiResponseText, setApiResponseText] = React.useState(false);
    const [assignedKAMs, setAssignedKAMs] = React.useState<any>([]);

    // ======================= Need to change the same for QA========
    // ==============================================================
    // https://alfuttaimb2c.b2clogin.com/alfuttaimb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_app_fpass&client_id=9a648910-3844-4d5a-8cca-61479ca664dd&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms&scope=openid&response_type=id_token&prompt=login

    const redirect_uri = encodeURIComponent(`${API_ENDPOINTS.ON_BOARDING_URL}/login`);//encodeURIComponent('https://localhost:3005/onboard')

    let changePassword = `https://${b2cPolicies.authorityDomain}/${b2cPolicies.tenantUrl}/oauth2/v2.0/authorize?p=${b2cPolicies.names.forgotPassword}&client_id=${msalConfig.auth.clientId}&nonce=defaultNonce&redirect_uri=${redirect_uri}&scope=openid&response_type=id_token&prompt=login`
    const populateProfileData = useCallback(() => {
        const profile = get(profileDetails, 'data.data', {});
        profile && profile.basicInfo && profile.basicInfo.firstName ? setName(profile.basicInfo.firstName) : setName("");
        profile && profile.basicInfo && profile.basicInfo.lastName ? setLastName(profile.basicInfo.lastName) : setLastName("");
        profile && profile.basicInfo && profile.basicInfo.firstTelephoneNumber ? setMobileNumber(profile.basicInfo.firstTelephoneNumber) : setMobileNumber("");
        profile && profile.basicInfo && profile.basicInfo.email ? setEmail(profile.basicInfo.email) : setEmail("");
        profile && profile.basicInfo && profile.basicInfo.b2cUserId ? setUsername(profile.basicInfo.b2cUserId) : setUsername("");
        profile && profile.basicInfo && profile.basicInfo.userType ? setUserType(profile.basicInfo.userType) : setUserType("");
    }, [profileDetails])

    useEffect(() => {
        dispatch(getAssignedKAMs());
        const b2cUid = sessionStorage.getItem('userId');

        dispatch(getProfile(b2cUid, "dealerUser"));

        populateProfileData();
        setAssignedKAMs(get(profileDetails, 'data.data.basicInfo.assignedKamAndFse', []));
    }, []); // eslint-disable-line

    const [mobileError, setMobileError] = useState(false);
    const OnMobileChange = (e: any) => {
        setMobileNumber(e.target.value);
        if (/^[5]\d{8}$/.test(e.target.value))
            setMobileError(false);
        else
            setMobileError(true);
    };

    const UpdateProfile = async (e: any) => {
        e.preventDefault();
        let hasError = false;
        if (mobileError || !(/^[5]\d{8}$/.test(mobileNumber))) {
            setMobileError(true);
            hasError = true;
        }
        if ((name.trim() === '') || (lastname.trim() === '')) {
            hasError = true;
        }

        if (!hasError) {

            let data = {
                "id": username.trim(),
                "firstName": name.trim(),
                "lastName": lastname.trim(),
                "mobileNumber": mobileNumber.trim(),
                "email": email.trim(),
                "userType": userType === 'guestAdmin' ? "guestuser" : "dealeruser"
            }
            let result = await dispatch(updateProfile(data));
            const apiResponse = get(result, 'message', {});
            if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
                setOpenSnackbar(true);
                setApiResponseText(apiResponse.message);
                const b2cUid = sessionStorage.getItem('userId');
                dispatch(getProfile(b2cUid, "dealerUser"))
                setTimeout(() => {
                    setOpenSnackbar(false);
                }, 2000);
            }
        }
    };

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    return (
        <Fragment>
            {profileDetails.loading && <FullscreenLoader />}
            <div className="d-flex align-items-center">
                <h2 className="page-title">
                    My Profile</h2>
                <div className="ml-auto">
                    {userType === 'guestAdmin' ? "" : <AnnouncementIcon />}
                    <NotificationIcon />
                </div>
            </div>
            <div className="row mt-4">
                <div className={userType !== 'guestAdmin' ? "col-lg-8 col-sm-12 mb-2" : "col-lg-12 col-sm-12 mb-2"}>
                    <h5 className="page-sub-title mb-2">Your Information</h5>
                    <div className="mt-1 cardCommon p-3">
                        <div className="d-flex align-items-center">
                            {/* <h6 className="ml-auto font-12 text-muted">To add a new address, please contact your KAM</h6> */}
                        </div>
                        <div className="col-md-7 col-sm-12">

                            <div className="mt-2 ">
                                <TextField required error={(name === '' || name.trim() === '') ? true : false} value={name} onChange={(e) => { setName(e.target.value) }} className="full-width rounded-input" size="small" id="name" label="First Name" variant="outlined" margin="dense" />
                            </div>
                            <div className="mt-2 ">
                                <TextField required error={(lastname === '' || lastname.trim() === '') ? true : false} value={lastname} onChange={(e) => { setLastName(e.target.value) }} className="full-width rounded-input" size="small" id="lastName" label="Last Name" variant="outlined" margin="dense" />
                            </div>
                            <div className="mt-2 ">
                                <TextField error={mobileError} placeholder={Messages.MOBILE_PLACEHOLDER_TEXT} helperText={mobileError && "It should start with 5 and contains 9 digits"} onChange={OnMobileChange} value={mobileNumber} type="tel" className="full-width rounded-input" id="mobile" label="Mobile" size="small" variant="outlined" margin="dense" />
                            </div>
                            <div className="mt-2 ">
                                <TextField value={email} type="email" className="full-width rounded-input" id="email" label="Email" variant="outlined" margin="dense" size="small" disabled />
                            </div>
                            <div className="mt-2 ">
                                <TextField value={username} className="full-width rounded-input" id="username" label="Username" variant="outlined" margin="dense" size="small" disabled />
                            </div>
                            <div className="mt-2 text-right">
                                <Button onClick={UpdateProfile} variant="contained" className="rounded-button font-size-11"> Update </Button>
                            </div>
                            <div className="mt-4 mb-4">
                                <p className="mb-2 text-muted">Want to change your password ? Click below link to change password.</p>
                                <Link href={changePassword} className="changePassword" >
                                    Change Password
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {userType !== 'guestAdmin' && (
                    <div className="col-lg-4 col-sm-12">
                        <h5 className="page-sub-title  mb-2">Assigned KAM &amp; FSE</h5>
                        <div className="cardCommon p-3">
                            {assignedKAMs && assignedKAMs.map((item: any, mainIndex: any) =>
                                <div key={mainIndex}>
                                    <div className="brand-name pb-1">{getNewBrand(item.make)}</div>
                                    <div className="d-flex font-13-bold pt-1 pb-1">
                                        <div className="col-2 p-0">KAM</div>
                                        <div className="col-1">-</div>
                                        {item.kamUser === "" ? 'Error fetching correct data.' :
                                            <div className="col-9 p-0">{item.kamUser}</div>
                                        }
                                    </div>
                                    <div className="d-flex font-13-bold pt-1 pb-1">
                                        <div className="col-2 p-0">FSE</div>
                                        <div className="col-1">-</div>
                                        {item.fseUser === "" ? 'Error fetching correct data.' :
                                            <div className="col-9 p-0">{item.fseUser}</div>
                                        }
                                    </div>
                                    {mainIndex < assignedKAMs.length - 1 && <Divider className="mt-2 mb-2" />}
                                </div>
                            )}

                        </div>
                    </div>
                )}
                <Snackbar
                    open={openSnackbar}
                    handleopenClose={() => {
                        handleopenCloseSnackbar(false)
                    }} text={apiResponseText} />
            </div>
        </Fragment>
    )
}

export default Profile
