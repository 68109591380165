export const ADD_PARAMS_BRANDS = {
    NO_DETAILS: "Please fill the details for atleast one brand below.",
    PLEASE_COMPLETE: "Please complete the details for the following brand(s): ",
    OTHER_PANELS_EMPTY: "If you do not wish to proceed with more than one brand, please leave the other panels empty."
};

export const Messages = {
    MOBILE_PLACEHOLDER_TEXT: "Mobile number to begin with 5",
    LANDLINE_PLACEHOLDER_TEXT: "Landline number should not start with 0"
}

export const ValidationErrorMessage = {
    LANDLINE_TEXT_START: "It cannot start with 0",
    LANDLINE_TEXT_END: "It should not exceed 20 characters.",
    COMPANY_NAME_TEXT: "Special Characters not allowed and Should not exceed 250 characters.",
    SPECIAL_CHARACTERS: "Special Characters not allowed",
    LENGTH: "Should not exceed 250 characters.",
    ANNUAL_TURNOVER_TEXT: "Should not exceed 20 digits",
    TRADE_LICENSE_TEXT: "Should not exceed 55 digits",
    OWNER_FIRST_NAME_TEXT: "Should not exceed 35 characters.",
    OWNER_LAST_NAME_TEXT: "Should not exceed 35 characters.",
    ADMIN_FIRST_NAME_TEXT: "Should not exceed 35 characters.",
    ADMIN_LAST_NAME_TEXT: "Should not exceed 35 characters.",
    EMAIL_TEXT: "Invalid email.",
    MOBILE_TEXT: "It should start with 0 and contain 9 digits.",
    TAX_REGISTRATION_TEXT: "It should contain 15 digits",
    COMPANY_BANK_NAME_TEXT: "Should not exceed 100 characters.",
    COMPANY_IBAN_TEXT: "First 2 characters should be alphabets followed by numbers - with a total 23 characters.",
    PHONE_TWO_TEXT: "It should not exceed 9 characters.",
    FAX_TEXT: "It should not exceed 9 characters.",
    VAT_TEXT: "It should not exceed 15 digits",
    LANDLINE_PHONE_TEXT: "Only numbers are allowed and It cannot start with 0 and should be 8 characters long.",
    ALPHA_NUMERIC_TEXT: "Special characters are not allowed.",
    POBOX_TEXT: "Upto 7 digits are allowed",
    CONTACT_NUMBER: "Upto 10 digits are allowed"
}

export const KamReviewValidationMessages={
    SIZE:"It should not exceed 50 characters.",
    STOCK:"It should not exceed 50 characters.",
    NO_OF_STAFF:"It should not exceed 100 characters.",
    SALES_SINCE:"It should not exceed 100 characters.",
    SALES_TO_CUSTOMER:"It should not exceed 100 characters.",
    GROWTH_PLAN:"It should not exceed 500 characters.",
    PAYMENT_HISTORY:"It should not exceed 500 characters.",
    SPECIAL_CHARACTERS:"Special characters are not allowed."


}

export const OnboardingAuthorisedSignatoriesValidation = {
    ADMIN_FIRST_NAME_TEXT: "Special characters are not allowed.",
    ADMIN_LAST_NAME_TEXT: "Special characters are not allowed.",
    ADMIN_LAST_DESIGNATION: "Special characters are not allowed.",
    ADMIN_LAST_NATIONALITY: "Special characters are not allowed.",
    EMAIL_TEXT: "Invalid email.",
    MOBILE_TEXT: "It should contains 10 digits.",
    EMARITESIDNO_TEXT: "It should be xxx-xxxx-xxxxxxx-x in this format.",
    EMARITESIDNO_ERROR_TEXT: "It should be in mentioned format (xxx-xxxx-xxxxxxx-x).",
    ALPHA_NUMERIC_TEXT: "Special characters are not allowed.",
    ALPHA_NUMERIC_LIMIT_TEXT: "Special characters are not allowed.",
    POBOX_TEXT: "Upto 7 digits are allowed"
}

export const OnboardingAddOwnerPartnerValidation = {
    OWNER_PARTNER_FIRST_NAME_TEXT: "Special characters are not allowed.",
    OWNER_PARTNER_LAST_NAME_TEXT: "Special characters are not allowed.",
    OWNER_PARTNER_LAST_DESIGNATION: "Special characters are not allowed.",
    OWNER_PARTNER_LAST_NATIONALITY: "Special characters are not allowed.",
    OWNER_PARTNER_EMAIL_TEXT: "Invalid email.",
    OWNER_PARTNER_MOBILE_TEXT: "It should contains 10 digits.",
    OWNER_PARTNER_EMARITESIDNO_TEXT: "It should be xxx-xxxx-xxxxxxx-x in this format.",
    OWNER_PARTNER_EMARITESIDNO_ERROR_TEXT: "It should be in mentioned format (xxx-xxxx-xxxxxxx-x).",
    OWNER_PARTNER_ALPHA_NUMERIC_TEXT: "Only numbers and characters with space allowed",
    OWNER_PARTNER_ALPHA_NUMERIC_LIMIT_TEXT: "Only numbers and characters with space allowed.",
    OWNER_PARTNER_POBOX_TEXT: "Upto 7 digits are allowed"
}

export const OnboardingEmailVarificationValidation = {
    EMAIL_VALIDATION_TEXT: "Only 6 digits are allowed."
}

export const kamAdditionalCreditRequestValidation = {
    EXISTING_CREDIT: "It should contains 5 digits.",
    ADDITIONAL_CREDIT:"It should contains 5 digits."
    // ALPHA_NUMERIC_TEXT: "Special characters are not allowed.",
}
