import { Divider, MenuItem, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useState, Dispatch, useEffect, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { editSuperDealer } from '../../store/actions/superAdmin.actions';
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import { Prompt } from "react-router-dom"
import FullscreenLoader from '../../common/components/FullscreenLoader';
import ActionButtonsSuperAdmin from './ActionButtonsSuperAdmin';

const EditDealer = (props: any) => {

  const [excel, setExcel] = useState<boolean>()
  const [customerTier, setCustomerTier] = useState<string>("");
  const [customerTierKey, setCustomerTierKey] = useState<string>('');
  const [paymentMode, setPaymentMode] = useState<any>([]);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [openErrorSnackbar, setOpenErrorSnackbar] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [dataUpdated, setDataUpdated] = useState<boolean>(false);

  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory()

  const { paymentModeList }: any = useSelector((state: any) => state.onboard);
  const { getCustomerDetails, tierList }: any = useSelector((state: any) => state.superAdmin);

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  useEffect(() => {
    setCustomerTier('');
    const data = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data;
    const paymentModes = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.paymentMode || []
    setPaymentMode(paymentModes);
    if (data && data.customerTierInfo && data.customerTierInfo.isTierFound && data.customerTierInfo.customerType === 'dealer') {
      setCustomerTier(data.customerTierInfo.currentTier);
    }
    setExcel(data?.manageExportsToExcel);
  }, [getCustomerDetails])

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#1A9FE0'
      },
    }
  });

  const onChangeTier = (e: any) => {
    setCustomerTier(e.target.value);
    if (tierList && tierList.data && tierList.data.length > 0) {
      let data = tierList.data.filter((item: any) => item.value === e.target.value);
      if (data && data.length > 0) {
        setCustomerTierKey(data[0].key);
      }
    }
  }

  const handleExcel = (e: any) => {
    setDataUpdated(true)
    setExcel(e.target.value === "true" ? true : false)
  }

  const onChangePaymentMode = (checked: any, code: any) => {
    setDataUpdated(true);
    let payment = [...paymentMode]
    if (checked) {
      payment.push(code);
      setPaymentMode(payment);
    }
    else {
      payment = payment.filter(e => e !== code);
      setPaymentMode(payment);
    }
  }

  const handleSubmit = async (e: any) => {
    setLoading(true);
    const id = getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data.sapCustomerId
    const data = {
      "paymentMode": paymentMode,
      "b2bUserId": getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data && getCustomerDetails.data.data.b2bUserId,
      "manageExportsToExcel": excel,
      "customerTier": customerTierKey
    }
    const update: any = await dispatch(editSuperDealer(data, id))
    if (update && update.message && update.message.status === "S") {
      setLoading(false);
      setDataUpdated(false);
      setOpenSnackbar(true)
      setSuccessMessage(update && update.message && update.message.message);
      setOpenErrorSnackbar(false)
    } else {
      if (update && update.error && Array.isArray(update.error)) {
        setSuccessMessage(update.error[0].message);
      } else if (update && update.error && typeof update.error === 'string') {
        setSuccessMessage(update.error);
      } else if (update && update.error && typeof update.error === 'object' && update.error.message) {
        setSuccessMessage(update.error.message);
      }
      setOpenSnackbar(true)
      setLoading(false);
      setOpenErrorSnackbar(true)
    }
  }

  return (
    <>
      {loading || (getCustomerDetails && getCustomerDetails.loading) || (paymentModeList && paymentModeList.loading) && <FullscreenLoader />}
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="d-flex flex-row">
          <h5 className="page-sub-title text-bold font-20 edit-font fontweight-superadmin-header mb-2">Edit Dealer Details  </h5>
          <div className="font-12 mt-3 pl-2 edit-font fontweight-superadmin">({getCustomerDetails && getCustomerDetails.data && getCustomerDetails.data.data && getCustomerDetails.data.data && getCustomerDetails.data.data.companyName})</div>
        </div>
        <div className="pb-2">
          <Button
            className="float-right  col-2 col-sm-2 col-md-2 col-lg-1 font-size-5 pl-0 addOrderEnqButton mb-4"
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/superadmindealers")
            }}
          >Back
          </Button>
        </div>
        <div className="cardCommon mt-5">
          <form>
            <div className=" font-13 pt-1 mb-3 pl-4 p-3">
              <div className="col-2 p-0 edit-font fontweight-superadmin mt-2">Payment Mode</div>
              <div className="flex row-space-start ml-auto mt-4">
                <div>
                  <div className="mb-2">
                  </div>
                  {paymentModeList && paymentModeList.data && paymentModeList.data.length > 0
                    && paymentModeList.data[0] && paymentModeList.data[0].paymentMode &&
                    paymentModeList.data[0].paymentMode.length > 0 &&
                    paymentModeList.data[0].paymentMode?.map((item: any, i: number) => (
                      (<>
                        <ThemeProvider theme={theme} key={i}>
                          <Checkbox className="checkbox" checked={paymentMode.includes(item.code)} disabled={item.code === 'OFFLINE'} name="bank" onChange={(e) => { onChangePaymentMode(e.target.checked, item.code); }} />
                        </ThemeProvider>
                        <span className=" mr-3 edit-font">{item.label}</span>
                      </>
                      )
                    ))}
                </div>
              </div>
            </div>
            <Divider className="" />
            <div className="font-13 pt-1 pb-1 p-3 pl-4 mt-2 mb-3">
              <div className="col-lg-2 col-12 p-0 edit-font fontweight-superadmin mt-2">Manage Exports to excel</div>
              <div className="flex row-space-start mt-4">
                <div className='d-flex flex-row'>
                  <input type="radio" className='mt-1' name="excel" value="true" checked={excel == true} onChange={handleExcel} style={{ "height": "1.3em", "width": "1.3em" }} />
                  <label className='mb-0 mt-0 pt-0 ml-1 edit-font pt-1 pl-1' style={{ fontSize: "0.8215rem" }}>Yes</label><br></br>
                </div>
                <div className="ml-5 ">
                  <div className='d-flex flex-row pl-5 ml-1'>
                    <input type="radio" className='mt-1' name="excel" value="false" checked={excel == false} onChange={handleExcel} style={{ "height": "1.3em", "width": "1.3em" }} />
                    <span className='mb-0 mt-0 pt-0 ml-1 edit-font pt-1 pl-1' style={{ fontSize: "0.8215rem" }}>No</span>
                  </div>
                </div>
              </div>
            </div>
            <Divider className="" />
            <div className="font-13 pt-1 pb-1 p-3 pl-4 mb-2">
              <div className="col-2 p-0 edit-font fontweight-superadmin mt-2">Customer Tier</div>
              <div className="flex row-space-start mt-4 col-4">
                <TextField select id="customerTier"
                  value={customerTier}
                  onChange={(e: any) => onChangeTier(e)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Select Customer Tier" variant="outlined" margin="dense" >
                  {tierList && tierList.data && tierList.data.length > 0 &&
                    tierList.data.map((item: any) => (
                      <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>))}
                </TextField>
                {/* <div className='d-flex flex-row mb-2'>
                  <input type="radio" name="customer" className='mt-1' value="Workshop" checked={customer == "Workshop"} onChange={handleCustomer} style={{ "height": "1.3em", "width": "1.3em" }} />
                  <span className='mb-0 mt-0 pt-0 ml-1  edit-font pt-1 pl-1' style={{ fontSize: "0.8215rem" }}>Workshop</span><br></br>
                </div>q adx
                <div className="ml-5 mb-2">
                  <div className='d-flex flex-row'>
                    <input type="radio" name="customer" value="Dealer" checked={customer == "Dealer"} onChange={handleCustomer} className='mt-1' style={{ "height": "1.3em", "width": "1.3em" }} />
                    <label className='mb-0 mt-0 pt-0 ml-1  edit-font pt-1 pl-1' style={{ fontSize: "0.8215rem" }}>Dealer</label>
                  </div>
                </div> */}
              </div>
              {/* <Button
                className="col-6 px-1 mt-5  font-update-superadmin col-sm-6 col-md-6 col-lg-1 font-size-17  pl-0 addOrderEnqButton mt-5 mb-5"
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >Update
              </Button> */}
              {/* <Button
                className="mt-5  col-2 col-sm-2 col-md-2 col-lg-1 font-size-5 pl-0 addOrderEnqButton mb-4"
                size="small"
                variant="contained"
                color="primary"
                type="submit"
              >Update
              </Button> */}
            </div>
          </form>
        </div>
      </div>
      <ReturnRequestSnackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }}
        message={successMessage} />
      <ActionButtonsSuperAdmin
        openRequestSnackbar={true}
        deactiveShow={true}
        handleSubmit={handleSubmit}
      />
      <Prompt when={dataUpdated} message={"Do you wish to proceed without saving the data?"}></Prompt>
    </>
  )
}

export default EditDealer

