import { API_ENDPOINTS, ORDER_ENQUIRY, CREATE_UPDATE_ENQUIRY } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const orderHistoryService = {
    getOrdersList,
    getOrdersDetails,
    returnRequest,
    getInvoiceDetails,
    getOrderStatus,
    getPartList
};

async function getOrdersList(filterParams: any, page?: any) {
    const apiPath = API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH;
    const options = {
        path: `${apiPath}/customers/${sessionStorage.getItem('customerId')}/orders`,
        method: "POST",
        bodyObj: {
            "sessionDetails": {
                "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
                "clientIPAddress": sessionStorage.getItem('clientIp'),
                "loginPassword": "",
                "applicationSecurityKey": "",
                "initialLogonIndicator": "",
                "initialPassword": "",
                "username": "",
                "password": ""
            },
            "searchParams": filterParams
        },
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getPartList(partnumber: any, brand: any) {


    const apiPath = API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH;
    const options = {
        path: `${apiPath}/getSuperSessionDetails/${brand}/${partnumber}`,
        method: "GET",
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getOrdersDetails(id: any, backOrder?: any) {
    const apiPath = API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH + CREATE_UPDATE_ENQUIRY.ORDERS + `/${id}?backOrder=${backOrder ? 'true' : 'false'}`;
    const options = {
        path: `${apiPath}`,
        method: "GET",
        customHeaders: {
            "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getInvoiceDetails(data: any) {
    const apiPath = API_ENDPOINTS.DELIVERY + ORDER_ENQUIRY.INVOICE + `?enquiryNumber=${data.enquiryNumber}&orderNumber=${data.orderNumber}&make=${data.make}&webApplicationLoginId=${data.webApplicationLoginId}`;
    const options = {
        path: `${apiPath}`,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function returnRequest(data: any) {
    const apiPath = API_ENDPOINTS.RETURN_REQUEST;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {

        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getOrderStatus() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ORDER_ENQUIRY.FORMFIELD + '/orderStatus';
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}
