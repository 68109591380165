import React, { Fragment, Dispatch, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getAllDealers, getAllDealersRequests, getAllUsers, getDealerDetails, getDealerCreditRequest, getkamUserList, isEdit, selectedNewDealer, viewCreditDealer } from "../../store/actions/dealer.action";
import "./Dealers.css";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
import Snackbar from './../../common/components/Snackbar';
import AddDealerDialog from './AddDealerDialog';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import DealerDetailsDialog from "./DealerDetailsDialog";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import TablePagination from '@material-ui/core/TablePagination';
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { ButtonGroup, Tab, Tabs } from "@material-ui/core";
import defaultLogo from '../../assets/default-user-logo.png';
import { getNewBrand } from "../../common/components/util";
import { get } from "lodash";
import { userTypes } from "../../constants/userTypes";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Visibility from "@material-ui/icons/Visibility";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Dialog from '@material-ui/core/Dialog';
import RetrieveBrandsDialog from "./RetrieveBrandsDialog";
import { AddNewDealer } from "./newAddDealerDialog";
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from "@material-ui/core/Popover";
import { getCustomerDealerDetails } from "../../store/actions/superAdmin.actions";
import { EmojiEventsOutlined } from "@material-ui/icons";
import { getPurChaseHistoryByYear } from "../../store/actions/achievement.actions";


const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  actionButton: {
    color: '#1A9FE0',
  },

}))

const Dealers: React.FC = () => {
  const [dealerDetailTab, setDealerDetailTab] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const dispatch: Dispatch<any> = useDispatch();
  const { allDealers, kamUserList, dealerRequests }: any = useSelector((state: any) => state.dealer);
  const [openDealerDetailDialog, setOpenDealerDetailDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openAddDealer, setOpenAddDealer] = React.useState(false);
  const [openNewAddDealerPopup, setOpenNewAddDealerPopup] = React.useState(false);
  const [isDialogForEdit, setIsDialogForEdit] = React.useState(false);
  const [kamUserId, setKamUserId] = React.useState<any>(sessionStorage.getItem('webApplicationLoginId'));
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [filterValue, setFilterValue] = React.useState("");
  const [filterValueForNewDealerReq, setFilterValueForNewDealerReq] = React.useState("all");
  const [selectedStatusByKAM, setSelectedStatusByKAM] = React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedStatusFilterByKAM, setSelectedStatusFilterByKAM] = React.useState<any>([
    {
      name: "All",
      code: "all"
    },
    {
      name: "Assigned to me",
      code: "assignedToMe"
    },
    {
      name: "Added by me",
      code: "addedByMe"
    },
  ]);
  const [selectedTab, setselectedTab] = React.useState(0);
  const history = useHistory();
  const classes = useStyles();
  // let adminDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.userDetails) ? dealerDetails.data.data.userDetails : null;
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { changeCustomerTypeData }: any = useSelector((state: any) => state.onboard);
  //const webApplicationLoginId = adminDetails && adminDetails.webApplicationLoginId;
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId') || '';
  const userType = get(profileDetails, 'data.data.basicInfo.userType', '');
  const [open, setOpen] = React.useState(false);
  const [openCustomerDialog, setOpenCustomerDialog] = React.useState(false);
  const [showOnboard, showOnboarding] = React.useState(false);
  const [item, setItem] = React.useState<any>()


  const verifySuccess = () => {
    showOnboarding(true);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleClose = () => {
  //     // setselectedOrder(null)
  // };

  useEffect(() => {
    dispatch(getAllDealers(kamUserId));
    dispatch(getAllDealersRequests(webApplicationLoginId, searchValue, 'all'));
    dispatch(getkamUserList());
    // setOpenCustomerDialog(false)
  }, []);

  // useEffect(() => {
  //   if (changeCustomerTypeData && changeCustomerTypeData.data && changeCustomerTypeData.data.message && changeCustomerTypeData.data.message.status === 'S') {
  //     addDealerOpen(true);
  //   }
  // }, [changeCustomerTypeData])

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseAdddealer = (open?: any) => {
    setOpenAddDealer(open ? true : false);
  };

  const addDealerOpen = (open: any) => {
    setOpenCustomerDialog(true)
    setOpenNewAddDealerPopup(true)
  }

  const ITEM_HEIGHT = 48;

  const filterDealersByKAM = (e: any) => {
    setPage(0);
    if (e.target.value === "0") {
      setKamUserId(e.target.value);
      if (searchValue.trim() !== "")
        dispatch(getAllDealers("", searchValue.trim(), 0));
      else
        dispatch(getAllDealers());
    }
    else {
      setKamUserId(e.target.value);
      let searchFilter = e.target.value ? e.target.value : "";
      if (e.target.value && e.target.value.trim() !== "") {
        if (searchValue.trim() === "")
          dispatch(getAllDealers(searchFilter.trim(), "", 0));
        else
          dispatch(getAllDealers(searchFilter.trim(), searchValue.trim(), 0));
      }
    }
  }

  // FILTER FOR NEW DEALER REQUEST - FOR KAM
  const filterNewDealerReq = (e: any) => {
    setPage(0);
    setFilterValueForNewDealerReq(e.target.value);
    let searchFilter = e.target.value ? e.target.value : "";
    if (e.target.value && e.target.value.trim() !== "") {
      if (searchValue.trim() === "")
        dispatch(getAllDealersRequests(webApplicationLoginId, "", searchFilter, 0));
      else
        dispatch(getAllDealersRequests(webApplicationLoginId, searchValue, searchFilter, 0));
    }
  }

  const searchDealerList = (e: any, isSearch: any) => {
    if (selectedTab == 0) {
      if (searchValue.trim() === "") {
        if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
          dispatch(getAllDealers(kamUserId.trim(), "", isSearch ? 0 : page));
        else
          dispatch(getAllDealers());
      } else {
        if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
          dispatch(getAllDealers(kamUserId, searchValue.trim(), isSearch ? 0 : page));
        else
          dispatch(getAllDealers("", searchValue.trim(), isSearch ? 0 : page));
      }
    } else {
      dispatch(getAllDealersRequests(webApplicationLoginId, searchValue.trim(), filterValueForNewDealerReq, 0));
    }
  }

  const handleChangePage = (newPage: number) => {
    if (selectedTab == 0 && kamUserId.trim() !== "0") {
      if (searchValue.trim() === "" && filterValue.trim() === "") {
        dispatch(getAllDealers(kamUserId, "", newPage));
      }
      else if (searchValue.trim() === "" && filterValue.trim() !== "") {
        dispatch(getAllDealers(kamUserId, "", newPage));
      }
      else {
        dispatch(getAllDealers(kamUserId, searchValue.trim(), newPage));
      }
    } else if (selectedTab == 0 && kamUserId.trim() === "0") {
      dispatch(getAllDealers('', searchValue.trim(), newPage));
    } else {
      dispatch(getAllDealersRequests(webApplicationLoginId, searchValue.trim(), filterValueForNewDealerReq, newPage));
    }
    setPage(newPage);
  };

  const id = open ? "simple-popover" : undefined

  return (
    <Fragment>
      {allDealers && allDealers.loading && <FullscreenLoader />}
      {dealerRequests && dealerRequests.loading && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Dealers</h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <Grid container className="">
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} text="Dealer added successfully!" />
        <AddDealerDialog
          open={openAddDealer}
          isEdit={isDialogForEdit}
          handleopenClose={(data: any) => {
            if (data) {
              handleopenCloseSnackbar(true)
            }
            handleopenCloseAdddealer(false)
          }}
        />
        {openDealerDetailDialog &&
          <DealerDetailsDialog selectedTabValue={dealerDetailTab} open={openDealerDetailDialog} onClose={(value: boolean) => setOpenDealerDetailDialog(value)} />
        }
        <Grid item xs={12} sm={4}>
          <ThemeProvider theme={theme}>
            <Paper square className="bg-transparent">
              <Tabs
                className="tabSelect"
                value={selectedTab}
                textColor="primary"
                indicatorColor="primary"
                onChange={(event, newValue) => {
                  setPage(0);
                  setselectedTab(newValue);
                  setSearchValue('');
                  if (newValue == 0) {
                    if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
                      dispatch(getAllDealers(kamUserId));
                    else
                      dispatch(getAllDealers());
                  } else {
                    dispatch(getAllDealersRequests(webApplicationLoginId, '', 'all', page));
                  }
                }}
                aria-label="disabled tabs example">
                <Tab label="Dealer List" value={0} {...a11yProps('DealerList')} />
                <Tab label="New Dealer Req." value={1} {...a11yProps('NewDealerReq.')} />
              </Tabs>
            </Paper>
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} sm={8}>
          <div className="float-right">
            {selectedTab == 0 && <TextField
              id="kamList"
              select
              label="Filter By KAM"
              variant="outlined"
              className="rounded-input-dealer mw-200p mr-2 mb-2"
              size="small"
              onChange={(e: any) => { setPage(0); filterDealersByKAM(e); setFilterValue('') }}
              value={kamUserId}
            >
              {kamUserList && kamUserList.data && kamUserList.data.data && kamUserList.data.data.length > 0 && kamUserList.data.data.map((item: any, index: any) => (
                <MenuItem key={index} value={item.webApplicationLoginId}>
                  {item.displayName}
                </MenuItem>
              ))}
              <MenuItem value={"0"}>
                Show All
              </MenuItem>
            </TextField>}
            {selectedTab == 1 && <TextField
              id="filterValueForNewDealerReq"
              select
              label="Filter By KAM"
              variant="outlined"
              className="rounded-input-dealer mw-200p mr-2 mb-2"
              size="small"
              onChange={(e: any) => { setPage(0); filterNewDealerReq(e); }}
              value={filterValueForNewDealerReq}
            >
              {selectedStatusFilterByKAM && selectedStatusFilterByKAM && selectedStatusFilterByKAM.length > 0 && selectedStatusFilterByKAM.map((name: any) => (
                <MenuItem key={name.code} value={name.code}>{name.name} </MenuItem>))}
            </TextField>}
            <FormControl variant="outlined">
              <InputLabel htmlFor="dealer-search" className="pl-2" margin="dense">Search</InputLabel>
              <OutlinedInput
                id="dealer-search"
                value={searchValue}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    searchDealerList(searchValue, true);
                    setPage(0);
                  }
                }}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                  if (e.target.value.trim() === "") {
                    if (selectedTab == 0) {
                      if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
                        dispatch(getAllDealers(kamUserId));
                      else
                        dispatch(getAllDealers());
                    } else {
                      dispatch(getAllDealersRequests(webApplicationLoginId, e.target.value, filterValueForNewDealerReq, page));
                    }
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Search"
                      edge="end"
                      onClick={(e: any) => {
                        searchDealerList(e, true);
                        setPage(0);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={50} margin="dense" className="rounded-input-dealer mw-200p mr-2 mb-2" />
            </FormControl>
            {/* <div className="col-md-8 bg-white pb-0 pl-lg-10 mt-3"> */}
            <Button
              variant="contained"
              color="secondary"
              className="rounded-button-dealer pt-2 pb-2"
              startIcon={<AddIcon />}
              onClick={() => {
                addDealerOpen(true);
              }}
            >
              Add Dealer
            </Button>
            {/* <Button variant="contained" color="primary"
              className="rounded-pill ml-3 w-200p float-right"
              // disabled={isDisabled()}
              onClick={() => {
              }}> Add Dealer
            </Button> */}
            {/* </div> */}
            {/* {selectedTab == 0 && userType !== userTypes.fse &&
              <Button
                variant="contained"
                color="secondary"
                className="rounded-button-dealer pt-2 pb-2"
                startIcon={<AddIcon />}
                onClick={() => {
                  setIsDialogForEdit(false);
                  handleopenCloseAdddealer(true);
                }}
              >
                Add Dealer
              </Button>
            } */}
          </div>
        </Grid>
        {selectedTab == 0 ? <Grid item xs={12} className="mt-2 cardCommon">
          <TableContainer component={Paper} className="height-500 mb-5 pb-5">
            <Table aria-label="dealer table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="dealer-table-title">Dealer</div>
                    <div className="dealer-table-title-desc">Logo, Name &amp; Trade License No</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Address</div>
                    <div className="dealer-table-title-desc">Region &amp; Address</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Contact</div>
                    <div className="dealer-table-title-desc">Telephone &amp; Email</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Assigned</div>
                    <div className="dealer-table-title-desc">KAM &amp; FSE</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">Brands</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">SapCustomerId</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">Type</div>
                  </TableCell>
                  <TableCell><div className="dealer-table-title mb-3">Status</div></TableCell>
                  <TableCell ><div className="dealer-table-title mb-3">Action</div></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allDealers && allDealers.data && allDealers.data.data && allDealers.data.data.data && allDealers.data.data.data.customerList && allDealers.data.data.data.customerList.length > 0 &&
                  allDealers.data.data.data.customerList.map((item: any) =>
                    <TableRow key={item.sapCustomerId}>
                      <TableCell component="th" scope="row" className="mw-200p">
                        <Grid container>
                          <div className="d-flex">
                            {/* <Grid item xs={3}> */}
                            <div style={{ display: "inline" }}>
                              <img
                                src={item.logo ? item.logo : defaultLogo}
                                alt="Logo Client"
                                className="dealer-grid-logo"
                              /></div>

                            <div className="ml-2 py-1 pr-1" style={{ display: "inline" }}>
                              <div className="dealer-table-content dealer-company-name cursor-pointer"
                                onClick={async () => {
                                  dispatch(selectedNewDealer(item));
                                  dispatch(isEdit(false));
                                  history.push('/vieweditdealerdetails');
                                }}>{item.companyTitle} {item.companyName}</div>
                              <div className="dealer-table-subcontent">Trade License No. {item.tradeLicenseNo}</div>
                            </div>
                          </div>
                        </Grid>
                      </TableCell>
                      <TableCell className="mw-150p">
                        <div className="dealer-table-content">{item.addresses ? item.addresses : "-"}</div>
                      </TableCell>
                      <TableCell className="mw-150p">
                        <div className="dealer-table-content">{item.contactDetails.telephoneNumber ? item.contactDetails.telephoneNumber : "-"}</div>
                        <div className="dealer-table-subcontent">{item.contactDetails.email ? item.contactDetails.email : "-"}</div>
                      </TableCell>
                      <TableCell className="mw-150p">
                        <div className="dealer-table-content">{item.assignKam ? item.assignKam : "-"}</div>
                        <div className="dealer-table-subcontent">{item.assignFse}{item.assignFse ? " (FSE)" : '-'}</div>
                      </TableCell>
                      <TableCell className="mw-150p">
                        {/* <div className="dealer-table-content">{item.creditCurrencyType + " " + item.overallCreditLimit}</div> */}
                        <div className="dealer-table-subcontent">
                          {item.organizationIds &&
                            item.organizationIds.map((value: any) => getNewBrand(value && value.make)).join(", ")
                          }
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="dealer-table-content">{item.sapCustomerId ? item.sapCustomerId : "-"}</div>
                      </TableCell>
                      <TableCell>
                        <div className="dealer-table-content">{item.type ? item.type : "-"}</div>
                      </TableCell>
                      <TableCell>
                        <div className={item.status && item.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{item.status ? item.status : "-"}</div>
                      </TableCell>
                      <TableCell className="mw-230p">
                        <div style={{ background: "white" }}>
                          {<IconButton aria-describedby={id} onClick={(event: any) => { handleClick(event); setItem(item) }} className={classes.actionButton} size="small">
                            <div className="px-2 pt-1 pb-1 boxshadow-icon"> <MoreVertIcon fontSize="inherit" className={classes.actionButton} /> </div>
                          </IconButton>}
                        </div>
                      </TableCell>
                    </TableRow>)}

              </TableBody>
            </Table>
            {allDealers && allDealers.data && allDealers.data.data && allDealers.data.data.data && allDealers.data.data.data.customerList && allDealers.data.data.data.customerList.length === 0 && (
              <div className="my-5 mx-auto text-center">
                No users found.
              </div>
            )}
            {<div className="col-12 d-flex justify-content-end">
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={allDealers && allDealers.data && allDealers.data.data && allDealers.data.data.data && allDealers.data.data.data.totaCount ? allDealers.data.data.data.totaCount : 0}
                rowsPerPage={10}
                page={page}
                onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
              />
            </div>}
          </TableContainer>

          {
            openNewAddDealerPopup &&
            <AddNewDealer
              setOpenCustomerDialog={setOpenCustomerDialog}
              openCustomerDialog={openCustomerDialog}
              verifySuccess={verifySuccess} aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth="md" />
          }
        </Grid> : <Grid item xs={12} className="mt-2 cardCommon">
          <TableContainer component={Paper} className="height-500">
            <Table aria-label="dealer table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="dealer-table-title">Dealer</div>
                    <div className="dealer-table-title-desc">Name &amp; Trade License No</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Address</div>
                    <div className="dealer-table-title-desc">Region &amp; Address</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Contact</div>
                    <div className="dealer-table-title-desc">Telephone &amp; Email</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Owner</div>
                    <div className="dealer-table-title-desc">Name &amp; Mobile No.</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">Brands</div>
                    <div className="dealer-table-title-desc"></div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">SapCustomerId</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">Type</div>
                  </TableCell>
                  <TableCell><div className="dealer-table-title mb-3">Status</div></TableCell>
                  <TableCell ><div className="dealer-table-title mb-3">Action</div></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dealerRequests && dealerRequests.data && dealerRequests.data.data && dealerRequests.data.data.data && dealerRequests.data.data.data.customerList && dealerRequests.data.data.data.customerList.length > 0 &&
                  dealerRequests.data.data.data.customerList.map((item: any) =>
                    <TableRow key={item.sapCustomerId}>
                      <TableCell component="th" scope="row" className="mw-200p">
                        <Grid container>
                          <div className="d-flex">
                            <div className="ml-2 py-1 pr-1" style={{ display: "inline" }}>
                              <div className="dealer-table-content dealer-company-name cursor-pointer"
                                onClick={async () => {
                                  // await dispatch(getDealerDetails(item.sapCustomerId))
                                  // // if(webApplicationLoginId){
                                  // //   await dispatch(getCreditLimit(item.sapCustomerId, webApplicationLoginId));
                                  // // }
                                  // setDealerDetailTab(0);
                                  // setOpenDealerDetailDialog(true);
                                }}>{item.companyTitle} {item.companyName}</div>
                              <div className="dealer-table-subcontent">Trade License No. {item.tradeLicenseNo}</div>
                            </div>
                          </div>
                        </Grid>
                      </TableCell>
                      <TableCell className="mw-150p">
                        <div className="dealer-table-content">{item.addresses.streetName}{item.addresses.streetName ? ", " : ""}{item.addresses.poBox}{item.addresses.poBox ? ", " : ""}{item.addresses.city}{item.addresses.city ? ", " : ""}{item.addresses.region}{item.addresses.region ? ", " : ""}{item.addresses.country} </div>
                      </TableCell>
                      <TableCell className="mw-150p">
                        <div className="dealer-table-content">{item && item.contactDetails.telephoneNumber ? item.contactDetails.telephoneNumber : "-"}</div>
                        <div className="dealer-table-subcontent">{item && item.contactDetails ? item.contactDetails.email : "-"}</div>
                      </TableCell>
                      <TableCell className="mw-150p">
                        <div className="dealer-table-content">{item.ownerDetails.name ? item.ownerDetails.name : "-"}</div>
                        <div className="dealer-table-subcontent">{item.ownerDetails.mobileNumber ? item.ownerDetails.mobileNumber : "-"}</div>
                      </TableCell>
                      <TableCell className="mw-150p">
                        <div className="dealer-table-subcontent">
                          {item.organizationIds &&
                            item.organizationIds.map((value: any) => getNewBrand(value && value.make)).join(", ")
                          }
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="dealer-table-content">{item.sapCustomerId} </div>
                      </TableCell>
                      <TableCell >
                        <div className="dealer-table-content">{item.type ? item.type : "-"}</div>
                      </TableCell>
                      <TableCell>
                        <div className="dealer-table-content">{item.status}</div>
                      </TableCell>
                      <TableCell className="mw-230p">
                        <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                          <Button className="text-info btn-link" startIcon={<Visibility />}
                            onClick={() => {
                              dispatch(selectedNewDealer(item));
                              history.push('/dealerrequestdetails');
                            }}>View Dealer</Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>)}
              </TableBody>
            </Table>
            {dealerRequests && dealerRequests.data && dealerRequests.data.data && dealerRequests.data.data.data && dealerRequests.data.data.data.customerList && dealerRequests.data.data.data.customerList.length === 0 && (
              <div className="my-5 mx-auto text-center">
                No users found.
              </div>
            )}
            {<div className="col-12 d-flex justify-content-end">
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={dealerRequests && dealerRequests.data && dealerRequests.data.data && dealerRequests.data.data.data && dealerRequests.data.data.data.totalCount ? dealerRequests.data.data.data.totalCount : 0}
                rowsPerPage={10}
                page={page}
                onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
              />
            </div>}
            {
              openNewAddDealerPopup &&
              <AddNewDealer
                setOpenCustomerDialog={setOpenCustomerDialog}
                openCustomerDialog={openCustomerDialog}
                verifySuccess={verifySuccess} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md" />
            }
          </TableContainer>
        </Grid>}
      </Grid>

      {/* <AddNewDealer
        setOpenCustomerDialog={setOpenCustomerDialog}
        openCustomerDialog={openCustomerDialog}
        verifySuccess={verifySuccess} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md" /> */}

      <Dialog className="pb-0"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <RetrieveBrandsDialog setOpenDialog={handleClose} />
      </Dialog>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        className="ml-3 mt-5 "
      >
        <MenuItem className=""><Button className="text-info  mr-4" startIcon={<EditIcon />}
          onClick={() => {
            dispatch(selectedNewDealer(item));
            dispatch(isEdit(true));
            history.push('/vieweditdealerdetails');
          }}><div className="font-dropdown ">Edit</div></Button></MenuItem>
        <MenuItem> <Button className="text-info"
          startIcon={<GroupIcon />}
          onClick={() => {
            history.push('/dealerusers');
            dispatch(getAllUsers(item.sapCustomerId));
            dispatch(getDealerDetails(item.sapCustomerId, 'dealerDetails'))
          }}>Users</Button></MenuItem>
        <MenuItem>  <Button className="text-info  mr-4"
          startIcon={<AttachMoneyIcon />}
          onClick={() => {
            dispatch(getDealerCreditRequest(webApplicationLoginId, item.sapCustomerId, 'kam'));
            history.push('/viewcreditkam');
          }}><span className="ml-n2">ViewCredit</span></Button></MenuItem>
        <MenuItem> <Button className="text-info  mr-3"
          startIcon={<img src="./settargetinfo.svg" />}
          onClick={() => {
            history.push('/viewtarget');
            dispatch(viewCreditDealer(item.sapCustomerId, new Date().getFullYear()));
            dispatch(selectedNewDealer(item));
            dispatch(getCustomerDealerDetails(item.sapCustomerId))
          }}><span>View Target</span></Button>
        </MenuItem>
        <MenuItem className="mt-0"> <Button className="text-info  mr-3"
          startIcon={<EmojiEventsOutlined fontSize="inherit" />}
          onClick={() => {
            history.push("/achievementskam")
            // dispatch(getPurChaseHistoryByYearKam("",webApplicationLoginId,item.sapCustomerId,"2022")) 
            dispatch(selectedNewDealer(item));
          }}><span>Achievements</span></Button>
        </MenuItem>
        <MenuItem style={{ pointerEvents: 'none' }} className="mb-0"> <Button className="cursor-remove text-info mr-4" disabled
          startIcon={<AutorenewIcon />}
          onClick={() => { handleClickOpen(); }}><span className="">RetrieveBrands</span></Button>
        </MenuItem>
      </Menu>
    </Fragment >
  );
};

export default Dealers;
