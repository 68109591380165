
import React, { Fragment, useEffect, useState, Dispatch, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { useDispatch, useSelector } from "react-redux";
import { get } from 'lodash';
import TermsAndConditionsDialog from "../LeftMenu/TermsAndConditionsDialog";
import { confirmDataFromDealer } from '../../store/actions/dealer.action';
import { getDealerDetails, changeCustomerType } from '../../store/actions/onboard.actions';
import TermsAndConditions from '../Dealers/AddDealer/Terms&Conditions';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  }
}));
const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

export default function UserConfirmationDialog(props: any) {
  const dispatch: Dispatch<any> = useDispatch();
  const classes = useStyles();
  const { handleopenClose } = props;
  const history: any = useHistory();
  const [checkbox, setCheckbox] = React.useState<boolean>(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [dealerDetails, setDealerDetails] = React.useState<any>();
  const [openTAndCDialog, setOpenTAndCDialog] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const { customerDetails }: any = useSelector((state: any) => state.onboard);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const sapCustomerId = dealerDetails && dealerDetails.data && isExistingUser
    ? dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId : customerDetails && customerDetails.data && customerDetails.data.customerInformation.customerCode;
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const isKamOnboarded = get(profileDetails, "data.data.basicInfo.isAddedByKam", false);
  const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const [loading, setLoading] = React.useState<any>(false);

  // const handleopenClose = (open?: any) => {
  //   setSuccessMessage(open ? true : false);
  // };

  const handleOpenCloseTAndC = (open?: any) => {
    setOpenTAndCDialog(open ? true : false);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  // const handleopenCloseSnackbarForConfirm = (open?: any) => {
  //   setOpenSnackbar(open ? true : false);
  // };

  // VALIDATION FOR CHECKBOX
  function validationForCheckBox() {
    let isValid: boolean = true;
    if (checkbox === false) {
      isValid = false;
      showErrorMessage("Please accept Terms & Conditions before confirming");
      handleopenCloseErrorSnackbar(true);
      // handleopenClose(false);
    }
    else if (checkbox === true) {
      isValid = true;
      // props.handleopenClose('Confirm');
      confirmDealer();
    }
  }

  const confirmDealer = async () => {
    let data = {
      "b2bUserId": b2bUserId ? b2bUserId : '',
      "sapCustomerId": sapCustomerId ? sapCustomerId : ''
    }
    setLoading(true);
    const isConfirm: any = await dispatch(confirmDataFromDealer(data));
    if (isConfirm && isConfirm.message) {
      setDataUpdated(false);
      props.setSuccessMessage(isConfirm.message.message ? isConfirm.message.message : 'Dealer Confirmation done');
      props.handleopenCloseSnackbarForConfirm(true);
      // handleopenCloseSnackbarForConfirm(true);
      let data: any = await dispatch(getDealerDetails(b2bUserId, 'documents'));
      setDealerDetails(data);
      if (data && data.data && data.data.status)
        if (data && data.data && data.data.status === "Confirmed from Dealer") {
          props.confirmDealerForonboarding();
          props.handleopenClose();
        }
    }
  }

  return (
    <div className="float-left">
      {/* <Snackbar
        open={props.openRequestSnackbar ? true : false}
        handleopenClose={() => {
          props.handleopenCloseSnackbarForConfirm(false);
        }} message={successMessage}
      /> */}
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      <Dialog
        open={props.open ? true : false}
        onClose={() => { props.handleopenClose(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogContent>
          <div className={classes.icon}>
            <WarningIcon className="delete-warning" fontSize="small" />
          </div>
          <div>
            <DialogContentText id="alert-dialog-description">
              <div className="mt-2">
                <h5 className="submit-dialog-content">Are you sure you have verified all your details ?</h5>
              </div>
              <div className="pr-2 mb-2 pl-0 pt-3 pb-2">
                <ThemeProvider theme={theme}>
                  <Checkbox
                    checked={checkbox}
                    onChange={(e) => { setCheckbox(e.target.checked) }}
                    className="checkbox" />
                </ThemeProvider>
                <span className="dealer-table-content">I agree with AFG
                  <span className="download-offer mr-2 px-2 cursor-pointer"
                    onClick={() =>
                    // handleOpenCloseTAndC(true)
                    // history.push('/termsandconditions')
                      window.open('/termsandconditions', '_blank')
                    }>
                    Terms & Conditions.
                  </span>
                </span>
              </div>
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions className="mt-3 mb-3 justify-content-end">
          <Button variant="contained"
            color="secondary" className={`createFavourite ${classes.createButton}`}
            onClick={() => {
              validationForCheckBox();
            }}>Confirm
          </Button>
          <Button variant="contained" className={classes.closeButton}
            onClick={() => {
              props.handleopenClose(false)
              setCheckbox(false);
            }}>Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

