import React, { useEffect, Dispatch }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import { get, isEmpty, find } from 'lodash';

import { getUploadedDocuments } from "../../store/actions/dealer.action";
import { uploadedDocsLabels } from '../../constants/uploadedDocsConstants';

const UploadedDocuments: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const { dealerDetails, uploadedDocuments }: any = useSelector((state: any) => state.dealer);
    const [customerDocuments, setCustomerDocuments] = React.useState([]);

    useEffect(() => {   
        const sapCustomerId:any = get(dealerDetails, 'data.data.customerDetails.sapCustomerId', false),
            organization = get(dealerDetails, 'data.data.customerDetails.organizationIds[0]', {});

        if (sapCustomerId) {
            const customHeaders = {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": organization.organizationId || "",
            "Accept-Language": "EN",
            };
            
          dispatch(getUploadedDocuments(customHeaders, sapCustomerId));
        };
    }, [dealerDetails, dispatch]);

    useEffect(() => {

      const { data } = uploadedDocuments || {};
        // ,sapCustomerId = get(dealerDetails, 'data.data.customerDetails.sapCustomerId', false);
      if (isEmpty(data)) return;
      let documents: any = [];
      data.map((item: any) => { // eslint-disable-line
        const { label, expiryDate, url } = item;
  
        let name = '';
  
        if (label === uploadedDocsLabels.POWER_OF_ATTORNEY) { name = 'Power Of Attorney' }
        else if (label === uploadedDocsLabels.BANK_STATEMENT) { name = 'Bank Statement' }
        else if (label === uploadedDocsLabels.BANK_GUARANTEE) { name = 'Bank Guarantee' }
        else if (label === uploadedDocsLabels.VAT_RETURN) { name = 'VAT Return - Quarterly' }
        else if (label === uploadedDocsLabels.VISIT_REPORT) { name = 'Visit Report' }
        else if (label === uploadedDocsLabels.PASSPORT) { name = 'Valid Passport Copy' }
        else if (label === uploadedDocsLabels.VISA_COPY) { name = 'Valid Visa Copy' }
        else if (label === uploadedDocsLabels.EMIRATES_ID) { name = 'Valid Emirates ID Copy' }
        else if (label === uploadedDocsLabels.TRADE_LICENSE) { name = 'Valid Trade License With Partner/Shareholder' }
        else if (label === uploadedDocsLabels.MEMBERSHIP) { name = 'Membership Certificate' }
        else if (label === uploadedDocsLabels.VAT_TRN) { name = 'VAT TRN Certificate' }
  
        if (!name) return; // eslint-disable-line
  
        const dateString = new Date(expiryDate),
          date = dateString.getDate(),
          month = dateString.getMonth() + 1,
          year = dateString.getFullYear(),
          isDocPresent = find(documents, { label });
  
        if (isDocPresent) {
          isDocPresent['url'] = url;
          isDocPresent['expiryDate'] = (!expiryDate || expiryDate === 'null') ? '' : `Expiry Date ${date}-${month}-${year}`;
          return; // eslint-disable-line
        }
        
        const doc = {
          label,
          url,
          name,
          expiryDate: (!expiryDate || expiryDate === 'null') ? '' : `Expiry Date ${date}-${month}-${year}`,
          issueDate: (!expiryDate || expiryDate === 'null') ? '' : `Issue Date ${date}-${month}-${year}`
        };
        documents.push(doc);
      });
      setCustomerDocuments(documents);
    }, [uploadedDocuments, dealerDetails]);

    return (
        <Grid container>
            {customerDocuments.map((item, i) => {
                const { name, expiryDate, url, label, issueDate } = item || {};

                return (
                    <Grid item xs={6} className="pt-2" key={i}>
                        <Grid container>
                            <Grid item>
                                <GetAppIcon className="uploaded-docs pr-2" />
                            </Grid>
                            <Grid item>
                                <p><a href={url} download  className="uploaded-docs"><span>{name}</span></a></p>
                                {!!expiryDate &&
                                  <>
                                  {label === uploadedDocsLabels.VAT_TRN ? 
                                    <span className="info-text">{issueDate}</span> :
                                    <span className="info-text">{expiryDate}</span>
                                  }
                                  </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default UploadedDocuments
