import React, { Fragment, useEffect, useState, Dispatch, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, Select, MenuItem, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ActionButtons from './ActionButtons';
import { get } from 'lodash';
import { saveKamReviewDetails, getKamDetails, getDealerDetails, deleteDocument, } from '../../store/actions/editDealer.actions';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { deleteKamReviewsDocument, resetValues, updateDocument } from '../../store/actions/editDealer.actions';
import DeleteDialog from '../../common/components/deleteDialog';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { KamReviewValidationMessages } from '../../constants/messages';
import { updateDealer } from '../../store/actions/dealer.action';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { getIndustryType } from '../../store/actions/onboard.actions';

export const KamReview = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const { dealerDetails }: any = useSelector((state: any) => state.dealer);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [loading, setLoading] = React.useState<any>(true);
  const [setSave, SetSave] = React.useState<boolean>(false);
  const [setSize, SetSize] = React.useState<string>("");
  const [setSizeError, SetSizeError] = React.useState<boolean>(false);
  const [invalidSetSize, setInvalidSetSize] = React.useState<boolean>(false);
  const [setSizeValidationMessage, SetSizeValidationMessage] = React.useState<string>("");
  const [setStock, SetStock] = React.useState<string>("");
  const [setStockError, SetStockError] = React.useState<boolean>(false);
  const [invalidSetStock, setInvalidSetStock] = React.useState<boolean>(false);
  const [setStockValidationMessage, SetStockValidationMessage] = React.useState<string>("");
  const [setNumberOfStaff, SetNumberOfStaff] = React.useState<string>("");
  const [setNumberOfStaffError, SetNumberOfStaffError] = React.useState<boolean>(false);
  const [invalidSetNumberOfStaff, setInvalidSetNumberOfStaff] = React.useState<boolean>(false);
  const [setNumberOfStaffValidationMessage, SetNumberOfStaffValidationMessage] = React.useState<string>("");
  const [setSalesToCustomer, SetSalesToCustomer] = React.useState<string>("");
  const [setSalesToCustomerError, SetSalesToCustomerError] = React.useState<boolean>(false);
  const [invalidSetSalesToCustomer, setInvalidSetSalesToCustomer] = React.useState<boolean>(false);
  const [setSalesToCustomerValidationMessage, SetSalesToCustomerValidationMessage] = React.useState<string>("");
  const [setSalesToCustomerCurrencyType, SetSalesToCustomerCurrencyType] = React.useState<any>("AED");
  const [setSalesToCustomerCurrencyTypeError, SetSalesToCustomerCurrencyTypeError] = React.useState<boolean>(false);
  const [invalidSetSalesToCustomerCurrencyType, setInvalidSetSalesToCustomerCurrencyType] = React.useState<boolean>(false);
  const [setSalesToCustomerCurrencyTypeValidationMessage, SetSalesToCustomerCurrencyTypeValidationMessage] = React.useState<string>("");
  const [setSalesSince, SetSalesSince] = React.useState<any>(null);
  const [setSalesSinceError, SetSalesSinceError] = React.useState<boolean>(false);
  const [isValidSalesSinceExpiryDate, setIsValidSalesSinceExpiryDate] = React.useState<any>(true);
  const [invalidSetSalesSince, setInvalidSetSalesSince] = React.useState<boolean>(false);
  const [setSalesSinceValidationMessage, SetSalesSinceValidationMessage] = React.useState<string>("");
  const [setPaymentHistory, SetPaymentHistory] = React.useState<string>("");
  const [setPaymentHistoryError, SetPaymentHistoryError] = React.useState<boolean>(false);
  const [invalidSetPaymentHistory, setInvalidSetPaymentHistory] = React.useState<boolean>(false);
  const [setPaymentHistoryValidationMessage, SetPaymentHistoryValidationMessage] = React.useState<string>("");
  const [setGrowthPlan, SetGrowthPlan] = React.useState<string>("");
  const [setGrowthPlanError, SetGrowthPlanError] = React.useState<boolean>(false);
  const [invalidSetGrowthPlan, setInvalidSetGrowthPlan] = React.useState<boolean>(false);
  const [setGrowthPlanValidationMessage, SetGrowthPlanValidationMessage] = React.useState<string>("");
  const [setPictureOfHo, SetPictureOfHo] = React.useState<any>([]);
  const [setPictureOfHoError, SetPictureOfHoError] = React.useState<boolean>(false);
  const [invalidSetPictureOfHo, setInvalidSetPictureOfHo] = React.useState<boolean>(false);
  const [setPictureOfHoValidationMessage, SetPictureOfHoValidationMessage] = React.useState<string>("");
  const [error, setError] = React.useState<any>(null);
  const [invalidDate, setInvalidDate] = React.useState<boolean>(false);
  const [establishedDate, setEstablishedDate] = React.useState<any>(null);
  const [establishedDateError, setEstablishedDateError] = React.useState<boolean>(false);
  const [setSapCustomerId, SetSapCustomerId] = React.useState<any>(null);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState<any>();
  const [companyIndustrySector, setCompanyIndustrySector] = React.useState<any>('');
  const [selectedIndustryTypeError, setSelectedIndustryTypeError] = React.useState<boolean>(false);
  const [invalidSelectedIndustryType, setInvalidSelectedIndustryType] = React.useState<boolean>(false);
  const currencies = ["AED"];
  const [dealerstatus, setStatus] = React.useState<any>("");
  const [kamReviewDetailsObj, setKamReviewDetailsObj] = React.useState<any>([
    {
      size: "",
      stock: "",
      numberOfStaff: "",
      growthPlan: "",
      salesSince: "",
      paymentHistory: "",
      salesToCutomerCurrencyType: "",
      salesToCutomer: "",
      pictureOfHo: [{}],
      companyIndustrySector: "",
      addedBy: "",
      lastUpdateDate: ""
    }
  ])
  const { selectedNewDealer, isEditDealer }: any = useSelector((state: any) => state.dealer);
  let b2bUserId: any = selectedNewDealer && selectedNewDealer.createdByB2bUserId;
  let sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
  const { getIndustryTypeAPI }: any = useSelector((state: any) => state.onboard);

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEdited(false);
  };

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
  };

  const onClickEditAddress = async () => {
    setIsEdited(true);
    setOpen(true);
  }

  useEffect(() => {
    loadKamDetails();
    dispatch(getIndustryType());
  }, []);

  const loadKamDetails = async () => {
    setLoading(true);
    let response: any = await dispatch(getDealerDetails(sapCustomerId, 'kamReview'));
    if (response) {

      setLoading(false);
      if (response.data) {
        KamReviewDetails(response);
      }
    }
  }

  // useEffect(() => {
  //   if (companyIndustrySector) {
  //     getIndustryTypeAPI && getIndustryTypeAPI.data && getIndustryTypeAPI.data.companyIndustrySector.length > 0 && getIndustryTypeAPI.data.companyIndustrySector.map((r: any) => {
  //       if (r.code === companyIndustrySector) {
  //         setCompanyIndustrySector(r);
  //       }
  //     })
  //   }
  // }, [companyIndustrySector, getIndustryTypeAPI])

  function deleteDocumentDialog() {
    setOpenDeleteDialog(true);
    setSuccessMessage(null);
  }

  // ONCHANGE OF SELECT INDUSTRY TYPE
  const onChangeIndustryType = (e: any) => {
    setCompanyIndustrySector(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setSelectedIndustryTypeError(true);
    }
    else {
      setSelectedIndustryTypeError(false);
    }
  }

  function KamReviewDetails(data: any) {
    if (data.data.kamReview) {
      let duplicate = data.data.kamReview;
      if (data.data.sapCustomerId) {
        SetSapCustomerId(data.data.sapCustomerId);
      }
      if (duplicate.size) {
        SetSize(duplicate.size);
      }
      if (duplicate.stock) {
        SetStock(duplicate.stock);
      }
      if (duplicate.numberOfStaff) {
        SetNumberOfStaff(duplicate.numberOfStaff)
      }
      if (duplicate.salesToCutomer) {
        SetSalesToCustomer(duplicate.salesToCutomer);
      }
      if (duplicate.salesToCutomerCurrencyType) {
        SetSalesToCustomerCurrencyType(duplicate.salesToCutomerCurrencyType)
      }
      if (duplicate.salesSince) {
        SetSalesSince(duplicate.salesSince);
      }
      if (duplicate.paymentHistory) {
        SetPaymentHistory(duplicate.paymentHistory);
      }
      if (duplicate.companyIndustrySector) {
        setCompanyIndustrySector(duplicate.companyIndustrySector);
      }
      if (duplicate.growthPlan) {
        SetGrowthPlan(duplicate.growthPlan);
      }
      if (duplicate.pictureOfHo && duplicate.pictureOfHo.length) {
        let pictures = duplicate.pictureOfHo.filter((data: any) => data.documentId != null && data.documentId)
        SetPictureOfHo(pictures);
      }
    }
    if (data.data.status) {
      setStatus(data.data.status);
    }
  }

  function saveValidation() {
    SetSave(true);
    let validationSuccess = true;
    if (!setSize || setSizeError) {
      SetSizeError(true);
      validationSuccess = false
    }
    else {
      SetSizeError(false);
    }

    if (!setStock || setStockError) {
      SetStockError(true);
      validationSuccess = false;
    }
    else {
      SetStockError(false);
    }
    if (!setNumberOfStaff || setNumberOfStaffError) {
      SetNumberOfStaffError(true);
      validationSuccess = false;
    }
    else {
      SetNumberOfStaffError(false);
    }
    if (!setSalesToCustomer || setSalesToCustomerError) {
      SetSalesToCustomerError(true);
      validationSuccess = false;
    }
    else {
      SetSalesToCustomerError(false);
    }
    if (!setSalesToCustomerCurrencyType || setSalesToCustomerCurrencyTypeError) {
      SetSalesToCustomerCurrencyTypeError(true);
      validationSuccess = false;
    }
    else {
      SetSalesToCustomerCurrencyTypeError(false);
    }
    if (!setSalesSince || setSalesSinceError) {
      SetSalesSinceError(true);
      validationSuccess = false;
    }
    else {
      SetSalesSinceError(false);
    }
    if (!setPaymentHistory || setPaymentHistoryError) {
      SetPaymentHistoryError(true);
      validationSuccess = false;
    }
    else {
      SetPaymentHistoryError(false);
    }
    if (!companyIndustrySector && companyIndustrySector == "" || companyIndustrySector == undefined) {
      setSelectedIndustryTypeError(true);
      validationSuccess = false;
    }
    else {
      setSelectedIndustryTypeError(false);
    }
    if (!setGrowthPlan || setGrowthPlanError) {
      SetGrowthPlanError(true);
      validationSuccess = false;
    }
    else {
      SetGrowthPlanError(false);
    }
    let selectedPO: any = [];
    if (setPictureOfHo && setPictureOfHo.length > 0) {
      setPictureOfHo.map((po: any) => {
        if (po.documentId && po.documentId.id) {
          selectedPO.push({ documentId: po.documentId.id })
        }
      })
    }
    if (setPictureOfHo.length == 0) {
      validationSuccess = false;
    }
    let kamReviewPayload = {
      size: setSize,
      stock: setStock,
      numberOfStaff: setNumberOfStaff,
      growthPlan: setGrowthPlan,
      salesSince: setSalesSince,
      paymentHistory: setPaymentHistory,
      salesToCutomerCurrencyType: setSalesToCustomerCurrencyType,
      salesToCutomer: setSalesToCustomer,
      pictureOfHo: selectedPO,
      companyIndustrySector: companyIndustrySector,
      addedBy: sessionStorage.getItem('webApplicationLoginId'),
      lastUpdateDate: moment(new Date()).utc().format()
    }
    setKamReviewDetailsObj(kamReviewPayload);
    let finalPayload = {
      b2bUserId: b2bUserId,
      sapCustomerId: sapCustomerId,
      kamReview: kamReviewPayload
    }
    if (validationSuccess) {
      saveKamReview(finalPayload);
    } else {
      showErrorMessage('Please fill all mandatory fields');
      handleopenCloseErrorSnackbar(true);
    }
  }
  const saveKamReview = async (payload: any) => {
    setLoading(true);
    let response: any = await dispatch(updateDealer(sapCustomerId, payload));
    if (response && response.message && response.message.status === 'S') {
      props.setDataUpdated(false);
      props.setselectedTab(4);
      setOpenSnackbar(true);
      SetSave(false);
      props.handleopenCloseSnackbar(true);
      props.setSuccessMessage("Customer details updated successfully")
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
    }
    setLoading(false);
  }

  const onClickBrowseFileasync = async (type: any, item: any) => {
    showErrorMessage(null);
    setSuccessMessage(null);
    handleopenCloseSnackbar(false);
    handleopenCloseErrorSnackbar(false);
    const name = item && item.name ? item.name : '',
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
      doctExtensions = ['jpg', 'jpeg', 'png'],
      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
    if (!canUploadFile) {
      showErrorMessage('Invalid File type format');
      handleopenCloseErrorSnackbar(true);
      return;
    }

    if (item && item.size < 2000000 && setPictureOfHo.length < 10) {
      let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;

      if (type == "pictureOfHo") {
        let po = [...setPictureOfHo];
        po.push({ documentId: item });
        SetPictureOfHo(po);
      }

      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };

      let docType = '';
      if (type === 'pictureOfHo') { docType = 'pictureOfHo' }
      const formData: any = new FormData();
      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", sapCustomerId);
      formData.append("docType", docType);
      formData.append("label", type);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'kam');
      formData.append("uploadedBywebApplicationLoginId", '');
      formData.append("selectedFileName", item.name);
      let documentRes: any = await dispatch(updateDocument(formData, customHeaders));
      if (documentRes && documentRes.message && documentRes.message.status === 'S') {
        props.setDataUpdated(true);
        const { label, id } = documentRes.data;
        if (label === "pictureOfHo") {
          let po = [...setPictureOfHo];
          po.push({ documentId: documentRes.data });
          SetPictureOfHo(po);
        }
      }
    } else if (setPictureOfHo.length >= 10) {
      showErrorMessage('Should not exceed 10 files.');
      handleopenCloseErrorSnackbar(true);

    } else if (item && item.size > 2000000) {
      showErrorMessage('Please upload less than 2MB file');
      handleopenCloseErrorSnackbar(true);
    }
  }

  const removeDocument = async (type: any) => {
    props.setDataUpdated(true);
    dispatch(resetValues());
    let po = [...setPictureOfHo]
    const obj = {
      "type": type
    }
    const documentId = po[currentIndex].documentId && po[currentIndex].documentId.id;
    let response: any = await dispatch(deleteDocument(sapCustomerId, documentId, obj));
    if (response && response.message && response.message.status === 'S') {
      po[currentIndex].documentId = null;
      po.splice(currentIndex, 1);
      SetPictureOfHo(po);
      setSuccessMessage(response.message.message);
      handleopenCloseSnackbar(true);
      setOpenDeleteDialog(false);
    } else {
      showErrorMessage(response.error);
      handleopenCloseErrorSnackbar(true);
    }
  }

  function onChangeSize(event: any, type: string) {
    props.setDataUpdated(true);
    let size = event.target.value;
    SetSize(size);
    if (size.length > 50) {
      SetSizeError(true);
      SetSizeValidationMessage(KamReviewValidationMessages.SIZE)
    } else {
      SetSizeError(false);
    }
  }

  function onChangeStock(event: any, type: string) {
    props.setDataUpdated(true);
    let stock = event.target.value
    SetStock(stock);
    if (stock.length > 50) {
      SetStockError(true);
      SetStockValidationMessage(KamReviewValidationMessages.STOCK)
    } else {
      SetStockError(false);
    }
  }

  function onChangeNumberOfStaff(event: any, type: string) {
    props.setDataUpdated(true);
    let numberOfStaff = event.target.value;
    SetNumberOfStaff(numberOfStaff);
    if (numberOfStaff.length > 100) {
      SetNumberOfStaffError(true);
      SetNumberOfStaffValidationMessage(KamReviewValidationMessages.NO_OF_STAFF)
    } else {
      SetNumberOfStaffError(false);
    }
  }

  function onChangeSalesToCustomer(event: any, type: string) {
    props.setDataUpdated(true);
    let salesToCustomer = event.target.value;
    SetSalesToCustomer(salesToCustomer);
    if (salesToCustomer.length > 100) {
      SetSalesToCustomerError(true);
      SetSalesToCustomerValidationMessage(KamReviewValidationMessages.SALES_TO_CUSTOMER)
    } else {
      SetSalesToCustomerError(false);
    }
  }

  function onChangePaymentHistory(event: any, type: string) {
    props.setDataUpdated(true);
    let paymentHistory = event.target.value
    SetPaymentHistory(paymentHistory);
    if (!paymentHistory && paymentHistory === " " && paymentHistory === undefined) {
      if (paymentHistory.length > 500) {
        SetPaymentHistoryError(true);
        SetPaymentHistoryValidationMessage(KamReviewValidationMessages.PAYMENT_HISTORY)
      }
    } else {
      SetPaymentHistoryError(false);
    }
  }

  function onChangeGrowthPlan(event: any, type: string) {
    props.setDataUpdated(true);
    let growthPlan = event.target.value;
    SetGrowthPlan(growthPlan);
    if (growthPlan.length > 500) {
      SetGrowthPlanError(true);
      SetGrowthPlanValidationMessage(KamReviewValidationMessages.GROWTH_PLAN)
    } else {
      SetGrowthPlanError(false);
    }
  }

  function datePickerValid(e: any, state: any) {
    if (e === "") {
      return;
    } else {
      if (e === "Invalid Date Format") {
        setError("Invalid Date Format");
        state(true);
      } else {
        var varDate = new Date(setSalesSince);
        var today = new Date();
        if (varDate > today) {
          setInvalidDate(true);
          setError('Date should be less than today date.')
          SetSalesSinceError(true);
        } else {
          SetSalesSinceError(false);
          state(false);
          setError(null)
        }
      }
    }
  }

  return (
    <Fragment>
      {loading && <FullscreenLoader />}
      <div className="row-space-start col-12 p-3">
        <>
          <div className="col-12 m-0 p-0">
            <div className="row mt-0 mb-1">
              <h6 className="dealer-table-content mb-1 px-4 mt-3 mb-0">KAM Review</h6>
            </div>
            {isEditDealer ? (<>
              <div className="row mb-1 mt-0 px-3">
                <div className="col-sm-4 col-md-4 col-sm-4 px-1 pr-2 mb-1 mt-2 row-space-between ">
                  <TextField id="size"
                    required
                    type="text"
                    value={setSize}
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                    error={setSizeError}
                    helperText={setSizeError && setSize && <span style={{ color: 'red' }}>{setSizeValidationMessage}</span>}
                    onChange={(e: any) => onChangeSize(e, "size")}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Size" variant="outlined">
                  </TextField>
                </div>
                <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-1 mt-2 row-space-between">
                  <TextField id="stockQuantityValue"
                    required
                    value={setStock}
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                    error={setStockError}
                    helperText={setStockError && setStock && <span style={{ color: 'red' }}>{setStockValidationMessage}</span>}
                    onChange={(e: any) => onChangeStock(e, "stock")}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Stock(quantity & value)" variant="outlined" margin="dense">
                  </TextField>
                </div>
                <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-1 mt-2 row-space-between">
                  <TextField id="noOfStaff"
                    required
                    value={setNumberOfStaff}
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                    error={setNumberOfStaffError}
                    helperText={setNumberOfStaffError && setNumberOfStaff && <span style={{ color: 'red' }}>{setNumberOfStaffValidationMessage}</span>}
                    onChange={(e: any) => onChangeNumberOfStaff(e, "numberOfStaff")}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="No. of Staff" variant="outlined" margin="dense">
                  </TextField>
                </div>
              </div>
              <div className="row mb-1 mt-2 px-3">
                <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between salestoCustomerKam">
                  <TextField
                    select disabled id="salesToCustomer"
                    defaultValue={setSalesToCustomerCurrencyType}
                    value={setSalesToCustomerCurrencyType} className="col-sm-2 commonRoundedInputs-dropdown"
                    margin="dense"
                    error={setSalesToCustomerCurrencyTypeError}
                    helperText={setSalesToCustomerCurrencyTypeError && setSalesToCustomerCurrencyType && <span style={{ color: 'red' }}>{setSalesToCustomerCurrencyTypeValidationMessage}</span>}
                    variant="outlined" style={{ width: '74px' }}>
                    {currencies.map(item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>))}
                  </TextField>
                  <TextField id="salesToCustomer"
                    required
                    value={setSalesToCustomer}
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                    error={setSalesToCustomerError}
                    helperText={setSalesToCustomerError && setSalesToCustomer && <span style={{ color: 'red' }}>{setSalesToCustomerValidationMessage}</span>}
                    onChange={(e: any) => onChangeSalesToCustomer(e, "salesToCustomer")}
                    size="small" className="col-sm-10 commonRoundedInputs-textfield" style={{ maxHeight: '36px' }}
                    label="Sales to customer in last 6 months" variant="outlined" margin="dense" />
                </div>
                <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-1 row-space-between">
                  <KeyboardDatePicker required
                    disableFuture
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                    views={["year"]}
                    openTo="year"
                    autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                    onError={(e, v) => datePickerValid(e, setInvalidDate)}
                    format="yyyy" margin="normal" label="After Sales Since" value={setSalesSince}
                    onChange={(date: Date | null) => {
                      props.setDataUpdated(true);
                      if (date) {
                        let newDate = moment(date).format('YYYY');
                        SetSalesSince(newDate);
                        if (newDate != "Invalid Date") {
                          SetSalesSinceError(false)
                        }
                      } else {
                        SetSalesSince("");
                        SetSalesSinceError(true)
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className="rounded-input-dealer w-100 mt-2 cal-icon"
                    error={setSalesSinceError || invalidDate}
                    helperText={invalidDate && <span style={{ color: 'red' }}>{error}</span>}
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-1 row-space-between">
                  <TextField id="paymentHistory"
                    select
                    required value={setPaymentHistory}
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                    error={setPaymentHistoryError}
                    helperText={setPaymentHistoryError && setPaymentHistory && <span style={{ color: 'red' }}>{setPaymentHistoryValidationMessage}</span>}
                    onChange={(e: any) => onChangePaymentHistory(e, "paymentHistory")}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Payment History" variant="outlined" margin="dense">
                    <MenuItem key={'good'} value={'good'}>{'Good'}</MenuItem>
                    <MenuItem key={'bad'} value={'bad'}>{'Bad'}</MenuItem>
                    <MenuItem key={'excellent'} value={'excellent'}>{'Excellent'}</MenuItem>
                    <MenuItem key={'newCustomer'} value={'newCustomer'}>{'New Customer'}</MenuItem>
                  </TextField>
                </div>
              </div>
              <div className="row mb-1 mt-1 px-3">
                <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between salestoCustomerKam">
                  <TextField select id="selectIndustry" value={companyIndustrySector}
                    label="Select Industry Type" required variant="outlined" margin="dense"
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs hiddenNo" type="number"
                    error={selectedIndustryTypeError || invalidSelectedIndustryType}
                    helperText={invalidSelectedIndustryType && <span style={{ color: 'red' }}>Please select the industry type.</span>}
                    onChange={(e: any) => { props.setDataUpdated(true); setCompanyIndustrySector(e.target.value) }}
                  >
                    {getIndustryTypeAPI && getIndustryTypeAPI.data && getIndustryTypeAPI.data.length > 0 &&
                      getIndustryTypeAPI.data[0] && getIndustryTypeAPI.data[0] &&
                      getIndustryTypeAPI.data[0].companyIndustrySector.length > 0 && getIndustryTypeAPI.data[0].companyIndustrySector.map((item: any) => (
                        <MenuItem key={item.code} value={item.code}>{item.title} </MenuItem>))}
                  </TextField>
                </div>
                <div className="px-3 col-sm-12 col-md-8 col-sm-8 px-1 pr-2 mb-3 mt-0 row-space-between salestoCustomerKam">
                  <TextField id="growthPlan"
                    required value={setGrowthPlan}
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                    error={setGrowthPlanError}
                    helperText={setGrowthPlanError && setGrowthPlan && <span style={{ color: 'red' }}>{setGrowthPlanValidationMessage}</span>}
                    onChange={(e: any) => onChangeGrowthPlan(e, "growthPlan")}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Growth Plan" variant="outlined" margin="dense">
                  </TextField>
                </div>
              </div>
            </>) : (<>
              <div className="row col-12">
                <div className="col-4">
                  <div className="info-sub-title">Size</div>
                  <div className="info-sub-title-value mt-2">{setSize ? setSize : '-'}</div>
                </div>
                <div className="col-4">
                  <div className="info-sub-title">Stock(quantity & value)</div>
                  <div className="info-sub-title-value mt-2">{setStock ? setStock : '-'}</div>
                </div>
                <div className="col-4">
                  <div className="info-sub-title">No. of Staff</div>
                  <div className="info-sub-title-value mt-2">{setNumberOfStaff ? setNumberOfStaff : '-'}</div>
                </div>
                <div className="col-4 mt-3">
                  <div className="info-sub-title">Sales to customer</div>
                  <div className="info-sub-title-value mt-2">{setSalesToCustomer ? setSalesToCustomer : '-'}</div>
                </div>
                <div className="col-4 mt-3">
                  <div className="info-sub-title">After Sales Since</div>
                  <div className="info-sub-title-value mt-2">{setSalesSince ? setSalesSince : '-'}</div>
                </div>
                <div className="col-4 mt-3">
                  <div className="info-sub-title">Payment History</div>
                  <div className="info-sub-title-value mt-2">{setPaymentHistory ? setPaymentHistory : '-'}</div>
                </div>
                <div className="col-4 mt-3">
                  <div className="info-sub-title">Industry Type</div>
                  <div className="info-sub-title-value mt-2">{companyIndustrySector ? companyIndustrySector : '-'}</div>
                </div>
                <div className="col-4 mt-3">
                  <div className="info-sub-title">Growth Plan</div>
                  <div className="info-sub-title-value mt-2">{setGrowthPlan ? setGrowthPlan : '-'}</div>
                </div>
              </div>
            </>)}
          </div>
        </>
      </div>
      <div className="col-12 m-0 p-0 border-top">
        <div className="row px-3 mb-3">
          <div className="col-md-3 col-lg-3 pr-2 mb-4 px-4 pl-0 ">
            <h6 className="document-content pt-3 pb-2">Picture Of HO <span style={{ color: 'grey' }}>*</span></h6>
            {isEditDealer && (
              <small className="dealer-table-content">Photos(.jpg, .png  and maximum 10 files upto 2 MB can be uploaded)</small>
            )}
          </div>
          {isEditDealer && (
            <div className="col-md-2 col-lg-2 mb-2 pl-0 mt-4">
              <Button variant="contained"
                disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                component="label" color="secondary"
                className="rounded-button-dealer pl-4 pr-4 mt-0">
                Browse File
                <input type="file" hidden multiple
                  onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFileasync("pictureOfHo", e.target.files[0]) : ""
                  }
                />
              </Button>
              {(!setPictureOfHo || setPictureOfHo.length == 0) && setSave ?
                <div style={{ color: 'red' }}>Please upload the Picture Atleast one</div> : ""}
            </div>
          )}
          <div className="col-md-7 col-lg-7 mb-2 pl-0 mt-2">
            {setPictureOfHo && setPictureOfHo.length > 0 && setPictureOfHo.map((file: any, index: any) => (
              <div className="file-name-kam mt-2">
                <span className="cursor-pointer"
                  onClick={() => {
                    window.open(file.documentId.url)
                  }}
                >{file.documentId && file.documentId.name ? file.documentId.name : file.documentId && file.documentId.selectedFileName}</span>
                {file.documentId && file.documentId.selectedFileName && isEditDealer ? (
                  <IconButton className="p-1" aria-label="delete"
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                    onClick={(e: any) => { deleteDocumentDialog(); setCurrentIndex(index) }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                ) : ''}
              </div>
            ))}
          </div>
        </div>
      </div>
      <DeleteDialog
        open={openDeleteDialog}
        text="Are you sure you want to delete File ?"
        handleopenClose={(data: any) => {
          if (data === 'delete') removeDocument("kamReview");
          if (!data) handleopenClose(false);
        }} />
      {isEditDealer && (
        <ActionButtons
          openRequestSnackbar={true}
          deactiveShow={true}
          dealerDetails={dealerDetails}
          dealerStatus={dealerstatus}
          saveCallback={() => {
            saveValidation();
          }}
        />
      )}
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
    </Fragment>
  )
}

export default KamReview;