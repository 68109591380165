import { API_ENDPOINTS, CREATE_UPDATE_ENQUIRY } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const createUpdateEnquiryService = {
    createEnquiry,
    updateEnquiry,
    verifyEnquiry,
    cancelEnquiry,
    getEnquiryDetails,
    placeOrder,
    getEnquiryDetailsQueueCheck,
    deleteLostSaleReport
};


async function placeOrder(masterId: any, data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + '/' + masterId  + CREATE_UPDATE_ENQUIRY.ORDERS ;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function createEnquiry(data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateEnquiry(masterId: any, data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.UPDATE + '/' + masterId;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {
            
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function cancelEnquiry(masterId: any, data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + '/' + masterId  + CREATE_UPDATE_ENQUIRY.CANCEL;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {
            
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function verifyEnquiry(masterId: any, data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + '/' + masterId  + CREATE_UPDATE_ENQUIRY.VERIFY ;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}


async function getEnquiryDetails(masterId: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.GETSINGLE + '/' + masterId  ;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {
            
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getEnquiryDetailsQueueCheck(b2bEnquiryId: any, loginId: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + CREATE_UPDATE_ENQUIRY.DETAILS + `?b2bEnquiryId=${b2bEnquiryId}&loginId=${loginId}`;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function deleteLostSaleReport(data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + '/deleteLostSalesReport' ;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}






