import React, { Fragment, Dispatch, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withStyles, Theme, createStyles, makeStyles, createTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
// import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { get } from "lodash";
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import NotificationIcon from "../../common/components/NotificationIcon";
import Dialog from '@material-ui/core/Dialog';
import ActionButtonsKAM from './ActionButtonsKam';
import GetAppIcon from '@material-ui/icons/GetApp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { getDealerCreditRequest } from "../../store/actions/dealer.action";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import AdditionalCreditRequestKAM from './AdditionalCreditRequestKam';
import Alert from '@material-ui/lab/Alert';
import { getNewBrand } from '../../common/components/util';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    flex: '1 1 100%',
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 120,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  correctionTextCSS: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  }
}));

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const fieldLabelsAndConditions: any = {
  "bankStatement": {
    label: 'Bank Statement*',
    subLabel: 'Latest 6 months',
    required: true
  },
  "aecbConsentForm": {
    label: 'AECB Consent Form*',
    subLabel: '',
    required: true
  },
  "managementAccount": {
    label: 'Management Account*',
    subLabel: 'Current year (Financial statement/P&L/Balance sheet)',
    required: true
  },
  "vatReturnStatement": {
    label: 'VAT Return Statement*',
    subLabel: 'Last 2 years',
    required: true
  },
  "contract": {
    label: 'Contract*',
    subLabel: 'Latest 6 months',
    required: false
  },
  "tenancyContractCopy": {
    label: 'Tenancy Contract Copy',
    subLabel: '--',
    required: true
  },
  "auditedFinancialStatement": {
    label: 'Audited Financial Statements',
    subLabel: 'Last 3 years',
    required: false
  },
  "companyProfileDocument": {
    label: 'Company Profile Document',
    subLabel: '--',
    required: false
  },
  "authorizationLatter": {
    label: 'Authorization Letter',
    subLabel: '--',
    required: false
  },
  "rtaFleetListReport": {
    label: 'RTA Report / Fleet List Report',
    subLabel: '--',
    required: false
  },
  "additionalDocuments": {
    label: 'Additional Documents',
    subLabel: '--',
    required: false
  },
}

export default function ViewCreditKAM(props: any) {
  const dispatch: Dispatch<any> = useDispatch();
  const history: any = useHistory();
  const [open, setOpen] = React.useState(false);
  const [selectedTab, setselectedTab] = React.useState(0);
  const [selectedTabData, setSelectedTabData] = React.useState<any>();
  const [selectedTabDataError, setSelectedTabDataError] = React.useState<boolean>(false);
  const [value, setValue] = React.useState(0);
  const { getDealerCreditRequestAPI }: any = useSelector((state: any) => state.dealer);
  const [emptyWholePage, setemptyWholePage] = React.useState<any>(false)
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const userType = sessionStorage.getItem('userType');


  const handleChange = (newValue: any) => {
    setValue(newValue);
  }

  useEffect(() => {
    if (getDealerCreditRequestAPI && getDealerCreditRequestAPI.data && getDealerCreditRequestAPI.data.length > 0) {
      setSelectedTabData(getDealerCreditRequestAPI.data[0])
    }
  }, [getDealerCreditRequestAPI])

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isDisabled = () => {
    let isValid: boolean = true;
    if (selectedTab || selectedTab == 0) {
      selectedTabData && selectedTabData.documents.map((item: any, index: any) => {
        if (item.type !== "tenancyContractCopy" && item.type !== "auditedFinancialStatement" && item.type !== "authorizationLatter" &&
          item.type !== "companyProfileDocument" && item.type !== "rtaFleetListReport" &&
          item.type !== "additionalDocuments") {
          if ((item.type == "bankStatement" ||
            item.type == "aecbConsentForm" || item.type == "managementAccount" ||
            item.type == "vatReturnStatement" || item.type == "contract")
            && item.documentId && item.documentId.selectedFileName) {
            setSelectedTabDataError(false);
          } else {
            isValid = false;
            setSelectedTabDataError(true);
          }
        }
      })
      if (selectedTabData && selectedTabData.bankDetails && selectedTabData.bankDetails.bankName) {
        setSelectedTabDataError(false);
      } else {
        isValid = false;
        setSelectedTabDataError(true);
      }
      if (selectedTabData && selectedTabData.bankDetails && selectedTabData.bankDetails.accountNo) {
        setSelectedTabDataError(false);
      } else {
        isValid = false;
        setSelectedTabDataError(true);
      }
      if (selectedTabData && selectedTabData.bankDetails && selectedTabData.bankDetails.IBANNo) {
        setSelectedTabDataError(false);
      } else {
        isValid = false;
        setSelectedTabDataError(true);
      }
      if (selectedTabData && selectedTabData.bankDetails && selectedTabData.bankDetails.telephone) {
        setSelectedTabDataError(false);
      } else {
        isValid = false;
        setSelectedTabDataError(true);
      }
      if (selectedTabData && selectedTabData.bankDetails && selectedTabData.bankDetails.contactName) {
        setSelectedTabDataError(false);
      } else {
        isValid = false;
        setSelectedTabDataError(true);
      }
      if (selectedTabData && selectedTabData.bankDetails && selectedTabData.bankDetails.address) {
        setSelectedTabDataError(false);
      } else {
        isValid = false;
        setSelectedTabDataError(true);
      }
    }
    if (isValid) {
      handleClose();
      handleClickOpen();
      handleopenCloseErrorSnackbar(false);
    } else {
      isValid = false;
      showErrorMessage("Please contact to concerned dealer to fill all mandatory fields and documents");
      handleopenCloseErrorSnackbar(true);
    }
    return isValid
  }

  return (
    <Fragment>
      {getDealerCreditRequestAPI && getDealerCreditRequestAPI.loading && <FullscreenLoader />}
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Credit Request
        </h2>
        <div className="ml-auto">
          {/* <AnnouncementIcon /> */}
          <NotificationIcon />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-2 mb-1">
          <div className="d-flex align-items-center">
            <h6 className="dealer-table-content mb-0">Credit Details For </h6>
            <div className="dealer-table-content mb-0 px-2"><h6 className="page-sub-title mb-0">{getDealerCreditRequestAPI && getDealerCreditRequestAPI.data && getDealerCreditRequestAPI.data.length > 0 && getDealerCreditRequestAPI.data[0].companyName}</h6></div>
            <div className="row-space-start ml-auto sm">
              <Button variant="contained" color="primary" onClick={(e) => {
                e.preventDefault();
                { userType === 'afg_super_admin' ? history.push('/superadmindealers') : history.push('/dealers') }
              }} className="blueActionButton border-left rounded-pill ml-4">Back To Dealer List</Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        {(getDealerCreditRequestAPI && getDealerCreditRequestAPI.data && getDealerCreditRequestAPI.data.length === 0 ?
          <div className="col-12">
            <Alert severity="info">No Credit avaliable for any Brand !</Alert>
          </div>
          : <>
            {getDealerCreditRequestAPI && getDealerCreditRequestAPI.data && getDealerCreditRequestAPI.data && getDealerCreditRequestAPI.data.length > 0 && (
              <div className="mt-3 mb-3 pb-5 w-100">
                <ThemeProvider theme={theme}>
                  <Paper square>
                    <Tabs
                      className="tabSelect w-100"
                      value={selectedTab}
                      textColor="primary"
                      indicatorColor="primary"
                      onChange={(event, newValue) => {
                        setselectedTab(newValue);
                        if (getDealerCreditRequestAPI && getDealerCreditRequestAPI.data && getDealerCreditRequestAPI.data.length > 0)
                          setSelectedTabData(getDealerCreditRequestAPI.data[newValue])
                      }}
                      aria-label="disabled tabs example">
                      {getDealerCreditRequestAPI.data.map((data: any, index: any) => (
                        <Tab label={getNewBrand(data.make)} {...a11yProps(index)} />
                      ))}
                    </Tabs>
                    <TabPanel value={selectedTab} index={selectedTab}>
                      <div className="col-12 m-0 p-2">
                        <div className="row mt-1 mb-1 px-3">
                          {selectedTabData && selectedTabData.creditLimitSuccess === false && (
                            <div className="col-12">
                              <Alert severity="info">No Credit avaliable for this Brand !</Alert>
                              {selectedTabData && selectedTabData.errorMsg && typeof selectedTabData.errorMsg === 'string' && (
                                <div className="mt-5 text-center">{selectedTabData.errorMsg}</div>
                              )}
                            </div>
                          )}
                          {selectedTabData && selectedTabData.creditLimitSuccess === true && (
                            <>
                              <div className="col-12 m-0 p-2 border">
                                <div className="row mt-1 mb-1 px-3">
                                  <Grid container key={selectedTabData.sapCustomerId}>
                                    <Grid item xs={3}>
                                      <div className="info-sub-title-value mt-3 px-3">Brand</div>
                                      <div className="subTitle mt-1 mb-3 px-3">
                                        <Button
                                          className="brandbutton cursor-default"
                                          size="small"
                                          variant="contained"
                                          color="secondary"
                                        >{selectedTabData.make ? getNewBrand(selectedTabData.make) : "-"}</Button>
                                      </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <div className="info-sub-title-value mt-3">Credit Amount</div>
                                      <div className="subTitle mt-1 mb-3">
                                        <Button
                                          className="creditAmountButton cursor-default"
                                          size="small"
                                          variant="contained"
                                          color="secondary"
                                        >AED {selectedTabData.existingCreditAmount == 0 ? 0 : selectedTabData.existingCreditAmount ? selectedTabData.existingCreditAmount : '-'}</Button>
                                      </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <div className="info-sub-title-value mt-3">Credit Period (Days)</div>
                                      <div className="subTitle mt-1 mb-3">{selectedTabData.creditPeriodInDays ? selectedTabData.creditPeriodInDays : '-'}</div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                              <div className="col-12 m-0 p-2 border">
                                <div className="row mt-3 px-2">
                                  <h6 className="dealer-table-content mb-1 px-4 mt-0 mb-0">Bank Details</h6>
                                </div>
                                <div className="row mt-1 mb-1 px-3">
                                  {selectedTabData && selectedTabData.bankDetails && (
                                    <Grid container>
                                      <Grid item xs={3}>
                                        <div className="info-sub-title-value mt-3 px-3">Bank Name</div>
                                        <div className="subTitle mt-1 mb-3 px-3">{selectedTabData.bankDetails.bankName ? selectedTabData.bankDetails.bankName : '-'}</div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className="info-sub-title-value mt-3">Account No.</div>
                                        <div className="subTitle mt-1 mb-3">{selectedTabData.bankDetails.accountNo ? selectedTabData.bankDetails.accountNo : '-'}</div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className="info-sub-title-value mt-3">IBAN No.</div>
                                        <div className="subTitle mt-1 mb-3">{selectedTabData.bankDetails.IBANNo ? selectedTabData.bankDetails.IBANNo : '-'}</div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className="info-sub-title-value mt-3">Telephone</div>
                                        <div className="subTitle mt-1 mb-3">{selectedTabData.bankDetails.telephone ? selectedTabData.bankDetails.telephone : '-'}</div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className="info-sub-title-value mt-3 px-3">Contact Name</div>
                                        <div className="subTitle mt-1 mb-3 px-3">{selectedTabData.bankDetails.contactName ? selectedTabData.bankDetails.contactName : '-'}</div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className="info-sub-title-value mt-3">Address</div>
                                        <div className="subTitle mt-1 mb-3">{selectedTabData.bankDetails.address ? selectedTabData.bankDetails.address : '-'}</div>
                                      </Grid>
                                    </Grid>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 m-0 p-2 border">
                                <div className="row mt-3 mb-3 px-2">
                                  <h6 className="dealer-table-content px-4 mt-0 mb-0">Required Documents</h6>
                                </div>
                              </div>
                              {selectedTabData ?
                                <div className="col-lg-12 p-0 row m-0">
                                  <div className="card col-lg-6 col-md-6 border">
                                    <div className="row m-0 px-3">
                                      <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0">
                                        <h6 className="document-content pt-3 pb-2">Bank Statement {' '}*</h6>
                                        <small className="dealer-table-content">Latest 6 Months</small>
                                      </div>
                                      {selectedTabData && selectedTabData.documents.map((item: any, index: any) => {
                                        return <>
                                          {item.type == "bankStatement" && item.documentId && item.documentId.selectedFileName && (
                                            <div className="col-md-4 col-lg-4 pr-3 mb-2 pl-0 mt-2">
                                              <div className="subTitle mt-2 mb-2">
                                                <span className="info-sub-header-document pr-2 mt-0" onClick={() => { window.open(item.documentId.url) }}>
                                                  {item.type == "bankStatement" && item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"}
                                                </span>
                                                <IconButton className="p-1" aria-label="download"
                                                  onClick={() => { window.open(item.documentId.url) }}>
                                                  {item.type == "bankStatement" && item.documentId.selectedFileName ?
                                                    <GetAppIcon className="file-close-color" fontSize="small" />
                                                    : ""}
                                                </IconButton>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      })}
                                    </div>
                                  </div>
                                  <div className="card col-lg-6 col-md-6 border">
                                    <div className="row m-0 px-3">
                                      <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0">
                                        <h6 className="document-content pt-3 pb-2">AECB Consent Form {' '}*</h6>
                                        <small className="document-content"> ---- </small>
                                      </div>
                                      {selectedTabData && selectedTabData.documents.map((item: any, index: any) => {
                                        return <>
                                          {item.type == "aecbConsentForm" && item.documentId && item.documentId.selectedFileName && (
                                            <div className="col-md-4 col-lg-4 pr-5 mb-2 pl-0 mt-2">
                                              <div className="subTitle mt-2 mb-2">
                                                <span className="info-sub-header-document pr-2 mt-0" onClick={() => { window.open(item.documentId.url) }}>
                                                  {item.type == "aecbConsentForm" && item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"}
                                                  {/* {item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"} */}
                                                </span>
                                                <IconButton className="p-1" aria-label="download"
                                                  onClick={() => { window.open(item.documentId.url) }}
                                                >
                                                  {item.type == "aecbConsentForm" && item.documentId.selectedFileName ?
                                                    <GetAppIcon className="file-close-color" fontSize="small" />
                                                    : ""}
                                                </IconButton>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      })}
                                    </div>
                                  </div>
                                  <div className="card col-lg-6 col-md-6 border">
                                    <div className="row m-0 px-3">
                                      <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0">
                                        <h6 className="document-content pt-3 pb-2">Management Account{" "}*</h6>
                                        <small className="dealer-table-content">Current Year (Financial statement/P&L/Balance sheet)</small>
                                      </div>
                                      {selectedTabData && selectedTabData.documents.map((item: any, index: any) => {
                                        return <>
                                          {item.type == "managementAccount" && item.documentId && item.documentId.selectedFileName && (
                                            <div className="col-md-4 col-lg-4 pr-5 mb-2 pl-0 mt-2">
                                              <div className="subTitle mt-2 mb-2">
                                                <span className="info-sub-header-document pr-2 mt-0" onClick={() => { window.open(item.documentId.url) }}>
                                                  {item.type == "managementAccount" && item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"}
                                                  {/* {item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"} */}
                                                </span>
                                                <IconButton className="p-1" aria-label="download"
                                                  onClick={() => { window.open(item.documentId.url) }}
                                                >
                                                  {item.type == "managementAccount" && item.documentId.selectedFileName ?
                                                    <GetAppIcon className="file-close-color" fontSize="small" />
                                                    : ""}
                                                </IconButton>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      })}
                                    </div>
                                  </div>
                                  <div className="card col-lg-6 col-md-6 border">
                                    <div className="row m-0 px-3">
                                      <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0">
                                        <h6 className="document-content pt-3 pb-2">VAT Return Statement {" "}*</h6>
                                        <small className="dealer-table-content">Last 2 Year</small>
                                      </div>
                                      {selectedTabData && selectedTabData.documents.map((item: any, index: any) => {
                                        return <>
                                          {item.type == "vatReturnStatement" && item.documentId && item.documentId.selectedFileName && (
                                            <div className="col-md-4 col-lg-4 pr-5 mb-2 pl-0 mt-2">
                                              <div className="subTitle mt-2 mb-2">
                                                <span className="info-sub-header-document pr-2 mt-0" onClick={() => { window.open(item.documentId.url) }}>
                                                  {item.type == "vatReturnStatement" && item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"}
                                                  {/* {item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"} */}
                                                </span>
                                                <IconButton className="p-1" aria-label="download"
                                                  onClick={() => { window.open(item.documentId.url) }}
                                                >
                                                  {item.type == "vatReturnStatement" && item.documentId.selectedFileName ?
                                                    <GetAppIcon className="file-close-color" fontSize="small" />
                                                    : ""}
                                                </IconButton>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      })}
                                    </div>
                                  </div>
                                  <div className="card col-lg-6 col-md-6 border">
                                    <div className="row m-0 px-3">
                                      <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0">
                                        <h6 className="document-content pt-3 pb-2">Contract {" "}*</h6>
                                        <small className="document-content"> ---- </small>
                                      </div>
                                      {selectedTabData && selectedTabData.documents.map((item: any, index: any) => {
                                        return <>
                                          {item.type == "contract" && item.documentId && item.documentId.selectedFileName && (
                                            <div className="col-md-4 col-lg-4 pr-5 mb-2 pl-0 mt-2">
                                              <div className="subTitle mt-2 mb-2">
                                                <span className="info-sub-header-document pr-2 mt-0" onClick={() => { window.open(item.documentId.url) }}>
                                                  {item.type == "contract" && item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"}
                                                  {/* {item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"} */}
                                                </span>
                                                <IconButton className="p-1" aria-label="download"
                                                  onClick={() => { window.open(item.documentId.url) }}
                                                >
                                                  {item.type == "contract" && item.documentId.selectedFileName ?
                                                    <GetAppIcon className="file-close-color" fontSize="small" />
                                                    : ""}
                                                </IconButton>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      })}
                                    </div>
                                  </div>
                                </div>
                                : ""}
                              <div className="col-12 m-0 p-2 border">
                                <div className="row mt-3 mb-3 px-2">
                                  <h6 className="dealer-table-content px-4 mt-0 mb-0">Additional Documents</h6>
                                </div>
                              </div>
                              {selectedTabData &&
                                <div className="col-lg-12 p-0 row m-0">
                                  <div className="card col-lg-6 col-md-6 border">
                                    <div className="row m-0 px-3">
                                      <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0">
                                        <h6 className="document-content pt-3 pb-2">Tenancy Contract Copy</h6>
                                        <small className="dealer-table-content">Proof Of Address</small>
                                      </div>
                                      {selectedTabData && selectedTabData.documents.map((item: any, index: any) => {
                                        return <>
                                          {item.type == "tenancyContractCopy" && item.documentId && item.documentId.selectedFileName && (
                                            <div className="col-md-4 col-lg-4 pr-5 mb-2 pl-0 mt-2">
                                              <div className="subTitle mt-2 mb-2">
                                                <span className="info-sub-header-document pr-2 mt-0" onClick={() => { window.open(item.documentId.url) }}>
                                                  {item.type == "tenancyContractCopy" && item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"}
                                                  {/* {item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"} */}
                                                </span>
                                                <IconButton className="p-1" aria-label="download"
                                                  onClick={() => { window.open(item.documentId.url) }}
                                                >
                                                  {item.type == "tenancyContractCopy" && item.documentId.selectedFileName ?
                                                    <GetAppIcon className="file-close-color" fontSize="small" />
                                                    : ""}
                                                </IconButton>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      })}
                                    </div>
                                  </div>

                                  <div className="card col-lg-6 col-md-6 border">
                                    <div className="row m-0 px-3">
                                      <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0">
                                        <h6 className="document-content pt-3 pb-2">Audited Financial Statements</h6>
                                        <small className="dealer-table-content">Last 3 Years</small>
                                      </div>
                                      {selectedTabData && selectedTabData.documents.map((item: any, index: any) => {
                                        return <>
                                          {item.type == "auditedFinancialStatement" && item.documentId && item.documentId.selectedFileName && (
                                            <div className="col-md-4 col-lg-4 pr-5 mb-2 pl-0 mt-2">
                                              <div className="subTitle mt-2 mb-2">
                                                <span className="info-sub-header-document pr-2 mt-0" onClick={() => { window.open(item.documentId.url) }}>
                                                  {item.type == "auditedFinancialStatement" && item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"}
                                                  {/* {item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"} */}
                                                </span>
                                                <IconButton className="p-1" aria-label="download"
                                                  onClick={() => { window.open(item.documentId.url) }}
                                                >
                                                  {item.type == "auditedFinancialStatement" && item.documentId.selectedFileName ?
                                                    <GetAppIcon className="file-close-color" fontSize="small" />
                                                    : ""}
                                                </IconButton>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      })}
                                    </div>
                                  </div>
                                  <div className="card col-lg-6 col-md-6 border">
                                    <div className="row m-0 px-3">
                                      <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0">
                                        <h6 className="document-content pt-3 pb-2">Authorization Letter</h6>
                                        <small className="document-content"> ---- </small>
                                      </div>
                                      {selectedTabData && selectedTabData.documents.map((item: any, index: any) => {
                                        return <>
                                          {item.type == "authorizationLatter" && item.documentId && item.documentId.selectedFileName && (
                                            <div className="col-md-4 col-lg-4 pr-5 mb-2 pl-0 mt-2">
                                              <div className="subTitle mt-3 mb-2">
                                                <span className="info-sub-header-document pr-2 mt-0" onClick={() => { window.open(item.documentId.url) }}>
                                                  {item.type == "authorizationLatter" && item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"}
                                                  {/* {item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"} */}
                                                </span>
                                                <IconButton className="p-1" aria-label="download"
                                                  onClick={() => { window.open(item.documentId.url) }}
                                                >
                                                  {item.type == "authorizationLatter" && item.documentId.selectedFileName ?
                                                    <GetAppIcon className="file-close-color" fontSize="small" />
                                                    : ""}
                                                </IconButton>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      })}
                                    </div>
                                  </div>
                                  <div className="card col-lg-6 col-md-6 border">
                                    <div className="row m-0 px-3">
                                      <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0">
                                        <h6 className="document-content pt-3 pb-2">RTA Report/Fleet List Report</h6>
                                        <small className="document-content"> ---- </small>
                                      </div>
                                      {selectedTabData && selectedTabData.documents.map((item: any, index: any) => {
                                        return <>
                                          {item.type == "rtaFleetListReport" && item.documentId && item.documentId.selectedFileName && (
                                            <div className="col-md-4 col-lg-4 pr-5 mb-2 pl-0 mt-2">
                                              <div className="subTitle mt-2 mb-2">
                                                <span className="info-sub-header-document pr-2 mt-0" onClick={() => { window.open(item.documentId.url) }}>
                                                  {item.type == "rtaFleetListReport" && item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"}
                                                  {/* {item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"} */}
                                                </span>
                                                <IconButton className="p-1" aria-label="download"
                                                  onClick={() => { window.open(item.documentId.url) }}
                                                >
                                                  {item.type == "rtaFleetListReport" && item.documentId.selectedFileName ?
                                                    <GetAppIcon className="file-close-color" fontSize="small" />
                                                    : ""}
                                                </IconButton>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      })}
                                    </div>
                                  </div>
                                  <div className="card col-lg-6 col-md-6 border">
                                    <div className="row m-0 px-3">
                                      <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0">
                                        <h6 className="document-content pt-3 pb-2">Company Profile Documents</h6>
                                        <small className="document-content"> ---- </small>
                                      </div>
                                      {selectedTabData && selectedTabData.documents.map((item: any, index: any) => {
                                        return <>
                                          {item.type == "companyProfileDocument" && item.documentId && item.documentId.selectedFileName && (
                                            <div className="col-md-4 col-lg-4 pr-5 mb-2 pl-0 mt-2">
                                              <div className="subTitle mt-2 mb-2">
                                                <span className="info-sub-header-document pr-2 mt-0" onClick={() => { window.open(item.documentId.url) }}>
                                                  {item.type == "companyProfileDocument" && item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"}
                                                  {/* {item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"} */}
                                                </span>
                                                <IconButton className="p-1" aria-label="download"
                                                  onClick={() => { window.open(item.documentId.url) }}
                                                >
                                                  {item.type == "companyProfileDocument" && item.documentId.selectedFileName ?
                                                    <GetAppIcon className="file-close-color" fontSize="small" />
                                                    : ""}
                                                </IconButton>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      })}
                                    </div>
                                  </div>
                                  <div className="card col-lg-6 col-md-6 border">
                                    <div className="row m-0 px-3">
                                      <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0">
                                        <h6 className="document-content pt-3 pb-2">Additional Documents</h6>
                                        <small className="dealer-table-content">Latest 6 Months</small>
                                      </div>
                                      {selectedTabData && selectedTabData.documents.map((item: any, index: any) => {
                                        return <>
                                          {item.type == "additionalDocuments" && item.documentId && item.documentId.selectedFileName && (
                                            <div className="col-md-4 col-lg-4 pr-5 mb-2 pl-0 mt-2">
                                              <div className="subTitle mt-2 mb-2">
                                                <span className="info-sub-header-document pr-2 mt-0" onClick={() => { window.open(item.documentId.url) }}>
                                                  {item.type == "additionalDocuments" && item.documentId.selectedFileName ? item.documentId.selectedFileName : "-"}
                                                </span>
                                                <IconButton className="p-1" aria-label="download"
                                                  onClick={() => { window.open(item.documentId.url) }}
                                                >
                                                  {item.type == "additionalDocuments" && item.documentId.selectedFileName ?
                                                    <GetAppIcon className="file-close-color" fontSize="small" />
                                                    : ""}
                                                </IconButton>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      })}
                                    </div>
                                  </div>
                                </div>
                              }
                            </>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                  </Paper>
                </ThemeProvider>
                <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
                  <div className="col-12 d-flex mt-2 mb-2 p-0">
                    <div className="col-6 px-4">
                      <p className="mt-1 ml-4 shipAdd">Dealer</p>
                      <p className="subTitle mt-1 mb-1 px-4">{getDealerCreditRequestAPI && getDealerCreditRequestAPI.data && getDealerCreditRequestAPI.data.length > 0 && getDealerCreditRequestAPI.data[0].companyName}</p>
                    </div>
                    {userType !== 'afg_super_admin' && (
                      <>
                        {(selectedTab && !selectedTabData.existingCreditAmount ?
                          <div className="col-6 onboard-footer">
                            <div className="">
                              <Button
                                disabled
                                onClick={(e) => { e.preventDefault(); isDisabled(); }}
                                variant="contained" color="primary" className={`blueActionButton border-left rounded-pill ml-4 mt-2 mb-2`} >Additional Credit Request</Button>
                            </div>
                          </div>
                          :
                          <div className="col-6 onboard-footer">
                            <div className="">
                              <Button
                                // disabled={isDisabled()}
                                onClick={(e) => { e.preventDefault(); isDisabled(); }}
                                variant="contained" color="primary" className={`blueActionButton border-left rounded-pill ml-4 mt-2 mb-2`} >Additional Credit Request</Button>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Dialog className="pb-0"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <AdditionalCreditRequestKAM setOpenDialog={handleClose} selectedBrand={selectedTab} />
      </Dialog>
      {/* <ActionButtonsKAM
        saveCallback={() => {
          handleClose();
          // additionalCreditRequestDisabled();
        }}
      /> */}
    </Fragment >
  );
}