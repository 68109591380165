import React, { Fragment, Dispatch, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { get } from "lodash";
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import NotificationIcon from '../../common/components/NotificationIcon';
import { creditLimitService } from "../../services/creditLimit.service";
import _ from 'lodash';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        flex: '1 1 100%',
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 120,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    correctionTextCSS: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    }
}));

export default function ViewCreditDetails(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const history: any = useHistory();
    const [open, setOpen] = React.useState(false);
    const [requestData, setrequestData] = React.useState<any>(null);
    var brandData: any = sessionStorage.getItem('creditBrand');
    brandData = brandData ? JSON.parse(brandData) : null;
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getInitialData = async () =>{
        let data: any = await creditLimitService.getDefaultCreditAdditionalData(brandData && brandData.id, sapCustomerId);
        setrequestData(data.data)    
    }

    useEffect(() => {
        getInitialData();    
    }, [])

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <h2 className="page-title">
                    Credit Details
                </h2>
                <div className="ml-auto">
                    <AnnouncementIcon />
                    <NotificationIcon />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mt-2 mb-1">
                    <div className="d-flex align-items-center mb-2">
                        <h6 className="page-sub-title mb-0 mt-2">View Credit Details And Docs For '{requestData && requestData.make}'</h6>
                        <div className="row-space-start ml-auto sm">
                            <Button variant="contained" color="primary" onClick={(e) => { e.preventDefault(); history.push('/orgsetting'); }} className="blueActionButton border-left rounded-pill ml-4">Back </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card col-12 m-0 p-2">
                <div className="row mt-1 mb-1 px-3">
                    <Grid container>
                        <Grid item xs={3}>
                            <div className="info-sub-title-value mt-3 px-3">Brand</div>
                            <div className="subTitle mt-1 mb-3 px-3">
                                <Button
                                    className="brandbutton cursor-default"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                >{requestData && requestData.make}</Button>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="info-sub-title-value mt-3">Credit Amount</div>
                            <div className="subTitle mt-1 mb-3">
                                <Button
                                    className="creditAmountButton cursor-default"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                >AED {requestData && requestData.creditAmount}</Button>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="info-sub-title-value mt-3">Credit Period (Days)</div>
                            <div className="subTitle mt-1 mb-3">{requestData && requestData.creditPeriodInDays} Days</div>
                        </Grid>
                        {/* <Grid item xs={3}>
                            <div className="info-sub-title-value mt-3">Credit Control Area</div>
                            <div className="subTitle mt-1 mb-3">{requestData && requestData.make} </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="info-sub-title-value mt-3 px-3">Credit Control Area</div>
                            <div className="info-sub-header-document  mt-1 mb-3 px-3">{requestData && requestData.make} </div>
                        </Grid> */}
                    </Grid>
                </div>
            </div>
            <div className="card col-12 m-0 p-2">
                <div className="row mt-3 px-2">
                    <h6 className="dealer-table-content mb-1 px-4 mt-0 mb-0">Bank Details</h6>
                </div>
                <div className="row mt-1 mb-1 px-3">
                    <Grid container>
                        <Grid item xs={3}>
                            <div className="info-sub-title-value mt-3 px-3">Bank Name</div>
                            <div className="subTitle mt-1 mb-3 px-3">{requestData && requestData.bankDetails && requestData.bankDetails.bankName}</div>                        </Grid>
                        <Grid item xs={3}>
                            <div className="info-sub-title-value mt-3">Account No.</div>
                            <div className="subTitle mt-1 mb-3">{requestData && requestData.bankDetails && requestData.bankDetails.accountNumber}</div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="info-sub-title-value mt-3">IBAN No.</div>
                            <div className="subTitle mt-1 mb-3">{requestData && requestData.bankDetails && requestData.bankDetails.bankIBAN}</div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="info-sub-title-value mt-3">Telephone</div>
                            <div className="subTitle mt-1 mb-3">{requestData && requestData.bankDetails && requestData.bankDetails.telephone}</div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="info-sub-title-value mt-3 px-3">Contact Name</div>
                            <div className="subTitle mt-1 mb-3 px-3">{requestData && requestData.bankDetails && requestData.bankDetails.contactName}</div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="info-sub-title-value mt-3">Address</div>
                            <div className="subTitle mt-1 mb-3">{requestData && requestData.bankDetails && requestData.bankDetails.bankAddress}</div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="card col-12 m-0 p-2">
                <div className="row mt-3 mb-1 px-3">
                    <Grid container>
                        <Grid item xs={3}>
                            <div className="subTitle mt-1 mb-3 px-3">
                                <IconButton size="small" className="" onClick={() => { 
                                     window.open('') 
                                }}>
                                    <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs mr-2 px-2 mt-2" onClick={() => {
                                }}>Bank Statement
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="subTitle mt-1 mb-3">
                                <IconButton size="small" className="" onClick={() => { 
                                     window.open('') 
                                }}>
                                    <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs mr-2 px-2 mt-2" onClick={() => {
                                }}>AECB Consent Form
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="subTitle mt-1 mb-3">
                                <IconButton size="small" className="" onClick={() => { 
                                     window.open('') 
                                }}>
                                    <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs mr-2 px-2 mt-2" onClick={() => {
                                }}>Audited Financial Statement
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="subTitle mt-1 mb-3">
                                <IconButton size="small" className="" onClick={() => { 
                                     window.open('') 
                                }}>
                                    <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs mr-2 px-2 mt-2" onClick={() => {
                                }}>Management Account
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="subTitle mt-1 mb-3 px-3">
                                <IconButton size="small" className="" onClick={() => { 
                                     window.open('') 
                                }}>
                                    <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs mr-2 px-2 mt-2" onClick={() => {
                                }}>VAT Return Statement
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="subTitle mt-1 mb-3">
                                <IconButton size="small" className="" onClick={() => { 
                                     window.open('') 
                                }}>
                                    <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs mr-2 px-2 mt-2" onClick={() => {
                                }}>Contract
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="subTitle mt-1 mb-3">
                                <IconButton size="small" className="" onClick={() => { 
                                     window.open('') 
                                }}>
                                    <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs mr-2 px-2 mt-2" onClick={() => {
                                }}> Tenancy Contract Copy
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="subTitle mt-1 mb-3">
                                <IconButton size="small" className="" onClick={() => { 
                                     window.open('') 
                                }}>
                                    <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs mr-2 px-2 mt-2" onClick={() => {
                                }}>Authorization Letter
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="subTitle mt-1 mb-3 px-3">
                                <IconButton size="small" className="" onClick={() => { 
                                     window.open('') 
                                }}>
                                    <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs mr-2 px-2 mt-2" onClick={() => {
                                }}>RTA Reports/Fleet List Report
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="subTitle mt-1 mb-3">
                                <IconButton size="small" className="" onClick={() => { 
                                     window.open('') 
                                }}>
                                    <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs mr-2 px-2 mt-2" onClick={() => {
                                }}>Company Profile Documents
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="subTitle mt-1 mb-3">
                                <IconButton size="small" className="" onClick={() => { 
                                     window.open('') 
                                }}>
                                    <AssignmentReturnedIcon className="download-icon mr-1" />
                                </IconButton>
                                <span className="download-docs mr-2 px-2 mt-2" onClick={() => {
                                }}>Additional Documents
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Fragment>
    );
}