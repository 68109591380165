import axios from "axios";
import queryString from "query-string";
import _ from "lodash";
import { API_ENDPOINTS, FETCH_TIMEOUT } from "../constants/apiConfig";
import { get } from "../exportLogout";
import { v4 } from "uuid";

const axiosInstance: any = axios.create();

const timeoutPromise = (timeout: any) =>
  new Promise((resolve, reject) => setTimeout(() => reject(new Error("Network timeout")), timeout));

const fetchData = async (url: string, options: any) => {
  try {
    const fetchResult = await Promise.race([axiosInstance.request(url, options), timeoutPromise(FETCH_TIMEOUT)]);
    return handleResponse(fetchResult);
  } catch (error:any) {
    if (error?.response?.status != 304) {
      let errorMessage = error && error.response && error.response.data ?
        error.response.data.error && error.response.data.error.rejectionMsg ?
          error.response.data.error.rejectionMsg.toString() : error.response.data.error ?
            error.response.data.error : '' : '';
      let refLogout = get()
      if (refLogout && refLogout.current && error && error.response && error.response.status == 403) {
        refLogout.current.callLogout(errorMessage);
      }
      return {
        message: errorMessage,
        status: error && error.response && error.response.status ? error.response.status : error.response,
        statusText: error && error.response && error.response.statusText ? error.response.statusText : error.response,
        error: errorMessage
      };
    }
  }
};

function handleResponse(response: any) {
  const contentType = _.get(response, "headers.content-type", "");
  if (contentType && contentType.includes("application/json")) {
    return response ? response.data : [];
  }
  if ((contentType && contentType.includes("text/plain")) || contentType.includes("text/html")) {
    return response ? response.text : "";
  }
  throw new Error(`Sorry, content-type ${contentType} not supported`);
}

export const makeRequest = async ({ cors_url, path, method, customHeaders, queryParams, bodyObj, formdata }: any) => {
  const { url, query } = queryString.parseUrl(path);
  const newQueryString = queryString.stringify({ ...query, ...queryParams });
  let newURL = newQueryString ? `${url}?${newQueryString}` : url;
  const data = bodyObj ? formdata ? bodyObj : JSON.stringify(bodyObj) : null;
  customHeaders = customHeaders ? customHeaders : {};
  customHeaders['Unique-Reference-Code'] = v4()
  if (!_.isEmpty(cors_url)) {
    newURL = cors_url + newURL;
  }

  const currentTime = Date.now();
  const sessionExpiry = sessionStorage.getItem("sessionExpiry");

  let refLogout = get()
  if (currentTime > Number(sessionExpiry) && refLogout && refLogout.current) {
    refLogout.current.callLogout();
  } else {
    sessionStorage.setItem("sessionExpiry", String(currentTime + 3600000));
  }

  let currentTimeStamp = new Date().getTime();
  let accessTokenExpTime: any = sessionStorage.getItem('accessTokenExpiresOn') ? sessionStorage.getItem('accessTokenExpiresOn') : 0;
  accessTokenExpTime = parseInt(accessTokenExpTime);
  let refreshTokenExpTime: any = sessionStorage.getItem('refreshTokenExpiresOn') ? sessionStorage.getItem('refreshTokenExpiresOn') : 0;
  refreshTokenExpTime = parseInt(refreshTokenExpTime);
  let userOid: any = sessionStorage.getItem('userOid') ? sessionStorage.getItem('userOid') : null;
  if (!customHeaders) customHeaders = {};
  if (userOid) {
    customHeaders['state'] = userOid;
  }
  if (!url.includes('oauth/user')) {
    if (sessionStorage.getItem('accessToken') && currentTimeStamp < accessTokenExpTime) {
      customHeaders['Authorization'] = `Bearer ${sessionStorage.getItem('accessToken')}`
    }
    else if ((sessionStorage.getItem('refreshToken')) && currentTimeStamp < refreshTokenExpTime) {
      const formData: any = {
        refreshToken: sessionStorage.getItem('refreshToken'),
        userId: sessionStorage.getItem('userId'),
        email: sessionStorage.getItem('email'),
        source: sessionStorage.getItem('userType') === 'dealerUser' ? 'dealer' : sessionStorage.getItem('userType') === 'creditTeam' ? 'creditTeam' : 'admin'
      }
      await axios.post(API_ENDPOINTS.VALIDATE_TOKEN_ENDPOINT, formData, {
        headers: {
          state: userOid
        }
      })
        .then((response: any) => {
          if (response && response.data && response.data.data) {
            let exp: any = parseInt(response.data.data.accessTokenExpireOn) * 1000;
            sessionStorage.setItem('accessToken', response.data.data.accessToken);
            sessionStorage.setItem('accessTokenExpiresOn', exp)
            customHeaders['Authorization'] = `Bearer ${sessionStorage.getItem('accessToken')}`
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      // logout code here and clear session storage
    }
  }

  return await fetchData(newURL, {
    method,
    headers: customHeaders,
    data: data,
  });
};
