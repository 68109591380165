import React, { Dispatch, useState } from "react";
import "./SpecialOffers.css";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import XLSX from 'xlsx';
import offerBannerBG from "./../../assets/offersBanner.png";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getSpecialOffers } from "../../store/actions/specialOffers.action";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { get } from 'lodash';
import NotificationIcon from "../../common/components/NotificationIcon";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import { getUniqueEnquirySessionId } from "../../store/actions/createUpdateEnquiry.actions";
import { getUniqueTimestamp } from "../../common/components/util";

const useStyles = makeStyles({
  table: {},
  checkbox: {
    position: "absolute",
    right: "3px",
  },
});

const SpecialOfferDetail: React.FC = () => {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const userType = get(profileDetails, "data.data.basicInfo.userType", "");

  const columns = [
    {
      id: "part",
      label: "Part",
      align: "left",
    },
    {
      id: "before",
      label: "Before",
      align: "left",
    },
    {
      id: "after",
      label: "After",
      align: "left",
    },
  ];

  useEffect(() => {
    dispatch(getSpecialOffers());
  }, [dispatch]);

  const [checked, setChecked]: any = useState([]);
  const [checkedExport, setCheckedExport]: any = useState([]);
  const [enableAddButton, setEnableAddButton] = useState(false);
  const history = useHistory();
  const { specialOffers }: any = useSelector(
    (state: any) => state.specialOffers
  );

  const handleCheckbox = (data: any) => {
    let filter = [];
    let checkedExportData = [];
    const duplicate = checked.find((check: any) => {
      return check.id === data.id;
    });

    if (duplicate) {
      filter = checked.filter((check: any) => {
        return check.id !== data.id;
      });
      checkedExportData = checkedExport.filter((checkedExport: any) => {
        return checkedExport.id !== data.id;
      })
    } else {
      checked.push({
        id: data.id,
        productId: data.Part,
        quantity: 1,
      });
      checkedExport.push(
        {
          id: data.id,
          name: data.name,
          Part: data.Part,
          description: data.description,
          Part_After_Price: data.Part_After_Price,
          Part_Before_Price: data.Part_Before_Price
        }
      )
      filter = checked;
      checkedExportData = checkedExport;
    }
    setEnableAddButton(filter.length > 0);
    setChecked(filter);
    setCheckedExport(checkedExportData);
  };



  const specialOffersUnavailable =
    !specialOffers ||
    specialOffers.data === null ||
    specialOffers.data.message === "Network Error";

  const exportData = () => {
    const specialOffersList = specialOffers && specialOffers.data ? specialOffers.data : [];
    let dataSet: any = checkedExport.length > 0 ? [...checkedExport] : [...specialOffersList];
    let filename = 'SpecialOffers.xlsx';
    let data: any = [];
    dataSet && dataSet.length > 0 && dataSet.map((item: any, index: any) => {
      data.push({
        Name: item.name,
        PartNumber: item.Part,
        Description: item.description,
        PartAfterPrice: item.Part_After_Price,
        PartBeforePrice: item.Part_Before_Price
      });
      return undefined;
    });
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `SpecialOffers`);
    XLSX.writeFile(wb, filename);
  }

  const showCreateChangeEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);

  return (
    <>
      {specialOffers && specialOffers.loading && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h4 className="page-title">
          Special Offers
        </h4>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12">
          <div className="cardCommon">
            <div className="offers-top-section" style={{ backgroundImage: `url(${offerBannerBG})` }}>
              <div className="row justifyContentHorizontalSpacebetween align-items-center">
                <div className="offers-top-content ml-5 pl-3 mt-5 pt-3">
                  Offers
                </div>
                <div className="offers-top-sub-content mt-5 pt-4 mr-5">
                  {/* Valid till 31 Aug 2021s */}
                </div>
              </div>
              <div className="ml-5 pl-1 mt-2 offers-top-sub-content">
                Offers to suit every need.
              </div>
            </div>

            <div className="col-12 col-lg-12 col-md-12 col-sm-12 d-flex justifyContentHorizontalSpacebetween mb-1">
              <div className="offers-part-listing mt-2 ml-2 mr-auto">
                Offer Parts listing
                {/* <span className="click-part-copy ml-3">
                  Click on part number to copy.
                </span> */}
              </div>
              <div className="currency mt-2 pt-1 mr-4">Currency : AED</div>
              <IconButton size="small" className="mt-2" onClick={() => exportData()}>
                <AssignmentReturnedIcon className="download-icon mr-1" />
                <span className="download-offer mr-2">
                  Download Offer Parts & Descriptions List
                </span>
              </IconButton>
            </div>
            <div className="row px-3 pb-3 m-0">
              {specialOffersUnavailable
                ? null
                : specialOffers.data.map((data: any, index: number) => {
                  return (
                    <div className="border col-2 h-100 p-0" key={index}>
                      <Box>
                        {userType !== "KAM" && showCreateChangeEnquiry &&
                          <Tooltip title="Select this box to add part in enquiry" placement="top-start">
                            <Checkbox
                              onChange={() => handleCheckbox(data)}
                              className={`${classes.checkbox}`}
                              color="secondary"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </Tooltip>
                        }
                        <div className="text-center">
                          <div className="image-container">
                          <img
                            alt="resource"
                            src={data.thumbnail.renditionList[0].resourceUri}
                            className="rounded-circle mt-4 mb-1"
                          />
                          </div>
                          <Box className="text-center">
                            <Button
                              title={data.title}
                              className="special-offers-button mb-1 w-100"
                              variant="contained"
                            >
                              {data.title}
                            </Button>
                          </Box>

                          <TableContainer className="pt-2 pr-1 pl-1 pb-2">
                            <Table
                              className={`${classes.table}`}
                              aria-label="dense-table"
                              size="small"
                            >
                              <TableHead>
                                <TableRow>
                                  {columns.map((column: any) => (
                                    <TableCell
                                      key={column.id}
                                      className="header"
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell className="first-row underline-part">
                                    {data.Part}
                                  </TableCell>
                                  <TableCell className="first-row">
                                    {data.Part_Before_Price}
                                  </TableCell>
                                  <TableCell className="first-row">
                                    {data.Part_After_Price}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className="second-row" colSpan={5} style={{ textAlign: 'center', fontWeight: 600 }}>Offer Valid Till {data.validity_date}</TableCell>
                                  {/* <TableCell className="second-row"></TableCell>
                                    <TableCell className="second-row"></TableCell> */}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Box>
                    </div>
                  );
                })}
            </div>
            {enableAddButton && userType !== "KAM" && (
              <Button
                className="addOrderEnqButton"
                onClick={() => {
                  sessionStorage.setItem("partsData", JSON.stringify(checked));
                  dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                  history.push("/createenquiry");
                }}
                style={{ float: "right", marginTop: "10px" }}
                variant="contained"
                color="primary"
              >
                Add to Order Enquiry
              </Button>
            )}
          </div>
        </div>
      </div>
      <Typography
        variant="body2"
        className="special-tc mt-3 ml-4 pl-2"
        gutterBottom
      >
        Terms &amp; Conditions are:
      </Typography>
      <Typography
        variant="caption"
        display="block"
        className="special-conditions ml-3 pl-3 mt-3"
        gutterBottom
      >
        <KeyboardArrowRightIcon
          fontSize="large"
          className="font-weight-bold font-size-17"
        />{" "}
        Price will be net. No month-end rebate or any other discount will be
        applicable.
      </Typography>
      <Typography
        variant="caption"
        display="block"
        className="special-conditions ml-3 pl-3 mt-2"
        gutterBottom
      >
        <KeyboardArrowRightIcon
          fontSize="large"
          className="font-weight-bold font-size-17"
        />{" "}
        Part(s) is/are limited. Once stock has been depleted, prices will revert back
        to previous selling price.
      </Typography>
    </>
  );
};

export default SpecialOfferDetail;
