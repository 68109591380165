import { CREDITLIMIT } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { creditLimitService } from "../../services/creditLimit.service";

export const creditLimitActions = {
    getCreditLimit,
    getCreditRequest,
    updateCreditLimit,
    createCreditLimits,
    deleteCreditLimits
};

export function getCreditLimit(customerId: any, loginId: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITLIMIT.GET_CREDITLIMIT_FETCHING));
        try {
            const response = await creditLimitService.getCreditLimits(customerId, loginId);
            dispatch(apiSuccess(CREDITLIMIT.GET_CREDITLIMIT_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITLIMIT.GET_CREDITLIMIT_ERROR, error));
            return error;
        }
    };
}

export function getCreditRequest(customerId: any, loginId: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITLIMIT.GET_CREDITLIMIT_FETCHING));
        try {
            const response = await creditLimitService.getCreditRequest(customerId, loginId);
            dispatch(apiSuccess(CREDITLIMIT.GET_CREDITLIMIT_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITLIMIT.GET_CREDITLIMIT_ERROR, error));
            return error;
        }
    };
}

export function createCreditLimits(customerId: any, body: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITLIMIT.GET_CREDITLIMIT_FETCHING));
        try {
            const response = await creditLimitService.createCreditLimits(customerId, body);
            dispatch(apiSuccess(CREDITLIMIT.GET_CREDITLIMIT_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITLIMIT.GET_CREDITLIMIT_ERROR, error));
            return error;
        }
    };
}

export function updateCreditLimit(customerId: any, loginId: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITLIMIT.GET_CREDITLIMIT_FETCHING));
        try {
            const response = await creditLimitService.updateCreditLimits(customerId, loginId);
            dispatch(apiSuccess(CREDITLIMIT.GET_CREDITLIMIT_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITLIMIT.GET_CREDITLIMIT_ERROR, error));
            return error;
        }
    };
}

export function deleteCreditLimits(docid: any, type: any, requestId: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(CREDITLIMIT.GET_CREDITLIMIT_FETCHING));
        try {
            const response = await creditLimitService.deleteCreditLimits(docid, type, requestId);
            dispatch(apiSuccess(CREDITLIMIT.GET_CREDITLIMIT_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(CREDITLIMIT.GET_CREDITLIMIT_ERROR, error));
            return error;
        }
    };
}

