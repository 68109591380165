export function userAccessControl(profileDetails: any) {

    // const isAdmin = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === "Admin";

    let featureAccessibility = [];
    let inaccessibleFeatures = [];
    let inaccessibleFeaturesArray:any = ["CRE_ENQ","ENQ_LIST","ORD_LIST","SEARCH_EPC","FIND_SUPERSESSION","LIST_FAVORIT","UPDT_ENQ","PLACE_ORD","CREATE_DLV","STOCK_ENQ","VIEW_ENQ","VIEW_ORD","VIEW_CR_STATUS","MANAGE_USER","MANAGE_GBL_FAVORIT","LIST_BILL_DOC","VIEW_USERS","CURT_PREVI_MONTH_SALES","VIEW_DLV","ACCOUNT_STATEMENT","DOC_EXP","MANAGE_EXPORT_TO_EXL"];

    if (true) {
        featureAccessibility = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.accessParameter && profileDetails.data.data.accessParameter.featureAccessibility;

        if (featureAccessibility && featureAccessibility.length > 0) {
            featureAccessibility.filter((feature: any) => {
                 if(feature.value === true){
                    const index = inaccessibleFeaturesArray.indexOf(feature.featureCode);
                    if(index > -1){
                        inaccessibleFeaturesArray.splice(index, 1);
                    }
                 }
            });
        }
        // if (inaccessibleFeatures && inaccessibleFeatures.length > 0) {
        //     inaccessibleFeaturesArray = inaccessibleFeatures.map((feat: any) => feat.featureCode);
        // }
    }
    return sessionStorage.setItem('restrictFeat', inaccessibleFeaturesArray);
}