import { ASSIGNED_KAM } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  assignedKAMs: null
};

function assignedKAMsReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case ASSIGNED_KAM.GET_ASSIGNED_KAM_FETCHING:
      return {
        ...state,
        assignedKAMs: { loading: true, data: null, err: null }
      };
    case ASSIGNED_KAM.GET_ASSIGNED_KAM_SUCCESS:
      return {
        ...state,
        assignedKAMs: { loading: false, data: action.payload.data, err: null }
      };
    case ASSIGNED_KAM.GET_ASSIGNED_KAM_ERROR:
      return {
        ...state,
        assignedKAMs: { loading: false, data: null, err: action.payload }
      };
    default:
      return state;
  }
};

export default assignedKAMsReducer;
