import { getMaterialGroupService } from "../../services/materialGroups.service";
import { MATERIAL_GROUPS } from "./actionsTypes";
import { apiError, apiStart, apiSuccess } from "./apiActions";

export function getMaterialGroups() {
    return async (dispatch: any) => {
        dispatch(apiStart(MATERIAL_GROUPS.GET_MATERIAL_GROUPS_FETCHING));
        try {
            const response = await getMaterialGroupService();
            dispatch(apiSuccess(MATERIAL_GROUPS.GET_MATERIAL_GROUPS_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(MATERIAL_GROUPS.GET_MATERIAL_GROUPS_ERROR, error));
            return error;
        }
    };
}