import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useSelector } from "react-redux";
import CountryList from '../../components/Dealers/CountryList.json';
import RegionList from '../../components/Dealers/RegionList.json';

const DealerAdminDetails: React.FC = () => {
    const { dealerDetails }: any = useSelector((state: any) => state.dealer);
    let adminDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.userDetails) ? dealerDetails.data.data.userDetails : null;
    let countryData: any = adminDetails && CountryList.data.filter((item: any) => item.Code === adminDetails.countryKey)[0];
    let regionData: any = adminDetails && RegionList.data.filter((item: any) => item.Code === adminDetails.region)[0];
    return (
        <Grid container>
            <Grid item xs={4}>
                <div className="dealer-table-content pt-3">User ID</div>
                <div className="dealer-table-subcontent pt-1">{adminDetails && adminDetails.webApplicationLoginId}</div>
            </Grid>
            <Grid item xs={4}>
                <div className="dealer-table-content pt-3">Name</div>
                <div className="dealer-table-subcontent pt-1">{adminDetails && adminDetails.title + " " + adminDetails.firstName + " " + adminDetails.lastName}</div>
            </Grid>
            <Grid item xs={4}>
                <div className="dealer-table-content pt-3">Mobile</div>
                <div className="dealer-table-subcontent pt-1">{adminDetails && adminDetails.firstTelephoneNumber}</div>
            </Grid>
            <Grid item xs={4}>
                <div className="dealer-table-content pt-3">Email</div>
                <div className="dealer-table-subcontent pt-1">{adminDetails && adminDetails.email}</div>
            </Grid>
            <Grid item xs={4}>
                <div className="dealer-table-content pt-3">Type</div>
                <div className="dealer-table-subcontent pt-1">{adminDetails && adminDetails.userType}</div>
            </Grid>
            <Grid item xs={4}>
                <div className="dealer-table-content pt-3">Phone 2</div>
                <div className="dealer-table-subcontent pt-1">{adminDetails && adminDetails.mobileNumber}</div>
            </Grid>
            <Grid item xs={4}>
                <div className="dealer-table-content pt-3">Valid</div>
                <div className="dealer-table-subcontent pt-1">{adminDetails && adminDetails.validFromDate + " to " + adminDetails.validToDate}</div>
            </Grid>
            <Grid item xs={4}>
                <div className="dealer-table-content pt-3">Fax</div>
                <div className="dealer-table-subcontent pt-1">{adminDetails && adminDetails.faxNumber}</div>
            </Grid>
            <Grid item xs={4}>
                <div className="dealer-table-content pt-3">Country</div>
                <div className="dealer-table-subcontent pt-1">{countryData && countryData.Country}</div>
            </Grid>
            <Grid item xs={4}>
                <div className="dealer-table-content pt-3">Region</div>
                <div className="dealer-table-subcontent pt-1">{regionData && regionData.Region}</div>
            </Grid>
        </Grid>
    )
}

export default DealerAdminDetails
