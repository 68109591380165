import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { TextField, MenuItem } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles, Theme, createStyles, makeStyles, createTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RequestCorrectionDialog from './RequestCorrectionDialog';
import Dialog from '@material-ui/core/Dialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import ActionButtons from './ActionButtons';
import './Access.css';
import { useEffect } from 'react';
import { get } from 'lodash';
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { editDealerService } from '../../services/editDealer.service';
import { dealerService } from '../../services/dealer.service';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    flex: '1 1 100%',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function AccessKam(props: any) {
  const [selectedTab, setselectedTab] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const { selectedNewDealer, isEditDealer, dealerDetails }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
  const sapCustomerId = get(selectedNewDealer, "sapCustomerId", "");
  const [paramsdata, setparamsdata] = React.useState<any>([])
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [notfetchedInitialData, setnotfetchedInitialData] = React.useState(true);
  const [formSubmitted, setformSubmitted] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [dealerstatus, setStatus] = React.useState<any>("");
  const [sapAvailabilityChecked, setsapAvailabilityChecked] = React.useState<any>(false);
  const [formData, setformData] = React.useState<any>({
    "featureAccessibility": []
  });

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const classes = useStyles();

  useEffect(() => {
    loadInitialData()
  }, []);

  const loadInitialData = async () => {
    let paramsData = await editDealerService.getAllAcessParams();
    let data = await editDealerService.getDealerDetails(sapCustomerId, 'accessData');
    console.log(data)
    if (data && data.data) {
      setformData(data.data)
      setsapAvailabilityChecked(true)
      if (data.data.featureAccessibility && Array.isArray(data.data.featureAccessibility)) {
        let allowedIds: any = [];
        data.data.featureAccessibility.map((feature: any) => {
          if (feature.value) {
            allowedIds.push(feature.featureCode)
          }
        })
        if (paramsData && paramsData.data && paramsData.data[0] && paramsData.data[0].featureAccessibility) {
          let tempData = paramsData.data[0].featureAccessibility
          tempData.map((param: any) => {
            if (allowedIds.includes(param.featureCode)) {
              param.value = true
            } else {
              param.value = false
            }
            return param
          })
          setparamsdata(tempData)
        } else {
          setparamsdata(paramsData.data[0].featureAccessibility)
        }
      }
      else {
        setparamsdata(paramsData.data[0].featureAccessibility)
      }
      if (data.data.status) {
        // let dealerStatus=data.data.status;

        setStatus(data.data.status);

        console.log(dealerstatus, data.data.status);
      }
    }

    setnotfetchedInitialData(false)
  }

  const saveAccessTab = async () => {
    setformSubmitted(true);
    let featureData = paramsdata.filter((item: any) => item.value)
    let data = {
      "featureAccessibility": featureData,
    }
    setnotfetchedInitialData(true)
    const responseData = await editDealerService.saveUpdateAcessTab(sapCustomerId, formData.b2bUserId, data);
    setnotfetchedInitialData(false)
    if (responseData && responseData.message && responseData.message.status == "S") {
      props.setDataUpdated(false);
      setOpenErrorSnackbar(false);
      setOpenSnackbar(true);
      setSuccessMessage(responseData.message.message);
    } else {
      setOpenErrorSnackbar(true);
      setOpenSnackbar(true);
      setSuccessMessage(responseData.error ? responseData.error : "Something went Wrong");
    }
  }

  const isDisabled = (access: any) => {
    if (access.featureCode === 'SEARCH_EPC'
      || access.featureCode === 'LIST_BILL_DOC' || access.featureCode === 'ACCOUNT_STATEMENT'
      || access.featureCode === 'CURT_PREVI_MONTH_SALES' || access.featureCode === 'DOC_EXP') {
      return true
    }
  }

  return (
    <Fragment>
      {notfetchedInitialData && <FullscreenLoader />}
      <div className="card col-12 m-0 p-0">
        <div className="row mb-0 px-2">
          <div className="col-12 mt-1">
            <div className="d-flex align-items-center">
              <h5 className="page-sub-title px-2 mt-1">Customer Access</h5>
              <div className="row-space-start ml-auto">
                {isEditDealer && (
                  <Button
                    color="secondary" className="mr-2 text-info"
                    disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                    startIcon={<RotateLeftIcon className="font-size-18 mb-1" />}
                    onClick={async () => {
                      const paramsData = await editDealerService.getAllAcessParams();
                      if (paramsData && paramsData.data && paramsData.data[0] && paramsData.data[0].featureAccessibility) {
                        setparamsdata(paramsData.data[0].featureAccessibility)
                      }
                    }}
                  ><span className="onshiftoparty-font mt-1 mb-2 pr-0">Reset to Default
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card col-12 m-0 p-0">
        <div className="row mb-1 mt-1">
          {paramsdata && paramsdata.map((itemParam: any, index: any) => {
            return <div className="col-sm-12 col-md-4 col-lg-4 px-3 pr-2 mb-2 mt-2 row-space-between border-right">
              <div className="lock-indicator font-size-12 px-2">
                {itemParam.label}
              </div>
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>
                    <AntSwitch
                      checked={itemParam.value}
                      disabled={(itemParam.featureCode === 'MANAGE_EXPORT_TO_EXL') || (isEditDealer ? isDisabled(itemParam) || dealerstatus && dealerstatus === "Deactive" ? true : false : true)}
                      onChange={(e) => {
                        props.setDataUpdated(true);
                        let paramDataTemp = JSON.parse(JSON.stringify(paramsdata));
                        paramDataTemp[index].value = !itemParam.value
                        setparamsdata(paramDataTemp)
                      }} />
                  </Grid>
                </Grid>
              </Typography>
            </div>
          })}
        </div>
      </div>
      {isEditDealer && (
        <ActionButtons
          openRequestSnackbar={true}
          deactiveShow={true}
          dealerDetails={dealerDetails}
          dealerStatus={dealerstatus}
          saveCallback={() => {
            saveAccessTab()
          }}
          showSave
        />
      )}
      <ReturnRequestSnackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          setOpenSnackbar(false)
        }} message={successMessage} />
    </Fragment>
  );
}

