import { createStore, applyMiddleware, Store } from "redux";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import rootReducers from "./reducers/root.reducer";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

const loggerMiddleware = createLogger();

const getMiddleware = () => {
  if (process.env.NODE_ENV === "production") {
    return applyMiddleware(thunkMiddleware);
  } else {
    // Enable additional logging in non-production environments.
    return applyMiddleware(thunkMiddleware, loggerMiddleware);
  }
};

const store: Store = createStore(
  rootReducers,
  composeWithDevTools(getMiddleware())
);

store.subscribe(() => { 
  //called on all action dispatch 
});
export default store;
