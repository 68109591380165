import React, { Fragment } from "react";
import "./DeliveryList.css";
import IconButton from '@material-ui/core/IconButton';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/Visibility';
import NotificationIcon from "../../common/components/NotificationIcon";
import { get } from "lodash";
import { Tooltip } from "@material-ui/core";
import { getDeliveryDetails } from "../../store/actions/delivery.actions";
import FullscreenLoader from "../../common/components/FullscreenLoader";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    bodyContent: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito'
    },
    tableHead: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        minWidth: 100
    },
    actionButton: {
        color: '#1A9FE0',
    },
}));

const columns = [
    /* {
        id: 'orderEnquiryNo',
        label: 'Order Enquiry No.',
        align: 'left',
    }, */
    {
        id: 'delivery',
        label: 'Delivery No.',
        align: 'left',
    },
    {
        id: 'salesDocument',
        label: 'Order No.',
        align: 'left'
    },
    {
        id: 'description',
        label: 'Description',
        align: 'left'
    },
    {
        id: 'billingStatus',
        label: 'Billing Status',
        align: 'left'
    },
    {
        id: 'goodsMovementStatus',
        label: 'Goods Movement Status',
        align: 'left'
    },
    {
        id: 'shippingPlanningStatus',
        label: 'Shipping Planning Status',
        align: 'left'
    },
    {
        id: 'shippingReceivingPoint',
        label: 'Shipping Receiving Point',
        align: 'left'
    },
    {
        id: 'pickingStatus',
        label: 'Picking Status',
        align: 'left'
    },
];

const DeliveryList: React.FC = () => {
    const history = useHistory()
    const classes = useStyles();
    const dispatch = useDispatch();
    const { deliveryList }: any = useSelector((state: any) => state.delivery);
    const deliveryData = get(deliveryList, 'data.data.deliveryList', {});

    return (
        <Fragment>
            {deliveryList.loading && <FullscreenLoader />}
            <div className="d-flex align-items-center">
                <h2 className="page-title">
                    Delivery List
                    <Breadcrumbs>
                        <Typography color="textSecondary" className="breadcrumb-text-order-history">Order History</Typography>
                        <Typography color="textPrimary" className="breadcrumb-text-delivery-details">Delivery List</Typography>
                    </Breadcrumbs>
                </h2>
                <div className="ml-auto">
                    <AnnouncementIcon />
                    <NotificationIcon />
                </div>
            </div>
{/* 
            <div className="row mt-4">
                <div className="col-12">
                    <div className="cardCommon p-3"> */}
                        {/* <div className="d-flex align-items-center">
                            <h5 className="page-sub-title mb-2">Search Orders</h5>
                        </div>
                        <TextField
                        value={searchText}
                        onChange={(e) => {
                            setsearchText(e.target.value)
                        }}
                        size="small" className="col-4 col-sm-6 col-md-4 col-lg-4 common-input-font-size commonRoundedInputs searchField" label="Search by Order Enquiry No/Order No/Reference No" variant="outlined" />
                        <div className="col-4 col-sm-12 col-md-4 col-lg-4 d-inline-flex date-picker-50">
                            <DatePicker
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(start: any, end: any) => {
                                setstartDate(start);
                                setendDate(end);
                                }}

                            />
                        </div>
                        <div className="col-2 col-sm-6 col-md-2 col-lg-2  d-inline-flex pl-0  ">
                            <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                                <Select

                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={status}
                                onChange={(e) => {
                                    setstatus(e.target.value)
                                }}
                                label="Age"
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"Order Created"}>Order Created</MenuItem>
                                {/* <MenuItem value={"Verification Completed"}>Verification Completed</MenuItem> */}
                                {/* </Select>
                            </FormControl> */}
                        {/* </div>
                        <Button
                            className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton"
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                            }}>
                            Search
                        </Button>
                        <div className="ml-2 mt-2">
                            <Checkbox className="checkbox" />
                            <span className="myOrders">My Deliveries Only</span>
                        </div>  */}
                    {/* </div>
                </div>
            </div> */}

            <div className="row mt-4">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="d-flex align-items-center">
                        <h5 className="page-sub-title">List of Deliveries</h5>
                        <IconButton className={`ml-auto ${classes.actionButton}`} onClick={()=>{history.push(`/orderhistory`)}}>
                            <ChevronLeftIcon fontSize="small"/>
                            <span className="backLinkLabel">Back to List</span>
                        </IconButton>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="cardCommon orderItems">
                        <TableContainer className={`classes.container`}>
                            <Table aria-label="table" >
                                <TableHead className="w-100">
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Delivery No.</TableCell>
                                        <TableCell className={classes.tableHead}>Order No.</TableCell>
                                        <TableCell className={classes.tableHead}>Description</TableCell>
                                        <TableCell className={classes.tableHead}>Billing Status</TableCell>
                                        <TableCell className={classes.tableHead}>Goods Movement Status</TableCell>
                                        <TableCell className={classes.tableHead}>Shipping Planning Status</TableCell>
                                        <TableCell className={classes.tableHead}>Shipping Receiving Point</TableCell>
                                        <TableCell className={classes.tableHead}>Picking Status</TableCell>
                                        <TableCell className={classes.tableHead}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                             <TableBody>
                                    <TableRow>
                                        {
                                            columns.map((column: any) => {
                                                const value: any = deliveryData[column.id];
                                                return (
                                                    <TableCell key={column.id} className={classes.bodyContent}>
                                                        {value}
                                                    </TableCell>
                                                );
                                            })
                                        }
                                        <TableCell>
                                        <Tooltip title="View Delivery Details" placement="top">
                                            <IconButton size="small" className={classes.actionButton} onClick={() => { 
                                                dispatch(getDeliveryDetails(sessionStorage.getItem('selectedOrderEnquiryNumber'), deliveryData.delivery, sessionStorage.getItem('selectedOrderMake')));
                                                history.push(`/deliverydetails`)}}
                                            >
                                                <VisibilityIcon fontSize="inherit"/>
                                            </IconButton>   
                                        </Tooltip>
                                        </TableCell>
                                    </TableRow>
                            
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <div className="col-12 d-flex justify-content-end">
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </div> */}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default DeliveryList;