
import { ORDER_ENQUIRY } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { orderEnquiryService } from "../../services/orderEnquiry.service";

export const orderEnquiryActions = {
  getEnquiriesList,
  setOrderEnquiryDetails
};

export function getEnquiriesList(filterParams:any,page?:any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_FETCHING));
      const response = await orderEnquiryService.getEnquiriesList(filterParams,page);
      if (response.error) {
        dispatch(apiError(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_SUCCESS, response));
        return response || [];
      }
  };
}

export function setOrderEnquiryDetails(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_ENQUIRY.IS_ORDER_ENQUIRY_DETAILS, data));
  };
}

export function getOrderEnquiryStatus() {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_ENQUIRY.GET_ENQUIRY_STATUS_FETCHING));
    const response = await orderEnquiryService.getEnquiryStatus();
    if (response.error) {
      dispatch(apiError(ORDER_ENQUIRY.GET_ENQUIRY_STATUS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ORDER_ENQUIRY.GET_ENQUIRY_STATUS_SUCCESS, response));
      return response || [];
    }
  }
}


