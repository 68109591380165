import React, { Fragment, Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, Dialog, MenuItem, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { approveCreditRequest, getCreditRequestDetails } from '../../store/actions/creditRequest.actions';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    flex: '1 1 100%',
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 120,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  message: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  },
  correctionTextCSS: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  }
}));

export default function CreditRequestApproveDialog(props: any) {
  const dispatch: Dispatch<any> = useDispatch();
  const classes = useStyles();
  const [additionalRevisedCreditLimit, setAdditionalRevisedCreditLimit] = React.useState<any>('');
  const [additionalRevisedCreditLimitError, setAdditionalRevisedCreditLimitError] = React.useState<boolean>(false);
  const [invalidAdditionalRevisedCreditLimit, setInvalidAdditionalRevisedCreditLimit] = React.useState<boolean>(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [message, setMessage] = React.useState<any>("");
  const [messageError, setMessageError] = React.useState<any>("");
  const [messageValidation, setMessageValidation] = React.useState<boolean>(false);
  const { creditRequestDetails }: any = useSelector((state: any) => state.creditRequest);
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const { openRequestSnackbar } = props;
  const history = useHistory();

  // ONCHANGE OF TEXTAREA-AUTOSIZE 
  const handleCustomerChange = (e: any) => {
    setMessage(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        setMessageValidation(true);
      }
      else {
        setMessageValidation(false);
      }
    }
  }

  // RESET DATA ONCLICK OF CANCEL
  const resetData = () => {
    setAdditionalRevisedCreditLimit(null);
    setAdditionalRevisedCreditLimitError(false);
    setInvalidAdditionalRevisedCreditLimit(false);
    setMessage(null);
    setMessageError(false);
    setMessageValidation(false);
  }

  // ONCHANGE OF ADDITIONAL REVISED CREDIT LIMIT
  const onChangeAdditionalCreditLimit = (e: any) => {
    setAdditionalRevisedCreditLimit(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setAdditionalRevisedCreditLimitError(true);
    }
    else {
      setAdditionalRevisedCreditLimitError(false);
    }
  }

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleopenCloseSnackbar = (openRequestSnackbar?: any) => {
    setOpenSnackbar(openRequestSnackbar ? true : false);
  };

  // VALIDATIONS FOR DILAOG 
  async function validateMessage() {
    let isValid: boolean = true;
    if (!additionalRevisedCreditLimit || additionalRevisedCreditLimit.trim() == "") {
      setAdditionalRevisedCreditLimitError(true);
      isValid = false;
      return
    } else {
      if (additionalRevisedCreditLimit == "" || additionalRevisedCreditLimit == undefined) {
        setAdditionalRevisedCreditLimitError(true);
        setIsValid(false);
        isValid = false;
      }
      else {
        setAdditionalRevisedCreditLimitError(false);
      }
      if (!message || message.trim() == "") {
        setMessageValidation(true);
        isValid = false;
        return
      } else {
        if (message.split('').length > 500) {
          setMessageValidation(false);
          setMessageError(true);
          isValid = false;
          return
        }
        else {
          setMessageValidation(false);
          setMessageError(false);
        }
      }
      if (isValid) {
        let data = {
          "approvalMessage": message,
          "actionTakenBy": sessionStorage.getItem('webApplicationLoginId'),
          "additionalRevisedCreditLimit": additionalRevisedCreditLimit,
          "action": "approve",
          "source": "creditTeam",
          "sapCustomerId": creditRequestDetails && creditRequestDetails.data && creditRequestDetails.data.data && creditRequestDetails.data.data.dealerDetails && creditRequestDetails.data.data.dealerDetails.sapCustomerId
        }
        let approveCr: any = await dispatch(approveCreditRequest(data, creditRequestDetails && creditRequestDetails.data && creditRequestDetails.data.data && creditRequestDetails.data.data.dealerDetails && creditRequestDetails.data.data.dealerDetails.id ? creditRequestDetails.data.data.dealerDetails.id : ''));
        if (approveCr && approveCr.message && approveCr.message.status === 'S') {
                    setMessageError(false);
          setMessage(null);
          props.fromResponse(true);
          props.message(approveCr.message.message);
          setAdditionalRevisedCreditLimit(null);
          props.handleopenClose(false);
        } else {
          handleopenCloseSnackbar(true);
          setOpenErrorSnackbar(true);
          setSuccessMessage(approveCr.error);
        }
      }
    }
  }

  return (
    <Fragment>
      <Dialog
        open={props.open ? true : false}
        onClose={() => { props.handleopenClose(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}>
        <Snackbar
          open={openSnackbar ? true : false}
          type={openErrorSnackbar ? 'error' : ''}
          handleopenClose={() => { handleopenCloseSnackbar(false); setAdditionalRevisedCreditLimit(null); }} message={successMessage}
        />
        <DialogContent>
          <div className={classes.root}>
            <div className="col-12 mt-1 px-0 pr-0">
              <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                <h4 className="shift-to-party-page-title">
                  <IconButton aria-label="close" className="float-right pr-5 mt-2" onClick={() => { props.handleopenClose(false); handleopenCloseSnackbar(false); resetData(); }}><CloseIcon />  </IconButton>
                </h4>
              </Typography>
            </div>
            <DialogContent>
              <div className={classes.icon}><CheckCircleIcon className="approve-warning" fontSize="small" /> </div>
              <div>
                <DialogContentText id="alert-dialog-description">
                  <div className={`mt-3 ${classes.message}`}>
                    <h6 className="mb-0"> Are you sure to approve ? </h6>
                  </div>
                </DialogContentText>
              </div>
            </DialogContent>
          </div>
          {creditRequestDetails && creditRequestDetails.data && creditRequestDetails.data.data && creditRequestDetails.data.data.dealerDetails && (
            <>
              <div className="col-12 px-3">
                <div className="col-12 px-5">
                  <div className="row  col-sm-12 mt-1 mb-1 px-3">
                    <div className="col-sm-12">
                      <div className="col-sm-12 col-md-12 col-lg-12"> Existing Credit Limit:
                        <Button className="creditAmountButtonKAM cursor-default ml-2" size="small" variant="contained" color="secondary" >AED 
                        {" "}{creditRequestDetails.data.data.dealerDetails.existingCreditAmount}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="row  col-sm-12 mt-1 mb-1 px-3">
                    <div className="col-sm-12">
                      <div className="col-sm-12 col-md-12 col-lg-12"> Additional Credit Requested :
                        <Button className="creditAmountButton cursor-default ml-2" size="small" variant="contained" color="secondary" >AED 
                          {" "}{creditRequestDetails.data.data.dealerDetails.additionalCreditAmount}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="col-12 px-3">
            <div className="col-md-12 mt-2 px-5">
              <div className="d-flex align-items-center">
                <div className="col-md-12 px-4">
                  <TextField id="additionalRevisedCreditLimit" value={additionalRevisedCreditLimit}
                    label="Additional Revised Credit Limit" required variant="outlined" margin="dense"
                    size="small" type="text" className="col-sm-12 my-2 commonRoundedInputs hiddenNo"
                    error={additionalRevisedCreditLimitError || invalidAdditionalRevisedCreditLimit}
                    helperText={invalidAdditionalRevisedCreditLimit && <span style={{ color: 'red' }}>Please select the customer type.</span>}
                    onChange={(e: any) => { setAdditionalRevisedCreditLimit(e.target.value) }}>
                  </TextField>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 px-3">
            <div className="row col-md-12 mb-0 mt-2 px-4">
              <div className="col-sm-12 col-md-12 px-5 pr-2 mb-0 mt-0">
                <div className="col-6">
                  <TextareaAutosize id="message" value={message} onChange={(e: any) => handleCustomerChange(e)}
                    aria-label="minimum height" minRows={2} placeholder="Message.." className="textArea-correction p-4"
                  />
                </div>
                {messageValidation &&
                  <span className="col-12" style={{ color: 'red' }}>Please enter message.</span>
                }
                {messageError &&
                  <span className="col-12" style={{ color: 'red' }}>Only 500 characters are allowed.</span>
                }
              </div>
            </div>
          </div>
        </DialogContent>
        <div className="col-12 mt-2 mb-2">
          <div className="d-flex align-items-center">
            <div className="row-space-start ml-auto">
              <DialogActions className="mt-3 mb-1 justify-content-end">
                <Button variant="contained" color="secondary" className="check-availability-button-access-tab"
                  onClick={() => { validateMessage(); }} >Approve
                </Button>
                <Button variant="contained" className={classes.closeButton}
                  onClick={() => { props.handleopenClose(false); resetData(); }}>Cancel
                </Button>
              </DialogActions>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment >
  );
}
