export const OPEN_CLOSE_SIDE_MENU: string = "OPEN_CLOSE_SIDE_MENU";
export const MARK_AS_ADMIN: string = "MARK_AS_ADMIN";

export function openSideMenu(sideMenuOpened: any): ILogInActionType {
    return { type: OPEN_CLOSE_SIDE_MENU, sideMenuOpened: sideMenuOpened };
}

export function markAdminUser(isAdmin: any): ILogInActionType {
    return { type: MARK_AS_ADMIN, isAdmin: isAdmin };
}


interface ILogInActionType {
    type: string;
    sideMenuOpened?: any;
    isAdmin?: any
};
