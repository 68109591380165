import { TASKS } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  tasksList: null,
  closeTask: null,
};

function tasksReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case TASKS.GET_TASKS_FETCHING:
      return {
        ...state,
        tasksList: { loading: true, data: null, err: null },
        closeTask: null,
      };
    case TASKS.GET_TASKS_SUCCESS:
      return {
        ...state,
        tasksList: { loading: false, data: action.payload.data.data, err: null }
      };
    case TASKS.GET_TASKS_ERROR:
      return {
        ...state,
        tasksList: { loading: false, data: null, err: action.payload.error }
      };
    case TASKS.CLOSE_TASKS_FETCHING:
      return {
        ...state,
        closeTask: { loading: true, data: null, err: null }
      };
    case TASKS.CLOSE_TASKS_SUCCESS:
      return {
        ...state,
        closeTask: { loading: false, data: action.payload, err: null }
      };
    case TASKS.CLOSE_TASKS_ERROR:
      return {
        ...state,
        closeTask: { loading: false, data: null, err: action.payload.error }
      };
    default:
      return state;
  }
};

export default tasksReducer;
