import React, { Fragment, Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import { Button, Dialog, MenuItem, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { onboardingService } from '../../services/onboard.service';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        flex: '1 1 100%',
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 120,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    correctionText: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    }
}));

export default function ApproveDialog(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const [selectedCustomerType, setSelectedCustomerType] = React.useState<any>('');
    const [selectedCustomerTypeError, setSelectedCustomerTypeError] = React.useState<boolean>(false);
    const [invalidSelectedCustomerType, setInvalidSelectedCustomerType] = React.useState<boolean>(false);
    const history = useHistory()
    const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
    const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
    const [isValid, setIsValid] = React.useState<boolean>(true);
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const { openRequestSnackbar } = props;

    // ONCHANGE OF SELECT CUSTOMER TYPE
    const onChangeCustomerType = (e: any) => {
        setSelectedCustomerType(e.target.value);
        // if (e.target.required) {
        if (e.target.value === "" || e.target.value.trim() === "") {
            setSelectedCustomerTypeError(true);
        }
        else {
            setSelectedCustomerTypeError(false);
        }
        // }
    }

    // VALIDATIONS FOR CUSTOMER TYPE 
    const validateCustomerType = () => {
        let isValidationSucess: boolean = true;
        setIsValid(true);
        if (selectedCustomerType == "" || selectedCustomerType == undefined) {
            setSelectedCustomerTypeError(true);
            setIsValid(false);
            isValidationSucess = false;
        }
        else {
            setSelectedCustomerTypeError(false);
        }
        if (isValidationSucess) {
            approveDealerData(); 
            props.handleopenCloseSnackbar(true);
            setSuccessMessage('Customer approved successfully');
            setSelectedCustomerType(null);
        }
    }

    // API INTEGARTION FOR CUSTOMER TYPE SELECTION  
    const approveDealerData = async () => {
        const data: any = await onboardingService.getAccessTabData(b2bUserId);
        if (data && data.data) {
            let sendData = {
                "sapCustomerId": data.data.userInfo.sapCustomerId,
                "sessionDetails": {
                    "clientIPAddress": sessionStorage.getItem('clientIp'),
                    "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
                },
                "b2bUserId": b2bUserId,
                "isApprove": true,
                "customerType": selectedCustomerType,
                "featureAccessibility": data.data.featureAccessibility,
                "addParameter": data.data.addParameter
            }
            const responseData = await onboardingService.saveUpdateAcessTab(sendData)
            setTimeout(() => {
                history.push('/dealers')
            }, 20000)
            props.handleopenClose(false)
        }
    }

    return (
        <Fragment>
            <Dialog
                open={props.open ? true : false}
                onClose={() => { props.handleopenClose(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <Snackbar
                    open={props.openRequestSnackbar ? true : false}
                    handleopenClose={() => { props.handleopenCloseSnackbar(false); setSelectedCustomerType(null); }} message={successMessage}
                />
                <DialogContent>
                    <div className={classes.root}>
                        <div className="col-12 mt-1 px-0 pr-0">
                            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                                <h4 className="shift-to-party-page-title">
                                    <IconButton aria-label="close" className="float-right pr-5 mt-2" onClick={() => { props.handleopenClose(false); props.handleopenCloseSnackbar(false); setSelectedCustomerType(null); }}>
                                        <CloseIcon />
                                    </IconButton>
                                </h4>
                            </Typography>
                        </div>
                        <DialogContent>
                            <div className={classes.icon}>
                                <WarningIcon className="delete-warning" fontSize="small" />
                            </div>
                            <div>
                                <DialogContentText id="alert-dialog-description">
                                    <div className={`mt-3 ${classes.correctionText}`}>
                                        <h6 className="mb-0"> Are you sure to approve ? </h6>
                                    </div>
                                </DialogContentText>
                            </div>
                        </DialogContent>
                    </div>
                    <div className="col-md-12 mt-2 px-5">
                        <div className="d-flex align-items-center">
                            <div className="col-md-12 px-4">
                                <TextField select id="customerType" value={selectedCustomerType}
                                    label="Select Customer Type" required variant="outlined" margin="dense"
                                    size="small" className="col-sm-12 my-2 commonRoundedInputs hiddenNo" type="number"
                                    error={selectedCustomerTypeError || invalidSelectedCustomerType}
                                    helperText={invalidSelectedCustomerType && <span style={{ color: 'red' }}>Please select the customer type.</span>}
                                    onChange={(e: any) => { setSelectedCustomerType(e.target.value) }}
                                >
                                    {props.customerTypes && props.customerTypes.map((item: any) => {
                                        return <MenuItem value={item.title}>{item.title}</MenuItem>
                                    })}
                                </TextField>
                            </div>
                            <div className="col-md-">
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <div className="col-12 mt-2 mb-2">
                    <div className="d-flex align-items-center">
                        <div className="row-space-start ml-auto">
                            <DialogActions className="mt-3 mb-1 justify-content-end">
                                <Button variant="contained"
                                    color="secondary" className="check-availability-button-access-tab"
                                    onClick={() => { validateCustomerType(); }}
                                >Approve
                                </Button>
                                <Button variant="contained" className={classes.closeButton}
                                    onClick={() => { props.handleopenClose(false); setInvalidSelectedCustomerType(false); setSelectedCustomerTypeError(false); setSelectedCustomerType(null); }}
                                >Cancel
                                </Button>
                            </DialogActions>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );
}
