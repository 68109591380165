import { API_ENDPOINTS, DEALER, ONBOARD, ORDER_ENQUIRY } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const dealerService = {
    addDealer,
    addDealerUser,
    getAllDealers,
    getAllDealersRequests,
    getDealerDetails,
    checkUserIdAvailability,
    updateDealer,
    updateDealerUser,
    getAllUsers,
    verifySAPCustomerId,
    addUserParameter,
    editUserParameter,
    updateDealerInProgressUser,
    getAccessParam,
    getKamAccessParam,
    addAccessParam,
    updateAccessParam,
    getUserParameter,
    uploadDocument,
    deleteDocument,
    getUploadedDocuments,
    sendEmailTemplate,
    getUserDetailsInfo,
    getKAMUserList,
    resendInvitationLink,
    getAllSapCustomerId,
    getAllLostSaleReason,
    getAllBrand,
    getAllCRStatus,
    getLostSaleReportData,
    getDealerCreditRequest,
    saveAdditionalCreditRequest,
    deleteBankStatementOfCreditRequest,
    generateB2bUserId,
    sendInvitationLink,
    confirmDataFromDealer,
    updateSellingPrice,
    getViewTarget
};

async function addDealer(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.ADD_DEALER;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function addUserParameter(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.ADD_PARAMETER;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getUserParameter(sapCustomerId: any, b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_USER_PARAMETER + `?sapCustomerId=${sapCustomerId}&b2bUserId=${b2bUserId}`;
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function resendInvitationLink(b2bUserId: any) {
    const data = { "b2bUserId": b2bUserId }
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.RESENDINVITATION;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getUserDetailsInfo(b2bUserId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.ADD_DEALER_USER + `/${b2bUserId}`;
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function addDealerUser(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.ADD_DEALER_USER;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {

            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getAllDealers(filterBy?: string, searchBy?: string, page?: number) {
    let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ALL_DEALERS;
    if (filterBy && filterBy.trim() !== "" && searchBy && searchBy.trim() !== "")
        apiPath = `${apiPath}?&filterBy=${filterBy.trim()}&searchBy=${searchBy.trim()}`;
    else if (filterBy && filterBy.trim() !== "")
        apiPath = `${apiPath}?&filterBy=${filterBy.trim()}`;
    else if (searchBy && searchBy.trim() !== "")
        apiPath = `${apiPath}?&searchBy=${searchBy.trim()}`;

    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 10 : 0}|no-of-records=10`
        },
    };
    return await makeRequest(options)
}


async function getAllDealersRequests(loginId?: string, searchBy?: string, filterValue?: any, page?: number) {
    let apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ALL_DEALERS_REQUESTS;
    apiPath = `${apiPath}?&loginId=${loginId}&searchBy=${searchBy}&filterBy=${filterValue}`;

    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 10 : 0}|no-of-records=10`
        },
    };
    return await makeRequest(options)
}

async function getAllUsers(id?: any, page?: any, searchBy?: any) {
    let path = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ALL_USERS + `/${id}`;

    if (searchBy && searchBy.trim() !== "") {
        path = `${path}?&searchBy=${searchBy.trim()}`;
    }

    const options = {
        path,
        method: "GET",
        bodyObj: {},
        customHeaders: {
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
    };
    return await makeRequest(options)
}

async function getDealerDetails(customerId: string, type: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_DEALER_DETAILS;
    const options = {
        path: `${apiPath}/${customerId}?loginId=${sessionStorage.getItem('webApplicationLoginId')}&details=${type}`,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
    };
    return await makeRequest(options)
}


async function checkUserIdAvailability(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.CHECK_USERID_AVAILABILITY;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateDealer(customerId: string, data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.UPDATE_DEALER + "/" + customerId;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "Put",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateDealerUser(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.UPDATE_DEALER_USER;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "Put",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateDealerInProgressUser(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.UPDATE_DEALER_SUB_USER;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "Put",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function verifySAPCustomerId(sapCustomerId: string) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.VERIFY_SAPCUSTOMERID + "/" + sapCustomerId;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Accept-Language": "EN",
            "Content-Type": "application/json"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function editUserParameter(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_USER_PARAMETER
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function addAccessParam(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.ADD_ACCESS_PARAM;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateAccessParam(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.UPDATE_ACCESS_PARAM;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getAccessParam(id: any, adminId: any, action: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ACCESS_PARAM;
    const options = {
        path: `${apiPath}/${id}/adminB2bUserId/${adminId}?action=${action}`,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
    };
    return await makeRequest(options)
}

async function getKamAccessParam(id: any, sapId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.GET_ACCESS_PARAM;
    const options = {
        path: `${apiPath}/${id}/sapCustomerId/${sapId}`,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
    };
    return await makeRequest(options)
}

async function uploadDocument(data: any, customHeaders: any) {
    const path = API_ENDPOINTS.CMS_ENDPOINT + DEALER.DOCUMENTS;

    const options = {
        path,
        method: "POST",
        bodyObj: data,
        customHeaders,
        formdata: true
    };

    return await makeRequest(options)
}

async function deleteDocument(id: any, sapCustomerId: any, document: any) {
    const data: any = { id, sapCustomerId }
    document ? data.document = 'true' : data.document = 'false';
    const path = API_ENDPOINTS.CMS_ENDPOINT + DEALER.DOCUMENTS + '/delete';

    const options = {
        path,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };

    return await makeRequest(options)
}

async function getUploadedDocuments(customHeaders: any, sapCustomerId: any) {
    const path = API_ENDPOINTS.CMS_ENDPOINT + DEALER.DOCUMENTS + `/${sapCustomerId}`;

    const options = {
        path,
        method: "GET",
        customHeaders,
    };
    return await makeRequest(options)
}

async function sendEmailTemplate(data: any) {
    const apiPath = API_ENDPOINTS.EMAIL_ENDPOINT
    const options = {
        //cors_url: API_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            'Lob-Id': 'auto',
            'Channel-Id': 'web',
            'Org-Id': '2001',
            'Paging-Info': 'start-index=0',
            'Accept-Language': 'EN',
            'Content-Type': 'application/json'
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getKAMUserList(customHeaders: any) {
    const path = API_ENDPOINTS.KAMUSERS;

    const options = {
        path,
        method: "GET",
        customHeaders,
    };
    return await makeRequest(options)
}

async function getAllSapCustomerId() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + '/sapCustomerIds';
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function getAllBrand() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + '/brands';
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function getAllCRStatus(type: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + `/creditRequestStatus?source=${type}`;
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function getAllLostSaleReason() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + '/lossSaleReason';
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}

async function getLostSaleReportData(data: any) {
    const options = {
        path: API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH + '/report',
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getDealerCreditRequest(loginId: any, sapCustomerId: any, type: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/creditLimit/${type}/?sapCustomerId=${sapCustomerId}&loginId=${loginId}`;
    const options = {
        path: apiPath,
        method: "GET",
        // bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function saveAdditionalCreditRequest(sapCustomerId: any, data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/${sapCustomerId}/creditRequest`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function deleteBankStatementOfCreditRequest(documentId: any, requestId: any, data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/creditRequest/document/delete?docId=${documentId}&requestId=${requestId}`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function generateB2bUserId(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + '/getB2bUserId';
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}
async function sendInvitationLink() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + DEALER.FORMFIELD + '';
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}
async function confirmDataFromDealer(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ONBOARD.API_PATH + '/confirmRequest';
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true
    };
    return await makeRequest(options)
}

async function updateSellingPrice(data: any, sapId: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/${sapId}/percentageForSellingPrice`;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}


async function getViewTarget(sap: any, year: any) {
    const apipath = API_ENDPOINTS.DEALER_ENDPOINT + `/targets/customer/${sap}/getTarget?year=${year}`
    const options = {
        path: apipath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
        formdata: true
    }
    return await makeRequest(options)
}
