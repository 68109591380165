
import { CREATE_UPDATE, CREATE_UPDATE_QUEUE_CHECK } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { createUpdateEnquiryService } from "../../services/createUpdateEnquiry.service ";

export const createUpdateEnquiryActions = {
  createEnquiry,
  updateEnquiry,
  getEnquiryDetails
};

export function createEnquiry(reference: any, products: any, userName?: any, userId?: any, enquirySessionId?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING));
    try {
      let sendData = {
        "masterReferenceNumber": reference,
        "products": products,
        "customerId": sessionStorage.getItem('customerId'),
        "customerName": sessionStorage.getItem('customerName'),
        "sessionDetails": {
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          "clientIPAddress": sessionStorage.getItem('clientIp'),
        },
        "dealerCode": "test01",
        "userName": userName,
        "userId": userId,
        enquirySessionId: `b2bLSR${enquirySessionId}`,
      }
      const response = await createUpdateEnquiryService.createEnquiry(sendData);
      dispatch(apiSuccess(CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.POST_CREATE_UPDATE_ERROR, error));
      return error;
    }
  };
}

export function updateEnquiry(masterId: any, products: any, isCostUpdate: any, insertData?: any, enquiryNumber?: any, enquirySessionId?: any, isConfirmation?: any) {
  let sessionId = enquirySessionId && enquirySessionId.toString();
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING));
    try {
      let sendData: any = {
        "updateData": products,
        "sessionDetails": {
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "loginPassword": "",
          "applicationSecurityKey": "",
          "initialLogonIndicator": "",
          "initialPassword": "",
          "username": "",
          "password": ""
        },
        "insertData": insertData && Array.isArray(insertData) ? insertData : [],
        "isCostUpdate": isCostUpdate,
        "isConfirmation": isConfirmation ? true : false,
        "enquiryNumber": enquiryNumber,
        "enquirySessionId": sessionId && sessionId.includes('b2bLSR') ? enquirySessionId : `b2bLSR${enquirySessionId}`,
      }
      const response = await createUpdateEnquiryService.updateEnquiry(masterId, sendData);
      dispatch(apiSuccess(CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.POST_CREATE_UPDATE_ERROR, error));
      return error;
    }
  };
}

export function verifyEnquiry(masterId: any, enquirySessionId: any) {
  let sessionId = enquirySessionId && enquirySessionId.toString();
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING));
    try {
      let sendData: any = {
        "sessionDetails": {
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "loginPassword": "",
          "applicationSecurityKey": "",
          "initialLogonIndicator": "",
          "initialPassword": "",
        },
        enquirySessionId: sessionId && sessionId.includes('b2bLSR') ? enquirySessionId : `b2bLSR${enquirySessionId}`,
      }

      const response = await createUpdateEnquiryService.verifyEnquiry(masterId, sendData);
      dispatch(apiSuccess(CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.POST_CREATE_UPDATE_ERROR, error));
      return error;
    }
  };
}

export function verifyEnquiryQueueCheck(b2bEnquiryId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_FETCHING));
    try {
      const response = await createUpdateEnquiryService.getEnquiryDetailsQueueCheck(b2bEnquiryId, sessionStorage.getItem('webApplicationLoginId'));
      dispatch(apiSuccess(CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_ERROR, error));
      return error;
    }
  };
}

export function cancelEnquiry(masterId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING));
    try {
      let sendData: any = {
        "sessionDetails": {
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "loginPassword": "",
          "applicationSecurityKey": "",
          "initialLogonIndicator": "",
          "initialPassword": "",
        },
      }
      const response = await createUpdateEnquiryService.cancelEnquiry(masterId, sendData);
      dispatch(apiSuccess(CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.POST_CREATE_UPDATE_ERROR, error));
      return error;
    }
  };
}

export function getEnquiryDetails(masterId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING));
    try {
      const response = await createUpdateEnquiryService.getEnquiryDetails(masterId);
      dispatch(apiSuccess(CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.POST_CREATE_UPDATE_ERROR, error));
      return error;
    }
  };
}

export function placeOrder(masterId: any, userName?: any, userId?: any, enquirySessionId?: any) {
  let sessionId = enquirySessionId && enquirySessionId.toString();
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING));
    try {
      let sendData: any = {
        "sessionDetails": {
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          "clientIPAddress": sessionStorage.getItem('clientIp')
        },
        "userName": userName,
        "userId": userId,
        enquirySessionId: sessionId && sessionId.includes('b2bLSR') ? enquirySessionId : `b2bLSR${enquirySessionId}`,
      }
      const response = await createUpdateEnquiryService.placeOrder(masterId, sendData);
      dispatch(apiSuccess(CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.POST_CREATE_UPDATE_ERROR, error));
      return error;
    }
  };
}

export function deleteLostSaleReport(ids: any, enquirySessionId: any) {
  let sessionId = enquirySessionId && enquirySessionId.toString();
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.DELETE_LOST_SALE_REPORT_FETCHING));
    try {
      let sendData: any = {
        "id": ids,
        enquirySessionId: sessionId && sessionId.includes('b2bLSR') ? enquirySessionId : `b2bLSR${enquirySessionId}`,
      }
      const response = await createUpdateEnquiryService.deleteLostSaleReport(sendData);
      dispatch(apiSuccess(CREATE_UPDATE.DELETE_LOST_SALE_REPORT_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.DELETE_LOST_SALE_REPORT_ERROR, error));
      return error;
    }
  };
}

export function getUniqueEnquirySessionId(enquirySessionId: any) {
  return async (dispatch: any) => {
    dispatch(apiSuccess(CREATE_UPDATE.GET_UNIQUE_ENQUIRY_ID, enquirySessionId));
    return enquirySessionId || {};
  };
}





