export const authService = {
    setToken,
    getAccessToken,
    getRefreshToken,
    clearToken
};

async function setToken(tokenInfo: any) {
    if (tokenInfo && tokenInfo.accessToken) {
        const accessTokenExp: any = tokenInfo.accessTokenExpireOn * 1000;
        const refreshTokenExp: any = tokenInfo.refreshTokenExpireOn * 1000;
        sessionStorage.setItem('accessToken', tokenInfo.accessToken);
        sessionStorage.setItem('refreshToken', tokenInfo.refreshToken);
        sessionStorage.setItem('accessTokenExpiresOn', accessTokenExp);
        sessionStorage.setItem('refreshTokenExpiresOn', refreshTokenExp);
    }
}

async function getAccessToken() {
    return sessionStorage.getItem('accessToken');
}

async function getRefreshToken() {
    return sessionStorage.getItem('refreshToken');
}

async function clearToken() {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessTokenExpiresOn');
    sessionStorage.removeItem('refreshTokenExpiresOn');
}






