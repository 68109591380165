import { ASSIGNED_KAM } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import AssignedKAMs from '../../components/Profile/AssignedKAM.json';

export const assignedKAMsActions = {
    getAssignedKAMs
};

export function getAssignedKAMs() {
    return async (dispatch: any) => {
        dispatch(apiStart(ASSIGNED_KAM.GET_ASSIGNED_KAM_FETCHING));
        try {
            const response = AssignedKAMs.data;
            dispatch(apiSuccess(ASSIGNED_KAM.GET_ASSIGNED_KAM_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(ASSIGNED_KAM.GET_ASSIGNED_KAM_ERROR, error));
            return error;
        }
    };
}