import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const creditRequestService = {
    getCreditRequest,
    updateCreditLimits,
    createCreditLimits,
    deleteCreditLimits,
    getCreditRequestStatus,
    getCreditRequestDetails,
    approveCreditRequest,
    rejectCreditRequest,
    getallCreditRequestKam
};

async function getCreditRequest(filterBy: any,page:any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/creditRequest?filterBy=${filterBy}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 10 : 0}|no-of-records=10`
        },
    };
    return await makeRequest(options)
}

async function createCreditLimits(sapCustomerId: any, bodyData: any) {
    const apiPath = API_ENDPOINTS.CREDIT_REQUEST;
    const options = {
        path: `${apiPath}/creditRequest/${sapCustomerId}`,
        method: "POST",
        bodyObj: bodyData,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateCreditLimits(sapCustomerId: any, bodyData: any) {
    const apiPath = API_ENDPOINTS.CREDIT_REQUEST;
    const options = {
        path: `${apiPath}/creditRequest`,
        method: "PUT",
        bodyObj: bodyData,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function deleteCreditLimits(docid: any, type: any, requestId: any) {
    const apiPath = API_ENDPOINTS.CREDIT_REQUEST;
    const options = {
        path: `${apiPath}/creditRequest/delete?docId=${docid}&requestId=${requestId}`,
        method: "POST",
        bodyObj: {
            "type": type
        },
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getCreditRequestStatus(filterBy?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/creditRequest?filterBy=${filterBy}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
    };
    return await makeRequest(options)
}

async function getallCreditRequestKam(filterBy: any, searchBy: any, Id: any,page:any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/creditRequest?filterBy=${filterBy}&searchBy=${searchBy}&id=${Id}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 10 : 0}|no-of-records=10`
        },
    };
    return await makeRequest(options)
}

async function getCreditRequestDetails(id: any, sapCustomerId: any, type: any) {

    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/creditRequest/requestId/${id}?sapCustomerId=${sapCustomerId}&source=${type}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
    };
    return await makeRequest(options)
}

async function rejectCreditRequest(data: any, id: any) {

    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/creditRequest/${id}`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function approveCreditRequest(data: any, id:any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/creditRequest/${id}`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true,
        customHeaders: {},
    };
    return await makeRequest(options)
}