import { CREDITLIMIT } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
    creditLimitDetails: null
};


function creditLimitReducer(state: any = initialState, action: IActionBase): IGlobal {
switch (action.type) {
    case CREDITLIMIT.GET_CREDITLIMIT_FETCHING:
        return {
            ...state,
            creditLimitDetails: { loading: true, data: null, err: null }
        };
        case CREDITLIMIT.GET_CREDITLIMIT_SUCCESS:
        return {
            ...state,
            creditLimitDetails: { loading: false, data: action.payload, err: null }
        };
        case CREDITLIMIT.GET_CREDITLIMIT_ERROR:
        return {
            ...state,
            creditLimitDetails: { loading: false, data: null, err: action.payload }
        };
        default:
        return state;
    }
};
export default creditLimitReducer;