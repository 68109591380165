
import { PRODUCTS_SEARCH } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { productsService } from "../../services/products.service";

export const favoritesActions = {
  searchProducts
};

export function searchProducts(parts: any, enquirySessionId: any, firstName: any, b2bUserId: any, products: any, region?: any, ) {
  return async (dispatch: any) => {
    dispatch(apiStart(PRODUCTS_SEARCH.GET_PRODUCTS_SEARCH_FETCHING));
    try {
      const response = await productsService.searchProducts(parts, enquirySessionId, firstName, b2bUserId, products, region);
      dispatch(apiSuccess(PRODUCTS_SEARCH.GET_PRODUCTS_SEARCH_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(PRODUCTS_SEARCH.GET_PRODUCTS_SEARCH_ERROR, error));
      return error;
    }
  };
}

export function removeProductData() {
  return async (dispatch: any) => {
    dispatch(apiStart(PRODUCTS_SEARCH.REMOVE_PRODUCTS));
  }
}




