import { ONBOARD } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { onboardingService } from "../../services/onboard.service";



export const ONBOARDActions = {
  getDealerDetails,
  saveDealerDetails,
  getDetailsBySapId,
  deleteKamReviewsDocument,
};

export function getDetailsBySapId(sapCustomerId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_DETAILS_BY_SAP_ID_FETCHING));
    const response = await onboardingService.getDetailsBySapId(sapCustomerId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_DETAILS_BY_SAP_ID_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_DETAILS_BY_SAP_ID_SUCCESS, response));
      return response || [];
    }
  };
}

export function getDealerDetails(b2bUserId: any, type: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_DEALER_DETAILS_FETCHING));
    const response = await onboardingService.getDealerDetails(b2bUserId, type);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_DEALER_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_DEALER_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

export function getKamDetails(b2bUserId: any, type: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_KAM_REVIEW_DETAILS));
    const response = await onboardingService.getKamDetails(b2bUserId, type);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_KAM_REVIEW_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_KAM_REVIEW_SUCCESS, response));
      return response || [];
    }
  };
}

export function saveKamReviewDetails(data:any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_KAM_REVIEW_DETAILS));
    const response = await onboardingService.saveKamReviewDetails(data);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_KAM_REVIEW_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_KAM_REVIEW_SUCCESS, response));
      return response || [];
    }
  };
}


export function getRegion() {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_REGION_FETCHING));
    const response = await onboardingService.getRegion();
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_REGION_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_REGION_SUCCESS, response));
      return response || [];
    }
  };
}

export function getTradeRegion(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_TRADE_REGION_FETCHING));
    const response = await onboardingService.getTradeRegion(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_TRADE_REGION_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_TRADE_REGION_SUCCESS, response));
      return response || [];
    }
  };
}

export function getDealingIn() {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_DEALINGIN_FETCHING));
    const response = await onboardingService.getDealingIn();
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_DEALINGIN_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_DEALINGIN_SUCCESS, response));
      return response || [];
    }
  };
}

export function getTypeOfTrade() {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_TYPEOFTRADE_FETCHING));
    const response = await onboardingService.getTypeOfTrade();
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_TYPEOFTRADE_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_TYPEOFTRADE_SUCCESS, response));
      return response || [];
    }
  }
}

export function saveDealerDetails(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.SAVE_DEALER_DETAILS_FETCHING));
    const response = await onboardingService.saveDealerDetails(data);
    if (response.error) {
      dispatch(apiError(ONBOARD.SAVE_DEALER_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.SAVE_DEALER_DETAILS_SUCCESS, response));
      return response || [];
    }
  }
}

export function getPartnerDetails(id: any, partnerId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_PARTNER_DETAILS_FETCHING));
    const response = await onboardingService.getPartnerDetails(id, partnerId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_PARTNER_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_PARTNER_DETAILS_SUCCESS, response));
      return response || [];
    }
  }
}

export function savePartnerDetails(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.SAVE_PARTNER_DETAILS_FETCHING));
    const response = await onboardingService.savePartnerDetails(data);
    if (response.error) {
      dispatch(apiError(ONBOARD.SAVE_PARTNER_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.SAVE_PARTNER_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

export function editPartnerDetails(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.UPDATE_PARTNER_DETAILS_FETCHING));
    const response = await onboardingService.editPartnerDetails(data);
    if (response.error) {
      dispatch(apiError(ONBOARD.UPDATE_PARTNER_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.UPDATE_PARTNER_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

export function deletePartnerDetails(id: any, sapId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.DELETE_PARTNER_DETAILS_FETCHING));
    const response = await onboardingService.deletePartnerDetails(id, sapId);
    if (response.error) {
      dispatch(apiError(ONBOARD.DELETE_PARTNER_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.DELETE_PARTNER_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

//FUNCTION CODE FOR ADD NEW AUTHORISED SIGNATORIES
export function getAddNewAuthorisedSignatories(id: any, b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_DETAILS_AUTHORISED_SIGNATORIES_FETCHING));
    const response = await onboardingService.getAddNewAuthorisedSignatories(id, b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_DETAILS_AUTHORISED_SIGNATORIES_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_DETAILS_AUTHORISED_SIGNATORIES_SUCCESS, response));
      return response || [];
    }
  };
}

export function saveAuthorisedSignatories(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.SAVE_AUTHORISED_SIGNATORIES_FETCHING));
    const response = await onboardingService.saveAuthorisedSignatories(data);
    if (response.error) {
      dispatch(apiError(ONBOARD.SAVE_AUTHORISED_SIGNATORIES_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.SAVE_AUTHORISED_SIGNATORIES_SUCCESS, response));
      return response || [];
    }
  };
}

export function updateAuthorisedSignatories(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.UPDATED_AUTHORISED_SIGNATORIES_FETCHING));
    const response = await onboardingService.updateAuthorisedSignatories(data);
    if (response.error) {
      dispatch(apiError(ONBOARD.UPDATED_AUTHORISED_SIGNATORIES_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.UPDATED_AUTHORISED_SIGNATORIES_SUCCESS, response));
      return response || [];
    }
  };
}

export function deleteAuthorisedSignatories(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.DELETE_AUTHORISED_SIGNATORIES_FETCHING));
    const response = await onboardingService.deleteAuthorisedSignatories(data);
    if (response.error) {
      dispatch(apiError(ONBOARD.DELETE_AUTHORISED_SIGNATORIES_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.DELETE_AUTHORISED_SIGNATORIES_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR DOCUMENTS TAB  
export function saveDocument(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.SAVE_DOCUMENT_FETCHING));
    const response = await onboardingService.saveDocument(data);
    if (response.error) {
      dispatch(apiError(ONBOARD.SAVE_DOCUMENT_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.SAVE_DOCUMENT_SUCCESS, response));
      return response || [];
    }
  };
}

export function updateDocument(formData: any, headers: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.UPLOAD_DOCUMENT_FETCHING));
    const response = await onboardingService.updateDocument(formData, headers);
    if (response.error) {
      dispatch(apiError(ONBOARD.UPLOAD_DOCUMENT_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.UPLOAD_DOCUMENT_SUCCESS, response));
      return response || [];
    }
  };
}

export function deleteDocument(b2bUserId: any, id: any, type: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.DELETE_DOCUMENT_FETCHING));
    const response = await onboardingService.deleteDocument(b2bUserId, id, type);
    if (response.error) {
      dispatch(apiError(ONBOARD.DELETE_DOCUMENT_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.DELETE_DOCUMENT_SUCCESS, response));
      return response || [];
    }
  };
}

export function deletePartnerDocument(partnerId: any, documentId: any, data: any, b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.DELETE_ADDRESS_DOCUMENT_FETCHING));
    const response = await onboardingService.deletePartnerDocument(partnerId, documentId, data, b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.DELETE_ADDRESS_DOCUMENT_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.DELETE_ADDRESS_DOCUMENT_SUCCESS, response));
      return response || [];
    }
  };
}

export function deleteSignatoryDocument(signatoryId: any, documentId: any, data: any, b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.DELETE_SIGNATORY_DOCUMENT_FETCHING));
    const response = await onboardingService.deleteSignatoryDocument(signatoryId, documentId, data, b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.DELETE_SIGNATORY_DOCUMENT_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.DELETE_SIGNATORY_DOCUMENT_SUCCESS, response));
      return response || [];
    }
  };
}

//FUNCTION CODE FOR GENERATE OTP
export function getGenrateOTP(sapId: any, b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_GENARTE_OTP_DETAILS_FETCHING));
    const response = await onboardingService.getGenrateOTP(sapId, b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_GENARTE_OTP_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_GENARTE_OTP_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

//FUNCTION CODE FOR VERIFY OTP
export function getVerifyOTP(sapVerifyId: any, otp: any, b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_VERIFY_OTP_DETAILS_FETCHING));
    const response = await onboardingService.getVerifyOTP(sapVerifyId, otp, b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_VERIFY_OTP_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_VERIFY_OTP_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

export function getShipToPartyAddress(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_ALL_ADDRESS_FETCHING));
    const response = await onboardingService.getAllAddress(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_ALL_ADDRESS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_ALL_ADDRESS_SUCCESS, response));
      return response || [];
    }
  };
}


export function AddShipToPartyAddress(b2bUserId: any, data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.SAVE_ADDRESS_FETCHING));
    const response = await onboardingService.addNewAddress(b2bUserId, data);

    if (response.error) {
      dispatch(apiError(ONBOARD.SAVE_ADDRESS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.SAVE_ADDRESS_SUCCESS, response));
      return response || [];
    }
  };
}

export function UpdatedShipToPartyAddress(b2bUserId: any, data: any, addressId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.UPDATE_ADDRESS_FETCHING));
    const response = await onboardingService.updateAddress(b2bUserId, data, addressId);

    if (response.error) {
      dispatch(apiError(ONBOARD.UPDATE_ADDRESS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.UPDATE_ADDRESS_SUCCESS, response));
      return response || [];
    }
  };
}


export function getSelectedShipToPartyAddress(b2bUserId: any, addressId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_SELECTED_ADDRESS_FETCHING));
    const response = await onboardingService.getAddressDetails(b2bUserId, addressId);

    if (response.error) {
      dispatch(apiError(ONBOARD.GET_SELECTED_ADDRESS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_SELECTED_ADDRESS_SUCCESS, response));
      return response || [];
    }
  };
}

export function deleteShipToPartyAddress(b2bUserId: any, addressId: any, userType: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.DELETE_ADDRESS_FETCHING));
    const response = await onboardingService.deleteAddress(b2bUserId, addressId, userType);

    if (response.error) {
      dispatch(apiError(ONBOARD.DELETE_ADDRESS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.DELETE_ADDRESS_SUCCESS, response));
      return response || [];
    }
  };
}

export function deleteAddressDocument(addressId: any, docId: any, type: any, b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.DELETE_DOCUMENT_ADDRESS_FETCHING));
    const response = await onboardingService.deleteAddressDocument(addressId, docId, type, b2bUserId);

    if (response.error) {
      dispatch(apiError(ONBOARD.DELETE_DOCUMENT_ADDRESS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.DELETE_DOCUMENT_ADDRESS_SUCCESS, response));
      return response || [];
    }
  };
}

export function deleteKamReviewsDocument(b2bUserId:any,docId:any,type:any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.DELETE_ADDRESS_FETCHING));
    const response = await onboardingService.deleteKamReviewsDocument(b2bUserId, docId,type);

    if (response.error) {
      dispatch(apiError(ONBOARD.DELETE_ADDRESS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.DELETE_ADDRESS_SUCCESS, response));
      return response || [];
    }
  };
}


// FUNCTION CODE FOR GET DOCUMENT BY ID 
export function getDocumentById(getData: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_DOCUMENT_BY_ID_DETAILS_FETCHING));
    const response = await onboardingService.getDocumentById();
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_DOCUMENT_BY_ID_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_DOCUMENT_BY_ID_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR CHANGE CUSTOMER TYPE 
export function changeCustomerType(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.CHANGE_CUSTOMER_TYPE_DETAILS_FETCHING));
    const response = await onboardingService.changeCustomerType(data);
    if (response.error) {
      dispatch(apiError(ONBOARD.CHANGE_CUSTOMER_TYPE_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.CHANGE_CUSTOMER_TYPE_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}
export function setCustomerType(type: any) {
  return async (dispatch: any) => {
    dispatch(apiSuccess(ONBOARD.SET_CUSTOMER_TYPE, type));
  };
}
export function setStatus(status: any) {
  return async (dispatch: any) => {
    dispatch(apiSuccess(ONBOARD.SET_STATUS, status));
  };
}
export function resetValues(type?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.RESET_VALUES, type));
  };
}

// FUNCTION CODE GET ALL PARTNERS DATA
export function getAllPartners(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_ALL_PARTNERS_DETAILS_FETCHING));
    const response = await onboardingService.getAllPartners(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_ALL_PARTNERS_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_ALL_PARTNERS_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR CHANGE CUSTOMER TYPE 
export function getAllSignatories(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_ALL_SIGNATORIES_DETAILS_FETCHING));
    const response = await onboardingService.getAllSignatories(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_ALL_SIGNATORIES_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_ALL_SIGNATORIES_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR GET REQUEST CORRECTION DETAILS
export function requestCorrection(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_REQUEST_CORRECTION_DETAILS_FETCHING));
    const response = await onboardingService.requestCorrection(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_REQUEST_CORRECTION_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_REQUEST_CORRECTION_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR SAVE REQUEST CORRECTION DETAILS
export function saveRequestCorrection(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.SAVE_REQUEST_CORRECTION_DETAILS_FETCHING));
    const response = await onboardingService.saveRequestCorrection(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.SAVE_REQUEST_CORRECTION_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.SAVE_REQUEST_CORRECTION_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR HISTORY LIST CORRECTION DETAILS
export function historyListRequestCorrection(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.HISTORY_LIST_REQUEST_CORRECTION_DETAILS_FETCHING));
    const response = await onboardingService.historyListRequestCorrection(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.HISTORY_LIST_REQUEST_CORRECTION_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.HISTORY_LIST_REQUEST_CORRECTION_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR GET TRANSPORTATION ZONE
export function getTransportationZone(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_TRANSPORTATION_ZONE_DETAILS_FETCHING));
    const response = await onboardingService.getTransportationZone(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_TRANSPORTATION_ZONE_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_TRANSPORTATION_ZONE_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR SAVE ZONE
export function saveZone(sapCustomerId: any, data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.SAVE_ZONE_DETAILS_FETCHING));
    const response = await onboardingService.saveZone(sapCustomerId, data);
    if (response.error) {
      dispatch(apiError(ONBOARD.SAVE_ZONE_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.SAVE_ZONE_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR GET PARAMETERS DETAILS
export function getParameters(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_PARAMETERS_DETAILS_FETCHING));
    const response = await onboardingService.getParameters(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_PARAMETERS_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_PARAMETERS_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR GET PARAMETER VALUES
export function getParameterValues(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_PARAMETER_VALUES_FETCHING));
    const response = await onboardingService.getParameterValues(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_PARAMETER_VALUES_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_PARAMETER_VALUES_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR SAVE PARAMETER VALUES
export function saveParameterValues(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.SAVE_PARAMETER_VALUES_FETCHING));
    const response = await onboardingService.saveParameterValues(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.SAVE_PARAMETER_VALUES_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.SAVE_PARAMETER_VALUES_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR UPDATE PARAMETER VALUES
export function updateParameterValues(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.UPDATE_PARAMETER_VALUES_FETCHING));
    const response = await onboardingService.updateParameterValues(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.UPDATE_PARAMETER_VALUES_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.UPDATE_PARAMETER_VALUES_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR GET SAP DETAILS
export function getSapDetails(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_SAP_DETAILS_FETCHING));
    const response = await onboardingService.getSapDetails(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_SAP_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_SAP_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR SAVE SAP DETAILS
export function saveSapDetails(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.SAVE_SAP_DETAILS_FETCHING));
    const response = await onboardingService.saveSapDetails(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.SAVE_SAP_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.SAVE_SAP_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR UPDATE SAP DETAILS
export function updateSapDetails(b2bUserId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.UPDATE_SAP_DETAILS_FETCHING));
    const response = await onboardingService.updateSapDetails(b2bUserId);
    if (response.error) {
      dispatch(apiError(ONBOARD.UPDATE_SAP_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.UPDATE_SAP_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

// FUNCTION CODE FOR GET NATIONALITY SELECTOR DATA OWNER/PARTNER & ADD AUTHORIZED SIGANTORIES DETAILS
export function getNationality() {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_NATIONALITY_FETCHING));
    const response = await onboardingService.getNationality();
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_NATIONALITY_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_NATIONALITY_SUCCESS, response));
      return response || [];
    }
  };
}

// CODE FOR GET INDUSTRY TYPE SELECTOR AT APPROVE BUTTON IN KAM PORTAL
export function getIndustryType() {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_INDUSTRY_TYPE_FETCHING));
    const response = await onboardingService.getIndustryType();
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_INDUSTRY_TYPE_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_INDUSTRY_TYPE_SUCCESS, response));
      return response || [];
    }
  };
}

export function getPaymentMode() {
  return async (dispatch: any) => {
    dispatch(apiStart(ONBOARD.GET_PAYMENT_MODE_FETCHING));
    const response = await onboardingService.getPaymentMode();
    if (response.error) {
      dispatch(apiError(ONBOARD.GET_PAYMENT_MODE_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ONBOARD.GET_PAYMENT_MODE_SUCCESS, response));
      return response || [];
    }
  };
}

