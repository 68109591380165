import React from 'react'
import { useSelector } from 'react-redux'

const OurBrands = (props:any) => {
    const imageSource = useSelector((state: any) => {
        return state.home.brands
    })
  
    return (
    <div className="section" id="ourBrand">
        <div className="container xl text-center">
            <h2 className="poppins-700 text-black">{props?.pageData?.brands?.heading}</h2>
            <p className="mt-4 font-16">{props?.pageData?.brands?.short_desc}</p>
            <div className="row mt-5 mb-3 align-items-center">
            {props.pageData && props.pageData.brands && props.pageData.brands.list && props.pageData.brands.list?.map((item: any) => {
                     let imageURL= item && item.image && item.image.renditionList && item.image.renditionList[0] && item.image.renditionList[0].resourceUri  ? item.image.renditionList[0].resourceUri : ''
                    return (
                        <div key={item.id} className="col-6 col-md-4 col-lg-2 my-4">
                            <img src={imageURL} className="img-fluid max-width-80" alt="logo" /> 
                        </div>
                    )
                })}
            </div>
        </div>
    </div>
    )
}

export default OurBrands