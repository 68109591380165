import React, { Fragment, Dispatch, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import XLSX from 'xlsx';
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import Snackbar from '../../../common/components/CreatedEnquirySnackbar';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { OnboardingAuthorisedSignatoriesValidation } from '../../../constants/messages';
import { deleteSignatoryDocument, resetValues, updateDocument, getNationality } from '../../../store/actions/onboard.actions';
import { get } from 'lodash';
import { MenuItem } from '@material-ui/core';
import { TestRow } from '../../NewDealerRequestsDetails/OnboardComponentStyling';
import DeleteDialog from '../../../common/components/deleteDialog';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  title: {
    flex: '1 1 100%',
  },
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  addUpdateDailogTitle: {
    position: "absolute",
    top: 10,
    left: 10
  },
}));
const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

export default function AddNewAuthorisedSignatoriesDailog(props: any) {
  const dispatch: Dispatch<any> = useDispatch();
  const [Data, setData] = React.useState<any>([]);
  const [firstName, setFirstName] = React.useState<string>("");
  const [adminFirstNameError, setAdminFirstNameError] = React.useState<boolean>(false);
  const [invalidAdminFirstName, setInvalidAdminFirstName] = React.useState<boolean>(false);
  const [lastName, setLastName] = React.useState<string>("");
  const [adminLastNameError, setAdminLastNameError] = React.useState<boolean>(false);
  const [invalidAdminLastName, setInvalidAdminLastName] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [adminEmailError, setAdminEmailError] = React.useState<boolean>(false);
  const [invalidEmail, setInvalidEmail] = React.useState<boolean>(false);
  const [nationality, setNationality] = React.useState('');
  const [adminNationalityError, setAdminNationalityError] = React.useState<boolean>(false);
  const [invalidAdminNationality, setInvalidAdminNationality] = React.useState<boolean>(false);
  const [mobile, setMobile] = React.useState<string>("");
  const [adminMobileError, setAdminMobileError] = React.useState<boolean>(false);
  const [invalidMobile, setInvalidMobile] = React.useState<boolean>(false);
  const [designation, setDesignation] = React.useState<string>("");
  const [adminDesignationError, setAdminDesignationError] = React.useState<boolean>(false);
  const [invalidAdminDesignation, setInvalidAdminDesignation] = React.useState<boolean>(false);
  const [emiratesIdNo, setEmiratesIdNo] = React.useState<string>("");
  const [adminEmiratesIdNoError, setAdminEmiratesIdNoError] = React.useState<boolean>(false);
  const [invalidAdminEmaritesIdNo, setInvalidAdminEmaritesIdNo] = React.useState<boolean>(false);
  const [cheques, setCheques] = React.useState<boolean>(false);
  const [purchaseOrders, setPurchaseOrders] = React.useState<boolean>(false);
  const [isValidEdit, setIsValidEdit] = React.useState<boolean>(true);
  const [signatureFile, setSignatureFile] = React.useState<any>(null);
  const [visaExpiryDate, setVisaExpiryDate] = React.useState<any>(null);
  const [isValidVisaExpiryDate, setIsValidVisaExpiryDate] = React.useState<any>(true);
  const [visaFile, setVisaFile] = React.useState<any>(null);
  const [visaCopy, setVisaCopy] = React.useState<any>({ documentId: '', expiryDate: '' });
  const [passportExpiryDate, setPassportExpiryDate] = React.useState<any>(null);
  const [isValidPassportExpiryDate, setIsValidPassportExpiryDate] = React.useState<any>(true);
  const [passportCopy, setPassportCopy] = React.useState<any>({ documentId: '', expiryDate: '' });
  const [passportFile, setPassportFile] = React.useState<any>(null);
  const [emiratesIdExpiryDate, setEmiratesIdExpiryDate] = React.useState<any>(null);
  const [isValidEmiratesIdExpiryDate, setIsValidEmiratesIdExpiryDate] = React.useState<any>(true);
  const [emiratesDcoumentId, setEmiratesDcoumentId] = React.useState<any>({ documentId: '', expiryDate: '' });
  const [emiratesIdFile, setEmiratesIdFile] = React.useState<any>(null);
  const [poaExpiryDate, setPoaExpiryDate] = React.useState<any>(null);
  const [isValidPoaExpiryDate, setIsValidPoaExpiryDate] = React.useState<any>(true);
  const [poaFile, setPoaFile] = React.useState<any>(null);
  const [poaCopy, setPoaCopy] = React.useState<any>({ documentId: '', expiryDate: '' });
  const [signatureCopy, setSignatureCopy] = React.useState<any>({ documentId: '' });
  const [deletedType, setDeletedType] = React.useState();
  const { setOpenDialog, getNewAuthorisedSignatories, isEdit, editData, updateNewAuthorisedSignatories, isView } = props;
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const { getSignatoriesData, deleteSignatoryDocumentData, saveSignatoriesData, editSignatoriesData, deleteSignatoriesData, dealerDetails, getNationalityData }: any = useSelector((state: any) => state.onboard);
  const { selectedNewDealer, generateB2bId }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(generateB2bId, "data.createdByB2bUserId", "");
  const sapCustomerId = get(selectedNewDealer, "sapCustomerId", "");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [documentType, setDocumentType] = React.useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  useEffect(() => {
    if (nationality) {
      getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((r: any) => {
        if (r.Country === nationality) {
          setNationality(r);
        }
      })
    }
    if (nationality === 'AE') setVisaCopy({ documentId: '', expiryDate: '' })
  }, [nationality, getNationalityData])

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  function isDisabled() {
    if (isView) {
      return true;
    } else {
      return false;
    }
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  function populateSignatoriesDetails() {
    if (getSignatoriesData && getSignatoriesData.data) {
      let selectedSignatories = getSignatoriesData.data;
      setFirstName(selectedSignatories.firstName);
      setLastName(selectedSignatories.lastName);
      setNationality(selectedSignatories.nationality);
      setEmail(selectedSignatories.email);
      setMobile(selectedSignatories.mobile);
      setDesignation(selectedSignatories.designation);
      setEmiratesIdNo(selectedSignatories.emiratesIdNumber);
      setCheques(selectedSignatories.authorizedForCheque);
      setPurchaseOrders(selectedSignatories.authorizedForPurchaseOffice);
      if (selectedSignatories.signature && selectedSignatories.signature.documentId) {
        const { id } = selectedSignatories.signature.documentId;
        setSignatureFile(selectedSignatories.signature.documentId)
        setSignatureCopy({ documentId: id });
      }
      if (selectedSignatories && selectedSignatories.passportCopy && selectedSignatories.passportCopy.documentId) {
        const { expiryDate, id } = selectedSignatories.passportCopy.documentId;
        setPassportExpiryDate(expiryDate);
        setPassportCopy({ documentId: id, expiryDate: expiryDate })
        setPassportFile(selectedSignatories.passportCopy.documentId);
      }
      if (selectedSignatories && selectedSignatories.emiratesDcoumentId && selectedSignatories.emiratesDcoumentId) {
        const { expiryDate, id } = selectedSignatories.emiratesDcoumentId;
        setEmiratesIdExpiryDate(expiryDate);
        setEmiratesDcoumentId({ documentId: id, expiryDate: expiryDate })
        setEmiratesIdFile(selectedSignatories.emiratesDcoumentId);
      }
      if (selectedSignatories && selectedSignatories.visaCopy && selectedSignatories.visaCopy.documentId) {
        const { expiryDate, id } = selectedSignatories.visaCopy.documentId;
        setVisaExpiryDate(expiryDate)
        setVisaCopy({ documentId: id, expiryDate: expiryDate })
        setVisaFile(selectedSignatories.visaCopy.documentId);
      }
      if (selectedSignatories && selectedSignatories.powerOfAttorney && selectedSignatories.powerOfAttorney.documentId) {
        const { expiryDate, id } = selectedSignatories.powerOfAttorney.documentId;
        setPoaExpiryDate(expiryDate)
        setPoaCopy({ documentId: id, expiryDate: expiryDate })
        setPoaFile(selectedSignatories.powerOfAttorney.documentId);
      }
    }
  }

  useEffect(() => {
    if (deleteSignatoryDocumentData && deleteSignatoryDocumentData.data && deleteSignatoryDocumentData.data.message && deleteSignatoryDocumentData.data.message.status === 'S') {
      if (deletedType === 'emiratesDcoumentId') {
        setEmiratesDcoumentId({ documentId: '', expiryDate: '' });
      }
      if (deletedType === 'passportCopy') {
        setPassportCopy({ documentId: '', expiryDate: '' });
      }
      if (deletedType === 'visaCopy') {
        setVisaCopy({ documentId: '', expiryDate: '' });
      }
      if (deletedType === 'poaCopy') {
        setPoaCopy({ documentId: '', expiryDate: '' });
      }
      if (deletedType === 'signature') {
        setSignatureCopy({ documentId: '' });
      }
    }
  }, [deleteSignatoryDocumentData])

  useEffect(() => {
    if (getSignatoriesData && getSignatoriesData.data) {
      populateSignatoriesDetails();
      //   dispatch(getNationality());
    }
  }, [getSignatoriesData]); // eslint-disable-line

  useEffect(() => {
    if ((saveSignatoriesData && saveSignatoriesData.data && saveSignatoriesData.data.data || editSignatoriesData && editSignatoriesData.data && editSignatoriesData.data.data) || (deleteSignatoriesData && deleteSignatoriesData.data && deleteSignatoriesData.data.data)) {
      resetData();
    }
  }, [saveSignatoriesData, editSignatoriesData, deleteSignatoriesData])

  const resetData = () => {
    // dispatch(resetValues());
    setFirstName("");
    setAdminFirstNameError(false);
    setInvalidAdminFirstName(false);

    setLastName("");
    setAdminLastNameError(false);
    setInvalidAdminLastName(false);

    setNationality("");
    setAdminNationalityError(false);

    setMobile("");
    setAdminMobileError(false);
    setInvalidMobile(false);

    setEmail("");
    setAdminEmailError(false);
    setInvalidEmail(false);

    setDesignation("");
    setAdminDesignationError(false);
    setInvalidAdminDesignation(false);

    setEmiratesIdNo("");
    setAdminEmiratesIdNoError(false);
    setInvalidAdminEmaritesIdNo(false);

    setCheques(false);
    setPurchaseOrders(false);

    setSignatureFile(null);
    setPassportExpiryDate(null);
    setEmiratesIdExpiryDate(null);
    setPassportFile(null);
    setEmiratesIdFile(null);
    setIsValidPassportExpiryDate(null);
    setIsValidEmiratesIdExpiryDate(null);
    setEmiratesIdExpiryDate(null);
    setVisaExpiryDate(null);
    setIsValidVisaExpiryDate(null);
    setVisaFile(null);
    setPassportCopy({ documentId: '', expiryDate: '' });
    setEmiratesDcoumentId({ documentId: '', expiryDate: '' });
    setVisaCopy({ documentId: '', expiryDate: '' });
    setPoaExpiryDate(null);
    setIsValidPoaExpiryDate(null);
    setPoaFile(null);
    setPoaCopy({ documentId: '', expiryDate: '' });
    setSignatureCopy({ documentId: '' });
  }

  // const deleteDocument = (type: any, data: any) => {
  //   setDeletedType(type);
  //   const obj = {
  //     "type": type
  //   }
  //   dispatch(deleteSignatoryDocument(getSignatoriesData && getSignatoriesData.data && getSignatoriesData.data.id, data.id, obj));
  // }

  const deleteDocument = async (type?: any, dataObj?: any) => {
    let data: any;
    setDeletedType(documentType);
    if (documentType == 'signature') {
      data = signatureFile;
    } else if (documentType == 'passportCopy') {
      data = passportFile;
    } else if (documentType == 'emiratesDcoumentId') {
      data = emiratesIdFile;
    } else if (documentType == 'visaCopy') {
      data = visaFile;
    } else if (documentType == 'powerOfAttorney') {
      data = poaFile;
    }
    data = dataObj ? dataObj : data;
    const obj = {
      "type": type ? type : documentType
    }
    let deleteDocumentData: any = await dispatch(deleteSignatoryDocument(getSignatoriesData && getSignatoriesData.data && getSignatoriesData.data.id, data.id, obj, b2bUserId));
    if (deleteDocumentData && deleteDocumentData.message) {
      if (documentType == 'signature') {
        setSignatureFile(null);
      } else if (documentType == 'passportCopy') {
        setPassportFile(null);
        setPassportExpiryDate(null);
      } else if (documentType == 'emiratesDcoumentId') {
        setEmiratesIdFile(null);
        setEmiratesIdExpiryDate(null);
      } else if (documentType == 'visaCopy') {
        setVisaFile(null);
        setVisaExpiryDate(null);
      } else if (documentType == 'powerOfAttorney') {
        setPoaFile(null);
        setPoaExpiryDate(null);
        setPoaCopy({ documentId: '', expiryDate: '' })
      }
    }
    handleopenClose(false);
  }

  const handleInputChange = (e: any, setterName: any, errorSetterName?: any, emiratesId?: any) => {
    if (emiratesId === 'emiratesId') {
      if (emiratesIdNo && emiratesIdNo.length > e.target.value.length) {
        setterName(e.target.value);
      } else if (e.target.value && e.target.value.length === 3) {
        setterName(`${e.target.value}-`);
      } else if (e.target.value && e.target.value.length === 8) {
        setterName(`${e.target.value}-`);
      } else if (e.target.value && e.target.value.length === 16) {
        setterName(`${e.target.value}-`);
      } else {
        setterName(e.target.value);
      }
    }
    else {
      setterName(e.target.value);
    }
    if (errorSetterName && e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        errorSetterName(true);
      else
        errorSetterName(false);
    }
  }

  const removeUserData = (e: any, index: any) => {
    const userData: any = [...Data];
    userData.splice(index, 1);
    setData(userData);
  };

  function validateFormFields(action: string, value: any) {
    switch (action) {
      // case "nationality": {
      //   const re = /^[a-zA-Z0-9 ]{1,250}$/;
      //   return value.length > 250 || !re.test(value) ? false : true;
      // }
      case "lastName": {
        const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return value.length > 250 || !re.test(value) ? false : true;
      }
      case "designation": {
        const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return value.length > 250 || !re.test(value) ? false : true;

      }
      case "firstName": {
        const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return !re.test(value) ? false : true;
      }
      default:
        return true

    }
  }

  const onChangeOfNationalitySelector = (e: any) => {
    setNationality(e.target.value);
    if (visaFile && visaFile.url) {
      deleteDocument('visaCopy', visaFile);
      setVisaFile(null);
      setVisaExpiryDate(null);
    }
    if (e.target.value === "" || e.target.value.trim() === "") {
      setAdminNationalityError(true);
    }
    else {
      setAdminNationalityError(false);
    }
  }

  function validateAddNewAuthorisedSignatoriesDialog() {
    let isValid: boolean = true;
    let fields: boolean = true;
    if (firstName.trim() === "") {
      setAdminFirstNameError(true);
      isValid = false;
      fields = false;
    }
    if (firstName.trim() !== "") {
      if (firstName.split('').length > 250) {
        setInvalidAdminFirstName(true);
        isValid = false;
        fields = false;
      }
      else if (!validateFormFields("firstName", firstName)) {
        setInvalidAdminFirstName(true);
        isValid = false;
        fields = false;
      }
      else {
        setInvalidAdminFirstName(false);
      }
    }
    if (lastName.trim() === "") {
      setAdminLastNameError(true);
      isValid = false;
      fields = false;
    }
    if (lastName.trim() !== "") {
      if (lastName.split('').length > 250) {
        setInvalidAdminLastName(true);
        isValid = false;
        fields = false;
      }
      else if (!validateFormFields("lastName", lastName)) {
        setInvalidAdminLastName(true);
        isValid = false;
        fields = false;
      }
      else {
        setInvalidAdminLastName(false);
      }
    }
    // if (nationality.trim() === "") {
    //   setAdminNationalityError(true);
    //   isValid = false;
    // }
    // if (nationality.trim() !== "") {
    //   if (nationality.split('').length > 250) {
    //     setInvalidAdminNationality(true);
    //     isValid = false;
    //   }
    //   else if (!validateFormFields("nationality", nationality)) {
    //     setInvalidAdminNationality(true);
    //     isValid = false;
    //   }
    //   else {
    //     setInvalidAdminNationality(false);
    //   }
    // }
    if (nationality == "" || nationality == undefined) {
      setAdminNationalityError(true);
      isValid = false;
      fields = false;
    }
    else {
      // setInvalidAdminNationality(false);
      setAdminNationalityError(false);
    }
    if (designation.trim() === "") {
      setAdminDesignationError(true);
      isValid = false;
      fields = false;
    }
    if (designation.trim() !== "") {
      if (designation.split('').length > 250) {
        setInvalidAdminDesignation(true);
        isValid = false;
        fields = false;
      }
      else if (!validateFormFields("designation", designation)) {
        setInvalidAdminDesignation(true);
        isValid = false;
        fields = false;
      }
      else {
        setInvalidAdminDesignation(false);
      }
    }
    if (mobile.trim() === "") {
      setAdminMobileError(true);
      isValid = false;
      fields = false;
    }
    if (mobile.trim() !== "") {
      if (!validateMobile(mobile)) {
        isValid = false;
        fields = false;
      }
      if (validateMobile(mobile) === false) {
        setInvalidMobile(true);
      }
      else {
        setInvalidMobile(false);
      }
    }
    if (emiratesIdNo.trim() === "") {
      setAdminEmiratesIdNoError(true);
      isValid = false;
      fields = false;
    }
    if (emiratesIdNo.trim() !== "") {
      if (!validateEmaritesIdNO(emiratesIdNo)) {
        isValid = false;
        fields = false;
        setInvalidAdminEmaritesIdNo(true);
      }
      else {
        setInvalidAdminEmaritesIdNo(false);
      }
    }
    if (email.trim() === "") {
      setAdminEmailError(true);
      isValid = false;
      fields = false;
    }
    if (email.trim() !== "") {
      if (!validateEmail(email)) {
        isValid = false;
        fields = false;
      }
      if (validateEmail(email) === false) {
        setInvalidEmail(true);
      }
      else {
        setInvalidEmail(false);
      }
    }
    if (signatureCopy.documentId.trim() === '') {
      isValid = false;
      showErrorMessage("Please upload all mandatory documents");
      handleopenCloseErrorSnackbar(true);
      handleopenClose(false);
    }
    if (passportCopy.documentId.trim() === '') {
      isValid = false;
      showErrorMessage("Please upload all mandatory documents");
      handleopenCloseErrorSnackbar(true);
      handleopenClose(false);
    }
    if (emiratesDcoumentId.documentId.trim() === '') {
      isValid = false;
      showErrorMessage("Please upload all mandatory documents");
      handleopenCloseErrorSnackbar(true);
      handleopenClose(false);
    }
    if (nationality !== 'AE') {
      if (visaCopy.documentId.trim() === '') {
        isValid = false;
        showErrorMessage("Please upload all mandatory documents");
        handleopenCloseErrorSnackbar(true);
        handleopenClose(false);
      }
    }
    if (poaCopy.documentId.trim() === '') {
      isValid = false;
      showErrorMessage("Please upload all mandatory documents");
      handleopenCloseErrorSnackbar(true);
      handleopenClose(false);
    }
    if (fields === true && cheques === false && purchaseOrders === false) {
      isValid = false;
      showErrorMessage("Please select Authorized For");
      handleopenCloseErrorSnackbar(true);
      handleopenClose(false);
    } else {
      showErrorMessage("Please fill all mandatory fields and documents.");
      handleopenCloseErrorSnackbar(true);
      handleopenClose(false);
    }
    if (isValid) {
      if (getSignatoriesData && getSignatoriesData.data && getSignatoriesData.data.id) {
        updateAuthorisedSignatories();
      }
      else {
        createNewAuthorisedSignatories();
      }
    }
  }

  function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validateMobile(number: string) {
    const re = /^\d{10}$/;
    return re.test(number);
  }

  function validateEmaritesIdNO(number: string) {
    const re = /^\d{3}-\d{4}-\d{7}-\d{1}$/;
    return re.test(number);
  }

  const createNewAuthorisedSignatories = () => {
    const data = {
      sapCustomerId: sapCustomerId,
      createdByB2bUserId: b2bUserId,
      firstName: firstName,
      lastName: lastName,
      nationality: nationality,
      email: email,
      mobile: mobile,
      designation: designation,
      emiratesIdNumber: emiratesIdNo,
      authorizedForCheque: cheques,
      authorizedForPurchaseOffice: purchaseOrders,
      signature: signatureCopy,
      passportCopy: passportCopy,
      emiratesDcoumentId: emiratesDcoumentId.documentId,
      emiratesIdexpiryDate: emiratesDcoumentId.expiryDate,
      visaCopy: visaCopy,
      powerOfAttorney: poaCopy,
    }
    getNewAuthorisedSignatories(data);
    setOpenDialog();
  }

  const updateAuthorisedSignatories = () => {
    const data = {
      id: b2bUserId,
      signatoriesId: getSignatoriesData && getSignatoriesData.data && getSignatoriesData.data.id,
      sapCustomerId: sapCustomerId,
      createdByB2bUserId: b2bUserId,
      firstName: firstName,
      lastName: lastName,
      nationality: nationality,
      email: email,
      mobile: mobile,
      designation: designation,
      emiratesIdNumber: emiratesIdNo,
      authorizedForCheque: cheques,
      authorizedForPurchaseOffice: purchaseOrders,
      signature: signatureCopy,
      passportCopy: passportCopy,
      emiratesDcoumentId: emiratesDcoumentId.documentId,
      emiratesIdexpiryDate: emiratesDcoumentId.expiryDate,
      visaCopy: visaCopy,
      powerOfAttorney: poaCopy,
    }
    updateNewAuthorisedSignatories(data);
    setOpenDialog();
  }

  const exportData = () => {
    let filename = 'SpecialOffers.xlsx';
    let data: any = [];
    data.push({
    });
    return undefined;
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `SpecialOffers`);
    XLSX.writeFile(wb, filename);
  }

  function datePickerValid(e: any, state: any) {
    if (e === "Invalid Date Format") {
      state(false);
      // setSubmitDisabled(true);
    } else if (e === 'Date should not be before minimal date') {
      state(false);
    } else if (e === 'Date should not be after maximal date') {
      state(false);
    } else {
      state(true);
    }
  }

  const onClickBrowseFile = async (type: any, item: any, setCallback: any) => {
    showErrorMessage(null);
    handleopenCloseErrorSnackbar(false);
    const name = item && item.name ? item.name : '',
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
      doctExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
      signatureDocExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
      emiratesIdFileExtensions = ['jpg', 'jpeg', 'png', 'pdf'],

      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1,
      canUploadSignatureFile = signatureDocExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
    const canUploademiratesIdFile = emiratesIdFileExtensions.indexOf(doctExtension.toLowerCase()) !== -1;

    if (type === 'signatureFile' && !canUploadSignatureFile) {
      showErrorMessage('Invalid File type format');
      handleopenCloseErrorSnackbar(true);
      return;
    } else {
      if (!canUploadFile) {
        showErrorMessage('Invalid File type format');
        handleopenCloseErrorSnackbar(true);
        return;
      }
    }
    if (type === 'emiratesIdFile' && !canUploademiratesIdFile) {
      showErrorMessage('Invalid File type format, please upload jpg, png or pdf');
      handleopenCloseErrorSnackbar(true);
      return;
    } else {
      if (!canUploadFile) {
        showErrorMessage('Invalid File type format');
        handleopenCloseErrorSnackbar(true);
        return;
      }
    }
    if (item && item.size < 2000000) {
      const canNotUploadWithoutDate = type === 'passportFile' || type === 'visaCopyFile' || type === 'emiratesIdFile' || type === 'poaFile';
      const formData: any = new FormData();
      let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;
      let expiryDate: any = '';
      if (setCallback) setCallback(item);
      if (canNotUploadWithoutDate) {
        if (type === 'visaCopyFile') expiryDate = moment(visaExpiryDate).utc().format();
        else if (type === 'poaFile') expiryDate = moment(poaExpiryDate).utc().format();
        else if (type === 'passportFile') expiryDate = moment(passportExpiryDate).utc().format();
        else if (type === 'emiratesIdFile') expiryDate = moment(emiratesIdExpiryDate).utc().format();
      }
      keyAttribute = `${keyAttribute}&expiryDate=${expiryDate}`;
      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };
      let docType = '';
      if (type === 'visaCopyFile') { docType = 'Valid Visa Copy' }
      else if (type === 'poaFile') { docType = 'Power Of Attorney File' }
      else if (type === 'passportFile') { docType = 'Valid Passport Copy' }
      else if (type === 'emiratesIdFile') { docType = 'Valid Emirates Copy' }
      else if (type === 'signatureFile') { docType = 'Signature File' }
      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", sapCustomerId ? sapCustomerId : '');
      formData.append("docType", docType);
      formData.append("label", type);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'dealerUser');
      formData.append("uploadedBywebApplicationLoginId", '');
      formData.append("selectedFileName", item.name);

      let uploadedDocumentData: any = await dispatch(updateDocument(formData, customHeaders));
      if (uploadedDocumentData && uploadedDocumentData.message && uploadedDocumentData.message.status === 'S') {
        const item = uploadedDocumentData && uploadedDocumentData.data ? uploadedDocumentData.data : {};
        const { expiryDate, id, label } = item;
        if (label === 'passportFile') {
          setPassportExpiryDate(expiryDate);
          setPassportCopy({ documentId: id, expiryDate: expiryDate });
          setPassportFile(item);
        }
        else if (label === 'emiratesIdFile') {
          setEmiratesIdExpiryDate(expiryDate);
          setEmiratesDcoumentId({ documentId: id, expiryDate: expiryDate });
          setEmiratesIdFile(item);
        }
        else if (label === 'visaCopyFile') {
          setVisaCopy({ documentId: id, expiryDate: expiryDate })
          setVisaExpiryDate(expiryDate);
          setVisaFile(item);
        } else if (label === 'poaFile') {
          setPoaCopy({ documentId: id, expiryDate: expiryDate })
          setPoaExpiryDate(expiryDate);
          setPoaFile(item);
        } else if (label === 'signatureFile') {
          setSignatureCopy({ documentId: id });
          setSignatureFile(item);
        }
      } else {
        showErrorMessage('Document upload failed');
        handleopenCloseErrorSnackbar(true);
        if (type === 'passportFile') {
          setPassportExpiryDate(null);
          setPassportCopy({ documentId: '', expiryDate: '' });
          setPassportFile(null);
        } else if (type === 'emiratesIdFile') {
          setEmiratesIdExpiryDate(null);
          setEmiratesDcoumentId({ documentId: '', expiryDate: '' });
          setEmiratesIdFile(null);
        } else if (type === 'visaCopyFile') {
          setVisaCopy({ documentId: '', expiryDate: '' })
          setVisaExpiryDate(null);
          setVisaFile(null);
        }
        else if (type === 'poaFile') {
          setPoaCopy({ documentId: '', expiryDate: '' })
          setPoaExpiryDate(null);
          setPoaFile(null);
        } else if (type === 'signatureFile') {
          setSignatureCopy({ documentId: '' });
          setSignatureFile(null);
        }
      }
    } else {
      showErrorMessage('Please upload less than 2MB file');
      handleopenCloseErrorSnackbar(true);
    }
  }

  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.root}>
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} text={successMessage} />
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
        <>
          <Toolbar
            className={clsx(classes.root)}>
            <div className="col-12 mb-2 mt-2">
              <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                <h4 className="shift-to-party-page-title">
                  <div className={classes.addUpdateDailogTitle}>
                    {isDisabled() ? 'View Authorized Signatories' : (
                      <>
                        {isEdit || props.typeofDailog === 'edit' ? 'Update Authorized Signatories' : props.typeofDailog === 'add' ? 'Add New Authorized Signatories': ''}
                      </>
                    )}
                  </div>
                  <IconButton aria-label="close" className="float-right" onClick={() => { setOpenDialog(); resetData(); }}>
                    <CloseIcon />
                  </IconButton>
                </h4>
              </Typography>
            </div>
          </Toolbar>
          <Toolbar className="mb-2 mt-2">
            <TextField id="firstName"
              value={firstName}
              disabled={isDisabled()}
              error={adminFirstNameError || invalidAdminFirstName}
              inputProps={{ maxLength: 250 }}
              //   helperText={invalidAdminFirstName &&
              //     <span style={{ color: 'red' }}> {OnboardingAuthorisedSignatoriesValidation.ADMIN_FIRST_NAME_TEXT}</span>
              //   }
              onChange={(e: any) => handleInputChange(e, setFirstName, setAdminFirstNameError)}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="First Name"
              variant="outlined"
              required
            />
            <TextField id="lastName"
              value={lastName}
              disabled={isDisabled()}
              error={adminLastNameError || invalidAdminLastName}
              inputProps={{ maxLength: 250 }}
              //   helperText={invalidAdminLastName &&
              //     <span style={{ color: 'red' }}>{OnboardingAuthorisedSignatoriesValidation.ADMIN_LAST_NAME_TEXT}</span>
              //   }
              onChange={(e: any) => handleInputChange(e, setLastName, setAdminLastNameError)}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="Last Name"
              variant="outlined"
              required
            />
          </Toolbar>
          <Toolbar className="mb-2 nationality">
            <TextField select id="nationality"
              value={nationality}
              disabled={isDisabled()}
              error={adminNationalityError}
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              // helperText={invalidAdminNationality &&
              //   <span style={{ color: 'red' }}> {OnboardingAuthorisedSignatoriesValidation.ADMIN_LAST_NATIONALITY}</span>
              // }
              // onChange={(e: any) => handleInputChange(e, setNationality, setAdminNationalityError)}
              onChange={(e: any) => onChangeOfNationalitySelector(e)}
              size="small"
              label="Nationality" required variant="outlined" margin="dense">
              {getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((item: any) => (
                <MenuItem key={item.Code} value={item.Code}>{item.Country} </MenuItem>))}
            </TextField>
            <TextField id="email" error={adminEmailError || invalidEmail}
              value={email}
              disabled={isDisabled()}
              helperText={invalidEmail &&
                <span style={{ color: 'red' }}>Please enter a valid email address.</span>
              }
              onChange={(e: any) => handleInputChange(e, setEmail, setAdminEmailError)}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="Email"
              variant="outlined"
              required
            />
          </Toolbar>
          <Toolbar className="mb-2">
            <TextField id="mobile" error={adminMobileError || invalidMobile}
              //   placeholder={OnboardingAuthorisedSignatoriesValidation.MOBILE_TEXT}
              value={mobile}
              disabled={isDisabled()}
              helperText={invalidMobile && <span style={{ color: 'red' }}>It should be 10 digits.</span>}
              onChange={(e: any) => handleInputChange(e, setMobile, setAdminMobileError)}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="Mobile"
              variant="outlined"
              required
            />
            <TextField id="designation"
              error={adminDesignationError || invalidAdminDesignation}
              inputProps={{ maxLength: 250 }}
              //   helperText={invalidAdminDesignation &&
              //     <span style={{ color: 'red' }}>{OnboardingAuthorisedSignatoriesValidation.ADMIN_LAST_DESIGNATION}</span>
              //   }
              onChange={(e: any) => handleInputChange(e, setDesignation, setAdminDesignationError)}
              value={designation}
              disabled={isDisabled()}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="Designation"
              variant="outlined"
              required
            />
          </Toolbar>
          <Toolbar className="mb-2 border-bottom">
            <TextField
              id="emiratesIdNo"
              disabled={isDisabled()}
              error={adminEmiratesIdNoError || invalidAdminEmaritesIdNo}
              //   placeholder={OnboardingAuthorisedSignatoriesValidation.EMARITESIDNO_TEXT}
              value={emiratesIdNo}
              //   helperText={invalidAdminEmaritesIdNo &&
              //     <span style={{ color: 'red' }}>{OnboardingAuthorisedSignatoriesValidation.EMARITESIDNO_ERROR_TEXT}</span>
              //   }
              onChange={(e: any) => handleInputChange(e, setEmiratesIdNo, setAdminEmiratesIdNoError, 'emiratesId')}
              inputProps={{ maxLength: 18 }}
              required
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="Emirates ID No."
              variant="outlined"
            />
            <div className="">
              <h6 className="document-content float-left">Authorized For*{' '}:</h6>
            </div>
            <div className="dealer-table-content float-right px-2">
              <ThemeProvider theme={theme}>
                <Checkbox
                  checked={cheques}
                  disabled={isDisabled()}
                  onChange={(e) => { setCheques(e.target.checked) }}
                  className="checkbox" />
              </ThemeProvider>
              <span className="dealer-table-content">Cheques </span>
            </div>
            <div>
              <ThemeProvider theme={theme}>
                <Checkbox
                  checked={purchaseOrders}
                  disabled={isDisabled()}
                  onChange={(e) => {
                    setPurchaseOrders(e.target.checked)
                  }} className="checkbox" />
              </ThemeProvider>
              <span className="dealer-table-content mr-3">Purchase Orders</span>
            </div>
          </Toolbar>
          <div className="row m-0 p-0 border-bottom">
            <div className="col-12 mt-3 mb-3">
              <h6 className="info-sub-header-document px-3">Supported File Extension : .jpg, .jpeg, .png, .pdf, .doc, .docx, upto 2 MB can be uploaded.</h6>
            </div>
          </div>
          <TestRow >
            <div className="col-md-4 col-lg-4 pr-3">
              <h6 className="document-content">Signature{' '}*</h6>
              <a href="./signatureexample.docx" download="signatureexample.docx">
                <IconButton size="small" className="" onClick={() => exportData()}>
                  <AssignmentReturnedIcon className="download-icon mr-1" />
                  <span className="download-offer mr-2">
                    Download Template
                  </span>
                </IconButton>
              </a>
            </div>
            <div className="col-md-2 col-lg-2 pr-2 pl-0">
              {signatureFile !== null ?
                <span className="file-name">
                  <span className="cursor-pointer" onClick={() => {
                    window.open(signatureFile.url)
                  }}>{signatureFile.name ? signatureFile.name : signatureFile.selectedFileName}</span>
                  <IconButton
                    disabled={isDisabled()}
                    className="p-1 mt-2" aria-label="delete" onClick={(e) => {
                      // setSignatureFile(null); deleteDocument('signature', signatureFile);
                      setDocumentType('signature');
                      handleopenClose(true);
                    }}>
                    <CancelIcon className={isDisabled() ? 'file-close-color disabledCustomclass' : "file-close-color"} fontSize="small" />
                  </IconButton>
                </span>
                : <Button variant="contained"
                  component="label" color="secondary"
                  className="rounded-button-dealer pl-4 pr-4 mt-2">
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('signatureFile', e.target.files[0], setSignatureFile) : setSignatureFile(null)
                  } />
                </Button>
              }
            </div>
          </TestRow>
          <TestRow>
            <div className="col-md-4 col-lg-4 pr-3 mb-1 mt-1">
              <h6 className="document-content mt-1">Valid Emirates ID Copy {' '}*</h6>
              <small className="document-sub-content pb-3">Owner/Partner</small>
            </div>
            <div className="col-md-2 col-lg-2 pr-2 mb-2 pl-0 mt-1">
              {emiratesIdFile !== null ?
                <div className="file-name">
                  <span className="cursor-pointer" onClick={() => {
                    window.open(emiratesIdFile.url)
                  }}>{emiratesIdFile.name ? emiratesIdFile.name : emiratesIdFile.selectedFileName}</span>
                  <IconButton className="p-1 mt-2"
                    disabled={isDisabled()}
                    aria-label="delete" onClick={(e) => {
                      // setEmiratesIdFile(null);
                      //  setEmiratesIdExpiryDate(null);
                      //  deleteDocument('emiratesDcoumentId', emiratesIdFile);
                      setDocumentType('emiratesDcoumentId');
                      handleopenClose(true);
                      // dispatch(deleteDocument(emiratesIdFile.id, emiratesIdFile.sapCustomerId, true));
                    }}>
                    <CancelIcon className={isDisabled() ? 'file-close-color disabledCustomclass' : "file-close-color"} fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component={emiratesIdExpiryDate && isValidEmiratesIdExpiryDate ? "label" : "button"}
                  color="secondary"
                  className="rounded-button-dealer pl-4 pr-4 mt-2"
                  disabled={isDisabled() ? isDisabled() : !emiratesIdExpiryDate || isValidEmiratesIdExpiryDate === false}
                >
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('emiratesIdFile', e.target.files[0], setEmiratesIdFile) : setEmiratesIdFile(null)
                  } />
                </Button>
              }
            </div>
            <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-1 px-4">
              <KeyboardDatePicker
                views={["year", "month", "date"]}
                openTo="year"
                disablePast
                onError={(e, v) => datePickerValid(e, setIsValidEmiratesIdExpiryDate)}
                disabled={isDisabled() ? isDisabled() : emiratesIdExpiryDate && emiratesIdFile ? true : false} autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                format="dd/MM/yyyy" margin="normal" label="Expiry Date" value={emiratesIdExpiryDate}
                onChange={(date: Date | null) => { setEmiratesIdExpiryDate(date); !date && setEmiratesIdFile(null) }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={'pl-0 pr-4 mt-2 rounded-input-dealer cal-icon'}
              />
            </div>
          </TestRow>
          <TestRow>
            <div className="col-md-4 col-lg-4 pr-3 mb-2 mt-2">
              <h6 className="document-content">Valid Passport Copy{' '}*</h6>
              <small className="document-sub-content pb-3">Owner/Partner</small>
            </div>
            <div className="col-md-2 col-lg-2 pr-2 mb-2 pl-0 mt-1">
              {passportFile !== null ?
                <div className="file-name">
                  <span className="cursor-pointer" onClick={() => {
                    window.open(passportFile.url)
                  }}>{passportFile.name ? passportFile.name : passportFile.selectedFileName}</span>
                  <IconButton className="p-1 mt-2" aria-label="delete"
                    disabled={isDisabled()}
                    onClick={(e) => {
                      // setPassportFile(null); setPassportExpiryDate(null); deleteDocument('passportCopy', passportFile);
                      setDocumentType('passportCopy');
                      handleopenClose(true);
                    }}>
                    <CancelIcon className={isDisabled() ? 'file-close-color disabledCustomclass' : "file-close-color"} fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component={passportExpiryDate && isValidPassportExpiryDate ? "label" : "button"}
                  color="secondary"
                  className="rounded-button-dealer pl-4 pr-4 mt-2"
                  disabled={isDisabled() ? isDisabled() : !passportExpiryDate || isValidPassportExpiryDate === false}
                > Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('passportFile', e.target.files[0], setPassportFile) : setPassportFile(null)
                  } />
                </Button>
              }
            </div>
            <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-1 px-4">
              <KeyboardDatePicker
                views={["year", "month", "date"]}
                openTo="year"
                disablePast
                onError={(e, v) => datePickerValid(e, setIsValidPassportExpiryDate)}
                disabled={isDisabled() ? isDisabled() : passportExpiryDate && passportFile ? true : false} autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                format="dd/MM/yyyy" margin="normal" label="Expiry Date *" value={passportExpiryDate}
                onChange={(date: Date | null) => { setPassportExpiryDate(date); !date && setPassportFile(null) }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={'pl-0 pr-4 mt-2 rounded-input-dealer cal-icon'}
              />
            </div>
          </TestRow>
          {nationality === 'AE' ? '' : (
            <TestRow>
              <div className="col-md-4 col-lg-4 pr-3 mb-2 mt-2">
                <h6 className="document-content">Valid Visa Copy{' '}*</h6>
                <small className="document-sub-content pb-3">Owner/Partner</small>
              </div>
              <div className="col-md-2 col-lg-2 pr-2 mb-2 pl-0 mt-1">
                {visaFile !== null ?
                  <div className="file-name">
                    <span className="cursor-pointer" onClick={() => {
                      window.open(visaFile.url)
                    }}>{visaFile.name ? visaFile.name : visaFile.selectedFileName}</span>
                    <IconButton className="p-1 mt-2" aria-label="delete"
                      disabled={isDisabled()}
                      onClick={(e) => {
                        // setVisaFile(null); setVisaExpiryDate(null); deleteDocument('visaCopy', visaFile);
                        setDocumentType('visaCopy');
                        handleopenClose(true);
                      }}>
                      <CancelIcon className={isDisabled() ? 'file-close-color disabledCustomclass' : "file-close-color"} fontSize="small" />
                    </IconButton>
                  </div>
                  : <Button
                    variant="contained"
                    component={visaExpiryDate && isValidVisaExpiryDate ? "label" : "button"}
                    color="secondary"
                    className="rounded-button-dealer pl-4 pr-4 mt-2"
                    disabled={isDisabled() ? isDisabled() : !visaExpiryDate || isValidVisaExpiryDate === false}
                  > Browse File
                    <input type="file" hidden onChange={(e) =>
                      (e.target && e.target.files) ?
                        onClickBrowseFile('visaCopyFile', e.target.files[0], setVisaFile) : setVisaFile(null)
                    } />
                  </Button>
                }
              </div>
              <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-1 px-4">
                <KeyboardDatePicker
                  views={["year", "month", "date"]}
                  openTo="year"
                  disablePast
                  onError={(e, v) => datePickerValid(e, setIsValidVisaExpiryDate)}
                  disabled={isDisabled() ? isDisabled() : visaExpiryDate && visaFile ? true : false} autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                  format="dd/MM/yyyy" margin="normal" label="Expiry Date *" value={visaExpiryDate}
                  onChange={(date: Date | null) => { setVisaExpiryDate(date); !date && setVisaFile(null) }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={'pl-0 pr-4 mt-2 rounded-input-dealer cal-icon'}
                />
              </div>
            </TestRow>
          )}
          <TestRow>
            <div className="col-md-4 col-lg-4 pr-3 mb-2 mt-2">
              <h6 className="document-content">Power Of Attorney (POA){' '}*</h6>
              <small className="document-sub-content pb-3">Owner/Partner</small>
            </div>
            <div className="col-md-2 col-lg-2 pr-2 mb-2 pl-0 mt-1">
              {poaFile !== null ?
                <div className="file-name">
                  <span className="cursor-pointer" onClick={() => {
                    window.open(poaFile.url)
                  }}>{poaFile.name ? poaFile.name : poaFile.selectedFileName}</span>
                  <IconButton className="p-1 mt-2" aria-label="delete"
                    disabled={isDisabled()}
                    onClick={(e) => {
                      // setPoaFile(null); setPoaExpiryDate(null); deleteDocument('poaCopy', poaFile);
                      setDocumentType('powerOfAttorney');
                      handleopenClose(true);
                    }}>
                    <CancelIcon className={isDisabled() ? 'file-close-color disabledCustomclass' : "file-close-color"} fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component={poaExpiryDate && isValidPoaExpiryDate ? "label" : "button"}
                  color="secondary"
                  className="rounded-button-dealer pl-4 pr-4 mt-2"
                  disabled={isDisabled() ? isDisabled() : !poaExpiryDate || isValidPoaExpiryDate === false}
                > Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('poaFile', e.target.files[0], setPoaFile) : setPoaFile(null)
                  } />
                </Button>
              }
            </div>
            <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-1 px-4">
              <KeyboardDatePicker
                views={["year", "month", "date"]}
                openTo="year"
                disablePast
                onError={(e, v) => datePickerValid(e, setIsValidPoaExpiryDate)}
                disabled={isDisabled() ? isDisabled() : poaExpiryDate && poaFile ? true : false} autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                format="dd/MM/yyyy" margin="normal" label="Expiry Date *" value={poaExpiryDate}
                onChange={(date: Date | null) => { setPoaExpiryDate(date); !date && setPoaFile(null) }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={'pl-0 pr-4 mt-2 rounded-input-dealer cal-icon'}
              />
            </div>
          </TestRow>
        </>
      </div>
      <DialogActions className="mt-3 mb-3 justify-content-end">
      {isDisabled() ? '' : (
        <Button variant="contained"
          disabled={!isValidEdit}
          color="secondary" className={`createFavourite ${classes.createButton}`}
          onClick={() => {
            validateAddNewAuthorisedSignatoriesDialog();
            handleopenClose(false);
          }}
        >{isEdit ? 'Update' : 'Save'}
        </Button>
        )}
        <Button variant="contained" className={classes.closeButton}
          onClick={() => {
            setOpenDialog();
            resetData();
          }}
        >Cancel</Button>
      </DialogActions>
        <DeleteDialog
          open={openDeleteDialog}
          text="Are you sure you want to delete this document ?"
          handleopenClose={(data: any) => {
            if (data === 'delete') deleteDocument();
            if (!data) handleopenClose(false);
          }} />
        {/* <Button variant="contained" className={classes.closeButton}
          onClick={() => {
            setOpenDialog();
            resetData();
          }}
        >Cancel</Button>
      </DialogActions> */}
    </Fragment>
  );
}