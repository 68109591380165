import React, { forwardRef, Fragment, Dispatch, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from "@material-ui/icons/Visibility";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { useEffect } from 'react';
import { FormControl, InputAdornment, MenuItem, Tooltip, Checkbox, Button } from '@material-ui/core';
import { Messages, ValidationErrorMessage } from '../../constants/messages';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import { getActualBrand, getNewBrand, getUniqueTimestamp } from '../../common/components/util';
import { editPartnerDetails, getDealerDetails, getPartnerDetails, saveDealerDetails, savePartnerDetails, deletePartnerDetails, setCustomerType, resetValues, getNationality, getAllPartners, } from '../../store/actions/onboard.actions';
import { get, stubFalse, truncate } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DeleteDialog from '../../common/components/deleteDialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import Snackbar from '../../common/components/Snackbar';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import ActionButtons from './ActionButtons';
import AddNewOwnerPartnerDialog from './addNewOwnerPartnerDialog'
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SubmitDialogKAM from '../Dealers/AddDealer/submitYourRequestDialogKam';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 10,
  },
  button: {
    borderRadius: "1.25rem",
    minWidth: 170,
    margin: "0 0 0 .5%",
    backgroundColor: "#1A9FE0",
    color: "white",
    height: "2.25rem",
    fontSize: "0.7rem",
  },
  header: {
    color: '#666666',
    fontWeight: 900,
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    textAlign: 'left',
    minWidth: 90,
    maxWidth: 120
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
  },
  footer: {
    paddingLeft: 220
  }
}));
const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

//TABLE HEADER COLOUMN CODE
const headCells = [
  { id: 'firstName', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
  { id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile' },
  { id: 'nationality', numeric: true, disablePadding: false, label: 'Nationality' },
  { id: 'designation', numeric: true, disablePadding: false, label: 'Designation' },
  { id: 'emiratesIdNo', numeric: true, disablePadding: false, label: 'Emirates ID No' },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Action' },
];

const DealerDetails = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [isAdmin, setIsAdmin] = React.useState(true);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [error, setError] = React.useState<any>(null);
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isView, setIsView] = React.useState(false);
  const [status, setStatus] = React.useState<any>();
  const [editData, setEditData] = React.useState();
  const [selected, setSelected] = React.useState<any>([]);
  const [companyName, setCompanyName] = React.useState<string>("");
  const [companyNameError, setCompanyNameError] = React.useState<boolean>(false);
  const [invalidCompanyName, setInvalidCompanyName] = React.useState<boolean>(false);
  const [emailId, setEmailId] = React.useState<string>("");
  const [telephoneOffice, setTelephoneOffice] = React.useState<string>("");
  const [telephoneOfficErrorMessage, setTelephoneOfficeErrorMessage] = React.useState<string>("");
  const [telephoneOfficError, setTelephoneOfficeError] = React.useState<boolean>(false);
  const [invalidTelephoneOffice, setInvalidTelephoneOffice] = React.useState<boolean>(false);
  const [vatRegistrationNumber, setVatRegistrationNumber] = React.useState<string>("");
  const [vatRegistrationNumberError, setVatRegistrationNumberError] = React.useState<boolean>(false);
  const [invalidVatRegistrationNumber, setInvalidVatRegistrationNumber] = React.useState<boolean>(false);
  const [dealerEmail, setDealerEmail] = React.useState<string>("");
  const [dealerEmailError, setDealerEmailError] = React.useState<boolean>(false);
  const [invalidDealerEmail, setInvalidDealerEmail] = React.useState<boolean>(false);
  const [headOfficeRegion, setHeadOfficeRegion] = React.useState<string>("");
  const [isNewCustomer, setIsNewCustomer] = React.useState<any>();
  const [headOfficeRegionCode, setHeadOfficeRegionCode] = React.useState<string>("");
  const [headOfficeRegionError, setHeadOfficeRegionError] = React.useState<boolean>(false);
  const [invalidHeadOfficeRegion, setInvalidHeadOfficeRegion] = React.useState<boolean>(false);
  const [typeOfTrade, setTypeOfTrade] = React.useState<string>("");
  const [typeOfTradeError, setTypeOfTradeError] = React.useState<boolean>(false);
  const [invalidTypeOfTrade, setInvalidTypeOfTrade] = React.useState<boolean>(false);
  const [dealingIn, setDealingIn] = React.useState<string>("");
  const [dealingInError, setDealingInError] = React.useState<boolean>(false);
  const [invalidDealingIn, setInvalidDealingIn] = React.useState<boolean>(false);
  const [annualTurnover, setAnnualTurnover] = React.useState<string>("");
  const [annualTurnoverError, setAnnualTurnoverError] = React.useState<boolean>(false);
  const [establishedDate, setEstablishedDate] = React.useState<any>(null);
  const [establishedDateError, setEstablishedDateError] = React.useState<boolean>(false);
  const [poBox, setPoBox] = React.useState<string>("");
  const [poBoxError, setPoBoxError] = React.useState<boolean>(false);
  const [invalidPoBox, setInvalidPoBox] = React.useState<boolean>(false);
  const [headOfficeAddress, setHeadOfficeAddress] = React.useState<any>({});
  const [firstName, setFirstName] = React.useState<string>("");
  const [firstNameError, setFirstNameError] = React.useState<boolean>(false);
  const [invalidFirstName, setInvalidFirstName] = React.useState<boolean>(false);
  const [lastName, setLastName] = React.useState<string>("");
  const [lastNameError, setLastNameError] = React.useState<boolean>(false);
  const [invalidLastName, setInvalidLastName] = React.useState<boolean>(false);
  const [ownerDesignation, setOwnerDesignation] = React.useState<string>("");
  const [ownerDesignationError, setOwnerDesignationError] = React.useState<boolean>(false);
  const [invalidOwnerDesignation, setInvalidOwnerDesignation] = React.useState<boolean>(false);
  const [ownerNationality, setOwnerNationality] = React.useState<string>("");
  const [ownerNationalityError, setOwnerNationalityError] = React.useState<boolean>(false);
  const [invalidOwnerNationality, setInvalidOwnerNationality] = React.useState<boolean>(false);
  const [ownerEmailId, setOwnerEmailId] = React.useState<string>("");
  const [ownerEmailIdError, setOwnerEmailIdError] = React.useState<boolean>(false);
  const [invalidOwnerEmailId, setInvalidOwnerEmailId] = React.useState<boolean>(false);
  const [ownerEmiratesIdNo, setOwnerEmiratesIdNo] = React.useState<string>("");
  const [ownerEmiratesIdNoError, setOwnerEmiratesIdNoError] = React.useState<boolean>(false);
  const [invalidOwnerEmiratesIdNo, setInvalidOwnerEmiratesIdNo] = React.useState<boolean>(false);
  const [bankName, setBankName] = React.useState<string>("");
  const [selectedPartnerData, setSelectedPartnerData] = React.useState<any>("");
  const [bankNameError, setBankNameError] = React.useState<boolean>(false);
  const [invalidBankName, setInvalidBankName] = React.useState<boolean>(false);
  const [accountNo, setAccountNo] = React.useState<string>("");
  const [accountNoError, setAccountNoError] = React.useState<boolean>(false);
  const [invalidAccountNo, setInvalidAccountNo] = React.useState<boolean>(false);
  const [contactName, setContactName] = React.useState<string>("");
  const [contactNameError, setContactNameError] = React.useState<boolean>(false);
  const [invalidContactName, setInvalidContactName] = React.useState<boolean>(false);
  const [telephone, setTelephone] = React.useState<string>("");
  const [telephoneError, setTelephoneError] = React.useState<boolean>(false);
  const [invalidTelephone, setInvalidTelephone] = React.useState<boolean>(false);
  const [address, setAddress] = React.useState<string>("");
  const [addressError, setAddressError] = React.useState<boolean>(false);
  const [invalidAddress, setInvalidAddress] = React.useState<boolean>(false);
  const [selectBrand, setSelectBrand] = React.useState<any>([]);
  const [selectBrandError, setSelectBrandError] = React.useState<boolean>(false);
  const [invalidSelectBrand, setInvalidSelectBrand] = React.useState<boolean>(false);
  const [creditLimit, setCreditLimit] = React.useState<string>("");
  const [creditLimitError, setCreditLimitError] = React.useState<boolean>(false);
  const [invalidCreditLimit, setInvalidCreditLimit] = React.useState<boolean>(false);
  const [initalStockOrder, setInitalStockOrder] = React.useState<string>("");
  const [initalStockOrderError, setInitalStockOrderError] = React.useState<boolean>(false);
  const [invalidInitalStockOrder, setInvalidInitalStockOrder] = React.useState<boolean>(false);
  const [totalInvestment, setTotalInvestment] = React.useState<string>("");
  const [totalInvestmentError, setTotalInvestmentError] = React.useState<boolean>(false);
  const [invalidTotalInvestment, setInvalidTotalInvestment] = React.useState<boolean>(false);
  const [otherBusinessEstablishments, setOtherBusinessEstablishments] = React.useState<string>("");
  const [otherBusinessEstablishmentsError, setOtherBusinessEstablishmentsError] = React.useState<boolean>(false);
  const [invalidOtherBusinessEstablishments, setInvalidOtherBusinessEstablishments] = React.useState<boolean>(false);
  const [otherBrandsProductsSold, setOtherBrandsProductsSold] = React.useState<string>("");
  const [otherBrandsProductsSoldError, setOtherBrandsProductsSoldError] = React.useState<boolean>(false);
  const [invalidOtherBrandsProductsSold, setInvalidOtherBrandsProductsSold] = React.useState<boolean>(false);
  const [creditLimitSelector, setCreditLimitSelector] = React.useState<string>("AED");
  const [creditLimitSelectorError, setCreditLimitSelectorError] = React.useState<boolean>(false);
  const [invalidCreditLimitSelector, setInvalidCreditLimitSelector] = React.useState<boolean>(false);
  const [initalStockOrderSelector, setInitalStockOrderSelector] = React.useState<string>("");
  const [initalStockOrderSelectorError, setInitalStockOrderSelectorError] = React.useState<boolean>(false);
  const [invalidInitalStockOrderSelector, setInvalidInitalStockOrderSelector] = React.useState<boolean>(false);
  const [totalInvestmentSelector, setTotalInvestmentSelector] = React.useState<string>("AED");
  const [totalInvestmentSelectorError, setTotalInvestmentSelectorError] = React.useState<boolean>(false);
  const [invalidTotalInvestmentSelector, setInvalidTotalInvestmentSelector] = React.useState<boolean>(false);
  const [annualTurnoverSelector, setAnnualTurnoverSelector] = React.useState<string>("AED");
  const [annualTurnoverSelectorError, setAnnualTurnoverSelectorError] = React.useState<boolean>(false);
  const [invalidAnnualTurnoverSelector, setInvalidAnnualTurnoverSelector] = React.useState<boolean>(false);
  const [streetName, setstreetName] = React.useState<string>("");
  const [OtherBrandsProductsSoldErrorMessage, setOtherBrandsProductsSoldErrorMessage] = React.useState<string>("");
  const [streetNameError, setstreetNameError] = React.useState<boolean>(false);
  const [invalidstreetName, setInvalidstreetName] = React.useState<boolean>(false)
  const [city, setcity] = React.useState<string>("");
  const [cityError, setcityError] = React.useState<boolean>(false);
  const [invalidcity, setInvalidcity] = React.useState<boolean>(false)
  const [country, setCountry] = React.useState<string>("");
  const [countryError, setCountryError] = React.useState<boolean>(false);
  const [invalidCountry, setInvalidCountry] = React.useState<boolean>(false);
  const [countryErrorMessage, setCountryErrorMessage] = React.useState<string>("");
  const [invalidcountry, setInvalidcountry] = React.useState<boolean>(false)
  const [postalCode, setpostalCode] = React.useState<string>("");
  const [postalCodeError, setpostalCodeError] = React.useState<boolean>(false);
  const [invalidpostalCode, setInvalidpostalCode] = React.useState<boolean>(false)
  const [brandLists, setBrandList] = React.useState<any>([]);
  const [brandListError, setBrandListError] = React.useState<boolean>(false);
  const [invalidBrandList, setInvalidBrandList] = React.useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = React.useState<any>();
  const [dataFetched, setDataFetched] = React.useState<any>(false);
  const [transportationZone, setTransportationZone] = React.useState<any>("");
  const [transportationZoneCode, setTransportationZoneCode] = React.useState<any>("");
  const [isExisting, setIsExisting] = React.useState<any>("");
  const [shiftToPartyCustomerNumber, setShiftToPartyCustomerNumber] = React.useState<any>("");
  const [loading, setLoading] = React.useState<any>(false);
  const currencies = ["AED"];
  const dealingInOptions = ['Corporate', 'Patnership', 'Sole Patnership'];
  const [invalidDate, setInvalidDate] = React.useState<boolean>(false);
  const { customerDetails, regionData, dealingInData, typeofTradeData,
    savePartnerData, editPartnerData, deletePartnerData, dealerDetails, getAllPartnersData,
    customerType, }: any = useSelector((state: any) => state.onboard);
  const { brandsList, selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
  const sapCustomerId = get(selectedNewDealer, "sapCustomerId", "");
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const customerInfo = dealerDetails && dealerDetails.data && isExistingUser
    ? dealerDetails && dealerDetails.data : customerDetails && customerDetails.data && customerDetails.data.customerInformation;
  const [tradeReference, setTradeReference] = React.useState<any>([]);
  const [selectedDealerInfo, setselectedDealerInfo] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [formSubmitted, setformSubmitted] = React.useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const classes = useStyles();
  const [tradeRowRefDeleteIndex, setTradeRowRefDeleteIndex] = React.useState<any>();
  const [typeOfDelete, setTypeOfDelete] = React.useState<string>("");
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [typeofDailog, setTypeOfDailog] = React.useState<string>("");
  const [OtherBusinessEstablishmentsErrorMessage, setOtherBusinessEstablishmentsErrorMessage] = React.useState<string>("");
  const [tradeReferenceErrors, setTradeReferenceErrors] = React.useState<any>([]);
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };
  const { getNationalityData }: any = useSelector((state: any) => state.onboard);

  useEffect(() => {
    if (!dataFetched) loadPartners();
  }, []);

  useEffect(() => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = brandsList.data.filter((brand: any) => brand.displayName !== 'All');
      setBrandList(data);
    }
  }, [brandsList])

  const loadPartners = async () => {
    setLoading(true);
    props.setDataUpdated(false)
    handleopenCloseSnackbar(false);
    dispatch(getAllPartners(b2bUserId));
    dispatch(getNationality());
    const data: any = await dispatch(getDealerDetails(b2bUserId, 'dealerDetails'));
    if (data && data.data && Object.keys(data.data.companyDetails).length > 0 && !dataFetched) {
      setIsNewCustomer(data.data.isNewCustomer);
      setStatus(data.data.status);
      setHeadOfficeRegion(data.data.companyDetails.region);
      setHeadOfficeRegionCode(data.data.companyDetails.regionCode);
      setDealingIn(data.data.companyDetails.dealingIn);
      setTypeOfTrade(data.data.companyDetails.typeOfTrade);
      setAnnualTurnover(data.data.companyDetails.companyAnnualTurnover);
      setEstablishedDate(data.data.companyDetails.establishedDate);
      setTradeReference(data.data.treadReference);
      setselectedDealerInfo(data.data)
      setCompanyName(data.data.companyDetails.companyName)
      setVatRegistrationNumber(data.data.companyDetails.vatRegistrationNumber)
      setTelephoneOffice(data.data.companyDetails.telephones[0].telephone)
      setDealerEmail(data.data.companyDetails.emails[0].emailId)
      setCountry(data.data.addresses[0].country);
      setPoBox(data.data.companyDetails.poBox)
      let selectedBrandsTemp: any = []
      data.data.organizationIds && data.data.organizationIds.map((item: any) => {
        if (item && item.make) selectedBrandsTemp.push(getNewBrand(item.make))
      })

      if (data.data.isNewCustomer) {
        let tradereferenceItems = [...tradeReference];
        let tradeError = [...tradeReferenceErrors];
        tradeReference.length == 0 && data.data.treadReference && data.data.treadReference.length && data.data.treadReference.forEach((element: any) => {
          tradereferenceItems.push({
            name: element.name,
            addresses: element.addresses,
            companyName: element.companyName,
            telephone: element && element.telephone && typeof element.telephone == 'string' ? element.telephone : element && element.telephones && element.telephones.length > 0 && element.telephones[0] && element.telephones[0].telephone ? element.telephones[0].telephone : ""
          })
          tradeError.push({
            companyNameError: false,
            companyNameErrorMessage: "",
            nameError: false,
            nameErrorMessage: "",
            addressError: false,
            addressErrorMessage: "",
            telephoneError: false,
            telephoneErrorMessage: "",
          })
        });
        if (data.data.treadReference.length == 0) {
          tradereferenceItems.push({
            companyName: "",
            addresses: "",
            name: "",
            telephone: ""
          })
          tradeError.push({
            companyNameError: false,
            companyNameErrorMessage: "",
            nameError: false,
            nameErrorMessage: "",
            addressError: false,
            addressErrorMessage: "",
            telephoneError: false,
            telephoneErrorMessage: "",
          })
        }
        setTradeReferenceErrors(tradeError)
        setTradeReference(tradereferenceItems)
      }
      if (!data.data.isNewCustomer && tradeReference.length == 0) {
        let tradereferenceItems = [...tradeReference];
        let tradeError = [...tradeReferenceErrors];
        tradereferenceItems.push({
          companyName: "",
          addresses: "",
          name: "",
          telephone: ""
        })
        tradeError.push({
          companyNameError: false,
          companyNameErrorMessage: "",
          nameError: false,
          nameErrorMessage: "",
          addressError: false,
          addressErrorMessage: "",
          telephoneError: false,
          telephoneErrorMessage: "",
        })
        setTradeReferenceErrors(tradeError)
        setTradeReference(tradereferenceItems)
      }

      if (data.data.companyDetails.emails && data.data.companyDetails.emails[0])
        setEmailId(data.data.companyDetails.emails[0].emailId)
      if (data.data.companyDetails.telephones && data.data.companyDetails.telephones[0])
        setTelephoneOffice(data.data.companyDetails.telephones[0].telephone)
      setSelectBrand(selectedBrandsTemp)
      setCreditLimit(data.data.initialStockOrder)
      setInitalStockOrderSelector(data.data.stockOrderCurrencyType)
      setTotalInvestment(data.data.totalInvesment)
      setTotalInvestmentSelector(data.data.totalInvesmentCurrencyType)
      setOtherBusinessEstablishments(data.data.otherBusinessEstablishments)
      setOtherBrandsProductsSold(data.data.otherBrandsOrProductsSold)
      setOtherBusinessEstablishments(data.data.otherBusinessEstablishments)
      if (data.data.addresses && data.data.addresses[0]) {
        setHeadOfficeAddress(data.data.addresses[0])
        setstreetName(data.data.addresses[0].streetName)
        setcity(data.data.addresses[0].city)
        setCountry(data.data.addresses[0].country)
        setpostalCode(data.data.addresses[0].postalCode)
        setShiftToPartyCustomerNumber(data.data.addresses[0].shiftToPartyCustomerNumber)
        setIsExisting(data.data.addresses[0].isExisting)
        setTransportationZoneCode(data.data.addresses[0].transportationZoneCode)
        setTransportationZone(data.data.addresses[0].transportationZone)
      }
    }
    if (!dataFetched) setDataFetched(true);
    if (true) dispatch(setCustomerType('existing'));
    setLoading(false);
  }

  function resetValue() {
    setIsEdit(false);
    handleClose();
    dispatch(resetValues());
  }

  function isDisabled() {
    if (status === 'Incomplete from KAM') {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (savePartnerData && savePartnerData.data && savePartnerData.data.message) {
      dispatch(getAllPartners(b2bUserId));
      setOpen(false);
      resetValue();
      setSuccessMessage(savePartnerData.data.message.message)
      handleopenCloseSnackbar(true);
      props.setDataUpdated(true);
    }
  }, [savePartnerData]);

  useEffect(() => {
    if (country) {
      getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((r: any) => {
        if (r.Country === country) {
          setCountry(r);
        }
      })
    }
  }, [country, getNationalityData])

  useEffect(() => {
    if (deletePartnerData && deletePartnerData.data && deletePartnerData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deletePartnerData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getAllPartners(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [deletePartnerData]);

  useEffect(() => {
    if (editPartnerData && editPartnerData.data && editPartnerData.data.message) {
      dispatch(getAllPartners(b2bUserId));
      setOpen(false);
      resetValue();
      setSuccessMessage(editPartnerData.data.message.message)
      handleopenCloseSnackbar(true);
      props.setDataUpdated(true);
    }
  }, [editPartnerData]);

  useEffect(() => {
    if (headOfficeRegion) {
      regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((r: any) => {
        if (r.Region === headOfficeRegion) {
          setHeadOfficeRegion(r.Region);
          setSelectedRegion(r);
        }
      })
    }
  }, [headOfficeRegion, regionData])

  const brands = [
    {
      "make": "Famco",
      "organizationId": "2003",
    },
    {
      "make": "Honda",
      "organizationId": "2002",
    },
    {
      "make": "TE",
      "organizationId": "2380",
    },
    {
      "make": "Toyota",
      "organizationId": "2001",
    }
  ]

  const handleInputChange = (e: any, setterName: any, errorSetterName?: any, errorErrorMessage?: any) => {
    props.setDataUpdated(true);
    setterName(e.target.value);
    if (errorSetterName) {
      if (!e.target.value && !Array.isArray(e.target.value) && !e.target.value.trim()) {
        errorSetterName(true);
      }
      else {
        errorErrorMessage("");
        errorSetterName(false);
      }
    }
  }

  const selectAllBrands = () => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = [];
      brandsList.data.map((brand: any) => data.push(brand.displayName));
      setSelectBrand(data);
    }
  }

  const handleSelectAllClick = (event: any, value: any) => {
    if (event.target.checked && value === 'All') {
      selectAllBrands();
    } else if (event.target.checked === false && value === 'All') {
      setBrandList([]);
    }
  };

  const handleInputChangetradeRef = (e: any, fieldname: any, index: any) => {
    let trade: any = [...tradeReference];
    trade[index][fieldname] = e.target.value
    setTradeReference(trade)
  }

  function validateMobile(number: string) {
    const re = /^[5]\d{8}$/;
    return re.test(number);
  }

  function validateLandline(number: string) {
    const re = /^(?:[1-9]\d*|0)$/;
    return number.length !== 8 || !re.test(number) ? false : true;
  }

  const tradeReferenceItem = (e: any, index: any, type: any) => {
    props.setDataUpdated(true);
    let trade = [...tradeReference];
    let tradeErrors = [...tradeReferenceErrors];

    if (type === "companyName") {
      // setDataUpdated(true);
      trade[index].companyName = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          tradeErrors[index].companyNameError = true;
          tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.LENGTH;
        }
        // else if (e.target.value.length > 250 && validateForm(e.target.value, "companyName")) {
        //   tradeErrors[index].companyNameError = true;
        //   tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.LENGTH;
        // }
        // else if (e.target.value.length <= 250 && !validateForm(e.target.value, "companyName")) {
        //   tradeErrors[index].companyNameError = true;
        //   tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.SPECIAL_CHARACTERS;
        // }
        else {
          tradeErrors[index].companyNameError = false;
          tradeErrors[index].companyNameErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].companyNameError = false;
        tradeErrors[index].companyNameErrorMessage = "";
      }
      setTradeReferenceErrors(tradeErrors);
      setTradeReference(trade)
    } else if (type === "name") {
      // setDataUpdated(true);
      trade[index].name = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          tradeErrors[index].nameError = true;
          tradeErrors[index].nameErrorMessage = ValidationErrorMessage.COMPANY_NAME_TEXT;
        }
        else {
          tradeErrors[index].nameError = false;
          tradeErrors[index].nameErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].nameError = false;
        tradeErrors[index].nameErrorMessage = "";
      }
      setTradeReferenceErrors(tradeErrors);
      setTradeReference(trade)
    }
    else if (type === "addresses") {
      trade[index].addresses = e.target.value;
      tradeErrors[index].addressError = false;
      tradeErrors[index].addressErrorMessage = "";
    }
    else if (type == "telephone") {
      // setDataUpdated(true);
      trade[index].telephone = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 20) {
          tradeErrors[index].telephoneError = true;
          tradeErrors[index].telephoneErrorMessage = ValidationErrorMessage.ANNUAL_TURNOVER_TEXT;
        }
        else {
          tradeErrors[index].telephoneError = false;
          tradeErrors[index].telephoneErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].telephoneError = false;
        tradeErrors[index].telephoneErrorMessage = "";
      }
    }
    setTradeReferenceErrors(tradeErrors);
    setTradeReference(trade)
  }

  function validateForm(value: any, type: string) {
    switch (type) {
      case "country":
      // case "bussinessEst":
      case "brandproductsold":
        {
          let re = /^[a-z\d\-_\s]+$/i;
          return !re.test(value) ? false : true;
        }
      case "companyName":
        {
          // let re = /^[a-z\d\-_\s]+$/i;
          return value.length > 250 ? false : true;
        }
      case "vatReg": {
        const re = /^[0-9]{15}$/;
        return !re.test(value) ? false : true;
      }
      case "telephone":
        {
          const re = /^[0-9]{9}$/;
          return !re.test(value) ? false : true;
        }
    }
  }

  function validateExistingTab() {
    let isValid: boolean = true;
    if ((!headOfficeRegion) || (headOfficeRegion && headOfficeRegion.trim() === '')) {
      setHeadOfficeRegionError(true);
      isValid = false;
    }
    if (poBox == "" || poBoxError) {
      setPoBoxError(true)
      isValid = false;
    }
    // else{
    //   setPoBoxError(false)
    // }
    if ((!dealingIn) || (dealingIn && dealingIn.trim() === '')) {
      setDealingInError(true);
      isValid = false;
    }
    if ((!typeOfTrade) || (typeOfTrade && typeOfTrade.trim() === '')) {
      setTypeOfTradeError(true);
      isValid = false;
    }
    if (!establishedDate) {
      setEstablishedDateError(true);
      isValid = false;
    }
    if (error) {
      isValid = false;
    }
    if (establishedDate) {
      var varDate = new Date(establishedDate);
      var today = new Date();
      if (varDate > today) {
        setInvalidDate(true);
        setError('Date should be less than today date.')
        setEstablishedDateError(true);
        isValid = false;
      }
      else {
        setEstablishedDateError(false);
        setInvalidDate(false);
        setError(null)
      }
    }

    if (getAllPartnersData && getAllPartnersData.data && getAllPartnersData.data.length === 0) {
      isValid = false;
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
      setSuccessMessage('Please add atleast one Owner/Partner');
    }
    return isValid;
  }

  function validateNewDealerDetailsTab() {
    let isValid: boolean = true;
    if (!validateExistingTab()) {
      isValid = false;
    }
    if (companyName === "") {
      setCompanyNameError(true);
      isValid = false;
    }
    else if (companyName.length > 250) {
      setCompanyNameError(true);
      isValid = false;
    }
    if (selectBrand.length === 0) {
      setSelectBrandError(true);
      isValid = false;
    }
    else {
      setSelectBrandError(false);
    }

    if (vatRegistrationNumber.trim() == "") {
      setVatRegistrationNumberError(true);
      isValid = false;
    }
    if (vatRegistrationNumber != "") {
      if (!validateForm(vatRegistrationNumber, 'vatReg')) {
        setVatRegistrationNumberError(true);
        isValid = false;
      }
    }
    if (telephoneOffice.trim() == "") {
      setTelephoneOfficeError(true);
      isValid = false;
    }
    if (telephoneOffice) {
      //if ((telephoneOffice.charAt(0) != "5" || telephoneOffice.charAt(0) != "0") && telephoneOffice.length > 9) {
      if ((telephoneOffice.charAt(0) != "0") && telephoneOffice.length > 9) {
        setTelephoneOfficeErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
        setTelephoneOfficeError(true);
        isValid = false;
      }
      //else if (telephoneOffice.charAt(0) != "5" && telephoneOffice.charAt(0) != "0") {
      else if (telephoneOffice.charAt(0) != "0") {
        //setTelephoneOfficeErrorMessage("It should be start with 5 or 0.")
        setTelephoneOfficeErrorMessage("It should be start with 0.")
        setTelephoneOfficeError(true);
        isValid = false;
      }
      else if (telephoneOffice.length > 9) {
        setTelephoneOfficeErrorMessage("It should not exceed 9 digits.")
        setTelephoneOfficeError(true);
        isValid = false;
      }
      else {
        setTelephoneOfficeErrorMessage("")
        setTelephoneOfficeError(false);
      }
    }
    if ((!country) || (country && country.trim() === undefined)) {
      setCountryError(true);

      isValid = false;
    }
    if ((!totalInvestment) || (totalInvestment && totalInvestment.trim() === '')) {
      setTotalInvestmentError(true);
      isValid = false;
    }
    else {
      setTotalInvestmentError(false);
    }
    if ((!creditLimit) || (creditLimit && creditLimit.trim() === '')) {
      setCreditLimitError(true);
      isValid = false;
    }
    if (creditLimit.trim() !== "") {
      setInvalidCreditLimit(false);
      setCreditLimitError(false);
    }
    if ((!headOfficeRegion) || (headOfficeRegion && headOfficeRegion.trim() === '')) {
      setHeadOfficeRegionError(true);
      isValid = false;
    }
    if ((!streetName) || (streetName && streetName.trim() === '')) {
      setstreetNameError(true);
      isValid = false;
    }
    if (streetName.trim() !== "") {
      if (streetName.split('').length > 250) {
        setInvalidstreetName(true);
        isValid = false;
      }
      else {
        setInvalidstreetName(false);
      }
    }
    if ((!city) || (city && city.trim() === '')) {
      setcityError(true);
      isValid = false;
    }
    if ((!poBox) || (poBox && poBox.trim() === '')) {
      setPoBoxError(true);
      isValid = false;
    }
    if (poBox.trim() !== "") {
      if (poBox.split('').length > 250 || !(/^[a-z\d\-_\s]+$/i.test(poBox.trim()))) {
        setInvalidPoBox(true);
        isValid = false;
      }
      else {
        setInvalidPoBox(false);
      }
    }
    if ((!postalCode) || (postalCode && postalCode.trim() === '')) {
      setpostalCodeError(true);
      isValid = false;
    }
    else {
      setpostalCodeError(false);
    }
    if (dealerEmail.trim() == "") {
      setDealerEmailError(true);
      isValid = false;
    }
    if (dealerEmail.trim() != "") {
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(dealerEmail.trim()))) {
        setInvalidDealerEmail(true);
        isValid = false;
      }
      else {
        setInvalidDealerEmail(false);
      }
    }
    if (creditLimit.trim() == "") {
      setCreditLimitError(true);
      isValid = false;
    }
    else {
      setCreditLimitError(false);
    }

    let trade1 = [...tradeReference];
    let tradeError = [...tradeReferenceErrors]
    trade1 && trade1.length > 0 && trade1.forEach((element: any, index: any) => {
      if (element.companyName == "" || element.companyName.length > 250) {
        isValid = false;
        tradeError[index].companyNameError = true;
      }
      else {
        tradeError[index].companyNameError = false;
      }
      if (element.name == "") {
        isValid = false;
        tradeError[index].nameError = true;
      }
      else {
        tradeError[index].nameError = false;
      }
      if (element.addresses == "") {
        tradeReferenceErrors[index].addressError = true;
        isValid = false;
      }
      else {
        tradeReferenceErrors[index].addressError = false;
      }
      if (element.telephone == "") {
        tradeReferenceErrors[index].telephoneError = true;
        isValid = false;
      }
      else {
        tradeReferenceErrors[index].telephoneError = false;
      }
    })
    return isValid;
  }

  function datePickerValid(e: any, state: any) {
    if (e === "Invalid Date Format") {
      setError("Invalid Date Format");
      state(true);
    } else {
      var varDate = new Date(establishedDate);
      var today = new Date();
      if (varDate > today) {
        setInvalidDate(true);
        setError('Date should be less than today date.')
        setEstablishedDateError(true);
      }
      else {
        setEstablishedDateError(false);
        state(false);
        setError(null)
      }
    }
  }

  async function saveDetails(isSubmitted?: any) {
    setOpenErrorSnackbar(false);
    // setdataUpdated(false)
    if (dealerDetails && dealerDetails.data && dealerDetails.data.isNewCustomer === false) {
      if (validateExistingTab()) {
        let selectBrandTemp = selectBrand;
        let selectedBrands: any[] = [];
        let dataOld: any = selectedDealerInfo
        selectBrandTemp && Array.isArray(selectBrandTemp) && selectBrandTemp.map((element: any) => {
          brands.map((item: any) => {
            if (getActualBrand(element)) {
              if (item && item.make === getActualBrand(element)) {
                selectedBrands.push(item);
              }
            }
          })
        });
        const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
        if (headOfficeAddress) {
          headOfficeAddress.companyName = companyName;
          headOfficeAddress.telephoneNumber = telephoneOffice;
          headOfficeAddress.poBox = poBox;
          headOfficeAddress.city = city;
          headOfficeAddress.country = country;
          headOfficeAddress.streetName = streetName;
          headOfficeAddress.postalCode = postalCode;
          headOfficeAddress.region = headOfficeRegionCode;
          headOfficeAddress.dafault = true;
          headOfficeAddress.firstAddress = true;
          headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
        }

        let traderefe: any[] = [];
        tradeReference && tradeReference.length > 0 && tradeReference.forEach((element: any) => {
          if (element.companyName && element.addresses && element.telephone) {
            traderefe.push({
              "title": "M/s",
              "companyName": element.companyName,
              name: element.name,
              "addresses": element.addresses,
              "telephones": [{
                "country": "-",
                telephone: element.telephone ? element.telephone : "",
                "phoneType": "PRIMARYMOBILE"
              }
              ]
            })
          }
        })

        let data = {
          "b2bUserId": b2bUserId,
          "sapCustomerId": dataOld && dataOld.customerCode ? dataOld.customerCode : dataOld && dataOld.sapCustomerId,
          "isNewCustomer": false,
          "companyDetails": {
            "title": dataOld && dataOld.title,
            "companyName": companyName,
            "vatRegistrationNumber": vatRegistrationNumber,
            "establishedDate": moment(establishedDate).utc().format(),
            "region": headOfficeRegion,
            "regionCode": headOfficeRegionCode,
            "emails": [{ emailId: emailId, emailType: "PRIMARY" }],
            "dealingIn": dealingIn,
            "tradeLicenseNo": "",
            "companyCurrencyType": annualTurnoverSelector,
            "companyAnnualTurnover": annualTurnover,
            "typeOfTrade": typeOfTrade,
            "telephones": [{ country: "AE", phoneType: "PRIMARYMOBILE", telephone: telephoneOffice }],
            "poBox": poBox,
            "taxRegistrationNo": "",
            "lobId": "AUTO",
            "orgId": "2001",
            "channelId": "EVENT"
          },
          "addresses": [headOfficeAddress],
          "organizationIds": selectedBrands.filter((brand: any) => brand),
          "stockOrderCurrencyType": initalStockOrderSelector,
          "initialStockOrder": creditLimit,
          "totalInvesmentCurrencyType": totalInvestmentSelector,
          "totalInvesment": totalInvestment,
          "otherBusinessEstablishments": otherBusinessEstablishments,
          "otherBrandsOrProductsSold": otherBrandsProductsSold,
          "treadReference": tradeReference,
          "isSubmitted": false,
        }
        const savedData: any = await dispatch(saveDealerDetails(data));

        if (savedData && savedData.message && savedData.message.status === 'S') {
          props.setDataUpdated(false);
          setSuccessMessage(savedData.message.message)
          handleopenCloseSnackbar(true);
          return true;
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage(savedData.error);
          return false;
        }
      } else {
        setOpenErrorSnackbar(true);
        handleopenCloseSnackbar(true);
        setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
      }
    }
    else if (dealerDetails && dealerDetails.data && dealerDetails.data.isNewCustomer === true) {
      if (validateNewDealerDetailsTab()) {
        let selectBrandTemp = selectBrand;
        let selectedBrands: any[] = [];
        let dataOld: any = selectedDealerInfo
        selectBrandTemp && Array.isArray(selectBrandTemp) && selectBrandTemp.map((element: any) => {
          brands.map((item: any) => {
            if (getActualBrand(element)) {
              if (item && item.make === getActualBrand(element)) {
                selectedBrands.push(item);
              }
            }
          })
        });
        // selectBrandTemp && Array.isArray(selectBrandTemp) && selectBrandTemp.forEach((element: any) => {
        //   selectedBrands.push(brands.filter((item: any) => item && item.make === getActualBrand(element))[0]);
        // });
        const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
        if (headOfficeAddress) {
          headOfficeAddress.companyName = companyName;
          headOfficeAddress.telephoneNumber = telephoneOffice;
          headOfficeAddress.poBox = poBox;
          headOfficeAddress.city = city;
          headOfficeAddress.country = country;
          headOfficeAddress.streetName = streetName;
          headOfficeAddress.postalCode = postalCode;
          headOfficeAddress.region = headOfficeRegionCode;
          headOfficeAddress.dafault = true;
          headOfficeAddress.firstAddress = true;
          headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
        }

        let traderefe: any[] = [];
        tradeReference && tradeReference.length > 0 && tradeReference.forEach((element: any) => {
          if (element.companyName && element.addresses && element.telephone) {
            traderefe.push({
              "title": "M/s",
              "companyName": element.companyName,
              name: element.name,
              "addresses": element.addresses,
              "telephones": [{
                "country": "-",
                telephone: element.telephone ? element.telephone : "",
                "phoneType": "PRIMARYMOBILE"
              }
              ]
            })
          }
        })

        let data = {
          "b2bUserId": b2bUserId,
          "sapCustomerId": dataOld && dataOld.customerCode ? dataOld.customerCode : dataOld && dataOld.sapCustomerId,
          "isNewCustomer": false,
          "companyDetails": {
            "title": dataOld && dataOld.title,
            "companyName": companyName,
            "vatRegistrationNumber": vatRegistrationNumber,
            "establishedDate": moment(establishedDate).utc().format(),
            "region": headOfficeRegion,
            "regionCode": headOfficeRegionCode,
            "emails": [{ emailId: emailId, emailType: "PRIMARY" }],
            "dealingIn": dealingIn,
            "tradeLicenseNo": "",
            "companyCurrencyType": annualTurnoverSelector,
            "companyAnnualTurnover": annualTurnover,
            "typeOfTrade": typeOfTrade,
            "telephones": [{ country: "AE", phoneType: "PRIMARYMOBILE", telephone: telephoneOffice }],
            "poBox": poBox,
            "taxRegistrationNo": "",
            "lobId": "AUTO",
            "orgId": "2001",
            "channelId": "EVENT"
          },
          "addresses": [headOfficeAddress],
          "organizationIds": selectedBrands.filter((brand: any) => brand),
          "stockOrderCurrencyType": initalStockOrderSelector,
          "initialStockOrder": creditLimit,
          "totalInvesmentCurrencyType": totalInvestmentSelector,
          "totalInvesment": totalInvestment,
          "otherBusinessEstablishments": otherBusinessEstablishments,
          "otherBrandsOrProductsSold": otherBrandsProductsSold,
          "treadReference": tradeReference,
          "isSubmitted": false,
        }
        const savedData: any = await dispatch(saveDealerDetails(data));

        if (savedData && savedData.message && savedData.message.status === 'S') {
          props.setDataUpdated(false);
          setOpenErrorSnackbar(false);
          setSuccessMessage(savedData.message.message)
          handleopenCloseSnackbar(true);
          if (isSubmitted) {
            props.setTabValue(1);
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage(savedData.error);
        }
      } else {
        setOpenErrorSnackbar(true);
        handleopenCloseSnackbar(true);
        setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
      }
    }
  }

  useEffect(() => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = brandsList.data.filter((brand: any) => brand.displayName !== 'All');
      setBrandList(data);
    }
  }, [brandsList])

  useEffect(() => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = brandsList.data.filter((brand: any) => brand.displayName !== 'All');
      setBrandList(data);
    }
  }, [brandsList])

  const handleClose = () => {
    setOpen(false);
  };

  const setOwnerData = (data: any) => {
    dispatch(savePartnerDetails(data));
  }

  const updateNewPartnerData = (data: any) => {
    dispatch(editPartnerDetails(data));
  }

  const onClickDeleteAddress = (partnerData: any) => {
    setTypeOfDelete('address')
    setDeleteMessage("Are you sure you want to delete this Owner/Partner ?");
    handleopenClose(true);
    setSelectedPartnerData(partnerData);
  }
  const onClickDeleteTradeRef = () => {
    setTypeOfDelete('trade')
    setDeleteMessage("Are you sure you want to delete this Trade Reference ?");
    handleopenClose(true);
  }

  const deletePartner = () => {
    dispatch(deletePartnerDetails(selectedPartnerData.id, b2bUserId));
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    if (!open) setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsView(false);
  };

  const addTradeRow = () => {
    let trade = [...tradeReference];
    let tradeError = [...tradeReferenceErrors];
    if (!isDisabledTradeRow()) {
      if (trade[trade.length - 1].companyName || trade[trade.length - 1].name || trade[trade.length - 1].addresses || trade[trade.length - 1].telephone) {
        const data = {
          companyName: "",
          name: "",
          addresses: "",
          telephone: ""
        }
        const errors =
        {
          companyNameError: false,
          companyNameErrorMessage: "",
          nameError: false,
          nameErrorMessage: "",
          addressError: false,
          addressErrorMessage: "",
          telephoneError: false,
          telephoneErrorMessage: "",
        }
        // validateDealerDetailsTradeReferance();
        trade.push(data);
        setTradeReference(trade);
        tradeError.push(errors)
        setTradeReferenceErrors(tradeError);
      }
    }
  }

  const deleteTradeRow = (index: any) => {
    let trade = [...tradeReference];
    let tradeError = [...tradeReferenceErrors]
    trade = trade.filter((data, i) => {
      if (index != i) {
        return data;
      }
    })
    tradeError = trade.filter((data, i) => {
      if (i <= trade.length) {
        return data;
      }
    })
    setTradeReferenceErrors(tradeError);
    setTradeReference(trade);
    handleopenClose(false);
  }

  const isDisabledTradeRow = () => {
    let length = tradeReference.length - 1
    if (tradeReference.length && ((tradeReference[length].companyName == "" || tradeReference[length].name == "" || tradeReference[length].addresses == "" || tradeReference[length].telephone == ""))) {
      // if (tradeReference.length == 1) {
      let tradeError = [...tradeReferenceErrors]
      if (tradeReference[length].companyName == "") {
        tradeError[length].companyNameError = true;
      }
      else {
        tradeError[length].companyNameError = false;
      }
      if (tradeReference[length].name == "") {
        tradeError[length].nameError = true;
      }
      else {
        tradeError[length].nameError = false;
      }
      if (tradeReference[length].addresses == "") {
        tradeReferenceErrors[length].addressError = true;
      }
      else {
        tradeReferenceErrors[length].addressError = false;
      }
      if (tradeReference[length].telephone == "") {
        tradeReferenceErrors[length].telephoneError = true;
      }
      else {
        tradeReferenceErrors[length].telephoneError = false;
      }
      setTradeReferenceErrors(tradeError)
      // }
      return true;
    }
    return false;
  }

  //TABLE HEADER CODE
  function EnhancedTableHead(props: any) {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              className={classes.bodyContent}
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }

  const handleopenCloseSubmit = (open?: any) => {
    setformSubmitted(true);
    if (validateExistingTab()) {
      setOpenSubmitDialog(open ? true : false);
    }
  };

  const handleNextClick = async () => {
    // if (saveDetails(true)) {
    //   props.setTabValue(1);
    // }
    saveDetails(true);
  }

  const handleEditPartnerDetails = (partner: any, isView: any) => {
    dispatch(getPartnerDetails(b2bUserId, partner.id));
    handleClickOpen();
    setIsView(isView);
    setIsEdit(true);
    setEditData(partner);
  }

  const onchangePobox = (e: any) => {
    props.setDataUpdated(true);
    setPoBox(e.target.value);
    if (e.target.value.length > 7) {
      setPoBoxError(true);
    }
    else {
      setPoBoxError(false);
    }
  }

  const onChangeTelephone = (e: any) => {
    props.setDataUpdated(true);
    setTelephoneOffice(e.target.value)
    if (e.target.value != "") {
      //if ((e.target.value.charAt(0) != "5" || e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
      if ((e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
        setTelephoneOfficeErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
        setTelephoneOfficeError(true);
      }
      //else if (e.target.value.charAt(0) != "5" && e.target.value.charAt(0) != "0") {
      else if (e.target.value.charAt(0) != "0") {
        //setTelephoneOfficeErrorMessage("It should be start with 5 or 0.")
        setTelephoneOfficeErrorMessage("It should be start with 0.")
        setTelephoneOfficeError(true);
      }
      else if (e.target.value.length > 9) {
        setTelephoneOfficeErrorMessage("It should not exceed 9 digits.")
        setTelephoneOfficeError(true);
      }
      else {
        setTelephoneOfficeErrorMessage("")
        setTelephoneOfficeError(false);
      }
    }
    else {
      setTelephoneOfficeErrorMessage("")
      setTelephoneOfficeError(false);
    }
  }

  const onChangeOtherBrandProducts = (e: any) => {
    props.setDataUpdated(true);
    setOtherBrandsProductsSold(e.target.value)
  }

  const onChangeBussinessEst = (e: any) => {
    props.setDataUpdated(true);
    setOtherBusinessEstablishments(e.target.value)
  }

  const onChangeCompanyName = (e: any) => {
    props.setDataUpdated(true);
    setCompanyName(e.target.value)
    if (e.target.value != "") {
      // if (!validateForm(e.target.value, 'companyName')) {
      //   setCompanyNameError(true);
      // }
      // else {
      setCompanyNameError(false);
      // }
    }
    else {
      setCompanyNameError(false);
    }
  }
  const onChangeVatReg = (e: any) => {
    props.setDataUpdated(true);
    setVatRegistrationNumber(e.target.value)
    if (e.target.value != "") {
      if (!validateForm(e.target.value, 'vatReg')) {
        setVatRegistrationNumberError(true);
      }
      else {
        setVatRegistrationNumberError(false);
      }
    }
    else {
      setVatRegistrationNumberError(false);
    }
  }

  return (
    <Fragment>
      {/* <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} text={successMessage} /> */}
      <ReturnRequestSnackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <div className="row m-0 p-4">
        <div className="col-12">
          <h6 className="dealer-table-content mb-2">Company Details</h6>
        </div>
        {loading && (<FullscreenLoader />)}
        <>
          <div className="col-md-4 col-lg-4">
            <TextField required id="companyName"
              value={companyName}
              error={companyNameError}
              disabled={isDisabled()}
              onChange={(e: any) => onChangeCompanyName(e)}
              label="Company Name" className="commonRoundedInputs w-100"
              helperText={companyNameError && companyName.length > 0 && <span style={{ color: 'red' }}>{ValidationErrorMessage.LENGTH}</span>}
              InputProps={{
                startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                endAdornment: <Tooltip title="As per trade license" placement="top"><ErrorIcon /></Tooltip>,
              }} margin="dense" variant="outlined" />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField id="vatRegistrationNumber"
              type="number"
              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              value={vatRegistrationNumber}
              error={vatRegistrationNumberError}
              disabled={isDisabled()}
              helperText={vatRegistrationNumberError && <span style={{ color: 'red' }}>{ValidationErrorMessage.VAT_TEXT}</span>}
              onChange={(e: any) => onChangeVatReg(e)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="VAT Registration Number" required variant="outlined" margin="dense" />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField id="telephoneOffice"
              error={telephoneOfficError}
              disabled={isDisabled()}
              helperText={telephoneOfficError && telephoneOfficErrorMessage.length > 0 && <span style={{ color: 'red' }}>{telephoneOfficErrorMessage}</span>}
              onChange={(e: any) => onChangeTelephone(e)}
              value={telephoneOffice} size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Telephone Office(Landline No.)" required variant="outlined" margin="dense" />
          </div>
          <div className="col-md-4 col-lg-4">
            <KeyboardDatePicker required
              disableFuture
              views={["year", "month", "date"]}
              openTo="year"
              autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
              onError={(e, v) => datePickerValid(e, setInvalidDate)}
              disabled={isDisabled()}
              format="dd/MM/yyyy" margin="normal" label="Established Date" value={establishedDate}
              onChange={(date: Date | null) => {
                props.setDataUpdated(true);
                if (date) {
                  setEstablishedDate(date);
                  setEstablishedDateError(false)
                } else {
                  setEstablishedDateError(true)
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className="rounded-input-dealer w-100 mt-2 cal-icon"
              error={establishedDateError || invalidDate}
              helperText={invalidDate && <span style={{ color: 'red' }}>{error}</span>}
            />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField select id="headOfficeRegion"
              error={headOfficeRegionError}
              helperText={invalidHeadOfficeRegion}
              value={selectedRegion && selectedRegion.Code ? selectedRegion.Code : headOfficeRegionCode}
              disabled={isDisabled()}
              onChange={(e: any) => handleInputChange(e, setHeadOfficeRegion, setHeadOfficeRegionError, setInvalidHeadOfficeRegion)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Head Office Region" required variant="outlined" margin="dense" >
              {regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((item: any) => (
                <MenuItem key={item.Code} value={item.Code}>{item.Region} </MenuItem>))}
            </TextField>
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField id="dealerEmail"
              error={dealerEmailError}
              disabled={isDisabled()}
              value={dealerEmail}
              helperText={invalidDealerEmail && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
              onChange={(e: any) => handleInputChange(e, setDealerEmail, setDealerEmailError, setInvalidDealerEmail)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Dealer Email" required variant="outlined" margin="dense" />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField select id="dealingIn"
              error={dealingInError}
              disabled={isDisabled()}
              helperText={invalidDealingIn}
              value={dealingIn}
              onChange={(e: any) => { handleInputChange(e, setDealingIn, setDealingInError, setInvalidDealingIn) }}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Dealing In" required variant="outlined" margin="dense" >
              {dealingInData && dealingInData.data && dealingInData.data.length > 0 &&
                dealingInData.data[0].regions.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
            </TextField>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0 salestoCustomerKam">
            <TextField disabled={isDisabled()} defaultValue={currencies[0]} value={annualTurnoverSelector} onChange={(e: any) => handleInputChange(e, setAnnualTurnoverSelector)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown aed-width" margin="dense" variant="outlined" style={{ width: '74px' }}>
              {currencies.map(item => (
                <MenuItem key={item} value={item}>{item}</MenuItem>))}
            </TextField>
            <TextField id="annualTurnover" type="number"
              value={annualTurnover}
              disabled={isDisabled()}
              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              // error={annualTurnoverSelectorError}
              // helperText={invalidAnnualTurnover}
              onChange={(e: any) => handleInputChange(e, setAnnualTurnover)}
              size="small" className="col-sm-9 commonRoundedInputs-textfield turnover-width"
              label="Annual Turnover" variant="outlined" margin="dense" />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField select id="typeOfTrade"
              error={typeOfTradeError}
              helperText={invalidTypeOfTrade}
              value={typeOfTrade}
              disabled={isDisabled()}
              onChange={(e: any) => handleInputChange(e, setTypeOfTrade,
                setTypeOfTradeError, setInvalidTypeOfTrade)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Type of Trade" required variant="outlined" margin="dense">
              {typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
            </TextField>
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField id="poBox"
              error={poBoxError}
              disabled={isDisabled()}
              helperText={poBoxError && poBox.length > 7 && <span style={{ color: 'red' }}>{ValidationErrorMessage.POBOX_TEXT}</span>}
              value={poBox}
              onChange={(e: any) => onchangePobox(e)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="PO Box" required variant="outlined" margin="dense" />
          </div>

          <div className="col-md-4 col-lg-4">
            <TextField id="Street Name"
              error={streetNameError}
              disabled={isDisabled()}
              helperText={invalidstreetName}
              value={streetName}
              onChange={(e: any) => handleInputChange(e, setstreetName, setstreetNameError, setInvalidstreetName)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Street Name" required variant="outlined" margin="dense" />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField id="City"
              error={cityError}
              disabled={isDisabled()}
              helperText={invalidcity}
              value={city}
              onChange={(e: any) => handleInputChange(e, setcity, setcityError, setInvalidcity)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="City" required variant="outlined" margin="dense" />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField select id="country"
              error={countryError}
              value={country}
              // disabled
              onChange={(e: any) => handleInputChange(e, setCountry, setCountryError, setCountryErrorMessage)}
              disabled={isDisabled()}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Country" required variant="outlined" margin="dense">
              {getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((item: any) => (
                <MenuItem key={item.Code} value={item.Code}>{item.Country} </MenuItem>))}
            </TextField>
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField id="Postal Code"
              error={postalCodeError}
              disabled={isDisabled()}
              type="number"
              helperText={invalidpostalCode}
              value={postalCode}
              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e: any) => handleInputChange(e, setpostalCode, setpostalCodeError, setInvalidpostalCode)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Postal Code" required variant="outlined" margin="dense" />
          </div>
          {dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId ?
            <div className="col-md-4 col-lg-4">
              <TextField id="Al-Futtaim Customer Number"
                disabled={isDisabled()}
                value={sapCustomerId}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label="Al-Futtaim Customer Number" required variant="outlined" margin="dense" />
            </div>
            : ""}
        </>
        <div className="row col-12  mt-2 mb-2 pl-3 border-top">
          <div className="col-lg-4 mt-3">
            <h6 className="dealer-table-content mb-2">Owner/Partners Details</h6>
          </div>
          <div className="col-md-8 bg-white pb-0 pl-lg-10 mt-3">
            <Button variant="contained" color="primary"
              className="rounded-button-dealer ml-3 w-200p float-right"
              // disabled={isDisabled()}
              disabled={isDisabled()}
              onClick={() => {
                handleClickOpen();
                //setIsEdit(false);
                setTypeOfDailog('add')
              }}>Add Owner/Partner
            </Button>
          </div>
          {getAllPartnersData && getAllPartnersData.data && getAllPartnersData.data.length > 0 && (
            <div className="row col-12 mt-2 mb-2 pl-3 border-top">
              <div className="col-lg-12 mt-3">
                <TableContainer>
                  <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                    <EnhancedTableHead numSelected={selected.length} />
                    <TableBody>
                      <>
                        {getAllPartnersData.data.map((newPartnerData: any, index: any) => (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell component="th"
                              scope="row" padding="none" className="document-tablecell-data">
                              {newPartnerData.firstName}{'  '}{newPartnerData.lastName}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.email}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.mobile}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.nationality}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.designation}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.emiratesIdNumber}
                            </TableCell>
                            <TableCell align="left">
                              <div className="row">
                                <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                  {isAdmin && (
                                    <Button
                                      onClick={() => { setTypeOfDailog('view')
                                       handleEditPartnerDetails(newPartnerData, true); }}
                                      className='text-info'
                                      startIcon={<Visibility />}
                                    >
                                      View
                                    </Button>
                                  )}
                                  {isAdmin && (
                                    <Button
                                    className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                                    disabled={isDisabled()}
                                      startIcon={<EditOutlinedIcon />}
                                      onClick={() => { setTypeOfDailog('edit')
                                       handleEditPartnerDetails(newPartnerData, false); }}>
                                      Edit
                                    </Button>
                                  )}
                                  {isAdmin && (
                                    <Button
                                      onClick={() => { onClickDeleteAddress(newPartnerData); }}
                                      className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                                      disabled={isDisabled()}
                                      startIcon={<DeleteOutlineOutlinedIcon />}
                                    >
                                      Delete
                                    </Button>
                                  )}
                                </ButtonGroup>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                        }
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
          <Dialog className="pb-0"
            open={open}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                handleClose()
              }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
            disableEscapeKeyDown>
            <AddNewOwnerPartnerDialog
              setOpenDialog={handleClose} setOwnerData={setOwnerData}
              updateNewPartnerData={updateNewPartnerData}
              isView={isView} typeofDailog={typeofDailog}
            />
          </Dialog>
        </div>
        <div className="row col-12  mt-2 mb-3 pl-3 border-top">
          <div className="col-12 mt-3">
            <h6 className="dealer-table-content mb-2">Brands</h6>
          </div>
          {isNewCustomer ? (
            <>
              <div className="col-md-8 col-lg-8">
                <FormControl className="commonRoundedInputs w-100 mt-2" size="small" variant="outlined">
                  <InputLabel error={selectBrandError} id="companyBrandsLabel">Select Brand*</InputLabel>
                  <Select
                    multiple
                    variant="outlined"
                    disabled={isDisabled()}
                    value={selectBrand}
                    error={selectBrandError}
                    onChange={(e: any) => handleInputChange(e, setSelectBrand, setSelectBrandError, setInvalidSelectBrand)}
                    input={<OutlinedInput label="Select Brand" />}
                    renderValue={(selected: any) => selected.join(', ')}>
                    {brandLists && brandLists.length > 0 && brandLists.map((brand: any, i: any) => (
                      <MenuItem key={i} value={brand.displayName}>
                        <Checkbox onChange={(e: any) => handleSelectAllClick(e, brand.displayName)}
                          checked={selectBrand && selectBrand.includes(brand.displayName) ? true : false}
                        />
                        {/* {getNewBrand(brand.make)} */}
                        <ListItemText primary={brand.displayName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-sm-4 col-md-2 col-lg-1 mb-2 pr-0">
                <TextField select id="creditLimitSelector"
                  error={creditLimitSelectorError}
                  value={creditLimitSelector} defaultValue={currencies[0]}
                  onChange={(e: any) => handleInputChange(e, setCreditLimitSelector, setCreditLimitSelectorError, setInvalidCreditLimitSelector)}
                  disabled={isDisabled()}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                  {currencies.map(item => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>))}
                </TextField>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-3 pr-3 mb-2 pl-0">
                <TextField id="creditLimit"
                  type="number"
                  error={creditLimitError}
                  helperText={invalidCreditLimit}
                  value={creditLimit}
                  disabled={isDisabled()}
                  onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  onChange={(e: any) => handleInputChange(e, setCreditLimit, setCreditLimitError, setInvalidCreditLimit)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs-textfield"
                  label="Initial Stock Order*" variant="outlined" margin="dense" />
              </div>
              <div className="col-sm-4 col-md-2 col-lg-1 mb-2 pr-0">
                <TextField select id="totalInvestmentSelector"
                  disabled={isDisabled()}
                  error={totalInvestmentSelectorError}
                  value={totalInvestmentSelector}
                  onChange={(e: any) => handleInputChange(e, setTotalInvestmentSelector, setTotalInvestmentSelectorError, setInvalidTotalInvestmentSelector)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                  {currencies.map(item => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>))}
                </TextField>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-3 pr-3 mb-2 pl-0">
                <TextField id="totalInvestment"
                  type="number"
                  error={totalInvestmentError}
                  helperText={invalidTotalInvestment}
                  value={totalInvestment}
                  disabled={isDisabled()}
                  onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  onChange={(e: any) => handleInputChange(e, setTotalInvestment, setTotalInvestmentError, setInvalidTotalInvestment)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs-textfield"
                  label="Total Investment*" variant="outlined" margin="dense" />
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField id="otherBusinessEstablishments"
                  // error={otherBusinessEstablishmentsError}
                  // helperText={otherBusinessEstablishmentsError && <span style={{ color: 'red' }}>{OtherBusinessEstablishmentsErrorMessage}</span>}
                  value={otherBusinessEstablishments}
                  onChange={(e: any) => onChangeBussinessEst(e)}
                  disabled={isDisabled()}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Other Business Establishments" variant="outlined" margin="dense" />
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField id="otherBrandsProductsSold"
                  // error={otherBrandsProductsSoldError}
                  // helperText={otherBrandsProductsSoldError && <span style={{ color: 'red' }}>{OtherBrandsProductsSoldErrorMessage}</span>}
                  value={otherBrandsProductsSold}
                  onChange={(e: any) => onChangeOtherBrandProducts(e)}
                  disabled={isDisabled()}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Other Brands/Products Sold" variant="outlined" margin="dense" />
              </div>
            </>
          ) :
            (
              <>
                {selectBrand.map((org: any, index: any) => (
                  <Button key={index} onClick={() => {
                  }}
                    className="brandsButton mr-2 cursor-default"
                    variant="contained">
                    {getNewBrand(org)}
                  </Button>
                ))}
              </>
            )}
        </div>
        {isNewCustomer === false ? '' : (
          <>
            <div className="row col-md-12 m-0 border-top pl-3" >
              <h6 className="dealer-table-content mb-2 mt-4">Trade Reference</h6>
            </div>
            {tradeReference.map((tradeItem: any, index: any) => {
              return (
                <div className="col-md-12 m-0" key={index}>
                  <div className="row p-0 m-0">
                    <div className="col-md-4 col-lg-4">
                      <TextField
                        required
                        value={tradeItem.name}
                        type="text"
                        disabled={isDisabled()}
                        error={tradeReferenceErrors[index] && tradeReferenceErrors[index].nameError}
                        onChange={(e: any) => tradeReferenceItem(e, index, "name")}
                        helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].nameError &&
                          <span style={{ color: 'red' }}>{tradeReferenceErrors[index] && tradeReferenceErrors[index].nameErrorMessage}</span>}
                        name={tradeItem.name + index}
                        size="small" className="col-sm-12 my-2 commonRoundedInputs"
                        label="Name" variant="outlined" margin="dense" />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      {/* <TextField
                      value={tradeItem.companyName}
                      disabled={isDisabled()}
                      name={tradeItem.companyName + index}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Company Name" variant="outlined" margin="dense"
                      onChange={(e: any) => {
                        handleInputChangetradeRef(e, 'companyName', index)
                      }}
                    /> */}
                      <TextField
                        required
                        value={tradeItem.companyName}
                        type="text"
                        disabled={isDisabled()}
                        error={tradeReferenceErrors[index] && tradeReferenceErrors[index].companyNameError}
                        onChange={(e: any) => tradeReferenceItem(e, index, "companyName")}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                        }}
                        helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].companyNameError &&
                          <span style={{ color: 'red' }}>{tradeReferenceErrors[index] && tradeReferenceErrors[index].companyNameErrorMessage}</span>}
                        name={tradeItem.companyName + index}
                        size="small" className="col-sm-12 my-2 commonRoundedInputs"
                        label="Company Name" variant="outlined" margin="dense" />
                    </div>
                  </div>
                  <div className="row p-0 m-0">
                    <div className="col-md-4 col-lg-4">
                      {/* <TextField
                      value={tradeItem.addresses}
                      disabled={isDisabled()}
                      // name={tradeItem.address + index}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Address" variant="outlined" margin="dense"
                      onChange={(e: any) => {
                        handleInputChangetradeRef(e, 'addresses', index)
                      }}
                    /> */}
                      <TextField
                        required
                        value={tradeItem.addresses}
                        type="text"
                        error={tradeReferenceErrors[index] && tradeReferenceErrors[index].addressError}
                        onChange={(e: any) => tradeReferenceItem(e, index, "addresses")}
                        disabled={isDisabled()}
                        helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].addressError &&
                          <span style={{ color: 'red' }}>{tradeReferenceErrors[index].addressErrorMessage}</span>}
                        inputProps={{ maxLenght: 30 }}
                        name={tradeItem.addresses + index}
                        size="small" className="col-sm-12 my-2 commonRoundedInputs"
                        label="Address" variant="outlined" margin="dense" />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      {/* <TextField
                      value={tradeItem.telephone}
                      disabled={isDisabled()}
                      // name={tradeItem.telephones && tradeItem.telephones.length > 0 && tradeItem.telephones[0].telephone + index}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Telephone" variant="outlined" margin="dense"
                      onChange={(e: any) => {
                        handleInputChangetradeRef(e, 'telephone', index)
                      }}
                    /> */}
                      <TextField
                        required
                        value={tradeItem.telephone}
                        type="number"
                        error={tradeReferenceErrors[index] && tradeReferenceErrors[index].telephoneError}
                        onChange={(e: any) => tradeReferenceItem(e, index, 'telephone')}
                        disabled={isDisabled()}
                        onKeyDown={(e: any) => ["e", "E", "-"].includes(e.key) && e.preventDefault()}
                        helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].telephoneError &&
                          <span style={{ color: 'red' }}>{tradeReferenceErrors[index].telephoneErrorMessage}</span>}
                        inputProps={{ maxLenght: 20 }}
                        name={tradeItem.telephone + index}
                        size="small" className="col-sm-12 my-2 commonRoundedInputs"
                        label="Telephone" variant="outlined" margin="dense" />
                    </div>
                    {
                      tradeReference.length !== 1 ?
                        <div className="col-md-4 col-lg-2">
                          <IconButton className="mr-1 mt-n2" size="small"
                            disabled={isDisabled()}
                            onClick={() => {
                              onClickDeleteTradeRef();
                              setTradeRowRefDeleteIndex(index)
                              // deleteTradeRow(index)
                            }}
                          >
                            <DeleteIcon fontSize="inherit" />
                            <span className="font-size-13 load-from-fav ml-1 mt-0">
                              Delete
                            </span>
                          </IconButton>
                        </div>
                        : ""}
                    {/* <div className="col-md-4 col-lg-2">
                    <IconButton disabled={isDisabled()} className="mr-1 mt-3" size="small" onClick={() => deleteTradeRow(index)}>
                      <DeleteIcon fontSize="inherit" />
                      <span className="font-size-13 load-from-fav ml-1 mt-0">
                        Delete
                      </span>
                    </IconButton>
                  </div> */}
                  </div>
                </div>
              )
            })}
            <div className="row col-md-12 m-0">
              <div className="row-space-start">
                <Button
                  disabled={isDisabled()}
                  className="text-info"
                  startIcon={<AddIcon className="font-size-18" />}
                  onClick={() => addTradeRow()}
                ><span className="onshiftoparty-font">Add More
                  </span>
                </Button>
              </div>
            </div>
            {dealerDetails && dealerDetails.data && dealerDetails.data.status === "Incomplete from KAM" && (
              <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
                <div className="col-12 display-flex p-0">
                  <div className="col-8 p-0">
                    <div className={`row m-0`}>
                      {/* <div className="col-5 p-0">
                        <p className="mt-2 ml-4 shipAdd">Dealer Type</p>
                        {status === 'Submitted' ? (
                          <p className="ml-4 mt-2 text-capitalize">{sessionStorage.getItem('customerType')} User</p>
                        ) : (
                          <p className="cursor-pointer ml-4 mt-2" onClick={() => { changeDealerType() }}>
                            <span className="document-content text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                              Dealer</span><KeyboardArrowDownIcon />{'  '}<span className="info-sub-header-document">Change Type</span>
                          </p>
                        )}
                      </div> */}
                      <div className="col-4 mt-3 p-0">
                        <Button variant="contained" className="border-left rounded-pill ml-5 w-150p mb-2"
                          onClick={() => saveDetails(false)}
                        >Save Form</Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 onboard-footer float-right">
                    {/* <div className="p-3 mr-3">
                      <Button variant="contained" className="rounded-pill pl-0 pr-0 w-150p"
                        onClick={() => 
                          handleBackClick()
                        }
                      >Back</Button>
                    </div> */}
                    <div className="p-3 mr-3">
                      <Button variant="contained" color="primary"
                        onClick={() => {
                          // setDeleteMessage("Are you sure you want to switch from the current Dealer type. By doing so, all your saved data will be lost.");
                          // handleopenCloseSubmit(true);
                          // saveDetails(true);
                          handleNextClick();
                        }}
                        className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2" >{props.showSave ? 'Next' : 'Next'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {/* <SubmitDialogKAM
        open={openSubmitDialog}
        handleopenClose={(data: any) => {
          if (data === 'Submit') {
            saveDetails();
          }
          if (!data) handleopenCloseSubmit(false);
        }} /> */}
      <DeleteDialog
        open={openDeleteDialog}
        type={typeOfDelete}
        text={deleteMessage}
        handleopenClose={(data: any) => {
          if (data === 'delete') {
            deletePartner()
          }
          else if (data === 'tradeRef') {
            deleteTradeRow(tradeRowRefDeleteIndex)
          }
          if (!data) handleopenClose(false);
        }} />
      {status && status !== "Incomplete from KAM" && (
        < ActionButtons
          isSaveDisabled={!props.dataUpdated}
          iscorrectionDisabled={dealerDetails && dealerDetails.data && dealerDetails.data.status === "Incomplete from KAM" ? true : false}
          isapproveDisabled={true}
          isrejectDisabled={true}
          saveCallback={() => {
            saveDetails()
          }}
          correctionCallback={() => { }}
          approveCallback={() => { }}
          rejectCallback={() => { }}
          setOpenErrorSnackbar={setOpenErrorSnackbar}
          openErrorSnackbar={openErrorSnackbar}
          handleopenCloseSnackbar={handleopenCloseSnackbar}
          setSuccessMessage={setSuccessMessage}
          setDataUpdated={props.setDataUpdated}
        />
      )}
    </Fragment >
  )
}

export default DealerDetails