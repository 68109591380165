import React, { Fragment, Dispatch, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Dialog, MenuItem, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  close: {
    color: '#000000',
    fontWeight: 900,
    fontSize: 18
  }
});

export default function AddNewFeatureDialog(props: any) {
  const classes = useStyles()
  const dispatch: Dispatch<any> = useDispatch();
  const [feature, setFeaure] = useState("")
  const [type, setType] = useState("")
  const [options, setoptions] = useState<any>([])
  const [formSubmitted, setformSubmitted] = useState(false)

  const handleFeature = (e: any) => {
    setFeaure(e.target.value)
  }

  const handleType = (e: any) => {
    setType(e.target.value)
  }

  useEffect(() => {
    if (props.open) {
      setformSubmitted(false)
      setFeaure(props.selectedKey)
      setType(props.selectedKeyType)
      setoptions(props.selectedKeyOptions || [])
    } else {
      setFeaure("")
      setType("textFiled")
      setoptions([])
    }
  }, [props.open, props.selectedKeyType, props.selectedKeyOptions])

  return (
    <Dialog
      open={props.open ? true : false}
      onClose={() => props.handleSave(false)}
      className="smalldialogadd"
    >
      <DialogTitle className="pb-0 w-100">
        <div className="page-title  w-100 d-flex  justify-content-between justify-space-between">
          <div className="pr-5 mr-5 pt-3 mr-5">
            {props.selectedKey ? 'Edit Feature' : 'Add New Feature'}
          </div>
          <IconButton onClick={() => props.handleSave(false)} className="float-right">
            <CloseIcon className={classes.close} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField id="feature"
          value={feature}
          type="text"
          error={formSubmitted && !feature}
         
          placeholder={"Max. character limit is 50"}
          onChange={handleFeature}
          size="medium" className="col-12 my-2 pr-2  mt-2 commonRoundedInputs"
          label="Feature Name" required variant="outlined" margin="dense" 
          inputProps={{ maxLength: 50 }}
          />
        <TextField
          id="filterValueForNewDealerReq"
          select
          label="Feature Value Type"
          variant="outlined"
          className="rounded-input-dealer pr-2 mb-2 col-12 mt-3"
          size="small"
          value={type}
          onChange={handleType}
        >
          <MenuItem value="dropDown" key="0">Dropdown</MenuItem>
          <MenuItem value="textFiled" key="1">Text Field</MenuItem>
        </TextField>
        {type == "dropDown" && <>
          <p className="my-2 text-bold">Options</p>
          {options && options?.map((item: any, index: any) => {
            return <TextField id="feature"
              value={item}
              type="text"
              placeholder={"Max. character limit is 20"}
              error={formSubmitted && !item}
              InputProps={{
                endAdornment: <DeleteIcon  className="click-entire-row" onClick={() => {
                  let optiontemp = JSON.parse(JSON.stringify(options));
                  optiontemp.splice(index, 1)
                  setoptions([...optiontemp])
                }} />
              }}
              inputProps={{ maxLength: 20 }}
              onChange={(e: any) => {
                let optiontemp = options;
                optiontemp[index] = e.target.value;
                setoptions([...optiontemp])
              }}
              size="medium" className="col-12 my-2 pr-2  mt-2 commonRoundedInputs"
              label={`Option ${index + 1}`} required variant="outlined" margin="dense"
              
            />
          })}
          <Button onClick={() => {
            let optiontemp = JSON.parse(JSON.stringify(options));
            optiontemp.push("");
            setoptions([...optiontemp])
          }} className="btn-link ml-1 text-info" startIcon={<AddIcon />}
          >Add Option</Button>
        </>}
        {formSubmitted && (!feature || options.includes("")) && <p className="text-danger my-2">Please fill all mandatory fields.</p>}
        <div className="row mt-4 mb-4 justify-content-end">
          <Button variant="contained" className="cancel-button font-size-11 mr-2" onClick={() => props.handleSave(false)}>Cancel</Button>
          <Button autoFocus className="add-user-tabs-button font-size-11 mr-4" onClick={() => {
            setformSubmitted(true)
            let validData = true;
            if (!(feature && feature?.trim() && type)) {
              validData = false;
            }
            options.map((item:any) => {
              if (!item || !item.trim()) {
                validData = false;
              }
            })
            if (!validData) {
              return
            }
            if (props.selectedKey) {
              props.handleSave(true, true, { key: feature, type: type, options: options })
            } else {
              props.handleSave(true, false, { key: feature, type: type, options: options })
            }
          }}>{props.selectedKey ? 'Update' : 'Add'}</Button>
        </div>
      </DialogContent>
    </Dialog >
  );
}
