import { API_ENDPOINTS, FAVORITES } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const favoritesService = {
    getFavoritesList,
    getFavoritesDetails,
    createFavorites,
    deleteFavorites,
    editFavorites,
};

async function getFavoritesList(createdByME: any, searchText: any, page?: any) {
    const apiPath = API_ENDPOINTS.PRODUCTS_ENDPOINT + FAVORITES.API_PATH;
    const options = {
        path: `${apiPath}/customers/${sessionStorage.getItem('customerId')}?userId=${sessionStorage.getItem('webApplicationLoginId')}${createdByME ? '&global=false' : ""}${searchText ? `&searchBy=${searchText}` : ''}`,
        method: "GET",
        bodyObj: {
        },
        customHeaders: {
            "userId": sessionStorage.getItem('webApplicationLoginId'),
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getFavoritesDetails(id: any) {
    const apiPath = API_ENDPOINTS.MAIN_ENDPOINT + FAVORITES.GET;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        // bodyObj:data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function createFavorites(data: any) {
    const apiPath = API_ENDPOINTS.PRODUCTS_ENDPOINT + FAVORITES.API_PATH;
    const options = {
        path: `${apiPath}`,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true,
    };
    return await makeRequest(options)
}

async function editFavorites(id: any, data: any) {
    const apiPath = API_ENDPOINTS.PRODUCTS_ENDPOINT + FAVORITES.API_PATH;
    const options = {
        path: `${apiPath}/${id}`,
        method: "PUT",
        bodyObj: data,
        customHeaders: {
            // "Lob-Id": "AUTO",
            // "Channel-Id": "B2B",
        },
        formdata: true,
    };
    return await makeRequest(options)
}

async function deleteFavorites(data: any) {
    const apiPath = API_ENDPOINTS.PRODUCTS_ENDPOINT + FAVORITES.API_PATH;
    const options = {
        path: `${apiPath}/delete`,
        method: "POST",
        bodyObj: {
            "deleteId": data
        },
        customHeaders: {
            // "Lob-Id": "AUTO",
            // "Channel-Id": "B2B",
        },
        formdata: true,
    };
    return await makeRequest(options)
}
