import { MATERIAL_GROUPS } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  materialGroups: null
};

function materialGroupsReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case MATERIAL_GROUPS.GET_MATERIAL_GROUPS_FETCHING:
      return {
        ...state,
        materialGroups: { loading: true, data: null, err: null }
      };
    case MATERIAL_GROUPS.GET_MATERIAL_GROUPS_SUCCESS:
      return {
        ...state,
        materialGroups: { loading: false, data: action.payload.data, err: null }
      };
    case MATERIAL_GROUPS.GET_MATERIAL_GROUPS_ERROR:
      return {
        ...state,
        materialGroups: { loading: false, data: null, err: action.payload }
      };
    default:
      return state;
  }
};

export default materialGroupsReducer;
