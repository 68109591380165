import React, { Fragment } from 'react';
import { useSelector } from "react-redux";
import CustomerOnboarding from './customerOnboarding';
import { NewCustomerDialog } from './newCustomerDialog';

const Onboarding: React.FC = () => {
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [openCustomerDialog, setOpenCustomerDialog] = React.useState(false);
  const [showOnboard, showOnboarding] = React.useState(false);

  const verifySuccess = () => {
    showOnboarding(true);
  }

  return (
    <Fragment>
      {sessionStorage.getItem('customerType') !== '' && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.isExistingUser === false ? (
        <CustomerOnboarding />
      ) : (
        <>
          {profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.isExistingUser === false && !showOnboard ? (
            <NewCustomerDialog setOpenCustomerDialog={setOpenCustomerDialog} openCustomerDialog={openCustomerDialog} verifySuccess={verifySuccess} />
          ) : (
            <CustomerOnboarding />
          )}
        </>
      )}

    </Fragment>
  )
}

export default Onboarding;
