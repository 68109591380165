
import { LOGON_USER } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { logonService } from "../../services/logon.service";

export const logonActions = {
  userLogon,
  getJwtToken
};

export function userLogon(portal: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(LOGON_USER.GET_LOGON_USER_FETCHING));
    try {
      // const response = { success: 'true' };
      const response = await logonService.userLogon(portal);
      dispatch(apiSuccess(LOGON_USER.GET_LOGON_USER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(LOGON_USER.GET_LOGON_USER_ERROR, error));
      return error;
    }
  };
}

export function getJwtToken(b2cUserId: any, email: any, portal?:any) {
  return async (dispatch: any) => {
      dispatch(apiStart(LOGON_USER.GET_JWT_TOKEN_FETCHING));
        const response = await logonService.getToken(b2cUserId, email, portal);
        if (response.error) {
          dispatch(apiError(LOGON_USER.GET_JWT_TOKEN_ERROR, response));
          return response || [];
        } else {
          dispatch(apiSuccess(LOGON_USER.GET_JWT_TOKEN_SUCCESS, response));
          return response || [];
        }
      }
}

export function getKamToken(oid: any, secret: any) {
  return async (dispatch: any) => {
      dispatch(apiStart(LOGON_USER.GET_KAM_TOKEN_FETCHING));
        const response = await logonService.getKamToken(oid, secret);
        if (response.error) {
          dispatch(apiError(LOGON_USER.GET_KAM_TOKEN_ERROR, response));
          return response || [];
        } else {
          dispatch(apiSuccess(LOGON_USER.GET_KAM_TOKEN_SUCCESS, response));
          return response || [];
        }
      }
}

export function validateRefreshToken(refreshToken: any, b2cUserId: any, email: any) {
  return async (dispatch: any) => {
      dispatch(apiStart(LOGON_USER.VALIDATE_REFRESH_TOKEN_FETCHING));
        const response = await logonService.validateRefreshToken(refreshToken, b2cUserId, email);
        if (response.error) {
          dispatch(apiError(LOGON_USER.VALIDATE_REFRESH_TOKEN_ERROR, response));
          return response || [];
        } else {
          dispatch(apiSuccess(LOGON_USER.VALIDATE_REFRESH_TOKEN_SUCCESS, response));
          return response || [];
        }
      }
}

export function logoutUser() {
  return async (dispatch: any) => {
    dispatch(apiStart(LOGON_USER.LOGOUT_USER_FETCHING));
    try {
      // const response = { success: 'true' };
      const response = await logonService.logoutUser();
      dispatch(apiSuccess(LOGON_USER.LOGOUT_USER_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(LOGON_USER.LOGOUT_USER_ERROR, error));
      return error;
    }
  };
}




