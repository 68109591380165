import _ from "lodash";
import { useSelector } from "react-redux";
import { PREVENT_ACCESS } from "../../constants/restrictedAccessConstants";
import { OnboardingErrorPage } from "./OnboardingErrorPage";

export const AccountRestrictedComponent = (props:any) => {

    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    
    const logonData: any = useSelector((state: any) => state.logon.logonData);

    // const inactive = get(profileDetails, "data.data.customerInfo.isActive", "") === false; 

    const inactive = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.isActive && profileDetails.data.data.customerInfo.isActive === false;

    const locked = _.get(profileDetails, "data.data.basicInfo.lockIndicator", false) === true; 

    // let featureAccessibility = _.get(profileDetails, "data.data.accessParameter.featureAccessibility", []);

    // const isAdmin = _.get(profileDetails, "data.data.basicInfo.userType", "User") === "Admin"; 

    const idExpired = _.get(logonData, "data.error", "") === PREVENT_ACCESS.ACCOUNT_EXPIRED;

    const sessionGenerationFailed = _.get(logonData, 'data.statusText.data.message.status', "") === "E";
    const sessionGenerationFailureErrorMessage = _.get(logonData, 'data.statusText.data.error', "");


    // let inaccessibleFeatures = [];

    // let noAccess = false;

    // if(!isAdmin){
    //     if(featureAccessibility){
    //         inaccessibleFeatures = featureAccessibility.filter((feature:any) => {
    //             return feature.value === false;
    //         });
    //     }
        
        // if(featureAccessibility){
        //     noAccess = featureAccessibility.length === inaccessibleFeatures.length;
        //

    // }
    // else {
    //     featureAccessibility =[];
    // }

    //handle login expiry

    if(idExpired){
        return <OnboardingErrorPage status="idExpired"/>
    }

    //handle login failure
    if(logonData && logonData.data && logonData.data.message && logonData.data.message.status === "E"){
        return <OnboardingErrorPage status="loginError"/>
    }

    if(sessionGenerationFailed){
        return <OnboardingErrorPage status="sessionGenerationFailed" message={sessionGenerationFailureErrorMessage}/>
    }

    if(inactive){
        return <OnboardingErrorPage status="inactive" />
    }
    else if(locked){
        return <OnboardingErrorPage status="locked" />
    }
    // else if(noAccess){
    //     return <OnboardingErrorPage status="noAccess" />
    // }
    else {
    return(
        props.children
    )
}
}