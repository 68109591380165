import { IActionBase } from "../models/root.interface";
import { IGlobal } from "../models/global.interface";
import { OPEN_CLOSE_SIDE_MENU , MARK_AS_ADMIN } from "../actions/global.actions";

const initialState: IGlobal = {
    sideMenuOpened: true,
    isAdmin:false
};

function globalReducer(state: IGlobal = initialState, action: IActionBase): IGlobal {
    switch (action.type) {
        case OPEN_CLOSE_SIDE_MENU: {
            return { ...state, sideMenuOpened: action.sideMenuOpened};
        }
        case MARK_AS_ADMIN: {
            return { ...state, isAdmin: action.isAdmin};
        }
        default:
            return state;
    }
}


export default globalReducer;
