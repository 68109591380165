import { LOGON_USER } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  logonData: null,
  tokenInfo: null,
  validTokenInfo: null
};

function logonReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case LOGON_USER.GET_LOGON_USER_FETCHING:
      return {
        ...state,
        logonData: { loading: true, data: null, err: null }
      };
    case LOGON_USER.GET_LOGON_USER_SUCCESS:
      return {
        ...state,
        logonData: { loading: false, data: action.payload.data, err: null }
      };
    case LOGON_USER.GET_LOGON_USER_ERROR:
      return {
        ...state,
        logonData: { loading: false, data: null, err: action.payload }
      };
    case LOGON_USER.GET_JWT_TOKEN_FETCHING:
      return {
        ...state,
        tokenInfo: { loading: true, data: null, err: null }
      };
    case LOGON_USER.GET_JWT_TOKEN_SUCCESS:
      return {
        ...state,
        tokenInfo: { loading: false, data: action.payload.data, err: null }
      };
    case LOGON_USER.GET_JWT_TOKEN_ERROR:
      return {
        ...state,
        tokenInfo: { loading: false, data: null, err: action.payload }
      };
    case LOGON_USER.GET_KAM_TOKEN_FETCHING:
        return {
          ...state,
          tokenInfo: { loading: true, data: null, err: null }
        };
    case LOGON_USER.GET_KAM_TOKEN_SUCCESS:
        return {
          ...state,
          tokenInfo: { loading: false, data: action.payload.data, err: null }
        };
    case LOGON_USER.GET_KAM_TOKEN_ERROR:
        return {
          ...state,
          tokenInfo: { loading: false, data: null, err: action.payload }
        };
    case LOGON_USER.VALIDATE_REFRESH_TOKEN_FETCHING:
      return {
        ...state,
        validTokenInfo: { loading: true, data: null, err: null }
      };
    case LOGON_USER.VALIDATE_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        validTokenInfo: { loading: false, data: action.payload.data, err: null }
      };
    case LOGON_USER.VALIDATE_REFRESH_TOKEN_ERROR:
      return {
        ...state,
        validTokenInfo: { loading: false, data: null, err: action.payload }
      };

    default:
      return state;
  }
};

export default logonReducer;
