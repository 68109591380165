import { API_END, ACCESS_DENIED, API_ERROR, API_SUCCESS } from "./actionsTypes";

export const apiStart = (actionType: string, data : any = {}) => ({
    type: actionType,
    payload: data
});

export const apiEnd = (label: any) => ({
    type: API_END,
    payload: label
});

export const accessDenied = (url: any) => ({
    type: ACCESS_DENIED,
    payload: {
        url
    }
});

export const apiError = (actionType: string, error: any) => ({
    type: actionType ? actionType : API_ERROR,
    payload: {
        error
    }
});


export const apiSuccess = (actionType: string, data: any) => ({
    type: actionType ? actionType : API_SUCCESS,
    payload: {
        data
    }
});
