import React, { Fragment, Dispatch, useEffect } from "react";
import "./OrderDetails.css";
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOrderHistoryDetails } from "../../store/actions/orderHistory.actions";
import moment from "moment";
import "moment-timezone";
import XLSX from 'xlsx'
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { getNewBrand } from "../../common/components/util";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { get } from "lodash";
import { getSellingPrice } from "../../helpers/commonmethod";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`order-tabpanel-${index}`}
      aria-labelledby={`order-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

function allTabProps(index: any) {
  return {
    id: `order-tab-${index}`,
    'aria-controls': `order-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito'
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 12,
    minWidth: 70
  },
  quantity: {
    minWidth: 70
  },
  vat: {
    color: '#666666',
    fontSize: '0.625rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
  },
}));
const columnsBack = [
  {

    id: 'productIdDescription',
    label: 'Description',
    align: 'left',
  },
  {
    id: 'nowAvailability',
    label: 'Qty',
    align: 'left'
  },
  {
    id: 'quantity',
    label: 'Qty',
    align: 'left'
  },
  {
    id: 'backOrderPrice',
    label: 'Net Price',
    align: 'left'
  },
  {
    id: 'currency',
    label: 'Currency',
    align: 'left'
  },
  {
    id: 'deliveredQuantity',
    label: 'Delivered Qty.',
    align: 'left'
  },
  {
    id: 'deliveryStatus',
    label: 'Delivery Status',
    align: 'left'
  }
];
const columns = [
  {
    id: 'productIdDescription',
    label: 'Description',
    align: 'left',
  },
  {
    id: 'nowAvailability',
    label: 'Qty',
    align: 'left'
  },
  {
    id: 'quantity',
    label: 'Qty',
    align: 'left'
  },
  {
    id: 'netValueOrderDocumentCurrency',
    label: 'Net Price',
    align: 'left'
  },
  {
    id: 'currency',
    label: 'Currency',
    align: 'left'
  },
  {
    id: 'deliveredQuantity',
    label: 'Delivered Qty.',
    align: 'left'
  },
  {
    id: 'deliveryStatus',
    label: 'Delivery Status',
    align: 'left'
  }
];

const OrderDetails: React.FC = () => {
  const history = useHistory()
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const { orderId, orderDetails }: any = useSelector((state: any) => state.orderHistory);
  const [tabValue, setTabValue] = React.useState(0);
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);

  const { materialGroups }: any = useSelector((state: any) => state.materialGroups)

  const materialGroupsData = get(materialGroups, 'data.data', []);

  let enquiryMapping: any = {};

  materialGroupsData.forEach((group: any) => {
    return group.materialGroup.forEach((item: any) => {
      return enquiryMapping[item] = group.make;
    })
  })

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (orderId) {
      let backOrder: any = sessionStorage.getItem('isBackOrder');
      dispatch(getOrderHistoryDetails(orderId, backOrder));
      sessionStorage.removeItem('isBackOrder')
    }
  }, [orderId, dispatch]);

  useEffect(() => {
    // if (!orderId) {
    //   history.push('/orderhistory')
    // }
  }, []);

  const formatMaterialGroup = (data: any) => {
    if (data) {
      return data.substring(0, 5).toUpperCase()
    } else {
      return "XXXXX"
    }
  }

  const exportData = () => {
    if (tabValue === 0 && orderDetails.data.products && Array.isArray(orderDetails.data.products) && orderDetails.data.products.length > 0) {
      let filename = 'orderexport.xlsx';
      let data: any = []
      orderDetails.data.products.map((item: any) => {
        data.push({
          'Part No': item.productId,
          'Alternate Part': item.isSuperseeded ? 'YES' : 'NO',
          'Part Description': item.productIdDescription,
          Brand: enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] : '',
          'Quantity': item.nowAvailability,
          'Net Price': Number(parseFloat(getSellingPrice(Number(item.netValueOrderDocumentCurrency), isSellingPriceData, markupPercentage)).toFixed(2)),
          Currency: item.currency,
          'Delivered Quantity': Number(parseFloat(item.deliveredQuantity).toFixed(2)),
          'Delivery Status': item.deliveryStatus,
          'Net Value (Excl. Vat)': Number(parseFloat(getSellingPrice(Number(item.netPrice), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Net Value (Incl. Vat)': Number(parseFloat(getSellingPrice(Number(item.nsp), isSellingPriceData, markupPercentage)).toFixed(2)),
          Width: item.dimensions.width ? Number(parseFloat(item.dimensions.width).toFixed(2)) : "",
          Height: item.dimensions.height ? Number(parseFloat(item.dimensions.height).toFixed(2)) : "",
          Length: item.dimensions.length ? Number(parseFloat(item.dimensions.length).toFixed(2)) : "",
          Weight: item.dimensions.grossWeight ? Number(parseFloat(item.dimensions.grossWeight).toFixed(2)) : "",
          Volume: item.dimensions.volume ? Number(parseFloat(item.dimensions.volume).toFixed(2)) : "",
          'Orignal Part': item.supercessionMaterial
        })
        return undefined;
      })
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
    }
    if ((tabValue === 1 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0)
      || (tabValue === 0 && orderDetails && orderDetails.data && orderDetails.data.products && orderDetails.data.products.length === 0 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0)) {
      let filename = 'backorderexport.xlsx';
      let data: any = []
      orderDetails.data.backOrder.map((item: any) => {
        data.push({
          'Part No': item.productId,
          'Part Description': item.productIdDescription,
          Brand: enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] : '',
          'Quantity': Number(parseInt(item.quantity)),
          'Net Price': Number(parseFloat(getSellingPrice(Number(item.backOrderPrice), isSellingPriceData, markupPercentage)).toFixed(2)),
          Currency: item.currency,
          'Delivered Quantity': item.deliveredQuantity ? Number(parseFloat(item.deliveredQuantity).toFixed(2)) : "",
          'Delivery Status': item.deliveryStatus,
          'Net Value (Excl. Vat)': Number(parseFloat(getSellingPrice(Number(item.nspev), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Net Value (Incl. Vat)': Number(parseFloat(getSellingPrice(Number(item.nsp), isSellingPriceData, markupPercentage)).toFixed(2)),
          Width: item.dimensions.width ? Number(parseFloat(item.dimensions.width).toFixed(2)) : "",
          Height: item.dimensions.height ? Number(parseFloat(item.dimensions.height).toFixed(2)) : "",
          Length: item.dimensions.length ? Number(parseFloat(item.dimensions.length).toFixed(2)) : "",
          Weight: item.dimensions.grossWeight ? Number(parseFloat(item.dimensions.grossWeight).toFixed(2)) : "",
          Volume: item.dimensions.volume ? Number(parseFloat(item.dimensions.volume).toFixed(3)) : ""
        })
        return undefined;
      })
      var ws1 = XLSX.utils.json_to_sheet(data);
      var wb1 = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb1, ws1, "sheet1");
      XLSX.writeFile(wb1, filename);
    }
  }
  const showDownloadOrders = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.DOWNLOAD_ORDER);
  const showListOrders = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.LIST_ORDERS);
  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Order History
        </h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      {orderDetails && orderDetails.loading && <FullscreenLoader />}
      {orderDetails && orderDetails.data && (
        <>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <h5 className="page-sub-title">Order Details</h5>
                <IconButton className="ml-auto" onClick={() => {
                  history.push('/orderhistory')
                }}>
                  <ChevronLeftIcon fontSize="small" />
                  <span className="backLinkLabel">Back to List</span>
                </IconButton>
              </div>
              <div className="cardCommon d-flex font-11">
                <div className="col-lg-4 col-md-4 col-sm-12 p-3 borderRight">
                  <h6 className="title mb-3">Enquiry Details</h6>
                  <div className="row">
                    <div className="col-6 col-md-6 col-sm-6">
                      <div className="subTitle mb-2">Reference No:
                        <span className="subTitleValue">{orderDetails.data.masterReferenceNumber}</span>
                      </div>
                      <div className="subTitle mb-2">Enquiry No:
                        <span className="subTitleValue">{orderDetails.data.masterId}</span>
                      </div>
                      <div className="subTitle mb-2">Created On:
                        <span className="subTitleValue">{orderDetails.data.enquiryCreatedOn && moment(orderDetails.data.enquiryCreatedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</span>
                      </div>
                      <div className="subTitle mb-2">Internal Number:
                        <span className="subTitleValue">{orderDetails.data.enquiryNumber}</span>
                      </div>
                    </div>
                    <div className="col-6 col-md-6 col-sm-6">
                      <div className="subTitle mb-2">Status:
                        <span className="subTitleValue">{orderDetails.data.enquiryStatus}</span>
                      </div>
                      <div className="subTitle mb-2">Created By:
                        <span className="subTitleValue">{orderDetails.data.createdBy}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 p-3">
                  <h6 className="title mb-3">Order Details</h6>
                  <div className="row">
                    <div className="col-4 col-md-4 col-sm-4">
                      <div className="subTitle mb-2">Now Order No:
                        <span className="subTitleValue">{orderDetails.data.orderNumber}</span>
                      </div>
                      <div className="subTitle mb-2">Created By:
                        <span className="subTitleValue">{orderDetails.data.createdBy}</span>
                      </div>
                      <div className="subTitle mb-2">Created On:
                        <span className="subTitleValue">{orderDetails.data.orderCreatedOn && moment(orderDetails.data.orderCreatedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</span>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 col-sm-4">
                      <div className="subTitle mb-2">Order Status:
                        <span className="subTitleValue">{orderDetails.data.orderStatus}</span>
                      </div>
                      <div className="subTitle mb-2">Back Order:
                        {orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && <span className="subTitleValue">
                          <Button variant="contained" className="subTitleButton" size="small">{orderDetails.data.backOrder.length > 0 ? 'YES' : 'NO'}</Button>
                        </span>}
                      </div>
                      <div className="subTitle mb-2">Brand:
                        <span className="subTitleValue">{getNewBrand(sessionStorage.getItem('orderStoredBrand'))}</span>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 col-sm-4">
                      <div className="subTitle mb-2">Overall Delivery Status:
                        <span className="subTitleValue">{orderDetails.data.overallDeliveryStatus}</span>
                      </div>
                      <div className="subTitle mb-2">Overall Blocked Status:
                        <span className="subTitleValue">{orderDetails.data.overallBlockedStatus}</span>
                      </div>
                      <div className="subTitle mb-2">Credit Status:
                        <span className="subTitleValue">{orderDetails.data.creditStatus}</span>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 col-sm-4">
                      {orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0 && <div className="subTitle mb-2">Back Order No:
                        <span className="subTitleValue">{sessionStorage.getItem('orderBackNumber')}</span>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div >
          </div>
          <div className="row mt-4">
            <div className="col-lg-12 col-md-12 col-sm-12 justifyContentHorizontalSpacebetween align-items-center">
              <h5 className="page-sub-title">Order Items</h5>
              <div className="d-flex justify-content-end">
                <span className="superseededdot mr-2"></span>
                <span className="superseededdotLabel mr-3">Alternate Part</span>
                {showDownloadOrders &&
                  <div className="exportExcel mr-3" onClick={() => exportData()} style={{ cursor: 'pointer' }}>
                    <IconButton className="exportExcel" size="small">
                      <ArrowDownwardIcon fontSize="inherit" />
                    </IconButton>
                    Export to Excel
                  </div>
                }
                <div className="font-weight-bold totalAed">Total AED {tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.netPrice ? parseFloat(getSellingPrice(orderDetails.data.netPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-') : (orderDetails.data.backOrderPrice ? parseFloat(getSellingPrice(orderDetails.data.backOrderPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-')}</div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="cardCommon orderItems">
                <Paper square>
                  <Tabs className="pl-2" value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="Dealer Tab">
                    {orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 && <Tab label="Now Order" {...allTabProps(0)} />}
                    {orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 && <Tab label="Back Order" {...allTabProps(orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? 1 : 0)} />}
                  </Tabs>
                </Paper>
                {orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 && <TabPanel value={tabValue} index={0}>
                  <TableContainer className={`classes.container`}>
                    <Table aria-label="table" >
                      <TableHead className="w-100">
                        <TableRow>
                          <TableCell className={`pt-0 pb-0 ${classes.tableHead}`}>Part No.</TableCell>
                          <TableCell className={classes.tableHead}>Description</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Qty</TableCell>
                          <TableCell className={classes.tableHead}>
                            <h6 className="netHead mt-3 pt-1 pb-0">Net Price</h6>
                            <div className={classes.vat}>(Incl.Vat)</div>
                          </TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Currency</TableCell>
                          <TableCell className={`mt-5 pt-0 pb-0 ${classes.tableHead}`}>Delivered Qty.</TableCell>
                          <TableCell className={`mt-5 pt-0 pb-0 ${classes.tableHead}`}>Delivery Status</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>
                            <h6 className="netHead mt-3 pt-0 pb-0">Net Value</h6>
                            <div className={classes.vat}>(Excl.Vat)</div>
                          </TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>
                            <h6 className="netHead mt-3 pt-0 pb-0">Net Value</h6>
                            <div className={classes.vat}>(Incl.Vat)</div>
                          </TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>AOP</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Width</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Height</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Length</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Weight</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Volume</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderDetails.data && orderDetails.data.products && orderDetails.data.products.map((row: any) => {
                          return (
                            <TableRow className={`${row.isSuperseeded ? "superseededRow" : ""}`}>
                              <TableCell className={classes.bodyContent}>
                                {!row.supercessionMaterial ? row.productId : <>
                                  {row.productId}<br />
                                  (<small>{row.supercessionMaterial}</small>)
                                </>}
                              </TableCell>
                              {columns.map((column: any) => {
                                if (column.id !== 'quantity' && column.id !== 'deliveredQuantity') {
                                  const value: any = column.id === 'nowAvailability' ? parseInt(row[column.id]) : row[column.id];
                                  return (
                                    <TableCell key={column.id} className={classes.bodyContent}>
                                      {column.id === 'netValueOrderDocumentCurrency' ? parseFloat(getSellingPrice(value, isSellingPriceData, markupPercentage)).toFixed(2) : value}
                                    </TableCell>
                                  );
                                }
                                if (column.id === 'deliveredQuantity') {
                                  const value: any = row[column.id];
                                  return (
                                    <TableCell key={column.id} className={classes.bodyContent}>
                                      {parseFloat(value).toFixed(2)}
                                    </TableCell>
                                  );
                                }
                                return undefined;
                              })}
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(getSellingPrice(row.netPrice, isSellingPriceData, markupPercentage)).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(getSellingPrice(row.nsp, isSellingPriceData, markupPercentage)).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(getSellingPrice(row.aop, isSellingPriceData, markupPercentage)).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(row.dimensions.width).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(row.dimensions.height).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(row.dimensions.length).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {row.dimensions.grossWeight ? parseFloat(row.dimensions.grossWeight).toFixed(2) : '-'}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {row.dimensions.volume}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>}
                {orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 && <TabPanel value={tabValue} index={orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? 1 : 0}>
                  <TableContainer className={`classes.container`}>
                    <Table aria-label="table" >
                      <TableHead className="w-100">
                        <TableRow>
                          <TableCell className={`pt-0 pb-0 ${classes.tableHead}`}>Part No.</TableCell>
                          <TableCell className={classes.tableHead}>Description</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Qty</TableCell>
                          <TableCell className={classes.tableHead}>
                            <h6 className="netHead mt-3 pt-1 pb-0">Net Price</h6>
                            <div className={classes.vat}>(Incl.Vat)</div>
                          </TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Currency</TableCell>
                          <TableCell className={`mt-5 pt-0 pb-0 ${classes.tableHead}`}>Delivered Qty.</TableCell>
                          <TableCell className={`mt-5 pt-0 pb-0 ${classes.tableHead}`}>Delivery Status</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>
                            <h6 className="netHead mt-3 pt-0 pb-0">Net Value</h6>
                            <div className={classes.vat}>(Excl.Vat)</div>
                          </TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>
                            <h6 className="netHead mt-3 pt-0 pb-0">Net Value</h6>
                            <div className={classes.vat}>(Incl.Vat)</div>
                          </TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>AOP</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Width</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Height</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Length</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Weight</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Volume</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder.map((row: any) => {
                          return (
                            <TableRow className={`${row.isSuperseeded ? "superseededRow" : ""}`}>
                              <TableCell className={classes.bodyContent}>
                                {!row.supercessionMaterial ? row.productId : <>
                                  {row.productId}<br />
                                  (<small>{row.supercessionMaterial}</small>)
                                </>}
                              </TableCell>
                              {columnsBack.map((column: any) => {
                                if (column.id !== 'nowAvailability') {
                                  const value: any = column.id === 'quantity' ? parseInt(row[column.id]) : row[column.id];
                                  return (
                                    <TableCell key={column.id} className={classes.bodyContent}>
                                      {column.id === 'backOrderPrice' ? parseFloat(getSellingPrice(value, isSellingPriceData, markupPercentage)).toFixed(2) : value}
                                    </TableCell>
                                  );
                                }
                                return undefined;
                              })}
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(getSellingPrice(row.nspev, isSellingPriceData, markupPercentage)).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(getSellingPrice(row.nsp, isSellingPriceData, markupPercentage)).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(getSellingPrice(row.aop, isSellingPriceData, markupPercentage)).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(row.dimensions.width).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(row.dimensions.height).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(row.dimensions.length).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {row.dimensions.grossWeight ? parseFloat(row.dimensions.grossWeight).toFixed(2) : '-'}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {row.dimensions.volume}                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
                }<div className="bottomBorder w-100 col-12 d-flex justify-content-end">
                  <div className="totalAED mt-3 mb-3 mr-3">Total AED {tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.netPrice ? parseFloat(getSellingPrice(orderDetails.data.netPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-') : (orderDetails.data.backOrderPrice ? parseFloat(getSellingPrice(orderDetails.data.backOrderPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-')}</div>
                </div>
                {/* <div className="col-12 d-flex justify-content-end">
                  <TablePagination
                    rowsPerPageOptions={[5]}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment >
  );
};

export default OrderDetails;
