import React from 'react';


export default function Spinner() {

    return (
        <div className="spinner-border text-success">     
        </div>
    );
}
