import { TERMS_AND_CONDITIONS } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  termsAndConditions: null,
};

function termsAndConditionsReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case TERMS_AND_CONDITIONS.GET_TERMS_AND_CONDITIONS_FETCHING:
      return {
        ...state,
        termsAndConditions: { loading: true, data: null, err: null }
      };
    case TERMS_AND_CONDITIONS.GET_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        termsAndConditions: { loading: false, data: action.payload.data, err: null }
      };
    case TERMS_AND_CONDITIONS.GET_TERMS_AND_CONDITIONS_ERROR:
      return {
        ...state,
        termsAndConditions: { loading: false, data: null, err: action.payload }
      };
    default:
      return state;
  }
};

export default termsAndConditionsReducer;
