import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";


export async function getMaterialGroupService() {
    const apiPath = API_ENDPOINTS.MATERIAL_GROUPS
    const options = {
      path: apiPath,
      customHeaders: {},
      method: "GET",
    };
    return await makeRequest(options);
  }