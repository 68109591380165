import React, { Fragment, Dispatch, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import TableCell from '@material-ui/core/TableCell';
import { TextField, MenuItem } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import CancelIcon from '@material-ui/icons/Cancel';
import { ValidationErrorMessage } from '../../constants/messages';
import { get } from 'lodash';
import { deleteAddressDocument, resetValues, updateDocument, getTradeRegion } from '../../store/actions/onboard.actions';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import DeleteDialog from '../../common/components/deleteDialog';
import DeleteDocumentDialog from '../../common/components/deleteDocumentDialog';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  title: {
    flex: '1 1 100%',
  },
  buttonDel: {
    color: "#1A9FE0",
    fontSize: '0.625rem',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    float: 'right'
  },
  buttonIconDel: {
    color: "#1A9FE0",
  },
  bodyContent: {
    color: '#666666',
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 600
  },
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  oppacityLess: {
    opacity: 0.5,
  }
}));

export const AddNewAddressDailog = forwardRef((props: { setOpenDialog: any, getNewAddressData: any, editedAddress: any, isEdited: boolean, regionData: any, sapId: any, status: any }, ref) => {
  const [address, setAddress] = React.useState<any>([]);
  const [companyName, setCompanyName] = React.useState('');
  const [companyNameError, setCompanyNameError] = React.useState<boolean>(false);
  const [streetName, setStreetName] = React.useState<string>("");
  const [streetNameError, setStreetNameError] = React.useState<boolean>(false);
  const [city, setCity] = React.useState<string>("");
  const [cityError, setCityError] = React.useState<boolean>(false);
  const [country, setCountry] = React.useState<string>("");
  const [countryError, setCountryError] = React.useState<boolean>(false);
  const [postalCode, setPostalCode] = React.useState<string>("");
  const [postalCodeError, setPostalCodeError] = React.useState<boolean>(false);
  const dispatch: Dispatch<any> = useDispatch();
  const { tradeRegionData }: any = useSelector((state: any) => state.onboard);
  const [nameError, showError] = React.useState<boolean>(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [isEdit, setIsEdit] = React.useState(true);
  const [expiryDate, setExpiryDate] = React.useState<string>();
  const today: any = moment(new Date()).format("YYYY-MM-DD");
  const [locationName, setLocationName] = React.useState<string>("");
  const [locationNameError, setLocationNameError] = React.useState<boolean>(false);
  const [invalidLocationName, setInvalidLocatioName] = React.useState<boolean>(false);
  const [telephoneOffice, setTelephoneOffice] = React.useState<string>("");
  const [telephoneOfficeError, setTelephoneOfficeError] = React.useState<boolean>(false);
  const [telephoneOfficeErrorMessage, setTelephoneOfficeValidtionMessage] = React.useState<string>("");
  const [invalidTelephoneOffice, setInvalidTelephoneOffice] = React.useState<boolean>(false);
  const [poBox, setPoBox] = React.useState<string>("");
  const [poBoxError, setPoBoxError] = React.useState<boolean>(false);
  const [invalidPoBox, setInvalidPoBox] = React.useState<boolean>(false);
  const [selectEmirates, setSelectEmirates] = React.useState<string>("");
  const [selectEmiratesError, setSelectEmiratesError] = React.useState<boolean>(false);
  const [dealerAddress, setDealerAddress] = React.useState<string>("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [isSubmit, setSubmit] = React.useState<any>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [documentType, setDocumentType] = React.useState<any>(null);
  const [openDeleteDialogDocument, setOpenDeleteDialogDocument] = React.useState(false);
  const [selectTrade, setSelectTrade] = React.useState<string>("");
  const [selectTradeId, setSelectTradeId] = React.useState<string>("");
  const [selectTradeError, setSelectTradeError] = React.useState<boolean>(false);
  const [authority, setAuthority] = React.useState<any>(null);
  const [authorityDocumentId, setAuthorityDocumentId] = React.useState<any>({ documentId: '' });
  const [deliveryLocationRegFile, setDeliveryLocationRegFile] = React.useState<any>(null);
  const [deliveryLocation, setDeliveryLocation] = React.useState<any>({ documentId: '' });
  const [permises, setPermises] = React.useState<any>(null);
  const [permisesDocumentId, setPermisesDocumentId] = React.useState<any>({ documentId: '' });
  const [invalidAuthorized, setInvalidAuthorized] = React.useState<boolean>(false);
  const [invalidStreetName, setInvalidStreetName] = React.useState<boolean>(false);
  const [invalidCityName, setInvalidCityName] = React.useState<boolean>(false);
  const [invalidCountryName, setInvalidCountryName] = React.useState<boolean>(false);
  const [dealerAddressError, setDealerAddressError] = React.useState<boolean>(false);
  const [invalidDoucmentupload, setInvalidDoucmentupload] = React.useState<boolean>(false);
  const [invalidPhotopremises, setInvalidPhotopremises] = React.useState<boolean>(false);
  const [visaExpiryDate, setVisaExpiryDate] = React.useState<any>(null);
  const [openDeleteDialogAddress, setOpenDeleteDialogAddress] = React.useState(false);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [pictureDocumentId, setPictureDocumentId] = React.useState<string>("");
  const [currentEmiratesIndex, setCurrentEmiratesIndex] = React.useState<any>(0);
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const b2bUserId: any = selectedNewDealer && selectedNewDealer.createdByB2bUserId;
  const sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
  const [symbolArr] = React.useState<any>(["e", "E", ".", "-"]);
  const [authorizedPersons, setAuthorizedPersons] = React.useState<any>([
    {
      name: "",
      ValidationErrorMessage: "",
      document: null,
      isValidEmail: true,
      isValidContactNumber: true,
      emailId: "",
      isDocumentUploaded: false,
      isValidName: true,
      expiryDate: null,
      // documentId: 0,
      isValidExpiryDate: false
    }
  ])

  function isFieldsDisabled() {
    if (props.status === 'Incomplete from KAM' || props.status === 'Dealer confirmation pending') {
      return false;
    } else {
      return true;
    }
  }

  const handleopenCloseDocument = (open?: any) => {
    setOpenDeleteDialogDocument(open ? true : false);
    setSuccessMessage(null);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const addAuthorizedPersonsRows = () => {
    let isValid = true;
    setSubmit(false);
    authorizedPersons.forEach((item: any, index: any) => {
      item.isValidName = true;
      item.isValidEmail = true;
      item.isValidExpiryDate = true;
      item.ValidationErrorMessage = "";
      item.isDocumentUploaded = true;
      if (item.name == "") {
        isValid = false;
        item.isValidName = false;
        item.ValidationErrorMessage = "Please fill the details and upload the document";
      }
      else if (item.document == null) {
        isValid = false;
        item.isDocumentUploaded = false;
        if (item.name != "") {
          setSubmit(true);
          item.ValidationErrorMessage = "Please upload the Emirates Id (jpg or png)";
        }
      }
      else if (item.expiryDate == "Invalid date") {
        item.isValidExpiryDate = false;
        isValid = false;
        item.ValidationErrorMessage = "Please mention the expiry date";
      }
      authorizedPersons[index] = {
        ...item
      }
    });
    let authorizedPersonsRow = [...authorizedPersons];
    setAuthorizedPersons(authorizedPersonsRow);
    const row = {
      name: "",
      ValidationErrorMessage: "",
      document: null,
      documentid: 0,
      isValidEmail: true,
      isValidContactNumber: true,
      contactNumber: '',
      emailId: '',
      isDocumentUploaded: false,
      isValidName: true,
      expiryDate: null,
      isValidExpiryDate: false
    }
    if (isValid) {
      authorizedPersonsRow.push(row);
      setAuthorizedPersons(authorizedPersonsRow);
      setInvalidAuthorized(false);
    }
    else {
      setInvalidAuthorized(true);
    }
  }
  const deleteAuthorizedPersonsRows = () => {
    let authorizedPersonsRow = [...authorizedPersons];
    authorizedPersonsRow = authorizedPersonsRow.filter((element, index1) => {
      return index1 != currentEmiratesIndex;
    })
    setAuthorizedPersons(authorizedPersonsRow);
    handleopenClose(false);
  }
  const { setOpenDialog, getNewAddressData, editedAddress, isEdited, regionData, sapId } = props;
  const removeAddress = (e: any, index: any) => {
    const addressData: any = [...address];
    addressData.splice(index, 1);
    setAddress(addressData);
  };

  function emailvalidation(email: any, index: any) {
    if (email.length > 0 && !(/^\w+([\..-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.trim()))) {
      return false;
    }
    else {
      return true;
    }
  }

  function validateAuthorisedPersonsName(name: string) {
    if (name !== '') {
      if (validateFormFields('authorizeName', name)) {
        return true
      } else {
        return false
      }
    } else {
      return name === "" || name === undefined || name === null
    }
  }

  function validateAuthorizedPersonsDocument(doc: any) {
    return doc === "" || doc === undefined || doc === null
  }

  function validateAuthorizedPersonDate(doc: any) {
    return doc === "" || doc === undefined || doc === null
  }

  function validatecontactNumber(contactNumber: any, index: any) {
    if (contactNumber && contactNumber !== '' && contactNumber.length <= 10) {
      authorizedPersons[index].isValidContactNumber = true;
      setAuthorizedPersons(authorizedPersons);
      return true;
    }
    authorizedPersons[index].isValidContactNumber = false;
    setAuthorizedPersons(authorizedPersons);
    return false
  }

  function validateAuthorisedPersons() {
    let ValidArray: any = [];
    authorizedPersons.map((eachPerson: any, index: any) => {
      if (validatePerson(eachPerson, index)) {
        ValidArray.push(eachPerson);
      }
    })
    if (authorizedPersons.length !== 0 && ValidArray && (ValidArray.length === 0 || ValidArray.length > 0) && authorizedPersons.length !== ValidArray.length) {
      const updatedDataWithErrors = authorizedPersons.map((person: any, index: any) => {
        return {
          ...person,
          isValidName: person.name === '' ? false : validateAuthorisedPersonsName(person.name) ? true : false,
          isValidEmail: emailvalidation(person.emailId, index)
        }
      })
      setAuthorizedPersons(updatedDataWithErrors)
      return false
    }
    return true
  }

  function validatePerson(eachPerson: any, index: any) {
    let isvalid = true
    if (eachPerson.name === "") {
      isvalid = false;
    }
    if (!validatecontactNumber(eachPerson.contactNumber, index)) {
      isvalid = false;
    }
    if (validateAuthorizedPersonDate(eachPerson.expiryDate)) {
      isvalid = false;
    }
    if (validateAuthorizedPersonsDocument(eachPerson.document)) {
      isvalid = false;
    }
    return isvalid;

  }

  function validateFormFields(action: string, value: any) {
    switch (action) {
      case "telephoneOffice": {
        const re = /^[0-9]{20}$/
        return value.length > 20 || !re.test(value) ? false : true;
      }
      case "location": {
        // const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return value.length > 250 ? false : true;
      }
      case "poBox": {
        const re = /^[0-9]{7}$/
        return !re.test(value) ? false : true;
      }
      case "streename": {
        // const re = /^[a-zA-Z0-9 ]{1,250}$/;
        // return !re.test(value) ? false : true;
        return value.length > 250 ? false : true;
      }
      case "city": {
        // const re = /^[a-zA-Z0-9 ]{1,250}$/;
        // return !re.test(value) ? false : true;
        return value.length > 250 ? false : true;
      }
      case "country": {
        const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return !re.test(value) ? false : true;
      }
      case "authorizeName": {
        return true;
        // const re = /^[a-zA-Z0-9 ]{1,250}$/;
        // return !re.test(value) ? false : true;
      }
      default:
        return true
    }
  }

  function ValidateOnSaveAddress() {
    let isValidationSucess: boolean = true;
    setIsValid(true);
    if (locationName == "" || locationName == undefined) {
      setLocationNameError(true);
      setIsValid(false);
      isValidationSucess = false;
    }
    if (locationName && locationName.trim() !== "") {
      if (!validateFormFields("location", locationName)) {
        setLocationNameError(true);
        setIsValid(false);
        isValidationSucess = false;
      }
      else {
        setLocationNameError(false);
      }
    }

    if (editedAddress.isExisting === false) {
      if (telephoneOffice == "" || telephoneOffice == undefined) {
        setTelephoneOfficeError(true);
        setIsValid(false);
        isValidationSucess = false;
      }
      if (poBox == "" || poBox == undefined) {
        setPoBoxError(true);
        setIsValid(false);
        isValidationSucess = false;
      }
      if (poBox.trim() !== "") {
        if (poBox.length > 7) {
          setPoBoxError(true);
          setInvalidPoBox(true);
          isValidationSucess = false;
        }
        else {
          setInvalidPoBox(false);
          setPoBoxError(false);
        }
      }
      if (selectEmirates == "" || selectEmirates == undefined) {
        setSelectEmiratesError(true);
        setIsValid(false);
        isValidationSucess = false;
      }
      else {
        setSelectEmiratesError(false);
      }
      if (streetName == "" || streetName == undefined) {
        setStreetNameError(true);
        setIsValid(false);
        isValidationSucess = false;
      }
      if (streetName.trim() !== "") {
        if (!validateFormFields("streename", streetName)) {
          setStreetNameError(true);
          setIsValid(false);
          isValidationSucess = false;
        }
        else {
          setStreetNameError(false);
        }
      }
      if (city == "" || city == undefined) {
        setCityError(true);
        setIsValid(false);
        isValidationSucess = false;
      }
      if (city.trim() !== "") {
        if (!validateFormFields("city", city)) {
          setCityError(true);
          setIsValid(false);
          isValidationSucess = false;
        }
        else {
          setCityError(false);
        }
      }
      if (country == "" || country == undefined) {
        setCountryError(true);
        setIsValid(false);
        isValidationSucess = false;
      }
      if (country && country.trim() !== "") {
        if (!validateFormFields("country", country)) {
          setCountryError(true);
          setIsValid(false);
          isValidationSucess = false;
        }
        else {
          setCountryError(false);
        }
      }
      if (postalCode == "" || postalCode == undefined) {
        setPostalCodeError(true);
        setIsValid(false);
        isValidationSucess = false;
      }

      if (telephoneOffice !== "" && telephoneOffice != undefined) {
        if (telephoneOffice.split('').length > 20) {
          setInvalidTelephoneOffice(true);
          isValidationSucess = false;
          setIsValid(false);
        }
        else {
          setInvalidTelephoneOffice(false);
        }
      }
    }
    if (selectTrade == "" || selectTrade == undefined) {
      setSelectTradeError(true);
      setIsValid(false);
      isValidationSucess = false;
    }
    else {
      setSelectTradeError(false);
    }

    if (!deliveryLocationRegFile) {
      isValidationSucess = false;
    }
    if (!permises) {
      isValidationSucess = false;
    }
    if (!authority) {
      isValidationSucess = false;
    }
    if (!validateAuthorisedPersons()) {
      isValidationSucess = false
    }
    return isValidationSucess;
  }

  const populateAddressDetails = (editedAddress: any) => {
    if (isEdited && editedAddress) {
      setLocationName(editedAddress.location);
      setTelephoneOffice(typeof editedAddress.telephoneNumber == 'string' ? editedAddress.telephoneNumber : editedAddress && editedAddress.telephoneNumber && editedAddress.telephoneNumber.length > 0 && editedAddress.telephoneNumber[0].telephone);
      setPoBox(editedAddress.poBox);
      setSelectEmirates(editedAddress.region);
      setSelectTrade(editedAddress.tradeLicenseNo);
      setSelectTradeId(editedAddress.tradeLicenseNoId)
      setStreetName(editedAddress.streetName);
      setCity(editedAddress.city);
      setCountry(editedAddress.country);
      setPostalCode(editedAddress.postalCode);
      if (editedAddress && editedAddress.personToAcceptDelivery && editedAddress.personToAcceptDelivery.length > 0) {
        let data: any = [];
        editedAddress.personToAcceptDelivery.map((tl: any) => {
          if (tl.documentId) {
            data.push({
              name: tl.name,
              isValidName: tl.name ? true : false,
              isValidEmail: true,
              document: { ...tl.documentId },
              expiryDate: tl.expiryDate,
              ValidationErrorMessage: "",
              isValidContactNumber: true,
              isDocumentUploaded: tl.documentId && tl.documentId.url ? true : false,
              isValidExpiryDate: true,
              contactNumber: tl.contactNumber,
              emailId: tl.emailId
            })
          }
        });
        setAuthorizedPersons(data);
      }
      if (editedAddress && editedAddress.pictureOfDeliveryLocation && editedAddress.pictureOfDeliveryLocation.documentId) {
        const { id } = editedAddress.pictureOfDeliveryLocation.documentId;
        setDeliveryLocation({ documentId: id })
        setDeliveryLocationRegFile(editedAddress.pictureOfDeliveryLocation.documentId);
      }
      if (editedAddress && editedAddress.photoOfPremises && editedAddress.photoOfPremises.documentId) {
        const { id } = editedAddress.photoOfPremises.documentId;
        setPermisesDocumentId({ documentId: id })
        setPermises(editedAddress.photoOfPremises.documentId);
      }
      if (editedAddress && editedAddress.authorityLetter && editedAddress.authorityLetter.documentId) {
        const { id } = editedAddress.authorityLetter.documentId;
        setAuthorityDocumentId({ documentId: id })
        setAuthority(editedAddress.authorityLetter.documentId);
      }
    }
  }

  useEffect(() => {
    populateAddressDetails(editedAddress);
    dispatch(getTradeRegion(b2bUserId));
  }, [editedAddress])


  const validateLocationField = (locationId: string, locationValue: string) => {
    if (!validateFormFields(locationId, locationValue)) {
      setInvalidLocatioName(true);
      setLocationNameError(true);
      setIsValid(false);
    }
    else {
      setInvalidLocatioName(false);
      setLocationNameError(false);
      setIsValid(true);
    }
  }

  const onChangeLocationName = (e: any) => {
    const locationValue = e.target.value;
    setLocationName(locationValue);
    if (e.target.required) {
      if (locationValue === "" || locationValue.trim() === "")
        setLocationNameError(true);
      else {
        validateLocationField(e.target.id, locationValue)
      }
    }
  }

  const onChangeCompanyName = (e: any) => {
    setCompanyName(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setCompanyNameError(true);
      else
        setCompanyNameError(false);
    }
  }

  const validateTelephoneValue = (telephoneId: string, telephoneValue: string) => {
    if (telephoneValue.length > 20 && telephoneValue.charAt(0) == "0") {
      setInvalidTelephoneOffice(true);
      setTelephoneOfficeError(true);
      setIsValid(false);
      setTelephoneOfficeValidtionMessage(ValidationErrorMessage.LANDLINE_TEXT_START + " and " + ValidationErrorMessage.LANDLINE_TEXT_END)
    }
    else if (telephoneValue.charAt(0) == "0") {
      setInvalidTelephoneOffice(true);
      setIsValid(false);
      setTelephoneOfficeError(true);
      setTelephoneOfficeValidtionMessage(ValidationErrorMessage.LANDLINE_TEXT_START)
    }
    else if (telephoneValue.length > 20) {
      setInvalidTelephoneOffice(true);
      setIsValid(false);
      setTelephoneOfficeError(true);
      setTelephoneOfficeValidtionMessage(ValidationErrorMessage.LANDLINE_TEXT_END)
    }
    else {
      setInvalidTelephoneOffice(false);
    }
  }

  const onChangeTelephoneOffice = (e: any) => {
    const telephoneValue = e.target.value
    const telephoneId = e.target.id
    setTelephoneOffice(telephoneValue);
    if (e.target.required) {
      if (telephoneValue === "" || telephoneValue.trim() === "") {
        setInvalidTelephoneOffice(true);
        setTelephoneOfficeError(true);
      }
      else {
        setInvalidTelephoneOffice(false);
        setTelephoneOfficeError(false);
      }
    }
    validateTelephoneValue(telephoneId, telephoneValue)
  }

  const onChangePoBox = (e: any) => {
    const value = e.target.value
    setPoBox(value);
    if (e.target.required) {
      if (value === "" || value.trim() === "") {
        setPoBoxError(true);
        setInvalidPoBox(true)
      }
      else if (value.length > 7) {
        setPoBoxError(true);
        setInvalidPoBox(true);
      }
      else {
        setPoBoxError(false);
        setInvalidPoBox(false)
      }
    }
  }

  const onChangeDealerAddress = (e: any) => {
    setDealerAddress(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setDealerAddressError(true);
      else
        setDealerAddressError(false);
    }
  }

  const onChangeCity = (e: any) => {
    setCity(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setCityError(true);
      else {
        if (!validateFormFields(e.target.id, e.target.value)) {
          setCityError(true);
          setInvalidCityName(true);
        }
        else {
          setCityError(false);
          setInvalidCityName(false);
        }
      }
    }
  }

  const onChangeStreetName = (e: any) => {
    const value = e.target.value
    setStreetName(value);
    if (e.target.required) {
      if (value === "" || value.trim() === "") {
        setStreetNameError(true);
      }
      else {
        if (!validateFormFields(e.target.id, value)) {
          setStreetNameError(true);
          setInvalidStreetName(true)
        }
        else {
          setStreetNameError(false);
          setInvalidStreetName(false)
        }
      }
    }
  }

  const onChangeCountry = (e: any) => {
    setCountry(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setCountryError(true);
      else {
        if (!validateFormFields(e.target.id, e.target.value)) {
          setInvalidCountryName(true);
          setCountryError(true);
        }
        else {
          setCountryError(false);
          setInvalidCountryName(false);
        }
      }
    }
  }

  const onChangePostalCode = (e: any) => {
    setPostalCode(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setPostalCodeError(true);
      else
        setPostalCodeError(false);
    }
  }

  const onChangeEmiratesRegion = (e: any) => {
    setSelectEmirates(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setSelectEmiratesError(true);
    }
    else {
      setSelectEmiratesError(false);
    }
  }
  const onChangeTradeLicenseNumber = (e: any) => {
    setSelectTradeId(e.target.value);
    if (tradeRegionData && tradeRegionData.data && tradeRegionData.data.length > 0) {
      let selected = tradeRegionData.data.filter((data: any) => data.id === e.target.value);
      setSelectTrade(selected && selected.length > 0 && selected[0].tradeLicenseNo);
    }
    if (e.target.value === "" || e.target.value.trim() === "") {
      setSelectTradeError(true);
    }
    else {
      setSelectTradeError(false);
    }
  }
  const onChangeAuthorizedName = (e: any, index: any) => {
    let authorizedPersonsRow = [...authorizedPersons];
    authorizedPersonsRow[index].name = e.target.value;
    authorizedPersonsRow[index].isValidName = e.target.value ? true : false
    setAuthorizedPersons(authorizedPersonsRow)
    if (e.target.value === "" || e.target.value.trim() === "") {
      setIsValid(false);
      authorizedPersonsRow[index].isValidName = false;
      authorizedPersonsRow[index].isValid = false;
    } else {
      if (!validateFormFields('authorizeName', e.target.value)) {
        authorizedPersonsRow[index].isValidName = false;
        authorizedPersonsRow[index].isValid = false;
        authorizedPersonsRow[index].ValidationErrorMessage = ValidationErrorMessage.ALPHA_NUMERIC_TEXT;
        setIsValid(false);
      }
      else {
        authorizedPersonsRow[index].isValidName = true;
        authorizedPersonsRow[index].isValid = true;
        authorizedPersonsRow[index].ValidationErrorMessage = "";
        setIsValid(true);
      }
    }
  }

  const onChangeContactNumber = (e: any, index: any) => {
    let authorizedPersonsRow = [...authorizedPersons];
    authorizedPersonsRow[index].contactNumber = e.target.value;
    setAuthorizedPersons(authorizedPersonsRow)
    if (e.target.value.length > 10) {
      authorizedPersonsRow[index].isValidContactNumber = false;
      authorizedPersonsRow[index].ValidationErrorMessage = ValidationErrorMessage.CONTACT_NUMBER;
    }
    else {
      authorizedPersonsRow[index].isValidContactNumber = true
      authorizedPersonsRow[index].ValidationErrorMessage = "";
    }
  }

  const onChangeEmailId = (e: any, index: any) => {
    let authorizedPersonsRow = [...authorizedPersons];
    if (emailvalidation(e.target.value, index)) {
      authorizedPersonsRow[index].isValidEmail = true;
      authorizedPersonsRow[index].isValid = true;
      setIsValid(true);
    } else {
      authorizedPersonsRow[index].isValidEmail = false;
      authorizedPersonsRow[index].isValid = false;
      setIsValid(false);
    }
    authorizedPersonsRow[index].isValidEmail = emailvalidation(e.target.value, index)
    authorizedPersonsRow[index].emailId = e.target.value;
    setAuthorizedPersons(authorizedPersonsRow)
  }

  const createNewAddress = () => {
    setSubmit(false);
    if (!validateAuthorisedPersons()) {
      setInvalidAuthorized(true);
    }
    else {
      setInvalidAuthorized(false);
    }
    let authorizedPList: any = [];
    let authorizedPersonsRow = [...authorizedPersons];
    if (authorizedPersonsRow.length > 0) {
      authorizedPersonsRow.map((item: any, index: any) => {
        let itemRow = {
          name: item.name,
          documentId: item.documentId ? item.documentId : item.document && item.document.id ? item.document.id : '',
          expiryDate: item.expiryDate ? moment(item.expiryDate).utc().format() : item.document && item.document.expiryDate ? moment(item.document.expiryDate).utc().format() : '',
          contactNumber: item.contactNumber,
          emailId: item.emailId
        }
        authorizedPList.push(itemRow)
      })
    }
    let updatedData = {};
    let data = {
      "sapCustomerId": sapId,
      "location": locationName,
      "telephoneNumber": telephoneOffice,
      "poBox": poBox,
      "tradeLicenseNo": selectTrade,
      "tradeLicenseNoId": selectTradeId,
      "region": selectEmirates,
      "city": city,
      "postalCode": postalCode,
      "streetName": streetName,
      "country": country,
      "transportationZone": "",
      "personToAcceptDelivery": authorizedPList,
      "pictureOfDeliveryLocation": deliveryLocation,
      "photoOfPremises": permisesDocumentId,
      "authorityLetter": authorityDocumentId
    }
    if (isEdited) {
      updatedData = {
        "sapCustomerId": sapId,
        "location": locationName,
        "telephoneNumber": telephoneOffice,
        "poBox": poBox,
        "tradeLicenseNo": selectTrade,
        "tradeLicenseNoId": selectTradeId,
        "region": selectEmirates,
        "city": city,
        "postalCode": postalCode,
        "streetName": streetName,
        "country": country,
        "transportationZone": "",
        "userType": "kam",
        "personToAcceptDelivery": authorizedPList,
        "pictureOfDeliveryLocation": deliveryLocation,
        "photoOfPremises": permisesDocumentId,
        "authorityLetter": authorityDocumentId
      }
    }
    if (ValidateOnSaveAddress() && isValid) {
      if (!deliveryLocationRegFile) {
        setSubmit(true);
      }
      if (!permises) {
        setSubmit(true);
      }
      if (!authority) {
        setSubmit(true);
      }
      else {
        getNewAddressData(isEdited ? updatedData : data);
        setOpenDialog();
      }
    }
    else {
      showErrorMessage('Please fill all mandatory fields and upload all mandatory documents');
      handleopenCloseErrorSnackbar(true);
      setSubmit(true);
    }
  }

  const onClickBrowseFile = async (type: any, item: any, setCallback: any, index?: any) => {
    showErrorMessage(null);
    handleopenCloseErrorSnackbar(false);
    setInvalidDoucmentupload(false);
    setInvalidPhotopremises(false);

    const name = item && item.name ? item.name : '',
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
      doctExtensions = ['jpg', 'jpeg', 'png'],
      authorityDocExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1,
      canUploadAuthorityFile = authorityDocExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
    if (type !== 'authorityLetter' && !canUploadFile) {
      showErrorMessage('Invalid File type format, please upload jpg or png.');
      handleopenCloseErrorSnackbar(true);
      if (type == 'pictureOfDeliveryLocation') {
        setInvalidDoucmentupload(true);
      }
      if (type == 'photoOfPremises') {
        setInvalidPhotopremises(true);
      }
      return;
    }
    if (type === 'authorityLetter' && !canUploadAuthorityFile) {
      showErrorMessage('Invalid File type format.');
      handleopenCloseErrorSnackbar(true);
      return;
    }

    if (item && item.size < 2000000) {
      let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;
      if (setCallback) {
        if (type == "document") {
          let authorizedPersonsRow = [...authorizedPersons];
          authorizedPersonsRow[index].document = item;
          let expiryDate = moment(authorizedPersonsRow[index].expiryDate).utc().format()
          keyAttribute = `${keyAttribute}&expiryDate=${expiryDate}`;
          setCurrentEmiratesIndex(index);
          setCallback(authorizedPersonsRow);
        }
        else {
          setCallback(item);
        }
      }
      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };

      let docType = '';
      if (type === 'document') { docType = 'Emirates Id File' }
      else if (type === 'pictureOfDeliveryLocation') { docType = 'Picture of Delivery Location File' }
      else if (type === 'photoOfPremises') { docType = 'Photo of Permises File' }
      else if (type === 'authorityLetter') { docType = 'Authority Letter' }
      const formData: any = new FormData();
      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", sapId);
      formData.append("docType", docType);
      formData.append("label", type);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'dealerUser');
      formData.append("uploadedBywebApplicationLoginId", '');
      formData.append("selectedFileName", item.name);

      let documentRes: any = await dispatch(updateDocument(formData, customHeaders));
      if (documentRes && documentRes.message && documentRes.message.status === 'S') {
        const { label, id } = documentRes.data;
        if (label === "pictureOfDeliveryLocation") {
          setDeliveryLocation({ documentId: id });
          setDeliveryLocationRegFile(documentRes.data)
        }
        else if (label === "photoOfPremises") {
          setPermisesDocumentId({ documentId: id });
          setPermises(documentRes.data);
        } else if (label === "authorityLetter") {
          setAuthorityDocumentId({ documentId: id });
          setAuthority(documentRes.data);
        }
        else if (label === "document") {
          let authorizedPersonsRow = [...authorizedPersons];
          authorizedPersonsRow[index].documentId = id;
          authorizedPersonsRow[index].document = documentRes.data;
          setAuthorizedPersons(authorizedPersonsRow)
        }
      } else {
        showErrorMessage('Document upload failed');
        handleopenCloseErrorSnackbar(true);
        if (type === "pictureOfDeliveryLocation") {
          setDeliveryLocation({ documentId: '' });
          setDeliveryLocationRegFile(null)
        }
        else if (type === "photoOfPremises") {
          setPermisesDocumentId({ documentId: '' });
          setPermises(null);
        }
        else if (type === "authorityLetter") {
          setAuthorityDocumentId({ documentId: '' });
          setAuthority(null);
        }
        else if (type === "document") {
          let authorizedPersonsRow = [...authorizedPersons];
          authorizedPersonsRow[index].documentId = null;
          authorizedPersonsRow[index].document = null;
          setAuthorizedPersons(authorizedPersonsRow)
        }
      }
    } else {
      showErrorMessage('Please upload less than 2MB file');
      handleopenCloseErrorSnackbar(true);
    }
  }

  // const deleteAddressDoc = async (type: any, data: any, index?: any) => {
  //   dispatch(resetValues());
  //   const obj = {
  //     "type": type
  //   }
  //   let response: any = await dispatch(deleteAddressDocument(editedAddress && editedAddress.id, data.id, obj));
  //   if (response && response.message && response.message.status === 'S') {
  //     if (type === 'pictureOfDeliveryLocation') {
  //       setDeliveryLocation({ documentId: '' });
  //       setDeliveryLocationRegFile(null);
  //     }
  //     if (type === 'photoOfPremises') {
  //       setPermises(null);
  //       setPermisesDocumentId({ documentId: '' });
  //     }
  //     if (type === 'personToAcceptDelivery') {
  //       let persons = [...authorizedPersons]
  //       persons[index].document = null;
  //     }
  //   }
  // }

  const deleteAddressDoc = async (index?: any) => {
    dispatch(resetValues());
    let data: any;
    if (documentType == 'photoOfPremises') {
      data = permises;
    } else if (documentType == 'personToAcceptDelivery') {
      let apRow = [...authorizedPersons]
      data = apRow[currentEmiratesIndex].document;
    } else if (documentType == 'pictureOfDeliveryLocation') {
      data = deliveryLocationRegFile;
    } else if (documentType == 'authorityLetter') {
      data = authority;
    }
    const obj = {
      "type": documentType
    }
    let response: any = await dispatch(deleteAddressDocument(editedAddress && editedAddress.id, data.id, obj, b2bUserId));
    if (response && response.message && response.message.status === 'S') {
      if (documentType === 'pictureOfDeliveryLocation') {
        setDeliveryLocation({ documentId: '' });
        setDeliveryLocationRegFile(null);
      }
      if (documentType === 'photoOfPremises') {
        setPermises(null);
        setPermisesDocumentId({ documentId: '' });
      }
      if (documentType === 'authorityLetter') {
        setAuthority(null);
        setAuthorityDocumentId({ documentId: '' });
      }
      if (documentType === 'personToAcceptDelivery') {
        let persons = [...authorizedPersons]
        persons[currentEmiratesIndex].document = null;
        persons[currentEmiratesIndex].isDocumentUploaded = false;
        persons[currentEmiratesIndex].expiryDate = null;
      }
      handleopenCloseDocument(false);
    } else {
      showErrorMessage(response.error);
      handleopenCloseErrorSnackbar(true);
    }
  }

  const handleInputChange = (e: any, type: any, index: number, errorSetterName?: any, errorErrorMessage?: any) => {
    let apRow = [...authorizedPersons]
    switch (type) {
      case "name":
        apRow[index].name = e.target.value;
        apRow[index].isValidName = e.target.value ? true : false
        break;
      case "document":
        handleopenCloseDocument(true);
        setCurrentEmiratesIndex(index);
        setDocumentType('personToAcceptDelivery');
        break;
      case "expiryDate":
        apRow[index].expiryDate = e.target.value;
        break;
      default: ;
    }
    setAuthorizedPersons(apRow);
  }
  const AddressList = (part: any, index: any) => (
    <>
      <TableRow>
        <TableCell className={`p-0 ${classes.bodyContent}`}>
          <IconButton aria-label="delete" size="small"
            className={`${classes.buttonIconDel} p-1 align-center`}>
            <AddIcon fontSize="inherit" />
          </IconButton>

          <IconButton aria-label="delete" size="small"
            onClick={(e) => { removeAddress(e, index); }} className={`${classes.buttonIconDel} p-1 align-center`}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
          {(
            <>
              <br />
              <span className="errorMsg">Add Address Details</span>
            </>
          )}
        </TableCell>
      </TableRow>
      )
    </>
  );

  const classes = useStyles();
  function getDocumentName(item: any) {
    if (item.document && item.document.selectedFileName) {
      return item.document.selectedFileName
    }
    else if (item.document && item.document.name) {
      return item.document.name
    }
  }

  function isDisabled(index: any) {
    if (!authorizedPersons[index].expiryDate) {
      return false;
    }
    else {
      return true;
    }
    // }
  }

  return (
    <Fragment>
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      {isEdit && (
        <div className={`${classes.root} onboarding-field`}>
          <>
            <Toolbar
              className={clsx(classes.root)}>
              <div className="col-12 mt-4">
                <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                  <h4 className="shift-to-party-page-title">
                    {isEdited && props.status === 'Incomplete from KAM' ? 'Edit Address' : isEdited ? 'View Address' : 'Add New Address'}
                    <IconButton aria-label="close" className="float-right"
                      onClick={() => setOpenDialog()}>
                      <CloseIcon />
                    </IconButton>
                  </h4>
                </Typography>
              </div>
            </Toolbar>
            <Toolbar className="mb-2">
              <TextField
                id="location"
                size="small"
                disabled={isFieldsDisabled()}
                className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
                label="Name of Location"
                variant="outlined"
                required
                inputProps={{ maxLength: 250 }}
                value={locationName}
                error={locationNameError}
                onChange={(e: any) => onChangeLocationName(e)}
                helperText={invalidLocationName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_TEXT}</span>}
              />
              <TextField
                required
                type="number"
                id="telephoneOffice"
                onChange={(e: any) => onChangeTelephoneOffice(e)}
                onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                error={telephoneOfficeError}
                disabled
                value={telephoneOffice}
                inputProps={{ maxLength: 20 }}
                size="small"
                helperText={telephoneOffice && invalidTelephoneOffice && <span style={{ color: 'red' }}>{telephoneOfficeErrorMessage}</span>}
                className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
                label="Telephone Office"
                variant="outlined"
              />
            </Toolbar>
            <Toolbar className="mb-2">
              <TextField
                required
                type="number"
                id="poBox"
                onChange={(e: any) => onChangePoBox(e)}
                disabled
                error={poBoxError}
                value={poBox}
                onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                inputProps={{ maxLength: 7 }}
                size="small"
                helperText={invalidPoBox && poBox.length > 7 && <span style={{ color: 'red' }}>{ValidationErrorMessage.POBOX_TEXT}</span>}
                className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
                label="PO Box"
                variant="outlined"
              />
              <TextField
                required
                id="emiratesRegion"
                error={selectEmiratesError}
                size="small"
                disabled
                value={selectEmirates}
                // helperText={invalidSelectEmirates && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_LIMIT_TEXT}</span>}
                select
                className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
                label="Select Emirates/Region"
                onChange={(e: any) => onChangeEmiratesRegion(e)}
                variant="outlined" >
                {regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((item: any) => (
                  <MenuItem key={item.Code} value={item.Code}>{item.Region} </MenuItem>))}
              </TextField>
            </Toolbar>
            <Toolbar className="mb-2">
              <TextField
                id="streename"
                size="small"
                disabled
                className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
                label="Street Name"
                variant="outlined"
                inputProps={{ maxLength: 250 }}
                required
                helperText={invalidStreetName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_TEXT}</span>}
                value={streetName}
                error={streetNameError}
                onChange={(e: any) => onChangeStreetName(e)}
              />
              <TextField
                required
                id="city"
                onChange={(e: any) => onChangeCity(e)}
                error={cityError}
                disabled
                value={city}
                inputProps={{ maxLength: 250 }}
                size="small"
                className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
                label="Area"
                helperText={invalidCityName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_TEXT}</span>}
                variant="outlined"
              />
            </Toolbar>
            <Toolbar className="mb-2">
              <TextField
                id="country"
                size="small"
                className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
                label="Country"
                disabled
                inputProps={{ maxLength: 250 }}
                helperText={invalidCountryName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_TEXT}</span>}
                variant="outlined"
                required
                value={country}
                error={countryError}
                onChange={(e: any) => onChangeCountry(e)}
              />
              <TextField
                required
                id="postalCode"
                onChange={(e: any) => onChangePostalCode(e)}
                error={postalCodeError}
                value={postalCode}
                disabled
                size="small"
                onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                inputProps={{ maxLength: 20 }}
                type="number"
                className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
                label="Postal Code"
                variant="outlined"
              />
            </Toolbar>
            <Toolbar className="mb-2">
              <TextField
                required
                id="tradeLicenseNumber"
                error={selectTradeError}
                disabled={isFieldsDisabled()}
                size="small"
                value={selectTradeId}
                // helperText={invalidSelectEmirates && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_LIMIT_TEXT}</span>}
                select
                className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
                label="Select Trade license number"
                onChange={(e: any) => onChangeTradeLicenseNumber(e)}
                variant="outlined" >
                {tradeRegionData && tradeRegionData.data && tradeRegionData.data.length > 0 && tradeRegionData.data.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>{item.tradeLicenseNo} </MenuItem>))}
              </TextField>
            </Toolbar>
            <div className="mb-2 border-top">
              <div className="row col-lg-12  mt-3">
                <h6 className="page-sub-title ml-4">Authorized Person to Accept the Deliveries</h6>
              </div>
              <div className="row col-lg-12">
                <h6 className="info-sub-header-document pb-3 ml-4">Supported File Extension : .jpg, .jpeg, .png, upto 2 MB can be uploaded.</h6>
              </div>
              {
                authorizedPersons && authorizedPersons.length > 0 && authorizedPersons.map((item: any, index: any) => {
                  return (
                    <div className="row col-md-12 m-0" key={item.documentId}>
                      <div className="col-md-6 col-lg-6">
                        <TextField
                          required
                          // inputMode='numeric'
                          value={item.name}
                          error={!item.isValidName}
                          disabled={isFieldsDisabled()}
                          onChange={(e: any) => { onChangeAuthorizedName(e, index) }}
                          helperText={!item.isValidName && item.ValidationErrorMessage && <span style={{ color: 'red' }}>{item.ValidationErrorMessage}</span>}
                          size="small" className="col-sm-12 my-2 pr-3 commonRoundedInputs"
                          label="Name" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <TextField
                          required
                          type="number"
                          value={item.contactNumber}
                          error={!item.isValidContactNumber}
                          disabled={isFieldsDisabled()}
                          onChange={(e: any) => { onChangeContactNumber(e, index) }}
                          helperText={!item.isValidContactNumber && item.ValidationErrorMessage && <span style={{ color: 'red' }}>{item.ValidationErrorMessage}</span>}
                          size="small" className="col-sm-12 my-2 pr-3 commonRoundedInputs"
                          label="Contact Number" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <TextField
                          value={item.emailId}
                          disabled={isFieldsDisabled()}
                          error={!item.isValidEmail && item.emailId}
                          onChange={(e: any) => { onChangeEmailId(e, index) }}
                          helperText={!item.isValidEmail && item.emailId && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
                          size="small" className="col-sm-12 my-2 pr-3 commonRoundedInputs"
                          label="Email ID" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-3 col-lg-3 pr-3 pt-1">
                        {item.document ?
                          <div className="file-name">
                            <span className="cursor-pointer" onClick={() => {
                              window.open(item.document.url)
                            }}>{getDocumentName(item)}</span>
                            <IconButton className={isFieldsDisabled() ? 'disabledCustomclass p-1 mt-2 disabled' : 'p-1 mt-2 disabled'} aria-label="delete" disabled={isFieldsDisabled()}
                              onClick={(e: any) => handleInputChange({ target: null }, "document", index)}>
                              <CancelIcon className="file-close-color" fontSize="small" />
                            </IconButton>
                          </div>
                          : <div> <Button
                            component="label"
                            disabled={isFieldsDisabled()}
                            // disabled={!isDisabled(index)}
                            color="secondary"
                            className={`col-md-12 rounded-button-dealer my-2 mt-2  px-4 ${!isDisabled(index) ? classes.oppacityLess : ""}`}
                          // className="col-md-12 rounded-button-dealer my-2 mt-2  px-4"
                          > Upload Emirates ID
                            <input type="file" hidden
                              onChange={(e) =>
                                (e.target && e.target.files) ?
                                  onClickBrowseFile('document', e.target.files[0], setAuthorizedPersons, index) : setAuthorizedPersons(item)
                              }
                            />
                          </Button>
                            {!item.isDocumentUploaded && isSubmit ?
                              <div style={{ color: 'red' }}>Please upload the Emirates Id (jpg or png)</div> : ""}
                          </div>
                        }
                      </div>
                      <div className="col-md-3 col-lg-3">
                        <KeyboardDatePicker
                          error={!item.isValidExpiryDate && isSubmit}
                          helperText={!item.isValidExpiryDate && isSubmit && <span style={{ color: 'red' }}>Please mention the expiry date</span>}
                          className="col-sm-11 my-2 pr-3 commonRoundedInputs cal-icon"
                          autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                          format="dd/MM/yyyy" margin="normal" label="Expiry Date"
                          views={["year", "month", "date"]}
                          disablePast
                          disabled={isFieldsDisabled()}
                          openTo="year"
                          InputProps={{ readOnly: true }}
                          value={authorizedPersons[index].expiryDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          onChange={(date: Date | null) => {
                            let currentDate = moment(date).format("YYYY-MM-DD");
                            setVisaExpiryDate(currentDate);
                            let authorizedPersonsRow = [...authorizedPersons];
                            authorizedPersonsRow[index].expiryDate = currentDate;
                            authorizedPersons[index].isValidExpiryDate = true;
                            setAuthorizedPersons(authorizedPersonsRow)
                          }}
                        />
                        {/* {!item.isValidExpiryDate ?
                          <span style={{ color: 'red' }}>Please mention the expiry date"</span>: ""} */}
                        {authorizedPersons && authorizedPersons.length === 1 ? '' : (
                          <IconButton disabled={isFieldsDisabled()} className={isFieldsDisabled() ? 'col-sm-1 mt-3 disabledCustomclass' : 'col-sm-1 mt-3'} size="small"
                            onClick={() => {
                              // deleteAuthorizedPersonsRows(item, index);
                              setCurrentEmiratesIndex(index);
                              handleopenClose(true);
                            }}>
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  )
                })
              }
              <div className="row col-md-12 m-0">
                <div className={isFieldsDisabled() ? 'row-space-start disabledCustomclass' : 'row-space-start'}>
                  <Button
                    disabled={isFieldsDisabled()}
                    className="text-info"
                    startIcon={<AddIcon className="font-size-18" />}
                    onClick={() => addAuthorizedPersonsRows()}
                  ><span className="onshiftoparty-font">Add More
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="row col-lg-12 col-md-12 m-0 p-0 border-top border-bottom">
              <div className="col-md-6 col-lg-6 mb-2 mt-3 pl-4 ml-1">
                <h6 className="document-content">Picture Of Delivery Location <span style={{ color: 'grey' }}>*</span></h6>
                <small className="document-sub-content pb-3">Photos(.jpg,.png)</small>
              </div>
              <div className="col-md-3 col-lg-3 mb-2 mt-3 mr-3">
                {deliveryLocationRegFile !== null ?
                  <span className="file-name">
                    <span className="cursor-pointer" onClick={() => {
                      window.open(deliveryLocationRegFile.url)
                    }}>
                      {deliveryLocationRegFile.name ? deliveryLocationRegFile.name : deliveryLocationRegFile.selectedFileName}
                    </span>
                    <IconButton disabled={isFieldsDisabled()} className={isFieldsDisabled() ? 'p-1 disabledCustomclass' : 'p-1'} aria-label="delete" onClick={(e) => {
                      // deleteAddressDoc('pictureOfDeliveryLocation', deliveryLocationRegFile);
                      // setDeliveryLocationRegFile(null);
                      setDocumentType('pictureOfDeliveryLocation');
                      handleopenCloseDocument(true);
                    }}>
                      <CancelIcon className="file-close-color" fontSize="small" />
                    </IconButton>
                  </span>
                  : <div> <Button
                    variant="contained"
                    disabled={isFieldsDisabled()}
                    component="label"
                    color="secondary"
                    className="col-md-11 rounded-button-dealer my-2 mt-2  px-4"
                  >Browse File
                    <input type="file" hidden
                      onChange={(e) =>
                        (e.target && e.target.files) ?
                          onClickBrowseFile('pictureOfDeliveryLocation', e.target.files[0], setDeliveryLocationRegFile) : setDeliveryLocationRegFile(null)
                      }
                    />
                  </Button>
                    {(!deliveryLocationRegFile && isSubmit) ?
                      <span style={{ color: 'red' }}>Required </span> : ""}
                    {invalidDoucmentupload ?
                      <span style={{ color: 'red' }}>Only .png, .jpg files formats are allowed</span> : ""}
                  </div>
                }
              </div>
            </div>
            <div className="row col-lg-12 col-md-12 m-0 p-0 border-top border-bottom">
              <div className="col-md-6 col-lg-6 mb-2 mt-3 pl-4 ml-1">
                <h6 className="document-content">Photo Of Premises  <span style={{ color: 'grey' }}>*</span></h6>
                <small className="document-sub-content pb-3">Photos(.jpg,.png)</small>
              </div>
              <div className="col-md-3 col-lg-3 mb-2 mt-3 mr-3">
                {permises !== null ?
                  <div className="file-name">
                    <span className="cursor-pointer" onClick={() => {
                      window.open(permises.url)
                    }}>{permises.name ? permises.name : permises.selectedFileName}</span>
                    <IconButton disabled={isFieldsDisabled()} className={isFieldsDisabled() ? 'p-1 disabledCustomclass' : 'p-1'} aria-label="delete" onClick={(e) => {
                      // deleteAddressDoc('photoOfPremises', permises);
                      // setPermises(null);
                      setDocumentType('photoOfPremises');
                      handleopenCloseDocument(true);
                    }}>
                      <CancelIcon className="file-close-color" fontSize="small" />
                    </IconButton>
                  </div>
                  :
                  <div>
                    <Button
                      variant="contained"
                      component="label"
                      disabled={isFieldsDisabled()}
                      color="secondary"
                      className="col-md-11 rounded-button-dealer my-2 mt-2  px-4"
                    > Browse File
                      <input type="file" hidden
                        onChange={(e) =>
                          (e.target && e.target.files) ?
                            onClickBrowseFile('photoOfPremises', e.target.files[0], setPermises) : setPermises(null)
                        }
                      />
                    </Button>
                    {(!permises && isSubmit) ?
                      <span style={{ color: 'red' }}>Required{' '}</span> : ""}
                    {invalidPhotopremises ?
                      <span style={{ color: 'red' }}>Only .png, .jpg files formats are allowed</span> : ""}
                  </div>
                }
              </div>
            </div>
            <div className="row col-lg-12 col-md-12 m-0 p-0 border-top border-bottom">
              <div className="col-md-6 col-lg-6 mb-2 mt-3 pl-4 ml-1">
                <h6 className="document-content">Upload Authority letter<span style={{ color: 'grey' }}>*</span></h6>
                <small className="document-sub-content pb-3">Upload (.pdf, .doc, .docx, .png, .jpeg, .jpg)</small>
                <br></br>
                <a href="./DeliveryAuthorityLetter.docx" download="authorityletter.docx">
                  <IconButton disabled={isFieldsDisabled()} size="small" className={isFieldsDisabled() ? 'disabledCustomclass' : ''}>
                    <AssignmentReturnedIcon className="download-icon mr-1" />
                    <span className="download-offer mr-2">
                      Download Template
                    </span>
                  </IconButton>
                </a>
              </div>
              <div className="col-md-3 col-lg-3 mb-2 mt-3 mr-3">
                {authority !== null ?
                  <div className="file-name">
                    <span className="cursor-pointer" onClick={() => {
                      window.open(authority.url)
                    }}>{authority.name ? authority.name : authority.selectedFileName}</span>
                    <IconButton disabled={isFieldsDisabled()} className={isFieldsDisabled() ? 'p-1 disabledCustomclass' : 'p-1'} aria-label="delete" onClick={(e) => {
                      // deleteAddressDoc('authorityLetter', authority);
                      // setAuthority(null);
                      setDocumentType('authorityLetter');
                      handleopenCloseDocument(true);
                    }}>
                      <CancelIcon className="file-close-color" fontSize="small" />
                    </IconButton>
                  </div>
                  :
                  <div>
                    <Button
                      variant="contained"
                      disabled={isFieldsDisabled()}
                      component="label"
                      color="secondary"
                      className="col-md-11 rounded-button-dealer my-2 mt-2  px-4"
                    > Browse File
                      <input type="file" hidden
                        onChange={(e) =>
                          (e.target && e.target.files) ?
                            onClickBrowseFile('authorityLetter', e.target.files[0], setAuthority) : setAuthority(null)
                        }
                      />
                    </Button>
                    {(!authority && isSubmit) ?
                      <span style={{ color: 'red' }}>Required{' '}</span> : ""}
                  </div>
                }
              </div>
            </div>
          </>
        </div>
      )}
      <DialogActions className="mt-3 mb-2 justify-content-end">
        {props.status === 'Incomplete from KAM' && (
          <Button variant="contained"
            // disabled={!isValid}
            color="secondary" className={`createFavourite ${classes.createButton}`}
            onClick={() => createNewAddress()}
          >{isEdited && props.status === 'Incomplete from KAM' ? 'Update' : 'Save'}
          </Button>
        )}
        <Button variant="contained" className={classes.closeButton}
          onClick={() => setOpenDialog()}
        >Cancel</Button>
      </DialogActions>
      <DeleteDocumentDialog
        open={openDeleteDialogDocument}
        text="Are you sure you want to delete this document ?"
        handleopenCloseDocument={(data: any) => {
          if (data === 'delete')
            deleteAddressDoc();
          if (!data) handleopenCloseDocument(false);
        }} />
      <DeleteDialog
        open={openDeleteDialog}
        text="Are you sure you want to delete details ?"
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteAuthorizedPersonsRows();
          if (!data) handleopenClose(false);
        }} />
    </Fragment>
  );
})