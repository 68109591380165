
export const RESTRICTED_ACCESS = {
    CREATE_CHANGE_ENQUIRY: 'CRE_ENQ',
    ENQ_LIST:"ENQ_LIST",
    LIST_ORDERS: 'ORD_LIST',
    SEARCH_EPC: "SEARCH_EPC",
    FIND_SUPERSESSION:"FIND_SUPERSESSION",
    UPDATE_ENQUORY:"UPDT_ENQ",
    CREATE_DELIVERY:"CREATE_DLV",
    UPLOAD_ENQUIRY_EXCEL: 'UPDT_ENQ',
    CANCEL_ENQUIRY: 'CNCL_ENQ',
    VIEW_ORDERS:"VIEW_ORD",
    VIEW_DELIVERY: 'VIEW_DLV',
    VIEW_USERS:'VIEW_USERS',
    VIEW_ENQUIRY_DETAILS: 'VIEW_ENQ',
    VIEW_CREDIT_STATUS: 'VIEW_CR_STATUS',
    DOwNLOAD_INVOICE_EXCEL: 'DWNLD_INV',
    MANAGER_USER: 'MANAGE_USER',
    MANAGEGLOBAL_FAV:"MANAGE_GBL_FAVORIT",
    LIST_ENQUIRIES: 'LIST_ENQ',
    LIST_FAVORITES: 'LIST_FAVORIT',
    DOWNLOAD_ENQUIRY_EXCEL: 'MANAGE_EXPORT_TO_EXL',
    PLACE_ORDER: 'PLACE_ORD',
    STOCK_ENQUIRY: 'STOCK_ENQ',
    DOWNLOAD_ORDER: 'MANAGE_EXPORT_TO_EXL',
    LIST_BILLING_DOCS: 'LIST_BILL_DOC',
    CURRENT_PREVIOUS_MONTH_SALES: 'CUR_PRV_MONTH_SALE',
}

export const PREVENT_ACCESS = {
    ACCOUNT_EXPIRED: 'Login Id is not within validity period'
}