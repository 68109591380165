import { HOME } from "../actions/actionsTypes";
import { IActionBase } from "../models/root.interface";

const homeInitialState = {
    features: null,
    brands: null,
    error: null 
}

function homeReducer(state: any = homeInitialState, action: IActionBase): any {
    switch (action.type) {
        case HOME.GET_FEATURES: {
            return { ...state, features: action.payload.data};
        }
        case HOME.GET_BRANDS: {
            return { ...state, brands: action.payload.data};
        }
        case HOME.GET_HOME_ERROR: {
            return { ...state, error: action.payload.error};
        }
        default:
            return state;
    }
}


export default homeReducer;