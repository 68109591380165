import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';


function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        borderRadius: 1000,
        fontFamily: 'Nunito',
        fontSize: 18,
        fontWeight: 700,

    },
}));

export default function ReturnRequestSnackbar(props: any) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Snackbar open={props.open ? true : false} autoHideDuration={6000} onClose={() => { props.handleopenClose(false) }}>
                <Alert onClose={() => { props.handleopenClose(false) }} >
                    Return request submitted successfully
                </Alert>
            </Snackbar>
        </div>
    );
}
