import React, { Fragment, Dispatch, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import ApproveDialog from './ApproveDialog';
import RejectDialog from './RejectDialog';
import { onBoardingService } from '../../services/onBoarding.service';
import { onboardingService } from '../../services/onboard.service';
import RequestCorrectionDialog from './RequestCorrectionDialog';

export default function ActionButtons(props: any) {
    const [showApproveDialog, setshowApproveDialog] = React.useState<boolean>(false);
    const [showRejectDialog, setshowRejectDialog] = React.useState<boolean>(false);
    const [showRequestCorrectionDialog, setshowRequestCorrectionDialog] = React.useState<boolean>(false);
    const [customerTypes, setcustomerTypes] = React.useState<any>([]);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [approvalData, setapprovalData] = React.useState<any>(null);
    const leftMenuVisibility: any = useSelector(
        (state: any) => state.global && state.global.sideMenuOpened
    );
    const { isSaveDisabled = false, saveCallback, iscorrectionDisabled = true, setSuccessMessage, handleopenCloseSnackbar,
        correctionCallback, isapproveDisabled = true, approveCallback, isrejectDisabled = true, rejectCallback, setOpenErrorSnackbar, openErrorSnackbar, setDataUpdated } = props;

    useEffect(() => {
        loadCustomerTypes()
    }, [])

    const loadCustomerTypes = async () => {
        const data = await onboardingService.getCustomerTypes()
        if (data && data.data && data.data[0])
            setcustomerTypes(data.data[0].customerType)
    }

    // const handleopenCloseSnackbar = (openRequestSnackbar?: any) => {
    //     setOpenSnackbar(openRequestSnackbar ? true : false);
    // };

    return (
        <Fragment>
            <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
                <div className="col-12 d-flex mt-2 p-0">
                    <div className="col-6">
                        <div className="">
                            <Button variant="contained" onClick={() => { if (saveCallback) saveCallback() }} color="primary" className={`blueActionButton border-left rounded-pill ml-4 mb-2 ${isSaveDisabled ? 'disabledButtonCommon' : ''}`} >Save</Button>
                            <Button variant="contained" onClick={() => { setshowRequestCorrectionDialog(true); setOpenSnackbar(false) }} className={`yellowActionButton border-left rounded-pill ml-4 mb-2 ${iscorrectionDisabled ? 'disabledButtonCommon' : ''}`}  >Request Correction</Button>
                        </div>
                    </div>
                    <div className="col-6 onboard-footer">
                        <div className="">
                            <Button variant="contained" onClick={() => { setshowRejectDialog(true); setOpenSnackbar(false) }} className={`redActionButton border-left rounded-pill ml-4 mb-2 ${isrejectDisabled ? 'disabledButtonCommon' : ''}`} >Reject</Button>
                            <Button variant="contained"
                                onClick={async () => {
                                    if (!isSaveDisabled) {
                                        if (saveCallback) saveCallback()
                                    }
                                    if (approveCallback) {
                                        let data = await props.approveCallback();
                                        if (data) {
                                            let returnData: any = await props.validateAction()
                                            if (!returnData) {
                                                return
                                            } else {
                                                setDataUpdated(false);
                                                setapprovalData(returnData);
                                                setshowApproveDialog(true);
                                                setOpenSnackbar(false)
                                            }
                                        }
                                    }
                                }}
                                color="primary" className={`blueActionButton border-left rounded-pill ml-4 mb-2 ${isapproveDisabled ? 'disabledButtonCommon' : ''}`} >Approve</Button>
                        </div>
                    </div>
                </div>
            </div>
            <ApproveDialog
                customerTypes={customerTypes}
                open={showApproveDialog}
                openRequestSnackbar={openSnackbar}
                handleopenCloseSnackbar={handleopenCloseSnackbar}
                setSuccessMessage={setSuccessMessage}
                setOpenErrorSnackbar={setOpenErrorSnackbar}
                openErrorSnackbar={openErrorSnackbar}
                approvalData={approvalData}
                handleopenClose={(data: any) => {
                    setshowApproveDialog(data ? true : false)
                }}
            />
            <RejectDialog
                open={showRejectDialog}
                openRequestSnackbar={openSnackbar}
                handleopenCloseSnackbar={handleopenCloseSnackbar}
                setOpenErrorSnackbar={setOpenErrorSnackbar}
                openErrorSnackbar={openErrorSnackbar}
                setSuccessMessage={setSuccessMessage}
                handleopenClose={(data: any) => {
                    setshowRejectDialog(data ? true : false)
                }}
            />
            <RequestCorrectionDialog
                open={showRequestCorrectionDialog}
                openRequestSnackbar={openSnackbar}
                handleopenCloseSnackbar={handleopenCloseSnackbar}
                setOpenErrorSnackbar={setOpenErrorSnackbar}
                openErrorSnackbar={openErrorSnackbar}
                setSuccessMessage={setSuccessMessage}
                handleopenClose={(data: any, open: any) => {
                    setshowRequestCorrectionDialog(data ? true : false)
                }}
            />
        </Fragment>
    );
}
