import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import { useEffect } from 'react';
import { getDealerDetails } from '../../store/actions/dealer.action';
import { dealerService } from '../../services/dealer.service';
import { getProfile, isSellingPrice } from '../../store/actions/profile.action';

const useStyles = makeStyles(() => ({
    title: {
        flex: '1 1 100%',
    },
}));

const theme = createTheme({
    palette: {
        secondary: {
            main: '#1A9FE0'
        },
    }
});

export default function SellingPrice(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const [checkselling, setCheckselling] = React.useState<any>();
    const [markupPercentage, setMarkupPercentage] = React.useState<any>("");
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [markupPercentageError, setMarkupPercentageError] = React.useState<boolean>(false);
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);

    useEffect(() => {
        if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
            loadSellingPrice();
        }
    }, [profileDetails]);

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    const loadSellingPrice = async () => {
        let data: any = await dispatch(getDealerDetails(profileDetails.data.data.basicInfo.sapCustomerId, 'percentageForSellingPrice'));
        if (data && data.data) {
            setMarkupPercentage(data.data.markupPercentage);
            setCheckselling(data.data.percentageForSellingPriceApply);
        }
    }

    // ONCHANGE OF TEXTFILED 
    const handleInputChange = (e: any) => {
        props.setDataUpdated(true);
        if (e.target.value.length < 6) {
            setMarkupPercentage(e.target.value);
            if (e.target.required) {
                if (e.target.value === "" || e.target.value.trim() === "") {
                    setMarkupPercentageError(true);
                }
                else {
                    setMarkupPercentageError(false);
                }
            }
        }
    }

    const savePrice = async (isChecked?: any) => {
        if (isChecked === true || isChecked === false) {
            let data = {
                "percentageForSellingPriceApply": isChecked,
                "markupPercentage": isChecked ? markupPercentage : 0,
                "b2bUserId": profileDetails.data.data.basicInfo.b2bUserId
            }

            let updatedData = await dealerService.updateSellingPrice(data, profileDetails.data.data.basicInfo.sapCustomerId);
            if (updatedData && updatedData.message && updatedData.message.status === 'S') {
                const b2cUid = sessionStorage.getItem('userId');
                const userType = sessionStorage.getItem('userType');
                if (b2cUid !== "undefined" && b2cUid !== null && userType !== 'undefined' && userType !== null) {
                    dispatch(getProfile(b2cUid, userType));
                }
                if (isChecked === false) {
                    dispatch(isSellingPrice(true));
                    setSuccessMessage('Markup percentage removed');
                    handleopenCloseSnackbar(true);
                }

            } else {
                setOpenErrorSnackbar(true);
                handleopenCloseSnackbar(true);
                setSuccessMessage(updatedData.error);
            }
        } else {
            let data = {
                "percentageForSellingPriceApply": checkselling,
                "markupPercentage": checkselling ? markupPercentage : 0,
                "b2bUserId": profileDetails.data.data.basicInfo.b2bUserId
            }

            let updatedData = await dealerService.updateSellingPrice(data, profileDetails.data.data.basicInfo.sapCustomerId);
            if (updatedData && updatedData.message && updatedData.message.status === 'S') {
                const b2cUid = sessionStorage.getItem('userId');
                const userType = sessionStorage.getItem('userType');
                if (b2cUid !== "undefined" && b2cUid !== null && userType !== 'undefined' && userType !== null) {
                    dispatch(getProfile(b2cUid, userType));
                }
                setSuccessMessage(updatedData.message.message);
                props.setDataUpdated(false);
                handleopenCloseSnackbar(true);
            } else {
                setOpenErrorSnackbar(true);
                handleopenCloseSnackbar(true);
                setSuccessMessage(updatedData.error);
            }
        }
    }

    async function validation() {
        if (markupPercentage || parseInt(markupPercentage) === 0) {
            if (parseFloat(markupPercentage) > 100) {
                setOpenErrorSnackbar(true);
                handleopenCloseSnackbar(true);
                setSuccessMessage("Markup Percentage should not exceed 100");
            }else if (parseFloat(markupPercentage) === 0) {
                setOpenErrorSnackbar(true);
                handleopenCloseSnackbar(true);
                setSuccessMessage("Markup Percentage should not contain 0");
            } else {
                setMarkupPercentageError(false);
                setOpenErrorSnackbar(false);
                handleopenCloseSnackbar(false);
                await savePrice();
            }
        }
    }

    return (
        <Fragment>
            <ReturnRequestSnackbar
                open={openSnackbar}
                type={openErrorSnackbar ? 'error' : ''}
                handleopenClose={() => {
                    handleopenCloseSnackbar(false)
                }} message={successMessage} />
            <div className="row">
                <div className="col-md-12 mt-2 mb-1">
                    <div className="d-flex align-items-center mb-2">
                        <h6 className="page-sub-title mb-0 mt-2">Selling Price</h6>
                    </div>
                </div>
            </div>
            <div className="card col-12 m-0 p-0">
                <div className="row px-3 mb-3">
                    <div className="col-md-3 col-lg-3 pr-2 mb-4 px-4 pl-0 ">
                    </div>
                    <div className="row col-12">
                        <div className="dealer-table-content float-right px-4 mb-2">
                            <ThemeProvider theme={theme}>
                                <Checkbox
                                    checked={checkselling ? true : false}
                                    onChange={(e) => {
                                        savePrice(e.target.checked)
                                        setCheckselling(e.target.checked)
                                    }}
                                    className="checkbox" />
                            </ThemeProvider>
                            <span className="dealer-table-content">Utilize Markup Percentage for Selling Price </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card col-12 m-0 p-2">
                <div className="row mt-3 px-2">
                </div>
                <Toolbar className="mb-2 mt-2">
                    <TextField
                        required
                        type="number"
                        id="markupPercentage"
                        disabled={checkselling === false}
                        value={markupPercentage}
                        error={markupPercentageError}
                        inputProps={{ minLength: 1, maxLength: 3 }}
                        onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        onChange={(e: any) => handleInputChange(e)}
                        InputProps={{
                            // startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                            endAdornment: <span>%</span>,
                        }}
                        size="small"
                        className="col-lg-3 col-md-3 col-sm-3 pr-3 commonRoundedInputs common-input-font-size"
                        label="Markup Percentage"
                        variant="outlined"
                    />
                </Toolbar>
                <div className="col-12">
                    <Button variant="contained"
                        onClick={() => { validation() }}
                        disabled={checkselling === false}
                        color="primary"
                        className="blueActionButton border-left rounded-pill ml-2 mb-2">Update</Button>
                </div>
            </div>
        </Fragment>
    );
}

