export const uploadedDocsLabels = {
    POWER_OF_ATTORNEY: 'poaFile',
    BANK_STATEMENT: 'bankStatementFile',
    BANK_GUARANTEE: 'bankGuaranteeFile',
    VAT_RETURN: 'vatReturnFile',
    VISIT_REPORT: 'visitReportFile',
    PASSPORT: 'passportFile',
    VISA_COPY: 'visaCopyFile',
    EMIRATES_ID: 'emiratesIdFile',
    TRADE_LICENSE: 'tradeLicenseFile',
    MEMBERSHIP: 'membershipFile',
    VAT_TRN: 'vatTRNFile'
}