import { DELIVERY } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState = {
  deliveryList: null,
  deliverDEtails: null,
};

function deliveryReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case DELIVERY.GET_DELIVERY_LIST_FETCHING:
      return {
        ...state,
        deliveryList: { loading: true, data: null, err: null }
      };
    case DELIVERY.GET_DELIVERY_LIST_SUCCESS:
      return {
        ...state,
        deliveryList: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case DELIVERY.GET_DELIVERY_LIST_ERROR:
      return {
        ...state,
        deliveryList: { loading: false, data: null, err: action.payload.error }
      };
      case DELIVERY.GET_DELIVERY_DETAILS_FETCHING:
      return {
        ...state,
        deliveryDetails: { loading: true, data: null, err: null }
      };
    case DELIVERY.GET_DELIVERY_DETAILS_SUCCESS:
      return {
        ...state,
        deliveryDetails: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case DELIVERY.GET_DELIVERY_DETAILS_ERROR:
      return {
        ...state,
        deliveryDetails: { loading: false, data: null, err: action.payload.error }
      };
    default:
      return state;
  }
};

export default deliveryReducer;