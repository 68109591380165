import React from 'react';
import { useSelector } from "react-redux";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AnnouncementIcon from '../../common/components/AnnouncementIcon';
import { Markup } from 'interweave';
import './Announcements.css'
import { Grid } from '@material-ui/core';
import NotificationIcon from '../../common/components/NotificationIcon';
import FullscreenLoader from '../../common/components/FullscreenLoader';

const AnnouncementDetail: React.FC = () => {
    const announcementDetail: any = useSelector((state: any) => state.announcements);

    if (announcementDetail && announcementDetail.announcementDetail && announcementDetail.announcementDetail.data) {
        localStorage.setItem("announcementTitle", announcementDetail.announcementDetail.data.title)
        localStorage.setItem("announcementArticleBody", announcementDetail.announcementDetail.data.Article_Body)
        localStorage.setItem("announcementImage", announcementDetail.announcementDetail.data.HeroImage.renditionList[0].resourceUri)
    }

    return (
        <>
            <div className="row mb-4">
                <div className="col-8">
                    <h2 className="page-title ml-2">
                        Announcement</h2>
                </div>
                <div className="col-4">
                    <div className="notification-pull-right">
                        <AnnouncementIcon />
                        <NotificationIcon />
                    </div>
                </div>
            </div>
            {announcementDetail && announcementDetail.loading && <FullscreenLoader />}
            <Paper className="height-500 ml-2 pt-3 pl-3">
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography variant="h6" className="announce-sub-title" gutterBottom>
                            {!announcementDetail || !announcementDetail.announcementDetail || !announcementDetail.announcementDetail.data ? localStorage.getItem("announcementTitle") : announcementDetail.announcementDetail.data.title}
                        </Typography>
                        <Typography variant="caption" className="announce-date" display="block" gutterBottom>
                            {announcementDetail && announcementDetail.announcementDetail && announcementDetail.announcementDetail.data && announcementDetail.announcementDetail.data.date}

                        </Typography>
                        <Typography component="span" variant="body2" className="announce-content text-ellipsis mt-4" gutterBottom >
                            <div style={{ display: 'inline-block', whiteSpace: 'normal', wordBreak: 'break-all', marginTop:'20px' }}>
                                {!announcementDetail || !announcementDetail.announcementDetail ? <Markup content={localStorage.getItem("announcementArticleBody")} /> :
                                    <Markup content={announcementDetail && announcementDetail.announcementDetail && announcementDetail.announcementDetail.data && announcementDetail.announcementDetail.data.Article_Body ? announcementDetail.announcementDetail.data.Article_Body : ""} />}
                            </div>
                        </Typography>
                    </Grid>
                    {/* ToDo - Put images if required */}
                    <Grid item xs={4}>
                        {!announcementDetail || !announcementDetail.announcementDetail ? <img className="img-fluid" alt="announcement" src={localStorage.getItem("announcementImage") || ""} /> :
                            <img className="img-fluid" src={announcementDetail && announcementDetail.announcementDetail && announcementDetail.announcementDetail.data && announcementDetail.announcementDetail.data.HeroImage.renditionList[0].resourceUri} alt="" />}
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default AnnouncementDetail
