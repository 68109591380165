import React, { useEffect, Dispatch } from 'react';
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { login } from "../../store/actions/account.actions";
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { onBoardingService } from './../../services/onBoarding.service'
import { getProfile } from "../../store/actions/profile.action";
// import { getJwtToken } from './../../store/actions/logon.actions';
import { authService } from './../../services/auth.service';
import { markAdminUser } from '../../store/actions/global.actions';
import { OnboardingErrorPage } from '../../common/AccountRestricted/OnboardingErrorPage';
import { DASHBOARD_REDIRECT_EXCLUSION } from '../../constants/dashboardRedirectExclusion';

const DealerUserOnboardingAuthenticateAzure: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [noAccess, setNoAccess] = React.useState(false)


    const validateAndUpdateUser = async () => {
        let data: any = queryString.parse(location.hash);
        if (data) {
            let jwt_token: any = jwt_decode(data.id_token);
            let userData = await onBoardingService.getUserB2CIdInSignup(jwt_token.oid);
            if (userData && userData.data && Array.isArray(userData.data) && userData.data[0]) {
                let b2cUid = userData.data[0].issuerAssignedId;
                let userPayload = { "b2bUserId": data.state, "b2cUserId": b2cUid };
                let isAdded = await onBoardingService.updateUserB2CId(userPayload);
                if (isAdded && isAdded.message && isAdded.message.status === "S") {
                    let usersData: any = await onBoardingService.getUserB2CIdNew(jwt_token.oid, isAdded && isAdded.data && isAdded.data.email);
                    sessionStorage.setItem('userOid', jwt_token.oid);
                    if (usersData && usersData.data) {
                        //let profileData: any = await dispatch(getProfile(b2cUid, "dealerUser"));
                        //let tokenData: any = await dispatch(getJwtToken(b2cUid, isAdded.data.email));
                        await authService.setToken(usersData && usersData.data && usersData.data);
                        let jwt_token: any = jwt_decode(usersData.data.accessToken);
                        let profileData: any = await dispatch(getProfile(jwt_token.userId, "dealerUser"));
                        //let profileData: any = await dispatch(getProfile(b2cUid, "dealerUser"));
                        if (profileData && profileData.message && profileData.message.status === "S") {
                            await dispatch(markAdminUser(false))
                            await dispatch(login(userPayload));
                            if (profileData.data && profileData.data.basicInfo) {
                                sessionStorage.setItem('userId', profileData.data.basicInfo.b2cUserId);
                                sessionStorage.setItem('email', profileData.data.basicInfo.email);
                                sessionStorage.setItem('customerId', profileData.data.basicInfo.sapCustomerId);
                                sessionStorage.setItem('userType', 'dealerUser');
                                sessionStorage.setItem('customerName', profileData.data.customerInfo.companyName)
                                sessionStorage.setItem('webApplicationLoginId', profileData.data.basicInfo.webApplicationLoginId);
                            }
                            if (profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.userType === "guestAdmin") {
                                history.push('/onboarding');
                                window.location.reload();
                            } else {
                                //DASHBOARD_REDIRECT_EXCLUSION to be modified for any path changes
                                if (!(DASHBOARD_REDIRECT_EXCLUSION.includes(window.location.pathname))) {
                                    history.push('/');
                                }
                            }
                        }
                    } else {
                        setNoAccess(true);
                        // setHasFailed('you dont have access to the app');
                    }
                }
                else {
                    setNoAccess(true);
                }
            }
            else {
                return <OnboardingErrorPage status="signUpFailed" />
            }
        } else {
            return <OnboardingErrorPage status="signUpFailed" />
            // alert('failed to signup')
        }
    }


    useEffect(() => {
        if (window.location.href.includes('error')) {
            history.push('/');
        }
        else {
            validateAndUpdateUser();
        }
        // This will be run on component mount
        // const callbackId = instance.addEventCallback((event: any) => {
        //     // This will be run every time an event is emitted after registering this callback
        //     if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        //         // dispatch(login(event.payload));
        //         // history.push('/');
        //     }
        // }
        // );

        // return () => {
        //     // This will be run on component unmount
        //     if (callbackId) {
        //         instance.removeEventCallback(callbackId);
        //     }
        // }
    }, []); // eslint-disable-line


    return (
        <div>
            {noAccess && <OnboardingErrorPage status="noAccess" />}
        </div>
    )
}

export default DealerUserOnboardingAuthenticateAzure
