/* eslint-disable */
import React, {
  Fragment,
  Dispatch,
  useEffect,
} from "react";
import "./CreateEnquiry.css";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ImportDialog from "./ImportExcelDialog";
import ViewFavorite from "./viewFavorite";
import OrderSummaryDialog from "./orderSummaryDialog";
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from "./../../common/components/CreatedEnquirySnackbar";
import XLSX from "xlsx";
import { searchProducts } from "../../store/actions/products.actions";
import FullscreenLoader from "./../../common/components/FullscreenLoader";
import NavigationPrompt from "react-router-navigation-prompt";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  createEnquiry,
  updateEnquiry,
  verifyEnquiry,
  getEnquiryDetails,
  placeOrder,
  verifyEnquiryQueueCheck,
  deleteLostSaleReport,
  getUniqueEnquirySessionId,
} from "../../store/actions/createUpdateEnquiry.actions";
import { useHistory } from "react-router-dom";
import moment from "moment";
import _, { get } from "lodash";
import Countdown from "react-countdown";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { Dialog } from "@material-ui/core";
import { getUniqueTimestamp } from "../../common/components/util";
import { getSellingPrice } from "../../helpers/commonmethod";

const CHECK_AVAILABILITY_BUTTON_TEXT = {
  NORMAL: 'Check Availability and Price',
  QUEUED: 'Queued for Verification',
  PROGRESS: 'Verification In Progress'
}

const orgIdBrandsMapping: any = {
  "2001": "Toyota",
  "2002": "Honda",
  "2003": "Famco",
  "2380": "TE",
}

const orgIdBrandsMappingReverse: any = {
  "Toyota": "2001",
  "Honda": "2002",
  "Famco": "2003",
  "TE": "2380",
}

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '2px 4px',
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  yes: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#1A9FE0',
    textTransform: 'capitalize'
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  logoutText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  },
  input: {
    flex: 1,
  },
  iconButton: {
    // padding: 10,
  },
  actionButton: {
    color: "#1a9fe0",
  },
  collapseBg: {
    background: "#FFFFF",
  },
  tableWidth: {
    minWidth: 170,
    // fontWeight: 600
  },
  collapseHeaderBg: {
    background: "rgba(17, 17, 17, 0.05)",
  },
}));

const getAmtValue = (item: any) => {
  let total = 0.00;
  if (item && item.nowAvailabilityChecked && item.nowAvailability && item.nsp) {
    total += parseFloat(item.nowAvailability) * parseFloat(item.nsp);
  }
  if (item && item.airAvailabilityChecked && item.airAvailability && item.aop) {
    total += parseFloat(item.airAvailability) * parseFloat(item.aop);
  }
  return total
}

function Row(props: any) {
  const {
    availability,
    setcheckedAllBO,
    setcheckedAllNow,
    enquiryAction,
    setisSelectedAll,
    brandCode,
    setbrandRefNumbers,
    brandRefNumbers,
    setproducts,
    isAvailabiityChecked,
    brandName,
    brandData,
    products,
    setIsQuantityUpdated,
    isSellingPriceData,
    markupPercentage
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <tr
        className="tableRowPanelsCreateEnquiry"
      >
        <td className="tdPanelsCreateEnquiry">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </td>
        <td colSpan={11} className="tdPanelsCreateEnquiry blueLabelHeaderTable">
          {brandName}{" "}
          <input
            maxLength={20}
            value={brandRefNumbers[brandCode]}
            onChange={(e) => {
              if (enquiryAction !== "view") {
                setbrandRefNumbers({
                  ...brandRefNumbers,
                  [brandCode]: e.target.value ? e.target.value : "",
                });
              }
            }}
            type="text"
            className="ml-3 referenceNumberText"
            placeholder="Your reference number"
          />
          <span className="ml-3 totalCountTextSmallest">{brandData.length} Parts</span>
        </td>
      </tr>
      {/* <tr className={"tableRowPanelsCreateEnquiry"}>
        <td className="p-0" colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit></Collapse>
        </td>
      </tr> */}
      {open &&
        brandData &&
        brandData.map((item: any, index: any) => {
          if (
            !isAvailabiityChecked ||
            (isAvailabiityChecked &&
              (availability === "All" ||
                (availability === "Now" &&
                  item.nowAvailability &&
                  !item.airAvailability) ||
                (availability === "BO" && item.airAvailability)))
          ) {
            return (

              <tr className={`${item.isSuperseeded ? "superseededRow" : ""} ${!item.isValid ? "tableRowCreateEnquiry invalidRowClass" : "tableRowCreateEnquiry"}`}>
                <td className="tdchildCreateEnquiry">
                  {!item.isSuperseeded ? <input type="checkbox"
                    checked={item.selected ? true : false}
                    onChange={() => {
                      if (enquiryAction !== "view") {
                        let productsTemp: any = JSON.parse(
                          JSON.stringify(products)
                        );
                        productsTemp[item.actualIndex]["selected"] =
                          !item.selected;
                        setproducts([...productsTemp]);
                        setisSelectedAll(false);
                      }
                    }}
                  /> : <ArrowRightAltIcon />}
                </td>
                <td className="tdchildCreateEnquiry">
                  <div className="innergreyLabelTable position-relative">
                    {" "}
                    {item.isSpecialPriced && (
                      <span className="dotlegendItemCreateEnquiry"></span>
                    )}{" "}
                    {/* {item.superceededPartIndicator && (
                      <span className="dotlegendItemCreateEnquirySupersede"></span>
                    )}{" "} */}
                    {item.productId}
                  </div>
                  <div className="greyLabelTable partNumberYextTable">
                    {item.productIdDescription}
                  </div>
                </td>
                <td className="tdchildCreateEnquiry">
                  {!item.isSuperseeded && <div className="innergreyLabelTable">
                    <input
                      type="text"
                      onChange={(e) => {
                        if (enquiryAction !== "view") {
                          let productsTemp: any = JSON.parse(
                            JSON.stringify(products)
                          );
                          let value = e.target.value && parseInt(e.target.value) && /^[+]?\d+([.]\d+)?$/.test(e.target.value) &&
                            parseInt(e.target.value) >= 1
                            ? parseInt(e.target.value)
                            : ""
                          setIsQuantityUpdated(true);
                          productsTemp[item.actualIndex]["quantity"] = value;
                          setproducts([...productsTemp]);
                        }
                      }}
                      value={item.quantity}
                      maxLength={5}
                      className={`cardCommon mr-1 smallcircularinputFiled ${!item.quantity || !parseInt(item.quantity)
                        ? "error-border-input"
                        : ""
                        }`}
                      placeholder=""
                    />{" "}
                    {isAvailabiityChecked && item["nowAvailability"]
                      ? item["nowAvailability"]
                      : "0"}
                  </div>}
                </td>
                <td className="tdchildCreateEnquiry font-10 font-weight-700 text-444">{isAvailabiityChecked && item["rsp"] ? "XX.XX" : "XX.XX"}</td>
                <td className="tdchildCreateEnquiry font-10 font-weight-700 text-444">{isAvailabiityChecked && item["nsp"] ? parseFloat(getSellingPrice(item["nsp"], isSellingPriceData, markupPercentage)).toFixed(2) : "0.00"}</td>
                <td className="tdchildCreateEnquiry font-10 font-weight-700 text-444">{isAvailabiityChecked ? parseFloat(getSellingPrice(getAmtValue(item), isSellingPriceData, markupPercentage)).toFixed(2) : "0.00"}</td>
                <td className="tdchildCreateEnquiry font-10 font-weight-700 text-444">{isAvailabiityChecked && item["nspev"] ? parseFloat(getSellingPrice(item["nspev"], isSellingPriceData, markupPercentage)).toFixed(2) : "0.00"}</td>
                <td className="tdchildCreateEnquiry font-10 font-weight-700 text-444">{isAvailabiityChecked && item["aop"] ? parseFloat(getSellingPrice(item["aop"], isSellingPriceData, markupPercentage)).toFixed(2) : "0.00"}</td>
                <td className="tdchildCreateEnquiry">
                  <div className="row no-gutters">
                    <div className="innergreyLabelTable col-6">
                      <input type="checkbox"
                        // disabled={item.isSuperseeded}
                        checked={item.nowAvailabilityChecked ? true : false}
                        onChange={() => {
                          if (enquiryAction !== "view" && item["nowAvailability"]) {
                            let productsTemp: any = JSON.parse(
                              JSON.stringify(products)
                            );
                            productsTemp[item.actualIndex][
                              "nowAvailabilityChecked"
                            ] = !item.nowAvailabilityChecked;
                            setproducts([...productsTemp]);
                            setcheckedAllNow(false);
                          }
                        }}
                      />{" "}
                      {isAvailabiityChecked && item["nowAvailability"]
                        ? item["nowAvailability"]
                        : "0"}
                    </div>
                    <div className=" innergreyLabelTable col-6">
                      <input type="checkbox"
                        disabled={item.isSuperseeded}
                        checked={item.airAvailabilityChecked ? true : false}
                        onChange={() => {
                          if (enquiryAction !== "view" && item["airAvailability"]) {
                            let productsTemp: any = JSON.parse(
                              JSON.stringify(products)
                            );
                            productsTemp[item.actualIndex][
                              "airAvailabilityChecked"
                            ] = !item.airAvailabilityChecked;
                            setproducts([...productsTemp]);
                            setcheckedAllBO(false);
                          }
                        }}
                      />{" "}
                      {isAvailabiityChecked && item["airAvailability"]
                        ? item["airAvailability"]
                        : "0"}
                    </div>
                  </div>
                </td>
                <td className="tdchildCreateEnquiry">
                  <div className="innergreyLabelTable">
                    {item.isValid
                      ? `${parseInt(item.dimensions.length)}x${parseInt(
                        item.dimensions.width
                      )}x${parseInt(item.dimensions.height)} | ${item.dimensions.grossWeight ? parseFloat(item.dimensions.grossWeight).toFixed(2) : '-'}`
                      : "-"}
                  </div>
                </td>
                <td className="tdchildCreateEnquiry">
                  <div className="innergreyLabelTable">
                    {item.isValid
                      ? `${parseFloat(item.dimensions.volume).toFixed(3)} cm`
                      : "-"}
                    <sup>3</sup>
                  </div>
                </td>
              </tr>
            );
          } else {
            return null;
          }
        })}
    </React.Fragment>
  );
}

const Dashboard: React.FC = (props: any) => {
  const classes = useStyles();
  const history: any = useHistory();
  const [products, setproducts] = React.useState<any>([]);
  const [isQuantityUpdated, setIsQuantityUpdated] = React.useState(false);
  const [orderTotal, setorderTotal] = React.useState<any>(0);
  const dispatch: Dispatch<any> = useDispatch();
  const [productId, setproductId] = React.useState("");
  const [quantity, setquantity] = React.useState<any>(0);
  const [isAvailabiityChecked, setisAvailabiityChecked] = React.useState(false);
  const [placeorderEnabled, setplaceorderEnabled] = React.useState(false);
  const [isAvailabiityCheckedAtTime, setisAvailabiityCheckedAtTime] =
    React.useState<any>(null);
  const [isEnquirySaved, setisEnquirySaved] = React.useState(false);
  const [enquiryData, setenquiryData] = React.useState<any>(null);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarType, setOpenSnackbarType] = React.useState("");
  const [openOrderSummary, setOpenOrderSummary] = React.useState(false);
  const [isUpdateMode, setisUpdateMode] = React.useState(false);
  const [isSelectedAll, setisSelectedAll] = React.useState(false);
  const [orderQueued, setorderQueued] = React.useState(false);
  const [enquiryAction, setenquiryAction] = React.useState("");
  const [referenceNumber, setreferenceNumber] = React.useState("");
  const [changeData, setchangeData] = React.useState<any>(null);
  const [brandRefNumbers, setbrandRefNumbers] = React.useState<any>({
    "2001": "",
    "2002": "",
    "2003": "",
    "2380": "",
  });
  const [availability, setAvailability] = React.useState("All");
  const [procuredPart, setprocuredPart] = React.useState<any>("");
  const [showBackOrderConfirmation, setshowBackOrderConfirmation] = React.useState<any>(false);
  const [actualProductLength, setActualProductLength] = React.useState<any>();
  const { enquiryInfo, enquirySessionId }: any = useSelector(
    (state: any) => state.createUpdateEnquiry
  );
  const [orderData, setorderData] = React.useState<any>(null);
  const [checkedAllNow, setcheckedAllNow] = React.useState<any>(false);
  const [checkedAllBO, setcheckedAllBO] = React.useState<any>(false);
  const [checkAvailabilityButtonText, setCheckAvailabilityButtonText] = React.useState<any>('Check Availability and Price');
  const { favoritesList }: any = useSelector((state: any) => state.favorites);
  const { materialGroups }: any = useSelector((state: any) => state.materialGroups);
  const materialGroupsData = get(materialGroups, 'data.data', null);

  const { isSellingPriceData, profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);

  let enquiryMappingReverse: any = {};

  materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
    enquiryMappingReverse[group.make] = group.materialGroup.toString();
  })

  let enquiryMapping: any = {};

  materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
    group.materialGroup.forEach((item: any) => {
      enquiryMapping[item] = group.make;
    })
  })

  enquiryMapping = {
    "AMPRP": "Toyota",
    "AMPTY": "Toyota",
    "AMPPP": "Toyota",
    "AMPLX": "Toyota",
    "AMPHN": "Toyota",
    "AMPDN": "Toyota",
    "AMPCT": "Toyota",
    "AMPBT": "Toyota",
    "TEPMC": "Honda",
    "TEPHP": "Honda",
    "TEPHN": "Honda",
    "TEPBG": "Honda",
    "FMPAG": "Famco",
    "FMPAL": "Famco",
    "FMPAM": "Famco",
    "FMPBA": "Famco",
    "FMPBP": "Famco",
    "FMPBS": "Famco",
    "FMPCA": "Famco",
    "FMPCC": "Famco",
    "FMPCE": "Famco",
    "FMPCM": "Famco",
    "FMPCP": "Famco",
    "FMPCT": "Famco",
    "FMPEB": "Famco",
    "FMPEC": "Famco",
    "FMPEK": "Famco",
    "FMPEM": "Famco",
    "FMPEX": "Famco",
    "FMPFR": "Famco",
    "FMPGB": "Famco",
    "FMPGG": "Famco",
    "FMPHA": "Famco",
    "FMPHI": "Famco",
    "FMPHN": "Famco",
    "FMPIK": "Famco",
    "FMPL0": "Famco",
    "FMPLB": "Famco",
    "FMPLG": "Famco",
    "FMPLK": "Famco",
    "FMPLL": "Famco",
    "FMPLU": "Famco",
    "FMPMG": "Famco",
    "FMPMP": "Famco",
    "FMPMR": "Famco",
    "FMPMS": "Famco",
    "FMPNL": "Famco",
    "FMPPE": "Famco",
    "FMPPK": "Famco",
    "FMPPT": "Famco",
    "FMPPU": "Famco",
    "FMPSD": "Famco",
    "FMPSH": "Famco",
    "FMPSL": "Famco",
    "FMPSS": "Famco",
    "FMPSW": "Famco",
    "FMPTE": "Famco",
    "FMPTH": "Famco",
    "FMPTR": "Famco",
    "FMPUH": "Famco",
    "FMPUL": "Famco",
    "FMPVB": "Famco",
    "FMPVC": "Famco",
    "FMPVE": "Famco",
    "FMPVK": "Famco",
    "FMPVL": "Famco",
    "FMPVT": "Famco",
    "FMPVW": "Famco",
    "FMPYA": "Famco",
    "FMPYK": "Famco",
    "FMPZF": "Famco",
    "FMPZT": "Famco",
    "FMUPT": "Famco",
    "FMUIP": "Famco",
    "FMUH0": "Famco",
    "FMUUL": "Famco",
    "FMAVC": "Famco",
    "FMAIP": "Famco",
    "FMALG": "Famco",
    "FMAPP": "Famco",
    "FMAVT": "Famco",
    "FMAFI": "Famco",
    "FMUTH": "Famco",
    "TEPVL": "TE",
    "TEPCR": "TE"
  };

  enquiryMappingReverse = {
    Toyota: "AMPRP,AMPTY,AMPPP,AMPLX,AMPHN,AMPDN,AMPCT,AMPBT",
    Famco: "FMPAG,FMPAL,FMPAM,FMPBA,FMPBP,FMPBS,FMPCA,FMPCC,FMPCE,FMPCM,FMPCP,FMPCT,FMPEB,FMPEC,FMPEK,FMPEM,FMPEX,FMPFR,FMPGB,FMPGG,FMPHA,FMPHI,FMPHN,FMPIK,FMPL0,FMPLB,FMPLG,FMPLK,FMPLL,FMPLU,FMPMG,FMPMP,FMPMR,FMPMS,FMPNL,FMPPE,FMPPK,FMPPT,FMPPU,FMPSD,FMPSH,FMPSL,FMPSS,FMPSW,FMPTE,FMPTH,FMPTR,FMPUH,FMPUL,FMPVB,FMPVC,FMPVE,FMPVK,FMPVL,FMPVT,FMPVW,FMPYA,FMPYK,FMPZF,FMPZT,FMUPT,FMUIP,FMUH0,FMUUL,FMAVC,FMAIP,FMALG,FMAPP,FMAVT,FMAFI,FMUTH",
    Honda: "TEPMC,TEPHP,TEPHN,TEPBG",
    TE: "TEPCR,TEPVL,TEPPO"
  };

  const b2bUserId = get(profileDetails, 'data.data.basicInfo.b2bUserId', "");
  const assignedBrandsData = get(
    profileDetails,
    "data.data.basicInfo.salesOrganization",
    []
  );

  let address = get(
    profileDetails,
    "data.data.customerInfo.addresses",
    ""
  );
  const firstName = get(
    profileDetails,
    "data.data.basicInfo.firstName",
    ""
  );

  const region = get(
    profileDetails,
    "data.data.basicInfo.region",
    ""
  );

  const b2cUserId = get(
    profileDetails,
    "data.data.basicInfo.b2cUserId",
    ""
  );

  if (typeof address != 'string') {
    if (typeof address == 'object') {
      let data = address.find((item: any) => item.firstAddress)
      if (data) {
        address = `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}`
      } else {
        address = ``
      }
    } else {
      address = ""
    }
  }


  const handleAvailabilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAvailability(event.target.value);
  };

  const handleopenCloseOrderSummary = (open?: any) => {
    setOpenOrderSummary(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  React.useEffect(() => {
    // dispatch(getFavoritesList());
    checkSessionStorage();
  }, []);

  const showImportExcel = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.UPLOAD_ENQUIRY_EXCEL);

  const checkSessionStorage = async () => {
    let enquiryDataExisting: any = sessionStorage.getItem("enquiryModeDetails");
    sessionStorage.setItem("enquiryModeDetails", "");
    if (enquiryDataExisting && JSON.parse(enquiryDataExisting)) {
      enquiryDataExisting = JSON.parse(enquiryDataExisting);
      setenquiryAction(enquiryDataExisting.mode);
      setproducts([]);
      let savedData: any = await dispatch(
        getEnquiryDetails(enquiryDataExisting.masterId)
      );
      enquiryDataToEditMode(savedData.data.data, true, false, enquiryDataExisting.mode);
      dispatch(getUniqueEnquirySessionId(savedData.data.data && savedData.data.data[0].b2bLostSalesReportId ? savedData.data.data[0].b2bLostSalesReportId : getUniqueTimestamp()));
      setenquiryData(savedData.data.data);
      setActualProductLength(savedData.data.data[0].products.length);
    } else if (sessionStorage.getItem("partsData")) {
      let productsTemp: any = JSON.parse(JSON.stringify(products));
      let partsData = sessionStorage.getItem("partsData");
      if (partsData) {
        partsData = JSON.parse(partsData);
        if (Array.isArray(partsData)) {
          partsData.map((item) => {
            if (item.productId && item.productId.trim()) {
              productsTemp.push({
                productId: item.productId ? item.productId.trim() : "",
                quantity:
                  item.quantity && parseInt(item.quantity)
                    ? parseInt(item.quantity)
                    : 1,
              });
            }
          });
          commonInsertionLogic(productsTemp);
        }
      }
      sessionStorage.setItem("partsData", "");
    }
  };

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      // setisAvailabiityChecked(false);
      return null;
    } else {
      // Render a countdown
      return <span>{minutes}:{seconds} mins</span>;
    }
  };


  useEffect(() => {
    history.listen((location: any) => {
      if (history.action === 'PUSH') {
        setorderData(null);
        setcheckedAllNow(false);
        setcheckedAllBO(false);
        setproducts([]);
        setproductId("");
        setorderTotal(0);
        setquantity(0);
        setorderQueued(false)
        setisAvailabiityChecked(false);
        setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL)
        setisAvailabiityCheckedAtTime(null);
        setisEnquirySaved(false);
        setenquiryData(null);
        setSuccessMessage(null);
        setOpenSnackbar(false);
        setOpenSnackbarType("");
        setOpenOrderSummary(false);
        setisUpdateMode(false);
        setisSelectedAll(false);
        setenquiryAction("");
        setreferenceNumber("");
        setchangeData(null);
        setbrandRefNumbers({
          "2001": "",
          "2002": "",
          "2003": "",
          "2380": "",
        });
        setAvailability("All");
        setprocuredPart("")
      }
    })

    return () => {

    }
  }, [])

  const commonInsertionLogic = async (jsonData: any, fetchFromRedux?: any) => {
    let negativeValuesPresent = false;
    if (jsonData && Array.isArray(jsonData) && jsonData.length > 0) {
      let orgsAssignedToUser = "";
      if (assignedBrandsData && Array.isArray(assignedBrandsData)) {
        assignedBrandsData.map((item: any) => {
          if (item.salesOrganization) {
            orgsAssignedToUser += enquiryMappingReverse[`${orgIdBrandsMapping[`${item.salesOrganization}`]}`] + ",";
          }
        });
      }
      let tempData = jsonData.filter(
        (item: any) => item.productId && item.productId.trim()
      );
      let partsList: any = tempData.map((item) => {
        return item.productId.trim();
      });
      let searchProduct: any = [];
      tempData.map(item => {
        searchProduct.push({ productId: item.productId.trim(), quantity: item.quantity })
      })
      let apiData: any = await dispatch(searchProducts(partsList, enquirySessionId, firstName, b2bUserId, searchProduct, region));
      let reduxData: any = sessionStorage.getItem("tempProducts");
      let productsTemp: any = JSON.parse(
        JSON.stringify(
          fetchFromRedux && reduxData ? JSON.parse(reduxData) : products
        )
      );
      jsonData.map((item: any) => {
        let isInvalidBrand = "";
        let isPresent: any =
          apiData && apiData.data && apiData.data.length > 0
            ? apiData.data.find((responsePart: any) => {
              if (responsePart && item.productId && responsePart.productId && responsePart.productId.trim().toUpperCase() === item.productId.trim().toUpperCase()) {
                isInvalidBrand = responsePart.attributes.materialGroup.value;
              }
              return (
                responsePart.productId.trim().toUpperCase() === item.productId.trim().toUpperCase() &&
                formatMaterialGroup(responsePart.attributes.materialGroup.value) &&
                orgsAssignedToUser.includes(formatMaterialGroup(responsePart.attributes.materialGroup.value))
              );
            })
            : null;
        if (
          !(
            item.quantity &&
            parseInt(item.quantity) &&
            parseInt(item.quantity) >= 1
          )
        ) {
          negativeValuesPresent = true;
        }
        if (
          item.quantity &&
          parseInt(item.quantity) &&
          parseInt(item.quantity) >= 1 &&
          item.productId &&
          item.productId.trim() &&
          productsTemp &&
          Array.isArray(productsTemp)
        ) {
          let containsItem = false;
          // productsTemp.map((product: any) => {
          //   if (
          //     product.productId &&
          //     item.productId.trim() &&
          //     product.productId === item.productId.trim()
          //   ) {
          //     product.quantity +=
          //       item.quantity && parseInt(item.quantity)
          //         ? parseInt(item.quantity) >= 1
          //           ? parseInt(item.quantity)
          //           : 0
          //         : 0;
          //     product.quantity = product.quantity && product.quantity.toString() && parseInt(product.quantity.toString().slice(0, 5))
          //     containsItem = false;
          //   }
          //   return product;
          // });
          if (!containsItem) {
            productsTemp.push({
              quantity:
                item.quantity &&
                  parseInt(item.quantity) &&
                  parseInt(item.quantity) >= 1
                  ? parseInt(item.quantity)
                  : 1,
              invalidMaterialGroup: isInvalidBrand ? isInvalidBrand : "",
              productId:
                isPresent && isPresent.productId
                  ? isPresent.productId
                  : item.productId.trim(),
              materialGroup:
                isPresent &&
                  isPresent.attributes &&
                  isPresent.attributes.materialGroup
                  ? isPresent.attributes.materialGroup.value.toUpperCase()
                  : "",
              productIdDescription:
                isPresent && isPresent.description
                  ? isPresent.description["EN"]
                  : "",
              productCost: 0,
              currency: "AED",
              unitOfMeasure:
                isPresent &&
                  isPresent.attributes &&
                  isPresent.attributes.unitOfMeasure
                  ? isPresent.attributes.unitOfMeasure.value
                  : "",
              VAT: "",
              orgId: isPresent && isPresent.orgId ? isPresent.orgId : "",
              enquiryReferenceNumber: referenceNumber ? referenceNumber : "",
              isSpecialPriced: false,
              nowAvailability: 0,
              nowAvailabilityChecked: false,
              airAvailability: 0,
              airAvailabilityChecked: false,
              action: "I",
              dimensions: {
                height:
                  isPresent &&
                    isPresent.attributes &&
                    isPresent.attributes.height
                    ? isPresent.attributes.height.value
                    : "",
                width:
                  isPresent &&
                    isPresent.attributes &&
                    isPresent.attributes.width
                    ? isPresent.attributes.width.value
                    : "",
                length:
                  isPresent &&
                    isPresent.attributes &&
                    isPresent.attributes.length
                    ? isPresent.attributes.length.value
                    : "",
                volume:
                  isPresent &&
                    isPresent.attributes &&
                    isPresent.attributes.volume
                    ? isPresent.attributes.volume.value
                    : "",
                grossWeight: isPresent &&
                  isPresent.attributes &&
                  isPresent.attributes.grossWeight
                  ? isPresent.attributes.grossWeight.value
                  : ""
              },
              attributes: [
                {
                  name: "height",
                  value:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.height
                      ? isPresent.attributes.height.value
                      : "",
                },
                {
                  name: "width",
                  value:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.width
                      ? isPresent.attributes.width.value
                      : "",
                },
                {
                  name: "length",
                  value:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.length
                      ? isPresent.attributes.length.value
                      : "",
                },
                {
                  name: "grossWeight",
                  value:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.grossWeight
                      ? isPresent.attributes.grossWeight.value
                      : "",
                },
                {
                  name: "volume",
                  value:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.volume
                      ? isPresent.attributes.volume.value
                      : "",
                },
              ],
              plant: "",
              nowEnquiryItemNumber: isPresent && isPresent.nowEnquiryItemNumber ? isPresent.nowEnquiryItemNumber : '',
              nowProposedItemNumber: "",
              airEnquiryItemNumber: isPresent && isPresent.airEnquiryItemNumber ? isPresent.airEnquiryItemNumber : '',
              airProposedItemNumber: "",
              isValid:
                isPresent && isPresent.attributes.materialGroup &&
                  isPresent.attributes.materialGroup.value &&
                  enquiryMapping[`${formatMaterialGroup(isPresent.attributes.materialGroup.value.toUpperCase())}`]
                  ? true
                  : false,
            });
          }
        } else {
          negativeValuesPresent = true;
        }
      });
      setproducts(JSON.parse(JSON.stringify(productsTemp)));
      // setisAvailabiityChecked(false);
    }
    if (negativeValuesPresent) {
      setOpenSnackbarType("error");
      setSuccessMessage(
        `Negative quantity values were ignored during the import`
      );
      handleopenCloseSnackbar(true);
    }
    setTimeout(() => {
      setproductId("");
      setquantity(0);
    }, 1000);
  };

  const exportData = () => {
    if (products && Array.isArray(products) && products.length > 0) {
      let filename = "enquiryexport.xlsx";
      let data: any = [];
      products.map((item: any) => {
        data.push({
          'Part No': item.productId,
          'Alternate Part': item.isSuperseeded ? 'YES' : 'NO',
          Brand: enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] : '',
          'Part Description': item.productIdDescription,
          Quantity: !item.isSuperseeded ? item.quantity : '',
          'Price (rsp)': item.rsp ? "XX.XX" : 'XX.XX',
          'NSP (Incl. VAT)': item.nsp ? Number(parseFloat(getSellingPrice(item.nsp, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
          Amt: Number(parseFloat(getSellingPrice(getAmtValue(item), isSellingPriceData, markupPercentage)).toFixed(2)),
          'NSP (Excl. VAT)': item.nspev ? Number(parseFloat(getSellingPrice(item.nspev, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
          AOP: item.aop ? Number(parseFloat(getSellingPrice(item.aop, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
          'Now Availability': item.nowAvailability,
          'BO Availability': item.airAvailability,
          Dimension: item.isValid
            ? `${parseInt(item.dimensions.length)} x ${parseInt(
              item.dimensions.width
            )} x ${parseInt(item.dimensions.height)}`
            : "-",
          Weight: item.isValid ? (item.dimensions.grossWeight ? Number(parseFloat(item.dimensions.grossWeight).toFixed(2)) : '-') : "-",
          Volume: item.isValid ? Number(parseFloat(item.dimensions.volume).toFixed(2)) : "-",
          'Orignal Part': item.supercessionMaterial
        });
      });
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
    }
  };

  const isNumeric = (n: any) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };


  const formatMaterialGroup = (data: any) => {
    if (data) {
      return data.substring(0, 5).toUpperCase()
    } else {
      return "XXXXX"
    }
  }

  const renderRows = () => {
    let mappedData: any = {
      Toyota: [],
      Honda: [],
      Famco: [],
      TE: [],
      Others: [],
    };
    let invalidProducts: any = [];
    if (products && Array.isArray(products) && products.length > 0) {
      let productunref: any = JSON.parse(JSON.stringify(products));
      productunref.map((item: any, index: any) => {
        let storeItem: any = JSON.parse(JSON.stringify(item));
        storeItem["actualIndex"] = index;
        if (item.materialGroup && enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] && item.productId != procuredPart) {
          mappedData[enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]].push(storeItem);
        } else {
          invalidProducts.push(storeItem);
        }
      });
    }
    Object.keys(mappedData).map((key) => {
      mappedData[key] = mappedData[key].sort((a: any, b: any) => {
        let deciderCondition = b.isSuperseeded ? -1 : 1;
        let part1ItemNumber = a.nowEnquiryItemNumber || a.airEnquiryItemNumber;
        let part2ItemNumber = b.nowEnquiryItemNumber || b.airEnquiryItemNumber;
        return parseInt(part1ItemNumber) > parseInt(part2ItemNumber) ? 1 : parseInt(part2ItemNumber) > parseInt(part1ItemNumber) ? -1 : deciderCondition;
      })
    })
    let finalRows: any = [];
    Object.keys(mappedData).map((key) => {
      if (mappedData[key].length > 0) {
        finalRows.push(
          <Row
            availability={availability}
            setcheckedAllBO={setcheckedAllBO}
            setcheckedAllNow={setcheckedAllNow}
            enquiryAction={enquiryAction}
            setisSelectedAll={setisSelectedAll}
            brandCode={orgIdBrandsMappingReverse[key]}
            setbrandRefNumbers={setbrandRefNumbers}
            brandRefNumbers={brandRefNumbers}
            products={products}
            brandName={key.toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" : key.toUpperCase() === "TE" ? "Trading Enterprises" : key}
            brandData={mappedData[key]}
            isAvailabiityChecked={isAvailabiityChecked}
            setproducts={setproducts}
            setIsQuantityUpdated={setIsQuantityUpdated}
            isSellingPriceData={isSellingPriceData}
            markupPercentage={markupPercentage}
          />
        );
      }
    });
    invalidProducts.map((item: any) => {
      finalRows.push(
        <tr className={!item.isValid || item.productId == procuredPart ? "invalidRowClass " : ""}>
          <td className="tdchildCreateEnquiry">
            <input type="checkbox"
              checked={item.selected ? true : false}
              onChange={() => {
                if (enquiryAction !== "view") {
                  let productsTemp: any = JSON.parse(JSON.stringify(products));
                  productsTemp[item.actualIndex]["selected"] = !item.selected;
                  setproducts([...productsTemp]);
                  setisSelectedAll(false);
                }
              }}
            />
          </td>
          <td className="tdchildCreateEnquiry">
            <div className="greyLabelTable fontsizetenew">{item.productId}</div>
            <div className="greyLabelTable invalidPartText fontsizetenew">
              {item.productId == procuredPart ? "Does not allow external procurement" : !item.invalidMaterialGroup ? 'Invalid part' : `${enquiryMapping[`${formatMaterialGroup(item.invalidMaterialGroup)}`]} Brand not allowed`}
            </div>
          </td>
          <td className="tdchildCreateEnquiry">
            <div className="greyLabelTable fontsizetenew">
              <input
                onChange={(e) => {
                  if (enquiryAction !== "view") {
                    let productsTemp: any = JSON.parse(
                      JSON.stringify(products)
                    );
                    let value = e.target.value && parseInt(e.target.value) && /^[+]?\d+([.]\d+)?$/.test(e.target.value) &&
                      parseInt(e.target.value) >= 1
                      ? parseInt(e.target.value)
                      : ""
                    setquantity(value);

                    productsTemp[item.actualIndex]["quantity"] = value;
                    setproducts([...productsTemp]);
                  }
                }}
                value={item.quantity}
                maxLength={5}
                type="text"
                className="cardCommon mr-2 smallcircularinputFiled"
                placeholder=""
              />{" "}
              {isAvailabiityChecked && item.quantity ? item.quantity : "0"}
            </div>
          </td>
          <td className="tdchildCreateEnquiry font-10 font-weight-700 text-444">XX.XX</td>
          <td className="tdchildCreateEnquiry font-10 font-weight-700 text-444">0.00</td>
          <td className="tdchildCreateEnquiry font-10 font-weight-700 text-444">0.00</td>
          <td className="tdchildCreateEnquiry font-10 font-weight-700 text-444">0.00</td>
          <td className="tdchildCreateEnquiry font-10 font-weight-700 text-444">0.00</td>
          <td className="tdchildCreateEnquiry">
            <div className="row no-gutters ml-n2">
              <div className="w-50">
                <input type="checkbox" />{" "}
                {isAvailabiityChecked && item.quantity ? item.quantity : "0"}
              </div>
              <div className="w-50">
                <input type="checkbox" /> 0
              </div>
            </div>
          </td>
          <td className="tdchildCreateEnquiry">
            <div className="greyLabelTable fontsizetenew">
              {item.isValid
                ? `${parseInt(item.dimensions.length)} x ${parseInt(
                  item.dimensions.width
                )} x ${parseInt(item.dimensions.height)} | ${item.dimensions.grossWeight ? parseFloat(item.dimensions.grossWeight).toFixed(2) : '-'}`
                : "-"}
            </div>
          </td>
          <td className="tdchildCreateEnquiry">
            <div className="greyLabelTable fontsizetenew">
              {item.isValid ? `${parseInt(item.dimensions.volume)}m` : "-"}
            </div>
          </td>
        </tr>
      );
    });
    return finalRows;
  };

  const enquiryDataToEditMode = (enquiryDataFetched: any, reset?: any, withDeletion?: any, availabilityMode?: any) => {
    let productsTemp: any = [];
    productsTemp = productsTemp.filter((product: any) => product.isValid);
    // let pushedProducts: any = [];
    let brandRefNumbersTemp = JSON.parse(JSON.stringify(brandRefNumbers));
    // if (!productsTemp || productsTemp.length === 0) {
    enquiryDataFetched.map((subenquiry: any, index: any) => {
      setreferenceNumber(subenquiry.masterReferenceNumber);
      if (subenquiry && subenquiry.products && Array.isArray(subenquiry.products)) {
        subenquiry.products.map((productSub: any) => {
          // if (!pushedProducts.includes(productSub.productId)) {
          //   pushedProducts.push(productSub.productId);
          // productsTemp.push(JSON.parse(JSON.stringify(productSub)));
          // }
          // setreferenceNumber(subenquiry.masterReferenceNumber);
          if (subenquiry.verifiedOn && index == 0) {
            var now = moment(new Date());
            var start = moment(new Date(subenquiry.verifiedOn));
            var duration: any = moment.duration(start.diff(now));
            var minutes: any = Math.abs(duration.asMinutes());
            if (!(minutes && minutes > 5) || availabilityMode) {
              setisAvailabiityCheckedAtTime(new Date(subenquiry.verifiedOn))
              setisAvailabiityChecked(true)
            }
          }
          brandRefNumbersTemp[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(productSub.materialGroup)}`]}`]}`] = productSub.enquiryReferenceNumber;
          productSub["enquiryNumber"] = subenquiry.enquiryNumber;
          productSub["id"] = subenquiry.id;
          productsTemp.push(JSON.parse(JSON.stringify(productSub)));
        });
      }
    });
    // }
    // if (withDeletion) {
    //   productsTemp = productsTemp.filter((item: any) => !item.selected);
    // }
    // let tempProductsObject: any = null;
    // tempProductsObject = _.keyBy(productsTemp, 'productId');
    // enquiryDataFetched.map((subenquiry: any, index: any) => {
    //   setreferenceNumber(subenquiry.masterReferenceNumber);
    //   if (subenquiry.verifiedOn && index === 0) {
    //     var now = moment(new Date());
    //     var start = moment(new Date(subenquiry.verifiedOn));
    //     var duration: any = moment.duration(start.diff(now));
    //     var minutes: any = Math.abs(duration.asMinutes());
    //     if (!(minutes && minutes > 5) || availabilityMode) {
    //       setisAvailabiityCheckedAtTime(new Date(subenquiry.verifiedOn))
    //       setisAvailabiityChecked(true)
    //     }
    //   }
    //   if (
    //     subenquiry &&
    //     subenquiry.products &&
    //     Array.isArray(subenquiry.products)
    //   ) {
    //     subenquiry.products.map((productSub: any) => {
    //       if (productSub.productId && tempProductsObject[productSub.productId]) {
    //         tempProductsObject[productSub.productId]["nowEnquiryItemNumber"] = productSub.nowEnquiryItemNumber ? productSub.nowEnquiryItemNumber : "";
    //         tempProductsObject[productSub.productId]["nowProposedItemNumber"] = productSub.nowProposedItemNumber ? productSub.nowProposedItemNumber : "";
    //         tempProductsObject[productSub.productId]["airEnquiryItemNumber"] = productSub.airEnquiryItemNumber ? productSub.airEnquiryItemNumber : "";
    //         tempProductsObject[productSub.productId]["airProposedItemNumber"] = productSub.airProposedItemNumber ? productSub.airProposedItemNumber : "";
    //         tempProductsObject[productSub.productId]['enquiryNumber'] = subenquiry.enquiryNumber;
    //         brandRefNumbersTemp[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(productSub.materialGroup)}`]}`]}`] = productSub.enquiryReferenceNumber;
    //       }
    //     });
    //   }
    // });
    // let finalArray = _.values(tempProductsObject);
    // finalArray = finalArray.map((item: any) => {
    //   item.action = "C";
    //   return item;
    // });
    productsTemp = productsTemp.map((item: any) => {
      item.action = "C";
      return item;
    });
    setbrandRefNumbers({ ...brandRefNumbersTemp });
    setproducts([...productsTemp]);
    setActualProductLength(productsTemp.length);
    setisUpdateMode(true);
    setisEnquirySaved(true);
  };

  const verifyEnquiryDataToEditMode = (enquiryDataFetched: any, enquiryDataFetchedGetCall?: any) => {
    let productsTemp: any = []
    if (enquiryDataFetchedGetCall && Array.isArray(enquiryDataFetchedGetCall)) {
      enquiryDataFetchedGetCall.map((subenquiry: any, index: any) => {
        if (subenquiry && subenquiry.products && Array.isArray(subenquiry.products)) {
          subenquiry.products.map((productSub: any) => {
            productSub["enquiryNumber"] = subenquiry.enquiryNumber;
            productSub["id"] = subenquiry.id;
            productsTemp.push(JSON.parse(JSON.stringify(productSub)));
          });
        }
      });
      productsTemp.map((item: any) => {
        item.action = "C";
        return item;
      });

    } else {
      productsTemp = JSON.parse(JSON.stringify(products));
    }
    productsTemp = productsTemp.filter((product: any) => product.isValid && !product.isSuperseeded);
    productsTemp = productsTemp.map((item: any) => {
      item["nowAvailability"] = 0;
      // item["nowAvailabilityChecked"] = false;
      item["airAvailability"] = 0;
      // item["airAvailabilityChecked"] = false;
      item["action"] = "C";
      return item;
    });
    if (enquiryDataFetched && Array.isArray(enquiryDataFetched)) {
      enquiryDataFetched.map((subEnquiries: any) => {
        if (
          subEnquiries &&
          subEnquiries["verifiedData"] &&
          subEnquiries["verifiedData"]["confirmations"] &&
          Array.isArray(subEnquiries["verifiedData"]["confirmations"])
        ) {
          subEnquiries["verifiedData"]["confirmations"].map((confirmationsingle: any) => {
            let isItemFound = false;
            productsTemp.map((item: any) => {
              if (item.productId == confirmationsingle.proposedArticle && item.nowEnquiryItemNumber == confirmationsingle.enquiryItemNumber) {
                isItemFound = true;
                if (
                  confirmationsingle &&
                  confirmationsingle["deliveryBucketId"] &&
                  confirmationsingle["deliveryBucketId"].toUpperCase() === "NOW"
                ) {
                  item["nowAvailability"] = parseInt(
                    confirmationsingle.availableQuantityforProposedArticle
                  );
                  item["nowAvailabilityChecked"] = item["nowAvailability"] ? true : false;
                } else {
                  item["airAvailability"] = parseInt(
                    confirmationsingle.availableQuantityforProposedArticle
                  );
                  item["airAvailabilityChecked"] = item["airAvailability"] ? true : false;
                }
                item["isSpecialPriced"] =
                  confirmationsingle.priceActivIndicator &&
                    confirmationsingle.priceActivIndicator === "X"
                    ? true
                    : false;
                item["rsp"] = parseFloat(
                  confirmationsingle.maximumRetailSalesPrice
                ).toFixed(2);
                item["nsp"] = parseFloat(
                  confirmationsingle.netSalesPrice
                ).toFixed(2);
                item["amt"] = parseFloat(
                  confirmationsingle.forwardOrderPrice
                ).toFixed(2);
                item["nspev"] = parseFloat(
                  confirmationsingle.netSellingPriceexcludingVAT ? confirmationsingle.netSellingPriceexcludingVAT : confirmationsingle.netSellingPriceExcludingVat
                ).toFixed(2);
                item["aop"] = parseFloat(
                  confirmationsingle.forwardOrderPrice
                ).toFixed(2);
                if (
                  confirmationsingle &&
                  confirmationsingle["deliveryBucketId"] &&
                  confirmationsingle["deliveryBucketId"].toUpperCase() === "NOW"
                ) {
                  item["nowEnquiryItemNumber"] = confirmationsingle.enquiryItemNumber ? confirmationsingle.enquiryItemNumber : "";
                  item["nowProposedItemNumber"] = confirmationsingle.proposedItemNumber ? confirmationsingle.proposedItemNumber : "";
                } else {
                  item["airEnquiryItemNumber"] = confirmationsingle.enquiryItemNumber ? confirmationsingle.enquiryItemNumber : "";
                  item["airProposedItemNumber"] = confirmationsingle.proposedItemNumber ? confirmationsingle.proposedItemNumber : "";
                }
              }
            })
            if (!isItemFound && confirmationsingle && confirmationsingle['supercessionMaterial'] && confirmationsingle['availableQuantityforProposedArticle'] && parseInt(confirmationsingle['availableQuantityforProposedArticle']) &&
              confirmationsingle["superceededPartIndicator"] &&
              confirmationsingle["superceededPartIndicator"].toUpperCase() === "X") {
              let materialGroup = ""
              let enquiryIdDatabase = ""
              let enquiryNumberDataBase = ""
              productsTemp.map((itemProd: any) => {
                if (itemProd.productId == confirmationsingle.supercessionMaterial) {
                  materialGroup = itemProd.materialGroup,
                    enquiryIdDatabase = itemProd.id
                  enquiryNumberDataBase = itemProd.enquiryNumber
                }
              })
              let newSupperSeededItem: any = {
                materialGroup: materialGroup,
                id: enquiryIdDatabase,
                enquiryNumber: enquiryNumberDataBase,
                isSuperseeded: true,
                quantity: 0,
                productId: confirmationsingle.proposedArticle,
                productIdDescription: confirmationsingle.materialDescription,
                productCost: 0,
                currency: "AED",
                unitOfMeasure: confirmationsingle.baseUnitofMeasure,
                VAT: "",
                orgId: "",
                enquiryReferenceNumber: "",
                isSpecialPriced: false,
                nowAvailability: 0,
                nowAvailabilityChecked: false,
                airAvailability: 0,
                airAvailabilityChecked: false,
                action: "I",
                dimensions: {
                  height: confirmationsingle.dimensionHeight,
                  width: confirmationsingle.dimensionWidth,
                  length: confirmationsingle.dimensionLength,
                  volume: confirmationsingle.proposedArticle,
                  grossWeight: confirmationsingle.proposedArticle
                },
                attributes: [
                  {
                    name: "height",
                    value: confirmationsingle.dimensionHeight,
                  },
                  {
                    name: "width",
                    value: confirmationsingle.dimensionHeight,
                  },
                  {
                    name: "length",
                    value: confirmationsingle.dimensionHeight,
                  },
                  {
                    name: "grossWeight",
                    value: confirmationsingle.dimensionHeight,
                  },
                  {
                    name: "volume",
                    value: 0,
                  },
                ],
                plant: "",
                nowEnquiryItemNumber: "",
                nowProposedItemNumber: "",
                airEnquiryItemNumber: "",
                airProposedItemNumber: "",
                isValid: true,
                supercessionMaterial: confirmationsingle.supercessionMaterial
              }
              if (
                confirmationsingle &&
                confirmationsingle["deliveryBucketId"] &&
                confirmationsingle["deliveryBucketId"].toUpperCase() === "NOW"
              ) {
                newSupperSeededItem["nowAvailability"] = parseInt(
                  confirmationsingle.availableQuantityforProposedArticle
                );
                newSupperSeededItem["quantity"] += parseInt(
                  confirmationsingle.availableQuantityforProposedArticle
                );
                newSupperSeededItem["nowAvailabilityChecked"] = newSupperSeededItem["nowAvailability"] ? true : false;
              } else {
                newSupperSeededItem["airAvailability"] = parseInt(
                  confirmationsingle.availableQuantityforProposedArticle
                );
                newSupperSeededItem["quantity"] = parseInt(
                  confirmationsingle.availableQuantityforProposedArticle
                );
                newSupperSeededItem["airAvailabilityChecked"] = newSupperSeededItem["airAvailability"] ? true : false;
              }
              newSupperSeededItem["isSpecialPriced"] =
                confirmationsingle.priceActivIndicator &&
                  confirmationsingle.priceActivIndicator === "X"
                  ? true
                  : false;
              newSupperSeededItem["rsp"] = parseFloat(
                confirmationsingle.maximumRetailSalesPrice
              ).toFixed(2);
              newSupperSeededItem["nsp"] = parseFloat(
                confirmationsingle.netSalesPrice
              ).toFixed(2);
              newSupperSeededItem["amt"] = parseFloat(
                confirmationsingle.forwardOrderPrice
              ).toFixed(2);
              newSupperSeededItem["nspev"] = parseFloat(
                confirmationsingle.netSellingPriceexcludingVAT ? confirmationsingle.netSellingPriceexcludingVAT : confirmationsingle.netSellingPriceExcludingVat
              ).toFixed(2);
              newSupperSeededItem["aop"] = parseFloat(
                confirmationsingle.forwardOrderPrice
              ).toFixed(2);
              if (
                confirmationsingle &&
                confirmationsingle["deliveryBucketId"] &&
                confirmationsingle["deliveryBucketId"].toUpperCase() === "NOW"
              ) {
                newSupperSeededItem["nowEnquiryItemNumber"] = confirmationsingle.enquiryItemNumber ? confirmationsingle.enquiryItemNumber : "";
                newSupperSeededItem["nowProposedItemNumber"] = confirmationsingle.proposedItemNumber ? confirmationsingle.proposedItemNumber : "";
              } else {
                newSupperSeededItem["airEnquiryItemNumber"] = confirmationsingle.enquiryItemNumber ? confirmationsingle.enquiryItemNumber : "";
                newSupperSeededItem["airProposedItemNumber"] = confirmationsingle.proposedItemNumber ? confirmationsingle.proposedItemNumber : "";
              }
              if (materialGroup) {
                productsTemp.push(newSupperSeededItem)
              }
            }
          });
        }
      });
    }
    // let finalArray = _.values(tempProductsObject);
    setproducts([...productsTemp]);
    setchangeData([...productsTemp]);
    setplaceorderEnabled(true)
  };

  useEffect(() => {
    if (changeData) {
      updateOnly(changeData);
      setchangeData(null);
    }
  }, [changeData]);

  const getEstimatedTotal = (withFilter?: any, withDeletion?: any) => {
    let productsTemp = JSON.parse(JSON.stringify(products))
    if (withFilter) {
      productsTemp = productsTemp.filter((item: any) => !isAvailabiityChecked ||
        (isAvailabiityChecked &&
          (availability === "All" ||
            (availability === "Now" &&
              item.nowAvailability &&
              !item.airAvailability) ||
            (availability === "BO" && item.airAvailability))))
    }

    if (isAvailabiityChecked && productsTemp && productsTemp.length > 0) {
      let total: any = 0;
      productsTemp.map((item: any) => {
        if (!withDeletion || (withDeletion && !item['selected'])) {
          if (item.nowAvailabilityChecked) {
            total += parseFloat(item.nowAvailability) * parseFloat(item.nsp);
          }
          if (item.airAvailabilityChecked) {
            total += parseFloat(item.airAvailability) * parseFloat(item.aop);
          }
        }
      });
      return parseFloat(total).toFixed(2);
    }
    return 0;
  };

  const saveUpdateEnquiry = async (shouldCheckAvailability: any, withDeletion?: any) => {
    let data: any = null;
    setIsQuantityUpdated(false);
    setActualProductLength(products.length);
    let productsTemp = JSON.parse(JSON.stringify(products));
    productsTemp = productsTemp.filter((product: any) => product.isValid);
    if (
      productsTemp &&
      Array.isArray(productsTemp) &&
      productsTemp.length > 0
    ) {
      let emptyRefNo = false;
      let emptyQuantity = false;
      productsTemp = productsTemp.map((item: any) => {
        if (
          !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`] ||
          !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].trim()
        ) {
          emptyRefNo = true;
        }
        if (referenceNumber === "") {
          emptyRefNo = true;
        }
        if (!parseInt(item.quantity)) {
          emptyQuantity = item.productId;
        }
        item["enquiryReferenceNumber"] = brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`];
        item["masterReferenceNumber"] = referenceNumber;
        return item;
      });
      if (emptyRefNo) {
        setOpenSnackbarType("error");
        setSuccessMessage(`All reference numbers are mandatory`);
        handleopenCloseSnackbar(true);
        return;
      }
      if (emptyQuantity) {
        setOpenSnackbarType("error");
        setSuccessMessage(
          `Quantity of product ${emptyQuantity} should be greater than zero`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      setproducts(JSON.parse(JSON.stringify(productsTemp)));
      let preventGetCall = false;
      if (!isEnquirySaved) {
        data = await dispatch(createEnquiry(referenceNumber, productsTemp, firstName, b2cUserId, enquirySessionId));
      } else {
        let filteredItemNos: any = []
        productsTemp.map((item: any) => {
          if (withDeletion) {
            if (item['selected']) {
              filteredItemNos.push({
                productId: item.productId,
                itemNumber: item.nowEnquiryItemNumber || item.airEnquiryItemNumber
              })
              item["action"] = "D"
            }
          }
          // item["nowAvailabilityChecked"] = false;
          // item["airAvailabilityChecked"] = false;
          if (item.nowAvailabilityChecked) {
            item["productCost"] = 0;
          }
          if (item.airAvailabilityChecked) {
            item["productCost"] = 0;
          }
          return item
        })
        productsTemp = productsTemp.filter((item: any) => {
          let isNotDeleted = true
          filteredItemNos.map((part: any) => {
            if (item.isSuperseeded && item.supercessionMaterial == part.productId && (part.itemNumber == item.nowEnquiryItemNumber || part.itemNumber == item.nowEnquiryItemNumber)) {
              isNotDeleted = false
            }
          })
          return isNotDeleted
        })
        let updationData: any = {};
        let insertData: any = [];
        productsTemp.map((singleProduct: any) => {
          if (singleProduct["id"]) {
            if (updationData[singleProduct["id"]]) {
              updationData[singleProduct["id"]].push(singleProduct);
            } else {
              updationData[singleProduct["id"]] = [singleProduct];
            }
          } else {
            insertData.push(singleProduct)
          }
        });
        let finalData: any = [];
        Object.keys(updationData).map((singlekey: any) => {
          finalData.push({
            id: singlekey,
            masterReferenceNumber: referenceNumber,
            products: updationData[singlekey],
            totalCost: getEstimatedTotal(null, withDeletion)
          });
        });
        let enquiryDetailsQueueCheckData: any;
        if (checkAvailabilityButtonText.toLowerCase() !== CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL.toLowerCase()) {
          //Trigger verification queued check before attempting update
          enquiryDetailsQueueCheckData = await dispatch(verifyEnquiryQueueCheck(enquiryData[0].B2bEnquiryId));
          //Error enquiryDetailsQueueCheckData.message.status == "E"
          if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.status && enquiryDetailsQueueCheckData.message.status == "E") {
            setOpenSnackbarType("error");
            setSuccessMessage(enquiryDetailsQueueCheckData.message.message);
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
          //Queued enquiryDetailsQueueCheckData.message.message === "verification queued"
          else if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message && enquiryDetailsQueueCheckData.message.message.toLowerCase() == "verification queued") {
            setOpenSnackbarType("error");
            setSuccessMessage('Enquiry queued for verification, press the verification button after 10 seconds');
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
          //In Progress enquiryDetailsQueueCheckData.message.message === "verification in progress"
          else if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message && enquiryDetailsQueueCheckData.message.message.toLowerCase() == "verification in progress") {
            setOpenSnackbarType("error");
            setSuccessMessage('Enquiry verification in progress, press the verification button after 10 seconds');
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.PROGRESS);
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
          //In Progress enquiryDetailsQueueCheckData.message.message === "enquiry changed"
          else if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message && enquiryDetailsQueueCheckData.message.message.toLowerCase() == "enquiry changed") {
            setOpenSnackbarType("error");
            setSuccessMessage('Enquiry changed, press the verification button after 10 seconds');
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.PROGRESS);
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
          //Success enquiryDetailsQueueCheckData.message.message === "verification completed"
          else if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message && enquiryDetailsQueueCheckData.message.message.toLowerCase() == "verification completed") {
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL);
            //should be removed and format should be updated on api side
            let verificationData: any = []
            let hasConfirmationData = true;
            if (enquiryDetailsQueueCheckData && Array.isArray(enquiryDetailsQueueCheckData.data)) {
              enquiryDetailsQueueCheckData.data.map((item: any) => {
                if (item && item.confirmations && Array.isArray(item.confirmations) && item.confirmations.length > 0) {
                  verificationData.push({
                    verifiedData: item
                  })
                } else {
                  hasConfirmationData = false;
                }
              })
            }
            if (hasConfirmationData) {
              //should be removed and format should be updated on api side
              setisAvailabiityChecked(true);
              setisAvailabiityCheckedAtTime(new Date());
              preventGetCall = true;
              let savedData: any = await dispatch(getEnquiryDetails(
                enquiryData &&
                  Array.isArray(enquiryData) &&
                  enquiryData.length > 0 &&
                  enquiryData[0].B2bEnquiryId
                  ? enquiryData[0].B2bEnquiryId
                  : data.data.masterId
              ));
              verifyEnquiryDataToEditMode(verificationData, savedData.data.data)
              setSuccessMessage(enquiryDetailsQueueCheckData.message.message);
              handleopenCloseSnackbar(true);
              return;
            } else {
              setOpenSnackbarType("error");
              setSuccessMessage('Enquiry verification in progress, press the verification button after 10 seconds');
              handleopenCloseSnackbar(true);
              setplaceorderEnabled(false);
              return;
            }
          }
          else {
            setOpenSnackbarType("error");
            setSuccessMessage(enquiryDetailsQueueCheckData.message.message);
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.PROGRESS);
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
        }
        data = await dispatch(
          updateEnquiry(enquiryData[0].B2bEnquiryId, finalData, false, insertData, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp()
          )
        );
        if (!(data && data.data && Array.isArray(data.data))) {
          setOpenSnackbarType("error");
          setSuccessMessage(data && data.error ? data.error : `Order Enquiry Update Failed`);
          handleopenCloseSnackbar(true);
          return
        }
      }
      if (
        ((data && data.data && data.data.masterId) ||
          (isEnquirySaved && enquiryData[0].B2bEnquiryId)) && !preventGetCall
      ) {
        let savedData: any = await dispatch(getEnquiryDetails(
          enquiryData &&
            Array.isArray(enquiryData) &&
            enquiryData.length > 0 &&
            enquiryData[0].B2bEnquiryId
            ? enquiryData[0].B2bEnquiryId
            : data.data.masterId
        ));
        if (
          savedData &&
          savedData.data &&
          savedData.data.data &&
          savedData.data.data[0]
        ) {
          setenquiryData(savedData.data.data);
          if (!isEnquirySaved) {
            setisUpdateMode(true);
            setisEnquirySaved(true);
            setActualProductLength(savedData.data.data[0].products.length)
          }
          if (!shouldCheckAvailability) {
            enquiryDataToEditMode(savedData.data.data, false, withDeletion);
            setSuccessMessage(
              isEnquirySaved
                ? `Order Enquiry Updated successfully ${savedData.data.data[0] &&
                savedData.data.data[0].B2bEnquiryId
                }`
                : `Order Enquiry saved successfully ${savedData.data.data[0] &&
                savedData.data.data[0].B2bEnquiryId
                }`
            );
            handleopenCloseSnackbar(true);
          }
        } else {
          setOpenSnackbarType("error");
          setSuccessMessage(
            `Order Enquiry ${!isEnquirySaved ? "Creation" : "Update"} Failed`
          );
          handleopenCloseSnackbar(true);
        }
        if (
          shouldCheckAvailability &&
          ((savedData &&
            savedData.data &&
            savedData.data.data[0] &&
            savedData.data.data[0].B2bEnquiryId) ||
            (enquiryData && enquiryData[0].B2bEnquiryId))
        ) {
          let availData: any = await dispatch(
            verifyEnquiry(
              (enquiryData &&
                Array.isArray(enquiryData) &&
                enquiryData[0].B2bEnquiryId
                ? enquiryData[0].B2bEnquiryId
                : savedData &&
                savedData.data &&
                savedData.data.data[0] &&
                savedData.data.data[0].B2bEnquiryId), enquirySessionId
            )
          );
          if (
            availData &&
            availData.data &&
            Array.isArray(availData.data) &&
            availData.data.length > 0
          ) {
            if (availData.message.status !== 'I') {
              setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL)
              setisAvailabiityChecked(true);
              setisAvailabiityCheckedAtTime(new Date());
              verifyEnquiryDataToEditMode(availData.data, savedData.data.data);
              setSuccessMessage(`Availability and Price successfully fetched`);
              handleopenCloseSnackbar(true);
            } else {
              //Set Check Availability Button to show verification queued if check data is not verified.
              setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.QUEUED)
              setOpenSnackbarType("error");
              setSuccessMessage('Enquiry queued for verification, press the verification button after 10 seconds');
              handleopenCloseSnackbar(true);
              setplaceorderEnabled(false);
            }
          } else {
            enquiryDataToEditMode(savedData.data.data, true, false, false);
            setOpenSnackbarType("error");
            setSuccessMessage(availData.error);
            handleopenCloseSnackbar(true);
          }
        }
      }
    } else {
      setOpenSnackbarType("error");
      setSuccessMessage(`No valid are parts present`);
      handleopenCloseSnackbar(true);
      return;
    }
    if (!shouldCheckAvailability) setplaceorderEnabled(false)
  };

  const updateOnly = async (productsData?: any) => {
    let productsTemp = JSON.parse(JSON.stringify(productsData));
    productsTemp = productsTemp.filter((product: any) => product.isValid);
    let emptyRefNo = false;
    let emptyQuantity = false;
    productsTemp = productsTemp.map((item: any) => {
      if (
        !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`] ||
        !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].trim()
      ) {
        emptyRefNo = true;
      }
      if (referenceNumber === "") {
        emptyRefNo = true;
      }
      if (!parseInt(item.quantity)) {
        emptyQuantity = item.productId;
      }
      item['enquiryReferenceNumber'] = brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`];
      item['masterReferenceNumber'] = referenceNumber;
      return item
    })
    if (emptyRefNo) {
      setOpenSnackbarType("error");
      setSuccessMessage(`All reference numbers are mandatory`);
      handleopenCloseSnackbar(true);
      return;
    }
    if (emptyQuantity) {
      setOpenSnackbarType("error");
      setSuccessMessage(
        `Quantity of product ${emptyQuantity} should be greater that zero`
      );
      handleopenCloseSnackbar(true);
      return;
    }
    // let tempProductsObject: any = null;
    // tempProductsObject = _.keyBy(productsTemp, 'productId');
    // enquiryData.map((subenquiry: any) => {
    //   if (
    //     subenquiry &&
    //     subenquiry.products &&
    //     Array.isArray(subenquiry.products)
    //   ) {
    //     subenquiry.products.map((productSub: any) => {
    //       if (!_.isEmpty(_.get(productSub, "productId", ""))) {
    //         let subMake = subenquiry && subenquiry["make"] && subenquiry["make"].toLowerCase();
    //         let materialGroupFetched = _.get(tempProductsObject, `${productSub.productId}.materialGroup`, "")
    //         let apiMake = enquiryMapping[`${formatMaterialGroup(materialGroupFetched)}`] ? enquiryMapping[`${formatMaterialGroup(materialGroupFetched)}`].toLowerCase() : ''
    //         if (_.has(tempProductsObject, productSub.productId) && subMake === apiMake) {
    //           tempProductsObject[productSub.productId]["enquiryNumber"] = subenquiry.enquiryNumber;
    //           tempProductsObject[productSub.productId]["id"] = subenquiry.id;
    //           tempProductsObject[productSub.productId]["action"] = "C";
    //           tempProductsObject[productSub.productId]["productCost"] = 0;
    //           if (tempProductsObject[productSub.productId].nowAvailabilityChecked) {
    //             tempProductsObject[productSub.productId]["productCost"] +=
    //               (tempProductsObject[productSub.productId].nowAvailability ? parseFloat(tempProductsObject[productSub.productId].nowAvailability) : 0) *
    //               (tempProductsObject[productSub.productId].nsp ? parseFloat(tempProductsObject[productSub.productId].nsp) : 0);
    //           }
    //           if (tempProductsObject[productSub.productId].airAvailabilityChecked) {
    //             tempProductsObject[productSub.productId]["productCost"] +=
    //               (tempProductsObject[productSub.productId].airAvailability ? parseFloat(tempProductsObject[productSub.productId].airAvailability) : 0) *
    //               (tempProductsObject[productSub.productId].aop ? parseFloat(tempProductsObject[productSub.productId].aop) : 0);
    //           }
    //           // tempProductsObject[productSub.productId]["nowAvailabilityChecked"] = false;
    //           // tempProductsObject[productSub.productId]["airAvailabilityChecked"] = false;
    //         }
    //       }
    //     });
    //   }
    // });
    // productsTemp = _.values(tempProductsObject);
    let updationData: any = {};
    let insertData: any = [];
    productsTemp.map((singleProduct: any) => {
      if (singleProduct["id"]) {
        if (updationData[singleProduct["id"]]) {
          updationData[singleProduct["id"]].push(singleProduct);
        } else {
          updationData[singleProduct["id"]] = [singleProduct];
        }
      } else {
        insertData.push(singleProduct)
      }
    });
    let finalData: any = [];
    Object.keys(updationData).map((singlekey: any) => {
      finalData.push({
        id: singlekey,
        masterReferenceNumber: referenceNumber,
        products: updationData[singlekey],
        totalCost: getEstimatedTotal(),
        verifiedOn: isAvailabiityCheckedAtTime
      })
    })
    await dispatch(updateEnquiry(enquiryData[0].B2bEnquiryId, finalData, true, insertData, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber, enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp()));
    let savedData: any = await dispatch(getEnquiryDetails(enquiryData[0].B2bEnquiryId));
    enquiryDataToEditMode(savedData.data.data, true, false, false);
    setenquiryData(savedData.data.data);
  };

  const getCounter = () => {
    if (isAvailabiityCheckedAtTime) {
      var now = moment(new Date());
      var start = moment(new Date(isAvailabiityCheckedAtTime));
      var duration: any = moment.duration(start.diff(now));
      var minutes: any = Math.abs(duration.asMinutes());
      if (
        !isAvailabiityCheckedAtTime ||
        (minutes && minutes > 5)
      ) {
        return <>
          <br />
          <span className="text-danger">
            (Availability Expired)
          </span>
        </>
      } else {
        return <>
          <br />
          <span>
            (Please submit your order in <Countdown date={isAvailabiityCheckedAtTime.getTime() + 300000} renderer={renderer} />)
          </span>
        </>
      }
    } else {
      return null
    }
  }


  const placeOrderHandler = async () => {
    if (!orderQueued) {
      var now = moment(new Date());
      var start = moment(
        isAvailabiityCheckedAtTime
          ? new Date(isAvailabiityCheckedAtTime)
          : new Date()
      );
      var duration: any = moment.duration(start.diff(now));
      var minutes: any = Math.abs(duration.asMinutes());
      if (
        !isAvailabiityCheckedAtTime ||
        (minutes && minutes > 5)
      ) {
        setOpenSnackbarType("error");
        setSuccessMessage(
          "Availability Expired: Please check availability again"
        );
        handleopenCloseSnackbar(true);
        return;
      }
      let productsTemp = JSON.parse(
        JSON.stringify(products)
      );
      productsTemp = productsTemp.filter(
        (product: any) => product.isValid
      );
      let emptyRefNo = false;
      let emptyQuantity = false;
      let itemsSelected = false;
      productsTemp = productsTemp.map((item: any) => {
        if (
          !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`] ||
          !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].trim()
        ) {
          emptyRefNo = true;
        }
        if (referenceNumber === "") {
          emptyRefNo = true;
        }
        if ((item.nowAvailabilityChecked && item.nowAvailability > 0) || (item.airAvailabilityChecked && item.airAvailability > 0)) {
          itemsSelected = true;
        }
        if (!parseInt(item.quantity)) {
          emptyQuantity = item.productId;
        }
        item["enquiryReferenceNumber"] =
          brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`];
        item["masterReferenceNumber"] = referenceNumber;
        return item;
      });
      if (emptyRefNo) {
        setOpenSnackbarType("error");
        setSuccessMessage(
          `All reference numbers are mandatory`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      if (emptyQuantity) {
        setOpenSnackbarType("error");
        setSuccessMessage(
          `Quantity of product ${emptyQuantity} should be greater that zero`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      if (!itemsSelected) {
        setOpenSnackbarType("error");
        setSuccessMessage(
          `Please select atleast one item with quantity greater than 0 to proceed.`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      productsTemp = productsTemp.map((item: any) => {
        enquiryData.map((subenquiry: any) => {
          if (
            subenquiry &&
            subenquiry.products &&
            Array.isArray(subenquiry.products)
          ) {
            subenquiry.products.map((productSub: any) => {
              if (
                (productSub.productId && productSub.productId.toLowerCase() === item.productId.toLowerCase()) ||
                subenquiry["make"] && subenquiry["make"].toLowerCase() ===
                enquiryMapping[
                  `${formatMaterialGroup(item.materialGroup)}`
                ].toLowerCase()
              ) {
                item["enquiryNumber"] =
                  subenquiry.enquiryNumber;
                item["id"] = subenquiry.id;
                item["action"] = "C";
                item["productCost"] = 0;
                if (item.nowAvailabilityChecked) {
                  item["productCost"] +=
                    (item.nowAvailability
                      ? parseFloat(item.nowAvailability)
                      : 0) *
                    (item.nsp ? parseFloat(item.nsp) : 0);
                }
                if (item.airAvailabilityChecked) {
                  item["productCost"] +=
                    (item.airAvailability
                      ? parseFloat(item.airAvailability)
                      : 0) *
                    (item.aop ? parseFloat(item.aop) : 0);
                }
              }
            });
          }
        });
        return item;
      });

      let updationData: any = {};
      productsTemp.map((singleProduct: any) => {
        if (singleProduct["id"]) {
          if (updationData[singleProduct["id"]]) {
            updationData[singleProduct["id"]].push(
              singleProduct
            );
          } else {
            updationData[singleProduct["id"]] = [
              singleProduct,
            ];
          }
        }
      });
      let finalData: any = [];
      Object.keys(updationData).map((singlekey: any) => {
        finalData.push({
          id: singlekey,
          masterReferenceNumber: referenceNumber,
          products: updationData[singlekey],
          totalCost: getEstimatedTotal(),
        });
      });
      await dispatch(
        updateEnquiry(
          enquiryData[0].B2bEnquiryId,
          finalData,
          false,
          null,
          enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber,
          enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp(),
          true
        )
      );
      let placeoRederData: any = await dispatch(
        placeOrder(enquiryData[0].B2bEnquiryId, firstName, b2cUserId, enquirySessionId)
      );

      if (placeoRederData && placeoRederData.message.status && (placeoRederData.message.status == "I" || placeoRederData.message.status == "E")) {
        setorderQueued(placeoRederData.message.status == 'I' ? true : false);
        setOpenSnackbarType("error");
        setSuccessMessage(placeoRederData && placeoRederData.message.message ? placeoRederData.message.message : "Unable to place order");
        handleopenCloseSnackbar(true);
        if (placeoRederData.message && placeoRederData.message.message && placeoRederData.message.message.includes("does not allow external procurement")) {
          let wordsArray = placeoRederData.message.message.split(" ")
          let indexPart = wordsArray.indexOf("material");
          let procuredPartTemp = indexPart >= 0 ? wordsArray[indexPart + 1] : "";
          setprocuredPart(procuredPartTemp)
        }
      }
      else if (
        placeoRederData &&
        placeoRederData.data &&
        Array.isArray(placeoRederData.data)
      ) {
        setSuccessMessage(`Order successfully placed`);
        handleopenCloseSnackbar(true);
        setorderData(placeoRederData.data);
        setActualProductLength(products.length);
        setIsQuantityUpdated(false);
        setorderTotal(placeoRederData.data[0].orderTotal);
        handleopenCloseOrderSummary(true);
      }
    } else {
      let enquiryDetailsQueueCheckData: any = await dispatch(verifyEnquiryQueueCheck(enquiryData[0].B2bEnquiryId));
      if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.status && enquiryDetailsQueueCheckData.message.status == "S" && enquiryDetailsQueueCheckData.message.message == "Order Created") {
        setSuccessMessage(`Order successfully placed`);
        handleopenCloseSnackbar(true);
        if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.data && Array.isArray(enquiryDetailsQueueCheckData.data) && enquiryDetailsQueueCheckData.data.length > 0) {
          // setorderData(enquiryDetailsQueueCheckData.data[0].orderSummary);
          let summaryData: any = [];
          enquiryDetailsQueueCheckData.data.map((item: any) => {
            summaryData = [...summaryData, ...item.orderSummary]
          })

          let total = 0;
          enquiryDetailsQueueCheckData.data && Array.isArray(enquiryDetailsQueueCheckData.data) && enquiryDetailsQueueCheckData.data.map((item: any) => {
            if (item.brandTotal) {
              total += parseFloat(item.brandTotal)
            }
          })

          setorderTotal(total)
          setorderData(summaryData);
        }
        handleopenCloseOrderSummary(true);
      } else {
        if (enquiryDetailsQueueCheckData.message.message.toLowerCase() == 'order placement failed') {
          setorderQueued(false)
        }
        setOpenSnackbarType("error");
        setSuccessMessage(enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message);
        handleopenCloseSnackbar(true);
      }
    }
  }

  const showDownloadEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.DOWNLOAD_ENQUIRY_EXCEL);
  const showPlaceOrder = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.PLACE_ORDER);
  const showStockEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.STOCK_ENQUIRY);
  const showUpdateEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.UPDATE_ENQUORY);

  return (
    <Fragment>
      {((enquiryInfo && enquiryInfo.loading) || (favoritesList && favoritesList.loading)) && <FullscreenLoader />}
      <Snackbar
        open={openSnackbar}
        type={openSnackbarType}
        message={successMessage}
        handleopenClose={() => {
          handleopenCloseSnackbar(false);
          setOpenSnackbarType("");
        }}
      />
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          <span>
            {isUpdateMode
              ? enquiryAction === "view"
                ? "View"
                : "Update"
              : "New"}{" "}
            Order Enquiry{" "}
            {isEnquirySaved && (
              <span className="font-size-13">
                {isUpdateMode &&
                  enquiryData &&
                  enquiryData[0] &&
                  `(${enquiryData[0].B2bEnquiryId})`}
              </span>
            )}
          </span>
        </h2>

        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      {enquiryAction !== "view" && (
        <div className="row mt-4">
          <div className="col-12">
            <div className="cardCommon p-3">
              <div className="d-flex align-items-center">
                <h5 className="page-sub-title mb-2">Search Parts</h5>
                <div className="ml-auto">
                  <ViewFavorite commonInsertionLogic={commonInsertionLogic} />
                  {showImportExcel &&
                    <ImportDialog
                      isOpened={() => {
                        sessionStorage.setItem(
                          "tempProducts",
                          JSON.stringify(products ? products : [])
                        );
                      }}
                      commonInsertionLogic={(jsonData: any) => {
                        commonInsertionLogic(jsonData, true);
                      }}
                    />
                  }
                </div>
              </div>
              <TextField
                onChange={(e) => setproductId(e.target.value)}
                onPaste={(e) => {
                  let clipData: any = e.clipboardData.getData("Text");
                  let crlf: any =
                    String.fromCharCode(13) + String.fromCharCode(10);
                  let clipRows: any = clipData.split(String.fromCharCode(13));
                  let jsonData: any = [];
                  for (let iRow: any = 0; iRow < clipRows.length - 1; iRow++) {
                    let productId = clipRows[iRow].split(
                      String.fromCharCode(9)
                    )[0];
                    var quantity = isNumeric(
                      clipRows[iRow].split(String.fromCharCode(9))[1]
                    )
                      ? clipRows[iRow].split(String.fromCharCode(9))[1]
                      : 1;
                    jsonData.push({
                      productId: productId.replace(/(\r\n|\n|\r)/gm, ""),
                      quantity: quantity && quantity.toString() && quantity.toString().slice(0, 5),
                    });
                  }
                  if (
                    jsonData &&
                    Array.isArray(jsonData) &&
                    jsonData.length > 0
                  )
                    commonInsertionLogic(jsonData);
                }}
                value={productId}
                onKeyDown={(e: any) => {
                  if (e.keyCode == 13) {
                    if (productId && productId.trim()) {
                      commonInsertionLogic([
                        {
                          productId: productId.trim(),
                          quantity:
                            quantity &&
                              parseInt(quantity) &&
                              parseInt(quantity) >= 1
                              ? parseInt(quantity)
                              : 1,
                        },
                      ]);
                    }
                  }
                }}
                size="small"
                className="col-sm-12 col-md-6 col-ml-7 col-xl-8 pr-3 commonRoundedInputs common-input-font-size"
                label="Search By Part Number"
                variant="outlined"
              />
              <TextField
                type="text"
                onChange={(e) => {
                  let value = e.target.value && parseInt(e.target.value) && /^[+]?\d+([.]\d+)?$/.test(e.target.value) &&
                    parseInt(e.target.value) >= 1
                    ? parseInt(e.target.value)
                    : "";
                  setquantity(value);
                }}
                onKeyDown={(e: any) => {
                  if (e.keyCode == 13) {
                    if (productId && productId.trim()) {
                      commonInsertionLogic([
                        {
                          productId: productId.trim(),
                          quantity:
                            quantity &&
                              parseInt(quantity) &&
                              parseInt(quantity) >= 1
                              ? parseInt(quantity)
                              : 1,
                        },
                      ]);
                    }
                  }
                }}
                value={quantity}
                size="small"
                className="col-sm-6 col-md-3 col-ml-2 col-xl-2 pr-3 commonRoundedInputs common-input-font-size"
                label="Quantity"
                inputProps={{ maxLength: 5 }}
                variant="outlined"
              />
              <Button
                className="col-sm-6 col-md-3 col-ml-3 col-xl-2 font-12 addOrderEnqButton"
                size="small"
                onClick={async () => {
                  if (productId && productId.trim()) {
                    commonInsertionLogic([
                      {
                        productId: productId.trim(),
                        quantity:
                          quantity &&
                            parseInt(quantity) &&
                            parseInt(quantity) >= 1
                            ? parseInt(quantity)
                            : 1,
                      },
                    ]);
                  }
                }}
                variant="contained"
                color="primary"
              >
                Add to Order Enquiry
              </Button>
            </div>
          </div>
        </div>
      )}

      <div className="row mt-4">
        <div className="col-12">
          <div className="d-flex align-items-center mb-2">
            <h5 className="page-sub-title">
              Order Enquiry Item List
              <input
                value={referenceNumber}
                maxLength={20}
                onChange={(e) => {
                  if (enquiryAction !== "view") {
                    setreferenceNumber(e.target.value ? e.target.value : "");
                    setbrandRefNumbers({
                      "2001": e.target.value ? e.target.value : "",
                      "2002": e.target.value ? e.target.value : "",
                      "2003": e.target.value ? e.target.value : "",
                      "2380": e.target.value ? e.target.value : "",
                    });
                  }
                }}
                type="text"
                className=" ml-3 referenceNumberText"
                placeholder="Your reference number"
              />
              <span className="ml-3 totalCountTextSmallest">Total parts {products ? products.length : 0}</span>
              {enquiryAction === "view" &&
                enquiryData &&
                Array.isArray(enquiryData) &&
                enquiryData.length > 0 &&
                ((enquiryData[0].status && enquiryData[0].status.toLowerCase() === "enquiry cancelled") ||
                  (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "cancel")) && (
                  <span className="text-danger ml-3">
                    Order Enquiry Cancelled
                  </span>
                )}
            </h5>
            <div className="ml-auto searchbarOptions">
              {enquiryAction !== "view" && (
                <>
                  <span className="mr-2 commonRoundedInputs">
                    {isAvailabiityChecked && isAvailabiityCheckedAtTime && (
                      <span className="offer px-2 font-11 float-left">
                        <span>
                          Verified At -{" "}
                          {moment(isAvailabiityCheckedAtTime).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                        {checkAvailabilityButtonText === CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL && !orderQueued && getCounter()}
                      </span>
                    )}
                    <TextField
                      id="availabilityType"
                      select
                      label="Availability Type"
                      variant="outlined"
                      className="availabilityDropdown"
                      size="small"
                      value={availability}
                      onChange={handleAvailabilityChange}
                    >
                      <MenuItem key={1} value="All">
                        All
                      </MenuItem>
                      <MenuItem key={2} value="Now">
                        Now
                      </MenuItem>
                      <MenuItem key={3} value="BO">
                        BO
                      </MenuItem>
                    </TextField>
                  </span>
                  <span
                    className="mr-2 cursor-pointer"
                    onClick={() => {
                      let hasDeletions = false;
                      let selected: any = [];
                      let productsTemp: any = JSON.parse(
                        JSON.stringify(products)
                      );
                      productsTemp = productsTemp.filter(
                        (item: any) => {
                          if (item.selected) {
                            selected.push(item.productId)
                            hasDeletions = true;
                          }
                          return !item.selected
                        }
                      );
                      if (isEnquirySaved) {
                        if (hasDeletions) {
                          saveUpdateEnquiry(false, true)
                        }
                      } else {
                        setproducts([...productsTemp]);
                        dispatch(deleteLostSaleReport(selected, enquirySessionId))
                      }
                    }}
                  >
                    <IconButton className="mr-1 mt-2" size="small">
                      <DeleteIcon fontSize="inherit" />
                      <span className="font-size-13 load-from-fav ml-1">
                        Delete
                      </span>
                    </IconButton>
                  </span>
                </>
              )}
              {showDownloadEnquiry && products.length > 0 &&
                <span className="cursor-pointer" onClick={() => exportData()}>
                  <IconButton className="mr-1 mt-2" size="small">
                    <GetAppIcon fontSize="inherit" />
                    <span className="font-size-13 load-from-fav ml-1">
                      Export Excel
                    </span>
                  </IconButton>
                </span>
              }
              {enquiryAction === "view" && (
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <IconButton className="mr-1 mt-2" size="small">
                    <ArrowBackIosIcon fontSize="inherit" />
                    <span className="font-size-13 load-from-fav">
                      Back to List
                    </span>
                  </IconButton>
                </span>
              )}
            </div>
          </div>
          <div className="cardCommon">
            <div className="enquiryDetails" unselectable="on">
              <div className="customTableContainerCreateEnquiry">
                <div className="tableRootCreateEnquiry">
                  <thead className="tableHeadCreateEnquiry">
                    <tr className="trCreateEnquiry">
                      <th className="thCreateEnquiry w-60p pr-0">
                        <input type="checkbox"
                          checked={isSelectedAll}
                          onChange={() => {
                            if (enquiryAction !== "view") {
                              let productsTemp: any = JSON.parse(
                                JSON.stringify(products)
                              );
                              productsTemp = productsTemp.map((item: any) => {
                                item.selected = !isSelectedAll;
                                return item;
                              });
                              setproducts([...productsTemp]);
                              setisSelectedAll(!isSelectedAll);
                            }
                          }}
                        />
                      </th>
                      <th className="thCreateEnquiry">
                        <h6 className="blueLabelTable">Part No.</h6>
                        <div className="greyLabelTable">
                          Part Name &amp; Desc
                        </div>
                      </th>
                      <th className="thCreateEnquiry">
                        <h6 className="blueLabelTable">Quantity</h6>
                        <div className="row no-gutters">
                          <div className="col-6 greyLabelTable">Qty</div>
                          <div className="col-6 greyLabelTable">Avl</div>
                        </div>
                      </th>
                      <th className="thCreateEnquiry font-10 font-weight-700 text-444">
                        <h6 className="blueLabelTable">Price</h6>RSP
                      </th>
                      <th className="thCreateEnquiry font-10 font-weight-700 text-444"><h6>&nbsp;</h6>NSP (Incl. VAT)</th>
                      <th className="thCreateEnquiry font-10 font-weight-700 text-444"><h6>&nbsp;</h6>Amt</th>
                      <th className="thCreateEnquiry font-10 font-weight-700 text-444"><h6>&nbsp;</h6>NSP (Excl. VAT)</th>
                      <th className="thCreateEnquiry font-10 font-weight-700 text-444"><h6>&nbsp;</h6>AOP</th>
                      <th className="thCreateEnquiry mw-150p">
                        <h6 className="blueLabelTable">Availability</h6>
                        <div className="row no-gutters">
                          <div className="col-6 greyLabelTable">
                            <input type="checkbox"
                              value={checkedAllNow}
                              onChange={() => {
                                if (enquiryAction !== "view") {
                                  let productsTemp: any = JSON.parse(
                                    JSON.stringify(products)
                                  );
                                  productsTemp = productsTemp.map(
                                    (item: any) => {
                                      item.nowAvailabilityChecked =
                                        !!!checkedAllNow;
                                      return item;
                                    }
                                  );
                                  setproducts([...productsTemp]);
                                  setcheckedAllNow(!checkedAllNow);
                                }
                              }}
                              className="checkbox small-check mr-1"
                            // size="small"
                            />
                            Now
                          </div>
                          <div className="col-6 greyLabelTable">
                            <input type="checkbox"
                              value={checkedAllBO}
                              onChange={() => {
                                if (enquiryAction !== "view") {
                                  let productsTemp: any = JSON.parse(
                                    JSON.stringify(products)
                                  );
                                  productsTemp = productsTemp.map(
                                    (item: any) => {
                                      item.airAvailabilityChecked =
                                        !!!checkedAllBO;
                                      return item;
                                    }
                                  );
                                  setproducts([...productsTemp]);
                                  setcheckedAllBO(!checkedAllBO);
                                }
                              }}
                              className="checkbox small-check mr-1"
                            // size="small"
                            />
                            BO
                          </div>
                        </div>
                      </th>
                      <th className="thCreateEnquiry">
                        <h6 className="blueLabelTable">Dimension</h6>
                        <div className="greyLabelTable">
                          L x W x H(mm) | W(gm)
                        </div>
                      </th>
                      <th className="thCreateEnquiry">
                        <h6 className="blueLabelTable">Volume</h6>
                        <div className="greyLabelTable">(Per Unit)</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderRows()}</tbody>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row tableBottom">
                <div className="col-lg-3 col-md-3 col-sm-6 p-3 borderRightBottom">
                  <p className="bottomFieldNameTable">
                    Estimated Total (incl. of VAT)
                  </p>
                  <p className="bottomFieldValueTable ">
                    AED {parseFloat(getSellingPrice(getEstimatedTotal(true), isSellingPriceData, markupPercentage)).toFixed(2)}
                  </p>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 p-3 borderRightBottom">
                  <div className="d-flex">
                    <div>
                      <p className="bottomFieldNameTable">Office Address</p>
                      <p className="bottomFieldValueTable">
                        {address ? address : "-"}
                      </p>
                    </div>
                    {/* <IconButton aria-label="expand row" size="small">
                      <KeyboardArrowDownIcon />
                    </IconButton> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 p-3 pb-0 borderBottom d-flex justify-content-end">
                  {enquiryAction !== "view" && (
                    <>
                      <NavigationPrompt when={(products.length > 0 && !isEnquirySaved) || (isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated)}>
                        {({ onConfirm, onCancel }) => (
                          <React.Fragment>
                            <Dialog
                              disableBackdropClick={true}
                              open={true}
                              fullWidth={true}
                              maxWidth={'sm'}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogContent>
                                <div className={classes.icon}>
                                  <WarningIcon className="delete-warning" fontSize="small" />
                                </div>
                                <div>
                                  <DialogContentText id="alert-dialog-description">
                                    <div className={`mt-3 ${classes.logoutText}`}>
                                      {(isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated)
                                        ? 'Your enquiry is not updated, please press Yes to Update your enquiry.'
                                        : 'Your enquiry is not saved, please press Yes to Save your enquiry.'}
                                    </div>
                                  </DialogContentText>
                                </div>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  className={`mx-4 ${classes.yes}`}
                                  onClick={async () => {
                                    saveUpdateEnquiry(false);
                                    onCancel();
                                  }}>Yes</Button>
                                <Button
                                  className={`mr-4 ${classes.cancel}`}
                                  onClick={() => {
                                    if (isEnquirySaved && products.length !== actualProductLength) {
                                    } else {
                                      let partsList: any = products.map((item: any) => {
                                        return item.productId.trim();
                                      });
                                      let searchProduct: any = [];
                                      products.map((item: any) => {
                                        searchProduct.push({ productId: item.productId.trim(), quantity: item.quantity })
                                      })
                                      dispatch(searchProducts(partsList, enquirySessionId, firstName, b2bUserId, searchProduct));
                                    }
                                    onConfirm();
                                  }}>No</Button>
                              </DialogActions>
                            </Dialog>
                          </React.Fragment>
                        )}
                      </NavigationPrompt>
                      {showUpdateEnquiry && <Button
                        onClick={async () => {
                          saveUpdateEnquiry(false);
                        }}
                        className={`mr-2 font-size-11 ${products.length > 0 && !orderQueued
                          ? "actionButtomButtomsBlue"
                          : "actionButtomButtomsBlue actionButtomButtomsBlueDisabled"
                          }`}
                        variant="contained"
                        color="primary"
                        disabled={checkAvailabilityButtonText !== CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL}
                      >
                        {isEnquirySaved
                          ? "Update Order Enquiry"
                          : "Save Order Enquiry"}
                      </Button>}
                      {showStockEnquiry &&
                        <>
                          <Button
                            className={`mr-2 font-size-11 ${products.length > 0 && !orderQueued
                              ? "actionButtomButtomsBlue"
                              : "actionButtomButtomsBlue actionButtomButtomsBlueDisabled"
                              }`}
                            onClick={async () => {
                              saveUpdateEnquiry(true);
                            }}
                            color="primary"
                            variant="contained"
                          >
                            {checkAvailabilityButtonText}
                          </Button>
                          {showPlaceOrder &&
                            <Tooltip title={isAvailabiityChecked && products.length ? '' : `Please do 'Check Availability and Price' to place the order`} placement="top">
                              <span>
                                <Button
                                  onClick={() => {
                                    let isAirOrder = false
                                    let productsTemp = JSON.parse(
                                      JSON.stringify(products)
                                    );
                                    productsTemp = productsTemp.filter(
                                      (product: any) => product.isValid && !product.isSuperseeded
                                    );
                                    productsTemp = productsTemp.map((item: any) => {
                                      if (item.airAvailabilityChecked && item.airAvailability > 0) {
                                        isAirOrder = true;
                                      }

                                    });
                                    if (!isAirOrder) {
                                      placeOrderHandler()
                                    } else {
                                      setshowBackOrderConfirmation(true)
                                    }
                                  }}
                                  className={`mr-2 font-size-11 ${placeorderEnabled && isAvailabiityChecked && products.length > 0
                                    ? "actionButtomButtomsBlue"
                                    : "actionButtomButtomsBlue actionButtomButtomsBlueDisabled"
                                    }`}
                                  disabled={placeorderEnabled && isAvailabiityChecked && products.length ? false : true}
                                  variant="contained"
                                  color="primary"
                                >
                                  {orderQueued ? "Order in progress" : "Place Order"}
                                </Button>
                              </span>
                            </Tooltip>
                          }
                        </>
                      }
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="legendItemCreateEnquiry m-2 mt-3">
            <span className="dot"></span>
            <span className="legendItemLabel mr-4 ml-2">Special Price</span>
          </div>
          <div className="tandcText m-2">
            <p className="tandcHeading">Terms & Condition</p>
            <p className="tandcBody">
              The parties agree that that in the event of Value Added Tax (VAT) being levied or introduced by the government authorities in the jurisdiction where the [Vendor] is incorporated and/or tax resident the [Vendor] reserves the right to charge VAT at the prevailing VAT rate in respect of any supply made under this Agreement. The [Purchaser] shall pay the amount of any such VAT as an addition to the invoice value. The [Vendor] shall provide to the [Purchaser] the documentation required by the applicable VAT legislation to permit the [Purchaser] to claim an input VAT deduction on its VAT return.
            </p>
            <p className="tandcBody mt-2">
              يوافق الطرفان على أنه في حال فرض أو تطبيق ضريبة القيمة المضافة (VAT) من قِبَل السلطات الحكومية في الولاية القضائية التي يكون [البائع] مؤسّساً / مُقيماً دافعاً للضرائب بها، يحتفظ [البائع] بالحق في فرض ضريبة القيمة المضافة بمعدّلها السائد فيما يتعلق بأي عملية توريد بموجب هذه الاتفاقية. ويدفع [المشتري] مبلغ ضريبة القيمة المضافة تلك كإضافة إلى قيمة الفاتورة. ويقوم [البائع] بتزويد [المشتري] بالمستندات المطلوبة بموجب تشريع ضريبة القيمة المضافة المعمول به للسماح لـ [المشتري] بالمطالبة بخصم ضريبة القيمة المضافة المُدخلة في إقراره الضريبي
            </p>
          </div>
        </div>
      </div>
      <OrderSummaryDialog
        open={openOrderSummary && orderData}
        orderData={orderData}
        orderTotal={orderTotal}
        getEstimatedTotal={getEstimatedTotal}
        handleopenClose={(data: any) => {
          history.push("/orderhistory");
          handleopenCloseOrderSummary(false);
        }}
      />
      <React.Fragment>
        <Dialog
          open={showBackOrderConfirmation}
          fullWidth={true}
          onClose={() => {
            setshowBackOrderConfirmation(false)
          }}
          maxWidth={'sm'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div className={classes.icon}>
              <WarningIcon className="delete-warning" fontSize="small" />
            </div>
            <div>
              <DialogContentText id="alert-dialog-description">
                <div className={`mt-3 ${classes.logoutText}`}>
                  Your order-enquiry basket has <span className="text-danger">BACK ORDER</span> items. Are you sure you want to proceed with order placement?”
                </div>
              </DialogContentText>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              className={`mx-4 ${classes.yes}`}
              onClick={async () => {
                placeOrderHandler()
                setshowBackOrderConfirmation(false)
              }}>Yes</Button>
            <Button
              className={`mr-4 ${classes.cancel}`}
              onClick={() => {
                setshowBackOrderConfirmation(false)
              }}>No</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </Fragment>
  );
};

export default Dashboard;
