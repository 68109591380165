import React, { Fragment, Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, MenuItem, TextField } from "@material-ui/core";
import { withStyles, Theme, createStyles, makeStyles, createTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import Close from "@material-ui/icons/Close";
import AddNewFeatureDialog from "./AddNewFeatureDialog";
import { superAdminServices } from "../../services/superAdmin.service";
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import { Prompt } from 'react-router-dom';
import DeleteDialog from '../../common/components/deleteDialog';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import UpdateDialog from "../../common/components/updateDialog";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      height: 120,
      fontSize: 18
    },
    body: {
      fontSize: 14,
      fontWeight: 600,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  close: {
    color: '#000000',
    fontWeight: 900,
    fontSize: 18
  }
});


const iconsPlan: any = {
  "silver": <HomeOutlinedIcon />,
  "gold": <StorageOutlinedIcon />,
  "platinum": <EmojiEventsOutlinedIcon />,
  "elite": <SportsSoccerIcon />,
}


const showStars: any = {
  "1 stars": <><StarBorderOutlinedIcon /></>,
  "2 stars": <><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /></>,
  "3 stars": <><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /></>,
  "4 stars": <><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /></>,
  "5 stars": <><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /><StarBorderOutlinedIcon /></>,
}

const PlanFeatures: React.FC = () => {
  const classes = useStyles()
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const [isEdit, setIsEdit] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [editKeyName, seteditKeyName] = useState(false);
  const [planfeaturedData, setplanfeaturedData] = useState<any>(null);
  const [selectedKey, setselectedKey] = useState<any>(null);
  const [selectedKeyType, setselectedKeyType] = useState<any>(null);
  const [selectedKeyOptions, setselectedKeyOptions] = useState<any>(null);
  const [dataUpdated, setdataUpdated] = useState<any>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
  const [comment, setcomment] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarType, setOpenSnackbarType] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState<any>(null);

  useEffect(() => {
    loadPlanFeatures()
  }, [])

  const loadPlanFeatures = async () => {
    let data = await superAdminServices.getPlanFeatures();
    setcomment(data?.data?.comment)
    setplanfeaturedData(data?.data)
    setdataUpdated(false)
  }

  const showText = (text: any) => {
    if (text && showStars[text]) {
      return showStars[text]
    } else {
      return text
    }
  }


  let allColumns: any = planfeaturedData?.featurePlans && planfeaturedData?.featurePlans[0] ? Object.keys(planfeaturedData?.featurePlans[0].features) : []

  allColumns = allColumns.sort();

  function convertToInternationalCurrencySystem(labelValue: any) {
    return Math.abs(Number(labelValue)) >= 1.0e+9
      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
      : Math.abs(Number(labelValue)) >= 1.0e+6
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
        : Math.abs(Number(labelValue)) >= 1.0e+3
          ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"
          : Math.abs(Number(labelValue));
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? open : false);
    setSuccessMessage(null);
  };

  const handleopenCloseUpdate = (open?: any) => {
    setOpenUpdateDialog(open ? open : false);
    setSuccessMessage(null);
  };

  return (
    <Fragment>
      <Snackbar
        open={openSnackbar}
        type={openSnackbarType}
        message={successMessage}
        handleopenClose={() => {
          setOpenSnackbar(false);
          setOpenSnackbarType("");
        }}
      />
      <Prompt when={dataUpdated} message={"Do you wish to proceed without saving the data?"}>
      </Prompt>
      {!(planfeaturedData && planfeaturedData.featurePlans) && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Plan Features</h2>
        <div className="ml-auto">
        </div>
      </div>
      <div className="d-flex align-items-center my-4">
        List of all features
        <div className="ml-auto">
          {isEdit ? (
            <>
              <Button
                variant="contained"
                color="default"
                className="rounded-button-dealer cancel-button pt-2 pb-2 mr-2"
                startIcon={<Close />}
                onClick={() => {
                  handleopenClose("Are you sure you want to discard all changes you made?");
                }}
              >
                Discard Changes
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className="rounded-button-dealer pt-2 pb-2"
                startIcon={<CheckIcon />}
                onClick={() => {
                  handleopenCloseUpdate("Are you sure you want to update all changes you made?");
                  }
                }
              >
                Update All
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              className="rounded-button-dealer pt-2 pb-2"
              startIcon={<EditIcon />}
              onClick={() => {
                setIsEdit(true);
              }}
            >
              Edit All Features
            </Button>
          )}
        </div>
      </div>
      <div className="cardCommon">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <h4 className="mt-5 mb-0">Features</h4>
                </StyledTableCell>
                {planfeaturedData?.featurePlans?.map((item: any, index: any) => {
                  return <StyledTableCell align="left"> <h4 className="color-info mb-3 font-20">{item.label}</h4>
                    <Button className="color-info btn-link font-18 ml-3 cursor-default" startIcon={iconsPlan[item.label.toLowerCase()]}
                    ></Button>
                  </StyledTableCell>
                })}
              </TableRow>
            </TableHead>
            {<TableBody>
              {allColumns?.map((key: any) => {
                return <StyledTableRow>
                  <StyledTableCell component="th" scope="row">
                    {key}{(key == "Monthly Spend" || key == "Al-Futtaim Partnership Brand") && "*"} {isEdit && (key != "Monthly Spend" && key != "Al-Futtaim Partnership Brand") && <>
                      <Button onClick={() => {
                        setselectedKeyType(planfeaturedData?.featurePlans[0]?.features[key]?.inputType)
                        setselectedKeyOptions(planfeaturedData?.featurePlans[0]?.features[key]?.options)
                        setselectedKey(key);
                        seteditKeyName(true)
                      }} className="btn-link ml-2 mr-1" startIcon={<EditIcon />}
                      ></Button>
                      <Button onClick={() => {
                        let NewData = planfeaturedData?.featurePlans;
                        NewData.map((item: any, index: any) => {
                          delete item?.features[key];
                          return item
                        })
                        setOpenSnackbar(true)
                        setOpenSnackbarType("")
                        setSuccessMessage("Successfully deleted plan feature")
                        setplanfeaturedData({
                          featurePlans: NewData
                        })
                      }} className="btn-link" startIcon={<DeleteIcon />}
                      ></Button>
                    </>}
                  </StyledTableCell>
                  {planfeaturedData?.featurePlans?.map((item: any, index: any) => {
                    if (!isEdit) {
                      return <StyledTableCell align="left">{item?.features[key]?.value ? key != "Monthly Spend" ? (((`${item?.features[key]?.value}`)?.toLowerCase() == 'yes' || (`${item?.features[key]?.value}`)?.toLowerCase() == 'no') ? ((`${item?.features[key]?.value}`)?.toLowerCase() == 'yes' ? <DoneIcon /> : "" ) : showText(item?.features[key]?.value)) : convertToInternationalCurrencySystem(item?.features[key]?.value) : ""}</StyledTableCell>
                    } else {
                      if (item?.features[key]?.inputType == 'dropDown') {
                        return <StyledTableCell align="left">
                          <TextField
                            select

                            size="small" className="col-sm-12 commonRoundedInputs"
                            value={item?.features[key]?.value}
                            onChange={(e: any) => {
                              let updatedFeatures = planfeaturedData;
                              updatedFeatures.featurePlans[index].features[key].value = e.target.value;
                              setplanfeaturedData({ ...updatedFeatures })
                              setdataUpdated(true)
                            }}
                            variant="outlined" margin="dense">
                            {item?.features[key]?.options.map((menuOption: any) => {
                              return <MenuItem key={menuOption} value={menuOption}>{menuOption}</MenuItem>
                            })}
                          </TextField>
                        </StyledTableCell>
                      } else {
                        return <StyledTableCell align="left">
                          <TextField
                            type={key == "Monthly Spend" ? "number" : "text"}
                            size="small" className="col-sm-12 commonRoundedInputs"
                            value={item?.features[key]?.value}
                            variant="outlined" margin="dense"
                            onChange={(e: any) => {
                              let updatedFeatures = planfeaturedData;
                              updatedFeatures.featurePlans[index].features[key].value = e.target.value;
                              if (key != "Monthly Spend") {
                                updatedFeatures.featurePlans[index].features[key].value = e.target.value;
                                setplanfeaturedData({ ...updatedFeatures })
                              } else {
                                updatedFeatures.featurePlans[index].features[key].value = e.target.value && parseInt(e.target.value) && parseInt(e.target.value) < 99999999999999999999 ? parseInt(e.target.value) : 0;
                                setplanfeaturedData({ ...updatedFeatures })
                              }

                              setdataUpdated(true)
                            }}
                            inputProps={{ maxLength: 20 }}
                          >
                          </TextField>
                        </StyledTableCell>
                      }
                    }
                  })}
                </StyledTableRow>
              })}
            </TableBody>}
          </Table >
          {isEdit ?
            (<div className="pl-3">
              <div className="color-info cursor-pointer mt-3 mb-2" onClick={() => { seteditKeyName(true) }}>+ Add New Feature</div>
              <br />
              <div className="pb-4 pr-4 col-lg-none">
                <TextField
                  label="Comment"
                  size="small" className="col-sm-12 w-100 commonRoundedInputs"
                  value={comment}
                  variant="outlined" margin="dense"
                  placeholder={"Max. character limit is 1000"}
                  inputProps={{ maxLength: 1000 }}
                  onChange={(e: any) => {
                    setcomment(e.target.value)
                  }}
                >
                </TextField>
              </div>
            </div>)
            : (<div className="pl-3 s-4 pb-4 col-12 pt-2 font-planfeatures">
              <p>Comment:</p>
              {comment}
            </div>
            )}
        </TableContainer >
        <p className="pl-3  pt-2 pb-2 col-12 ">
          <b>* denotes feature is mandatory</b>
        </p>
      </div>
      {(
        <AddNewFeatureDialog selectedKeyOptions={selectedKeyOptions} selectedKey={selectedKey} selectedKeyType={selectedKeyType} open={editKeyName} handleSave={async (isSaved: any, isUpdated: any, data: any) => {
          if (isSaved) {
            let NewData = planfeaturedData?.featurePlans;
            if (isUpdated) {
              NewData.map((item: any, index: any) => {
                if (data.type != selectedKey) {
                  item.features[data.key] = item?.features[selectedKey];
                  delete item?.features[selectedKey];
                }
                item.features[data.key] = {
                  inputType: data.type,
                  options: data.options,
                  value: "",
                };
                return item
              })
              setplanfeaturedData({
                featurePlans: NewData
              })
              setOpenSnackbar(true)
              setOpenSnackbarType("")
              setSuccessMessage("Plan feature updated successfully")
            }
            else {
              NewData.map((item: any, index: any) => {
                item.features[data.key] = {
                  inputType: data.type,
                  options: data.options,
                  value: "",
                };
                return item
              })
              setplanfeaturedData({
                featurePlans: NewData
              })
              setOpenSnackbar(true)
              setOpenSnackbarType("")
              setSuccessMessage("Plan feature added successfully")
            }
            setdataUpdated(true)
          }
          seteditKeyName(false)
          setselectedKey(null)
          setselectedKeyType(null)
          setselectedKeyOptions(null)
        }} />
      )
      }
      <DeleteDialog
        open={Boolean(openDeleteDialog)}
        text={openDeleteDialog}
        approval={true}
        handleopenClose={(data: any) => {
          if (data === 'delete') {
            loadPlanFeatures();
            setIsEdit(false)
          }
          handleopenClose(false);
        }} />
      <UpdateDialog
        open={Boolean(openUpdateDialog)}
        text={openUpdateDialog}
        updateApproval={true}
        handleopenCloseUpdate={async (data: any) => {
          if (data === 'update') {
            await superAdminServices.updatePlanFeatures({
              comment: comment,
              featurePlans: planfeaturedData?.featurePlans,
              updatedBy: sessionStorage.getItem('webApplicationLoginId')
            })
            loadPlanFeatures()
            setOpenSnackbar(true)
            setOpenSnackbarType("")
            setIsEdit(false)
          }
          handleopenCloseUpdate(false);
          setSuccessMessage("Successfully updated plan features");
        }} />
    </Fragment >
  );
};

export default PlanFeatures;