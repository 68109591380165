import React from 'react'
import { useHistory } from "react-router-dom"
import image1 from './../../assets/landingpage/images/logo.png'
import { MsalProvider, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { b2cPolicies, msalConfig } from "../../authConfig";
import { API_ENDPOINTS } from '../../constants/apiConfig';

const Register= (props:any) => {
    const history = useHistory()
    const { instance } = useMsal();
    const redirect_uri = encodeURIComponent(`${API_ENDPOINTS.ON_BOARDING_URL}/login`);
    
    return (
        <div className="section bg-register position-relative">
            <div className="position-absolute w-100 h-100 t-0 bg-black-75"></div>
            <div className="container xl text-white text-center position-relative z-1">
                <h2 className="poppins-700 mt-lg-3">{props.pageData && props.pageData.register && props.pageData.register.title}</h2>
                <p className="mt-4 font-16 px-lg-10">{props.pageData && props.pageData.register && props.pageData.register.short_desc}</p>
                <button onClick={() => instance.loginRedirect(loginRequest)} className="btn bg-white tp-btn mt-5 text-black mb-lg-3 color_black">{props.pageData && props.pageData.register && props.pageData.register.CTA_Label}</button> 
            </div>
        </div>
    )
}

export default Register 