

import { DEALER } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  createDealer: null,
  createDealerUser: null,
  allDealers: null,
  dealerDetails: null,
  userIdAvailability: null,
  updateDealer: null,
  updateDealerUser: null,
  updateDealerInProgressUser: null,
  usersList: null,
  verifiedSAPCustomer: null,
  userDetails: null,
  addParamInfo: null,
  editParamInfo: null,
  brandValues: null,
  userParameters: null,
  accessParam: null,
  addAccessParam: null,
  updateAccessParam: null,
  accessParamAdd: null,
  accessParamUpdate: null,
  uploadDocumentData: null,
  removeDocumentData: null,
  uploadedDocuments: null,
  sendEmail: null,
  userDetailsInfo: null,
  kamUserList: null,
  resendInvitation: null,
  customerIds: null,
  lostSaleReason: null,
  brandsList: null,
  lostSaleReportData: null,
  selectedNewDealer: null,
  isEditDealer: null,
  getDealerCreditRequestAPI: null,
  saveAdditionalCreditRequestAPI: null,
  deleteBankStatementDocAPI: null,
  generateB2bId: null,
  sendInvitationLinkForNewUser: null,
  dealerConfirmation: null
};

function dealerReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case DEALER.POST_DEALER_FETCHING:
      return {
        ...state,
        createDealer: { loading: true, data: null, err: null },
      };
    case DEALER.POST_DEALER_SUCCESS:
      return {
        ...state,
        createDealer: { loading: false, data: action.payload, err: null }
      };
    case DEALER.POST_DEALER_ERROR:
      return {
        ...state,
        createDealer: { loading: false, data: null, err: action.payload }
      };
    case DEALER.POST_DEALER_USER_FETCHING:
      return {
        ...state,
        createDealerUser: { loading: true, data: null, err: null }
      };
    case DEALER.POST_DEALER_USER_SUCCESS:
      return {
        ...state,
        createDealerUser: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.POST_DEALER_USER_ERROR:
      return {
        ...state,
        createDealerUser: { loading: false, data: null, err: action.payload }
      };
    case DEALER.GET_ALL_DEALERS_FETCHING:
      return {
        ...state,
        allDealers: { loading: true, data: null, err: null },
        uploadedDocuments: null,
      };
    case DEALER.GET_ALL_DEALERS_SUCCESS:
      return {
        ...state,
        allDealers: { loading: false, data: action.payload, err: null }
      };
    case DEALER.GET_ALL_DEALERS_ERROR:
      return {
        ...state,
        allDealers: { loading: false, data: null, err: action.payload }
      };

    case DEALER.GET_ALL_DEALER_REQUESTS_FETCHING:
      return {
        ...state,
        dealerRequests: { loading: true, data: null, err: null },
        uploadedDocuments: null,
      };
    case DEALER.GET_ALL_DEALER_REQUESTS_SUCCESS:
      return {
        ...state,
        dealerRequests: { loading: false, data: action.payload, err: null }
      };
    case DEALER.GET_ALL_DEALER_REQUESTS_ERROR:
      return {
        ...state,
        dealerRequests: { loading: false, data: null, err: action.payload }
      };
    case DEALER.GET_USER_LIST_FETCHING:
      return {
        ...state,
        usersList: { loading: true, data: null, err: null },
        addParamInfo: null,
        resendInvitation: null,
        editParamInfo: null,
        brandValues: null,
      };
    case DEALER.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        usersList: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.GET_USER_LIST_ERROR:
      return {
        ...state,
        usersList: { loading: false, data: null, err: action.payload }
      };

    case DEALER.GET_DEALER_DETAILS_FETCHING:
      return {
        ...state,
        dealerDetails: { loading: true, data: null, err: null },
        uploadedDocuments: null,
        addParamInfo: null,
        editParamInfo: null,
        brandValues: null,
      };
    case DEALER.GET_DEALER_DETAILS_SUCCESS:
      return {
        ...state,
        dealerDetails: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.GET_DEALER_DETAILS_ERROR:
      return {
        ...state,
        dealerDetails: { loading: false, data: null, err: action.payload }
      };
    case DEALER.GET_USER_DETAILS_FETCHING:
      return {
        ...state,
        userDetailsInfo: { loading: true, data: null, err: null },
      };
    case DEALER.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetailsInfo: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.GET_USER_DETAILS_ERROR:
      return {
        ...state,
        userDetailsInfo: { loading: false, data: null, err: action.payload }
      };
    case DEALER.CHECK_USER_AVAILABILITY_FETCHING:
      return {
        ...state,
        userIdAvailability: { loading: true, data: null, err: null }
      };
    case DEALER.CHECK_USER_AVAILABILITY_SUCCESS:
      return {
        ...state,
        userIdAvailability: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.CHECK_USER_AVAILABILITY_ERROR:
      return {
        ...state,
        userIdAvailability: { loading: false, data: null, err: action.payload }
      };
    case DEALER.PUT_DEALER_FETCHING:
      return {
        ...state,
        updateDealer: { loading: true, data: null, err: null }
      };
    case DEALER.PUT_DEALER_SUCCESS:
      return {
        ...state,
        updateDealer: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.PUT_DEALER_ERROR:
      return {
        ...state,
        updateDealer: { loading: false, data: null, err: action.payload }
      };
    case DEALER.PUT_DEALER_USER_FETCHING:
      return {
        ...state,
        updateDealerUser: { loading: true, data: null, err: null }
      };
    case DEALER.PUT_DEALER_USER_SUCCESS:
      return {
        ...state,
        updateDealerUser: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.PUT_DEALER_USER_ERROR:
      return {
        ...state,
        updateDealerUser: { loading: false, data: null, err: action.payload }
      };
    case DEALER.PUT_DEALER_USER_IN_PROGRESS_FETCHING:
      return {
        ...state,
        updateDealerInProgressUser: { loading: true, data: null, err: null }
      };
    case DEALER.PUT_DEALER_USER_IN_PROGRESS_SUCCESS:
      return {
        ...state,
        updateDealerInProgressUser: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.PUT_DEALER_USER_IN_PROGRESS_ERROR:
      return {
        ...state,
        updateDealerInProgressUser: { loading: false, data: null, err: action.payload }
      };
    case DEALER.VERIFY_SAPCUSTOMERID_FETCHING:
      return {
        ...state,
        verifiedSAPCustomer: { loading: true, data: null, err: null }
      };
    case DEALER.VERIFY_SAPCUSTOMERID_SUCCESS:
      return {
        ...state,
        verifiedSAPCustomer: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.VERIFY_SAPCUSTOMERID_ERROR:
      return {
        ...state,
        verifiedSAPCustomer: { loading: false, data: null, err: action.payload }
      };
    case DEALER.SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload.data,
        addParamInfo: null,
        editParamInfo: null,
        brandValues: null,
      }
    case DEALER.STORE_BRAND_VALUES:
      return {
        ...state,
        brandValues: action.payload.data
      }
    case DEALER.GET_USER_PARAMETER_FETCHING:
      return {
        ...state,
        userParameters: { loading: true, data: null, err: null }
      };
    case DEALER.GET_USER_PARAMETER_SUCCESS:
      return {
        ...state,
        userParameters: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.GET_USER_PARAMETER_ERROR:
      return {
        ...state,
        userParameters: { loading: false, data: null, err: action.payload }
      };
    case DEALER.RESEND_INVITATION_LINK_FETCHING:
      return {
        ...state,
        resendInvitation: { loading: true, data: null, err: null }
      };
    case DEALER.RESEND_INVITATION_LINK_SUCCESS:
      return {
        ...state,
        resendInvitation: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.RESEND_INVITATION_LINK_ERROR:
      return {
        ...state,
        resendInvitation: { loading: false, data: null, err: action.payload }
      };
    case DEALER.ADD_USER_PARAMETER_FETCHING:
      return {
        ...state,
        addParamInfo: { loading: true, data: null, err: null }
      };
    case DEALER.ADD_USER_PARAMETER_SUCCESS:
      return {
        ...state,
        addParamInfo: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.ADD_USER_PARAMETER_ERROR:
      return {
        ...state,
        addParamInfo: { loading: false, data: null, err: action.payload }
      };
    case DEALER.EDIT_USER_PARAMETER_FETCHING:
      return {
        ...state,
        editParamInfo: { loading: true, data: null, err: null }
      };
    case DEALER.EDIT_USER_PARAMETER_SUCCESS:
      return {
        ...state,
        editParamInfo: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.EDIT_USER_PARAMETER_ERROR:
      return {
        ...state,
        editParamInfo: { loading: false, data: null, err: action.payload }
      };

    case DEALER.GET_ACCESS_PARAM_FETCHING:
      return {
        ...state,
        accessParam: { loading: true, data: null, err: null }
      };
    case DEALER.GET_ACCESS_PARAM_SUCCESS:
      return {
        ...state,
        accessParam: { loading: false, data: action.payload.data.data, err: null }
      };
    case DEALER.GET_ACCESS_PARAM_ERROR:
      return {
        ...state,
        accessParam: { loading: false, data: null, err: action.payload }
      };

    case DEALER.PUT_ACCESS_PARAM_FETCHING:
      return {
        ...state,
        accessParamUpdate: { loading: true, data: null, err: null }
      };
    case DEALER.PUT_ACCESS_PARAM_SUCCESS:
      return {
        ...state,
        accessParamUpdate: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.PUT_ACCESS_PARAM_ERROR:
      return {
        ...state,
        accessParamUpdate: { loading: false, data: null, err: action.payload }
      };

    case DEALER.POST_ACCESS_PARAM_FETCHING:
      return {
        ...state,
        accessParamAdd: { loading: true, data: null, err: null }
      };
    case DEALER.POST_ACCESS_PARAM_SUCCESS:
      return {
        ...state,
        accessParamAdd: { loading: false, data: action.payload, err: null }
      };
    case DEALER.POST_ACCESS_PARAM_ERROR:
      return {
        ...state,
        accessParamAdd: { loading: false, data: null, err: action.payload }
      };

    case DEALER.POST_UPLOAD_DOCUMENT_FETCHING:
      return {
        ...state,
        uploadDocumentData: { loading: true, data: null, err: null }
      };
    case DEALER.POST_UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadDocumentData: { loading: false, data: action.payload, err: null }
      };
    case DEALER.POST_UPLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        uploadDocumentData: { loading: false, data: null, err: action.payload }
      };
    case DEALER.DELETE_DOCUMENT_FETCHING:
      return {
        ...state,
        removeDocumentData: { loading: true, data: null, err: null }
      };
    case DEALER.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        removeDocumentData: { loading: false, data: action.payload, err: null }
      };
    case DEALER.DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        removeDocumentData: { loading: false, data: null, err: action.payload }
      };
    case DEALER.Get_UPLOADED_DOCUMENT_FETCHING:
      return {
        ...state,
        uploadedDocuments: { loading: true, data: null, err: null }
      };
    case DEALER.Get_UPLOADED_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadedDocuments: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.Get_UPLOADED_DOCUMENT_ERROR:
      return {
        ...state,
        uploadedDocuments: { loading: false, data: null, err: action.payload }
      };

    case DEALER.SEND_EMAIL_FETCHING:
      return {
        ...state,
        sendEmail: { loading: true, data: null, err: null }
      };
    case DEALER.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmail: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.SEND_EMAIL_ERROR:
      return {
        ...state,
        sendEmail: { loading: false, data: null, err: action.payload }
      };
    case DEALER.GET_KAMUSER_FETCHING:
      return {
        ...state,
        kamUserList: { loading: true, data: null, err: null }
      };
    case DEALER.GET_KAMUSER_SUCCESS:
      return {
        ...state,
        kamUserList: { loading: false, data: action.payload.data, err: null }
      };
    case DEALER.GET_KAMUSER_ERROR:
      return {
        ...state,
        kamUserList: { loading: false, data: null, err: action.payload }
      };
    case DEALER.GET_ALL_BRANDS_FETCHING:
      return {
        ...state,
        brandsList: { loading: true, data: null, err: null }
      };
    case DEALER.GET_ALL_BRANDS_SUCCESS:
      return {
        ...state,
        brandsList: { loading: false, data: action.payload.data.data, err: null }
      };
    case DEALER.GET_ALL_BRANDS_ERROR:
      return {
        ...state,
        brandsList: { loading: false, data: null, err: action.payload }
      };
    case DEALER.GET_LOST_SALE_REASON_FETCHING:
      return {
        ...state,
        lostSaleReason: { loading: true, data: null, err: null }
      };
    case DEALER.GET_LOST_SALE_REASON_SUCCESS:
      return {
        ...state,
        lostSaleReason: { loading: false, data: action.payload.data.data, err: null }
      };
    case DEALER.GET_LOST_SALE_REASON_ERROR:
      return {
        ...state,
        lostSaleReason: { loading: false, data: null, err: action.payload }
      };
    case DEALER.GET_SAP_CUSTOMER_IDS_FETCHING:
      return {
        ...state,
        customerIds: { loading: true, data: null, err: null }
      };
    case DEALER.GET_SAP_CUSTOMER_IDS_SUCCESS:
      return {
        ...state,
        customerIds: { loading: false, data: action.payload.data.data, err: null }
      };
    case DEALER.GET_SAP_CUSTOMER_IDS_ERROR:
      return {
        ...state,
        customerIds: { loading: false, data: null, err: action.payload }
      };
    case DEALER.GET_LOST_SALE_REPORT_FETCHING:
      return {
        ...state,
        lostSaleReportData: { loading: true, data: null, err: null }
      };
    case DEALER.GET_LOST_SALE_REPORT_SUCCESS:
      return {
        ...state,
        lostSaleReportData: { loading: false, data: action.payload.data.data, err: null }
      };
    case DEALER.GET_LOST_SALE_REPORT_ERROR:
      return {
        ...state,
        lostSaleReportData: { loading: false, data: null, err: action.payload }
      };
    case DEALER.RESET_REPORT_DATA:
      return {
        ...state,
        lostSaleReportData: null
      };
    case DEALER.SET_NEW_DEALER_DATA:
      return {
        ...state,
        selectedNewDealer: action.payload
      }
    case DEALER.IS_EDIT:
      return {
        ...state,
        isEditDealer: action.payload
      }
    case DEALER.GET_DEALER_CREDIT_REQUEST_FETCHING:
      return {
        ...state,
        getDealerCreditRequestAPI: { loading: true, data: null, err: null }
      };
    case DEALER.GET_DEALER_CREDIT_REQUEST_SUCCESS:
      return {
        ...state,
        getDealerCreditRequestAPI: { loading: false, data: action.payload.data.data, err: null }
      };
    case DEALER.GET_DEALER_CREDIT_REQUEST_ERROR:
      return {
        ...state,
        getDealerCreditRequestAPI: { loading: false, data: null, err: action.payload }
      };
    case DEALER.SAVE_ADDITIONAL_CREDIT_REQUEST_FETCHING:
      return {
        ...state,
        saveAdditionalCreditRequestAPI: { loading: true, data: null, err: null }
      };
    case DEALER.SAVE_ADDITIONAL_CREDIT_REQUEST_SUCCESS:
      return {
        ...state,
        saveAdditionalCreditRequestAPI: { loading: false, data: action.payload.data.data, err: null }
      };
    case DEALER.SAVE_ADDITIONAL_CREDIT_REQUEST_ERROR:
      return {
        ...state,
        saveAdditionalCreditRequestAPI: { loading: false, data: null, err: action.payload }
      };
    // FOR DELETE DOCUMENT FOR BANK STATEMENT 
    case DEALER.DELETE_BANK_STATEMENT_CREDIT_REQUEST_FETCHING:
      return {
        ...state,
        deleteBankStatementDocAPI: { loading: true, data: null, err: null }
      };
    case DEALER.DELETE_BANK_STATEMENT_CREDIT_REQUEST_SUCCESS:
      return {
        ...state,
        deleteBankStatementDocAPI: { loading: false, data: action.payload.data.data, err: null }
      };
    case DEALER.DELETE_BANK_STATEMENT_CREDIT_REQUEST_ERROR:
      return {
        ...state,
        deleteBankStatementDocAPI: { loading: false, data: null, err: action.payload }
      };
    // FOR NEW USER CREATED BY KAM - SEND INVITATION LINK - KAM NEW USER
    case DEALER.SEND_INVITATION_LINK_FETCHING:
      return {
        ...state,
        sendInvitationLinkForNewUser: { loading: true, data: null, err: null }
      };
    case DEALER.SEND_INVITATION_LINK_SUCCESS:
      return {
        ...state,
        sendInvitationLinkForNewUser: { loading: false, data: action.payload.data.data, err: null }
      };
    case DEALER.SEND_INVITATION_LINK_ERROR:
      return {
        ...state,
        sendInvitationLinkForNewUser: { loading: false, data: null, err: action.payload }
      };
    // FOR NEW USER CREATED BY KAM - CONFIRM DATA FROM DEALER - KAM NEW USER
    case DEALER.CONFIRM_DATA_FROM_DEALER_FETCHING:
      return {
        ...state,
        dealerConfirmation: { loading: true, data: null, err: null }
      };
    case DEALER.CONFIRM_DATA_FROM_DEALER_SUCCESS:
      return {
        ...state,
        dealerConfirmation: { loading: false, data: action.payload.data.data, err: null }
      };
    case DEALER.CONFIRM_DATA_FROM_DEALER_ERROR:
      return {
        ...state,
        dealerConfirmation: { loading: false, data: null, err: action.payload }
      };
    // FOR NEW USER CREATED BY KAM GENERATE B2B USER ID - KAM NEW USER 
    case DEALER.GENERATE_B2B_USER_ID_FETCHING:
      return {
        ...state,
        generateB2bId: { loading: true, data: null, err: null }
      };
    case DEALER.GENERATE_B2B_USER_ID_SUCCESS:
      return {
        ...state,
        generateB2bId: { loading: false, data: action.payload.data.data, err: null }
      };
    case DEALER.GENERATE_B2B_USER_ID_ERROR:
      return {
        ...state,
        generateB2bId: { loading: false, data: null, err: action.payload }
      };
      case DEALER.GET_VIEWCREDIT_DEALER_FETCHING:
        return {
          ...state,
          viewTargetDealer:{loading:false,data:null,err:null}
        };
      case DEALER.GET_VIEWCREDIT_DEALER_SUCCESS:
        return {
          ...state,
          viewTargetDealer:{loading:false,data:action.payload.data.data,err:null}
        };
      case DEALER.GET_VIEWCREDIT_DEALER_ERROR:
        return {
          ...state,
          viewTargetDealer:{loading:false,data:null,err:action.payload}
        }; 
    default:
      return state;
  }
};

export default dealerReducer;
