import { ORDER_HISTORY } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  orderList: null,
  partsHistoryList: null,
  orderDetails: null,
  returnRequestR: null,
  orderId: null,
  invoice: null,
  prevPage: null,
  orderStatus: null
};

function orderHistoryReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case ORDER_HISTORY.GET_ORDER_HISTORY_LIST_FETCHING:
      return {
        ...state,
        orderList: { loading: true, data: null, err: null }
      };
    case ORDER_HISTORY.GET_ORDER_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        orderList: {
          totalCount: action.payload.data && action.payload.data.data && action.payload.data.data.totalCount ? action.payload.data.data.totalCount : 0,
          loading: false,
          data: action.payload.data && action.payload.data.data && action.payload.data.data.orderList && Array.isArray(action.payload.data.data.orderList) && action.payload.data.data.orderList,
          err: null
        }
      };
    case ORDER_HISTORY.GET_ORDER_HISTORY_LIST_ERROR:
      return {
        ...state,
        orderList: { loading: false, data: null, err: action.payload }
      };
    case ORDER_HISTORY.GET_PARTS_HISTORY_LIST_FETCHING:
      return {
        ...state,
        partsHistoryList: { loading: true, data: null, err: null }
      };
    case ORDER_HISTORY.GET_PARTS_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        partsHistoryList: {
          // totalCount: action.payload.data && action.payload.data && action.payload.data.totalCount ? action.payload.data.totalCount : 0,
          loading: false,
          data: action.payload.data && action.payload.data && action.payload.data && Array.isArray(action.payload.data) && action.payload.data,
          err: null
        }
      };
    case ORDER_HISTORY.GET_PARTS_HISTORY_LIST_ERROR:
      return {
        ...state,
        partsHistoryList: { loading: false, data: null, err: action.payload }
      };
    case ORDER_HISTORY.GET_ORDER_HISTORY_DETAILS_FETCHING:
      return {
        ...state,
        orderDetails: { loading: true, data: null, err: null }
      };
    case ORDER_HISTORY.GET_ORDER_HISTORY_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: {
          loading: false, data: action.payload && action.payload.data && action.payload.data.data &&
            Array.isArray(action.payload.data.data) && action.payload.data.data.length > 0 && action.payload.data.data[0], err: null
        }
      };
    case ORDER_HISTORY.GET_ORDER_HISTORY_DETAILS_ERROR:
      return {
        ...state,
        orderDetails: { loading: false, data: null, err: action.payload }
      };
    case ORDER_HISTORY.CREATE_RETURN_REQUEST_FETCHING:
      return {
        ...state,
        returnRequestR: { loading: true, data: null, err: null }
      };
    case ORDER_HISTORY.CREATE_RETURN_REQUEST_SUCCESS:
      return {
        ...state,
        returnRequestR: { loading: false, data: action.payload, err: null }
      };
    case ORDER_HISTORY.CREATE_RETURN_REQUEST_ERROR:
      return {
        ...state,
        returnRequestR: { loading: false, data: null, err: action.payload }
      };
    case ORDER_HISTORY.GET_INVOICE_FETCHING:
      return {
        ...state,
        invoice: { loading: true, data: null, err: null }
      };
    case ORDER_HISTORY.GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: { loading: false, data: action.payload.data, err: null }
      };
    case ORDER_HISTORY.GET_INVOICE_ERROR:
      return {
        ...state,
        invoice: { loading: false, data: null, err: action.payload.error }
      };
    case ORDER_HISTORY.GET_ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
      }
    case ORDER_HISTORY.RESET_INVOICE:
      return {
        ...state,
        invoice: null
      }
    case ORDER_HISTORY.SET_PAGE_NUMBER:
      return {
        ...state,
        prevPage: action.payload
      }
    case ORDER_HISTORY.GET_ORDER_STATUS_FETCHING:
      return {
        ...state,
        orderStatus: { loading: true, data: null, err: null }
      };
    case ORDER_HISTORY.GET_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        orderStatus: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ORDER_HISTORY.GET_ORDER_STATUS_ERROR:
      return {
        ...state,
        orderStatus: { loading: false, data: null, err: action.payload }
      };
    default:
      return state;
  }
};

export default orderHistoryReducer;
