import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";


export const profileservice = {
    getProfile,
    updateProfile,
    profileStatus
};

async function getProfile(id: any, userType:string) {
    const apiPath =  API_ENDPOINTS.PROFILE_ENDPOINT;
    const options = {
        path: `${apiPath}?id=${id}&userType=${userType}&details=basic,customer,access`,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
    };
    return await makeRequest(options)
}

async function updateProfile(data:any) {
    const apiPath = API_ENDPOINTS.PROFILE_ENDPOINT;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "Put",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function profileStatus(data:any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/${data}/accountInfo`;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "Get",
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}