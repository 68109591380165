import { ONBOARD } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState = {
  customerDetails: null,
  dealerDetails: null,
  enquiryAction: null,
  enquiryStatus: null,
  regionData: null,
  tradeRegionData: null,
  dealingInData: null,
  typeofTradeData: null,
  partnerDetails: null,
  savePartnerData: null,
  editPartnerData: null,
  deletePartnerData: null,
  deleteSignatoriesData: null,
  saveSignatoriesData: null,
  editSignatoriesData: null,
  getSignatoriesData: null,
  documentTab: null,
  generateOTP: null,
  verifyOtp: null,
  uploadedDocumentData: null,
  deleteDocumentData: null,
  shipToPartyAddressData: null,
  getDocumentByIdData: null,
  saveNewAdress: null,
  getSelectedAddress: null,
  deleteAddressData: null,
  updateAddressData: null,
  changeCustomerTypeData: null,
  status: null,
  deleteAddressDocumentData: null,
  getAllSignatoriesData: null,
  getAllPartnersData: null,
  deletePartnerDocumentData: null,
  deleteSignatoryDocumentData: null,
  isRefresh: null,
  requestCorrectionAPI: null,
  saveRequestCorrectionApi: null,
  historyListRequestCorrectionApi: null,
  zoneData:null,
  saveZoneData:null,
  getParametersDetailsAPI:null,
  getParameterValuesAPI:null,
  saveParameterValuesAPI:null,
  updateParameterValuesAPI:null,
  getSapDetailsAPI:null,
  saveSapDetailsAPI:null,
  updateSapDetailsAPI:null,
  getNationalityData:null,
  getIndustryTypeAPI: null,
  paymentModeList: null,
};

function onboardingReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case ONBOARD.GET_DETAILS_BY_SAP_ID_FETCHING:
      return {
        ...state,
        customerDetails: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_DETAILS_BY_SAP_ID_SUCCESS:
      return {
        ...state,
        customerDetails: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_DETAILS_BY_SAP_ID_ERROR:
      return {
        ...state,
        customerDetails: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.GET_REGION_FETCHING:
      return {
        ...state,
        regionData: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_REGION_SUCCESS:
      return {
        ...state,
        regionData: {
          loading: false, data: action.payload.data.data, err: null
        }
      };
    case ONBOARD.GET_REGION_ERROR:
      return {
        ...state,
        regionData: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.GET_TRADE_REGION_FETCHING:
      return {
        ...state,
        tradeRegionData: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_TRADE_REGION_SUCCESS:
      return {
        ...state,
        tradeRegionData: {
          loading: false, data: action.payload.data.data, err: null
        }
      };
    case ONBOARD.GET_TRADE_REGION_ERROR:
      return {
        ...state,
        tradeRegionData: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.GET_DEALINGIN_FETCHING:
      return {
        ...state,
        dealingInData: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_DEALINGIN_SUCCESS:
      return {
        ...state,
        dealingInData: {
          loading: false, data: action.payload.data.data, err: null
        }
      };
    case ONBOARD.GET_DEALINGIN_ERROR:
      return {
        ...state,
        dealingInData: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.GET_DEALER_DETAILS_FETCHING:
      return {
        ...state,
        savePartnerData: null,
        editPartnerData: null,
        deletePartnerData: null,
        dealerDetails: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_DEALER_DETAILS_SUCCESS:
      return {
        ...state,
        dealerDetails: {
          loading: false, data: action.payload.data.data, err: null
        },
      };
    case ONBOARD.GET_DEALER_DETAILS_ERROR:
      return {
        ...state,
        dealerDetails: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.GET_TYPEOFTRADE_FETCHING:
      return {
        ...state,
        typeofTradeData: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_TYPEOFTRADE_SUCCESS:
      return {
        ...state,
        typeofTradeData: {
          loading: false, data: action.payload.data.data, err: null
        }
      };
    case ONBOARD.GET_TYPEOFTRADE_ERROR:
      return {
        ...state,
        typeofTradeData: { loading: false, data: null, err: action.payload.error }
      };

    case ONBOARD.GET_PARTNER_DETAILS_FETCHING:
      return {
        ...state,
        partnerDetails: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        partnerDetails: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_PARTNER_DETAILS_ERROR:
      return {
        ...state,
        partnerDetails: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.SAVE_PARTNER_DETAILS_FETCHING:
      return {
        ...state,
        savePartnerData: { loading: true, data: null, err: null }
      };
    case ONBOARD.SAVE_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        savePartnerData: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.SAVE_PARTNER_DETAILS_ERROR:
      return {
        ...state,
        savePartnerData: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.UPDATE_PARTNER_DETAILS_FETCHING:
      return {
        ...state,
        editPartnerData: { loading: true, data: null, err: null }
      };
    case ONBOARD.UPDATE_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        editPartnerData: {
          loading: false,
          data: action.payload.data,
          err: null
        },
        partnerDetails: null
      };
    case ONBOARD.UPDATE_PARTNER_DETAILS_ERROR:
      return {
        ...state,
        editPartnerData: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.DELETE_PARTNER_DETAILS_FETCHING:
      return {
        ...state,
        deletePartnerData: { loading: true, data: null, err: null }
      };
    case ONBOARD.DELETE_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        deletePartnerData: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.DELETE_PARTNER_DETAILS_ERROR:
      return {
        ...state,
        deletePartnerData: { loading: false, data: null, err: action.payload.error }
      }

    // CODE FOR ADD NEW AUTHORISED SIGNATORIES
    case ONBOARD.GET_DETAILS_AUTHORISED_SIGNATORIES_FETCHING:
      return {
        ...state,
        getSignatoriesData: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_DETAILS_AUTHORISED_SIGNATORIES_SUCCESS:
      return {
        ...state,
        getSignatoriesData: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.SAVE_AUTHORISED_SIGNATORIES_ERROR:
      return {
        ...state,
        saveSignatoriesData: { loading: false, data: null, err: action.payload.error }
      };

    case ONBOARD.SAVE_AUTHORISED_SIGNATORIES_FETCHING:
      return {
        ...state,
        saveSignatoriesData: { loading: true, data: null, err: null }
      };
    case ONBOARD.SAVE_AUTHORISED_SIGNATORIES_SUCCESS:
      return {
        ...state,
        saveSignatoriesData: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.UPDATED_AUTHORISED_SIGNATORIES_ERROR:
      return {
        ...state,
        editSignatoriesData: { loading: false, data: null, err: action.payload.error }
      };

    case ONBOARD.UPDATED_AUTHORISED_SIGNATORIES_FETCHING:
      return {
        ...state,
        editSignatoriesData: { loading: true, data: null, err: null }
      };
    case ONBOARD.UPDATED_AUTHORISED_SIGNATORIES_SUCCESS:
      return {
        ...state,
        editSignatoriesData: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.DELETE_AUTHORISED_SIGNATORIES_ERROR:
      return {
        ...state,
        deleteSignatoriesData: { loading: false, data: null, err: action.payload.error }
      };

    case ONBOARD.DELETE_AUTHORISED_SIGNATORIES_FETCHING:
      return {
        ...state,
        deleteSignatoriesData: { loading: true, data: null, err: null }
      };
    case ONBOARD.DELETE_AUTHORISED_SIGNATORIES_SUCCESS:
      return {
        ...state,
        deleteSignatoriesData: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.GET_DETAILS_AUTHORISED_SIGNATORIES_ERROR:
      return {
        ...state,
        getSignatoriesData: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR DOCUMENTS TAB
    case ONBOARD.SAVE_DOCUMENT_FETCHING:
      return {
        ...state,
        documentTab: { loading: true, data: null, err: null }
      };
    case ONBOARD.SAVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentTab: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.UPLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        uploadedDocumentData: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.UPLOAD_DOCUMENT_FETCHING:
      return {
        ...state,
        uploadedDocumentData: { loading: true, data: null, err: null }
      };
    case ONBOARD.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadedDocumentData: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        deleteDocumentData: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.DELETE_DOCUMENT_FETCHING:
      return {
        ...state,
        deleteDocumentData: { loading: true, data: null, err: null }
      };
    case ONBOARD.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteDocumentData: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.SAVE_DOCUMENT_ERROR:
      return {
        ...state,
        documentTab: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR GENRATE OTP
    case ONBOARD.GET_GENARTE_OTP_DETAILS_FETCHING:
      return {
        ...state,
        generateOTP: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_GENARTE_OTP_DETAILS_SUCCESS:
      return {
        ...state,
        generateOTP: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_GENARTE_OTP_DETAILS_ERROR:
      return {
        ...state,
        generateOTP: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR GET DOCUMENT BY ID
    case ONBOARD.GET_DOCUMENT_BY_ID_DETAILS_FETCHING:
      return {
        ...state,
        getDocumentByIdData: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_DOCUMENT_BY_ID_DETAILS_SUCCESS:
      return {
        ...state,
        getDocumentByIdData: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_DOCUMENT_BY_ID_DETAILS_ERROR:
      return {
        ...state,
        getDocumentByIdData: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR VERIFY OTP
    case ONBOARD.GET_VERIFY_OTP_DETAILS_FETCHING:
      return {
        ...state,
        verifyOtp: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_VERIFY_OTP_DETAILS_SUCCESS:
      return {
        ...state,
        verifyOtp: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.GET_VERIFY_OTP_DETAILS_ERROR:
      return {
        ...state,
        verifyOtp: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.GET_ALL_ADDRESS_FETCHING:
      return {
        ...state,
        shipToPartyAddressData: {
          loading: false,
          data: null,
          err: null
        }
      };
    case ONBOARD.GET_ALL_ADDRESS_SUCCESS:
      return {
        ...state,
        shipToPartyAddressData: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_ALL_ADDRESS_ERROR:
      return {
        ...state,
        shipToPartyAddressData: {
          loading: false,
          data: null,
          err: action.payload.error
        }
      };



    case ONBOARD.SAVE_ADDRESS_FETCHING:
      return {
        ...state,
        saveNewAdress: {
          loading: false,
          data: null,
          err: null
        }
      };
    case ONBOARD.SAVE_ADDRESS_SUCCESS:
      return {
        ...state,
        saveNewAdress: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.SAVE_ADDRESS_ERROR:
      return {
        ...state,
        saveNewAdress: {
          loading: false,
          data: null,
          err: action.payload.error
        }
      };

    case ONBOARD.GET_SELECTED_ADDRESS_FETCHING:
      return {
        ...state,
        getSelectedAddress: {
          loading: true,
          data: null,
          err: null
        }
      };
    case ONBOARD.GET_SELECTED_ADDRESS_SUCCESS:
      return {
        ...state,
        getSelectedAddress: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_SELECTED_ADDRESS_ERROR:
      return {
        ...state,
        getSelectedAddress: {
          loading: false,
          data: null,
          err: action.payload.error
        }
      };
    case ONBOARD.SAVE_DEALER_DETAILS_FETCHING:
      return {
        ...state,
        saveDealerDetailsTab: { loading: true, data: null, err: null }
      }
    case ONBOARD.SAVE_DEALER_DETAILS_SUCCESS:
      return {
        ...state,
        saveDealerDetailsTab: { loading: false, data: action.payload.data.data, err: null }
      }
    case ONBOARD.SAVE_DEALER_DETAILS_ERROR:
      return {
        ...state,
        saveDealerDetailsTab: { loading: false, data: null, err: action.payload.error }
      }
    case ONBOARD.DELETE_ADDRESS_FETCHING:
      return {
        ...state,
        deleteAddressData: { loading: false, data: null, err: null }
      }
    case ONBOARD.DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        deleteAddressData: { loading: false, data: action.payload.data, err: null }
      }
    case ONBOARD.DELETE_ADDRESS_ERROR:
      return {
        ...state,
        deleteAddressData: { loading: false, data: null, err: action.payload.error }
      }

    case ONBOARD.UPDATE_ADDRESS_FETCHING:
      return {
        ...state,
        updateAddressData: { loading: true, data: null, err: null }
      }
    case ONBOARD.UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        updateAddressData: { loading: false, data: action.payload.data, err: null }
      }
    case ONBOARD.UPDATE_ADDRESS_ERROR:
      return {
        ...state,
        updateAddressData: { loading: false, data: null, err: action.payload.error }
      }

    case ONBOARD.SET_CUSTOMER_TYPE:
      return {
        ...state,
        customerType: action.payload.data
      };

    // CODE FOR GET CHANGE CUSTOMER TYPE
    case ONBOARD.CHANGE_CUSTOMER_TYPE_DETAILS_FETCHING:
      return {
        ...state,
        dealerDetails: null,
        customerDetails: null,
        enquiryAction: null,
        enquiryStatus: null,
        regionData: null,
        tradeRegionData: null,
        dealingInData: null,
        typeofTradeData: null,
        partnerDetails: null,
        savePartnerData: null,
        editPartnerData: null,
        deletePartnerData: null,
        deleteSignatoriesData: null,
        saveSignatoriesData: null,
        editSignatoriesData: null,
        getSignatoriesData: null,
        documentTab: null,
        generateOTP: null,
        verifyOtp: null,
        uploadedDocumentData: null,
        deleteDocumentData: null,
        shipToPartyAddressData: null,
        getDocumentByIdData: null,
        saveNewAdress: null,
        getSelectedAddress: null,
        deleteAddressData: null,
        updateAddressData: null,
        status: null,
        deleteAddressDocumentData: null,
        getAllSignatoriesData: null,
        getAllPartnersData: null,
        deletePartnerDocumentData: null,
        deleteSignatoryDocumentData: null,
        changeCustomerTypeData: { loading: true, data: null, err: null }
      };
    case ONBOARD.CHANGE_CUSTOMER_TYPE_DETAILS_SUCCESS:
      return {
        ...state,
        changeCustomerTypeData: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.CHANGE_CUSTOMER_TYPE_DETAILS_ERROR:
      return {
        ...state,
        changeCustomerTypeData: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.SET_STATUS:
      return {
        ...state,
        status: action.payload.data
      };
    case ONBOARD.RESET_VALUES:
      return {
        ...state,
        partnerDetails: null,
        getSignatoriesData: null,
        saveSignatoriesData: null,
        editSignatoriesData: null,
        deleteSignatoriesData: null,
        uploadedDocumentData: null,
        saveNewAdress: null,
        deleteAddressData: null,
        updateAddressData: null,
        verifyOtp: null,
        generateOTP : null,
        isRefresh: action.payload
      };

    // FOR DELETE DOCUMENT UPLOAD 
    case ONBOARD.DELETE_DOCUMENT_ADDRESS_FETCHING:
      return {
        ...state,
        deleteAddressDocumentData: { loading: true, data: null, err: null }
      };
    case ONBOARD.DELETE_DOCUMENT_ADDRESS_SUCCESS:
      return {
        ...state,
        deleteAddressDocumentData: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.DELETE_DOCUMENT_ADDRESS_ERROR:
      return {
        ...state,
        deleteAddressDocumentData: { loading: false, data: null, err: action.payload.error }
      };
    // FOR GET ALL PARTNERS DATA
    case ONBOARD.GET_ALL_PARTNERS_DETAILS_FETCHING:
      return {
        ...state,
        getAllPartnersData: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_ALL_PARTNERS_DETAILS_SUCCESS:
      return {
        ...state,
        getAllPartnersData: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_ALL_PARTNERS_DETAILS_ERROR:
      return {
        ...state,
        getAllPartnersData: { loading: false, data: null, err: action.payload.error }
      };
    // FOR GET ALL SIGNATORIES DATA
    case ONBOARD.GET_ALL_SIGNATORIES_DETAILS_FETCHING:
      return {
        ...state,
        getAllSignatoriesData: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_ALL_SIGNATORIES_DETAILS_SUCCESS:
      return {
        ...state,
        getAllSignatoriesData: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_ALL_SIGNATORIES_DETAILS_ERROR:
      return {
        ...state,
        getAllSignatoriesData: { loading: false, data: null, err: action.payload.error }
      };

    case ONBOARD.DELETE_ADDRESS_DOCUMENT_FETCHING:
      return {
        ...state,
        deletePartnerDocumentData: { loading: true, data: null, err: null }
      };
    case ONBOARD.DELETE_ADDRESS_DOCUMENT_SUCCESS:
      return {
        ...state,
        deletePartnerDocumentData: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.DELETE_ADDRESS_DOCUMENT_ERROR:
      return {
        ...state,
        deletePartnerDocumentData: { loading: false, data: null, err: action.payload.error }
      };
    case ONBOARD.DELETE_SIGNATORY_DOCUMENT_FETCHING:
      return {
        ...state,
        deleteSignatoryDocumentData: { loading: true, data: null, err: null }
      };
    case ONBOARD.DELETE_SIGNATORY_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteSignatoryDocumentData: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.DELETE_SIGNATORY_DOCUMENT_ERROR:
      return {
        ...state,
        deleteSignatoryDocumentData: { loading: false, data: null, err: action.payload.error }
      };
      // CODE FOR GET REQUEST CORRECTION DETAILS
    case ONBOARD.GET_REQUEST_CORRECTION_DETAILS_FETCHING:
      return {
        ...state,
        requestCorrectionApi: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_REQUEST_CORRECTION_DETAILS_SUCCESS:
      return {
        ...state,
        requestCorrectionApi: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.GET_REQUEST_CORRECTION_DETAILS_ERROR:
      return {
        ...state,
        requestCorrectionApi: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR SAVE REQUEST CORRECTION DETAILS
    case ONBOARD.SAVE_REQUEST_CORRECTION_DETAILS_FETCHING:
      return {
        ...state,
        saveRequestCorrectionApi: { loading: true, data: null, err: null }
      };
    case ONBOARD.SAVE_REQUEST_CORRECTION_DETAILS_SUCCESS:
      return {
        ...state,
        saveRequestCorrectionApi: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.SAVE_REQUEST_CORRECTION_DETAILS_ERROR:
      return {
        ...state,
        saveRequestCorrectionApi: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR HISTORY LIST CORRECTION DETAILS
    case ONBOARD.HISTORY_LIST_REQUEST_CORRECTION_DETAILS_FETCHING:
      return {
        ...state,
        historyListRequestCorrectionApi: { loading: true, data: null, err: null }
      };
    case ONBOARD.HISTORY_LIST_REQUEST_CORRECTION_DETAILS_SUCCESS:
      return {
        ...state,
        historyListRequestCorrectionApi: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ONBOARD.HISTORY_LIST_REQUEST_CORRECTION_DETAILS_ERROR:
      return {
        ...state,
        historyListRequestCorrectionApi: { loading: false, data: null, err: action.payload.error }
      };
     // CODE FOR GET TRANSPORTATION ZONE
    case ONBOARD.GET_TRANSPORTATION_ZONE_DETAILS_FETCHING:
      return {
        ...state,
        zoneData: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_TRANSPORTATION_ZONE_DETAILS_SUCCESS:
      return {
        ...state,
        zoneData: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_TRANSPORTATION_ZONE_DETAILS_ERROR:
      return {
        ...state,
        zoneData: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR SAVE ZONE
    case ONBOARD.SAVE_ZONE_DETAILS_FETCHING:
      return {
        ...state,
        saveZoneData: { loading: true, data: null, err: null }
      };
    case ONBOARD.SAVE_ZONE_DETAILS_SUCCESS:
      return {
        ...state,
        saveZoneData: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.SAVE_ZONE_DETAILS_ERROR:
      return {
        ...state,
        saveZoneData: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR GET PARAMETERS DETAILS
    case ONBOARD.GET_PARAMETERS_DETAILS_FETCHING:
      return {
        ...state,
        getParametersDetailsAPI: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_PARAMETERS_DETAILS_SUCCESS:
      return {
        ...state,
        getParametersDetailsAPI: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_PARAMETERS_DETAILS_ERROR:
      return {
        ...state,
        getParametersDetailsAPI: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR GET PARAMETER VALUES
    case ONBOARD.GET_PARAMETER_VALUES_FETCHING:
      return {
        ...state,
        getParameterValuesAPI: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_PARAMETER_VALUES_SUCCESS:
      return {
        ...state,
        getParameterValuesAPI: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_PARAMETER_VALUES_ERROR:
      return {
        ...state,
        getParameterValuesAPI: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR SAVE PARAMETER VALUES
    case ONBOARD.SAVE_PARAMETER_VALUES_FETCHING:
      return {
        ...state,
        saveParameterValuesAPI: { loading: true, data: null, err: null }
      };
    case ONBOARD.SAVE_PARAMETER_VALUES_SUCCESS:
      return {
        ...state,
        saveParameterValuesAPI: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.SAVE_PARAMETER_VALUES_ERROR:
      return {
        ...state,
        saveParameterValuesAPI: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR UPDATE PARAMETER VALUES
    case ONBOARD.UPDATE_PARAMETER_VALUES_FETCHING:
      return {
        ...state,
        updateParameterValuesAPI: { loading: true, data: null, err: null }
      };
    case ONBOARD.UPDATE_PARAMETER_VALUES_SUCCESS:
      return {
        ...state,
        updateParameterValuesAPI: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.UPDATE_PARAMETER_VALUES_ERROR:
      return {
        ...state,
        updateParameterValuesAPI: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR GET SAP DETAILS
    case ONBOARD.GET_SAP_DETAILS_FETCHING:
      return {
        ...state,
        getSapDetailsAPI: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_SAP_DETAILS_SUCCESS:
      return {
        ...state,
        getSapDetailsAPI: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_SAP_DETAILS_ERROR:
      return {
        ...state,
        getSapDetailsAPI: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR SAVE SAP DETAILS
    case ONBOARD.SAVE_SAP_DETAILS_FETCHING:
      return {
        ...state,
        saveSapDetailsAPI: { loading: true, data: null, err: null }
      };
    case ONBOARD.SAVE_SAP_DETAILS_SUCCESS:
      return {
        ...state,
        saveSapDetailsAPI: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.SAVE_SAP_DETAILS_ERROR:
      return {
        ...state,
        saveSapDetailsAPI: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR UPDATE SAP DETAILS
    case ONBOARD.UPDATE_SAP_DETAILS_FETCHING:
      return {
        ...state,
        updateSapDetailsAPI: { loading: true, data: null, err: null }
      };
    case ONBOARD.UPDATE_SAP_DETAILS_SUCCESS:
      return {
        ...state,
        updateSapDetailsAPI: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.UPDATE_SAP_DETAILS_ERROR:
      return {
        ...state,
        updateSapDetailsAPI: { loading: false, data: null, err: action.payload.error }
      };
    // CODE FOR GET NATIONALITY SELECTOR DATA FOR OWNER/PARTNER & ADD AUTHORIZED SIGANTORIES DETAILS
    case ONBOARD.GET_NATIONALITY_FETCHING:
      return {
        ...state,
        getNationalityData: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_NATIONALITY_SUCCESS:
      return {
        ...state,
        getNationalityData: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_NATIONALITY_ERROR:
      return {
        ...state,
        getNationalityData: { loading: false, data: null, err: action.payload.error }
      };

    // CODE FOR GET INDUSTRY TYPE SELECTOR AT APPROVE BUTTON IN KAM PORTAL
    case ONBOARD.GET_INDUSTRY_TYPE_FETCHING:
      return {
        ...state,
        getIndustryTypeAPI: { loading: true, data: null, err: null }
      };
    case ONBOARD.GET_INDUSTRY_TYPE_SUCCESS:
      return {
        ...state,
        getIndustryTypeAPI: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_INDUSTRY_TYPE_ERROR:
      return {
        ...state,
        getIndustryTypeAPI: { loading: false, data: null, err: action.payload.error }
      };

      //Payment Mode
      case ONBOARD.GET_PAYMENT_MODE_FETCHING:
      return {
        ...state,
        paymentModeList: { loading: true, data: null, err: null }

      };
    case ONBOARD.GET_PAYMENT_MODE_SUCCESS:
      return {
        ...state,
        paymentModeList: {
          loading: false,
          data: action.payload.data.data,
          err: null
        }
      };
    case ONBOARD.GET_PAYMENT_MODE_ERROR:
      return {
        ...state,
        paymentModeList: { loading: false, data: null, err: action.payload.error }
      };
    default:
      return state;
  }
};

export default onboardingReducer;