import { API_ENDPOINTS, ONBOARDING } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const onBoardingService = {
    verifyLink,
    updateUserB2CId,
    getUserB2CId,
    getUserB2CIdInSignup,
    getUserB2CIdNew,
};

async function verifyLink(b2bID:any) {
    const apiPath = API_ENDPOINTS.ONBOARD + ONBOARDING.VERIFYDATA + `/${b2bID}`;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getUserB2CIdInSignup(b2c_oid:any) {
    const apiPath = API_ENDPOINTS.ONBOARD + ONBOARDING.GETB2CIDINSIGNUPFLOW + `/${b2c_oid}`;

    const options = {
        //cors_url: API_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin" : "https://autob2bdev.corp.al-futtaim.com"
        },
        formdata: false
    };
    return await makeRequest(options)
}

async function getUserB2CId(b2c_oid:any) {
    const apiPath = API_ENDPOINTS.ONBOARD + ONBOARDING.GETB2CUSERDATA + `/${b2c_oid}`;

    const options = {
        //cors_url: API_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin" : "https://autob2bdev.corp.al-futtaim.com"
        },
        formdata: false
    };
    return await makeRequest(options)
}

async function getUserB2CIdNew(b2c_oid: any, emailId: any) {
    const apiPath = API_ENDPOINTS.ONBOARD + ONBOARDING.GETB2CUSERDATA;

    const options = {
        //cors_url: API_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        customHeaders: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://autob2bdev.corp.al-futtaim.com"
        },
        bodyObj: {
            id: b2c_oid,
            email: emailId
        },
        formdata: false
    };
    return await makeRequest(options)
}

async function updateUserB2CId(b2bdata:any) {
    const apiPath = API_ENDPOINTS.ONBOARD + ONBOARDING.UPDATEID;// + `/${b2b_id}`;

    const options = {
        //cors_url: API_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "PUT",
        bodyObj: b2bdata,
        customHeaders: {
            // "Content-Type": "application/json",
            // "Access-Control-Allow-Origin" : "https://autob2bdev.corp.al-futtaim.com"
        },
        formdata: true
    };
    return await makeRequest(options)
}


