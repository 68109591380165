import React, { Fragment, Dispatch, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withStyles, Theme, createStyles, makeStyles, createTheme } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ThemeProvider } from '@material-ui/styles';
import XLSX from 'xlsx';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    title: {
        flex: '1 1 100%',
    },
    createButton: {
        backgroundColor: '#1A9FE0',
        width: 140,
        fontWeight: 800,
        textTransform: 'inherit',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#FFFFFF',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 8
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 140,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    addUpdateDailogTitle: {
        position: "absolute",
        top: 10,
        left: 10
    },
    correctionTextCSS: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    }
}));

const theme = createTheme({
    palette: {
        secondary: {
            main: '#1A9FE0'
        },
    }
});

const AntSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 60,
            height: 28,
            padding: 0,
        },
        switchBase: {
            padding: 5,
            color: '#FFFFFF',
        },
        thumb: {
            width: 17,
            height: 17,
        },
        track: {
            background: '#444444',
            opacity: '1 !important',
            borderRadius: '2rem',
            position: 'relative',
            '&:before, &:after': {
                display: 'inline-block',
                position: 'absolute',
                top: '50%',
                width: '50%',
                transform: 'translateY(-50%)',
                color: '#FFFFFF',
                textAlign: 'center',
                fontSize: 10,
                fontFamily: 'Nunito',
                fontWeight: 500
            },
            '&:before': {
                content: '"Yes"',
                left: 7,
                opacity: 0,
            },
            '&:after': {
                content: '"No"',
                right: 6,
            },
        },
        checked: {
            '&$switchBase': {
                color: '#FFFFFF',
                transform: 'translateX(33px)',
            },
            '& $thumb': {
                backgroundColor: '#FFFFFF',
            },
            '& + $track': {
                '&:before': {
                    opacity: 1,
                },
                '&:after': {
                    opacity: 0,
                }
            },
        },
    }),
)(Switch);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

export default function RetrieveBrandsDialog(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState<any>(null)
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [errorMessage, showErrorMessage] = React.useState<any>(null);
    const [creditControlArea, setCreditControlArea] = React.useState<any>("");
    const [creditControlAreaError, setCreditControlAreaError] = React.useState<boolean>(false);
    const [invalidCreditControlArea, setInvalidCreditControlArea] = React.useState<boolean>(false);
    const [salesOrg, setSalesOrg] = React.useState<any>();
    const [salesOrgError, setSalesOrgError] = React.useState<boolean>(false);
    const [invalidSalesOrg, setInvalidSalesOrg] = React.useState<boolean>(false);
    const [distributionChannel, setDistributionChannel] = React.useState<any>();
    const [distributionChannelError, setDistributionChannelError] = React.useState<boolean>(false);
    const [invalidDistributionChannel, setInvalidDistributionChannel] = React.useState<boolean>(false);
    const [division, setDivision] = React.useState<any>();
    const [divisionError, setDivisionError] = React.useState<boolean>(false);
    const [invalidDivision, setInvalidDivision] = React.useState<boolean>(false);
    const [salesGroup, setSalesGroup] = React.useState<any>();
    const [salesGroupError, setSalesGroupError] = React.useState<boolean>(false);
    const [invalidSalesGroup, setInvalidSalesGroup] = React.useState<boolean>(false);
    const [salesOffice, setSalesOffice] = React.useState<any>();
    const [salesOfficeError, setSalesOfficeError] = React.useState<boolean>(false);
    const [invalidSalesOffice, setInvalidSalesOffice] = React.useState<boolean>(false);
    const [salesEmployee, setSalesEmployee] = React.useState<any>();
    const [salesEmployeeError, setSalesEmployeeError] = React.useState<boolean>(false);
    const [invalidSalesEmployee, setInvalidSalesEmployee] = React.useState<boolean>(false);
    const [assignedKAM, setAssignedKAM] = React.useState<any>();
    const [assignedKAMError, setAssignedKAMError] = React.useState<boolean>(false);
    const [invalidAssignedKAM, setInvalidAssignedKAM] = React.useState<boolean>(false);
    const { setOpenDialog } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (newValue: any) => {
        setValue(newValue);
    }

    const handleopenCloseErrorSnackbar = (open?: any) => {
        setOpenErrorSnackbar(open ? true : false);
    };

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    const handleopenClose = (open?: any) => {
        setOpenDeleteDialog(open ? true : false);
        setSuccessMessage(null);
    };

    const handleInputChange = (e: any, setterName: any, errorSetterName?: any) => {
        setterName(e.target.value);
        if (errorSetterName && e.target.required) {
            if (e.target.value === "" || e.target.value.trim() === "")
                errorSetterName(true);
            else
                errorSetterName(false);
        }
    }

    const resetData = () => {
        setCreditControlArea(null);
        setCreditControlAreaError(false);
        setInvalidCreditControlArea(false);
        setSalesOrg(null);
        setSalesOrgError(false);
        setInvalidSalesOrg(false);
        setDistributionChannel(null);
        setDistributionChannelError(false);
        setInvalidDistributionChannel(false);
        setDivision(null);
        setDivisionError(false);
        setInvalidDivision(false);
        setSalesGroup(null);
        setSalesGroupError(false);
        setInvalidSalesGroup(false);
        setSalesOffice(null);
        setSalesOfficeError(false);
        setInvalidSalesOffice(false);
        setSalesEmployee(null);
        setSalesEmployeeError(false);
        setInvalidSalesEmployee(false);
        setAssignedKAM(null);
        setAssignedKAMError(false);
        setInvalidAssignedKAM(false);
    }

    function validateFormFields(action: string, value: any) {
        switch (action) {
            case "creditControlArea": {
                const re = /^[a-zA-Z0-9 ]{1,250}$/;
                return value.length > 250 || !re.test(value) ? false : true;
            }
            case "salesOrg": {
                const re = /^[a-zA-Z0-9 ]{1,250}$/;
                return value.length > 250 || !re.test(value) ? false : true;
            }
            case "distributionChannel": {
                const re = /^[a-zA-Z0-9 ]{1,250}$/;
                return value.length > 250 || !re.test(value) ? false : true;
            }
            case "division": {
                const re = /^[a-zA-Z0-9 ]{1,250}$/;
                return value.length > 250 || !re.test(value) ? false : true;
            }
            case "salesGroup": {
                const re = /^[a-zA-Z0-9 ]{1,250}$/;
                return value.length > 250 || !re.test(value) ? false : true;
            }
            case "salesOffice": {
                const re = /^[a-zA-Z0-9 ]{1,250}$/;
                return value.length > 250 || !re.test(value) ? false : true;
            }
            case "salesEmployee": {
                const re = /^[a-zA-Z0-9 ]{1,250}$/;
                return value.length > 250 || !re.test(value) ? false : true;
            }
            case "assignedKAM": {
                const re = /^[a-zA-Z0-9 ]{1,250}$/;
                return !re.test(value) ? false : true;
            }
            default:
                return true
        }
    }

    const validationAdditionalCreditRequest = () => {
        let isValid: boolean = true;
        if (!creditControlArea || creditControlArea.trim() === "") {
            setCreditControlAreaError(true);
            isValid = false;
        }
        if (!creditControlArea || creditControlArea === "" || creditControlArea.trim() === "") {
            if (creditControlArea && creditControlArea.split('').length > 250) {
                setCreditControlAreaError(true);
                isValid = false;
            }
            else if (creditControlArea && !validateFormFields("creditControlArea", creditControlArea)) {
                setInvalidCreditControlArea(true);
                isValid = false;
            }
            else {
                setInvalidCreditControlArea(false);
            }
        }
        if (!salesOrg || salesOrg.trim() === "") {
            setSalesOrgError(true);
            isValid = false;
        }
        if (!salesOrg || salesOrg === "" || salesOrg.trim() === "") {
            if (salesOrg && salesOrg.split('').length > 250) {
                setSalesOrgError(true);
                isValid = false;
            }
            else if (salesOrg && !validateFormFields("salesOrg", salesOrg)) {
                setInvalidSalesOrg(true);
                isValid = false;
            }
            else {
                setInvalidSalesOrg(false);
            }
        }
        if (!distributionChannel || distributionChannel.trim() === "") {
            setDistributionChannelError(true);
            isValid = false;
        }
        if (!distributionChannel || distributionChannel === "" || distributionChannel.trim() === "") {
            if (distributionChannel && distributionChannel.split('').length > 250) {
                setDistributionChannelError(true);
                isValid = false;
            }
            else if (distributionChannel && !validateFormFields("distributionChannel", distributionChannel)) {
                setInvalidDistributionChannel(true);
                isValid = false;
            }
            else {
                setInvalidDistributionChannel(false);
            }
        }
        if (!division || division.trim() === "") {
            setDivisionError(true);
            isValid = false;
        }
        if (!division || division === "" || division.trim() === "") {
            if (division && division.split('').length > 250) {
                setDivisionError(true);
                isValid = false;
            }
            else if (division && !validateFormFields("division", division)) {
                setInvalidDivision(true);
                isValid = false;
            }
            else {
                setInvalidDivision(false);
            }
        }
        if (!salesGroup || salesGroup.trim() === "") {
            setSalesGroupError(true);
            isValid = false;
        }
        if (!salesGroup || salesGroup === "" || salesGroup.trim() === "") {
            if (salesGroup && salesGroup.split('').length > 250) {
                setSalesGroupError(true);
                isValid = false;
            }
            else if (salesGroup && !validateFormFields("salesGroup", salesGroup)) {
                setInvalidSalesGroup(true);
                isValid = false;
            }
            else {
                setInvalidSalesGroup(false);
            }
        }
        if (!salesOffice || salesOffice.trim() === "") {
            setSalesOfficeError(true);
            isValid = false;
        }
        if (!salesOffice || salesOffice === "" || salesOffice.trim() === "") {
            if (salesOffice && salesOffice.split('').length > 250) {
                setSalesOfficeError(true);
                isValid = false;
            }
            else if (salesOffice && !validateFormFields("salesOffice", salesOffice)) {
                setInvalidSalesOffice(true);
                isValid = false;
            }
            else {
                setInvalidSalesOffice(false);
            }
        }
        if (!salesEmployee || salesEmployee.trim() === "") {
            setSalesEmployeeError(true);
            isValid = false;
        }
        if (!salesEmployee || salesEmployee === "" || salesEmployee.trim() === "") {
            if (salesEmployee && salesEmployee.split('').length > 250) {
                setSalesEmployeeError(true);
                isValid = false;
            }
            else if (salesEmployee && !validateFormFields("salesEmployee", salesEmployee)) {
                setInvalidSalesEmployee(true);
                isValid = false;
            }
            else {
                setInvalidSalesEmployee(false);
            }
        }
        if (!assignedKAM || assignedKAM.trim() === "") {
            setAssignedKAMError(true);
            isValid = false;
        }
        if (!assignedKAM || assignedKAM === "" || assignedKAM.trim() === "") {
            if (assignedKAM && assignedKAM.split('').length > 250) {
                setAssignedKAMError(true);
                isValid = false;
            }
            else if (assignedKAM && !validateFormFields("assignedKAM", assignedKAM)) {
                setInvalidAssignedKAM(true);
                isValid = false;
            }
            else {
                setInvalidAssignedKAM(false);
            }
        }
    }

    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.root}>
                <Snackbar
                    open={openSnackbar}
                    handleopenClose={() => {
                        handleopenCloseSnackbar(false)
                    }} text={successMessage} />
                <Snackbar
                    open={openErrorSnackbar}
                    type="error"
                    handleopenClose={() => {
                        handleopenCloseErrorSnackbar(false)
                    }} message={errorMessage} />
                <Toolbar
                    className={clsx(classes.root)}>
                    <div className="col-12 mb-2 mt-2">
                        <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                            <h4 className="shift-to-party-page-title">
                                <div className={classes.addUpdateDailogTitle}> Retrieve Brands </div>
                                <IconButton aria-label="close" className="float-right" onClick={() => { setOpenDialog(); resetData(); }}>
                                    <CloseIcon />
                                </IconButton>
                            </h4>
                        </Typography>
                    </div>
                </Toolbar>
                <div className="row px-4">
                    <div className="col-md-12 mt-2 mb-2">
                        <div className="d-flex align-items-center mb-2 px-3">
                            <h6 className="dealer-table-content mb-0">New Brands Added For</h6>
                            <div className="dealer-table-content mb-0 px-2"><h6 className="subTitleRetrieveDialogContent">Vivat International</h6></div>
                            <h6 className="dealer-table-content">Please Update Parameters.</h6>
                        </div>
                    </div>
                </div>
                <div className="mt-1 mb-1">
                    <ThemeProvider theme={theme}>
                        <Paper square>
                            <Tabs className="tabSelect" textColor="primary" indicatorColor="primary"
                                value={value} onChange={handleChange} aria-label="disabled tabs example">
                                <Tab label="FAMCO" {...a11yProps(0)} />
                                <Tab label="TE" {...a11yProps(1)} />
                            </Tabs>
                        </Paper>
                        <TabPanel value={value} index={0}>
                            <div className="row col-sm-12 mb-4 mt-4 px-4">
                                <div className="col-md-6 col-lg-6 px-2 mb-1 pl-0 mt-0">
                                    <TextField
                                        value={creditControlArea} id="creditControlArea"
                                        error={creditControlAreaError || invalidCreditControlArea}
                                        helperText={invalidCreditControlArea &&
                                            <span style={{ color: 'red' }}>Please enter credit control area </span>
                                        }
                                        onChange={(e: any) => handleInputChange(e, setCreditControlArea, setCreditControlAreaError)}
                                        size="small" className="col-lg-12 col-md-12 col-sm-12 pr-0 commonRoundedInputs common-input-font-size" label="Credit Control Area" variant="outlined" required />
                                </div>
                                <div className="col-md-6 col-lg-6 px-2 mb-1 pl-0 mt-0">
                                    <TextField
                                        value={salesOrg} id="salesOrg"
                                        error={salesOrgError || invalidSalesOrg}
                                        helperText={invalidSalesOrg &&
                                            <span style={{ color: 'red' }}> Please enter sales org </span>
                                        }
                                        onChange={(e: any) => handleInputChange(e, setSalesOrg, setSalesOrgError)}
                                        size="small" className="col-lg-12 col-md-12 col-sm-12 pr-0 commonRoundedInputs common-input-font-size" label="Sales Org" variant="outlined" required />
                                </div>
                            </div>
                            <div className="row col-sm-12 mb-4 mt-4 px-4">
                                <div className="col-md-6 col-lg-6 px-2 mb-1 pl-0 mt-0">
                                    <TextField
                                        value={distributionChannel} id="distributionChannel"
                                        error={distributionChannelError || invalidDistributionChannel}
                                        helperText={invalidDistributionChannel &&
                                            <span style={{ color: 'red' }}> Please enter distribution channel</span>
                                        }
                                        onChange={(e: any) => handleInputChange(e, setDistributionChannel, setDistributionChannelError)}
                                        size="small" className="col-lg-12 col-md-12 col-sm-12 pr-0 commonRoundedInputs common-input-font-size" label="Distribution Channel" variant="outlined" required />
                                </div>
                                <div className="col-md-6 col-lg-6 px-2 mb-1 pl-0 mt-0">
                                    <TextField
                                        value={division} id="division"
                                        error={divisionError || invalidDivision}
                                        helperText={invalidDivision &&
                                            <span style={{ color: 'red' }}> Please enter distribution channel</span>
                                        }
                                        onChange={(e: any) => handleInputChange(e, setDivision, setDivisionError)}
                                        size="small" className="col-lg-12 col-md-12 col-sm-12 pr-0 commonRoundedInputs common-input-font-size" label="Division" variant="outlined" required />
                                </div>
                            </div>
                            <div className="row col-sm-12 mb-4 mt-4 px-4">
                                <div className="col-md-6 col-lg-6 px-2 mb-1 pl-0 mt-0">
                                    <TextField
                                        value={salesGroup} id="salesGroup"
                                        error={salesGroupError || invalidSalesGroup}
                                        helperText={invalidSalesGroup &&
                                            <span style={{ color: 'red' }}> Please enter sales group</span>
                                        }
                                        onChange={(e: any) => handleInputChange(e, setSalesGroup, setSalesGroupError)}
                                        size="small" className="col-lg-12 col-md-12 col-sm-12 pr-0 commonRoundedInputs common-input-font-size" label="Sales Group" variant="outlined" required />
                                </div>
                                <div className="col-md-6 col-lg-6 px-2 mb-1 pl-0 mt-0">
                                    <TextField
                                        value={salesOffice} id="salesOffice"
                                        error={salesOfficeError || invalidSalesOffice}
                                        helperText={invalidSalesOffice &&
                                            <span style={{ color: 'red' }}> Please enter distribution office</span>
                                        }
                                        onChange={(e: any) => handleInputChange(e, setSalesOffice, setSalesOfficeError)}
                                        size="small" className="col-lg-12 col-md-12 col-sm-12 pr-0 commonRoundedInputs common-input-font-size" label="Sales Office" variant="outlined" required />
                                </div>
                            </div>
                            <div className="row col-sm-12 mb-4 mt-4 px-4">
                                <div className="col-md-6 col-lg-6 px-2 mb-1 pl-0 mt-0">
                                    <TextField
                                        value={salesEmployee} id="salesEmployee"
                                        error={salesEmployeeError || invalidSalesEmployee}
                                        helperText={invalidSalesEmployee &&
                                            <span style={{ color: 'red' }}> Please enter sales Employee</span>
                                        }
                                        onChange={(e: any) => handleInputChange(e, setSalesEmployee, setSalesEmployeeError)}
                                        size="small" className="col-lg-12 col-md-12 col-sm-12 pr-0 commonRoundedInputs common-input-font-size" label="Sales Employee" variant="outlined" required />
                                </div>
                                <div className="col-md-6 col-lg-6 px-2 mb-1 pl-0 mt-0">
                                    <TextField
                                        value={assignedKAM} id="assignedKAM"
                                        error={assignedKAMError || invalidAssignedKAM}
                                        helperText={invalidAssignedKAM &&
                                            <span style={{ color: 'red' }}> Please enter distribution channel</span>
                                        }
                                        onChange={(e: any) => handleInputChange(e, setAssignedKAM, setAssignedKAMError)}
                                        size="small" className="col-lg-12 col-md-12 col-sm-12 pr-0 commonRoundedInputs common-input-font-size" label="Division" variant="outlined" required />
                                </div>
                            </div>
                        </TabPanel>
                    </ThemeProvider >
                </div >
                <DialogActions className="mt-3 mb-3 justify-content-end">
                    <Button variant="contained" color="secondary" className={`createFavourite ${classes.createButton}`}
                        onClick={() => { validationAdditionalCreditRequest(); handleopenClose(false); }} >Save
                    </Button>
                    <Button variant="contained" className={classes.closeButton}
                        onClick={() => { setOpenDialog(); resetData(); }} >Cancel</Button>
                </DialogActions>
            </div >
        </Fragment >
    );
}