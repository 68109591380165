import { cmsAPIService } from "../../services/cms.service";
import { SPECIAL_OFFERS } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";

export const specialOffersActions = {
  getSpecialOffers,
};

export function getSpecialOffers() {
  return async (dispatch: any) => {
    dispatch(apiStart(SPECIAL_OFFERS.GET_SPECIAL_OFFERS_FETCHING));
    try {
    const response = await cmsAPIService.getSpecialoffers();
      dispatch(apiSuccess(SPECIAL_OFFERS.GET_SPECIAL_OFFERS_SUCCESS, response.data.Offers.OffersList));
      return response || [];
    } catch (error) {
      dispatch(apiError(SPECIAL_OFFERS.GET_SPECIAL_OFFERS_ERROR, error));
      return error;
    }
  };
}

