import { FAVORITES } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  favoritesList: null,
  favoritesDetails: null,
  createFavorite: null,
  updateFavorite: null,
  deleteFavorite: null,
};

function favoritesReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case FAVORITES.GET_FAVORITES_LIST_FETCHING:
      return {
        ...state,
        favoritesList: { loading: true, data: null, err: null },
        createFavorite: null,
        updateFavorite: null,
        deleteFavorite: null,
      };
    case FAVORITES.GET_FAVORITES_LIST_SUCCESS:
      return {
        ...state,
        favoritesList: { loading: false, data: action.payload.data.data, err: null }
      };
    case FAVORITES.GET_FAVORITES_LIST_ERROR:
      return {
        ...state,
        favoritesList: { loading: false, data: null, err: action.payload.error }
      };
    case FAVORITES.GET_FAVORITES_DETAILS_SUCCESS:
      return {
        ...state,
        favoritesDetails: action.payload
      };
    case FAVORITES.CREATE_FAVORITES_FETCHING:
      return {
        ...state,
        createFavorite: { loading: true, data: null, err: null }
      };
    case FAVORITES.CREATE_FAVORITES_SUCCESS:
      return {
        ...state,
        createFavorite: { loading: false, data: action.payload, err: null }
      };
    case FAVORITES.CREATE_FAVORITES_ERROR:
      return {
        ...state,
        createFavorite: { loading: false, data: null, err: action.payload.error }
      };
    case FAVORITES.DELETE_FAVORITES_FETCHING:
      return {
        ...state,
        deleteFavorite: { loading: true, data: null, err: null }
      };
    case FAVORITES.DELETE_FAVORITES_SUCCESS:
      return {
        ...state,
        deleteFavorite: { loading: false, data: action.payload, err: null }
      };
    case FAVORITES.DELETE_FAVORITES_ERROR:
      return {
        ...state,
        deleteFavorite: { loading: false, data: null, err: action.payload.error }
      };
    case FAVORITES.UPDATE_FAVORITES_FETCHING:
      return {
        ...state,
        updateFavorite: { loading: true, data: null, err: null }
      };
    case FAVORITES.UPDATE_FAVORITES_SUCCESS:
      return {
        ...state,
        updateFavorite: { loading: false, data: action.payload, err: null }
      };
    case FAVORITES.UPDATE_FAVORITES_ERROR:
      return {
        ...state,
        updateFavorite: { loading: false, data: null, err: action.payload.error }
      };
    default:
      return state;
  }
};

export default favoritesReducer;
