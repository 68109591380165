import React, { Fragment, useEffect, useState, Dispatch, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import NotificationIcon from '../../common/components/NotificationIcon';
import Dialog from '@material-ui/core/Dialog';
import DeleteDialog from '../../common/components/deleteDialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { AddNewAddressDailog } from './AddNewAddressDailog';
import AddIcon from '@material-ui/icons/Add';
import { useRef } from 'react';
import { DataUsageTwoTone, Visibility } from '@material-ui/icons';
import { getShipToPartyAddress, getDealerDetails, AddShipToPartyAddress, deleteShipToPartyAddress, getSelectedShipToPartyAddress, UpdatedShipToPartyAddress, getTradeRegion, resetValues, getTransportationZone, saveZone, setCustomerType, getDealingIn } from '../../store/actions/editDealer.actions';
import TextField from '@material-ui/core/TextField';
import { MenuItem, Button, Tooltip } from '@material-ui/core';
import { get } from 'lodash';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import Alert from '@material-ui/lab/Alert';
import ActionButtons from './ActionButtons';
import './shiptoparty.css';
import { Help } from '@material-ui/icons';
import { updateDealer } from '../../store/actions/dealer.action';

export const ShipToParty = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const [shippingAddress, setShippingAddress] = React.useState<any>(null);
  const { selectedNewDealer, isEditDealer }: any = useSelector((state: any) => state.dealer);
  const b2bUserId: any = selectedNewDealer && selectedNewDealer.createdByB2bUserId;
  const sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
  const [selected, setSelected] = React.useState<string>("");
  const [sapId, setSapId] = React.useState<string>();
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [apiResponseText, setApiResponseText] = React.useState('');
  const [selectedZone, setSelectedZone] = React.useState<any>([]);
  const [tempSetCustomer, TempSetCustomer] = React.useState<any>(["New", "Existing"]);
  const [setCustomer, SetCustomer] = React.useState<any>("");
  const [dealerstatus, setStatus] = React.useState<any>("");

  const { dealerDetails, getSelectedAddress, regionData, updateAddressData, deleteAddressData, saveNewAdress, zoneData }: any = useSelector((state: any) => state.onboard);

  const [editedAddress, setEditAddress] = React.useState({});
  const [addressList, setAddressList] = React.useState<any>([]);

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getNewAddressData = async (data: any) => {
    if (isEdited) {
      let result = await dispatch(UpdatedShipToPartyAddress(sapCustomerId, data, selected))
      const apiResponse = get(result, 'message', {});

      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
    }
    else {
      let result = await dispatch(AddShipToPartyAddress(sapCustomerId, data));
      const apiResponse = get(result, 'message', {});

      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);


        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }

      setSelected("");
    }


  }

  const getRegionName = (regionCode: any) => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      var regionName = regionData.data[0].regions.filter((obj: any) => obj.Code == regionCode)
      return regionName && regionName.length > 0 ? regionName[0].Region : '-';
    }
    return "";
  }

  const onClickDeleteAddress = async (shippingAddress: any) => {
    handleopenClose(true);
    setIsEdited(false);
    setSelected("");
    setShippingAddress(shippingAddress);

  }

  const deleteShippingAddress = async (action: any) => {
    handleopenClose(false);
    var userType = {
      "userType": "kam"
    }
    let result = await dispatch(deleteShipToPartyAddress(sapCustomerId, shippingAddress.id, userType));
    const apiResponse = get(result, 'message', {});
    if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
      setOpenSnackbar(true);
      setApiResponseText(apiResponse.message);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
    }
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEdited(false);
    setEditAddress([]);
  };

  const onClickEditAddress = async (addressId: any) => {
    setIsEdited(true);
    await dispatch(getSelectedShipToPartyAddress(sapCustomerId, addressId));
    setSelected(addressId);
    setOpen(true);
  }

  function getZoneCode(zoneName: any) {
    let zoneCode: any = '';
    if (zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone
      && zoneData.data[0].transportationZone.length > 0)
      zoneData.data[0].transportationZone.map((zone: any) => {
        if (zone.title === zoneName) {
          zoneCode = zone.code
        }
      });
    return zoneCode;
  }


  const onChangeZone = (e: any, data: any, index: any) => {
    props.setDataUpdated(true);
    let address = [...addressList]
    if (e.target.value && e.target.value) {
      if (address[index].type == "New") {
        address[index].transportationZone = e.target.value;
        address[index].transportationZoneCode = getZoneCode(e.target.value);
      }

      else {
        address[index].shiftToPartyCustomerNumber = e.target.value;
      }

      setAddressList(address);
    }
    else if (!e.target.value) {
      address[index].shiftToPartyCustomerNumber = "";
      setAddressList(address);
    }
  }

  const onChangeNew = (e: any, data: any, index: any) => {
    if (e.target.value && e.target.value) {
      let address = [...addressList]
      address[index].type = e.target.value;
      if (e.target.value == "New") {
        address[index].isExisting = false;
        address[index].transportationZone = "";
        address[index].transportationZoneCode = getZoneCode(e.target.value);
      }
      else {
        address[index].isExisting = true;
        address[index].shiftToPartyCustomerNumber = "";
      }
      setAddressList(address);
    }
  }

  const saveZoneAddress = async () => {
    setIsValid(true);
    let isValidTemp = true
    let address = [...addressList];
    let data: any = {
      addresses: []
    };
    address.map((add: any) => {
      if (add.transportationZone && add.transportationZoneCode && !add.isExisting) {
        data.addresses.push({
          id: add.id,
          transportationZone: add.transportationZone,
          transportationZoneCode: add.transportationZoneCode,
          isExisting: add.isExisting,
        })
      }
      else if (add.isExisting && add.shiftToPartyCustomerNumber) {
        data.addresses.push({
          id: add.id,
          isExisting: add.isExisting,
          shiftToPartyCustomerNumber: add.shiftToPartyCustomerNumber
        })
      } else {
        isValidTemp = false;
        setIsValid(false);
      }
    });
    if (isValidTemp && address.length === data.addresses.length) {
      let savedZone: any = await dispatch(saveZone(sapCustomerId, data));
      if (savedZone && savedZone.message && savedZone.message.status === 'S') {
        props.setDataUpdated(false);
        props.setselectedTab(3);
        props.setSuccessMessage("Customer details updated successfully");
        props.handleopenCloseSnackbar(true);
      } else {
        setApiResponseText(savedZone.error);
        handleopenCloseErrorSnackbar(true);
      }
    } else {
      setApiResponseText('Please fill all mandatory fields');
      handleopenCloseErrorSnackbar(true);
    }
  }

  useEffect(() => {
    if (saveNewAdress && saveNewAdress.data && saveNewAdress.data.message) {
      handleopenClose(false);
      setSuccessMessage(saveNewAdress.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
      props.setDataUpdated(true);
    }
  }, [saveNewAdress]);

  useEffect(() => {
    if (updateAddressData && updateAddressData.data && updateAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(updateAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
      props.setDataUpdated(true);
    }
  }, [updateAddressData]);

  useEffect(() => {
    if (deleteAddressData && deleteAddressData.data && deleteAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deleteAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
      props.setDataUpdated(true);
    }
  }, [deleteAddressData]);

  useEffect(() => {
    dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
    dispatch(getTransportationZone(b2bUserId));
    setSuccessMessage(null)
    handleopenCloseSnackbar(false);
    dispatch(resetValues());
  }, []);

  useEffect(() => {

    if (dealerDetails && dealerDetails.data) {
      if (dealerDetails.data.status) {
        setStatus(dealerDetails.data.status)
      }
      if (dealerDetails.data.addresses && dealerDetails.data.addresses.length > 0) {
        setAddressList(setaddressData(dealerDetails.data.addresses));
        setSapId(dealerDetails.data.sapCustomerId);
      }
    }
    else if (dealerDetails && dealerDetails.err) {

    }
    if (getSelectedAddress && getSelectedAddress.data) {
      setEditAddress(getSelectedAddress ? getSelectedAddress.data : {})
    }
    else {
      setSelected("");
    }

  }, [dealerDetails, getSelectedAddress])

  function setaddressData(data: any) {
    data.forEach((element: any) => {
      element["type"] = "";
      if (dealerDetails && dealerDetails.data && dealerDetails.data.isNewCustomer) {
        element.type = "New";
        element.isExisting = false;
      } else {
        element.type = "Existing";
        element.isExisting = true;
      }
    });
    return data;

  }

  return (
    <Fragment>
      {dealerDetails && dealerDetails.loading && <FullscreenLoader />}
      {getSelectedAddress && getSelectedAddress.loading && <FullscreenLoader />}
      {updateAddressData && updateAddressData.loading && <FullscreenLoader />}
      {deleteAddressData && deleteAddressData.loading && <FullscreenLoader />}
      {saveNewAdress && saveNewAdress.loading && <FullscreenLoader />}
      {/* {isEditDealer && (
        <div className="col-12">
          <div className="d-flex align-items-center mb-2">
            <div className="row-space-start ml-auto">
              <Button
                className="text-info disabledCustomclass"
                startIcon={<AddIcon className="font-size-18" />}
                // disabled={dealerDetails && dealerDetails.err && dealerDetails.err.error}
                disabled
                onClick={() => {
                  handleClickOpen();
                }}
              ><span className="onshiftoparty-font">Add New Address
                </span>
              </Button> 
            </div>
          </div>
        </div>
      )} */}
      <div className="row">
        {(dealerDetails && dealerDetails.err) && (
          <div className="col-12">
            <Alert severity="info">Please submit Complete Registration details prior to Ship to Party!</Alert>
          </div>
        )}
        {addressList && addressList.length > 0 && addressList.map((shipppingAddress: any, index: any) => (
          <>
            <div className="col-12 m-0 pl-3">
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="card-body border-bottom pt-1" key={index}>
                    <div className="w-75 d-inline-block">
                      <div className="shipAddTitle">{shipppingAddress.dafault ? shipppingAddress.companyName : shipppingAddress.location} &nbsp;

                        {shipppingAddress.dafault && (
                          <Button
                            className="defaultAddressButton cursor-default"
                            size="small"
                            variant="contained"
                            color="secondary"
                          >Head Office</Button>
                        )}
                      </div>
                      <div className="shipAdd mt-2">{shipppingAddress.streetName ? shipppingAddress.streetName + " - " : ""}  {shipppingAddress.city ? shipppingAddress.city + " - " : ""} {shipppingAddress.country}</div>
                      <div className="shipAdd">Telephone Office: {typeof shipppingAddress.telephoneNumber == 'string' ? shipppingAddress.telephoneNumber : shipppingAddress && shipppingAddress.telephoneNumber && shipppingAddress.telephoneNumber.length > 0 && shipppingAddress.telephoneNumber[0].telephone}</div>
                      <div className="shipAdd">Emirates/Region: {shipppingAddress.region} {getRegionName(shipppingAddress.region)}</div>
                      {isEditDealer && (
                        <div className="px-0 col-md-3 col-lg-5" style={{ display: "flex" }}>
                          <div className="px-0 col-md-3 col-lg-4 typeOfCustomer">
                            <TextField select id="New"
                              required
                              disabled
                              // error={!shipppingAddress.transportationZone && !isValid}
                              value={shipppingAddress.type}
                              onChange={(e: any) => { onChangeNew(e, shipppingAddress, index); props.setDataUpdated(true); }}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs"
                              variant="outlined" margin="dense" >
                              {tempSetCustomer.length > 0 && tempSetCustomer.map((zone: any, index: any) => (
                                <MenuItem value={zone}> {zone} </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          <Tooltip title={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 ?
                            'Please fill all the address details before assigning Transportation zone/Ship to party customer number ' : ''} placement="top">
                            {!shipppingAddress.isExisting ?
                              <TextField select id="transportationZone"
                                required
                                disabled={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 || dealerstatus && dealerstatus === "Deactive" ? true : false}
                                error={!shipppingAddress.transportationZone && !isValid}
                                value={shipppingAddress.transportationZone}
                                onChange={(e: any) => onChangeZone(e, shipppingAddress, index)}
                                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                label="Transportation Zone" variant="outlined" margin="dense" >
                                {zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone && zoneData.data[0].transportationZone.length > 0 && zoneData.data[0].transportationZone.map((zone: any, index: any) => (
                                  <MenuItem key={zone.code} value={zone.title}> {zone.title} </MenuItem>
                                ))}
                              </TextField>
                              : <TextField type="text" id="shipToParty"
                                required
                                error={!shipppingAddress.shiftToPartyCustomerNumber && !isValid}
                                value={shipppingAddress.shiftToPartyCustomerNumber}
                                disabled={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 || dealerstatus && dealerstatus === "Deactive" ? true : false}
                                onChange={(e: any) => onChangeZone(e, shipppingAddress, index)}
                                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                label="Ship To Party Customer number" variant="outlined" margin="dense" >
                              </TextField>
                            }
                          </Tooltip>
                          {shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && (
                            <Tooltip title={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 ?
                              'Please fill all the address details before assigning Transportation zone/Ship to party customer number ' : ''} placement="top">
                              <Button
                                className="cursor-normal mb-1 text-info"
                                startIcon={<Help />}
                              >
                              </Button>
                            </Tooltip>
                          )} </div>
                      )}
                    </div>
                    {shipppingAddress.dafault && isEditDealer && (
                      <div className="markDefaultIcon w-25 d-inline-block text-right">
                        <Button
                          className="text-info"
                          startIcon={<EditOutlinedIcon />}
                          disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                          onClick={() => {
                            onClickEditAddress(shipppingAddress.id)
                          }}><span className="onshiftoparty-font">Edit</span>
                        </Button>
                      </div>
                    )}
                    {shipppingAddress.dafault && !isEditDealer && (
                      <div className="markDefaultIcon w-25 d-inline-block text-right">
                        <Button
                          className="text-info"
                          startIcon={<Visibility />}
                          onClick={() => {
                            onClickEditAddress(shipppingAddress.id)
                          }}><span className="onshiftoparty-font">View</span>
                        </Button>
                      </div>
                    )}
                    {/* {!shipppingAddress.dafault && isEditDealer && (
                      <div className="markDefaultIcon w-25 d-inline-block text-right">
                        <Button
                          className="text-info disabledCustomclass"
                          // disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                          disabled
                          startIcon={<EditOutlinedIcon />}
                          onClick={() => {
                            onClickEditAddress(shipppingAddress.id)
                          }}><span className="onshiftoparty-font">View/Edit</span>
                        </Button>
                        <Button
                          onClick={() => onClickDeleteAddress(shipppingAddress)}
                          className="text-info disabledCustomclass"
                          startIcon={<DeleteOutlineOutlinedIcon />}
                        > <span className="onshiftoparty-font">Delete</span>
                        </Button>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <AddNewAddressDailog setOpenDialog={handleClose} getNewAddressData={getNewAddressData} editedAddress={editedAddress} isEdited={isEdited} regionData={regionData} sapId={sapId} isEditDealer={isEditDealer} />
      </Dialog>
      <DeleteDialog
        open={openDeleteDialog}
        text="Are you sure you want to delete this shipping address ?"
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteShippingAddress(data);
          if (!data) handleopenClose(false);
        }} />
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={apiResponseText} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={apiResponseText} />
      {isEditDealer && (
        <ActionButtons
          openRequestSnackbar={true}
          deactiveShow={true}
          addressList={addressList}
          dealerDetails={dealerDetails}
          dealerStatus={dealerstatus}
          saveCallback={() => {
            saveZoneAddress();
          }}
        />
      )}
    </Fragment>
  )
}
export default ShipToParty;
